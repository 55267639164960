(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-concurrent'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'korge-root-korlibs-concurrent'.");
    }
    root['korge-root-korlibs-concurrent'] = factory(typeof this['korge-root-korlibs-concurrent'] === 'undefined' ? {} : this['korge-root-korlibs-concurrent'], this['kotlin-kotlin-stdlib']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var protoOf = kotlin_kotlin.$_$.y9;
  var Unit_instance = kotlin_kotlin.$_$.g3;
  var VOID = kotlin_kotlin.$_$.d;
  var interfaceMeta = kotlin_kotlin.$_$.g9;
  var setMetadataFor = kotlin_kotlin.$_$.z9;
  var classMeta = kotlin_kotlin.$_$.t8;
  var toString = kotlin_kotlin.$_$.da;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.j1;
  //endregion
  //region block: pre-declaration
  function notify$default(unit, $super) {
    unit = unit === VOID ? Unit_instance : unit;
    var tmp;
    if ($super === VOID) {
      this.wx(unit);
      tmp = Unit_instance;
    } else {
      var tmp_0 = $super.wx;
      tmp = tmp_0.call(this, Unit_instance);
    }
    return tmp;
  }
  setMetadataFor(BaseLock, 'BaseLock', interfaceMeta);
  setMetadataFor(NonRecursiveLock, 'NonRecursiveLock', classMeta, VOID, [BaseLock], NonRecursiveLock);
  setMetadataFor(Lock, 'Lock', classMeta, VOID, [BaseLock], Lock);
  //endregion
  function BaseLock() {
  }
  function NonRecursiveLock() {
  }
  protoOf(NonRecursiveLock).wx = function (unit) {
  };
  function Lock() {
    this.yx_1 = false;
  }
  protoOf(Lock).wx = function (unit) {
    if (!this.yx_1) {
      // Inline function 'kotlin.error' call
      var message = 'Must lock before notifying';
      throw IllegalStateException_init_$Create$(toString(message));
    }
  };
  //region block: post-declaration
  protoOf(NonRecursiveLock).xx = notify$default;
  protoOf(Lock).xx = notify$default;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Lock;
  _.$_$.b = NonRecursiveLock;
  //endregion
  return _;
}));
