(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js', './korge-root-korlibs-datastructure.js', './korge-root-korlibs-logger.js', './korge-root-korlibs-concurrent.js', './korge-root-korlibs-math-core.js', './korge-root-korlibs-memory.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'), require('./korge-root-korlibs-datastructure.js'), require('./korge-root-korlibs-logger.js'), require('./korge-root-korlibs-concurrent.js'), require('./korge-root-korlibs-math-core.js'), require('./korge-root-korlibs-memory.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korge-foundation'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'korge-root-korge-foundation'.");
    }
    if (typeof this['korge-root-korlibs-datastructure'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korge-foundation'. Its dependency 'korge-root-korlibs-datastructure' was not found. Please, check whether 'korge-root-korlibs-datastructure' is loaded prior to 'korge-root-korge-foundation'.");
    }
    if (typeof this['korge-root-korlibs-logger'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korge-foundation'. Its dependency 'korge-root-korlibs-logger' was not found. Please, check whether 'korge-root-korlibs-logger' is loaded prior to 'korge-root-korge-foundation'.");
    }
    if (typeof this['korge-root-korlibs-concurrent'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korge-foundation'. Its dependency 'korge-root-korlibs-concurrent' was not found. Please, check whether 'korge-root-korlibs-concurrent' is loaded prior to 'korge-root-korge-foundation'.");
    }
    if (typeof this['korge-root-korlibs-math-core'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korge-foundation'. Its dependency 'korge-root-korlibs-math-core' was not found. Please, check whether 'korge-root-korlibs-math-core' is loaded prior to 'korge-root-korge-foundation'.");
    }
    if (typeof this['korge-root-korlibs-memory'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korge-foundation'. Its dependency 'korge-root-korlibs-memory' was not found. Please, check whether 'korge-root-korlibs-memory' is loaded prior to 'korge-root-korge-foundation'.");
    }
    root['korge-root-korge-foundation'] = factory(typeof this['korge-root-korge-foundation'] === 'undefined' ? {} : this['korge-root-korge-foundation'], this['kotlin-kotlin-stdlib'], this['korge-root-korlibs-datastructure'], this['korge-root-korlibs-logger'], this['korge-root-korlibs-concurrent'], this['korge-root-korlibs-math-core'], this['korge-root-korlibs-memory']);
  }
}(this, function (_, kotlin_kotlin, kotlin_com_soywiz_korge_korlibs_datastructure, kotlin_com_soywiz_korge_korlibs_logger, kotlin_com_soywiz_korge_korlibs_concurrent, kotlin_com_soywiz_korge_korlibs_math_core, kotlin_com_soywiz_korge_korlibs_memory) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var hypot = Math.hypot;
  var Duration__minus_impl_q5cfm7 = kotlin_kotlin.$_$.c2;
  var protoOf = kotlin_kotlin.$_$.y9;
  var Duration__plus_impl_yu9v8f = kotlin_kotlin.$_$.d2;
  var Duration__compareTo_impl_pchp0f = kotlin_kotlin.$_$.w1;
  var THROW_CCE = kotlin_kotlin.$_$.md;
  var Unit_instance = kotlin_kotlin.$_$.g3;
  var Comparable = kotlin_kotlin.$_$.cd;
  var Closeable = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.r;
  var classMeta = kotlin_kotlin.$_$.t8;
  var setMetadataFor = kotlin_kotlin.$_$.z9;
  var VOID = kotlin_kotlin.$_$.d;
  var Console_getInstance = kotlin_com_soywiz_korge_korlibs_logger.$_$.f;
  var Duration = kotlin_kotlin.$_$.xc;
  var ValueTimeMark__elapsedNow_impl_eonqvs = kotlin_kotlin.$_$.g2;
  var printStackTrace = kotlin_kotlin.$_$.zd;
  var SyncPauseable = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.s;
  var NonRecursiveLock = kotlin_com_soywiz_korge_korlibs_concurrent.$_$.b;
  var Monotonic_instance = kotlin_kotlin.$_$.d3;
  var ArrayDeque_init_$Create$ = kotlin_kotlin.$_$.k;
  var Companion_instance = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.q;
  var maxOf = kotlin_kotlin.$_$.m7;
  var Lock = kotlin_com_soywiz_korge_korlibs_concurrent.$_$.a;
  var KMutableProperty0 = kotlin_kotlin.$_$.ya;
  var getPropertyCallableRef = kotlin_kotlin.$_$.c9;
  var compareTo = kotlin_kotlin.$_$.u8;
  var FloatCompanionObject_instance = kotlin_kotlin.$_$.w2;
  var objectMeta = kotlin_kotlin.$_$.x9;
  var getNumberHashCode = kotlin_kotlin.$_$.b9;
  var equals = kotlin_kotlin.$_$.v8;
  var convertRange = kotlin_com_soywiz_korge_korlibs_math_core.$_$.h;
  var isAlmostEquals = kotlin_com_soywiz_korge_korlibs_math_core.$_$.n;
  var numberToInt = kotlin_kotlin.$_$.u9;
  var toList = kotlin_kotlin.$_$.a7;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.y3;
  var mapCapacity = kotlin_kotlin.$_$.y5;
  var coerceAtLeast = kotlin_kotlin.$_$.ma;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.q;
  var LazyThreadSafetyMode_PUBLICATION_getInstance = kotlin_kotlin.$_$.i;
  var lazy = kotlin_kotlin.$_$.wd;
  var interfaceMeta = kotlin_kotlin.$_$.g9;
  var clamp = kotlin_com_soywiz_korge_korlibs_math_core.$_$.d;
  var get_PI = kotlin_kotlin.$_$.ea;
  var Enum = kotlin_kotlin.$_$.ed;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var replace = kotlin_kotlin.$_$.yb;
  var clamp01 = kotlin_com_soywiz_korge_korlibs_math_core.$_$.a;
  var KProperty1 = kotlin_kotlin.$_$.bb;
  var IntArrayList = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.f1;
  var _BSearchResult___init__impl__em6zam = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.h;
  var coerceIn = kotlin_kotlin.$_$.pa;
  var toString = kotlin_kotlin.$_$.da;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.j1;
  var _BSearchResult___get_found__impl__om04iz = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.i;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.z;
  var objectCreate = kotlin_kotlin.$_$.w9;
  var umod = kotlin_com_soywiz_korge_korlibs_math_core.$_$.g1;
  var roundDecimalPlaces = kotlin_com_soywiz_korge_korlibs_math_core.$_$.u;
  var DoubleCompanionObject_instance = kotlin_kotlin.$_$.v2;
  var isNaN_0 = kotlin_kotlin.$_$.vd;
  var contentEquals = kotlin_kotlin.$_$.c4;
  var contentHashCode = kotlin_kotlin.$_$.i4;
  var Mixin = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.v;
  var DoubleArrayList = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.u;
  var round = kotlin_kotlin.$_$.ha;
  var isInterface = kotlin_kotlin.$_$.o9;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.m;
  var addAll = kotlin_kotlin.$_$.t3;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.l;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.yd;
  var ensureNotNull = kotlin_kotlin.$_$.td;
  var clamp_0 = kotlin_com_soywiz_korge_korlibs_math_core.$_$.f;
  var getCyclic = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.a2;
  var getExtraTyped = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.b2;
  var setExtra = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.k2;
  var checkIndexOverflow = kotlin_kotlin.$_$.x3;
  var lazy_0 = kotlin_kotlin.$_$.xd;
  var binarySearch = kotlin_kotlin.$_$.w3;
  var first = kotlin_kotlin.$_$.d5;
  var last = kotlin_kotlin.$_$.t5;
  var getOrNull = kotlin_kotlin.$_$.h5;
  var emptyList = kotlin_kotlin.$_$.u4;
  var listOf = kotlin_kotlin.$_$.w5;
  var hashCode = kotlin_kotlin.$_$.e9;
  var getBooleanHashCode = kotlin_kotlin.$_$.z8;
  var primitiveArrayConcat = kotlin_kotlin.$_$.c;
  var NotImplementedError = kotlin_kotlin.$_$.jd;
  var sort = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.o2;
  var contentEquals_0 = kotlin_kotlin.$_$.d4;
  var contentHashCode_0 = kotlin_kotlin.$_$.h4;
  var contentToString = kotlin_kotlin.$_$.j4;
  var until = kotlin_kotlin.$_$.wa;
  var Collection = kotlin_kotlin.$_$.k3;
  var isAlmostZero = kotlin_com_soywiz_korge_korlibs_math_core.$_$.o;
  var listOf_0 = kotlin_kotlin.$_$.x5;
  var binarySearch_0 = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.q1;
  var _BSearchResult___get_index__impl__b5kraz = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.j;
  var _BSearchResult___get_nearIndex__impl__s8nq43 = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.k;
  var last_0 = kotlin_kotlin.$_$.u5;
  var joinToString = kotlin_kotlin.$_$.l5;
  var getCyclic_0 = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.z1;
  var PropertyThis = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.w;
  var extendThrowable = kotlin_kotlin.$_$.w8;
  var captureStack = kotlin_kotlin.$_$.o8;
  var FastArrayList_init_$Create$ = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.c;
  var fastArrayListOf = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.u1;
  var umod_0 = kotlin_com_soywiz_korge_korlibs_math_core.$_$.e1;
  var insert2 = kotlin_com_soywiz_korge_korlibs_memory.$_$.z2;
  var insert = kotlin_com_soywiz_korge_korlibs_memory.$_$.g3;
  var fillArrayVal = kotlin_kotlin.$_$.x8;
  var swap = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.p2;
  var rotateRight = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.j2;
  var getStringHashCode = kotlin_kotlin.$_$.d9;
  var FastIntMap = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.z;
  var get_size = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.n2;
  var IntCompanionObject_instance = kotlin_kotlin.$_$.x2;
  var Pool = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.i1;
  var SortOps = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.n1;
  var Pool_init_$Create$ = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.g;
  var genericSort = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.x1;
  var step = kotlin_kotlin.$_$.ua;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ce;
  var clamp_1 = kotlin_com_soywiz_korge_korlibs_math_core.$_$.e;
  var numberToDouble = kotlin_kotlin.$_$.t9;
  var hasExtra = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.e2;
  var IntArrayList_init_$Create$ = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.d;
  var DoubleArrayList_init_$Create$ = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.a;
  var isCharSequence = kotlin_kotlin.$_$.j9;
  var trimEnd = kotlin_kotlin.$_$.sc;
  var lastOrNull = kotlin_kotlin.$_$.r5;
  var KMutableProperty1 = kotlin_kotlin.$_$.za;
  var Property = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.x;
  var ConcurrentPool = kotlin_com_soywiz_korge_korlibs_datastructure.$_$.t;
  var numberToLong = kotlin_kotlin.$_$.v9;
  var roundDecimalPlaces_0 = kotlin_com_soywiz_korge_korlibs_math_core.$_$.v;
  var isAlmostEquals_0 = kotlin_com_soywiz_korge_korlibs_math_core.$_$.m;
  var toIntOrNull = kotlin_kotlin.$_$.mc;
  var lines = kotlin_kotlin.$_$.tb;
  var charSequenceLength = kotlin_kotlin.$_$.s8;
  var Regex_init_$Create$ = kotlin_kotlin.$_$.x;
  var substringAfterLast = kotlin_kotlin.$_$.dc;
  var substringBeforeLast = kotlin_kotlin.$_$.fc;
  var firstOrNull = kotlin_kotlin.$_$.b5;
  var charArrayOf = kotlin_kotlin.$_$.p8;
  var trimEnd_0 = kotlin_kotlin.$_$.tc;
  var trim = kotlin_kotlin.$_$.wc;
  var split = kotlin_kotlin.$_$.ac;
  var contains = kotlin_kotlin.$_$.ib;
  var startsWith = kotlin_kotlin.$_$.bc;
  var arrayListOf = kotlin_kotlin.$_$.v3;
  var stackTraceToString = kotlin_kotlin.$_$.ae;
  var Exception_init_$Create$ = kotlin_kotlin.$_$.f1;
  //endregion
  //region block: pre-declaration
  setMetadataFor(TimedTask, 'TimedTask', classMeta, VOID, [Comparable, Closeable]);
  setMetadataFor(BaseEventLoop, 'BaseEventLoop', classMeta, VOID, [Closeable]);
  setMetadataFor(SyncEventLoop, 'SyncEventLoop', classMeta, BaseEventLoop, VOID, SyncEventLoop);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Ratio, 'Ratio', classMeta, VOID, [Comparable]);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  function invoke(it) {
    return this.r1e(it);
  }
  function invoke_0(it) {
    return _Ratio___init__impl__9mwvn2(this.r1e(Ratio__toFloat_impl_1ftup5(it)));
  }
  setMetadataFor(Easing, 'Easing', interfaceMeta);
  setMetadataFor(EasingCubic, 'EasingCubic', classMeta, VOID, [Easing]);
  setMetadataFor(Easings, 'Easings', classMeta, Enum, [Enum, Easing]);
  setMetadataFor(Easings$SMOOTH, 'SMOOTH', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_ELASTIC, 'EASE_IN_ELASTIC', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_ELASTIC, 'EASE_OUT_ELASTIC', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_BOUNCE, 'EASE_OUT_BOUNCE', classMeta, Easings);
  setMetadataFor(Easings$LINEAR, 'LINEAR', classMeta, Easings);
  setMetadataFor(Easings$EASE, 'EASE', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN, 'EASE_IN', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT, 'EASE_OUT', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT, 'EASE_IN_OUT', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OLD, 'EASE_IN_OLD', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_OLD, 'EASE_OUT_OLD', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_OLD, 'EASE_IN_OUT_OLD', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_IN_OLD, 'EASE_OUT_IN_OLD', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_BACK, 'EASE_IN_BACK', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_BACK, 'EASE_OUT_BACK', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_BACK, 'EASE_IN_OUT_BACK', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_IN_BACK, 'EASE_OUT_IN_BACK', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_ELASTIC, 'EASE_IN_OUT_ELASTIC', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_IN_ELASTIC, 'EASE_OUT_IN_ELASTIC', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_BOUNCE, 'EASE_IN_BOUNCE', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_BOUNCE, 'EASE_IN_OUT_BOUNCE', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_IN_BOUNCE, 'EASE_OUT_IN_BOUNCE', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_QUAD, 'EASE_IN_QUAD', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_QUAD, 'EASE_OUT_QUAD', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_QUAD, 'EASE_IN_OUT_QUAD', classMeta, Easings);
  setMetadataFor(Easings$EASE_SINE, 'EASE_SINE', classMeta, Easings);
  setMetadataFor(Easings$EASE_CLAMP_START, 'EASE_CLAMP_START', classMeta, Easings);
  setMetadataFor(Easings$EASE_CLAMP_END, 'EASE_CLAMP_END', classMeta, Easings);
  setMetadataFor(Easings$EASE_CLAMP_MIDDLE, 'EASE_CLAMP_MIDDLE', classMeta, Easings);
  function isAlmostEquals$default(other, epsilon, $super) {
    epsilon = epsilon === VOID ? 1.0E-6 : epsilon;
    return $super === VOID ? this.w1h(other, epsilon) : $super.w1h.call(this, other, epsilon);
  }
  setMetadataFor(IsAlmostEquals, 'IsAlmostEquals', interfaceMeta);
  setMetadataFor(IntSegmentSet, 'IntSegmentSet', classMeta, VOID, VOID, IntSegmentSet);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(Anchor2D, 'Anchor2D', classMeta);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(Angle, 'Angle', classMeta, VOID, [Comparable, IsAlmostEquals]);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(BoundsBuilder, 'BoundsBuilder', classMeta);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(Line2D, 'Line2D', classMeta, VOID, VOID, Line2D_init_$Create$);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  setMetadataFor(MarginInt, 'MarginInt', classMeta);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor(Matrix, 'Matrix', classMeta, VOID, [IsAlmostEquals]);
  setMetadataFor(MatrixType, 'MatrixType', classMeta, Enum);
  setMetadataFor(Companion_7, 'Companion', objectMeta);
  setMetadataFor(MatrixTransform, 'MatrixTransform', classMeta, VOID, [IsAlmostEquals]);
  setMetadataFor(Companion_8, 'Companion', objectMeta);
  setMetadataFor(Matrix4, 'Matrix4', classMeta, VOID, VOID, Matrix4_init_$Create$_0);
  setMetadataFor(Companion_9, 'Companion', objectMeta);
  setMetadataFor(Orientation, 'Orientation', classMeta, Enum);
  setMetadataFor(Companion_10, 'Companion', objectMeta);
  setMetadataFor(RectangleD, 'RectangleD', classMeta, VOID, [IsAlmostEquals]);
  setMetadataFor(Companion_11, 'Companion', objectMeta);
  setMetadataFor(RectangleI, 'RectangleI', classMeta, VOID, VOID, RectangleI_init_$Create$);
  setMetadataFor(Companion_12, 'Companion', objectMeta);
  setMetadataFor(Scale, 'Scale', classMeta, VOID, VOID, Scale_init_$Create$);
  setMetadataFor(Companion_13, 'Companion', objectMeta);
  setMetadataFor(ScaleMode, 'ScaleMode', classMeta);
  setMetadataFor(Companion_14, 'Companion', objectMeta);
  setMetadataFor(Size2D, 'Size2D', classMeta, VOID, VOID, Size2D_init_$Create$);
  setMetadataFor(SizeInt, 'SizeInt', classMeta, VOID, VOID, SizeInt_init_$Create$);
  setMetadataFor(Companion_15, 'Companion', objectMeta);
  setMetadataFor(Vector2D, 'Vector2D', classMeta, VOID, [IsAlmostEquals], Vector2D_init_$Create$_1);
  setMetadataFor(Companion_16, 'Companion', objectMeta);
  setMetadataFor(Vector2F, 'Vector2F', classMeta, VOID, VOID, Vector2F_init_$Create$_1);
  setMetadataFor(Companion_17, 'Companion', objectMeta);
  setMetadataFor(Vector4F, 'Vector4F', classMeta);
  setMetadataFor(Companion_18, 'Companion', objectMeta);
  setMetadataFor(Vector3F, 'Vector3F', classMeta);
  setMetadataFor(Companion_19, 'Companion', objectMeta);
  setMetadataFor(Vector2I, 'Vector2I', classMeta, VOID, VOID, Vector2I_init_$Create$);
  function isEmpty() {
    return this.l() === 0;
  }
  function isNotEmpty() {
    return !(this.l() === 0);
  }
  function isAlmostEquals_1(other, epsilon) {
    if (!(this.l() === other.l()))
      return false;
    if (!(this.i1u() === other.i1u()))
      return false;
    var inductionVariable = 0;
    var last = this.i1u();
    if (inductionVariable < last)
      do {
        var dim = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var inductionVariable_0 = 0;
        var last_0 = this.l();
        if (inductionVariable_0 < last_0)
          do {
            var n = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            if (!isAlmostEquals(this.f10(n, dim), other.f10(n, dim), epsilon))
              return false;
          }
           while (inductionVariable_0 < last_0);
      }
       while (inductionVariable < last);
    return true;
  }
  setMetadataFor(DoubleVectorList, 'DoubleVectorList', interfaceMeta, VOID, [IsAlmostEquals]);
  function get_dimensions() {
    return 2;
  }
  function getX(index) {
    return this.f10(index, 0);
  }
  function getY(index) {
    return this.f10(index, 1);
  }
  function get(index) {
    return new Vector2D(this.f10(index, 0), this.f10(index, 1));
  }
  function getComponentList(component, out) {
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out[n] = this.f10(n, component);
      }
       while (inductionVariable < last);
    return out;
  }
  function getComponentList$default(component, out, $super) {
    out = out === VOID ? new Float64Array(this.l()) : out;
    return $super === VOID ? this.l1u(component, out) : getComponentList(component, out);
  }
  function get_first() {
    return this.b1(0);
  }
  function get_last() {
    return this.b1(this.l() - 1 | 0);
  }
  function clone(out) {
    out.r1u(this);
    return out;
  }
  function clone$default(out, $super) {
    out = out === VOID ? new PointArrayList(this.l()) : out;
    return $super === VOID ? this.n1u(out) : clone(out);
  }
  setMetadataFor(PointList, 'PointList', interfaceMeta, VOID, [DoubleVectorList]);
  setMetadataFor(Companion_20, 'Companion', objectMeta);
  setMetadataFor(PointArrayList, 'PointArrayList', classMeta, VOID, [PointList], PointArrayList);
  setMetadataFor(DoubleVectorArrayList, 'DoubleVectorArrayList', classMeta, VOID, [DoubleVectorList]);
  setMetadataFor(Companion_21, 'Companion', objectMeta);
  setMetadataFor(PointIntArrayList, 'PointIntArrayList', classMeta, VOID, VOID, PointIntArrayList);
  setMetadataFor(StrokePointsMode, 'StrokePointsMode', classMeta, Enum);
  setMetadataFor(StrokePointsBuilder, 'StrokePointsBuilder', classMeta);
  setMetadataFor(CurveInfo, 'CurveInfo', classMeta);
  setMetadataFor(Curves, 'Curves', classMeta);
  setMetadataFor(ProjectedPoint, 'ProjectedPoint', classMeta, VOID, [IsAlmostEquals], ProjectedPoint);
  setMetadataFor(Extrema, 'Extrema', classMeta);
  setMetadataFor(Companion_22, 'Companion', objectMeta);
  setMetadataFor(Bezier, 'Bezier', classMeta, VOID, [IsAlmostEquals], Bezier_init_$Create$);
  setMetadataFor(Companion_23, 'Companion', objectMeta);
  setMetadataFor(SubBezier, 'SubBezier', classMeta);
  setMetadataFor(ClosestResult, 'ClosestResult', classMeta);
  setMetadataFor(Estimation, 'Estimation', classMeta, VOID, VOID, Estimation);
  setMetadataFor(CurveLUT, 'CurveLUT', classMeta);
  setMetadataFor(Arc, 'Arc', objectMeta);
  setMetadataFor(Companion_24, 'Companion', objectMeta);
  setMetadataFor(ImageDoNotFitException, 'ImageDoNotFitException', classMeta, Error);
  setMetadataFor(BinPacker, 'BinPacker', classMeta);
  setMetadataFor(MaxRects, 'MaxRects', classMeta);
  setMetadataFor(Companion_25, 'Companion', objectMeta);
  setMetadataFor(HitTestDirection, 'HitTestDirection', classMeta, Enum);
  setMetadataFor(Convex, 'Convex', objectMeta);
  setMetadataFor(AbstractShape2D, 'AbstractShape2D', classMeta);
  setMetadataFor(Companion_26, 'Companion', objectMeta);
  setMetadataFor(SliceRotation, 'SliceRotation', classMeta, Enum);
  setMetadataFor(Companion_27, 'Companion', objectMeta);
  setMetadataFor(SliceOrientation, 'SliceOrientation', classMeta);
  function x(index) {
    var tmp;
    switch (index) {
      case 0:
        tmp = this.w23();
        break;
      case 1:
        tmp = this.y23();
        break;
      case 2:
        tmp = this.a24();
        break;
      case 3:
        tmp = this.c24();
        break;
      default:
        tmp = NaN;
        break;
    }
    return tmp;
  }
  function y(index) {
    var tmp;
    switch (index) {
      case 0:
        tmp = this.x23();
        break;
      case 1:
        tmp = this.z23();
        break;
      case 2:
        tmp = this.b24();
        break;
      case 3:
        tmp = this.d24();
        break;
      default:
        tmp = NaN;
        break;
    }
    return tmp;
  }
  setMetadataFor(SliceCoords, 'SliceCoords', interfaceMeta);
  function get_frameOffsetX() {
    return this.k23().x1k_1;
  }
  function get_frameOffsetY() {
    return this.k23().u1k_1;
  }
  function get_frameWidth() {
    return this.c10() + this.k23().z1k() | 0;
  }
  function get_frameHeight() {
    return this.d10() + this.k23().a1l() | 0;
  }
  setMetadataFor(SliceCoordsWithBase, 'SliceCoordsWithBase', interfaceMeta, VOID, [SliceCoords]);
  function get_left() {
    return this.j23().u1j();
  }
  function get_top() {
    return this.j23().w1j();
  }
  function get_right() {
    return this.j23().v1j();
  }
  function get_bottom() {
    return this.j23().x1j();
  }
  function get_area() {
    return this.j23().u1r();
  }
  setMetadataFor(SliceCoordsWithBaseAndRect, 'SliceCoordsWithBaseAndRect', interfaceMeta, VOID, [SliceCoordsWithBase]);
  setMetadataFor(RectSlice, 'RectSlice', classMeta, VOID, [SliceCoordsWithBaseAndRect]);
  setMetadataFor(Companion_28, 'Companion', objectMeta);
  setMetadataFor(SliceCoordsImpl, 'SliceCoordsImpl', classMeta, VOID, [SliceCoordsWithBase]);
  setMetadataFor(RectCoords, 'RectCoords', classMeta, VOID, [SliceCoords]);
  setMetadataFor(Bucket, 'Bucket', classMeta, VOID, VOID, Bucket);
  setMetadataFor(Buckets, 'Buckets', classMeta);
  setMetadataFor(AllBuckets, 'AllBuckets', classMeta, VOID, VOID, AllBuckets);
  setMetadataFor(XWithWind, 'XWithWind', classMeta, VOID, VOID, XWithWind);
  setMetadataFor(IntArrayListSort, 'IntArrayListSort', objectMeta, SortOps);
  setMetadataFor(RastScale, 'RastScale', classMeta, VOID, VOID, RastScale);
  setMetadataFor(PolygonScanline, 'PolygonScanline', classMeta, RastScale, VOID, PolygonScanline);
  setMetadataFor(Companion_29, 'Companion', objectMeta);
  setMetadataFor(Companion_30, 'Companion', objectMeta);
  setMetadataFor(MEdge, 'MEdge', classMeta, VOID, VOID, MEdge);
  setMetadataFor(EdgePoint, 'EdgePoint', classMeta, Enum);
  setMetadataFor(StrokeToFill, 'StrokeToFill', classMeta, VOID, VOID, StrokeToFill);
  function quadTo(c, a) {
    // Inline function 'korlibs.math.geom.bezier.Companion.quadToCubic' call
    var this_0 = Companion_getInstance_22();
    var x0 = this.h29().m1e_1;
    var y0 = this.h29().n1e_1;
    var xc = c.m1e_1;
    var yc = c.n1e_1;
    var x1 = a.m1e_1;
    var y1 = a.n1e_1;
    // Inline function 'kotlin.contracts.contract' call
    var cx1 = this_0.r1y(x0, xc);
    var cy1 = this_0.r1y(y0, yc);
    var cx2 = this_0.s1y(xc, x1);
    var cy2 = this_0.s1y(yc, y1);
    this.g20(new Vector2D(cx1, cy1), new Vector2D(cx2, cy2), new Vector2D(x1, y1));
  }
  function line(p0, p1) {
    this.f20(p0);
    this.s20(p1);
  }
  function isEmpty_0() {
    return this.g29() === 0;
  }
  function isNotEmpty_0() {
    return !(this.g29() === 0);
  }
  function rect(rect) {
    return this.l29(rect.o1j_1, rect.p1j_1, rect.q1j_1, rect.r1j_1);
  }
  function rect_0(pos, size) {
    this.l29(pos.m1e_1, pos.n1e_1, size.e1q_1, size.f1q_1);
  }
  function rect_1(x, y, width, height) {
    this.f20(new Vector2D(x, y));
    this.s20(new Vector2D(x + width, y));
    this.s20(new Vector2D(x + width, y + height));
    this.s20(new Vector2D(x, y + height));
    this.x18();
  }
  function arc(center, r, start, end, counterclockwise) {
    return Arc_instance.e20(this, center, numberToDouble(r), start, end, counterclockwise);
  }
  function arc$default(center, r, start, end, counterclockwise, $super) {
    counterclockwise = counterclockwise === VOID ? false : counterclockwise;
    var tmp;
    if ($super === VOID) {
      this.m29(center, r, start, end, counterclockwise);
      tmp = Unit_instance;
    } else {
      arc(center, r, start, end, counterclockwise);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function circle(center, radius) {
    return this.n29(center, radius, Companion_getInstance_2().x1i_1, Companion_getInstance_2().b1j_1);
  }
  function rMoveTo(delta) {
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    var this_0 = this.h29();
    var tmp$ret$0 = new Vector2D(this_0.m1e_1 + delta.m1e_1, this_0.n1e_1 + delta.n1e_1);
    return this.f20(tmp$ret$0);
  }
  function rLineTo(delta) {
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    var this_0 = this.h29();
    var tmp$ret$0 = new Vector2D(this_0.m1e_1 + delta.m1e_1, this_0.n1e_1 + delta.n1e_1);
    return this.s20(tmp$ret$0);
  }
  function transformed(m) {
    var im = m.q1m();
    var parent = this;
    return new VectorBuilder$transformed$1(parent, im, m);
  }
  function transformed_0(m, block) {
    return block(this.f1r(m));
  }
  setMetadataFor(VectorBuilder, 'VectorBuilder', interfaceMeta);
  setMetadataFor(VectorBuilder$transformed$1, VOID, classMeta, VOID, [VectorBuilder]);
  setMetadataFor(Companion_31, 'Companion', objectMeta);
  function close() {
    return Unit_instance;
  }
  function moveTo(p) {
    return Unit_instance;
  }
  function lineTo(p) {
    return Unit_instance;
  }
  function quadTo_0(c, a) {
    return Unit_instance;
  }
  function cubicTo(c1, c2, a) {
    return Unit_instance;
  }
  setMetadataFor(Visitor, 'Visitor', interfaceMeta);
  setMetadataFor(Command, 'Command', objectMeta);
  setMetadataFor(VectorPath, 'VectorPath', classMeta, AbstractShape2D, [AbstractShape2D, VectorBuilder], VectorPath);
  setMetadataFor(with$1, VOID, classMeta, VOID, [Visitor]);
  setMetadataFor(Companion_32, 'Companion', objectMeta);
  setMetadataFor(Winding, 'Winding', classMeta, Enum);
  setMetadataFor(Companion_33, 'Companion', objectMeta);
  setMetadataFor(LineJoin, 'LineJoin', classMeta, Enum);
  setMetadataFor(Companion_34, 'Companion', objectMeta);
  setMetadataFor(LineCap, 'LineCap', classMeta, Enum);
  setMetadataFor(LineScaleMode, 'LineScaleMode', classMeta, Enum);
  setMetadataFor(Companion_35, 'Companion', objectMeta);
  setMetadataFor(StrokeInfo, 'StrokeInfo', classMeta, VOID, VOID, StrokeInfo);
  setMetadataFor(Companion_36, 'Companion', objectMeta);
  setMetadataFor(MPoint, 'MPoint', classMeta, VOID, [Comparable, IsAlmostEquals]);
  setMetadataFor(Companion_37, 'Companion', objectMeta);
  setMetadataFor(MMatrix, 'MMatrix', classMeta, VOID, VOID, MMatrix);
  setMetadataFor(Companion_38, 'Companion', objectMeta);
  setMetadataFor(MLine, 'MLine', classMeta, VOID, VOID, MLine_init_$Create$);
  setMetadataFor(Companion_39, 'Companion', objectMeta);
  setMetadataFor(MRectangle, 'MRectangle', classMeta, VOID, [IsAlmostEquals]);
  setMetadataFor(Companion_40, 'Companion', objectMeta);
  setMetadataFor(Companion_41, 'Companion', objectMeta);
  setMetadataFor(Circle, 'Circle', classMeta, AbstractShape2D);
  setMetadataFor(Entry, 'Entry', classMeta);
  setMetadataFor(Companion_42, 'Companion', objectMeta);
  setMetadataFor(JSStackTrace, 'JSStackTrace', classMeta);
  //endregion
  function TimedTask(eventLoop, now, time, interval, callback) {
    this.d1d_1 = eventLoop;
    this.e1d_1 = now;
    this.f1d_1 = time;
    this.g1d_1 = interval;
    this.h1d_1 = callback;
  }
  protoOf(TimedTask).i1d = function (value) {
    this.e1d_1 = Duration__minus_impl_q5cfm7(value, this.f1d_1);
  };
  protoOf(TimedTask).j1d = function () {
    return Duration__plus_impl_yu9v8f(this.e1d_1, this.f1d_1);
  };
  protoOf(TimedTask).k1d = function (other) {
    return Duration__compareTo_impl_pchp0f(this.j1d(), other.j1d());
  };
  protoOf(TimedTask).d = function (other) {
    return this.k1d(other instanceof TimedTask ? other : THROW_CCE());
  };
  protoOf(TimedTask).x18 = function () {
    this.g1d_1 = false;
    this.d1d_1.v1d_1.v17(this);
  };
  function _get_now__e6dlkr($this) {
    return $this.t1d_1().ai_1;
  }
  function _queueAfter($this, time, interval, task) {
    // Inline function 'korlibs.concurrent.lock.NonRecursiveLock.invoke' call
    $this.q1d_1;
    // Inline function 'korlibs.datastructure.event.SyncEventLoop._queueAfter.<anonymous>' call
    var task_0 = new TimedTask($this, _get_now__e6dlkr($this), time, interval, task);
    if ($this.r1d_1) {
      $this.v1d_1.k13(task_0);
    } else {
      Console_getInstance().f1a(['WARNING: QUEUED TASK time=' + new Duration(time) + ' interval=' + interval + ' without running']);
    }
    $this.q1d_1.xx();
    return task_0;
  }
  function SyncEventLoop$nowProvider$lambda(this$0) {
    return function () {
      return new Duration(ValueTimeMark__elapsedNow_impl_eonqvs(this$0.s1d_1));
    };
  }
  function SyncEventLoop$timedTasks$lambda(a, b) {
    return a.k1d(b);
  }
  function SyncEventLoop$uncatchedExceptionHandler$lambda(it) {
    printStackTrace(it);
    return Unit_instance;
  }
  function SyncEventLoop(precise, immediateRun) {
    precise = precise === VOID ? false : precise;
    immediateRun = immediateRun === VOID ? false : immediateRun;
    BaseEventLoop.call(this);
    this.m1d_1 = precise;
    this.n1d_1 = immediateRun;
    this.o1d_1 = new SyncPauseable();
    this.p1d_1 = paused$factory(this.o1d_1);
    this.q1d_1 = new NonRecursiveLock();
    this.r1d_1 = true;
    this.s1d_1 = Monotonic_instance.y9();
    var tmp = this;
    tmp.t1d_1 = SyncEventLoop$nowProvider$lambda(this);
    this.u1d_1 = ArrayDeque_init_$Create$();
    var tmp_0 = this;
    var tmp_1 = Companion_instance;
    tmp_0.v1d_1 = tmp_1.m17(VOID, SyncEventLoop$timedTasks$lambda);
    var tmp_2 = this;
    tmp_2.w1d_1 = SyncEventLoop$uncatchedExceptionHandler$lambda;
    this.x1d_1 = null;
  }
  protoOf(SyncEventLoop).y1d = function (task) {
    // Inline function 'korlibs.concurrent.lock.NonRecursiveLock.invoke' call
    this.q1d_1;
    this.u1d_1.ie(task);
    this.q1d_1.xx();
  };
  protoOf(SyncEventLoop).z1d = function (time, task) {
    return _queueAfter(this, time, false, task);
  };
  protoOf(SyncEventLoop).x18 = function () {
    var oldImmediateRun = this.n1d_1;
    try {
      this.n1d_1 = true;
      this.a1e();
      this.r1d_1 = false;
    }finally {
      this.n1d_1 = oldImmediateRun;
    }
  };
  protoOf(SyncEventLoop).b1e = function (task) {
    if (this.n1d_1)
      return true;
    return Duration__compareTo_impl_pchp0f(_get_now__e6dlkr(this), this.v1d_1.s17().j1d()) >= 0;
  };
  protoOf(SyncEventLoop).c1e = function (runTimers) {
    var count = 0;
    while (this.d1e(runTimers)) {
      count = count + 1 | 0;
    }
    return count;
  };
  protoOf(SyncEventLoop).a1e = function (runTimers, $super) {
    runTimers = runTimers === VOID ? true : runTimers;
    return $super === VOID ? this.c1e(runTimers) : $super.c1e.call(this, runTimers);
  };
  protoOf(SyncEventLoop).d1e = function (runTimers) {
    // Inline function 'korlibs.concurrent.lock.NonRecursiveLock.invoke' call
    this.q1d_1;
    // Inline function 'korlibs.datastructure.event.SyncEventLoop.runAvailableNextTask.<anonymous>' call
    var tmp;
    if (runTimers) {
      var tmp_0;
      var tmp_1;
      // Inline function 'kotlin.collections.isNotEmpty' call
      if (!this.v1d_1.x()) {
        tmp_1 = this.b1e(this.v1d_1.s17());
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp_0 = this.v1d_1.t17();
      } else {
        tmp_0 = null;
      }
      tmp = tmp_0;
    } else {
      tmp = null;
    }
    var timedTask = tmp;
    if (!(timedTask == null)) {
      // Inline function 'korlibs.datastructure.event.SyncEventLoop.runCatchingExceptions' call
      try {
        // Inline function 'kotlin.run' call
        // Inline function 'kotlin.contracts.contract' call
        timedTask.h1d_1();
      } catch ($p) {
        if ($p instanceof Error) {
          var e = $p;
          this.w1d_1(e);
        } else {
          throw $p;
        }
      }
      if (timedTask.g1d_1 ? !this.n1d_1 : false) {
        timedTask.i1d(maxOf(new Duration(Duration__plus_impl_yu9v8f(timedTask.j1d(), timedTask.f1d_1)), new Duration(_get_now__e6dlkr(this))).ai_1);
        this.v1d_1.k13(timedTask);
      }
    }
    // Inline function 'korlibs.concurrent.lock.NonRecursiveLock.invoke' call
    this.q1d_1;
    // Inline function 'korlibs.datastructure.event.SyncEventLoop.runAvailableNextTask.<anonymous>' call
    var tmp_2;
    // Inline function 'kotlin.collections.isNotEmpty' call
    if (!this.u1d_1.x()) {
      tmp_2 = this.u1d_1.je();
    } else {
      tmp_2 = null;
    }
    var task = tmp_2;
    // Inline function 'korlibs.datastructure.event.SyncEventLoop.runCatchingExceptions' call
    try {
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      if (task == null)
        null;
      else
        task();
    } catch ($p) {
      if ($p instanceof Error) {
        var e_0 = $p;
        this.w1d_1(e_0);
      } else {
        throw $p;
      }
    }
    return !(task == null) ? true : !(timedTask == null);
  };
  function BaseEventLoop() {
    this.e1e_1 = new Lock();
  }
  function paused$factory($b0) {
    return getPropertyCallableRef('paused', 0, KMutableProperty0, function () {
      return $b0.z18_1;
    }, function (value) {
      return $b0.a19(value);
    });
  }
  var MINUS_ZERO_D;
  var MINUS_ZERO_F;
  function normalizeZero(_this__u8e3s4) {
    return _this__u8e3s4 === MINUS_ZERO_D ? 0.0 : _this__u8e3s4;
  }
  function min(a, b, c, d) {
    // Inline function 'kotlin.math.min' call
    // Inline function 'kotlin.math.min' call
    // Inline function 'kotlin.math.min' call
    var a_0 = Math.min(a, b);
    var a_1 = Math.min(a_0, c);
    return Math.min(a_1, d);
  }
  function max(a, b, c, d) {
    // Inline function 'kotlin.math.max' call
    // Inline function 'kotlin.math.max' call
    // Inline function 'kotlin.math.max' call
    var a_0 = Math.max(a, b);
    var a_1 = Math.max(a_0, c);
    return Math.max(a_1, d);
  }
  function floorCeil(v) {
    var tmp;
    if (v < 0.0) {
      // Inline function 'kotlin.math.ceil' call
      tmp = Math.ceil(v);
    } else {
      // Inline function 'kotlin.math.floor' call
      tmp = Math.floor(v);
    }
    return tmp;
  }
  function min_0(a, b, c, d, e) {
    // Inline function 'kotlin.math.min' call
    // Inline function 'kotlin.math.min' call
    // Inline function 'kotlin.math.min' call
    // Inline function 'kotlin.math.min' call
    var a_0 = Math.min(a, b);
    var a_1 = Math.min(a_0, c);
    var a_2 = Math.min(a_1, d);
    return Math.min(a_2, e);
  }
  function max_0(a, b, c, d, e) {
    // Inline function 'kotlin.math.max' call
    // Inline function 'kotlin.math.max' call
    // Inline function 'kotlin.math.max' call
    // Inline function 'kotlin.math.max' call
    var a_0 = Math.max(a, b);
    var a_1 = Math.max(a_0, c);
    var a_2 = Math.max(a_1, d);
    return Math.max(a_2, e);
  }
  function nextMultipleOf(_this__u8e3s4, multiple) {
    return isMultipleOf(_this__u8e3s4, multiple) ? _this__u8e3s4 : imul((_this__u8e3s4 / multiple | 0) + 1 | 0, multiple);
  }
  function isMultipleOf(_this__u8e3s4, multiple) {
    return multiple === 0 ? true : (_this__u8e3s4 % multiple | 0) === 0;
  }
  function _Ratio___init__impl__9mwvn2(value) {
    return value;
  }
  function _Ratio___get_value__impl__57cli6($this) {
    return $this;
  }
  function _Ratio___init__impl__9mwvn2_0(ratio) {
    return _Ratio___init__impl__9mwvn2(ratio);
  }
  function Ratio__toFloat_impl_1ftup5($this) {
    return _Ratio___get_value__impl__57cli6($this);
  }
  function Ratio__toDouble_impl_o7epze($this) {
    return _Ratio___get_value__impl__57cli6($this);
  }
  function _Ratio___init__impl__9mwvn2_1(value, maximum) {
    return _Ratio___init__impl__9mwvn2(value / maximum);
  }
  function Ratio__plus_impl_pm43pk($this, that) {
    return _Ratio___init__impl__9mwvn2(_Ratio___get_value__impl__57cli6($this) + _Ratio___get_value__impl__57cli6(that));
  }
  function Ratio__minus_impl_s1uwyg($this, that) {
    return _Ratio___init__impl__9mwvn2(_Ratio___get_value__impl__57cli6($this) - _Ratio___get_value__impl__57cli6(that));
  }
  function Ratio__times_impl_ucdh7y($this, that) {
    return _Ratio___init__impl__9mwvn2(_Ratio___get_value__impl__57cli6($this) * _Ratio___get_value__impl__57cli6(that));
  }
  function Ratio__times_impl_ucdh7y_0($this, that) {
    return _Ratio___get_value__impl__57cli6($this) * that;
  }
  function _Ratio___get_absoluteValue__impl__92ozif($this) {
    // Inline function 'kotlin.math.absoluteValue' call
    var this_0 = _Ratio___get_value__impl__57cli6($this);
    var tmp$ret$0 = Math.abs(this_0);
    return _Ratio___init__impl__9mwvn2(tmp$ret$0);
  }
  function Ratio__compareTo_impl_z2ienc($this, other) {
    return compareTo(_Ratio___get_value__impl__57cli6($this), _Ratio___get_value__impl__57cli6(other));
  }
  function Ratio__compareTo_impl_z2ienc_0($this, other) {
    return Ratio__compareTo_impl_z2ienc($this.f1e_1, other instanceof Ratio ? other.f1e_1 : THROW_CCE());
  }
  function Ratio__toString_impl_mfgt3e($this) {
    return '' + _Ratio___get_value__impl__57cli6($this);
  }
  function Companion() {
    Companion_instance_0 = this;
    this.g1e_1 = _Ratio___init__impl__9mwvn2(0.0);
    this.h1e_1 = _Ratio___init__impl__9mwvn2(0.25);
    this.i1e_1 = _Ratio___init__impl__9mwvn2(0.5);
    this.j1e_1 = _Ratio___init__impl__9mwvn2(1.0);
    var tmp = this;
    tmp.k1e_1 = _Ratio___init__impl__9mwvn2_0(NaN);
  }
  var Companion_instance_0;
  function Companion_getInstance() {
    if (Companion_instance_0 == null)
      new Companion();
    return Companion_instance_0;
  }
  function Ratio__hashCode_impl_quhc2v($this) {
    return getNumberHashCode($this);
  }
  function Ratio__equals_impl_mfc2gt($this, other) {
    if (!(other instanceof Ratio))
      return false;
    var tmp0_other_with_cast = other instanceof Ratio ? other.f1e_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function Ratio(value) {
    Companion_getInstance();
    this.f1e_1 = value;
  }
  protoOf(Ratio).l1e = function (other) {
    return Ratio__compareTo_impl_z2ienc(this.f1e_1, other);
  };
  protoOf(Ratio).d = function (other) {
    return Ratio__compareTo_impl_z2ienc_0(this, other);
  };
  protoOf(Ratio).toString = function () {
    return Ratio__toString_impl_mfgt3e(this.f1e_1);
  };
  protoOf(Ratio).hashCode = function () {
    return Ratio__hashCode_impl_quhc2v(this.f1e_1);
  };
  protoOf(Ratio).equals = function (other) {
    return Ratio__equals_impl_mfc2gt(this.f1e_1, other);
  };
  function toRatio(_this__u8e3s4) {
    return _this__u8e3s4;
  }
  function convertRange_0(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
    return _Ratio___init__impl__9mwvn2(convertRange(Ratio__toDouble_impl_o7epze(_this__u8e3s4), Ratio__toDouble_impl_o7epze(srcMin), Ratio__toDouble_impl_o7epze(srcMax), Ratio__toDouble_impl_o7epze(dstMin), Ratio__toDouble_impl_o7epze(dstMax)));
  }
  function toRatio_0(_this__u8e3s4) {
    return _Ratio___init__impl__9mwvn2(_this__u8e3s4);
  }
  function isAlmostEquals_2(_this__u8e3s4, that, epsilon) {
    epsilon = epsilon === VOID ? _Ratio___init__impl__9mwvn2(1.0E-6) : epsilon;
    return isAlmostEquals(Ratio__toDouble_impl_o7epze(_this__u8e3s4), Ratio__toDouble_impl_o7epze(that), Ratio__toDouble_impl_o7epze(epsilon));
  }
  function interpolate(_this__u8e3s4, l, r) {
    return new Vector2D(interpolate_1(_this__u8e3s4, l.m1e_1, r.m1e_1), interpolate_1(_this__u8e3s4, l.n1e_1, r.n1e_1));
  }
  function get_niceStr(_this__u8e3s4) {
    return get_niceStr_0(Ratio__toDouble_impl_o7epze(_this__u8e3s4));
  }
  function interpolate_0(_this__u8e3s4, l, r) {
    return Ratio__plus_impl_pm43pk(l, Ratio__times_impl_ucdh7y(Ratio__minus_impl_s1uwyg(r, l), _this__u8e3s4));
  }
  function interpolate_1(_this__u8e3s4, l, r) {
    return l + (r - l) * Ratio__toDouble_impl_o7epze(_this__u8e3s4);
  }
  function interpolate_2(_this__u8e3s4, l, r) {
    return numberToInt(l + (r - l | 0) * Ratio__toDouble_impl_o7epze(_this__u8e3s4));
  }
  function toRatio_1(_this__u8e3s4) {
    return _Ratio___init__impl__9mwvn2_0(_this__u8e3s4);
  }
  function _get__ALL_LIST__dej8hi($this) {
    // Inline function 'kotlin.getValue' call
    var this_0 = $this.o1e_1;
    _ALL_LIST$factory();
    return this_0.e2();
  }
  function Easing$Companion$_ALL_LIST$delegate$lambda() {
    return toList(values());
  }
  function Easing$Companion$ALL$delegate$lambda() {
    // Inline function 'kotlin.collections.associateBy' call
    var this_0 = _get__ALL_LIST__dej8hi(Companion_getInstance_0());
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_0, 10)), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap_init_$Create$(capacity);
    var tmp0_iterator = this_0.s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      // Inline function 'korlibs.math.interpolation.Companion.ALL$delegate.<anonymous>.<anonymous>' call
      var tmp$ret$0 = element.s9_1;
      destination.a2(tmp$ret$0, element);
    }
    return destination;
  }
  function Companion_0() {
    Companion_instance_1 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.o1e_1 = lazy(tmp_0, Easing$Companion$_ALL_LIST$delegate$lambda);
    var tmp_1 = this;
    var tmp_2 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_1.p1e_1 = lazy(tmp_2, Easing$Companion$ALL$delegate$lambda);
  }
  protoOf(Companion_0).q1e = function () {
    return Easings_EASE_IN_OUT_QUAD_getInstance();
  };
  var Companion_instance_1;
  function Companion_getInstance_0() {
    if (Companion_instance_1 == null)
      new Companion_0();
    return Companion_instance_1;
  }
  function Easing() {
  }
  function EasingCubic(x1, y1, x2, y2, name) {
    name = name === VOID ? null : name;
    this.u1e_1 = x1;
    this.v1e_1 = y1;
    this.w1e_1 = x2;
    this.x1e_1 = y2;
    this.y1e_1 = name;
    this.z1e_1 = Bezier_init_$Create$_2(Vector2D_init_$Create$(0.0, 0.0), Vector2D_init_$Create$(clamp(this.u1e_1, 0.0, 1.0), this.v1e_1), Vector2D_init_$Create$(clamp(this.w1e_1, 0.0, 1.0), this.x1e_1), new Vector2D(1.0, 1.0));
  }
  protoOf(EasingCubic).toString = function () {
    var tmp0_elvis_lhs = this.y1e_1;
    return tmp0_elvis_lhs == null ? 'cubic-bezier(' + this.u1e_1 + ', ' + this.v1e_1 + ', ' + this.w1e_1 + ', ' + this.x1e_1 + ')' : tmp0_elvis_lhs;
  };
  protoOf(EasingCubic).r1e = function (it) {
    var pivotLeft = it < 0.0 ? it * 10.0 : 0.0;
    var pivotRight = it > 1.0 ? it * 10.0 : 1.0;
    var pivot = it;
    var lastX = 0.0;
    var lastY = 0.0;
    var steps = 0;
    var inductionVariable = 0;
    if (inductionVariable < 50)
      $l$loop_0: do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        steps = steps + 1 | 0;
        var res = this.z1e_1.l1f(toRatio_1(pivot));
        lastX = res.m1e_1;
        lastY = res.n1e_1;
        // Inline function 'kotlin.math.absoluteValue' call
        var this_0 = lastX - it;
        if (Math.abs(this_0) < 0.001)
          break $l$loop_0;
        if (it < lastX) {
          pivotRight = pivot;
          pivot = (pivotLeft + pivot) * 0.5;
        } else if (it > lastX) {
          pivotLeft = pivot;
          pivot = (pivotRight + pivot) * 0.5;
        } else {
          break $l$loop_0;
        }
      }
       while (inductionVariable < 50);
    return lastY;
  };
  function Easings$SMOOTH() {
    Easings.call(this, 'SMOOTH', 0);
    Easings_SMOOTH_instance = this;
  }
  protoOf(Easings$SMOOTH).r1e = function (it) {
    return it * it * (3 - 2 * it);
  };
  var Easings_SMOOTH_instance;
  function Easings$EASE_IN_ELASTIC() {
    Easings.call(this, 'EASE_IN_ELASTIC', 1);
    Easings_EASE_IN_ELASTIC_instance = this;
  }
  protoOf(Easings$EASE_IN_ELASTIC).r1e = function (it) {
    var tmp;
    switch (it) {
      case 0.0:
      case 1.0:
        tmp = it;
        break;
      default:
        var p = 0.3;
        var s = p / 4.0;
        var inv = it - 1;
        // Inline function 'kotlin.math.pow' call

        var x = 10.0 * inv;
        var tmp_0 = -1.0 * Math.pow(2.0, x);
        // Inline function 'kotlin.math.sin' call

        var x_0 = (inv - s) * (2.0 * get_PI()) / p;
        tmp = tmp_0 * Math.sin(x_0);
        break;
    }
    return tmp;
  };
  var Easings_EASE_IN_ELASTIC_instance;
  function Easings$EASE_OUT_ELASTIC() {
    Easings.call(this, 'EASE_OUT_ELASTIC', 2);
    Easings_EASE_OUT_ELASTIC_instance = this;
  }
  protoOf(Easings$EASE_OUT_ELASTIC).r1e = function (it) {
    var tmp;
    switch (it) {
      case 0.0:
      case 1.0:
        tmp = it;
        break;
      default:
        var p = 0.3;
        var s = p / 4.0;
        // Inline function 'kotlin.math.pow' call

        var x = -10.0 * it;
        var tmp_0 = Math.pow(2.0, x);
        // Inline function 'kotlin.math.sin' call

        var x_0 = (it - s) * (2.0 * get_PI()) / p;
        tmp = tmp_0 * Math.sin(x_0) + 1;
        break;
    }
    return tmp;
  };
  var Easings_EASE_OUT_ELASTIC_instance;
  function Easings$EASE_OUT_BOUNCE() {
    Easings.call(this, 'EASE_OUT_BOUNCE', 3);
    Easings_EASE_OUT_BOUNCE_instance = this;
  }
  protoOf(Easings$EASE_OUT_BOUNCE).r1e = function (it) {
    var s = 7.5625;
    var p = 2.75;
    var tmp;
    if (it < 1.0 / p) {
      // Inline function 'kotlin.math.pow' call
      tmp = s * Math.pow(it, 2.0);
    } else if (it < 2.0 / p) {
      // Inline function 'kotlin.math.pow' call
      var this_0 = it - 1.5 / p;
      tmp = s * Math.pow(this_0, 2.0) + 0.75;
    } else if (it < 2.5 / p) {
      // Inline function 'kotlin.math.pow' call
      var this_1 = it - 2.25 / p;
      tmp = s * Math.pow(this_1, 2.0) + 0.9375;
    } else {
      // Inline function 'kotlin.math.pow' call
      var this_2 = it - 2.625 / p;
      tmp = s * Math.pow(this_2, 2.0) + 0.984375;
    }
    return tmp;
  };
  var Easings_EASE_OUT_BOUNCE_instance;
  function Easings$LINEAR() {
    Easings.call(this, 'LINEAR', 4);
    Easings_LINEAR_instance = this;
  }
  protoOf(Easings$LINEAR).r1e = function (it) {
    return it;
  };
  var Easings_LINEAR_instance;
  function Easings$EASE() {
    Easings.call(this, 'EASE', 5);
    Easings_EASE_instance = this;
    this.y1f_1 = new EasingCubic(0.25, 0.1, 0.25, 1.0, 'ease');
  }
  protoOf(Easings$EASE).r1e = function (it) {
    return this.y1f_1.r1e(it);
  };
  var Easings_EASE_instance;
  function Easings$EASE_IN() {
    Easings.call(this, 'EASE_IN', 6);
    Easings_EASE_IN_instance = this;
    this.b1g_1 = new EasingCubic(0.42, 0.0, 1.0, 1.0, 'ease-in');
  }
  protoOf(Easings$EASE_IN).r1e = function (it) {
    return this.b1g_1.r1e(it);
  };
  var Easings_EASE_IN_instance;
  function Easings$EASE_OUT() {
    Easings.call(this, 'EASE_OUT', 7);
    Easings_EASE_OUT_instance = this;
    this.e1g_1 = new EasingCubic(0.0, 0.0, 0.58, 1.0, 'ease-out');
  }
  protoOf(Easings$EASE_OUT).r1e = function (it) {
    return this.e1g_1.r1e(it);
  };
  var Easings_EASE_OUT_instance;
  function Easings$EASE_IN_OUT() {
    Easings.call(this, 'EASE_IN_OUT', 8);
    Easings_EASE_IN_OUT_instance = this;
    this.h1g_1 = new EasingCubic(0.42, 0.0, 0.58, 1.0, 'ease-in-out');
  }
  protoOf(Easings$EASE_IN_OUT).r1e = function (it) {
    return this.h1g_1.r1e(it);
  };
  var Easings_EASE_IN_OUT_instance;
  function Easings$EASE_IN_OLD() {
    Easings.call(this, 'EASE_IN_OLD', 9);
    Easings_EASE_IN_OLD_instance = this;
  }
  protoOf(Easings$EASE_IN_OLD).r1e = function (it) {
    return it * it * it;
  };
  var Easings_EASE_IN_OLD_instance;
  function Easings$EASE_OUT_OLD() {
    Easings.call(this, 'EASE_OUT_OLD', 10);
    Easings_EASE_OUT_OLD_instance = this;
  }
  protoOf(Easings$EASE_OUT_OLD).r1e = function (it) {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.interpolation.EASE_OUT_OLD.invoke.<anonymous>' call
    var inv = it - 1.0;
    return inv * inv * inv + 1;
  };
  var Easings_EASE_OUT_OLD_instance;
  function Easings$EASE_IN_OUT_OLD() {
    Easings.call(this, 'EASE_IN_OUT_OLD', 11);
    Easings_EASE_IN_OUT_OLD_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_OLD).r1e = function (it) {
    // Inline function 'korlibs.math.interpolation.combine' call
    var start = Easings_EASE_IN_OLD_getInstance();
    var end = Easings_EASE_OUT_OLD_getInstance();
    return it < 0.5 ? 0.5 * start.r1e(it * 2.0) : 0.5 * end.r1e((it - 0.5) * 2.0) + 0.5;
  };
  var Easings_EASE_IN_OUT_OLD_instance;
  function Easings$EASE_OUT_IN_OLD() {
    Easings.call(this, 'EASE_OUT_IN_OLD', 12);
    Easings_EASE_OUT_IN_OLD_instance = this;
  }
  protoOf(Easings$EASE_OUT_IN_OLD).r1e = function (it) {
    // Inline function 'korlibs.math.interpolation.combine' call
    var start = Easings_EASE_OUT_OLD_getInstance();
    var end = Easings_EASE_IN_OLD_getInstance();
    return it < 0.5 ? 0.5 * start.r1e(it * 2.0) : 0.5 * end.r1e((it - 0.5) * 2.0) + 0.5;
  };
  var Easings_EASE_OUT_IN_OLD_instance;
  function Easings$EASE_IN_BACK() {
    Easings.call(this, 'EASE_IN_BACK', 13);
    Easings_EASE_IN_BACK_instance = this;
  }
  protoOf(Easings$EASE_IN_BACK).r1e = function (it) {
    // Inline function 'kotlin.math.pow' call
    return Math.pow(it, 2.0) * (2.70158 * it - 1.70158);
  };
  var Easings_EASE_IN_BACK_instance;
  function Easings$EASE_OUT_BACK() {
    Easings.call(this, 'EASE_OUT_BACK', 14);
    Easings_EASE_OUT_BACK_instance = this;
  }
  protoOf(Easings$EASE_OUT_BACK).r1e = function (it) {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.interpolation.EASE_OUT_BACK.invoke.<anonymous>' call
    var inv = it - 1.0;
    // Inline function 'kotlin.math.pow' call
    return Math.pow(inv, 2.0) * (2.70158 * inv + 1.70158) + 1.0;
  };
  var Easings_EASE_OUT_BACK_instance;
  function Easings$EASE_IN_OUT_BACK() {
    Easings.call(this, 'EASE_IN_OUT_BACK', 15);
    Easings_EASE_IN_OUT_BACK_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_BACK).r1e = function (it) {
    // Inline function 'korlibs.math.interpolation.combine' call
    var start = Easings_EASE_IN_BACK_getInstance();
    var end = Easings_EASE_OUT_BACK_getInstance();
    return it < 0.5 ? 0.5 * start.r1e(it * 2.0) : 0.5 * end.r1e((it - 0.5) * 2.0) + 0.5;
  };
  var Easings_EASE_IN_OUT_BACK_instance;
  function Easings$EASE_OUT_IN_BACK() {
    Easings.call(this, 'EASE_OUT_IN_BACK', 16);
    Easings_EASE_OUT_IN_BACK_instance = this;
  }
  protoOf(Easings$EASE_OUT_IN_BACK).r1e = function (it) {
    // Inline function 'korlibs.math.interpolation.combine' call
    var start = Easings_EASE_OUT_BACK_getInstance();
    var end = Easings_EASE_IN_BACK_getInstance();
    return it < 0.5 ? 0.5 * start.r1e(it * 2.0) : 0.5 * end.r1e((it - 0.5) * 2.0) + 0.5;
  };
  var Easings_EASE_OUT_IN_BACK_instance;
  function Easings$EASE_IN_OUT_ELASTIC() {
    Easings.call(this, 'EASE_IN_OUT_ELASTIC', 17);
    Easings_EASE_IN_OUT_ELASTIC_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_ELASTIC).r1e = function (it) {
    // Inline function 'korlibs.math.interpolation.combine' call
    var start = Easings_EASE_IN_ELASTIC_getInstance();
    var end = Easings_EASE_OUT_ELASTIC_getInstance();
    return it < 0.5 ? 0.5 * start.r1e(it * 2.0) : 0.5 * end.r1e((it - 0.5) * 2.0) + 0.5;
  };
  var Easings_EASE_IN_OUT_ELASTIC_instance;
  function Easings$EASE_OUT_IN_ELASTIC() {
    Easings.call(this, 'EASE_OUT_IN_ELASTIC', 18);
    Easings_EASE_OUT_IN_ELASTIC_instance = this;
  }
  protoOf(Easings$EASE_OUT_IN_ELASTIC).r1e = function (it) {
    // Inline function 'korlibs.math.interpolation.combine' call
    var start = Easings_EASE_OUT_ELASTIC_getInstance();
    var end = Easings_EASE_IN_ELASTIC_getInstance();
    return it < 0.5 ? 0.5 * start.r1e(it * 2.0) : 0.5 * end.r1e((it - 0.5) * 2.0) + 0.5;
  };
  var Easings_EASE_OUT_IN_ELASTIC_instance;
  function Easings$EASE_IN_BOUNCE() {
    Easings.call(this, 'EASE_IN_BOUNCE', 19);
    Easings_EASE_IN_BOUNCE_instance = this;
  }
  protoOf(Easings$EASE_IN_BOUNCE).r1e = function (it) {
    return 1.0 - Easings_EASE_OUT_BOUNCE_getInstance().r1e(1.0 - it);
  };
  var Easings_EASE_IN_BOUNCE_instance;
  function Easings$EASE_IN_OUT_BOUNCE() {
    Easings.call(this, 'EASE_IN_OUT_BOUNCE', 20);
    Easings_EASE_IN_OUT_BOUNCE_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_BOUNCE).r1e = function (it) {
    // Inline function 'korlibs.math.interpolation.combine' call
    var start = Easings_EASE_IN_BOUNCE_getInstance();
    var end = Easings_EASE_OUT_BOUNCE_getInstance();
    return it < 0.5 ? 0.5 * start.r1e(it * 2.0) : 0.5 * end.r1e((it - 0.5) * 2.0) + 0.5;
  };
  var Easings_EASE_IN_OUT_BOUNCE_instance;
  function Easings$EASE_OUT_IN_BOUNCE() {
    Easings.call(this, 'EASE_OUT_IN_BOUNCE', 21);
    Easings_EASE_OUT_IN_BOUNCE_instance = this;
  }
  protoOf(Easings$EASE_OUT_IN_BOUNCE).r1e = function (it) {
    // Inline function 'korlibs.math.interpolation.combine' call
    var start = Easings_EASE_OUT_BOUNCE_getInstance();
    var end = Easings_EASE_IN_BOUNCE_getInstance();
    return it < 0.5 ? 0.5 * start.r1e(it * 2.0) : 0.5 * end.r1e((it - 0.5) * 2.0) + 0.5;
  };
  var Easings_EASE_OUT_IN_BOUNCE_instance;
  function Easings$EASE_IN_QUAD() {
    Easings.call(this, 'EASE_IN_QUAD', 22);
    Easings_EASE_IN_QUAD_instance = this;
  }
  protoOf(Easings$EASE_IN_QUAD).r1e = function (it) {
    return 1.0 * it * it;
  };
  var Easings_EASE_IN_QUAD_instance;
  function Easings$EASE_OUT_QUAD() {
    Easings.call(this, 'EASE_OUT_QUAD', 23);
    Easings_EASE_OUT_QUAD_instance = this;
  }
  protoOf(Easings$EASE_OUT_QUAD).r1e = function (it) {
    return -1.0 * it * (it - 2);
  };
  var Easings_EASE_OUT_QUAD_instance;
  function Easings$EASE_IN_OUT_QUAD() {
    Easings.call(this, 'EASE_IN_OUT_QUAD', 24);
    Easings_EASE_IN_OUT_QUAD_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_QUAD).r1e = function (it) {
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.interpolation.EASE_IN_OUT_QUAD.invoke.<anonymous>' call
    var t = it * 2.0;
    var tmp;
    if (t < 1.0) {
      tmp = 0.5 * t * t;
    } else {
      tmp = -0.5 * ((t - 1) * (t - 1 - 2) - 1);
    }
    return tmp;
  };
  var Easings_EASE_IN_OUT_QUAD_instance;
  function Easings$EASE_SINE() {
    Easings.call(this, 'EASE_SINE', 25);
    Easings_EASE_SINE_instance = this;
  }
  protoOf(Easings$EASE_SINE).r1e = function (it) {
    // Inline function 'kotlin.math.sin' call
    var x = it * 1.5707964;
    return Math.sin(x);
  };
  var Easings_EASE_SINE_instance;
  function Easings$EASE_CLAMP_START() {
    Easings.call(this, 'EASE_CLAMP_START', 26);
    Easings_EASE_CLAMP_START_instance = this;
  }
  protoOf(Easings$EASE_CLAMP_START).r1e = function (it) {
    return it <= 0.0 ? 0.0 : 1.0;
  };
  var Easings_EASE_CLAMP_START_instance;
  function Easings$EASE_CLAMP_END() {
    Easings.call(this, 'EASE_CLAMP_END', 27);
    Easings_EASE_CLAMP_END_instance = this;
  }
  protoOf(Easings$EASE_CLAMP_END).r1e = function (it) {
    return it < 1.0 ? 0.0 : 1.0;
  };
  var Easings_EASE_CLAMP_END_instance;
  function Easings$EASE_CLAMP_MIDDLE() {
    Easings.call(this, 'EASE_CLAMP_MIDDLE', 28);
    Easings_EASE_CLAMP_MIDDLE_instance = this;
  }
  protoOf(Easings$EASE_CLAMP_MIDDLE).r1e = function (it) {
    return it < 0.5 ? 0.0 : 1.0;
  };
  var Easings_EASE_CLAMP_MIDDLE_instance;
  function values() {
    return [Easings_SMOOTH_getInstance(), Easings_EASE_IN_ELASTIC_getInstance(), Easings_EASE_OUT_ELASTIC_getInstance(), Easings_EASE_OUT_BOUNCE_getInstance(), Easings_LINEAR_getInstance(), Easings_EASE_getInstance(), Easings_EASE_IN_getInstance(), Easings_EASE_OUT_getInstance(), Easings_EASE_IN_OUT_getInstance(), Easings_EASE_IN_OLD_getInstance(), Easings_EASE_OUT_OLD_getInstance(), Easings_EASE_IN_OUT_OLD_getInstance(), Easings_EASE_OUT_IN_OLD_getInstance(), Easings_EASE_IN_BACK_getInstance(), Easings_EASE_OUT_BACK_getInstance(), Easings_EASE_IN_OUT_BACK_getInstance(), Easings_EASE_OUT_IN_BACK_getInstance(), Easings_EASE_IN_OUT_ELASTIC_getInstance(), Easings_EASE_OUT_IN_ELASTIC_getInstance(), Easings_EASE_IN_BOUNCE_getInstance(), Easings_EASE_IN_OUT_BOUNCE_getInstance(), Easings_EASE_OUT_IN_BOUNCE_getInstance(), Easings_EASE_IN_QUAD_getInstance(), Easings_EASE_OUT_QUAD_getInstance(), Easings_EASE_IN_OUT_QUAD_getInstance(), Easings_EASE_SINE_getInstance(), Easings_EASE_CLAMP_START_getInstance(), Easings_EASE_CLAMP_END_getInstance(), Easings_EASE_CLAMP_MIDDLE_getInstance()];
  }
  var Easings_entriesInitialized;
  function Easings_initEntries() {
    if (Easings_entriesInitialized)
      return Unit_instance;
    Easings_entriesInitialized = true;
    Easings_SMOOTH_instance = new Easings$SMOOTH();
    Easings_EASE_IN_ELASTIC_instance = new Easings$EASE_IN_ELASTIC();
    Easings_EASE_OUT_ELASTIC_instance = new Easings$EASE_OUT_ELASTIC();
    Easings_EASE_OUT_BOUNCE_instance = new Easings$EASE_OUT_BOUNCE();
    Easings_LINEAR_instance = new Easings$LINEAR();
    Easings_EASE_instance = new Easings$EASE();
    Easings_EASE_IN_instance = new Easings$EASE_IN();
    Easings_EASE_OUT_instance = new Easings$EASE_OUT();
    Easings_EASE_IN_OUT_instance = new Easings$EASE_IN_OUT();
    Easings_EASE_IN_OLD_instance = new Easings$EASE_IN_OLD();
    Easings_EASE_OUT_OLD_instance = new Easings$EASE_OUT_OLD();
    Easings_EASE_IN_OUT_OLD_instance = new Easings$EASE_IN_OUT_OLD();
    Easings_EASE_OUT_IN_OLD_instance = new Easings$EASE_OUT_IN_OLD();
    Easings_EASE_IN_BACK_instance = new Easings$EASE_IN_BACK();
    Easings_EASE_OUT_BACK_instance = new Easings$EASE_OUT_BACK();
    Easings_EASE_IN_OUT_BACK_instance = new Easings$EASE_IN_OUT_BACK();
    Easings_EASE_OUT_IN_BACK_instance = new Easings$EASE_OUT_IN_BACK();
    Easings_EASE_IN_OUT_ELASTIC_instance = new Easings$EASE_IN_OUT_ELASTIC();
    Easings_EASE_OUT_IN_ELASTIC_instance = new Easings$EASE_OUT_IN_ELASTIC();
    Easings_EASE_IN_BOUNCE_instance = new Easings$EASE_IN_BOUNCE();
    Easings_EASE_IN_OUT_BOUNCE_instance = new Easings$EASE_IN_OUT_BOUNCE();
    Easings_EASE_OUT_IN_BOUNCE_instance = new Easings$EASE_OUT_IN_BOUNCE();
    Easings_EASE_IN_QUAD_instance = new Easings$EASE_IN_QUAD();
    Easings_EASE_OUT_QUAD_instance = new Easings$EASE_OUT_QUAD();
    Easings_EASE_IN_OUT_QUAD_instance = new Easings$EASE_IN_OUT_QUAD();
    Easings_EASE_SINE_instance = new Easings$EASE_SINE();
    Easings_EASE_CLAMP_START_instance = new Easings$EASE_CLAMP_START();
    Easings_EASE_CLAMP_END_instance = new Easings$EASE_CLAMP_END();
    Easings_EASE_CLAMP_MIDDLE_instance = new Easings$EASE_CLAMP_MIDDLE();
  }
  function Easings(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(Easings).toString = function () {
    // Inline function 'kotlin.text.lowercase' call
    // Inline function 'kotlin.js.asDynamic' call
    return replace(protoOf(Enum).toString.call(this), _Char___init__impl__6a9atx(95), _Char___init__impl__6a9atx(45)).toLowerCase();
  };
  function toRatioClamped(_this__u8e3s4) {
    return _Ratio___init__impl__9mwvn2(clamp01(_this__u8e3s4));
  }
  function Easings_SMOOTH_getInstance() {
    Easings_initEntries();
    return Easings_SMOOTH_instance;
  }
  function Easings_EASE_IN_ELASTIC_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_ELASTIC_instance;
  }
  function Easings_EASE_OUT_ELASTIC_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_ELASTIC_instance;
  }
  function Easings_EASE_OUT_BOUNCE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_BOUNCE_instance;
  }
  function Easings_LINEAR_getInstance() {
    Easings_initEntries();
    return Easings_LINEAR_instance;
  }
  function Easings_EASE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_instance;
  }
  function Easings_EASE_IN_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_instance;
  }
  function Easings_EASE_OUT_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_instance;
  }
  function Easings_EASE_IN_OUT_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_instance;
  }
  function Easings_EASE_IN_OLD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OLD_instance;
  }
  function Easings_EASE_OUT_OLD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_OLD_instance;
  }
  function Easings_EASE_IN_OUT_OLD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_OLD_instance;
  }
  function Easings_EASE_OUT_IN_OLD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_IN_OLD_instance;
  }
  function Easings_EASE_IN_BACK_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_BACK_instance;
  }
  function Easings_EASE_OUT_BACK_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_BACK_instance;
  }
  function Easings_EASE_IN_OUT_BACK_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_BACK_instance;
  }
  function Easings_EASE_OUT_IN_BACK_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_IN_BACK_instance;
  }
  function Easings_EASE_IN_OUT_ELASTIC_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_ELASTIC_instance;
  }
  function Easings_EASE_OUT_IN_ELASTIC_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_IN_ELASTIC_instance;
  }
  function Easings_EASE_IN_BOUNCE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_BOUNCE_instance;
  }
  function Easings_EASE_IN_OUT_BOUNCE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_BOUNCE_instance;
  }
  function Easings_EASE_OUT_IN_BOUNCE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_IN_BOUNCE_instance;
  }
  function Easings_EASE_IN_QUAD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_QUAD_instance;
  }
  function Easings_EASE_OUT_QUAD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_QUAD_instance;
  }
  function Easings_EASE_IN_OUT_QUAD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_QUAD_instance;
  }
  function Easings_EASE_SINE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_SINE_instance;
  }
  function Easings_EASE_CLAMP_START_getInstance() {
    Easings_initEntries();
    return Easings_EASE_CLAMP_START_instance;
  }
  function Easings_EASE_CLAMP_END_getInstance() {
    Easings_initEntries();
    return Easings_EASE_CLAMP_END_instance;
  }
  function Easings_EASE_CLAMP_MIDDLE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_CLAMP_MIDDLE_instance;
  }
  function _ALL_LIST$factory() {
    return getPropertyCallableRef('_ALL_LIST', 1, KProperty1, function (receiver) {
      return _get__ALL_LIST__dej8hi(receiver);
    }, null);
  }
  function IsAlmostEquals() {
  }
  function get_0(_this__u8e3s4, min, max) {
    return min + _this__u8e3s4.sg() * (max - min);
  }
  function insertAt($this, n, min, max) {
    $this.y1h_1.y11(n, min);
    $this.z1h_1.y11(n, max);
  }
  function removeAt($this, n, count) {
    $this.y1h_1.g11(n, count);
    $this.z1h_1.g11(n, count);
  }
  function IntSegmentSet() {
    this.y1h_1 = new IntArrayList(16);
    this.z1h_1 = new IntArrayList(16);
  }
  protoOf(IntSegmentSet).l = function () {
    return this.y1h_1.l();
  };
  protoOf(IntSegmentSet).x = function () {
    return this.l() === 0;
  };
  protoOf(IntSegmentSet).v11 = function () {
    return this.l() > 0;
  };
  protoOf(IntSegmentSet).a1i = function () {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.clear.<anonymous>' call
    this.y1h_1.l1();
    this.z1h_1.l1();
    return this;
  };
  protoOf(IntSegmentSet).b1i = function () {
    return this.v11() ? this.y1h_1.e10(0) : 0;
  };
  protoOf(IntSegmentSet).c1i = function () {
    return this.v11() ? this.z1h_1.e10(this.z1h_1.l() - 1 | 0) : 0;
  };
  protoOf(IntSegmentSet).d1i = function (x) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.genericBinarySearch' call
      var low = 0;
      var high = this.l() - 1 | 0;
      while (low <= high) {
        var mid = (low + high | 0) / 2 | 0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.findNearIndex.<anonymous>' call
        var min = this.y1h_1.e10(mid);
        var max = this.z1h_1.e10(mid);
        var mval = x < min ? 1 : x > max ? -1 : 0;
        if (mval < 0)
          low = mid + 1 | 0;
        else if (mval > 0)
          high = mid - 1 | 0;
        else {
          tmp$ret$1 = mid;
          break $l$block;
        }
      }
      // Inline function 'korlibs.datastructure.genericBinarySearch.<anonymous>' call
      tmp$ret$1 = (-low | 0) - 1 | 0;
    }
    return _BSearchResult___init__impl__em6zam(tmp$ret$1);
  };
  protoOf(IntSegmentSet).e1i = function (x) {
    // Inline function 'korlibs.math.segment.genericBinarySearchLeft' call
    var toIndex = this.l();
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.genericBinarySearch' call
      var low = 0;
      var high = toIndex - 1 | 0;
      while (low <= high) {
        var mid = (low + high | 0) / 2 | 0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.findLeftBound.<anonymous>' call
        var mval = compareTo(this.y1h_1.e10(mid), x);
        if (mval < 0)
          low = mid + 1 | 0;
        else if (mval > 0)
          high = mid - 1 | 0;
        else {
          tmp$ret$1 = mid;
          break $l$block;
        }
      }
      // Inline function 'korlibs.math.segment.genericBinarySearchLeft.<anonymous>' call
      var low_0 = low;
      // Inline function 'kotlin.math.min' call
      var b = high;
      var tmp$ret$2 = Math.min(low_0, b);
      tmp$ret$1 = coerceIn(tmp$ret$2, 0, toIndex - 1 | 0);
    }
    var tmp$ret$4 = tmp$ret$1;
    return coerceIn(tmp$ret$4, 0, this.l() - 1 | 0);
  };
  protoOf(IntSegmentSet).f1i = function (x) {
    // Inline function 'korlibs.math.segment.genericBinarySearchRight' call
    var toIndex = this.l();
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.genericBinarySearch' call
      var low = 0;
      var high = toIndex - 1 | 0;
      while (low <= high) {
        var mid = (low + high | 0) / 2 | 0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.findRightBound.<anonymous>' call
        var mval = compareTo(this.z1h_1.e10(mid), x);
        if (mval < 0)
          low = mid + 1 | 0;
        else if (mval > 0)
          high = mid - 1 | 0;
        else {
          tmp$ret$1 = mid;
          break $l$block;
        }
      }
      // Inline function 'korlibs.math.segment.genericBinarySearchRight.<anonymous>' call
      var low_0 = low;
      // Inline function 'kotlin.math.max' call
      var b = high;
      var tmp$ret$2 = Math.max(low_0, b);
      tmp$ret$1 = coerceIn(tmp$ret$2, 0, toIndex - 1 | 0);
    }
    var tmp$ret$4 = tmp$ret$1;
    return coerceIn(tmp$ret$4, 0, this.l() - 1 | 0);
  };
  protoOf(IntSegmentSet).g1i = function (min, max) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    $l$block_0: {
      // Inline function 'korlibs.math.segment.IntSegmentSet.add.<anonymous>' call
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      if (!(min <= max)) {
        // Inline function 'kotlin.check.<anonymous>' call
        var message = 'Check failed.';
        throw IllegalStateException_init_$Create$(toString(message));
      }
      if (this.x()) {
        insertAt(this, this.l(), min, max);
      } else if (min === this.c1i()) {
        this.z1h_1.t11(this.z1h_1.l() - 1 | 0, max);
      } else if (max === this.b1i()) {
        this.y1h_1.t11(0, min);
      } else {
        var removeStart = -1;
        var removeCount = -1;
        $l$block: {
          // Inline function 'korlibs.math.segment.IntSegmentSet.fastForEachInterestingRange' call
          if (this.x()) {
            break $l$block;
          }
          var nmin = this.e1i(min);
          var nmax = this.f1i(max);
          var inductionVariable = nmin;
          if (inductionVariable <= nmax)
            do {
              var n = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              // Inline function 'korlibs.math.segment.IntSegmentSet.add.<anonymous>.<anonymous>' call
              var n_0 = n;
              var x1 = this.y1h_1.e10(n);
              var x2 = this.z1h_1.e10(n);
              if (this.h1i(x1, x2, min, max)) {
                if (removeStart === -1)
                  removeStart = n_0;
                var tmp = removeStart;
                // Inline function 'kotlin.math.min' call
                var a = this.y1h_1.e10(removeStart);
                // Inline function 'kotlin.math.min' call
                var b = Math.min(x1, min);
                var tmp$ret$3 = Math.min(a, b);
                this.y1h_1.t11(tmp, tmp$ret$3);
                var tmp_0 = removeStart;
                // Inline function 'kotlin.math.max' call
                var a_0 = this.z1h_1.e10(removeStart);
                // Inline function 'kotlin.math.max' call
                var b_0 = Math.max(x2, max);
                var tmp$ret$5 = Math.max(a_0, b_0);
                this.z1h_1.t11(tmp_0, tmp$ret$5);
                removeCount = removeCount + 1 | 0;
              }
            }
             while (!(n === nmax));
        }
        if (removeCount !== 0)
          if (removeCount > 0) {
            removeAt(this, removeStart + 1 | 0, removeCount);
          } else if (max < this.b1i()) {
            insertAt(this, 0, min, max);
          } else if (min > this.c1i()) {
            insertAt(this, this.l(), min, max);
          } else {
            var inductionVariable_0 = coerceAtLeast(this.e1i(min), 1);
            var last = this.f1i(max);
            if (inductionVariable_0 <= last)
              do {
                var m = inductionVariable_0;
                inductionVariable_0 = inductionVariable_0 + 1 | 0;
                var prevMax = this.z1h_1.e10(m - 1 | 0);
                var currMin = this.y1h_1.e10(m);
                if (min > prevMax ? max < currMin : false) {
                  insertAt(this, m, min, max);
                  break $l$block_0;
                }
              }
               while (!(m === last));
            // Inline function 'kotlin.error' call
            var message_0 = 'Unexpected';
            throw IllegalStateException_init_$Create$(toString(message_0));
          }
      }
    }
    return this;
  };
  protoOf(IntSegmentSet).ah = function (v) {
    return _BSearchResult___get_found__impl__om04iz(this.d1i(v));
  };
  protoOf(IntSegmentSet).i1i = function (a, b) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.setToIntersect.<anonymous>' call
    var aSmaller = a.l() < b.l();
    var av = aSmaller ? a : b;
    var bv = aSmaller ? b : a;
    // Inline function 'kotlin.also' call
    this.a1i();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.setToIntersect.<anonymous>.<anonymous>' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.fastForEach' call
    var inductionVariable = 0;
    var last = av.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.setToIntersect.<anonymous>.<anonymous>.<anonymous>' call
        var x1 = av.y1h_1.e10(n);
        // Inline function 'korlibs.math.segment.IntSegmentSet.intersection' call
        var max = av.z1h_1.e10(n);
        var count = 0;
        $l$block: {
          // Inline function 'korlibs.math.segment.IntSegmentSet.fastForEachInterestingRange' call
          if (bv.x()) {
            break $l$block;
          }
          var nmin = bv.e1i(x1);
          var nmax = bv.f1i(max);
          var inductionVariable_0 = nmin;
          if (inductionVariable_0 <= nmax)
            do {
              var n_0 = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              // Inline function 'korlibs.math.segment.IntSegmentSet.intersection.<anonymous>' call
              var x1_0 = bv.y1h_1.e10(n_0);
              var x2 = bv.z1h_1.e10(n_0);
              if (bv.h1i(x1_0, x2, x1, max)) {
                // Inline function 'korlibs.math.segment.IntSegmentSet.setToIntersect.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                // Inline function 'kotlin.math.max' call
                var min = Math.max(x1_0, x1);
                // Inline function 'kotlin.math.min' call
                var max_0 = Math.min(x2, max);
                this.g1i(min, max_0);
                count = count + 1 | 0;
              }
            }
             while (!(n_0 === nmax));
        }
      }
       while (inductionVariable < last);
    return this;
  };
  protoOf(IntSegmentSet).h1i = function (x1, x2, y1, y2) {
    return x2 >= y1 ? y2 >= x1 : false;
  };
  protoOf(IntSegmentSet).toString = function () {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.toString.<anonymous>' call
    this_0.i5('[');
    // Inline function 'korlibs.math.segment.IntSegmentSet.fastForEach' call
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.toString.<anonymous>.<anonymous>' call
        var min = this.y1h_1.e10(n);
        var max = this.z1h_1.e10(n);
        var first = n === 0;
        if (!first) {
          this_0.i5(', ');
        }
        this_0.i5('' + min + '-' + max);
      }
       while (inductionVariable < last);
    this_0.i5(']');
    return this_0.toString();
  };
  function Anchor2D_init_$Init$(sx, sy, $this) {
    Anchor2D.call($this, sx, sy);
    return $this;
  }
  function Anchor2D_init_$Create$(sx, sy) {
    return Anchor2D_init_$Init$(sx, sy, objectCreate(protoOf(Anchor2D)));
  }
  function Companion_1() {
    Companion_instance_2 = this;
    this.j1i_1 = Anchor2D_init_$Create$(0.0, 0.0);
    this.k1i_1 = Anchor2D_init_$Create$(0.5, 0.0);
    this.l1i_1 = Anchor2D_init_$Create$(1.0, 0.0);
    this.m1i_1 = Anchor2D_init_$Create$(0.0, 0.5);
    this.n1i_1 = Anchor2D_init_$Create$(0.5, 0.5);
    this.o1i_1 = Anchor2D_init_$Create$(1.0, 0.5);
    this.p1i_1 = Anchor2D_init_$Create$(0.0, 1.0);
    this.q1i_1 = Anchor2D_init_$Create$(0.5, 1.0);
    this.r1i_1 = Anchor2D_init_$Create$(1.0, 1.0);
  }
  protoOf(Companion_1).s1i = function () {
    return this.n1i_1;
  };
  var Companion_instance_2;
  function Companion_getInstance_1() {
    if (Companion_instance_2 == null)
      new Companion_1();
    return Companion_instance_2;
  }
  function Anchor2D(sx, sy) {
    Companion_getInstance_1();
    this.t1i_1 = sx;
    this.u1i_1 = sy;
  }
  protoOf(Anchor2D).toString = function () {
    return 'Anchor2D(sx=' + this.t1i_1 + ', sy=' + this.u1i_1 + ')';
  };
  protoOf(Anchor2D).hashCode = function () {
    var result = getNumberHashCode(this.t1i_1);
    result = imul(result, 31) + getNumberHashCode(this.u1i_1) | 0;
    return result;
  };
  protoOf(Anchor2D).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Anchor2D))
      return false;
    var tmp0_other_with_cast = other instanceof Anchor2D ? other : THROW_CCE();
    if (!equals(this.t1i_1, tmp0_other_with_cast.t1i_1))
      return false;
    if (!equals(this.u1i_1, tmp0_other_with_cast.u1i_1))
      return false;
    return true;
  };
  function _Angle___init__impl__g23q1m(radians) {
    return radians;
  }
  function _Angle___get_radians__impl__n00yt5($this) {
    return $this;
  }
  function _Angle___get_ratio__impl__ap3on4($this) {
    // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
    Companion_getInstance_2();
    var radians = _Angle___get_radians__impl__n00yt5($this);
    return _Ratio___init__impl__9mwvn2(radians / 6.283185307179586);
  }
  function _Angle___get_degrees__impl__qg56vw($this) {
    // Inline function 'korlibs.math.geom.Companion.radiansToDegrees' call
    Companion_getInstance_2();
    return _Angle___get_radians__impl__n00yt5($this) * 57.29577951308232;
  }
  function _Angle___get_cosine__impl__vprvmu($this) {
    // Inline function 'kotlin.math.cos' call
    var x = _Angle___get_radians__impl__n00yt5($this);
    return Math.cos(x);
  }
  function _Angle___get_sine__impl__15nmyi($this) {
    // Inline function 'kotlin.math.sin' call
    var x = _Angle___get_radians__impl__n00yt5($this);
    return Math.sin(x);
  }
  function _Angle___get_tangent__impl__vushdy($this) {
    // Inline function 'kotlin.math.tan' call
    var x = _Angle___get_radians__impl__n00yt5($this);
    return Math.tan(x);
  }
  function Angle__cosine_impl_bgvzun($this, up) {
    return _Angle___get_cosine__impl__vprvmu(adjustFromUp($this, up));
  }
  function Angle__sine_impl_3l11xv($this, up) {
    return _Angle___get_sine__impl__15nmyi(adjustFromUp($this, up));
  }
  function _Angle___get_absoluteValue__impl__4qoakv($this) {
    // Inline function 'kotlin.math.absoluteValue' call
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var this_0 = _Angle___get_radians__impl__n00yt5($this);
    var tmp$ret$1 = Math.abs(this_0);
    return _Angle___init__impl__g23q1m(tmp$ret$1);
  }
  function Angle__times_impl_v3s9y2($this, scale) {
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
    return _Angle___init__impl__g23q1m(tmp$ret$0 * scale);
  }
  function Angle__div_impl_ut2btd($this, scale) {
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
    return _Angle___init__impl__g23q1m(tmp$ret$0 / scale);
  }
  function Angle__times_impl_v3s9y2_0($this, scale) {
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
    return _Angle___init__impl__g23q1m(tmp$ret$0 * scale);
  }
  function Angle__umod_impl_4wilf7($this, angle) {
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp = _Angle___get_radians__impl__n00yt5($this);
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp$ret$1 = _Angle___get_radians__impl__n00yt5(angle);
    return _Angle___init__impl__g23q1m(umod(tmp, tmp$ret$1));
  }
  function Angle__div_impl_ut2btd_0($this, other) {
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp = _Angle___get_radians__impl__n00yt5($this);
    // Inline function 'korlibs.math.geom.Angle.internal' call
    return tmp / _Angle___get_radians__impl__n00yt5(other);
  }
  function Angle__plus_impl_26v37k($this, other) {
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp = _Angle___get_radians__impl__n00yt5($this);
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp$ret$1 = _Angle___get_radians__impl__n00yt5(other);
    return _Angle___init__impl__g23q1m(tmp + tmp$ret$1);
  }
  function Angle__minus_impl_xeau7k($this, other) {
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp = _Angle___get_radians__impl__n00yt5($this);
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp$ret$1 = _Angle___get_radians__impl__n00yt5(other);
    return _Angle___init__impl__g23q1m(tmp - tmp$ret$1);
  }
  function Angle__unaryMinus_impl_7901fr($this) {
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
    return _Angle___init__impl__g23q1m(-tmp$ret$0);
  }
  function Angle__unaryPlus_impl_eyi5pl($this) {
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var tmp$ret$0 = _Angle___get_radians__impl__n00yt5($this);
    return _Angle___init__impl__g23q1m(+tmp$ret$0);
  }
  function Angle__isAlmostEquals_impl_201sgt($this, other, epsilon) {
    return isAlmostEquals(_Angle___get_radians__impl__n00yt5($this), _Angle___get_radians__impl__n00yt5(other), epsilon);
  }
  function Angle__isAlmostEquals_impl_201sgt_0($this, other, epsilon) {
    return Angle__isAlmostEquals_impl_201sgt($this.v1i_1, other instanceof Angle ? other.v1i_1 : THROW_CCE(), epsilon);
  }
  function _Angle___get_normalized__impl__9s30ys($this) {
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    Companion_getInstance_2();
    var ratio = umod(Ratio__toDouble_impl_o7epze(_Angle___get_ratio__impl__ap3on4($this)), 1.0);
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    var ratio_0 = toRatio_0(ratio);
    var tmp$ret$0 = Ratio__times_impl_ucdh7y_0(ratio_0, 6.283185307179586);
    return _Angle___init__impl__g23q1m(tmp$ret$0);
  }
  function Angle__compareTo_impl_a0hfds($this, other) {
    return Ratio__compareTo_impl_z2ienc(_Angle___get_ratio__impl__ap3on4($this), _Angle___get_ratio__impl__ap3on4(other));
  }
  function Angle__compareTo_impl_a0hfds_0($this, other) {
    return Angle__compareTo_impl_a0hfds($this.v1i_1, other instanceof Angle ? other.v1i_1 : THROW_CCE());
  }
  function Angle__toString_impl_786qky($this) {
    return get_niceStr_0(roundDecimalPlaces(_Angle___get_degrees__impl__qg56vw($this), 2)) + '.degrees';
  }
  function Companion_2() {
    Companion_instance_3 = this;
    var tmp = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_2();
    var ratio = toRatio_0(1.0E-5);
    var tmp$ret$0 = Ratio__times_impl_ucdh7y_0(ratio, 6.283185307179586);
    tmp.w1i_1 = _Angle___init__impl__g23q1m(tmp$ret$0);
    var tmp_0 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_2();
    var ratio_0 = toRatio_0(0.0);
    var tmp$ret$2 = Ratio__times_impl_ucdh7y_0(ratio_0, 6.283185307179586);
    tmp_0.x1i_1 = _Angle___init__impl__g23q1m(tmp$ret$2);
    var tmp_1 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_2();
    var ratio_1 = toRatio_0(0.25);
    var tmp$ret$4 = Ratio__times_impl_ucdh7y_0(ratio_1, 6.283185307179586);
    tmp_1.y1i_1 = _Angle___init__impl__g23q1m(tmp$ret$4);
    var tmp_2 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_2();
    var ratio_2 = toRatio_0(0.5);
    var tmp$ret$6 = Ratio__times_impl_ucdh7y_0(ratio_2, 6.283185307179586);
    tmp_2.z1i_1 = _Angle___init__impl__g23q1m(tmp$ret$6);
    var tmp_3 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_2();
    var ratio_3 = toRatio_0(0.75);
    var tmp$ret$8 = Ratio__times_impl_ucdh7y_0(ratio_3, 6.283185307179586);
    tmp_3.a1j_1 = _Angle___init__impl__g23q1m(tmp$ret$8);
    var tmp_4 = this;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    Companion_getInstance_2();
    var ratio_4 = toRatio_0(1.0);
    var tmp$ret$10 = Ratio__times_impl_ucdh7y_0(ratio_4, 6.283185307179586);
    tmp_4.b1j_1 = _Angle___init__impl__g23q1m(tmp$ret$10);
  }
  var Companion_instance_3;
  function Companion_getInstance_2() {
    if (Companion_instance_3 == null)
      new Companion_2();
    return Companion_instance_3;
  }
  function Angle__hashCode_impl_szcndt($this) {
    return getNumberHashCode($this);
  }
  function Angle__equals_impl_zcf5mt($this, other) {
    if (!(other instanceof Angle))
      return false;
    var tmp0_other_with_cast = other instanceof Angle ? other.v1i_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function Angle(radians) {
    Companion_getInstance_2();
    this.v1i_1 = radians;
  }
  protoOf(Angle).c1j = function (other, epsilon) {
    return Angle__isAlmostEquals_impl_201sgt(this.v1i_1, other, epsilon);
  };
  protoOf(Angle).w1h = function (other, epsilon) {
    return Angle__isAlmostEquals_impl_201sgt_0(this, other, epsilon);
  };
  protoOf(Angle).d1j = function (other) {
    return Angle__compareTo_impl_a0hfds(this.v1i_1, other);
  };
  protoOf(Angle).d = function (other) {
    return Angle__compareTo_impl_a0hfds_0(this, other);
  };
  protoOf(Angle).toString = function () {
    return Angle__toString_impl_786qky(this.v1i_1);
  };
  protoOf(Angle).hashCode = function () {
    return Angle__hashCode_impl_szcndt(this.v1i_1);
  };
  protoOf(Angle).equals = function (other) {
    return Angle__equals_impl_zcf5mt(this.v1i_1, other);
  };
  function get_PI2() {
    return PI2;
  }
  var PI2;
  function get_degrees(_this__u8e3s4) {
    // Inline function 'korlibs.math.geom.Companion.fromDegrees' call
    // Inline function 'korlibs.math.geom.Companion.degreesToRadians' call
    Companion_getInstance_2();
    var tmp$ret$0 = _this__u8e3s4 * 0.017453292519943295;
    return _Angle___init__impl__g23q1m(tmp$ret$0);
  }
  function get_radians(_this__u8e3s4) {
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    Companion_getInstance_2();
    return _Angle___init__impl__g23q1m(_this__u8e3s4);
  }
  function interpolateAngleDenormalized(_this__u8e3s4, l, r) {
    return interpolateAngle(_this__u8e3s4, l, r, false);
  }
  function get_radians_0(_this__u8e3s4) {
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    Companion_getInstance_2();
    return _Angle___init__impl__g23q1m(_this__u8e3s4);
  }
  function adjustFromUp(_this__u8e3s4, up) {
    Companion_instance_10.f1j(up);
    return up.n1e_1 > 0.0 ? _this__u8e3s4 : Angle__unaryMinus_impl_7901fr(_this__u8e3s4);
  }
  function Angle_between(x0, y0, x1, y1, up) {
    up = up === VOID ? Companion_getInstance_15().k1j_1 : up;
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    Companion_getInstance_2();
    var x = y1 - y0;
    var y = x1 - x0;
    var up_0 = Companion_getInstance_15().k1j_1;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    // Inline function 'kotlin.math.atan2' call
    var radians = Math.atan2(x, y);
    var tmp$ret$1 = _Angle___init__impl__g23q1m(radians);
    var angle = adjustFromUp(tmp$ret$1, up_0);
    return adjustFromUp(Angle__compareTo_impl_a0hfds(angle, Companion_getInstance_2().x1i_1) < 0 ? Angle__plus_impl_26v37k(angle, Companion_getInstance_2().b1j_1) : angle, up);
  }
  function interpolateAngle(_this__u8e3s4, l, r, minimizeAngle) {
    return _interpolateAngleAny(_this__u8e3s4, l, r, minimizeAngle);
  }
  function _interpolateAngleAny(ratio, l, r, minimizeAngle) {
    minimizeAngle = minimizeAngle === VOID ? true : minimizeAngle;
    if (!minimizeAngle) {
      // Inline function 'korlibs.math.geom.Companion.fromRatio' call
      Companion_getInstance_2();
      // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
      var ratio_0 = interpolate_0(ratio, _Angle___get_ratio__impl__ap3on4(l), _Angle___get_ratio__impl__ap3on4(r));
      var tmp$ret$0 = Ratio__times_impl_ucdh7y_0(ratio_0, 6.283185307179586);
      return _Angle___init__impl__g23q1m(tmp$ret$0);
    }
    var ln = _Angle___get_normalized__impl__9s30ys(l);
    var rn = _Angle___get_normalized__impl__9s30ys(r);
    var tmp;
    if (Angle__compareTo_impl_a0hfds(_Angle___get_absoluteValue__impl__4qoakv(Angle__minus_impl_xeau7k(rn, ln)), Companion_getInstance_2().z1i_1) <= 0) {
      // Inline function 'korlibs.math.geom.Companion.fromRadians' call
      Companion_getInstance_2();
      var radians = interpolate_1(ratio, _Angle___get_radians__impl__n00yt5(ln), _Angle___get_radians__impl__n00yt5(rn));
      tmp = _Angle___init__impl__g23q1m(radians);
    } else if (Angle__compareTo_impl_a0hfds(ln, rn) < 0) {
      // Inline function 'korlibs.math.geom.Companion.fromRadians' call
      Companion_getInstance_2();
      var radians_0 = interpolate_1(ratio, _Angle___get_radians__impl__n00yt5(Angle__plus_impl_26v37k(ln, Companion_getInstance_2().b1j_1)), _Angle___get_radians__impl__n00yt5(rn));
      var tmp$ret$3 = _Angle___init__impl__g23q1m(radians_0);
      tmp = _Angle___get_normalized__impl__9s30ys(tmp$ret$3);
    } else {
      // Inline function 'korlibs.math.geom.Companion.fromRadians' call
      Companion_getInstance_2();
      var radians_1 = interpolate_1(ratio, _Angle___get_radians__impl__n00yt5(ln), _Angle___get_radians__impl__n00yt5(Angle__plus_impl_26v37k(rn, Companion_getInstance_2().b1j_1)));
      var tmp$ret$4 = _Angle___init__impl__g23q1m(radians_1);
      tmp = _Angle___get_normalized__impl__9s30ys(tmp$ret$4);
    }
    return tmp;
  }
  function _BoundsBuilder___init__impl__g6dprh(bounds) {
    return bounds;
  }
  function _BoundsBuilder___get_bounds__impl__s1l1gx($this) {
    return $this;
  }
  function _BoundsBuilder___get_isEmpty__impl__e9t785($this) {
    return _BoundsBuilder___get_bounds__impl__s1l1gx($this).s1j();
  }
  function _BoundsBuilder___get_isNotEmpty__impl__w6rbsy($this) {
    return _BoundsBuilder___get_bounds__impl__s1l1gx($this).t1j();
  }
  function _BoundsBuilder___get_xmin__impl__o2sib0($this) {
    // Inline function 'kotlin.math.min' call
    var a = _BoundsBuilder___get_bounds__impl__s1l1gx($this).u1j();
    var b = _BoundsBuilder___get_bounds__impl__s1l1gx($this).v1j();
    return Math.min(a, b);
  }
  function _BoundsBuilder___get_xmax__impl__gsz0km($this) {
    // Inline function 'kotlin.math.max' call
    var a = _BoundsBuilder___get_bounds__impl__s1l1gx($this).u1j();
    var b = _BoundsBuilder___get_bounds__impl__s1l1gx($this).v1j();
    return Math.max(a, b);
  }
  function _BoundsBuilder___get_ymin__impl__5n2e8j($this) {
    // Inline function 'kotlin.math.min' call
    var a = _BoundsBuilder___get_bounds__impl__s1l1gx($this).w1j();
    var b = _BoundsBuilder___get_bounds__impl__s1l1gx($this).x1j();
    return Math.min(a, b);
  }
  function _BoundsBuilder___get_ymax__impl__oia4uz($this) {
    // Inline function 'kotlin.math.max' call
    var a = _BoundsBuilder___get_bounds__impl__s1l1gx($this).w1j();
    var b = _BoundsBuilder___get_bounds__impl__s1l1gx($this).x1j();
    return Math.max(a, b);
  }
  function BoundsBuilder__xminOr_impl_k3l1m2($this, default_0) {
    return _BoundsBuilder___get_hasPoints__impl__6mwi9x($this) ? _BoundsBuilder___get_xmin__impl__o2sib0($this) : default_0;
  }
  function _BoundsBuilder___get_hasPoints__impl__6mwi9x($this) {
    return _BoundsBuilder___get_isNotEmpty__impl__w6rbsy($this);
  }
  function Companion_3() {
    Companion_instance_4 = this;
    this.y1j_1 = _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_10().c1k());
  }
  protoOf(Companion_3).d1k = function () {
    return this.y1j_1;
  };
  protoOf(Companion_3).e1k = function (p1, p2, p3, p4) {
    return _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_10().h1k(Companion_getInstance_15().f1k(p1, p2, p3, p4), Companion_getInstance_15().g1k(p1, p2, p3, p4)));
  };
  var Companion_instance_4;
  function Companion_getInstance_3() {
    if (Companion_instance_4 == null)
      new Companion_3();
    return Companion_instance_4;
  }
  function BoundsBuilder__plus_impl_cz7f7x($this, p) {
    if (_BoundsBuilder___get_bounds__impl__s1l1gx($this).s1j())
      return _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_10().i1k(p, Size2D_init_$Create$_1(0, 0)));
    return _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_10().h1k(Companion_getInstance_15().k1k(_BoundsBuilder___get_bounds__impl__s1l1gx($this).j1k(), p), Companion_getInstance_15().m1k(_BoundsBuilder___get_bounds__impl__s1l1gx($this).l1k(), p)));
  }
  function BoundsBuilder__plus_impl_cz7f7x_0($this, p) {
    var bb = $this;
    // Inline function 'korlibs.math.geom.fastForEach' call
    var inductionVariable = 0;
    var last = p.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.geom.BoundsBuilder.plus.<anonymous>' call
        var it = p.b1(n);
        bb = BoundsBuilder__plus_impl_cz7f7x(bb, it);
      }
       while (inductionVariable < last);
    return bb;
  }
  function BoundsBuilder__plus_impl_cz7f7x_1($this, rect) {
    if (rect == null)
      return $this;
    if (rect.s1j())
      return $this;
    return BoundsBuilder__plus_impl_cz7f7x(BoundsBuilder__plus_impl_cz7f7x($this, rect.j1k()), rect.l1k());
  }
  function BoundsBuilder__plus_impl_cz7f7x_2($this, rects) {
    var bb = $this;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < rects.l()) {
      // Inline function 'korlibs.math.geom.BoundsBuilder.plus.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var it = rects.b1(tmp0);
      bb = BoundsBuilder__plus_impl_cz7f7x_1(bb, it);
    }
    return bb;
  }
  function BoundsBuilder__boundsOrNull_impl_se9lyg($this) {
    return _BoundsBuilder___get_isEmpty__impl__e9t785($this) ? null : _BoundsBuilder___get_bounds__impl__s1l1gx($this);
  }
  function BoundsBuilder__toString_impl_fmvxh7($this) {
    return 'BoundsBuilder(bounds=' + $this + ')';
  }
  function BoundsBuilder__hashCode_impl_649zbo($this) {
    return $this.hashCode();
  }
  function BoundsBuilder__equals_impl_jngfjs($this, other) {
    if (!(other instanceof BoundsBuilder))
      return false;
    var tmp0_other_with_cast = other instanceof BoundsBuilder ? other.n1k_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function BoundsBuilder(bounds) {
    Companion_getInstance_3();
    this.n1k_1 = bounds;
  }
  protoOf(BoundsBuilder).toString = function () {
    return BoundsBuilder__toString_impl_fmvxh7(this.n1k_1);
  };
  protoOf(BoundsBuilder).hashCode = function () {
    return BoundsBuilder__hashCode_impl_649zbo(this.n1k_1);
  };
  protoOf(BoundsBuilder).equals = function (other) {
    return BoundsBuilder__equals_impl_jngfjs(this.n1k_1, other);
  };
  function Line2D_init_$Init$($this) {
    Line2D.call($this, Vector2D_init_$Create$_1(), Vector2D_init_$Create$_1());
    return $this;
  }
  function Line2D_init_$Create$() {
    return Line2D_init_$Init$(objectCreate(protoOf(Line2D)));
  }
  function Line2D_init_$Init$_0(x0, y0, x1, y1, $this) {
    Line2D.call($this, Vector2D_init_$Create$_0(x0, y0), Vector2D_init_$Create$_0(x1, y1));
    return $this;
  }
  function Line2D_init_$Create$_0(x0, y0, x1, y1) {
    return Line2D_init_$Init$_0(x0, y0, x1, y1, objectCreate(protoOf(Line2D)));
  }
  function Companion_4() {
    Companion_instance_5 = this;
    this.o1k_1 = new Line2D(Companion_getInstance_15().g1j_1, Companion_getInstance_15().g1j_1);
    this.p1k_1 = new Line2D(Companion_getInstance_15().h1j_1, Companion_getInstance_15().h1j_1);
  }
  var Companion_instance_5;
  function Companion_getInstance_4() {
    if (Companion_instance_5 == null)
      new Companion_4();
    return Companion_instance_5;
  }
  function Line2D(a, b) {
    Companion_getInstance_4();
    this.q1k_1 = a;
    this.r1k_1 = b;
  }
  protoOf(Line2D).s1k = function () {
    return Bezier_init_$Create$_0(this.q1k_1, this.r1k_1);
  };
  protoOf(Line2D).toString = function () {
    return 'Line(' + this.q1k_1 + ', ' + this.r1k_1 + ')';
  };
  protoOf(Line2D).hashCode = function () {
    var result = this.q1k_1.hashCode();
    result = imul(result, 31) + this.r1k_1.hashCode() | 0;
    return result;
  };
  protoOf(Line2D).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Line2D))
      return false;
    var tmp0_other_with_cast = other instanceof Line2D ? other : THROW_CCE();
    if (!this.q1k_1.equals(tmp0_other_with_cast.q1k_1))
      return false;
    if (!this.r1k_1.equals(tmp0_other_with_cast.r1k_1))
      return false;
    return true;
  };
  function MarginInt_init_$Init$(margin, $this) {
    MarginInt.call($this, margin, margin, margin, margin);
    return $this;
  }
  function MarginInt_init_$Create$(margin) {
    return MarginInt_init_$Init$(margin, objectCreate(protoOf(MarginInt)));
  }
  function Companion_5() {
    Companion_instance_6 = this;
    this.t1k_1 = new MarginInt(0, 0, 0, 0);
  }
  var Companion_instance_6;
  function Companion_getInstance_5() {
    if (Companion_instance_6 == null)
      new Companion_5();
    return Companion_instance_6;
  }
  function MarginInt(top, right, bottom, left) {
    Companion_getInstance_5();
    this.u1k_1 = top;
    this.v1k_1 = right;
    this.w1k_1 = bottom;
    this.x1k_1 = left;
  }
  protoOf(MarginInt).y1k = function () {
    return ((!(this.u1k_1 === 0) ? true : !(this.x1k_1 === 0)) ? true : !(this.v1k_1 === 0)) ? true : !(this.w1k_1 === 0);
  };
  protoOf(MarginInt).z1k = function () {
    return this.x1k_1 + this.v1k_1 | 0;
  };
  protoOf(MarginInt).a1l = function () {
    return this.u1k_1 + this.w1k_1 | 0;
  };
  protoOf(MarginInt).toString = function () {
    return 'MarginInt(top=' + this.u1k_1 + ', right=' + this.v1k_1 + ', bottom=' + this.w1k_1 + ', left=' + this.x1k_1 + ')';
  };
  protoOf(MarginInt).hashCode = function () {
    var result = this.u1k_1;
    result = imul(result, 31) + this.v1k_1 | 0;
    result = imul(result, 31) + this.w1k_1 | 0;
    result = imul(result, 31) + this.x1k_1 | 0;
    return result;
  };
  protoOf(MarginInt).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MarginInt))
      return false;
    var tmp0_other_with_cast = other instanceof MarginInt ? other : THROW_CCE();
    if (!(this.u1k_1 === tmp0_other_with_cast.u1k_1))
      return false;
    if (!(this.v1k_1 === tmp0_other_with_cast.v1k_1))
      return false;
    if (!(this.w1k_1 === tmp0_other_with_cast.w1k_1))
      return false;
    if (!(this.x1k_1 === tmp0_other_with_cast.x1k_1))
      return false;
    return true;
  };
  function Companion_6() {
    Companion_instance_7 = this;
    this.b1l_1 = new Matrix(1.0, 0.0, 0.0, 1.0, 0.0, 0.0);
    var tmp = this;
    tmp.c1l_1 = new Matrix(NaN, NaN, NaN, NaN, NaN, NaN);
    this.d1l_1 = this.c1l_1;
  }
  protoOf(Companion_6).e1l = function () {
    return this.b1l_1;
  };
  protoOf(Companion_6).f1l = function (a, b, epsilon) {
    return ((((isAlmostEquals(a.k1l_1, b.k1l_1, epsilon) ? isAlmostEquals(a.l1l_1, b.l1l_1, epsilon) : false) ? isAlmostEquals(a.g1l_1, b.g1l_1, epsilon) : false) ? isAlmostEquals(a.h1l_1, b.h1l_1, epsilon) : false) ? isAlmostEquals(a.i1l_1, b.i1l_1, epsilon) : false) ? isAlmostEquals(a.j1l_1, b.j1l_1, epsilon) : false;
  };
  protoOf(Companion_6).m1l = function (l, r) {
    if (l.s1j())
      return r;
    if (r.s1j())
      return l;
    return new Matrix(l.g1l_1 * r.g1l_1 + l.h1l_1 * r.i1l_1, l.g1l_1 * r.h1l_1 + l.h1l_1 * r.j1l_1, l.i1l_1 * r.g1l_1 + l.j1l_1 * r.i1l_1, l.i1l_1 * r.h1l_1 + l.j1l_1 * r.j1l_1, l.k1l_1 * r.g1l_1 + l.l1l_1 * r.i1l_1 + r.k1l_1, l.k1l_1 * r.h1l_1 + l.l1l_1 * r.j1l_1 + r.l1l_1);
  };
  protoOf(Companion_6).n1l = function (x, y, rotation, scaleX, scaleY, skewX, skewY, pivotX, pivotY) {
    // Inline function 'korlibs.math.geom.cos' call
    var angle = Angle__plus_impl_26v37k(rotation, skewY);
    var up = Companion_getInstance_15().k1j_1;
    var a = Angle__cosine_impl_bgvzun(angle, up) * scaleX + 0.0;
    // Inline function 'korlibs.math.geom.sin' call
    var angle_0 = Angle__plus_impl_26v37k(rotation, skewY);
    var up_0 = Companion_getInstance_15().k1j_1;
    var b = Angle__sine_impl_3l11xv(angle_0, up_0) * scaleX + 0.0;
    // Inline function 'korlibs.math.geom.sin' call
    var angle_1 = Angle__minus_impl_xeau7k(rotation, skewX);
    var up_1 = Companion_getInstance_15().k1j_1;
    var c = -Angle__sine_impl_3l11xv(angle_1, up_1) * scaleY + 0.0;
    // Inline function 'korlibs.math.geom.cos' call
    var angle_2 = Angle__minus_impl_xeau7k(rotation, skewX);
    var up_2 = Companion_getInstance_15().k1j_1;
    var d = Angle__cosine_impl_bgvzun(angle_2, up_2) * scaleY + 0.0;
    var tx;
    var ty;
    if (pivotX === 0.0 ? pivotY === 0.0 : false) {
      tx = x;
      ty = y;
    } else {
      tx = x - (pivotX * a + pivotY * c);
      ty = y - (pivotX * b + pivotY * d);
    }
    return new Matrix(a, b, c, d, tx, ty);
  };
  protoOf(Companion_6).o1l = function (a, b, c, d, tx, ty, p) {
    return new Vector2D(a * p.m1e_1 + c * p.n1e_1 + tx, d * p.n1e_1 + b * p.m1e_1 + ty);
  };
  var Companion_instance_7;
  function Companion_getInstance_6() {
    if (Companion_instance_7 == null)
      new Companion_6();
    return Companion_instance_7;
  }
  function Matrix(a, b, c, d, tx, ty) {
    Companion_getInstance_6();
    tx = tx === VOID ? 0.0 : tx;
    ty = ty === VOID ? 0.0 : ty;
    this.g1l_1 = a;
    this.h1l_1 = b;
    this.i1l_1 = c;
    this.j1l_1 = d;
    this.k1l_1 = tx;
    this.l1l_1 = ty;
  }
  protoOf(Matrix).p1l = function (other) {
    return Companion_getInstance_6().m1l(this, other);
  };
  protoOf(Matrix).s1j = function () {
    return isNaN_0(this.g1l_1);
  };
  protoOf(Matrix).t1j = function () {
    return !this.s1j();
  };
  protoOf(Matrix).q1l = function () {
    return ((((this.g1l_1 === 1.0 ? this.h1l_1 === 0.0 : false) ? this.i1l_1 === 0.0 : false) ? this.j1l_1 === 1.0 : false) ? this.k1l_1 === 0.0 : false) ? this.l1l_1 === 0.0 : false;
  };
  protoOf(Matrix).r1l = function () {
    var hasRotation = !(this.h1l_1 === 0.0) ? true : !(this.i1l_1 === 0.0);
    var hasScale = !(this.g1l_1 === 1.0) ? true : !(this.j1l_1 === 1.0);
    var hasTranslation = !(this.k1l_1 === 0.0) ? true : !(this.l1l_1 === 0.0);
    return hasRotation ? MatrixType_COMPLEX_getInstance() : (hasScale ? hasTranslation : false) ? MatrixType_SCALE_TRANSLATE_getInstance() : hasScale ? MatrixType_SCALE_getInstance() : hasTranslation ? MatrixType_TRANSLATE_getInstance() : MatrixType_IDENTITY_getInstance();
  };
  protoOf(Matrix).s1l = function (p) {
    return this.t1l(p.m1e_1, p.n1e_1);
  };
  protoOf(Matrix).u1l = function (x, y) {
    return this.t1l(x, y);
  };
  protoOf(Matrix).v1l = function (x, y) {
    return this.w1l(x, y);
  };
  protoOf(Matrix).t1l = function (x, y) {
    return this.g1l_1 * x + this.i1l_1 * y + this.k1l_1;
  };
  protoOf(Matrix).w1l = function (x, y) {
    return this.j1l_1 * y + this.h1l_1 * x + this.l1l_1;
  };
  protoOf(Matrix).x1l = function (x, y) {
    return this.t1l(x, y);
  };
  protoOf(Matrix).y1l = function (x, y) {
    return this.w1l(x, y);
  };
  protoOf(Matrix).z1l = function (angle) {
    // Inline function 'korlibs.math.geom.cos' call
    var up = Companion_getInstance_15().k1j_1;
    var cos = Angle__cosine_impl_bgvzun(angle, up);
    // Inline function 'korlibs.math.geom.sin' call
    var up_0 = Companion_getInstance_15().k1j_1;
    var sin = Angle__sine_impl_3l11xv(angle, up_0);
    var a1 = this.g1l_1 * cos - this.h1l_1 * sin;
    var b = this.g1l_1 * sin + this.h1l_1 * cos;
    var a = a1;
    var c1 = this.i1l_1 * cos - this.j1l_1 * sin;
    var d = this.i1l_1 * sin + this.j1l_1 * cos;
    var c = c1;
    var tx1 = this.k1l_1 * cos - this.l1l_1 * sin;
    var ty = this.k1l_1 * sin + this.l1l_1 * cos;
    var tx = tx1;
    return new Matrix(a, b, c, d, tx, ty);
  };
  protoOf(Matrix).a1m = function (scale) {
    return new Matrix(this.g1l_1 * scale.b1m_1, this.h1l_1 * scale.b1m_1, this.i1l_1 * scale.c1m_1, this.j1l_1 * scale.c1m_1, this.k1l_1 * scale.b1m_1, this.l1l_1 * scale.c1m_1);
  };
  protoOf(Matrix).d1m = function (scaleX, scaleY) {
    return this.a1m(Scale_init_$Create$_1(scaleX, scaleY));
  };
  protoOf(Matrix).e1m = function (scaleX, scaleY) {
    return this.a1m(new Scale(scaleX, scaleY));
  };
  protoOf(Matrix).f1m = function (scaleX, scaleY, $super) {
    scaleY = scaleY === VOID ? scaleX : scaleY;
    return $super === VOID ? this.e1m(scaleX, scaleY) : $super.e1m.call(this, scaleX, scaleY);
  };
  protoOf(Matrix).g1m = function (scale) {
    return new Matrix(this.g1l_1 * scale.b1m_1, this.h1l_1 * scale.b1m_1, this.i1l_1 * scale.c1m_1, this.j1l_1 * scale.c1m_1, this.k1l_1, this.l1l_1);
  };
  protoOf(Matrix).h1m = function (scaleX, scaleY) {
    return this.g1m(new Scale(scaleX, scaleY));
  };
  protoOf(Matrix).i1m = function (scaleX, scaleY, $super) {
    scaleY = scaleY === VOID ? scaleX : scaleY;
    return $super === VOID ? this.h1m(scaleX, scaleY) : $super.h1m.call(this, scaleX, scaleY);
  };
  protoOf(Matrix).j1m = function (delta) {
    return new Matrix(this.g1l_1, this.h1l_1, this.i1l_1, this.j1l_1, this.k1l_1 + delta.m1e_1, this.l1l_1 + delta.n1e_1);
  };
  protoOf(Matrix).k1m = function (x, y) {
    return this.j1m(Vector2D_init_$Create$_0(x, y));
  };
  protoOf(Matrix).l1m = function (x, y) {
    return this.j1m(new Vector2D(x, y));
  };
  protoOf(Matrix).m1m = function (delta) {
    return new Matrix(this.g1l_1, this.h1l_1, this.i1l_1, this.j1l_1, this.k1l_1 + (this.g1l_1 * delta.m1e_1 + this.i1l_1 * delta.n1e_1), this.l1l_1 + (this.h1l_1 * delta.m1e_1 + this.j1l_1 * delta.n1e_1));
  };
  protoOf(Matrix).n1m = function (deltaX, deltaY) {
    return this.m1m(Vector2D_init_$Create$(deltaX, deltaY));
  };
  protoOf(Matrix).o1m = function (deltaX, deltaY) {
    return this.m1m(new Vector2D(deltaX, deltaY));
  };
  protoOf(Matrix).p1m = function (m) {
    return m.p1l(this);
  };
  protoOf(Matrix).q1m = function () {
    if (this.s1j())
      return Companion_getInstance_6().b1l_1;
    var m = this;
    var norm = m.g1l_1 * m.j1l_1 - m.h1l_1 * m.i1l_1;
    var tmp;
    if (norm === 0.0) {
      tmp = new Matrix(0.0, 0.0, 0.0, 0.0, -m.k1l_1, -m.l1l_1);
    } else {
      var inorm = 1.0 / norm;
      var d = m.g1l_1 * inorm;
      var a = m.j1l_1 * inorm;
      var b = m.h1l_1 * -inorm;
      var c = m.i1l_1 * -inorm;
      tmp = new Matrix(a, b, c, d, -a * m.k1l_1 - c * m.l1l_1, -b * m.k1l_1 - d * m.l1l_1);
    }
    return tmp;
  };
  protoOf(Matrix).r1m = function () {
    return this.s1m();
  };
  protoOf(Matrix).s1m = function () {
    return Companion_getInstance_7().u1m(this);
  };
  protoOf(Matrix).toString = function () {
    return 'Matrix(' + get_niceStr_0(this.g1l_1) + ', ' + get_niceStr_0(this.h1l_1) + ', ' + get_niceStr_0(this.i1l_1) + ', ' + get_niceStr_0(this.j1l_1) + ', ' + get_niceStr_0(this.k1l_1) + ', ' + get_niceStr_0(this.l1l_1) + ')';
  };
  protoOf(Matrix).v1m = function (other, epsilon) {
    return Companion_getInstance_6().f1l(this, other, epsilon);
  };
  protoOf(Matrix).w1h = function (other, epsilon) {
    return this.v1m(other instanceof Matrix ? other : THROW_CCE(), epsilon);
  };
  protoOf(Matrix).w1m = function (other) {
    return this.p1l(other);
  };
  protoOf(Matrix).hashCode = function () {
    var result = getNumberHashCode(this.g1l_1);
    result = imul(result, 31) + getNumberHashCode(this.h1l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.i1l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.j1l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.k1l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.l1l_1) | 0;
    return result;
  };
  protoOf(Matrix).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Matrix))
      return false;
    var tmp0_other_with_cast = other instanceof Matrix ? other : THROW_CCE();
    if (!equals(this.g1l_1, tmp0_other_with_cast.g1l_1))
      return false;
    if (!equals(this.h1l_1, tmp0_other_with_cast.h1l_1))
      return false;
    if (!equals(this.i1l_1, tmp0_other_with_cast.i1l_1))
      return false;
    if (!equals(this.j1l_1, tmp0_other_with_cast.j1l_1))
      return false;
    if (!equals(this.k1l_1, tmp0_other_with_cast.k1l_1))
      return false;
    if (!equals(this.l1l_1, tmp0_other_with_cast.l1l_1))
      return false;
    return true;
  };
  var MatrixType_IDENTITY_instance;
  var MatrixType_TRANSLATE_instance;
  var MatrixType_SCALE_instance;
  var MatrixType_SCALE_TRANSLATE_instance;
  var MatrixType_COMPLEX_instance;
  var MatrixType_entriesInitialized;
  function MatrixType_initEntries() {
    if (MatrixType_entriesInitialized)
      return Unit_instance;
    MatrixType_entriesInitialized = true;
    MatrixType_IDENTITY_instance = new MatrixType('IDENTITY', 0, 1, false, false, false);
    MatrixType_TRANSLATE_instance = new MatrixType('TRANSLATE', 1, 2, false, false, true);
    MatrixType_SCALE_instance = new MatrixType('SCALE', 2, 3, false, true, false);
    MatrixType_SCALE_TRANSLATE_instance = new MatrixType('SCALE_TRANSLATE', 3, 4, false, true, true);
    MatrixType_COMPLEX_instance = new MatrixType('COMPLEX', 4, 5, true, true, true);
  }
  function MatrixType(name, ordinal, id, hasRotation, hasScale, hasTranslation) {
    Enum.call(this, name, ordinal);
    this.z1m_1 = id;
    this.a1n_1 = hasRotation;
    this.b1n_1 = hasScale;
    this.c1n_1 = hasTranslation;
  }
  function MatrixTransform_init_$Init$($this) {
    MatrixTransform.call($this, 0.0, 0.0, 1.0, 1.0, Companion_getInstance_2().x1i_1, Companion_getInstance_2().x1i_1, Companion_getInstance_2().x1i_1);
    return $this;
  }
  function MatrixTransform_init_$Create$() {
    return MatrixTransform_init_$Init$(objectCreate(protoOf(MatrixTransform)));
  }
  function Companion_7() {
    Companion_instance_8 = this;
    this.t1m_1 = new MatrixTransform(0.0, 0.0, 1.0, 1.0, Companion_getInstance_2().x1i_1, Companion_getInstance_2().x1i_1, Companion_getInstance_2().x1i_1);
  }
  protoOf(Companion_7).d1n = function (matrix, pivotX, pivotY) {
    var a = matrix.g1l_1;
    var b = matrix.h1l_1;
    var c = matrix.i1l_1;
    var d = matrix.j1l_1;
    // Inline function 'kotlin.math.atan2' call
    var y = -c;
    var skewX = -Math.atan2(y, d);
    // Inline function 'kotlin.math.atan2' call
    var skewY = Math.atan2(b, a);
    // Inline function 'kotlin.math.abs' call
    var x = skewX + skewY;
    var delta = Math.abs(x);
    var trotation;
    var tskewX;
    var tskewY;
    var tx;
    var ty;
    var tmp;
    if (delta < 0.001) {
      tmp = true;
    } else {
      // Inline function 'kotlin.math.abs' call
      var x_0 = get_PI() * 2 - delta;
      tmp = Math.abs(x_0) < 0.001;
    }
    if (tmp) {
      trotation = get_radians_0(skewY);
      tskewX = get_radians_0(0.0);
      tskewY = get_radians_0(0.0);
    } else {
      trotation = get_radians(0);
      tskewX = get_radians_0(skewX);
      tskewY = get_radians_0(skewY);
    }
    // Inline function 'kotlin.math.hypot' call
    var tscaleX = hypot(a, b);
    // Inline function 'kotlin.math.hypot' call
    var tscaleY = hypot(c, d);
    if (pivotX === 0.0 ? pivotY === 0.0 : false) {
      tx = matrix.k1l_1;
      ty = matrix.l1l_1;
    } else {
      tx = matrix.k1l_1 + (pivotX * a + pivotY * c);
      ty = matrix.l1l_1 + (pivotX * b + pivotY * d);
    }
    return new MatrixTransform(tx, ty, tscaleX, tscaleY, tskewX, tskewY, trotation);
  };
  protoOf(Companion_7).u1m = function (matrix, pivotX, pivotY, $super) {
    pivotX = pivotX === VOID ? 0.0 : pivotX;
    pivotY = pivotY === VOID ? 0.0 : pivotY;
    return $super === VOID ? this.d1n(matrix, pivotX, pivotY) : $super.d1n.call(this, matrix, pivotX, pivotY);
  };
  protoOf(Companion_7).e1n = function (a, b, epsilon) {
    return (((((isAlmostEquals(a.f1n_1, b.f1n_1, epsilon) ? isAlmostEquals(a.g1n_1, b.g1n_1, epsilon) : false) ? isAlmostEquals(a.h1n_1, b.h1n_1, epsilon) : false) ? isAlmostEquals(a.i1n_1, b.i1n_1, epsilon) : false) ? Angle__isAlmostEquals_impl_201sgt(a.j1n_1, b.j1n_1, epsilon) : false) ? Angle__isAlmostEquals_impl_201sgt(a.k1n_1, b.k1n_1, epsilon) : false) ? Angle__isAlmostEquals_impl_201sgt(a.l1n_1, b.l1n_1, epsilon) : false;
  };
  var Companion_instance_8;
  function Companion_getInstance_7() {
    if (Companion_instance_8 == null)
      new Companion_7();
    return Companion_instance_8;
  }
  function MatrixTransform(x, y, scaleX, scaleY, skewX, skewY, rotation) {
    Companion_getInstance_7();
    x = x === VOID ? 0.0 : x;
    y = y === VOID ? 0.0 : y;
    scaleX = scaleX === VOID ? 1.0 : scaleX;
    scaleY = scaleY === VOID ? 1.0 : scaleY;
    skewX = skewX === VOID ? Companion_getInstance_2().x1i_1 : skewX;
    skewY = skewY === VOID ? Companion_getInstance_2().x1i_1 : skewY;
    rotation = rotation === VOID ? Companion_getInstance_2().x1i_1 : rotation;
    this.f1n_1 = x;
    this.g1n_1 = y;
    this.h1n_1 = scaleX;
    this.i1n_1 = scaleY;
    this.j1n_1 = skewX;
    this.k1n_1 = skewY;
    this.l1n_1 = rotation;
  }
  protoOf(MatrixTransform).m1n = function () {
    return new Scale(this.h1n_1, this.i1n_1);
  };
  protoOf(MatrixTransform).toString = function () {
    return 'MatrixTransform(x=' + get_niceStr_0(this.f1n_1) + ', y=' + get_niceStr_0(this.g1n_1) + ', scaleX=' + this.h1n_1 + ', scaleY=' + this.i1n_1 + ', skewX=' + new Angle(this.j1n_1) + ', skewY=' + new Angle(this.k1n_1) + ', rotation=' + new Angle(this.l1n_1) + ')';
  };
  protoOf(MatrixTransform).n1n = function (other, epsilon) {
    return Companion_getInstance_7().e1n(this, other, epsilon);
  };
  protoOf(MatrixTransform).w1h = function (other, epsilon) {
    return this.n1n(other instanceof MatrixTransform ? other : THROW_CCE(), epsilon);
  };
  protoOf(MatrixTransform).o1n = function () {
    return (this.h1n_1 + this.i1n_1) * 0.5;
  };
  protoOf(MatrixTransform).hashCode = function () {
    var result = getNumberHashCode(this.f1n_1);
    result = imul(result, 31) + getNumberHashCode(this.g1n_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.h1n_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.i1n_1) | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.j1n_1) | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.k1n_1) | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.l1n_1) | 0;
    return result;
  };
  protoOf(MatrixTransform).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MatrixTransform))
      return false;
    var tmp0_other_with_cast = other instanceof MatrixTransform ? other : THROW_CCE();
    if (!equals(this.f1n_1, tmp0_other_with_cast.f1n_1))
      return false;
    if (!equals(this.g1n_1, tmp0_other_with_cast.g1n_1))
      return false;
    if (!equals(this.h1n_1, tmp0_other_with_cast.h1n_1))
      return false;
    if (!equals(this.i1n_1, tmp0_other_with_cast.i1n_1))
      return false;
    if (!equals(this.j1n_1, tmp0_other_with_cast.j1n_1))
      return false;
    if (!equals(this.k1n_1, tmp0_other_with_cast.k1n_1))
      return false;
    if (!equals(this.l1n_1, tmp0_other_with_cast.l1n_1))
      return false;
    return true;
  };
  function MatrixType_IDENTITY_getInstance() {
    MatrixType_initEntries();
    return MatrixType_IDENTITY_instance;
  }
  function MatrixType_TRANSLATE_getInstance() {
    MatrixType_initEntries();
    return MatrixType_TRANSLATE_instance;
  }
  function MatrixType_SCALE_getInstance() {
    MatrixType_initEntries();
    return MatrixType_SCALE_instance;
  }
  function MatrixType_SCALE_TRANSLATE_getInstance() {
    MatrixType_initEntries();
    return MatrixType_SCALE_TRANSLATE_instance;
  }
  function MatrixType_COMPLEX_getInstance() {
    MatrixType_initEntries();
    return MatrixType_COMPLEX_instance;
  }
  function Matrix4_init_$Init$(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33, $this) {
    // Inline function 'kotlin.floatArrayOf' call
    var tmp$ret$0 = new Float32Array([v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33]);
    Matrix4.call($this, tmp$ret$0);
    return $this;
  }
  function Matrix4_init_$Create$(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33) {
    return Matrix4_init_$Init$(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33, objectCreate(protoOf(Matrix4)));
  }
  function Matrix4_init_$Init$_0($this) {
    Matrix4_init_$Init$(1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, $this);
    return $this;
  }
  function Matrix4_init_$Create$_0() {
    return Matrix4_init_$Init$_0(objectCreate(protoOf(Matrix4)));
  }
  function Companion_8() {
    Companion_instance_9 = this;
    this.p1n_1 = 0;
    this.q1n_1 = 1;
    this.r1n_1 = 2;
    this.s1n_1 = 3;
    this.t1n_1 = 4;
    this.u1n_1 = 5;
    this.v1n_1 = 6;
    this.w1n_1 = 7;
    this.x1n_1 = 8;
    this.y1n_1 = 9;
    this.z1n_1 = 10;
    this.a1o_1 = 11;
    this.b1o_1 = 12;
    this.c1o_1 = 13;
    this.d1o_1 = 14;
    this.e1o_1 = 15;
    this.f1o_1 = Matrix4_init_$Create$_0();
  }
  protoOf(Companion_8).g1o = function () {
    return Companion_getInstance_40().x1o_1;
  };
  protoOf(Companion_8).b1p = function (v00, v01, v02, v03, v10, v11, v12, v13, v20, v21, v22, v23, v30, v31, v32, v33) {
    return Matrix4_init_$Create$(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33);
  };
  protoOf(Companion_8).c1p = function (l, r) {
    return Companion_getInstance_8().b1p(l.e1p() * r.e1p() + l.f1p() * r.g1p() + l.h1p() * r.i1p() + l.j1p() * r.k1p(), l.e1p() * r.f1p() + l.f1p() * r.l1p() + l.h1p() * r.m1p() + l.j1p() * r.n1p(), l.e1p() * r.h1p() + l.f1p() * r.o1p() + l.h1p() * r.p1p() + l.j1p() * r.q1p(), l.e1p() * r.j1p() + l.f1p() * r.r1p() + l.h1p() * r.s1p() + l.j1p() * r.t1p(), l.g1p() * r.e1p() + l.l1p() * r.g1p() + l.o1p() * r.i1p() + l.r1p() * r.k1p(), l.g1p() * r.f1p() + l.l1p() * r.l1p() + l.o1p() * r.m1p() + l.r1p() * r.n1p(), l.g1p() * r.h1p() + l.l1p() * r.o1p() + l.o1p() * r.p1p() + l.r1p() * r.q1p(), l.g1p() * r.j1p() + l.l1p() * r.r1p() + l.o1p() * r.s1p() + l.r1p() * r.t1p(), l.i1p() * r.e1p() + l.m1p() * r.g1p() + l.p1p() * r.i1p() + l.s1p() * r.k1p(), l.i1p() * r.f1p() + l.m1p() * r.l1p() + l.p1p() * r.m1p() + l.s1p() * r.n1p(), l.i1p() * r.h1p() + l.m1p() * r.o1p() + l.p1p() * r.p1p() + l.s1p() * r.q1p(), l.i1p() * r.j1p() + l.m1p() * r.r1p() + l.p1p() * r.s1p() + l.s1p() * r.t1p(), l.k1p() * r.e1p() + l.n1p() * r.g1p() + l.q1p() * r.i1p() + l.t1p() * r.k1p(), l.k1p() * r.f1p() + l.n1p() * r.l1p() + l.q1p() * r.m1p() + l.t1p() * r.n1p(), l.k1p() * r.h1p() + l.n1p() * r.o1p() + l.q1p() * r.p1p() + l.t1p() * r.q1p(), l.k1p() * r.j1p() + l.n1p() * r.r1p() + l.q1p() * r.s1p() + l.t1p() * r.t1p());
  };
  protoOf(Companion_8).u1p = function (left, right, bottom, top, near, far) {
    var sx = 2.0 / (right - left);
    var sy = 2.0 / (top - bottom);
    var sz = -2.0 / (far - near);
    var tx = -(right + left) / (right - left);
    var ty = -(top + bottom) / (top - bottom);
    var tz = -(far + near) / (far - near);
    return Companion_getInstance_8().b1p(sx, 0.0, 0.0, tx, 0.0, sy, 0.0, ty, 0.0, 0.0, sz, tz, 0.0, 0.0, 0.0, 1.0);
  };
  protoOf(Companion_8).v1p = function (left, right, bottom, top, near, far) {
    return this.u1p(left, right, bottom, top, near, far);
  };
  protoOf(Companion_8).w1p = function (rect, near, far) {
    return this.v1p(rect.u1j(), rect.v1j(), rect.x1j(), rect.w1j(), near, far);
  };
  var Companion_instance_9;
  function Companion_getInstance_8() {
    if (Companion_instance_9 == null)
      new Companion_8();
    return Companion_instance_9;
  }
  function Matrix4(data) {
    Companion_getInstance_8();
    this.d1p_1 = data;
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(this.d1p_1.length === 16)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException_init_$Create$(toString(message));
    }
  }
  protoOf(Matrix4).e1p = function () {
    return this.d1p_1[0];
  };
  protoOf(Matrix4).g1p = function () {
    return this.d1p_1[1];
  };
  protoOf(Matrix4).i1p = function () {
    return this.d1p_1[2];
  };
  protoOf(Matrix4).k1p = function () {
    return this.d1p_1[3];
  };
  protoOf(Matrix4).f1p = function () {
    return this.d1p_1[4];
  };
  protoOf(Matrix4).l1p = function () {
    return this.d1p_1[5];
  };
  protoOf(Matrix4).m1p = function () {
    return this.d1p_1[6];
  };
  protoOf(Matrix4).n1p = function () {
    return this.d1p_1[7];
  };
  protoOf(Matrix4).h1p = function () {
    return this.d1p_1[8];
  };
  protoOf(Matrix4).o1p = function () {
    return this.d1p_1[9];
  };
  protoOf(Matrix4).p1p = function () {
    return this.d1p_1[10];
  };
  protoOf(Matrix4).q1p = function () {
    return this.d1p_1[11];
  };
  protoOf(Matrix4).j1p = function () {
    return this.d1p_1[12];
  };
  protoOf(Matrix4).r1p = function () {
    return this.d1p_1[13];
  };
  protoOf(Matrix4).s1p = function () {
    return this.d1p_1[14];
  };
  protoOf(Matrix4).t1p = function () {
    return this.d1p_1[15];
  };
  protoOf(Matrix4).equals = function (other) {
    var tmp;
    if (other instanceof Matrix4) {
      tmp = contentEquals(this.d1p_1, other.d1p_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Matrix4).hashCode = function () {
    return contentHashCode(this.d1p_1);
  };
  protoOf(Matrix4).x1p = function (that) {
    return Companion_getInstance_8().c1p(this, that);
  };
  protoOf(Matrix4).f10 = function (row, column) {
    if (!(0 <= column ? column <= 3 : false) ? true : !(0 <= row ? row <= 3 : false)) {
      // Inline function 'kotlin.error' call
      var message = 'Invalid index ' + row + ',' + column;
      throw IllegalStateException_init_$Create$(toString(message));
    }
    return this.d1p_1[imul(row, 4) + column | 0];
  };
  protoOf(Matrix4).y1p = function (index) {
    if (!(0 <= index ? index <= (this.d1p_1.length - 1 | 0) : false)) {
      // Inline function 'kotlin.error' call
      var message = 'Invalid index ' + index;
      throw IllegalStateException_init_$Create$(toString(message));
    }
    return this.d1p_1[index];
  };
  protoOf(Matrix4).toString = function () {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.Matrix4.toString.<anonymous>' call
    this_0.i5('Matrix4(\n');
    var inductionVariable = 0;
    if (inductionVariable < 4)
      do {
        var row = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this_0.i5('  [ ');
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < 4)
          do {
            var col = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            if (!(col === 0)) {
              this_0.i5(', ');
            }
            var v = this.f10(row, col);
            // Inline function 'kotlin.math.floor' call
            if (Math.floor(v) === v) {
              this_0.t7(numberToInt(v));
            } else {
              this_0.v7(v);
            }
          }
           while (inductionVariable_0 < 4);
        this_0.i5(' ],\n');
      }
       while (inductionVariable < 4);
    this_0.i5(')');
    return this_0.toString();
  };
  var Orientation_CLOCK_WISE_instance;
  var Orientation_COUNTER_CLOCK_WISE_instance;
  var Orientation_COLLINEAR_instance;
  function Companion_9() {
    this.e1j_1 = 1.0E-7;
  }
  protoOf(Companion_9).f1j = function (up) {
    // Inline function 'kotlin.check' call
    var tmp;
    if (up.m1e_1 === 0.0) {
      // Inline function 'kotlin.math.absoluteValue' call
      var this_0 = up.n1e_1;
      tmp = Math.abs(this_0) === 1.0;
    } else {
      tmp = false;
    }
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp) {
      // Inline function 'korlibs.math.geom.Companion.checkValidUpVector.<anonymous>' call
      var message = 'up vector only supports (0, -1) and (0, +1) for now';
      throw IllegalStateException_init_$Create$(toString(message));
    }
  };
  var Companion_instance_10;
  function Companion_getInstance_9() {
    return Companion_instance_10;
  }
  var Orientation_entriesInitialized;
  function Orientation_initEntries() {
    if (Orientation_entriesInitialized)
      return Unit_instance;
    Orientation_entriesInitialized = true;
    Orientation_CLOCK_WISE_instance = new Orientation('CLOCK_WISE', 0, 1);
    Orientation_COUNTER_CLOCK_WISE_instance = new Orientation('COUNTER_CLOCK_WISE', 1, -1);
    Orientation_COLLINEAR_instance = new Orientation('COLLINEAR', 2, 0);
  }
  function Orientation(name, ordinal, value) {
    Enum.call(this, name, ordinal);
    this.b1q_1 = value;
  }
  function Companion_10() {
    Companion_instance_11 = this;
    this.z1j_1 = Companion_getInstance_10().c1q(0, 0, 0, 0);
    var tmp = this;
    var tmp_0 = Companion_getInstance_10();
    tmp.a1k_1 = tmp_0.d1q(-Infinity, -Infinity, Infinity, Infinity);
    var tmp_1 = this;
    var tmp_2 = Companion_getInstance_10();
    tmp_1.b1k_1 = tmp_2.d1q(NaN, NaN, 0.0, 0.0);
  }
  protoOf(Companion_10).c1k = function () {
    return this.b1k_1;
  };
  protoOf(Companion_10).e1l = function () {
    return this.z1j_1;
  };
  protoOf(Companion_10).i1k = function (p, s) {
    return new RectangleD(p.m1e_1, p.n1e_1, s.e1q_1, s.f1q_1);
  };
  protoOf(Companion_10).c1q = function (x, y, width, height) {
    return Companion_getInstance_10().i1k(Vector2D_init_$Create$_0(x, y), Size2D_init_$Create$_1(width, height));
  };
  protoOf(Companion_10).d1q = function (x, y, width, height) {
    return Companion_getInstance_10().i1k(Vector2D_init_$Create$(x, y), Size2D_init_$Create$_0(width, height));
  };
  protoOf(Companion_10).g1q = function (left, top, right, bottom) {
    return new RectangleD(left, top, right - left, bottom - top);
  };
  protoOf(Companion_10).h1q = function (left, top, right, bottom) {
    return this.g1q(left, top, right, bottom);
  };
  protoOf(Companion_10).h1k = function (point1, point2) {
    var tmp = Companion_getInstance_10();
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var tmp$ret$0 = new Vector2D(point2.m1e_1 - point1.m1e_1, point2.n1e_1 - point1.n1e_1);
    return tmp.i1k(point1, toSize(tmp$ret$0));
  };
  protoOf(Companion_10).i1q = function (a, b) {
    return ((a.o1j_1 >= b.o1j_1 ? a.p1j_1 >= b.p1j_1 : false) ? a.o1j_1 + a.q1j_1 <= b.o1j_1 + b.q1j_1 : false) ? a.p1j_1 + a.r1j_1 <= b.p1j_1 + b.r1j_1 : false;
  };
  var Companion_instance_11;
  function Companion_getInstance_10() {
    if (Companion_instance_11 == null)
      new Companion_10();
    return Companion_instance_11;
  }
  function RectangleD(x, y, width, height) {
    Companion_getInstance_10();
    this.o1j_1 = x;
    this.p1j_1 = y;
    this.q1j_1 = width;
    this.r1j_1 = height;
  }
  protoOf(RectangleD).i11 = function () {
    return this;
  };
  protoOf(RectangleD).l = function () {
    return new Size2D(this.q1j_1, this.r1j_1);
  };
  protoOf(RectangleD).j1q = function () {
    return isNaN_0(this.o1j_1);
  };
  protoOf(RectangleD).s1j = function () {
    return this.j1q();
  };
  protoOf(RectangleD).t1j = function () {
    return !this.s1j();
  };
  protoOf(RectangleD).k1q = function (other, epsilon) {
    return ((isAlmostEquals(this.o1j_1, other.o1j_1, epsilon) ? isAlmostEquals(this.p1j_1, other.p1j_1, epsilon) : false) ? isAlmostEquals(this.q1j_1, other.q1j_1, epsilon) : false) ? isAlmostEquals(this.r1j_1, other.r1j_1, epsilon) : false;
  };
  protoOf(RectangleD).w1h = function (other, epsilon) {
    return this.k1q(other instanceof RectangleD ? other : THROW_CCE(), epsilon);
  };
  protoOf(RectangleD).toString = function () {
    return this.s1j() ? 'null' : 'Rectangle(x=' + get_niceStr_0(this.o1j_1) + ', y=' + get_niceStr_0(this.p1j_1) + ', width=' + get_niceStr_0(this.q1j_1) + ', height=' + get_niceStr_0(this.r1j_1) + ')';
  };
  protoOf(RectangleD).l1q = function (scale) {
    return new RectangleD(this.o1j_1 * scale, this.p1j_1 * scale, this.q1j_1 * scale, this.r1j_1 * scale);
  };
  protoOf(RectangleD).m1q = function (that) {
    return this.n1q(that.m1e_1, that.n1e_1);
  };
  protoOf(RectangleD).o1q = function (that) {
    return this.r1q(that.p1q_1, that.q1q_1);
  };
  protoOf(RectangleD).n1q = function (x, y) {
    return (x >= this.u1j() ? x < this.v1j() : false) ? y >= this.w1j() ? y < this.x1j() : false : false;
  };
  protoOf(RectangleD).r1q = function (x, y) {
    return this.n1q(x, y);
  };
  protoOf(RectangleD).u1j = function () {
    return this.o1j_1;
  };
  protoOf(RectangleD).w1j = function () {
    return this.p1j_1;
  };
  protoOf(RectangleD).v1j = function () {
    return this.o1j_1 + this.q1j_1;
  };
  protoOf(RectangleD).x1j = function () {
    return this.p1j_1 + this.r1j_1;
  };
  protoOf(RectangleD).j1k = function () {
    return new Vector2D(this.u1j(), this.w1j());
  };
  protoOf(RectangleD).s1q = function () {
    return new Vector2D(this.v1j(), this.w1j());
  };
  protoOf(RectangleD).t1q = function () {
    return new Vector2D(this.u1j(), this.x1j());
  };
  protoOf(RectangleD).l1k = function () {
    return new Vector2D(this.v1j(), this.x1j());
  };
  protoOf(RectangleD).u1q = function () {
    return (this.v1j() + this.u1j()) * 0.5;
  };
  protoOf(RectangleD).v1q = function () {
    return (this.x1j() + this.w1j()) * 0.5;
  };
  protoOf(RectangleD).w1q = function () {
    return new Vector2D(this.u1q(), this.v1q());
  };
  protoOf(RectangleD).x1q = function () {
    var centerX = this.u1q();
    var centerY = this.v1q();
    return new Circle(this.w1q(), Companion_getInstance_15().y1q(centerX, centerY, this.v1j(), this.w1j()));
  };
  protoOf(RectangleD).z1q = function (that) {
    return this.b1r(that) ? this.a1r(that) : false;
  };
  protoOf(RectangleD).b1r = function (that) {
    return that.u1j() <= this.v1j() ? that.v1j() >= this.u1j() : false;
  };
  protoOf(RectangleD).a1r = function (that) {
    return that.w1j() <= this.x1j() ? that.x1j() >= this.w1j() : false;
  };
  protoOf(RectangleD).c1r = function (that) {
    var tmp;
    if (this.z1q(that)) {
      // Inline function 'kotlin.math.max' call
      var a = this.u1j();
      var b = that.u1j();
      var tmp_0 = Math.max(a, b);
      // Inline function 'kotlin.math.max' call
      var a_0 = this.w1j();
      var b_0 = that.w1j();
      var tmp_1 = Math.max(a_0, b_0);
      // Inline function 'kotlin.math.min' call
      var a_1 = this.v1j();
      var b_1 = that.v1j();
      var tmp_2 = Math.min(a_1, b_1);
      // Inline function 'kotlin.math.min' call
      var a_2 = this.x1j();
      var b_2 = that.x1j();
      var tmp$ret$3 = Math.min(a_2, b_2);
      tmp = new RectangleD(tmp_0, tmp_1, tmp_2, tmp$ret$3);
    } else {
      tmp = Companion_getInstance_10().c1k();
    }
    return tmp;
  };
  protoOf(RectangleD).ua = function () {
    return new RectangleI(numberToInt(this.o1j_1), numberToInt(this.p1j_1), numberToInt(this.q1j_1), numberToInt(this.r1j_1));
  };
  protoOf(RectangleD).d1r = function (border) {
    return Companion_getInstance_10().g1q(this.u1j() - border.x1k_1, this.w1j() - border.u1k_1, this.v1j() + border.v1k_1, this.x1j() + border.w1k_1);
  };
  protoOf(RectangleD).j1m = function (delta) {
    return this.e1r(this.o1j_1 + delta.m1e_1, this.p1j_1 + delta.n1e_1);
  };
  protoOf(RectangleD).f1r = function (m) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var p = this.j1k();
      if (m.s1j()) {
        tmp$ret$0 = p;
        break $l$block;
      }
      tmp$ret$0 = new Vector2D(m.t1l(p.m1e_1, p.n1e_1), m.w1l(p.m1e_1, p.n1e_1));
    }
    var tl = tmp$ret$0;
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var p_0 = this.s1q();
      if (m.s1j()) {
        tmp$ret$1 = p_0;
        break $l$block_0;
      }
      tmp$ret$1 = new Vector2D(m.t1l(p_0.m1e_1, p_0.n1e_1), m.w1l(p_0.m1e_1, p_0.n1e_1));
    }
    var tr = tmp$ret$1;
    var tmp$ret$2;
    $l$block_1: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var p_1 = this.t1q();
      if (m.s1j()) {
        tmp$ret$2 = p_1;
        break $l$block_1;
      }
      tmp$ret$2 = new Vector2D(m.t1l(p_1.m1e_1, p_1.n1e_1), m.w1l(p_1.m1e_1, p_1.n1e_1));
    }
    var bl = tmp$ret$2;
    var tmp$ret$3;
    $l$block_2: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var p_2 = this.l1k();
      if (m.s1j()) {
        tmp$ret$3 = p_2;
        break $l$block_2;
      }
      tmp$ret$3 = new Vector2D(m.t1l(p_2.m1e_1, p_2.n1e_1), m.w1l(p_2.m1e_1, p_2.n1e_1));
    }
    var br = tmp$ret$3;
    var min = Companion_getInstance_15().f1k(tl, tr, bl, br);
    var max = Companion_getInstance_15().g1k(tl, tr, bl, br);
    return Companion_getInstance_10().h1k(min, max);
  };
  protoOf(RectangleD).g1r = function () {
    return Companion_getInstance_10().h1k(Companion_getInstance_15().k1k(this.j1k(), this.l1k()), Companion_getInstance_15().m1k(this.j1k(), this.l1k()));
  };
  protoOf(RectangleD).h1r = function (x, y, width, height) {
    return new RectangleD(x, y, width, height);
  };
  protoOf(RectangleD).e1r = function (x, y, width, height, $super) {
    x = x === VOID ? this.o1j_1 : x;
    y = y === VOID ? this.p1j_1 : y;
    width = width === VOID ? this.q1j_1 : width;
    height = height === VOID ? this.r1j_1 : height;
    return $super === VOID ? this.h1r(x, y, width, height) : $super.h1r.call(this, x, y, width, height);
  };
  protoOf(RectangleD).hashCode = function () {
    var result = getNumberHashCode(this.o1j_1);
    result = imul(result, 31) + getNumberHashCode(this.p1j_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.q1j_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.r1j_1) | 0;
    return result;
  };
  protoOf(RectangleD).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RectangleD))
      return false;
    var tmp0_other_with_cast = other instanceof RectangleD ? other : THROW_CCE();
    if (!equals(this.o1j_1, tmp0_other_with_cast.o1j_1))
      return false;
    if (!equals(this.p1j_1, tmp0_other_with_cast.p1j_1))
      return false;
    if (!equals(this.q1j_1, tmp0_other_with_cast.q1j_1))
      return false;
    if (!equals(this.r1j_1, tmp0_other_with_cast.r1j_1))
      return false;
    return true;
  };
  function RectangleI_init_$Init$($this) {
    RectangleI_init_$Init$_0(Vector2I_init_$Create$(), SizeInt_init_$Create$(), $this);
    return $this;
  }
  function RectangleI_init_$Create$() {
    return RectangleI_init_$Init$(objectCreate(protoOf(RectangleI)));
  }
  function RectangleI_init_$Init$_0(position, size, $this) {
    RectangleI.call($this, position.i1r_1, position.j1r_1, size.k1r_1, size.l1r_1);
    return $this;
  }
  function RectangleI_init_$Create$_0(position, size) {
    return RectangleI_init_$Init$_0(position, size, objectCreate(protoOf(RectangleI)));
  }
  function Companion_11() {
  }
  protoOf(Companion_11).m1r = function (a, b) {
    // Inline function 'kotlin.math.min' call
    var a_0 = a.u1j();
    var b_0 = b.u1j();
    var tmp = Math.min(a_0, b_0);
    // Inline function 'kotlin.math.min' call
    var a_1 = a.w1j();
    var b_1 = b.w1j();
    var tmp_0 = Math.min(a_1, b_1);
    // Inline function 'kotlin.math.max' call
    var a_2 = a.v1j();
    var b_2 = b.v1j();
    var tmp_1 = Math.max(a_2, b_2);
    // Inline function 'kotlin.math.max' call
    var a_3 = a.x1j();
    var b_3 = b.x1j();
    var tmp$ret$3 = Math.max(a_3, b_3);
    return this.h1q(tmp, tmp_0, tmp_1, tmp$ret$3);
  };
  protoOf(Companion_11).r1r = function (topLeft, bottomRight) {
    return RectangleI_init_$Create$_0(topLeft, toSize_0(bottomRight.s1r(topLeft)));
  };
  protoOf(Companion_11).h1q = function (left, top, right, bottom) {
    return this.r1r(new Vector2I(left, top), new Vector2I(right, bottom));
  };
  var Companion_instance_12;
  function Companion_getInstance_11() {
    return Companion_instance_12;
  }
  function RectangleI(x, y, width, height) {
    this.n1r_1 = x;
    this.o1r_1 = y;
    this.p1r_1 = width;
    this.q1r_1 = height;
  }
  protoOf(RectangleI).t1r = function () {
    return Companion_getInstance_10().c1q(this.n1r_1, this.o1r_1, this.p1r_1, this.q1r_1);
  };
  protoOf(RectangleI).u1r = function () {
    return imul(this.p1r_1, this.q1r_1);
  };
  protoOf(RectangleI).u1j = function () {
    return this.n1r_1;
  };
  protoOf(RectangleI).w1j = function () {
    return this.o1r_1;
  };
  protoOf(RectangleI).v1j = function () {
    return this.n1r_1 + this.p1r_1 | 0;
  };
  protoOf(RectangleI).x1j = function () {
    return this.o1r_1 + this.q1r_1 | 0;
  };
  protoOf(RectangleI).v1r = function (left, top, right, bottom, clamped) {
    var left_0 = !clamped ? left : coerceIn(left, 0, this.p1r_1);
    var right_0 = !clamped ? right : coerceIn(right, 0, this.p1r_1);
    var top_0 = !clamped ? top : coerceIn(top, 0, this.q1r_1);
    var bottom_0 = !clamped ? bottom : coerceIn(bottom, 0, this.q1r_1);
    return Companion_instance_12.h1q(this.n1r_1 + left_0 | 0, this.o1r_1 + top_0 | 0, this.n1r_1 + right_0 | 0, this.o1r_1 + bottom_0 | 0);
  };
  protoOf(RectangleI).d1r = function (border) {
    return Companion_instance_12.h1q(this.u1j() - border.x1k_1 | 0, this.w1j() - border.u1k_1 | 0, this.v1j() + border.v1k_1 | 0, this.x1j() + border.w1k_1 | 0);
  };
  protoOf(RectangleI).toString = function () {
    return 'Rectangle(x=' + this.n1r_1 + ', y=' + this.o1r_1 + ', width=' + this.p1r_1 + ', height=' + this.q1r_1 + ')';
  };
  protoOf(RectangleI).hashCode = function () {
    var result = this.n1r_1;
    result = imul(result, 31) + this.o1r_1 | 0;
    result = imul(result, 31) + this.p1r_1 | 0;
    result = imul(result, 31) + this.q1r_1 | 0;
    return result;
  };
  protoOf(RectangleI).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RectangleI))
      return false;
    var tmp0_other_with_cast = other instanceof RectangleI ? other : THROW_CCE();
    if (!(this.n1r_1 === tmp0_other_with_cast.n1r_1))
      return false;
    if (!(this.o1r_1 === tmp0_other_with_cast.o1r_1))
      return false;
    if (!(this.p1r_1 === tmp0_other_with_cast.p1r_1))
      return false;
    if (!(this.q1r_1 === tmp0_other_with_cast.q1r_1))
      return false;
    return true;
  };
  function Companion_12() {
    Companion_instance_13 = this;
    this.w1r_1 = Scale_init_$Create$_1(1.0, 1.0);
  }
  var Companion_instance_13;
  function Companion_getInstance_12() {
    if (Companion_instance_13 == null)
      new Companion_12();
    return Companion_instance_13;
  }
  function Scale_init_$Init$($this) {
    Scale_init_$Init$_2(1.0, 1.0, $this);
    return $this;
  }
  function Scale_init_$Create$() {
    return Scale_init_$Init$(objectCreate(protoOf(Scale)));
  }
  function Scale_init_$Init$_0(scale, $this) {
    Scale.call($this, scale, scale);
    return $this;
  }
  function Scale_init_$Init$_1(scale, $this) {
    Scale_init_$Init$_0(scale, $this);
    return $this;
  }
  function Scale_init_$Create$_0(scale) {
    return Scale_init_$Init$_1(scale, objectCreate(protoOf(Scale)));
  }
  function Scale_init_$Init$_2(scaleX, scaleY, $this) {
    Scale.call($this, scaleX, scaleY);
    return $this;
  }
  function Scale_init_$Create$_1(scaleX, scaleY) {
    return Scale_init_$Init$_2(scaleX, scaleY, objectCreate(protoOf(Scale)));
  }
  function Scale_init_$Init$_3(scaleX, scaleY, $this) {
    Scale.call($this, scaleX, scaleY);
    return $this;
  }
  function Scale_init_$Create$_2(scaleX, scaleY) {
    return Scale_init_$Init$_3(scaleX, scaleY, objectCreate(protoOf(Scale)));
  }
  function Scale(scaleX, scaleY) {
    Companion_getInstance_12();
    this.b1m_1 = scaleX;
    this.c1m_1 = scaleY;
  }
  protoOf(Scale).o1n = function () {
    return this.b1m_1 * 0.5 + this.c1m_1 * 0.5;
  };
  protoOf(Scale).toString = function () {
    return 'Scale(scaleX=' + this.b1m_1 + ', scaleY=' + this.c1m_1 + ')';
  };
  protoOf(Scale).hashCode = function () {
    var result = getNumberHashCode(this.b1m_1);
    result = imul(result, 31) + getNumberHashCode(this.c1m_1) | 0;
    return result;
  };
  protoOf(Scale).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Scale))
      return false;
    var tmp0_other_with_cast = other instanceof Scale ? other : THROW_CCE();
    if (!equals(this.b1m_1, tmp0_other_with_cast.b1m_1))
      return false;
    if (!equals(this.c1m_1, tmp0_other_with_cast.c1m_1))
      return false;
    return true;
  };
  function toVector2(_this__u8e3s4) {
    return new Vector2D(_this__u8e3s4.b1m_1, _this__u8e3s4.c1m_1);
  }
  function ScaleMode$Companion$COVER$lambda(i, c) {
    return i.l1q(toVector2(c.x1r(i)).y1r());
  }
  function ScaleMode$Companion$SHOW_ALL$lambda(i, c) {
    return i.l1q(toVector2(c.x1r(i)).z1r());
  }
  function ScaleMode$Companion$EXACT$lambda(i, c) {
    return c;
  }
  function ScaleMode$Companion$NO_SCALE$lambda(i, c) {
    return i;
  }
  function Companion_13() {
    Companion_instance_14 = this;
    var tmp = this;
    tmp.a1s_1 = new ScaleMode('COVER', ScaleMode$Companion$COVER$lambda);
    var tmp_0 = this;
    tmp_0.b1s_1 = new ScaleMode('SHOW_ALL', ScaleMode$Companion$SHOW_ALL$lambda);
    var tmp_1 = this;
    tmp_1.c1s_1 = new ScaleMode('EXACT', ScaleMode$Companion$EXACT$lambda);
    var tmp_2 = this;
    tmp_2.d1s_1 = new ScaleMode('NO_SCALE', ScaleMode$Companion$NO_SCALE$lambda);
  }
  protoOf(Companion_13).e1s = function () {
    return this.c1s_1;
  };
  var Companion_instance_14;
  function Companion_getInstance_13() {
    if (Companion_instance_14 == null)
      new Companion_13();
    return Companion_instance_14;
  }
  function ScaleMode(name, transform) {
    Companion_getInstance_13();
    name = name === VOID ? null : name;
    this.f1s_1 = name;
    this.g1s_1 = transform;
  }
  protoOf(ScaleMode).toString = function () {
    return 'ScaleMode(' + this.f1s_1 + ')';
  };
  protoOf(ScaleMode).h1s = function (item, container) {
    return toInt(this.g1s_1(toFloat(item), toFloat(container)));
  };
  function Companion_14() {
    Companion_instance_15 = this;
    this.i1s_1 = new Size2D(0.0, 0.0);
  }
  var Companion_instance_15;
  function Companion_getInstance_14() {
    if (Companion_instance_15 == null)
      new Companion_14();
    return Companion_instance_15;
  }
  function Size2D_init_$Init$($this) {
    Size2D.call($this, 0.0, 0.0);
    return $this;
  }
  function Size2D_init_$Create$() {
    return Size2D_init_$Init$(objectCreate(protoOf(Size2D)));
  }
  function Size2D_init_$Init$_0(width, height, $this) {
    Size2D.call($this, width, height);
    return $this;
  }
  function Size2D_init_$Create$_0(width, height) {
    return Size2D_init_$Init$_0(width, height, objectCreate(protoOf(Size2D)));
  }
  function Size2D_init_$Init$_1(width, height, $this) {
    Size2D.call($this, width, height);
    return $this;
  }
  function Size2D_init_$Create$_1(width, height) {
    return Size2D_init_$Init$_1(width, height, objectCreate(protoOf(Size2D)));
  }
  function Size2D(width, height) {
    Companion_getInstance_14();
    this.e1q_1 = width;
    this.f1q_1 = height;
  }
  protoOf(Size2D).j1s = function (scale) {
    return new Size2D(this.e1q_1 * scale.b1m_1, this.f1q_1 * scale.c1m_1);
  };
  protoOf(Size2D).k1s = function (s) {
    return new Size2D(this.e1q_1 * s, this.f1q_1 * s);
  };
  protoOf(Size2D).l1q = function (s) {
    return this.k1s(s);
  };
  protoOf(Size2D).x1r = function (other) {
    return new Scale(this.e1q_1 / other.e1q_1, this.f1q_1 / other.f1q_1);
  };
  protoOf(Size2D).toString = function () {
    return 'Size(width=' + get_niceStr_0(this.e1q_1) + ', height=' + get_niceStr_0(this.f1q_1) + ')';
  };
  protoOf(Size2D).bf = function () {
    return this.e1q_1;
  };
  protoOf(Size2D).cf = function () {
    return this.f1q_1;
  };
  protoOf(Size2D).l1s = function (width, height) {
    return new Size2D(width, height);
  };
  protoOf(Size2D).m1s = function (width, height, $super) {
    width = width === VOID ? this.e1q_1 : width;
    height = height === VOID ? this.f1q_1 : height;
    return $super === VOID ? this.l1s(width, height) : $super.l1s.call(this, width, height);
  };
  protoOf(Size2D).hashCode = function () {
    var result = getNumberHashCode(this.e1q_1);
    result = imul(result, 31) + getNumberHashCode(this.f1q_1) | 0;
    return result;
  };
  protoOf(Size2D).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Size2D))
      return false;
    var tmp0_other_with_cast = other instanceof Size2D ? other : THROW_CCE();
    if (!equals(this.e1q_1, tmp0_other_with_cast.e1q_1))
      return false;
    if (!equals(this.f1q_1, tmp0_other_with_cast.f1q_1))
      return false;
    return true;
  };
  function SizeInt_init_$Init$($this) {
    SizeInt.call($this, 0, 0);
    return $this;
  }
  function SizeInt_init_$Create$() {
    return SizeInt_init_$Init$(objectCreate(protoOf(SizeInt)));
  }
  function SizeInt(width, height) {
    this.k1r_1 = width;
    this.l1r_1 = height;
  }
  protoOf(SizeInt).toString = function () {
    return '' + this.k1r_1 + 'x' + this.l1r_1;
  };
  protoOf(SizeInt).hashCode = function () {
    var result = this.k1r_1;
    result = imul(result, 31) + this.l1r_1 | 0;
    return result;
  };
  protoOf(SizeInt).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SizeInt))
      return false;
    var tmp0_other_with_cast = other instanceof SizeInt ? other : THROW_CCE();
    if (!(this.k1r_1 === tmp0_other_with_cast.k1r_1))
      return false;
    if (!(this.l1r_1 === tmp0_other_with_cast.l1r_1))
      return false;
    return true;
  };
  function toSize(_this__u8e3s4) {
    return new Size2D(_this__u8e3s4.m1e_1, _this__u8e3s4.n1e_1);
  }
  function toSize_0(_this__u8e3s4) {
    return new SizeInt(_this__u8e3s4.i1r_1, _this__u8e3s4.j1r_1);
  }
  function toInt(_this__u8e3s4) {
    return new SizeInt(numberToInt(_this__u8e3s4.e1q_1), numberToInt(_this__u8e3s4.f1q_1));
  }
  function toFloat(_this__u8e3s4) {
    return Size2D_init_$Create$_0(_this__u8e3s4.k1r_1, _this__u8e3s4.l1r_1);
  }
  function square($this, x) {
    return x * x;
  }
  function Vector2D_init_$Init$(x, y, $this) {
    Vector2D.call($this, x, y);
    return $this;
  }
  function Vector2D_init_$Create$(x, y) {
    return Vector2D_init_$Init$(x, y, objectCreate(protoOf(Vector2D)));
  }
  function Vector2D_init_$Init$_0(x, y, $this) {
    Vector2D.call($this, x, y);
    return $this;
  }
  function Vector2D_init_$Create$_0(x, y) {
    return Vector2D_init_$Init$_0(x, y, objectCreate(protoOf(Vector2D)));
  }
  function Vector2D_init_$Init$_1($this) {
    Vector2D.call($this, 0.0, 0.0);
    return $this;
  }
  function Vector2D_init_$Create$_1() {
    return Vector2D_init_$Init$_1(objectCreate(protoOf(Vector2D)));
  }
  function Companion_15() {
    Companion_instance_16 = this;
    this.g1j_1 = new Vector2D(0.0, 0.0);
    var tmp = this;
    tmp.h1j_1 = new Vector2D(NaN, NaN);
    this.i1j_1 = new Vector2D(-1.0, 0.0);
    this.j1j_1 = new Vector2D(1.0, 0.0);
    this.k1j_1 = new Vector2D(0.0, 1.0);
    this.l1j_1 = new Vector2D(0.0, -1.0);
    this.m1j_1 = new Vector2D(0.0, -1.0);
    this.n1j_1 = new Vector2D(0.0, 1.0);
  }
  protoOf(Companion_15).n1s = function (a, b, up) {
    // Inline function 'korlibs.math.geom.Companion.between' call
    // Inline function 'korlibs.math.geom.Companion.between' call
    Companion_getInstance_2();
    var x0 = a.m1e_1;
    var y0 = a.n1e_1;
    var x1 = b.m1e_1;
    var y1 = b.n1e_1;
    return Angle_between(x0, y0, x1, y1, up);
  };
  protoOf(Companion_15).o1s = function (a, b, up, $super) {
    up = up === VOID ? this.k1j_1 : up;
    return $super === VOID ? this.n1s(a, b, up) : $super.n1s.call(this, a, b, up).v1i_1;
  };
  protoOf(Companion_15).y1q = function (x1, y1, x2, y2) {
    // Inline function 'kotlin.math.hypot' call
    var x = x1 - x2;
    var y = y1 - y2;
    return hypot(x, y);
  };
  protoOf(Companion_15).p1s = function (x1, y1, x2, y2) {
    // Inline function 'kotlin.math.hypot' call
    var x = x1 - x2;
    var y = y1 - y2;
    return hypot(x, y);
  };
  protoOf(Companion_15).q1s = function (x1, y1, x2, y2) {
    // Inline function 'kotlin.math.hypot' call
    var x = x1 - x2;
    var y = y1 - y2;
    return hypot(x, y);
  };
  protoOf(Companion_15).r1s = function (a, b) {
    return this.y1q(a.m1e_1, a.n1e_1, b.m1e_1, b.n1e_1);
  };
  protoOf(Companion_15).s1s = function (a, b) {
    return this.t1s(a.m1e_1, a.n1e_1, b.m1e_1, b.n1e_1);
  };
  protoOf(Companion_15).t1s = function (x1, y1, x2, y2) {
    return square(this, x1 - x2) + square(this, y1 - y2);
  };
  protoOf(Companion_15).u1s = function (ax, ay, bx, by) {
    return ax * by - bx * ay;
  };
  protoOf(Companion_15).v1s = function (p1, p2) {
    return this.u1s(p1.m1e_1, p1.n1e_1, p2.m1e_1, p2.n1e_1);
  };
  protoOf(Companion_15).k1k = function (p1, p2) {
    // Inline function 'kotlin.math.min' call
    var a = p1.m1e_1;
    var b = p2.m1e_1;
    var tmp = Math.min(a, b);
    // Inline function 'kotlin.math.min' call
    var a_0 = p1.n1e_1;
    var b_0 = p2.n1e_1;
    var tmp$ret$1 = Math.min(a_0, b_0);
    return new Vector2D(tmp, tmp$ret$1);
  };
  protoOf(Companion_15).f1k = function (p1, p2, p3, p4) {
    return new Vector2D(min(p1.m1e_1, p2.m1e_1, p3.m1e_1, p4.m1e_1), min(p1.n1e_1, p2.n1e_1, p3.n1e_1, p4.n1e_1));
  };
  protoOf(Companion_15).m1k = function (p1, p2) {
    // Inline function 'kotlin.math.max' call
    var a = p1.m1e_1;
    var b = p2.m1e_1;
    var tmp = Math.max(a, b);
    // Inline function 'kotlin.math.max' call
    var a_0 = p1.n1e_1;
    var b_0 = p2.n1e_1;
    var tmp$ret$1 = Math.max(a_0, b_0);
    return new Vector2D(tmp, tmp$ret$1);
  };
  protoOf(Companion_15).g1k = function (p1, p2, p3, p4) {
    return new Vector2D(max(p1.m1e_1, p2.m1e_1, p3.m1e_1, p4.m1e_1), max(p1.n1e_1, p2.n1e_1, p3.n1e_1, p4.n1e_1));
  };
  var Companion_instance_16;
  function Companion_getInstance_15() {
    if (Companion_instance_16 == null)
      new Companion_15();
    return Companion_instance_16;
  }
  function Vector2D(x, y) {
    Companion_getInstance_15();
    this.m1e_1 = x;
    this.n1e_1 = y;
  }
  protoOf(Vector2D).z1r = function () {
    // Inline function 'kotlin.math.min' call
    var a = this.m1e_1;
    var b = this.n1e_1;
    return Math.min(a, b);
  };
  protoOf(Vector2D).y1r = function () {
    // Inline function 'kotlin.math.max' call
    var a = this.m1e_1;
    var b = this.n1e_1;
    return Math.max(a, b);
  };
  protoOf(Vector2D).w1s = function (x, y) {
    // Inline function 'kotlin.math.hypot' call
    var x_0 = x - this.m1e_1;
    var y_0 = y - this.n1e_1;
    return hypot(x_0, y_0);
  };
  protoOf(Vector2D).x1s = function (that) {
    return this.w1s(that.m1e_1, that.n1e_1);
  };
  protoOf(Vector2D).y1s = function (m) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      if (m.s1j()) {
        tmp$ret$0 = this;
        break $l$block;
      }
      tmp$ret$0 = new Vector2D(m.t1l(this.m1e_1, this.n1e_1), m.w1l(this.m1e_1, this.n1e_1));
    }
    return tmp$ret$0.m1e_1;
  };
  protoOf(Vector2D).z1s = function (m) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      if (m.s1j()) {
        tmp$ret$0 = this;
        break $l$block;
      }
      tmp$ret$0 = new Vector2D(m.t1l(this.m1e_1, this.n1e_1), m.w1l(this.m1e_1, this.n1e_1));
    }
    return tmp$ret$0.n1e_1;
  };
  protoOf(Vector2D).a = function () {
    // Inline function 'kotlin.math.hypot' call
    var x = this.m1e_1;
    var y = this.n1e_1;
    return hypot(x, y);
  };
  protoOf(Vector2D).a1t = function () {
    var x = this.m1e_1;
    var y = this.n1e_1;
    return x * x + y * y;
  };
  protoOf(Vector2D).b1t = function () {
    // Inline function 'kotlin.math.hypot' call
    var x = this.m1e_1;
    var y = this.n1e_1;
    return hypot(x, y);
  };
  protoOf(Vector2D).c1t = function () {
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scale = 1.0 / this.b1t();
    return new Vector2D(this.m1e_1 * scale, this.n1e_1 * scale);
  };
  protoOf(Vector2D).d1t = function () {
    return new Vector2D(-this.n1e_1, this.m1e_1);
  };
  protoOf(Vector2D).e1t = function (other, epsilon) {
    return isAlmostEquals(this.m1e_1, other.m1e_1, epsilon) ? isAlmostEquals(this.n1e_1, other.n1e_1, epsilon) : false;
  };
  protoOf(Vector2D).w1h = function (other, epsilon) {
    return this.e1t(other instanceof Vector2D ? other : THROW_CCE(), epsilon);
  };
  protoOf(Vector2D).f1t = function () {
    return '(' + get_niceStr_0(this.m1e_1) + ', ' + get_niceStr_0(this.n1e_1) + ')';
  };
  protoOf(Vector2D).toString = function () {
    return this.f1t();
  };
  protoOf(Vector2D).bf = function () {
    return this.m1e_1;
  };
  protoOf(Vector2D).cf = function () {
    return this.n1e_1;
  };
  protoOf(Vector2D).hashCode = function () {
    var result = getNumberHashCode(this.m1e_1);
    result = imul(result, 31) + getNumberHashCode(this.n1e_1) | 0;
    return result;
  };
  protoOf(Vector2D).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector2D))
      return false;
    var tmp0_other_with_cast = other instanceof Vector2D ? other : THROW_CCE();
    if (!equals(this.m1e_1, tmp0_other_with_cast.m1e_1))
      return false;
    if (!equals(this.n1e_1, tmp0_other_with_cast.n1e_1))
      return false;
    return true;
  };
  function toInt_0(_this__u8e3s4) {
    return new Vector2I(numberToInt(_this__u8e3s4.m1e_1), numberToInt(_this__u8e3s4.n1e_1));
  }
  function Vector2F_init_$Init$(x, y, $this) {
    Vector2F.call($this, x, y);
    return $this;
  }
  function Vector2F_init_$Create$(x, y) {
    return Vector2F_init_$Init$(x, y, objectCreate(protoOf(Vector2F)));
  }
  function Vector2F_init_$Init$_0(x, y, $this) {
    Vector2F.call($this, x, y);
    return $this;
  }
  function Vector2F_init_$Create$_0(x, y) {
    return Vector2F_init_$Init$_0(x, y, objectCreate(protoOf(Vector2F)));
  }
  function Vector2F_init_$Init$_1($this) {
    Vector2F.call($this, 0.0, 0.0);
    return $this;
  }
  function Vector2F_init_$Create$_1() {
    return Vector2F_init_$Init$_1(objectCreate(protoOf(Vector2F)));
  }
  function Companion_16() {
    Companion_instance_17 = this;
    this.g1t_1 = new Vector2F(0.0, 0.0);
    var tmp = this;
    tmp.h1t_1 = new Vector2F(NaN, NaN);
    this.i1t_1 = new Vector2F(-1.0, 0.0);
    this.j1t_1 = new Vector2F(1.0, 0.0);
    this.k1t_1 = new Vector2F(0.0, 1.0);
    this.l1t_1 = new Vector2F(0.0, -1.0);
    this.m1t_1 = new Vector2F(0.0, -1.0);
    this.n1t_1 = new Vector2F(0.0, 1.0);
  }
  var Companion_instance_17;
  function Companion_getInstance_16() {
    if (Companion_instance_17 == null)
      new Companion_16();
    return Companion_instance_17;
  }
  function Vector2F(x, y) {
    Companion_getInstance_16();
    this.p1q_1 = x;
    this.q1q_1 = y;
  }
  protoOf(Vector2F).f1t = function () {
    return '(' + get_niceStr_1(this.p1q_1) + ', ' + get_niceStr_1(this.q1q_1) + ')';
  };
  protoOf(Vector2F).toString = function () {
    return this.f1t();
  };
  protoOf(Vector2F).hashCode = function () {
    var result = getNumberHashCode(this.p1q_1);
    result = imul(result, 31) + getNumberHashCode(this.q1q_1) | 0;
    return result;
  };
  protoOf(Vector2F).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector2F))
      return false;
    var tmp0_other_with_cast = other instanceof Vector2F ? other : THROW_CCE();
    if (!equals(this.p1q_1, tmp0_other_with_cast.p1q_1))
      return false;
    if (!equals(this.q1q_1, tmp0_other_with_cast.q1q_1))
      return false;
    return true;
  };
  function Companion_17() {
    Companion_instance_18 = this;
    this.o1t_1 = new Vector4F(0.0, 0.0, 0.0, 0.0);
    this.p1t_1 = new Vector4F(1.0, 1.0, 1.0, 1.0);
  }
  var Companion_instance_18;
  function Companion_getInstance_17() {
    if (Companion_instance_18 == null)
      new Companion_17();
    return Companion_instance_18;
  }
  function Vector4F(x, y, z, w) {
    Companion_getInstance_17();
    this.q1t_1 = x;
    this.r1t_1 = y;
    this.s1t_1 = z;
    this.t1t_1 = w;
  }
  protoOf(Vector4F).toString = function () {
    return 'Vector4(' + get_niceStr_1(this.q1t_1) + ', ' + get_niceStr_1(this.r1t_1) + ', ' + get_niceStr_1(this.s1t_1) + ', ' + get_niceStr_1(this.t1t_1) + ')';
  };
  protoOf(Vector4F).hashCode = function () {
    var result = getNumberHashCode(this.q1t_1);
    result = imul(result, 31) + getNumberHashCode(this.r1t_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.s1t_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.t1t_1) | 0;
    return result;
  };
  protoOf(Vector4F).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector4F))
      return false;
    var tmp0_other_with_cast = other instanceof Vector4F ? other : THROW_CCE();
    if (!equals(this.q1t_1, tmp0_other_with_cast.q1t_1))
      return false;
    if (!equals(this.r1t_1, tmp0_other_with_cast.r1t_1))
      return false;
    if (!equals(this.s1t_1, tmp0_other_with_cast.s1t_1))
      return false;
    if (!equals(this.t1t_1, tmp0_other_with_cast.t1t_1))
      return false;
    return true;
  };
  function Companion_18() {
    Companion_instance_19 = this;
    var tmp = this;
    tmp.u1t_1 = new Vector3F(NaN, NaN, NaN);
    this.v1t_1 = new Vector3F(0.0, 0.0, 0.0);
    this.w1t_1 = new Vector3F(1.0, 1.0, 1.0);
    this.x1t_1 = new Vector3F(0.0, 0.0, 1.0);
    this.y1t_1 = new Vector3F(0.0, 0.0, -1.0);
    this.z1t_1 = new Vector3F(-1.0, 0.0, 0.0);
    this.a1u_1 = new Vector3F(1.0, 0.0, 0.0);
    this.b1u_1 = new Vector3F(0.0, 1.0, 0.0);
    this.c1u_1 = new Vector3F(0.0, -1.0, 0.0);
  }
  var Companion_instance_19;
  function Companion_getInstance_18() {
    if (Companion_instance_19 == null)
      new Companion_18();
    return Companion_instance_19;
  }
  function Vector3F(x, y, z) {
    Companion_getInstance_18();
    this.d1u_1 = x;
    this.e1u_1 = y;
    this.f1u_1 = z;
  }
  protoOf(Vector3F).toString = function () {
    return 'Vector3(' + get_niceStr_1(this.d1u_1) + ', ' + get_niceStr_1(this.e1u_1) + ', ' + get_niceStr_1(this.f1u_1) + ')';
  };
  protoOf(Vector3F).hashCode = function () {
    var result = getNumberHashCode(this.d1u_1);
    result = imul(result, 31) + getNumberHashCode(this.e1u_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.f1u_1) | 0;
    return result;
  };
  protoOf(Vector3F).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector3F))
      return false;
    var tmp0_other_with_cast = other instanceof Vector3F ? other : THROW_CCE();
    if (!equals(this.d1u_1, tmp0_other_with_cast.d1u_1))
      return false;
    if (!equals(this.e1u_1, tmp0_other_with_cast.e1u_1))
      return false;
    if (!equals(this.f1u_1, tmp0_other_with_cast.f1u_1))
      return false;
    return true;
  };
  function times(_this__u8e3s4, v) {
    // Inline function 'korlibs.math.geom.Vector2F.times' call
    // Inline function 'korlibs.math.geom.Vector2F.times' call
    return new Vector2F(v.p1q_1 * _this__u8e3s4, v.q1q_1 * _this__u8e3s4);
  }
  function Companion_19() {
    Companion_instance_20 = this;
    this.g1u_1 = new Vector2I(0, 0);
  }
  var Companion_instance_20;
  function Companion_getInstance_19() {
    if (Companion_instance_20 == null)
      new Companion_19();
    return Companion_instance_20;
  }
  function Vector2I_init_$Init$($this) {
    Vector2I.call($this, 0, 0);
    return $this;
  }
  function Vector2I_init_$Create$() {
    return Vector2I_init_$Init$(objectCreate(protoOf(Vector2I)));
  }
  function Vector2I(x, y) {
    Companion_getInstance_19();
    this.i1r_1 = x;
    this.j1r_1 = y;
  }
  protoOf(Vector2I).h1u = function (that) {
    return new Vector2I(this.i1r_1 + that.i1r_1 | 0, this.j1r_1 + that.j1r_1 | 0);
  };
  protoOf(Vector2I).s1r = function (that) {
    return new Vector2I(this.i1r_1 - that.i1r_1 | 0, this.j1r_1 - that.j1r_1 | 0);
  };
  protoOf(Vector2I).toString = function () {
    return '(' + this.i1r_1 + ', ' + this.j1r_1 + ')';
  };
  protoOf(Vector2I).hashCode = function () {
    var result = this.i1r_1;
    result = imul(result, 31) + this.j1r_1 | 0;
    return result;
  };
  protoOf(Vector2I).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector2I))
      return false;
    var tmp0_other_with_cast = other instanceof Vector2I ? other : THROW_CCE();
    if (!(this.i1r_1 === tmp0_other_with_cast.i1r_1))
      return false;
    if (!(this.j1r_1 === tmp0_other_with_cast.j1r_1))
      return false;
    return true;
  };
  function PointList() {
  }
  function Companion_20() {
  }
  var Companion_instance_21;
  function Companion_getInstance_20() {
    return Companion_instance_21;
  }
  function index($this, index, offset) {
    return imul(index, 2) + offset | 0;
  }
  function PointArrayList(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    this.o1u_1 = new Mixin();
    this.p1u_1 = false;
    this.q1u_1 = new DoubleArrayList(imul(capacity, 2));
  }
  protoOf(PointArrayList).c19 = function (_set____db54di) {
    this.o1u_1.c19(_set____db54di);
  };
  protoOf(PointArrayList).d19 = function () {
    return this.o1u_1.d19();
  };
  protoOf(PointArrayList).l = function () {
    return this.q1u_1.l() / 2 | 0;
  };
  protoOf(PointArrayList).f10 = function (index_0, dim) {
    return this.q1u_1.e10(index(this, index_0, dim));
  };
  protoOf(PointArrayList).j1u = function (index_0) {
    return this.q1u_1.e10(index(this, index_0, 0));
  };
  protoOf(PointArrayList).k1u = function (index_0) {
    return this.q1u_1.e10(index(this, index_0, 1));
  };
  protoOf(PointArrayList).b1 = function (index_0) {
    var i = index(this, index_0, 0);
    return new Vector2D(this.q1u_1.e10(i), this.q1u_1.e10(i + 1 | 0));
  };
  protoOf(PointArrayList).a1i = function () {
    this.q1u_1.l1();
    return this;
  };
  protoOf(PointArrayList).u1u = function (x, y) {
    this.q1u_1.v10(x, y);
    return this;
  };
  protoOf(PointArrayList).v1u = function (p) {
    return this.u1u(p.m1e_1, p.n1e_1);
  };
  protoOf(PointArrayList).w1u = function (p) {
    return this.x1u(p);
  };
  protoOf(PointArrayList).x1u = function (p) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.PointArrayList.addAll.<anonymous>' call
    // Inline function 'korlibs.math.geom.fastForEach' call
    var inductionVariable = 0;
    var last = p.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.geom.PointArrayList.addAll.<anonymous>.<anonymous>' call
        var name_for_destructuring_parameter_0_fjsvno = p.b1(n);
        var x = name_for_destructuring_parameter_0_fjsvno.bf();
        var y = name_for_destructuring_parameter_0_fjsvno.cf();
        this.u1u(x, y);
      }
       while (inductionVariable < last);
    return this;
  };
  protoOf(PointArrayList).y1u = function (p, index) {
    this.u1u(p.j1u(index), p.k1u(index));
  };
  protoOf(PointArrayList).r1u = function (other) {
    this.a1i();
    this.w1u(other);
    return this;
  };
  protoOf(PointArrayList).n1u = function (out) {
    return out.a1i().w1u(this);
  };
  protoOf(PointArrayList).g11 = function (index_0, count) {
    this.q1u_1.g11(index(this, index_0, 0), imul(count, 2));
    return this;
  };
  protoOf(PointArrayList).z1u = function (index, count, $super) {
    count = count === VOID ? 1 : count;
    return $super === VOID ? this.g11(index, count) : $super.g11.call(this, index, count);
  };
  protoOf(PointArrayList).equals = function (other) {
    var tmp;
    if (other instanceof PointArrayList) {
      tmp = this.q1u_1.equals(other.q1u_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(PointArrayList).hashCode = function () {
    return this.q1u_1.hashCode();
  };
  protoOf(PointArrayList).toString = function () {
    var sb = StringBuilder_init_$Create$();
    sb.j5(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp1_container = this.b1(n);
        var x = tmp1_container.bf();
        var y = tmp1_container.cf();
        if (!(n === 0)) {
          sb.i5(', ');
        }
        sb.j5(_Char___init__impl__6a9atx(40));
        if (x === round(x)) {
          sb.t7(numberToInt(x));
        } else {
          sb.w7(x);
        }
        sb.i5(', ');
        if (y === round(y)) {
          sb.t7(numberToInt(y));
        } else {
          sb.w7(y);
        }
        sb.j5(_Char___init__impl__6a9atx(41));
      }
       while (inductionVariable < last);
    sb.j5(_Char___init__impl__6a9atx(93));
    return sb.toString();
  };
  protoOf(PointArrayList).w1h = function (other, epsilon) {
    return this.t1u((!(other == null) ? isInterface(other, DoubleVectorList) : false) ? other : THROW_CCE(), epsilon);
  };
  function pointArrayListOf(p0, p1) {
    return (new PointArrayList(2)).v1u(p0).v1u(p1);
  }
  function pointArrayListOf_0(p0, p1, p2) {
    return (new PointArrayList(3)).v1u(p0).v1u(p1).v1u(p2);
  }
  function pointArrayListOf_1(p0, p1, p2, p3) {
    return (new PointArrayList(4)).v1u(p0).v1u(p1).v1u(p2).v1u(p3);
  }
  function DoubleVectorList() {
  }
  function appendGenericArray(_this__u8e3s4, size, appendElement) {
    _this__u8e3s4.i5('[');
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          _this__u8e3s4.i5(', ');
        }
        appendElement(_this__u8e3s4, n);
      }
       while (inductionVariable < size);
    _this__u8e3s4.i5(']');
  }
  function checkDimensions($this, dim) {
    if (!(dim === $this.a1v_1)) {
      // Inline function 'kotlin.error' call
      var message = 'Invalid dimensions ' + dim + ' != ' + $this.a1v_1;
      throw IllegalStateException_init_$Create$(toString(message));
    }
  }
  function DoubleVectorArrayList$vectorToStringBuilder$lambda(this$0, $index, $roundDecimalPlaces) {
    return function ($this$appendGenericArray, it) {
      var v = this$0.f10($index, it);
      appendNice($this$appendGenericArray, !($roundDecimalPlaces == null) ? roundDecimalPlaces(v, $roundDecimalPlaces) : v);
      return Unit_instance;
    };
  }
  function DoubleVectorArrayList(dimensions, capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    this.a1v_1 = dimensions;
    this.b1v_1 = new Mixin();
    this.c1v_1 = new DoubleArrayList(imul(capacity, this.a1v_1));
    this.d1v_1 = false;
  }
  protoOf(DoubleVectorArrayList).i1u = function () {
    return this.a1v_1;
  };
  protoOf(DoubleVectorArrayList).c19 = function (_set____db54di) {
    this.b1v_1.c19(_set____db54di);
  };
  protoOf(DoubleVectorArrayList).d19 = function () {
    return this.b1v_1.d19();
  };
  protoOf(DoubleVectorArrayList).l = function () {
    return this.c1v_1.l() / this.a1v_1 | 0;
  };
  protoOf(DoubleVectorArrayList).f10 = function (index, dim) {
    return this.c1v_1.b1(imul(index, this.a1v_1) + dim | 0);
  };
  protoOf(DoubleVectorArrayList).v10 = function (v0, v1) {
    // Inline function 'kotlin.also' call
    checkDimensions(this, 2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.DoubleVectorArrayList.add.<anonymous>' call
    this.c1v_1.v10(v0, v1);
    return Unit_instance;
  };
  protoOf(DoubleVectorArrayList).w10 = function (v0, v1, v2, v3) {
    // Inline function 'kotlin.also' call
    checkDimensions(this, 4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.DoubleVectorArrayList.add.<anonymous>' call
    this.c1v_1.w10(v0, v1, v2, v3);
    return Unit_instance;
  };
  protoOf(DoubleVectorArrayList).x10 = function (v0, v1, v2, v3, v4, v5) {
    // Inline function 'kotlin.also' call
    checkDimensions(this, 6);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.DoubleVectorArrayList.add.<anonymous>' call
    this.c1v_1.x10(v0, v1, v2, v3, v4, v5);
    return Unit_instance;
  };
  protoOf(DoubleVectorArrayList).e1v = function (index, out, roundDecimalPlaces) {
    appendGenericArray(out, this.a1v_1, DoubleVectorArrayList$vectorToStringBuilder$lambda(this, index, roundDecimalPlaces));
  };
  protoOf(DoubleVectorArrayList).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof DoubleVectorArrayList) {
      tmp_0 = this.a1v_1 === other.a1v_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.c1v_1.equals(other.c1v_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(DoubleVectorArrayList).hashCode = function () {
    return this.c1v_1.hashCode();
  };
  protoOf(DoubleVectorArrayList).toString = function () {
    return this.f1v(null);
  };
  protoOf(DoubleVectorArrayList).f1v = function (roundDecimalPlaces) {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.DoubleVectorArrayList.toString.<anonymous>' call
    this_0.i5('VectorArrayList[' + this.l() + '](\n');
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.i5(', \n');
        }
        this_0.i5('   ');
        this.e1v(n, this_0, roundDecimalPlaces);
      }
       while (inductionVariable < last);
    this_0.i5('\n)');
    return this_0.toString();
  };
  protoOf(DoubleVectorArrayList).l1 = function () {
    this.c1v_1.l1();
  };
  protoOf(DoubleVectorArrayList).w1h = function (other, epsilon) {
    return this.t1u((!(other == null) ? isInterface(other, DoubleVectorList) : false) ? other : THROW_CCE(), epsilon);
  };
  function Companion_21() {
  }
  var Companion_instance_22;
  function Companion_getInstance_21() {
    return Companion_instance_22;
  }
  function PointIntArrayList(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    this.g1v_1 = new Mixin();
    this.h1v_1 = false;
    this.i1v_1 = new IntArrayList(capacity);
    this.j1v_1 = new IntArrayList(capacity);
  }
  protoOf(PointIntArrayList).c19 = function (_set____db54di) {
    this.g1v_1.c19(_set____db54di);
  };
  protoOf(PointIntArrayList).d19 = function () {
    return this.g1v_1.d19();
  };
  protoOf(PointIntArrayList).l = function () {
    return this.i1v_1.l();
  };
  protoOf(PointIntArrayList).x = function () {
    return this.l() === 0;
  };
  protoOf(PointIntArrayList).l1 = function () {
    this.i1v_1.l1();
    this.j1v_1.l1();
  };
  protoOf(PointIntArrayList).g1i = function (x, y) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.PointIntArrayList.add.<anonymous>' call
    this.i1v_1.p11(x);
    this.j1v_1.p11(y);
    return this;
  };
  protoOf(PointIntArrayList).k1v = function (p) {
    return this.g1i(p.i1r_1, p.j1r_1);
  };
  protoOf(PointIntArrayList).j1u = function (index) {
    return this.i1v_1.e10(index);
  };
  protoOf(PointIntArrayList).k1u = function (index) {
    return this.j1v_1.e10(index);
  };
  protoOf(PointIntArrayList).toString = function () {
    var sb = StringBuilder_init_$Create$();
    sb.j5(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var x = this.j1u(n);
        var y = this.k1u(n);
        if (!(n === 0)) {
          sb.i5(', ');
        }
        sb.j5(_Char___init__impl__6a9atx(40));
        sb.t7(x);
        sb.i5(', ');
        sb.t7(y);
        sb.j5(_Char___init__impl__6a9atx(41));
      }
       while (inductionVariable < last);
    sb.j5(_Char___init__impl__6a9atx(93));
    return sb.toString();
  };
  function toStrokePointsList(_this__u8e3s4, info, mode, generateDebug, forceClosed) {
    mode = mode === VOID ? StrokePointsMode_NON_SCALABLE_POS_getInstance() : mode;
    generateDebug = generateDebug === VOID ? false : generateDebug;
    forceClosed = forceClosed === VOID ? null : forceClosed;
    return toStrokePointsList_0(_this__u8e3s4, info.l1v_1, info.q1v_1, info.o1v_1, info.p1v_1, info.r1v_1, mode, info.s1v_1, info.t1v_1, generateDebug, forceClosed);
  }
  var StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_instance;
  var StrokePointsMode_NON_SCALABLE_POS_instance;
  var StrokePointsMode_entriesInitialized;
  function StrokePointsMode_initEntries() {
    if (StrokePointsMode_entriesInitialized)
      return Unit_instance;
    StrokePointsMode_entriesInitialized = true;
    StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_instance = new StrokePointsMode('SCALABLE_POS_NORMAL_WIDTH', 0);
    StrokePointsMode_NON_SCALABLE_POS_instance = new StrokePointsMode('NON_SCALABLE_POS', 1);
  }
  function StrokePointsMode(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function toStrokePointsList_0(_this__u8e3s4, width, join, startCap, endCap, miterLimit, mode, lineDash, lineDashOffset, generateDebug, forceClosed) {
    join = join === VOID ? LineJoin_MITER_getInstance() : join;
    startCap = startCap === VOID ? LineCap_BUTT_getInstance() : startCap;
    endCap = endCap === VOID ? LineCap_BUTT_getInstance() : endCap;
    miterLimit = miterLimit === VOID ? 10.0 : miterLimit;
    mode = mode === VOID ? StrokePointsMode_NON_SCALABLE_POS_getInstance() : mode;
    lineDash = lineDash === VOID ? null : lineDash;
    lineDashOffset = lineDashOffset === VOID ? 0.0 : lineDashOffset;
    generateDebug = generateDebug === VOID ? false : generateDebug;
    forceClosed = forceClosed === VOID ? null : forceClosed;
    var tmp;
    if (!(lineDash == null)) {
      // Inline function 'kotlin.collections.flatMap' call
      // Inline function 'kotlin.collections.flatMapTo' call
      var destination = ArrayList_init_$Create$();
      var tmp0_iterator = _this__u8e3s4.s();
      while (tmp0_iterator.t()) {
        var element = tmp0_iterator.u();
        // Inline function 'korlibs.math.geom.bezier.toStrokePointsList.<anonymous>' call
        var list = toDashes(element, lineDash.h11(), lineDashOffset);
        addAll(destination, list);
      }
      tmp = destination;
    } else {
      tmp = _this__u8e3s4;
    }
    var curvesList = tmp;
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination_0 = ArrayList_init_$Create$_0(collectionSizeOrDefault(curvesList, 10));
    var tmp0_iterator_0 = curvesList.s();
    while (tmp0_iterator_0.t()) {
      var item = tmp0_iterator_0.u();
      // Inline function 'korlibs.math.geom.bezier.toStrokePointsList.<anonymous>' call
      // Inline function 'kotlin.also' call
      var this_0 = new StrokePointsBuilder(width / 2.0, mode, generateDebug);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.bezier.toStrokePointsList.<anonymous>.<anonymous>' call
      this_0.b1w(item, join, startCap, endCap, miterLimit, forceClosed);
      destination_0.p(this_0);
    }
    return destination_0;
  }
  function StrokePointsBuilder(width, mode, generateDebug) {
    mode = mode === VOID ? StrokePointsMode_NON_SCALABLE_POS_getInstance() : mode;
    generateDebug = generateDebug === VOID ? false : generateDebug;
    this.u1v_1 = width;
    this.v1v_1 = mode;
    this.w1v_1 = generateDebug;
    this.x1v_1 = 20;
    var tmp = this;
    var tmp_0;
    switch (this.v1v_1.t9_1) {
      case 0:
        tmp_0 = 6;
        break;
      case 1:
        tmp_0 = 2;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    tmp.y1v_1 = new DoubleVectorArrayList(tmp_0);
    this.z1v_1 = new PointArrayList();
    var tmp_1 = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp_1.a1w_1 = ArrayList_init_$Create$();
  }
  protoOf(StrokePointsBuilder).c1w = function () {
    return this.y1v_1;
  };
  protoOf(StrokePointsBuilder).toString = function () {
    return 'StrokePointsBuilder(' + this.u1v_1 + ', ' + this.y1v_1 + ')';
  };
  protoOf(StrokePointsBuilder).d1w = function (pos, normal, width, maxWidth) {
    var tmp0 = this.v1v_1.t9_1;
    if (tmp0 === 0) {
      // Inline function 'kotlin.math.absoluteValue' call
      var tmp$ret$0 = Math.abs(maxWidth);
      this.y1v_1.x10(pos.m1e_1, pos.n1e_1, normal.m1e_1, normal.n1e_1, width, tmp$ret$0);
    } else if (tmp0 === 1) {
      this.y1v_1.v10(pos.m1e_1 + normal.m1e_1 * width, pos.n1e_1 + normal.n1e_1 * width);
    }
  };
  protoOf(StrokePointsBuilder).e1w = function (pos, normal, width, maxWidth, $super) {
    maxWidth = maxWidth === VOID ? width : maxWidth;
    var tmp;
    if ($super === VOID) {
      this.d1w(pos, normal, width, maxWidth);
      tmp = Unit_instance;
    } else {
      tmp = $super.d1w.call(this, pos, normal, width, maxWidth);
    }
    return tmp;
  };
  protoOf(StrokePointsBuilder).f1w = function (center, pos, sign) {
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var dist = new Vector2D(pos.m1e_1 - center.m1e_1, pos.n1e_1 - center.n1e_1);
    var tmp;
    if (sign < 0.0) {
      // Inline function 'korlibs.math.geom.Vector2D.unaryMinus' call
      tmp = new Vector2D(-dist.m1e_1, -dist.n1e_1);
    } else {
      tmp = dist;
    }
    var normal = tmp;
    this.e1w(center, normal.c1t(), dist.a() * sign);
  };
  protoOf(StrokePointsBuilder).g1w = function (center, pos, sign, $super) {
    sign = sign === VOID ? 1.0 : sign;
    var tmp;
    if ($super === VOID) {
      this.f1w(center, pos, sign);
      tmp = Unit_instance;
    } else {
      tmp = $super.f1w.call(this, center, pos, sign);
    }
    return tmp;
  };
  protoOf(StrokePointsBuilder).h1w = function (pos, normal, width) {
    this.e1w(pos, normal, width);
    this.e1w(pos, normal, -width);
  };
  protoOf(StrokePointsBuilder).i1w = function (curr, next, kind, miterLimitRatio) {
    var commonPoint = curr.l1f(Companion_getInstance().j1e_1);
    var currTangent = curr.j1w(Companion_getInstance().j1e_1);
    var currNormal = curr.k1w(Companion_getInstance().j1e_1);
    var nextTangent = next.j1w(Companion_getInstance().g1e_1);
    var nextNormal = next.k1w(Companion_getInstance().g1e_1);
    var tmp = Companion_instance_39;
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scale = this.u1v_1;
    var that = new Vector2D(currNormal.m1e_1 * scale, currNormal.n1e_1 * scale);
    var tmp$ret$1 = new Vector2D(commonPoint.m1e_1 + that.m1e_1, commonPoint.n1e_1 + that.n1e_1);
    var currLine0 = tmp.l1w(tmp$ret$1, currTangent);
    var tmp_0 = Companion_instance_39;
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scale_0 = -this.u1v_1;
    var that_0 = new Vector2D(currNormal.m1e_1 * scale_0, currNormal.n1e_1 * scale_0);
    var tmp$ret$3 = new Vector2D(commonPoint.m1e_1 + that_0.m1e_1, commonPoint.n1e_1 + that_0.n1e_1);
    var currLine1 = tmp_0.l1w(tmp$ret$3, currTangent);
    var tmp_1 = Companion_instance_39;
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scale_1 = this.u1v_1;
    var that_1 = new Vector2D(nextNormal.m1e_1 * scale_1, nextNormal.n1e_1 * scale_1);
    var tmp$ret$5 = new Vector2D(commonPoint.m1e_1 + that_1.m1e_1, commonPoint.n1e_1 + that_1.n1e_1);
    var nextLine0 = tmp_1.l1w(tmp$ret$5, nextTangent);
    var tmp_2 = Companion_instance_39;
    // Inline function 'korlibs.math.geom.Vector2D.plus' call
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var scale_2 = -this.u1v_1;
    var that_2 = new Vector2D(nextNormal.m1e_1 * scale_2, nextNormal.n1e_1 * scale_2);
    var tmp$ret$7 = new Vector2D(commonPoint.m1e_1 + that_2.m1e_1, commonPoint.n1e_1 + that_2.n1e_1);
    var nextLine1 = tmp_2.l1w(tmp$ret$7, nextTangent);
    var intersection0 = lineIntersectionPoint(Companion_instance_39, currLine0, nextLine0);
    var intersection1 = lineIntersectionPoint(Companion_instance_39, currLine1, nextLine1);
    if (intersection0 == null ? true : intersection1 == null) {
      this.h1w(commonPoint, currNormal, this.u1v_1);
      return Unit_instance;
    }
    var direction = Companion_getInstance_15().v1s(currTangent, nextTangent);
    var miterLength = Companion_getInstance_15().r1s(intersection0, intersection1);
    var miterLimit = miterLimitRatio * this.u1v_1;
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    Companion_getInstance_2();
    var up = Companion_getInstance_15().k1j_1;
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    var x = nextTangent.m1e_1;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    // Inline function 'kotlin.math.atan2' call
    var x_0 = nextTangent.n1e_1;
    var radians = Math.atan2(x, x_0);
    var tmp$ret$9 = _Angle___init__impl__g23q1m(radians);
    var tmp_3 = adjustFromUp(tmp$ret$9, up);
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    Companion_getInstance_2();
    var up_0 = Companion_getInstance_15().k1j_1;
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    var x_1 = currTangent.m1e_1;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    // Inline function 'kotlin.math.atan2' call
    var x_2 = currTangent.n1e_1;
    var radians_0 = Math.atan2(x_1, x_2);
    var tmp$ret$13 = _Angle___init__impl__g23q1m(radians_0);
    var tmp$ret$15 = adjustFromUp(tmp$ret$13, up_0);
    var angle = Angle__minus_impl_xeau7k(tmp_3, tmp$ret$15);
    if (!kind.equals(LineJoin_MITER_getInstance()) ? true : miterLength > miterLimit) {
      var p1 = direction <= 0.0 ? projectedPoint(currLine0, commonPoint) : projectedPoint(nextLine1, commonPoint);
      var p2 = direction <= 0.0 ? projectedPoint(nextLine0, commonPoint) : projectedPoint(currLine1, commonPoint);
      var p3 = direction <= 0.0 ? lineIntersectionPoint(Companion_instance_39, currLine1, nextLine1) : lineIntersectionPoint(Companion_instance_39, currLine0, nextLine0);
      var p4Line = direction < 0.0 ? nextLine1 : nextLine0;
      var p4 = projectedPoint(p4Line, commonPoint);
      if (p3 == null) {
        p3 = p4;
      }
      var angleB = _Angle___get_absoluteValue__impl__4qoakv(Angle__plus_impl_26v37k(angle, get_degrees(180)));
      var angle2 = _Angle___get_absoluteValue__impl__4qoakv(Angle__umod_impl_4wilf7(angle, get_degrees(180)));
      var angle3 = Angle__compareTo_impl_a0hfds(angle2, get_degrees(90)) >= 0 ? Angle__minus_impl_xeau7k(get_degrees(180), angle2) : angle2;
      var ratio = toRatioClamped(Ratio__times_impl_ucdh7y_0(_Ratio___get_absoluteValue__impl__92ozif(_Angle___get_ratio__impl__ap3on4(angle3)), 4.0));
      var p5 = interpolate(toRatio(ratio), p4, ensureNotNull(p3));
      if (this.w1v_1) {
        this.a1w_1.p(nextLine1.o1w(1000.0).i11());
        this.a1w_1.p(currLine1.o1w(1000.0).i11());
        this.a1w_1.p(nextLine0.o1w(1000.0).i11());
        this.a1w_1.p(currLine0.o1w(1000.0).i11());
        this.a1w_1.p(Companion_instance_39.l1w(commonPoint, currTangent).o1w(1000.0).i11());
        this.a1w_1.p(Companion_instance_39.l1w(commonPoint, nextTangent).o1w(1000.0).i11());
        this.z1v_1.v1u(p3);
        this.z1v_1.v1u(p4);
        this.z1v_1.v1u(p5);
      }
      var p6 = p5;
      if (direction < 0.0) {
        this.g1w(commonPoint, p1);
        this.f1w(commonPoint, p6, -1.0);
        this.g1w(commonPoint, p2);
        this.f1w(commonPoint, p6, -1.0);
      } else {
        this.g1w(commonPoint, p6);
        this.f1w(commonPoint, p2, -1.0);
        this.g1w(commonPoint, p6);
        this.f1w(commonPoint, p1, -1.0);
      }
      return Unit_instance;
    }
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var d0 = new Vector2D(intersection0.m1e_1 - commonPoint.m1e_1, intersection0.n1e_1 - commonPoint.n1e_1);
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var d1 = new Vector2D(commonPoint.m1e_1 - intersection1.m1e_1, commonPoint.n1e_1 - intersection1.n1e_1);
    var tmp_4 = d0.c1t();
    var tmp_5 = d0.a();
    // Inline function 'kotlin.math.absoluteValue' call
    var this_0 = d0.a();
    var tmp$ret$18 = Math.abs(this_0);
    this.d1w(commonPoint, tmp_4, tmp_5, tmp$ret$18);
    var tmp_6 = d1.c1t();
    var tmp_7 = -d1.a();
    // Inline function 'kotlin.math.absoluteValue' call
    var this_1 = d1.a();
    var tmp$ret$19 = Math.abs(this_1);
    this.d1w(commonPoint, tmp_6, tmp_7, tmp$ret$19);
  };
  protoOf(StrokePointsBuilder).p1w = function (curr, ratio, kind) {
    switch (kind.t9_1) {
      case 1:
      case 2:
        // Inline function 'kotlin.let' call

        // Inline function 'kotlin.contracts.contract' call

        // Inline function 'korlibs.math.geom.bezier.StrokePointsBuilder.addCap.<anonymous>' call

        var it = curr.k1w(ratio).d1t();
        var tmp;
        if (equals(ratio, Companion_getInstance().j1e_1)) {
          // Inline function 'korlibs.math.geom.Vector2D.unaryMinus' call
          tmp = new Vector2D(-it.m1e_1, -it.n1e_1);
        } else {
          tmp = it;
        }

        var derivate = tmp;
        switch (kind.t9_1) {
          case 1:
            // Inline function 'korlibs.math.geom.Vector2D.plus' call

            var this_0 = curr.l1f(ratio);
            // Inline function 'korlibs.math.geom.Vector2D.times' call

            var scale = this.u1v_1;
            var that = new Vector2D(derivate.m1e_1 * scale, derivate.n1e_1 * scale);
            var tmp$ret$4 = new Vector2D(this_0.m1e_1 + that.m1e_1, this_0.n1e_1 + that.n1e_1);
            this.h1w(tmp$ret$4, curr.k1w(ratio), this.u1v_1);
            break;
          case 2:
            var mid = curr.l1f(ratio);
            var normal = curr.k1w(ratio);
            // Inline function 'korlibs.math.geom.Vector2D.plus' call

            // Inline function 'korlibs.math.geom.Vector2D.times' call

            var scale_0 = this.u1v_1;
            var that_0 = new Vector2D(normal.m1e_1 * scale_0, normal.n1e_1 * scale_0);
            var p0 = new Vector2D(mid.m1e_1 + that_0.m1e_1, mid.n1e_1 + that_0.n1e_1);
            // Inline function 'korlibs.math.geom.Vector2D.plus' call

            // Inline function 'korlibs.math.geom.Vector2D.times' call

            var scale_1 = -this.u1v_1;
            var that_1 = new Vector2D(normal.m1e_1 * scale_1, normal.n1e_1 * scale_1);
            var p3 = new Vector2D(mid.m1e_1 + that_1.m1e_1, mid.n1e_1 + that_1.n1e_1);
            var a = equals(ratio, Companion_getInstance().g1e_1) ? p0 : p3;
            var b = equals(ratio, Companion_getInstance().g1e_1) ? p3 : p0;
            this.q1w(a, b, ratio, mid);
            break;
          default:
            // Inline function 'kotlin.error' call

            var message = "Can't happen";
            throw IllegalStateException_init_$Create$(toString(message));
        }

        break;
      case 0:
        this.h1w(curr.l1f(ratio), curr.k1w(ratio), this.u1v_1);
        break;
    }
  };
  protoOf(StrokePointsBuilder).r1w = function (p0, p3, ratio, mid, nsteps) {
    // Inline function 'korlibs.math.geom.Companion.between' call
    Companion_getInstance_2();
    var up = Companion_getInstance_15().k1j_1;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var x0 = mid.m1e_1;
    var y0 = mid.n1e_1;
    var x1 = p0.m1e_1;
    var y1 = p0.n1e_1;
    var angleStart = Angle_between(x0, y0, x1, y1, up);
    // Inline function 'korlibs.math.geom.Companion.between' call
    Companion_getInstance_2();
    var up_0 = Companion_getInstance_15().k1j_1;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var x0_0 = mid.m1e_1;
    var y0_0 = mid.n1e_1;
    var x1_0 = p3.m1e_1;
    var y1_0 = p3.n1e_1;
    var angleEnd = Angle_between(x0_0, y0_0, x1_0, y1_0, up_0);
    if (equals(ratio, Companion_getInstance().j1e_1)) {
      // Inline function 'korlibs.math.geom.Companion.polar' call
      // Inline function 'korlibs.math.geom.Companion.polar' call
      var up_1 = Companion_getInstance_15().k1j_1;
      var tmp$ret$8 = new Vector2D(0.0 + Angle__cosine_impl_bgvzun(angleEnd, up_1) * 1.0, 0.0 + Angle__sine_impl_3l11xv(angleEnd, up_1) * 1.0);
      this.h1w(mid, tmp$ret$8, this.u1v_1);
    }
    var addAngle = Companion_getInstance_15().v1s(p0, p3) <= 0.0 ? Companion_getInstance_2().x1i_1 : Companion_getInstance_2().z1i_1;
    // Inline function 'korlibs.math.interpolation.Companion.forEachRatio' call
    Companion_getInstance();
    var NS = nsteps - 1 | 0;
    var NSd = NS;
    var start = true ? 0 : 1;
    var end = true ? NS : NS - 1 | 0;
    var inductionVariable = start;
    if (inductionVariable <= end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ratio_0 = n / NSd;
        // Inline function 'korlibs.math.geom.bezier.StrokePointsBuilder.addCurvePointsCap.<anonymous>' call
        var it = toRatio_0(ratio_0);
        var angle = interpolateAngleDenormalized(it, angleStart, angleEnd);
        // Inline function 'korlibs.math.geom.Companion.polar' call
        var this_0 = Companion_getInstance_15();
        var angle_0 = Angle__plus_impl_26v37k(angle, addAngle);
        // Inline function 'korlibs.math.geom.Companion.polar' call
        var up_2 = this_0.k1j_1;
        var dir = new Vector2D(0.0 + Angle__cosine_impl_bgvzun(angle_0, up_2) * 1.0, 0.0 + Angle__sine_impl_3l11xv(angle_0, up_2) * 1.0);
        this.d1w(mid, dir, 0.0, this.u1v_1);
        this.d1w(mid, dir, this.u1v_1, this.u1v_1);
      }
       while (!(n === end));
    if (equals(ratio, Companion_getInstance().g1e_1)) {
      // Inline function 'korlibs.math.geom.Companion.polar' call
      // Inline function 'korlibs.math.geom.Companion.polar' call
      var up_3 = Companion_getInstance_15().k1j_1;
      var tmp$ret$12 = new Vector2D(0.0 + Angle__cosine_impl_bgvzun(angleStart, up_3) * 1.0, 0.0 + Angle__sine_impl_3l11xv(angleStart, up_3) * 1.0);
      this.h1w(mid, tmp$ret$12, this.u1v_1);
    }
  };
  protoOf(StrokePointsBuilder).q1w = function (p0, p3, ratio, mid, nsteps, $super) {
    var tmp;
    if (mid === VOID) {
      // Inline function 'korlibs.math.geom.Companion.middle' call
      Companion_getInstance_15();
      // Inline function 'korlibs.math.geom.Vector2D.times' call
      // Inline function 'korlibs.math.geom.Vector2D.plus' call
      var this_0 = new Vector2D(p0.m1e_1 + p3.m1e_1, p0.n1e_1 + p3.n1e_1);
      tmp = new Vector2D(this_0.m1e_1 * 0.5, this_0.n1e_1 * 0.5);
    } else {
      tmp = mid;
    }
    mid = tmp;
    nsteps = nsteps === VOID ? this.x1v_1 : nsteps;
    var tmp_0;
    if ($super === VOID) {
      this.r1w(p0, p3, ratio, mid, nsteps);
      tmp_0 = Unit_instance;
    } else {
      tmp_0 = $super.r1w.call(this, p0, p3, new Ratio(ratio), mid, nsteps);
    }
    return tmp_0;
  };
  protoOf(StrokePointsBuilder).s1w = function (curr, nsteps) {
    // Inline function 'korlibs.math.interpolation.Companion.forEachRatio' call
    Companion_getInstance();
    var NS = nsteps - 1 | 0;
    var NSd = NS;
    var start = false ? 0 : 1;
    var end = false ? NS : NS - 1 | 0;
    var inductionVariable = start;
    if (inductionVariable <= end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ratio = n / NSd;
        // Inline function 'korlibs.math.geom.bezier.StrokePointsBuilder.addCurvePoints.<anonymous>' call
        var it = toRatio_0(ratio);
        this.h1w(curr.l1f(it), curr.k1w(it), this.u1v_1);
      }
       while (!(n === end));
  };
  protoOf(StrokePointsBuilder).t1w = function (curr, nsteps, $super) {
    nsteps = nsteps === VOID ? numberToInt(clamp_0(curr.a() / 10.0, 10.0, 100.0)) : nsteps;
    var tmp;
    if ($super === VOID) {
      this.s1w(curr, nsteps);
      tmp = Unit_instance;
    } else {
      tmp = $super.s1w.call(this, curr, nsteps);
    }
    return tmp;
  };
  protoOf(StrokePointsBuilder).b1w = function (curves, join, startCap, endCap, miterLimit, forceClosed) {
    var closed = forceClosed == null ? curves.v1w_1 : forceClosed;
    var curves_0 = curves.u1w_1;
    var inductionVariable = 0;
    var last = curves_0.l() - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var curr = getCyclic(curves_0, n + 0 | 0);
        var next = getCyclic(curves_0, n + 1 | 0);
        if (n === 0) {
          if (closed) {
            this.i1w(getCyclic(curves_0, n - 1 | 0), curr, join, miterLimit);
          } else {
            this.p1w(curr, Companion_getInstance().g1e_1, startCap);
          }
        }
        if (!(curr.b1x() === 1)) {
          this.t1w(curr);
        }
        if (n < (curves_0.l() - 1 | 0)) {
          this.i1w(curr, next, join, miterLimit);
        } else {
          if (closed) {
            this.i1w(curr, next, join, miterLimit);
          } else {
            this.p1w(curr, Companion_getInstance().j1e_1, endCap);
          }
        }
      }
       while (inductionVariable <= last);
  };
  function StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_getInstance() {
    StrokePointsMode_initEntries();
    return StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_instance;
  }
  function StrokePointsMode_NON_SCALABLE_POS_getInstance() {
    StrokePointsMode_initEntries();
    return StrokePointsMode_NON_SCALABLE_POS_instance;
  }
  function get_isConvex(_this__u8e3s4) {
    _init_properties__MathGeom_bezier_kt__br5r2i();
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var this_0 = isConvex$delegate;
      var property = isConvex$factory();
      var tmp0_elvis_lhs = this_0.g19_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = this_0.h19_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = this_0.g19_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  var isConvex$delegate;
  function CurveInfo(index, curve, startLength, endLength, bounds) {
    this.c1x_1 = index;
    this.d1x_1 = curve;
    this.e1x_1 = startLength;
    this.f1x_1 = endLength;
    this.g1x_1 = bounds;
  }
  protoOf(CurveInfo).d11 = function (length) {
    var containsLower = this.e1x_1;
    return length <= this.f1x_1 ? containsLower <= length : false;
  };
  protoOf(CurveInfo).a = function () {
    return this.f1x_1 - this.e1x_1;
  };
  protoOf(CurveInfo).toString = function () {
    return 'CurveInfo(index=' + this.c1x_1 + ', curve=' + this.d1x_1 + ', startLength=' + this.e1x_1 + ', endLength=' + this.f1x_1 + ', bounds=' + this.g1x_1 + ')';
  };
  protoOf(CurveInfo).hashCode = function () {
    var result = this.c1x_1;
    result = imul(result, 31) + this.d1x_1.hashCode() | 0;
    result = imul(result, 31) + getNumberHashCode(this.e1x_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.f1x_1) | 0;
    result = imul(result, 31) + this.g1x_1.hashCode() | 0;
    return result;
  };
  protoOf(CurveInfo).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CurveInfo))
      return false;
    var tmp0_other_with_cast = other instanceof CurveInfo ? other : THROW_CCE();
    if (!(this.c1x_1 === tmp0_other_with_cast.c1x_1))
      return false;
    if (!this.d1x_1.equals(tmp0_other_with_cast.d1x_1))
      return false;
    if (!equals(this.e1x_1, tmp0_other_with_cast.e1x_1))
      return false;
    if (!equals(this.f1x_1, tmp0_other_with_cast.f1x_1))
      return false;
    if (!this.g1x_1.equals(tmp0_other_with_cast.g1x_1))
      return false;
    return true;
  };
  function Curves$contiguous$delegate$lambda(this$0) {
    return function () {
      var inductionVariable = 1;
      var last = this$0.u1w_1.l();
      var tmp;
      if (inductionVariable < last) {
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var curr = this$0.u1w_1.b1(n - 1 | 0);
          var next = this$0.u1w_1.b1(n);
          if (!curr.a1f_1.i13().x1h(next.a1f_1.h13()))
            return false;
        }
         while (inductionVariable < last);
        tmp = Unit_instance;
      }
      return true;
    };
  }
  function Curves$infos$delegate$lambda(this$0) {
    return function () {
      var pos = 0.0;
      // Inline function 'kotlin.collections.mapIndexed' call
      var this_0 = this$0.u1w_1;
      // Inline function 'kotlin.collections.mapIndexedTo' call
      var destination = ArrayList_init_$Create$_0(collectionSizeOrDefault(this_0, 10));
      var index = 0;
      var tmp0_iterator = this_0.s();
      while (tmp0_iterator.t()) {
        var item = tmp0_iterator.u();
        // Inline function 'korlibs.math.geom.bezier.Curves.infos$delegate.<anonymous>.<anonymous>' call
        var tmp1 = index;
        index = tmp1 + 1 | 0;
        var index_0 = checkIndexOverflow(tmp1);
        var start = pos;
        pos = pos + item.a();
        var tmp$ret$0 = new CurveInfo(index_0, item, start, pos, item.h1x());
        destination.p(tmp$ret$0);
      }
      return destination;
    };
  }
  function Curves$length$delegate$lambda(this$0) {
    return function () {
      // Inline function 'korlibs.datastructure.sumOfDouble' call
      var sum = 0.0;
      var tmp0_iterator = this$0.i1x().s();
      while (tmp0_iterator.t()) {
        var element = tmp0_iterator.u();
        var tmp = sum;
        // Inline function 'korlibs.math.geom.bezier.Curves.length$delegate.<anonymous>.<anonymous>' call
        sum = tmp + element.a();
      }
      return sum;
    };
  }
  function Curves$findInfo$lambda($pos) {
    return function (it) {
      return it.d11($pos) ? 0 : it.f1x_1 < $pos ? -1 : 1;
    };
  }
  function Curves$ratioFromLength$lambda($length) {
    return function (it) {
      return it.f1x_1 < $length ? -1 : it.e1x_1 > $length ? 1 : 0;
    };
  }
  function Curves(beziers, closed) {
    this.u1w_1 = beziers;
    this.v1w_1 = closed;
    this.w1w_1 = new Mixin();
    this.x1w_1 = false;
    var tmp = this;
    tmp.y1w_1 = lazy_0(Curves$contiguous$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.z1w_1 = lazy_0(Curves$infos$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.a1x_1 = lazy_0(Curves$length$delegate$lambda(this));
  }
  protoOf(Curves).c19 = function (_set____db54di) {
    this.w1w_1.c19(_set____db54di);
  };
  protoOf(Curves).d19 = function () {
    return this.w1w_1.d19();
  };
  protoOf(Curves).i1x = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.z1w_1;
    infos$factory();
    return this_0.e2();
  };
  protoOf(Curves).a = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.a1x_1;
    length$factory();
    return this_0.e2();
  };
  protoOf(Curves).j1x = function (_this__u8e3s4) {
    return _Ratio___init__impl__9mwvn2(_this__u8e3s4.e1x_1 / this.a());
  };
  protoOf(Curves).k1x = function (_this__u8e3s4) {
    return _Ratio___init__impl__9mwvn2(_this__u8e3s4.f1x_1 / this.a());
  };
  protoOf(Curves).l1x = function (t) {
    var pos = Ratio__times_impl_ucdh7y_0(t, this.a());
    var tmp = this.i1x();
    var index = binarySearch(tmp, VOID, VOID, Curves$findInfo$lambda(pos));
    if (Ratio__compareTo_impl_z2ienc(t, Companion_getInstance().g1e_1) < 0)
      return first(this.i1x());
    if (Ratio__compareTo_impl_z2ienc(t, Companion_getInstance().j1e_1) > 0)
      return last(this.i1x());
    var tmp0_elvis_lhs = getOrNull(this.i1x(), index);
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      throw IllegalStateException_init_$Create$(toString('OUTSIDE'));
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    return tmp_0;
  };
  protoOf(Curves).l1f = function (t) {
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos = Ratio__times_impl_ucdh7y_0(t, this.a());
    var info = this.l1x(t);
    var posInCurve = pos - info.e1x_1;
    var ratioInCurve = _Ratio___init__impl__9mwvn2(posInCurve / info.a());
    // Inline function 'korlibs.math.geom.bezier.Curves.calc.<anonymous>' call
    return info.d1x_1.l1f(toRatio(ratioInCurve));
  };
  protoOf(Curves).k1w = function (t) {
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos = Ratio__times_impl_ucdh7y_0(t, this.a());
    var info = this.l1x(t);
    var posInCurve = pos - info.e1x_1;
    var ratioInCurve = _Ratio___init__impl__9mwvn2(posInCurve / info.a());
    // Inline function 'korlibs.math.geom.bezier.Curves.normal.<anonymous>' call
    return info.d1x_1.k1w(ratioInCurve);
  };
  protoOf(Curves).j1w = function (t) {
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos = Ratio__times_impl_ucdh7y_0(t, this.a());
    var info = this.l1x(t);
    var posInCurve = pos - info.e1x_1;
    var ratioInCurve = _Ratio___init__impl__9mwvn2(posInCurve / info.a());
    // Inline function 'korlibs.math.geom.bezier.Curves.tangent.<anonymous>' call
    return info.d1x_1.j1w(ratioInCurve);
  };
  protoOf(Curves).m1x = function (length) {
    if (length <= 0.0)
      return Companion_getInstance().g1e_1;
    if (length >= this.a())
      return Companion_getInstance().j1e_1;
    var tmp = this.i1x();
    var curveIndex = binarySearch(tmp, VOID, VOID, Curves$ratioFromLength$lambda(length));
    var index = curveIndex < 0 ? (-curveIndex | 0) + 1 | 0 : curveIndex;
    if (curveIndex < 0) {
      return Companion_getInstance().k1e_1;
    }
    var info = this.i1x().b1(index);
    var lengthInCurve = length - info.e1x_1;
    var ratioInCurve = info.d1x_1.m1x(lengthInCurve);
    return convertRange_0(ratioInCurve, Companion_getInstance().g1e_1, Companion_getInstance().j1e_1, this.j1x(info), this.k1x(info));
  };
  protoOf(Curves).n1x = function (len0, len1) {
    return this.o1x(this.m1x(len0), this.m1x(len1));
  };
  protoOf(Curves).o1x = function (t0, t1) {
    if (Ratio__compareTo_impl_z2ienc(t0, t1) > 0)
      return this.o1x(t1, t0);
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(Ratio__compareTo_impl_z2ienc(t0, t1) <= 0)) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException_init_$Create$(toString(message));
    }
    if (equals(t0, t1))
      return new Curves(emptyList(), false);
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos = Ratio__times_impl_ucdh7y_0(t0, this.a());
    var info = this.l1x(t0);
    var posInCurve = pos - info.e1x_1;
    var ratioInCurve = _Ratio___init__impl__9mwvn2(posInCurve / info.a());
    // Inline function 'korlibs.math.geom.bezier.Curves.split.<anonymous>' call
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos_0 = Ratio__times_impl_ucdh7y_0(t1, this.a());
    var info_0 = this.l1x(t1);
    var posInCurve_0 = pos_0 - info_0.e1x_1;
    var ratioInCurve_0 = _Ratio___init__impl__9mwvn2(posInCurve_0 / info_0.a());
    // Inline function 'korlibs.math.geom.bezier.Curves.split.<anonymous>.<anonymous>' call
    var tmp;
    if (info.c1x_1 === info_0.c1x_1) {
      tmp = listOf(info.d1x_1.o1x(ratioInCurve, ratioInCurve_0).p1x_1);
    } else {
      // Inline function 'kotlin.collections.buildList' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.collections.buildListInternal' call
      // Inline function 'kotlin.apply' call
      var this_0 = ArrayList_init_$Create$();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.bezier.Curves.split.<anonymous>.<anonymous>.<anonymous>' call
      if (!equals(ratioInCurve, Companion_getInstance().j1e_1)) {
        this_0.p(info.d1x_1.t1x(ratioInCurve).p1x_1);
      }
      var inductionVariable = info.c1x_1 + 1 | 0;
      var last = info_0.c1x_1;
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          this_0.p(this.i1x().b1(index).d1x_1);
        }
         while (inductionVariable < last);
      if (!equals(ratioInCurve_0, Companion_getInstance().g1e_1)) {
        this_0.p(info_0.d1x_1.u1x(ratioInCurve_0).p1x_1);
      }
      tmp = this_0.s2();
    }
    var tmp$ret$7 = tmp;
    return new Curves(tmp$ret$7, false);
  };
  protoOf(Curves).toString = function () {
    return 'Curves(beziers=' + this.u1w_1 + ', closed=' + this.v1w_1 + ')';
  };
  protoOf(Curves).hashCode = function () {
    var result = hashCode(this.u1w_1);
    result = imul(result, 31) + getBooleanHashCode(this.v1w_1) | 0;
    return result;
  };
  protoOf(Curves).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Curves))
      return false;
    var tmp0_other_with_cast = other instanceof Curves ? other : THROW_CCE();
    if (!equals(this.u1w_1, tmp0_other_with_cast.u1w_1))
      return false;
    if (!(this.v1w_1 === tmp0_other_with_cast.v1w_1))
      return false;
    return true;
  };
  function Bezier$Extrema$allt$delegate$lambda(this$0) {
    return function () {
      return combineSmallDistinctSorted(Companion_getInstance_22(), this$0.v1x_1, this$0.w1x_1);
    };
  }
  function Bezier$Extrema$xt01$delegate$lambda(this$0) {
    return function () {
      // Inline function 'kotlin.doubleArrayOf' call
      return primitiveArrayConcat([new Float64Array([0.0]), this$0.v1x_1, new Float64Array([1.0])]);
    };
  }
  function Bezier$Extrema$yt01$delegate$lambda(this$0) {
    return function () {
      // Inline function 'kotlin.doubleArrayOf' call
      return primitiveArrayConcat([new Float64Array([0.0]), this$0.w1x_1, new Float64Array([1.0])]);
    };
  }
  function map($this, v, ds, de, ts, te) {
    return convertRange_0(v, ds, de, ts, te);
  }
  function angle($this, o, v1, v2) {
    var dx1 = v1.m1e_1 - o.m1e_1;
    var dy1 = v1.n1e_1 - o.n1e_1;
    var dx2 = v2.m1e_1 - o.m1e_1;
    var dy2 = v2.n1e_1 - o.n1e_1;
    var cross = dx1 * dy2 - dy1 * dx2;
    var dot = dx1 * dx2 + dy1 * dy2;
    // Inline function 'kotlin.math.atan2' call
    return Math.atan2(cross, dot);
  }
  function compute($this, t, points) {
    var p = points;
    var order = p.l() - 1 | 0;
    if (equals(t, Companion_getInstance().g1e_1))
      return p.b1(0);
    if (equals(t, Companion_getInstance().j1e_1))
      return p.b1(order);
    if (order === 0)
      return p.b1(0);
    var mt = Ratio__toDouble_impl_o7epze(Ratio__minus_impl_s1uwyg(Companion_getInstance().j1e_1, t));
    var mt2 = mt * mt;
    var t2 = Ratio__toDouble_impl_o7epze(Ratio__times_impl_ucdh7y(t, t));
    var tmp;
    switch (order) {
      case 1:
        // Inline function 'korlibs.math.geom.Vector2D.plus' call

        // Inline function 'korlibs.math.geom.Vector2D.times' call

        var this_0 = p.b1(0);
        var this_1 = new Vector2D(this_0.m1e_1 * mt, this_0.n1e_1 * mt);
        // Inline function 'korlibs.math.geom.Vector2D.times' call

        var this_2 = p.b1(1);
        var scale = Ratio__toDouble_impl_o7epze(t);
        var that = new Vector2D(this_2.m1e_1 * scale, this_2.n1e_1 * scale);
        tmp = new Vector2D(this_1.m1e_1 + that.m1e_1, this_1.n1e_1 + that.n1e_1);
        break;
      case 2:
        var a = mt2;
        // Inline function 'korlibs.math.interpolation.times' call

        var b = mt * _Ratio___get_value__impl__57cli6(t) * 2;
        var c = t2;
        // Inline function 'korlibs.math.geom.Vector2D.plus' call

        // Inline function 'korlibs.math.geom.Vector2D.plus' call

        // Inline function 'korlibs.math.geom.Vector2D.times' call

        var this_3 = p.b1(0);
        var this_4 = new Vector2D(this_3.m1e_1 * a, this_3.n1e_1 * a);
        // Inline function 'korlibs.math.geom.Vector2D.times' call

        var this_5 = p.b1(1);
        var that_0 = new Vector2D(this_5.m1e_1 * b, this_5.n1e_1 * b);
        var this_6 = new Vector2D(this_4.m1e_1 + that_0.m1e_1, this_4.n1e_1 + that_0.n1e_1);
        // Inline function 'korlibs.math.geom.Vector2D.times' call

        var this_7 = p.b1(2);
        var that_1 = new Vector2D(this_7.m1e_1 * c, this_7.n1e_1 * c);
        tmp = new Vector2D(this_6.m1e_1 + that_1.m1e_1, this_6.n1e_1 + that_1.n1e_1);
        break;
      case 3:
        var a_0 = mt2 * mt;
        // Inline function 'korlibs.math.interpolation.times' call

        var b_0 = mt2 * _Ratio___get_value__impl__57cli6(t) * 3;
        var c_0 = mt * t2 * 3;
        var d = Ratio__times_impl_ucdh7y_0(t, t2);
        // Inline function 'korlibs.math.geom.Vector2D.plus' call

        // Inline function 'korlibs.math.geom.Vector2D.plus' call

        // Inline function 'korlibs.math.geom.Vector2D.plus' call

        // Inline function 'korlibs.math.geom.Vector2D.times' call

        var this_8 = p.b1(0);
        var this_9 = new Vector2D(this_8.m1e_1 * a_0, this_8.n1e_1 * a_0);
        // Inline function 'korlibs.math.geom.Vector2D.times' call

        var this_10 = p.b1(1);
        var that_2 = new Vector2D(this_10.m1e_1 * b_0, this_10.n1e_1 * b_0);
        var this_11 = new Vector2D(this_9.m1e_1 + that_2.m1e_1, this_9.n1e_1 + that_2.n1e_1);
        // Inline function 'korlibs.math.geom.Vector2D.times' call

        var this_12 = p.b1(2);
        var that_3 = new Vector2D(this_12.m1e_1 * c_0, this_12.n1e_1 * c_0);
        var this_13 = new Vector2D(this_11.m1e_1 + that_3.m1e_1, this_11.n1e_1 + that_3.n1e_1);
        // Inline function 'korlibs.math.geom.Vector2D.times' call

        var this_14 = p.b1(3);
        var that_4 = new Vector2D(this_14.m1e_1 * d, this_14.n1e_1 * d);
        tmp = new Vector2D(this_13.m1e_1 + that_4.m1e_1, this_13.n1e_1 + that_4.n1e_1);
        break;
      default:
        var reason = 'higher order curves';
        throw new NotImplementedError('An operation is not implemented: ' + reason);
    }
    return tmp;
  }
  function derive($this, points) {
    // Inline function 'kotlin.collections.arrayListOf' call
    var out = ArrayList_init_$Create$();
    var current = points;
    while (current.l() >= 2) {
      var new_0 = new PointArrayList(current.l() - 1 | 0);
      var c = current.l() - 1 | 0;
      var inductionVariable = 0;
      var last = current.l() - 1 | 0;
      if (inductionVariable < last)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.math.geom.Vector2D.times' call
          // Inline function 'korlibs.math.geom.Vector2D.minus' call
          var this_0 = current.b1(n + 1 | 0);
          var that = current.b1(n);
          var this_1 = new Vector2D(this_0.m1e_1 - that.m1e_1, this_0.n1e_1 - that.n1e_1);
          var tmp$ret$2 = new Vector2D(this_1.m1e_1 * c, this_1.n1e_1 * c);
          new_0.v1u(tmp$ret$2);
        }
         while (inductionVariable < last);
      out.p(new_0);
      current = new_0;
    }
    return out;
  }
  function align($this, points, line, out) {
    var p1 = line.q1k_1;
    var p2 = line.r1k_1;
    var tx = p1.m1e_1;
    var ty = p1.n1e_1;
    // Inline function 'kotlin.math.atan2' call
    var y = p2.n1e_1 - ty;
    var x = p2.m1e_1 - tx;
    var a = -Math.atan2(y, x);
    // Inline function 'korlibs.math.geom.fastForEach' call
    var inductionVariable = 0;
    var last = points.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.geom.bezier.Companion.align.<anonymous>' call
        var name_for_destructuring_parameter_0_fjsvno = points.b1(n);
        var x_0 = name_for_destructuring_parameter_0_fjsvno.bf();
        var y_0 = name_for_destructuring_parameter_0_fjsvno.cf();
        var tmp = x_0 - tx;
        // Inline function 'kotlin.math.cos' call
        var tmp_0 = tmp * Math.cos(a);
        var tmp_1 = y_0 - ty;
        // Inline function 'kotlin.math.sin' call
        var tmp_2 = tmp_0 - tmp_1 * Math.sin(a);
        var tmp_3 = x_0 - tx;
        // Inline function 'kotlin.math.sin' call
        var tmp_4 = tmp_3 * Math.sin(a);
        var tmp_5 = y_0 - ty;
        // Inline function 'kotlin.math.cos' call
        var tmp$ret$4 = Math.cos(a);
        out.u1u(tmp_2, tmp_4 + tmp_5 * tmp$ret$4);
      }
       while (inductionVariable < last);
    return out;
  }
  function align$default($this, points, line, out, $super) {
    out = out === VOID ? new PointArrayList() : out;
    return align($this, points, line, out);
  }
  function droots($this, p) {
    switch (p.length) {
      case 3:
        var a = p[0];
        var b = p[1];
        var c = p[2];
        var d = a - 2 * b + c;
        if (!(d === 0.0)) {
          // Inline function 'kotlin.math.sqrt' call
          var x = b * b - a * c;
          var m1 = -Math.sqrt(x);
          var m2 = -a + b;
          var v1 = -(m1 + m2) / d;
          var v2 = -(-m1 + m2) / d;
          return doubleArrayOfValid01$default($this, v1, v2);
        } else if (!(b === c) ? d === 0.0 : false) {
          return doubleArrayOfValid01$default($this, (2.0 * b - c) / (2.0 * (b - c)));
        }

        break;
      case 2:
        var a_0 = p[0];
        var b_0 = p[1];
        if (!(a_0 === b_0))
          return doubleArrayOfValid01$default($this, a_0 / (a_0 - b_0));
        break;
      default:
        break;
    }
    return doubleArrayOfValid01$default($this);
  }
  function doubleArrayOfValid01($this, v1, v2, v3) {
    var v1Valid = 0.0 <= v1 ? v1 <= 1.0 : false;
    var v2Valid = 0.0 <= v2 ? v2 <= 1.0 : false;
    var v3Valid = 0.0 <= v3 ? v3 <= 1.0 : false;
    var validCount = 0;
    if (v1Valid) {
      validCount = validCount + 1 | 0;
    }
    if (v2Valid) {
      validCount = validCount + 1 | 0;
    }
    if (v3Valid) {
      validCount = validCount + 1 | 0;
    }
    if (validCount === 0)
      return $this.e1y_1;
    var index = 0;
    var out = new Float64Array(validCount);
    if (v1Valid) {
      var tmp3 = index;
      index = tmp3 + 1 | 0;
      out[tmp3] = normalizeZero(v1);
    }
    if (v2Valid) {
      var tmp4 = index;
      index = tmp4 + 1 | 0;
      out[tmp4] = normalizeZero(v2);
    }
    if (v3Valid) {
      var tmp5 = index;
      index = tmp5 + 1 | 0;
      out[tmp5] = normalizeZero(v3);
    }
    return out;
  }
  function doubleArrayOfValid01$default($this, v1, v2, v3, $super) {
    var tmp;
    if (v1 === VOID) {
      tmp = NaN;
    } else {
      tmp = v1;
    }
    v1 = tmp;
    var tmp_0;
    if (v2 === VOID) {
      tmp_0 = NaN;
    } else {
      tmp_0 = v2;
    }
    v2 = tmp_0;
    var tmp_1;
    if (v3 === VOID) {
      tmp_1 = NaN;
    } else {
      tmp_1 = v3;
    }
    v3 = tmp_1;
    return doubleArrayOfValid01($this, v1, v2, v3);
  }
  function combineSmallDistinctSorted($this, a, b) {
    var out = new DoubleArrayList(a.length + b.length | 0);
    out.s10(a);
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < b.length) {
      // Inline function 'korlibs.math.geom.bezier.Companion.combineSmallDistinctSorted.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var it = b[tmp0];
      if (!out.d11(it)) {
        out.u10(it);
      }
    }
    sort(out);
    return out.h11();
  }
  function Bezier_init_$Init$($this) {
    Bezier_init_$Init$_0(Vector2D_init_$Create$(0.0, 0.0), Vector2D_init_$Create$(0.0, 0.0), $this);
    return $this;
  }
  function Bezier_init_$Create$() {
    return Bezier_init_$Init$(objectCreate(protoOf(Bezier)));
  }
  function Bezier_init_$Init$_0(p0, p1, $this) {
    Bezier.call($this, pointArrayListOf(p0, p1), Unit_instance);
    return $this;
  }
  function Bezier_init_$Create$_0(p0, p1) {
    return Bezier_init_$Init$_0(p0, p1, objectCreate(protoOf(Bezier)));
  }
  function Bezier_init_$Init$_1(p0, p1, p2, $this) {
    Bezier.call($this, pointArrayListOf_0(p0, p1, p2), Unit_instance);
    return $this;
  }
  function Bezier_init_$Create$_1(p0, p1, p2) {
    return Bezier_init_$Init$_1(p0, p1, p2, objectCreate(protoOf(Bezier)));
  }
  function Bezier_init_$Init$_2(p0, p1, p2, p3, $this) {
    Bezier.call($this, pointArrayListOf_1(p0, p1, p2, p3), Unit_instance);
    return $this;
  }
  function Bezier_init_$Create$_2(p0, p1, p2, p3) {
    return Bezier_init_$Init$_2(p0, p1, p2, p3, objectCreate(protoOf(Bezier)));
  }
  function Bezier_init_$Init$_3(points, $this) {
    Bezier.call($this, points.s1u(), Unit_instance);
    return $this;
  }
  function Bezier_init_$Create$_3(points) {
    return Bezier_init_$Init$_3(points, objectCreate(protoOf(Bezier)));
  }
  function _get_aligned__7fjidv($this) {
    // Inline function 'kotlin.getValue' call
    var this_0 = $this.b1f_1;
    aligned$factory();
    return this_0.e2();
  }
  function _getBoundingBox($this, m) {
    var xmin = 0.0;
    var ymin = 0.0;
    var xmax = 0.0;
    var ymax = 0.0;
    var inductionVariable = 0;
    if (inductionVariable <= 1)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ext = $this.f1y().g1y(n);
        var min = Infinity;
        var max = -Infinity;
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var n_0 = 0;
        while (n_0 < ext.length) {
          // Inline function 'korlibs.math.geom.bezier.Bezier._getBoundingBox.<anonymous>' call
          var tmp0 = n_0;
          n_0 = tmp0 + 1 | 0;
          var t = ext[tmp0];
          var p = $this.h1y(_Ratio___init__impl__9mwvn2(t));
          var value = n === 0 ? p.y1s(m) : p.z1s(m);
          // Inline function 'kotlin.math.min' call
          var a = min;
          min = Math.min(a, value);
          // Inline function 'kotlin.math.max' call
          var a_0 = max;
          max = Math.max(a_0, value);
        }
        if (n === 0) {
          xmin = min;
          xmax = max;
        } else {
          ymin = min;
          ymax = max;
        }
      }
       while (inductionVariable <= 1);
    return Companion_getInstance_10().g1q(xmin, ymin, xmax, ymax);
  }
  function findNearestLine($this, pX, pY, aX, aY, bX, bY, out) {
    out.l1y_1 = $this;
    var atobX = bX - aX;
    var atobY = bY - aY;
    var atopX = pX - aX;
    var atopY = pY - aY;
    var len = atobX * atobX + atobY * atobY;
    var dot = atopX * atobX + atopY * atobY;
    // Inline function 'kotlin.math.min' call
    // Inline function 'kotlin.math.max' call
    var b = dot / len;
    var b_0 = Math.max(0.0, b);
    var tmp$ret$1 = Math.min(1.0, b_0);
    var t = toRatio_0(tmp$ret$1);
    var tmp = out;
    // Inline function 'korlibs.math.interpolation.times' call
    var tmp_0 = aX + atobX * _Ratio___get_value__impl__57cli6(t);
    // Inline function 'korlibs.math.interpolation.times' call
    var tmp$ret$3 = atobY * _Ratio___get_value__impl__57cli6(t);
    tmp.i1y_1 = new Vector2D(tmp_0, aY + tmp$ret$3);
    out.j1y_1 = t;
    out.k1y_1 = Companion_getInstance_15().t1s(out.i1y_1.m1e_1, out.i1y_1.n1e_1, pX, pY);
    return out;
  }
  function ProjectedPoint(p, t, dSq) {
    p = p === VOID ? Vector2D_init_$Create$_1() : p;
    t = t === VOID ? Companion_getInstance().g1e_1 : t;
    dSq = dSq === VOID ? 0.0 : dSq;
    this.i1y_1 = p;
    this.j1y_1 = t;
    this.k1y_1 = dSq;
  }
  protoOf(ProjectedPoint).m1y = function (other, epsilon) {
    return (this.i1y_1.e1t(other.i1y_1, epsilon) ? isAlmostEquals_2(this.j1y_1, other.j1y_1, toRatio_0(epsilon)) : false) ? isAlmostEquals(this.k1y_1, other.k1y_1, epsilon) : false;
  };
  protoOf(ProjectedPoint).w1h = function (other, epsilon) {
    return this.m1y(other instanceof ProjectedPoint ? other : THROW_CCE(), epsilon);
  };
  protoOf(ProjectedPoint).toString = function () {
    return 'ProjectedPoint(p=' + this.i1y_1 + ', t=' + new Ratio(this.j1y_1) + ', dSq=' + this.k1y_1 + ')';
  };
  protoOf(ProjectedPoint).hashCode = function () {
    var result = this.i1y_1.hashCode();
    result = imul(result, 31) + Ratio__hashCode_impl_quhc2v(this.j1y_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.k1y_1) | 0;
    return result;
  };
  protoOf(ProjectedPoint).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ProjectedPoint))
      return false;
    var tmp0_other_with_cast = other instanceof ProjectedPoint ? other : THROW_CCE();
    if (!this.i1y_1.equals(tmp0_other_with_cast.i1y_1))
      return false;
    if (!equals(this.j1y_1, tmp0_other_with_cast.j1y_1))
      return false;
    if (!equals(this.k1y_1, tmp0_other_with_cast.k1y_1))
      return false;
    return true;
  };
  function Extrema(xt, yt) {
    this.v1x_1 = xt;
    this.w1x_1 = yt;
    var tmp = this;
    tmp.x1x_1 = lazy_0(Bezier$Extrema$allt$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.y1x_1 = lazy_0(Bezier$Extrema$xt01$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.z1x_1 = lazy_0(Bezier$Extrema$yt01$delegate$lambda(this));
  }
  protoOf(Extrema).n1y = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.y1x_1;
    xt01$factory();
    return this_0.e2();
  };
  protoOf(Extrema).o1y = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.z1x_1;
    yt01$factory();
    return this_0.e2();
  };
  protoOf(Extrema).g1y = function (index) {
    return index === 0 ? this.n1y() : this.o1y();
  };
  protoOf(Extrema).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof Extrema) {
      tmp_0 = contentEquals_0(this.v1x_1, other.v1x_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals_0(this.w1x_1, other.w1x_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Extrema).hashCode = function () {
    return contentHashCode_0(this.v1x_1) + imul(contentHashCode_0(this.w1x_1), 7) | 0;
  };
  protoOf(Extrema).toString = function () {
    return 'Extrema(x=' + contentToString(this.v1x_1) + ', y=' + contentToString(this.w1x_1) + ')';
  };
  function Companion_22() {
    Companion_instance_23 = this;
    var tmp = this;
    // Inline function 'kotlin.doubleArrayOf' call
    tmp.a1y_1 = new Float64Array([-0.06405689286260563, 0.06405689286260563, -0.1911188674736163, 0.1911188674736163, -0.3150426796961634, 0.3150426796961634, -0.4337935076260451, 0.4337935076260451, -0.5454214713888396, 0.5454214713888396, -0.6480936519369755, 0.6480936519369755, -0.7401241915785544, 0.7401241915785544, -0.820001985973903, 0.820001985973903, -0.8864155270044011, 0.8864155270044011, -0.9382745520027328, 0.9382745520027328, -0.9747285559713095, 0.9747285559713095, -0.9951872199970213, 0.9951872199970213]);
    var tmp_0 = this;
    // Inline function 'kotlin.doubleArrayOf' call
    tmp_0.b1y_1 = new Float64Array([0.12793819534675216, 0.12793819534675216, 0.1258374563468283, 0.1258374563468283, 0.12167047292780339, 0.12167047292780339, 0.1155056680537256, 0.1155056680537256, 0.10744427011596563, 0.10744427011596563, 0.09761865210411388, 0.09761865210411388, 0.08619016153195327, 0.08619016153195327, 0.0733464814110803, 0.0733464814110803, 0.05929858491543678, 0.05929858491543678, 0.04427743881741981, 0.04427743881741981, 0.028531388628933663, 0.028531388628933663, 0.0123412297999872, 0.0123412297999872]);
    this.c1y_1 = 6.283185307179586;
    this.d1y_1 = Line2D_init_$Create$_0(0, 0, 1, 0);
    this.e1y_1 = new Float64Array(0);
  }
  protoOf(Companion_22).p1y = function (p0, p1, p2, p3, t) {
    var out;
    // Inline function 'korlibs.math.geom.bezier.Companion.cubicCalc' call
    var x0 = p0.m1e_1;
    var y0 = p0.n1e_1;
    var x1 = p1.m1e_1;
    var y1 = p1.n1e_1;
    var x2 = p2.m1e_1;
    var y2 = p2.n1e_1;
    // Inline function 'kotlin.contracts.contract' call
    var cx = 3.0 * (x1 - x0);
    var bx = 3.0 * (x2 - x1) - cx;
    var ax = p3.m1e_1 - x0 - cx - bx;
    var cy = 3.0 * (y1 - y0);
    var by = 3.0 * (y2 - y1) - cy;
    var ay = p3.n1e_1 - y0 - cy - by;
    var tSquared = Ratio__times_impl_ucdh7y(t, t);
    var tCubed = Ratio__times_impl_ucdh7y(tSquared, t);
    // Inline function 'korlibs.math.interpolation.times' call
    var tmp = ax * _Ratio___get_value__impl__57cli6(tCubed);
    // Inline function 'korlibs.math.interpolation.times' call
    var tmp_0 = tmp + bx * _Ratio___get_value__impl__57cli6(tSquared);
    // Inline function 'korlibs.math.interpolation.times' call
    var x = tmp_0 + cx * _Ratio___get_value__impl__57cli6(t) + x0;
    // Inline function 'korlibs.math.interpolation.times' call
    var tmp_1 = ay * _Ratio___get_value__impl__57cli6(tCubed);
    // Inline function 'korlibs.math.interpolation.times' call
    var tmp_2 = tmp_1 + by * _Ratio___get_value__impl__57cli6(tSquared);
    // Inline function 'korlibs.math.interpolation.times' call
    var y = tmp_2 + cy * _Ratio___get_value__impl__57cli6(t) + y0;
    out = new Vector2D(x, y);
    return out;
  };
  protoOf(Companion_22).q1y = function (p, c, a, t) {
    var out;
    // Inline function 'korlibs.math.geom.bezier.Companion.quadCalc' call
    var x0 = p.m1e_1;
    var y0 = p.n1e_1;
    var xc = c.m1e_1;
    var yc = c.n1e_1;
    var x1 = a.m1e_1;
    var y1 = a.n1e_1;
    // Inline function 'kotlin.contracts.contract' call
    var t1 = 1 - Ratio__toDouble_impl_o7epze(t);
    var a_0 = t1 * t1;
    var c_0 = Ratio__times_impl_ucdh7y(t, t);
    // Inline function 'korlibs.math.interpolation.times' call
    var b = 2 * t1 * _Ratio___get_value__impl__57cli6(t);
    var x = a_0 * x0 + b * xc + Ratio__times_impl_ucdh7y_0(c_0, x1);
    var y = a_0 * y0 + b * yc + Ratio__times_impl_ucdh7y_0(c_0, y1);
    out = new Vector2D(x, y);
    return out;
  };
  protoOf(Companion_22).r1y = function (a, b) {
    return a + (b - a) * 0.6666666666666666;
  };
  protoOf(Companion_22).s1y = function (a, b) {
    return b + (a - b) * 0.6666666666666666;
  };
  var Companion_instance_23;
  function Companion_getInstance_22() {
    if (Companion_instance_23 == null)
      new Companion_22();
    return Companion_instance_23;
  }
  function Bezier$aligned$delegate$lambda(this$0) {
    return function () {
      return align$default(Companion_getInstance_22(), this$0.a1f_1, new Line2D(this$0.a1f_1.h13(), this$0.a1f_1.i13()));
    };
  }
  function Bezier$dpoints$delegate$lambda(this$0) {
    return function () {
      return derive(Companion_getInstance_22(), this$0.a1f_1);
    };
  }
  function Bezier$direction$delegate$lambda(this$0) {
    return function () {
      // Inline function 'korlibs.math.geom.Companion.between' call
      Companion_getInstance_2();
      var p0 = this$0.a1f_1.b1(0);
      var p1 = this$0.a1f_1.b1(this$0.b1x());
      var up = this$0.a1f_1.b1(1);
      // Inline function 'korlibs.math.geom.Companion.between' call
      var x0 = p0.m1e_1;
      var y0 = p0.n1e_1;
      var x1 = p1.m1e_1;
      var y1 = p1.n1e_1;
      var tmp$ret$1 = Angle_between(x0, y0, x1, y1, up);
      return new Angle(tmp$ret$1);
    };
  }
  function Bezier$extrema$delegate$lambda(this$0) {
    return function () {
      // Inline function 'kotlin.collections.map' call
      var this_0 = until(0, this$0.t1y());
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList_init_$Create$_0(collectionSizeOrDefault(this_0, 10));
      var tmp0_iterator = this_0.s();
      while (tmp0_iterator.t()) {
        var item = tmp0_iterator.u();
        // Inline function 'korlibs.math.geom.bezier.Bezier.extrema$delegate.<anonymous>.<anonymous>' call
        var tmp = Companion_getInstance_22();
        // Inline function 'korlibs.datastructure.mapDouble' call
        var this_1 = this$0.u1y().b1(0).m1u(item);
        // Inline function 'kotlin.also' call
        var this_2 = new Float64Array(this_1.length);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.datastructure.mapDouble.<anonymous>' call
        var inductionVariable = 0;
        var last = this_1.length;
        if (inductionVariable < last)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.math.geom.bezier.Bezier.extrema$delegate.<anonymous>.<anonymous>.<anonymous>' call
            this_2[n] = this_1[n];
          }
           while (inductionVariable < last);
        var out = droots(tmp, this_2);
        if (this$0.b1x() === 3)
          out = combineSmallDistinctSorted(Companion_getInstance_22(), out, droots(Companion_getInstance_22(), this$0.u1y().b1(1).m1u(item)));
        var tmp$ret$3 = out;
        destination.p(tmp$ret$3);
      }
      var out_0 = destination;
      return new Extrema(out_0.b1(0), out_0.b1(1));
    };
  }
  function Bezier$outerCircle$delegate$lambda(this$0) {
    return function () {
      return this$0.v1y().x1q();
    };
  }
  function Bezier$boundingBox$delegate$lambda(this$0) {
    return function () {
      return _getBoundingBox(this$0, Companion_getInstance_6().c1l_1);
    };
  }
  function Bezier$length$delegate$lambda(this$0) {
    return function () {
      var z = 0.5;
      var sum = 0.0;
      var inductionVariable = 0;
      var last = Companion_getInstance_22().a1y_1.length - 1 | 0;
      var tmp;
      if (inductionVariable <= last) {
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var t = _Ratio___init__impl__9mwvn2(z * Companion_getInstance_22().a1y_1[i] + z);
          var temp = this$0.w1y(t);
          sum = sum + Companion_getInstance_22().b1y_1[i] * temp.a();
        }
         while (inductionVariable <= last);
        tmp = Unit_instance;
      }
      return z * sum;
    };
  }
  function Bezier$lut$delegate$lambda(this$0) {
    return function () {
      return this$0.x1y();
    };
  }
  function Bezier$isLinear$delegate$lambda(this$0) {
    return function () {
      // Inline function 'korlibs.math.geom.Vector2D.minus' call
      var this_0 = this$0.a1f_1.b1(this$0.b1x());
      var that = this$0.a1f_1.b1(0);
      var baseLength = (new Vector2D(this_0.m1e_1 - that.m1e_1, this_0.n1e_1 - that.n1e_1)).a();
      // Inline function 'korlibs.datastructure.sumOfDouble' call
      var sum = 0.0;
      var tmp0_iterator = until(0, _get_aligned__7fjidv(this$0).l()).s();
      while (tmp0_iterator.t()) {
        var element = tmp0_iterator.u();
        var tmp = sum;
        // Inline function 'korlibs.math.geom.bezier.Bezier.isLinear$delegate.<anonymous>.<anonymous>' call
        // Inline function 'kotlin.math.absoluteValue' call
        var this_1 = _get_aligned__7fjidv(this$0).k1u(element);
        sum = tmp + Math.abs(this_1);
      }
      return sum < baseLength / 50.0;
    };
  }
  function Bezier$isSimple$delegate$lambda(this$0) {
    return function () {
      var tmp;
      if (this$0.b1x() === 3) {
        var a1 = angle(Companion_getInstance_22(), this$0.a1f_1.b1(0), this$0.a1f_1.b1(3), this$0.a1f_1.b1(1));
        var a2 = angle(Companion_getInstance_22(), this$0.a1f_1.b1(0), this$0.a1f_1.b1(3), this$0.a1f_1.b1(2));
        var tmp_0;
        if ((a1 > 0.0 ? a2 < 0.0 : false) ? true : a1 < 0.0 ? a2 > 0.0 : false) {
          return false;
        }
        tmp = tmp_0;
      }
      var n1 = this$0.k1w(Companion_getInstance().g1e_1);
      var n2 = this$0.k1w(Companion_getInstance().j1e_1);
      var s = n1.m1e_1 * n2.m1e_1 + n1.n1e_1 * n2.n1e_1;
      // Inline function 'kotlin.math.abs' call
      // Inline function 'kotlin.math.acos' call
      var x = Math.acos(s);
      return Math.abs(x) < get_PI() / 3.0;
    };
  }
  function Bezier(points, dummy) {
    Companion_getInstance_22();
    this.a1f_1 = points;
    if (this.a1f_1.l() > 4) {
      // Inline function 'kotlin.error' call
      var message = 'Only supports quad and cubic beziers';
      throw IllegalStateException_init_$Create$(toString(message));
    }
    var tmp = this;
    tmp.b1f_1 = lazy_0(Bezier$aligned$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.c1f_1 = lazy_0(Bezier$dpoints$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.d1f_1 = lazy_0(Bezier$direction$delegate$lambda(this));
    var tmp_2 = this;
    tmp_2.e1f_1 = lazy_0(Bezier$extrema$delegate$lambda(this));
    var tmp_3 = this;
    tmp_3.f1f_1 = lazy_0(Bezier$outerCircle$delegate$lambda(this));
    var tmp_4 = this;
    tmp_4.g1f_1 = lazy_0(Bezier$boundingBox$delegate$lambda(this));
    var tmp_5 = this;
    tmp_5.h1f_1 = lazy_0(Bezier$length$delegate$lambda(this));
    var tmp_6 = this;
    tmp_6.i1f_1 = lazy_0(Bezier$lut$delegate$lambda(this));
    var tmp_7 = this;
    tmp_7.j1f_1 = lazy_0(Bezier$isLinear$delegate$lambda(this));
    var tmp_8 = this;
    tmp_8.k1f_1 = lazy_0(Bezier$isSimple$delegate$lambda(this));
  }
  protoOf(Bezier).y1y = function (other, epsilon) {
    if (!(this.a1f_1.l() === other.a1f_1.l()))
      return false;
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var this_0 = until(0, this.a1f_1.l());
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.x();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.s();
      while (tmp0_iterator.t()) {
        var element = tmp0_iterator.u();
        // Inline function 'korlibs.math.geom.bezier.Bezier.isAlmostEquals.<anonymous>' call
        if (!this.a1f_1.b1(element).e1t(other.a1f_1.b1(element), epsilon)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  protoOf(Bezier).w1h = function (other, epsilon) {
    return this.y1y(other instanceof Bezier ? other : THROW_CCE(), epsilon);
  };
  protoOf(Bezier).h1y = function (t) {
    return this.z1y(t);
  };
  protoOf(Bezier).h1x = function () {
    return this.v1y();
  };
  protoOf(Bezier).a1z = function (m) {
    return _getBoundingBox(this, m);
  };
  protoOf(Bezier).l1f = function (t) {
    return this.z1y(t);
  };
  protoOf(Bezier).equals = function (other) {
    var tmp;
    if (other instanceof Bezier) {
      tmp = equals(this.a1f_1, other.a1f_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Bezier).hashCode = function () {
    return hashCode(this.a1f_1);
  };
  protoOf(Bezier).toString = function () {
    return 'Bezier(' + this.a1f_1 + ')';
  };
  protoOf(Bezier).t1y = function () {
    return 2;
  };
  protoOf(Bezier).b1x = function () {
    return this.a1f_1.l() - 1 | 0;
  };
  protoOf(Bezier).u1y = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.c1f_1;
    dpoints$factory();
    return this_0.e2();
  };
  protoOf(Bezier).f1y = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.e1f_1;
    extrema$factory();
    return this_0.e2();
  };
  protoOf(Bezier).b1z = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.f1f_1;
    outerCircle$factory();
    return this_0.e2();
  };
  protoOf(Bezier).v1y = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.g1f_1;
    boundingBox$factory();
    return this_0.e2();
  };
  protoOf(Bezier).a = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.h1f_1;
    length$factory_0();
    return this_0.e2();
  };
  protoOf(Bezier).c1z = function () {
    // Inline function 'kotlin.getValue' call
    var this_0 = this.i1f_1;
    lut$factory();
    return this_0.e2();
  };
  protoOf(Bezier).m1x = function (length) {
    return this.c1z().k1z(length).e1z_1;
  };
  protoOf(Bezier).l1z = function (steps, out) {
    out.l1();
    // Inline function 'korlibs.math.interpolation.Companion.forEachRatio' call
    Companion_getInstance();
    var NS = (steps + 1 | 0) - 1 | 0;
    var NSd = NS;
    var start = true ? 0 : 1;
    var end = true ? NS : NS - 1 | 0;
    var inductionVariable = start;
    if (inductionVariable <= end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ratio = n / NSd;
        // Inline function 'korlibs.math.geom.bezier.Bezier.getLUT.<anonymous>' call
        var t = toRatio_0(ratio);
        out.m1z(t, this.z1y(t));
      }
       while (!(n === end));
    return out;
  };
  protoOf(Bezier).x1y = function (steps, out, $super) {
    steps = steps === VOID ? 100 : steps;
    out = out === VOID ? CurveLUT_init_$Create$(this, steps + 1 | 0) : out;
    return $super === VOID ? this.l1z(steps, out) : $super.l1z.call(this, steps, out);
  };
  protoOf(Bezier).n1z = function (point, out) {
    out.l1y_1 = this;
    if (this.a1f_1.l() === 2) {
      var p0 = this.a1f_1.b1(0);
      var p1 = this.a1f_1.b1(1);
      return findNearestLine(this, point.m1e_1, point.n1e_1, p0.m1e_1, p0.n1e_1, p1.m1e_1, p1.n1e_1, out);
    }
    var LUT = this.c1z();
    var l = LUT.o1z();
    var closest = LUT.p1z(point);
    var mpos = closest.r1z_1;
    var t1 = (mpos - 1 | 0) / l;
    var t2 = (mpos + 1 | 0) / l;
    var step = 0.1 / l;
    var mdistSq = closest.q1z_1;
    var t = t1;
    var ft = t;
    mdistSq = mdistSq + 1;
    while (t < t2 + step) {
      var p = this.z1y(toRatio_0(t));
      var d = Companion_getInstance_15().s1s(point, p);
      if (d < mdistSq) {
        mdistSq = d;
        ft = t;
        out.i1y_1 = p;
      }
      t = t + step;
    }
    out.i1y_1 = this.z1y(toRatio_0(ft));
    out.j1y_1 = ft < 0.0 ? Companion_getInstance().g1e_1 : ft > 1.0 ? Companion_getInstance().j1e_1 : toRatio_0(ft);
    out.k1y_1 = mdistSq;
    return out;
  };
  protoOf(Bezier).s1z = function () {
    if (this.a1f_1.l() < 4)
      return Companion_getInstance_22().e1y_1;
    var p = _get_aligned__7fjidv(this);
    var p1 = p.b1(1);
    var p2 = p.b1(2);
    var p3 = p.b1(3);
    var a = p2.m1e_1 * p1.n1e_1;
    var b = p3.m1e_1 * p1.n1e_1;
    var c = p1.m1e_1 * p2.n1e_1;
    var d = p3.m1e_1 * p2.n1e_1;
    var v1 = 18.0 * (-3.0 * a + 2.0 * b + 3.0 * c - d);
    var v2 = 18.0 * (3.0 * a - b - 3.0 * c);
    var v3 = 18.0 * (c - a);
    if (isAlmostZero(v1)) {
      if (!isAlmostZero(v2)) {
        var t = -v3 / v2;
        if (0.0 <= t ? t <= 1.0 : false) {
          // Inline function 'kotlin.doubleArrayOf' call
          return new Float64Array([t]);
        }
      }
      return Companion_getInstance_22().e1y_1;
    }
    var d2 = 2.0 * v1;
    if (isAlmostEquals(d2, 0.0))
      return Companion_getInstance_22().e1y_1;
    var trm = v2 * v2 - 4.0 * v1 * v3;
    if (trm < 0.0)
      return Companion_getInstance_22().e1y_1;
    // Inline function 'kotlin.math.sqrt' call
    var sq = Math.sqrt(trm);
    var out0 = (sq - v2) / d2;
    var out1 = -(v2 + sq) / d2;
    var out = new DoubleArrayList(2);
    if (0.0 <= out0 ? out0 <= 1.0 : false) {
      out.u10(out0);
    }
    if (0.0 <= out1 ? out1 <= 1.0 : false) {
      out.u10(out1);
    }
    return out.h11();
  };
  protoOf(Bezier).z1y = function (t) {
    return compute(Companion_getInstance_22(), t, this.a1f_1);
  };
  protoOf(Bezier).t1z = function (t, normalize) {
    var out = compute(Companion_getInstance_22(), t, this.u1y().b1(0));
    if ((equals(t, Companion_getInstance().g1e_1) ? true : equals(t, Companion_getInstance().j1e_1)) ? isAlmostZero(out.a1t()) : false) {
      var inductionVariable = 0;
      if (inductionVariable < 10)
        $l$loop: do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlin.math.pow' call
          var n_0 = -(10 - n | 0) | 0;
          var newT = Math.pow(10.0, n_0);
          var nt = equals(t, Companion_getInstance().j1e_1) ? 1.0 - newT : newT;
          out = compute(Companion_getInstance_22(), _Ratio___init__impl__9mwvn2(nt), this.u1y().b1(0));
          if (!isAlmostZero(out.a1t()))
            break $l$loop;
        }
         while (inductionVariable < 10);
    }
    if (normalize)
      out = out.c1t();
    return out;
  };
  protoOf(Bezier).w1y = function (t, normalize, $super) {
    normalize = normalize === VOID ? false : normalize;
    return $super === VOID ? this.t1z(t, normalize) : $super.t1z.call(this, new Ratio(t), normalize);
  };
  protoOf(Bezier).u1z = function (t, normalize) {
    return this.t1z(t, normalize).d1t();
  };
  protoOf(Bezier).k1w = function (t) {
    return this.u1z(t, true);
  };
  protoOf(Bezier).j1w = function (t) {
    return this.t1z(t, true);
  };
  protoOf(Bezier).v1z = function (t, out) {
    if (this.b1x() < 2)
      return null;
    var p = this.a1f_1;
    out.y1u(p, 0);
    out.y1u(p, 1);
    out.y1u(p, 2);
    if (this.b1x() === 3) {
      out.y1u(p, 3);
    }
    while (p.l() > 1) {
      var next = new PointArrayList();
      var inductionVariable = 0;
      var last = p.l() - 1 | 0;
      if (inductionVariable < last)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var p_0 = interpolate(toRatio(t), p.b1(i), p.b1(i + 1 | 0));
          out.v1u(p_0);
          next.v1u(p_0);
        }
         while (inductionVariable < last);
      p = next;
    }
    return out;
  };
  protoOf(Bezier).w1z = function (t, out, $super) {
    out = out === VOID ? new PointArrayList() : out;
    return $super === VOID ? this.v1z(t, out) : $super.v1z.call(this, new Ratio(t), out);
  };
  protoOf(Bezier).o1x = function (t0, t1) {
    return new SubBezier(this.t1x(t0).u1x(map(Companion_getInstance_22(), t1, t0, Companion_getInstance().j1e_1, Companion_getInstance().g1e_1, Companion_getInstance().j1e_1)).p1x_1, t0, t1, this);
  };
  protoOf(Bezier).u1x = function (t) {
    return SubBezier_init_$Create$(this).u1x(t);
  };
  protoOf(Bezier).t1x = function (t) {
    return SubBezier_init_$Create$(this).t1x(t);
  };
  protoOf(Bezier).x1z = function () {
    return new Line2D(this.a1f_1.b1(0), this.a1f_1.b1(this.b1x()));
  };
  function BezierCurveFromIndices($this, indices, points) {
    var p = new PointArrayList(indices.length);
    var inductionVariable = 0;
    var last = indices.length;
    while (inductionVariable < last) {
      var index = indices[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      p.y1u(points, index);
    }
    return Bezier_init_$Create$_3(p);
  }
  function SubBezier_init_$Init$(curve, $this) {
    SubBezier.call($this, curve, Companion_getInstance().g1e_1, Companion_getInstance().j1e_1, null);
    return $this;
  }
  function SubBezier_init_$Create$(curve) {
    return SubBezier_init_$Init$(curve, objectCreate(protoOf(SubBezier)));
  }
  function Companion_23() {
    Companion_instance_24 = this;
    var tmp = this;
    // Inline function 'kotlin.intArrayOf' call
    var tmp_0 = new Int32Array([0, 3, 5]);
    // Inline function 'kotlin.intArrayOf' call
    var tmp$ret$1 = new Int32Array([0, 4, 7, 9]);
    tmp.y1z_1 = listOf_0([null, null, tmp_0, tmp$ret$1]);
    var tmp_1 = this;
    // Inline function 'kotlin.intArrayOf' call
    var tmp_2 = new Int32Array([5, 4, 2]);
    // Inline function 'kotlin.intArrayOf' call
    var tmp$ret$3 = new Int32Array([9, 8, 6, 3]);
    tmp_1.z1z_1 = listOf_0([null, null, tmp_2, tmp$ret$3]);
  }
  var Companion_instance_24;
  function Companion_getInstance_23() {
    if (Companion_instance_24 == null)
      new Companion_23();
    return Companion_instance_24;
  }
  function _split($this, t, hull, left) {
    var rt = convertRange_0(t, Companion_getInstance().g1e_1, Companion_getInstance().j1e_1, $this.q1x_1, $this.r1x_1);
    var rt1 = left ? $this.q1x_1 : rt;
    var rt2 = left ? rt : $this.r1x_1;
    var tmp;
    if ($this.p1x_1.b1x() < 2) {
      var p1 = $this.l1f(rt1);
      var p2 = $this.l1f(rt2);
      tmp = Bezier_init_$Create$_0(p1, p2);
    } else {
      var indices = left ? Companion_getInstance_23().y1z_1 : Companion_getInstance_23().z1z_1;
      tmp = BezierCurveFromIndices(Companion_getInstance_23(), ensureNotNull(indices.b1($this.p1x_1.b1x())), ensureNotNull(hull));
    }
    var curve = tmp;
    return new SubBezier(curve, rt1, rt2, $this.s1x_1);
  }
  function _splitLeft($this, t, hull) {
    return _split($this, t, hull, true);
  }
  function _splitLeft$default($this, t, hull, $super) {
    hull = hull === VOID ? $this.p1x_1.w1z(t) : hull;
    return _splitLeft($this, t, hull);
  }
  function _splitRight($this, t, hull) {
    return _split($this, t, hull, false);
  }
  function _splitRight$default($this, t, hull, $super) {
    hull = hull === VOID ? $this.p1x_1.w1z(t) : hull;
    return _splitRight($this, t, hull);
  }
  function SubBezier(curve, t1, t2, parent) {
    Companion_getInstance_23();
    this.p1x_1 = curve;
    this.q1x_1 = t1;
    this.r1x_1 = t2;
    this.s1x_1 = parent;
  }
  protoOf(SubBezier).l1f = function (t) {
    return this.p1x_1.l1f(convertRange_0(t, this.q1x_1, this.r1x_1, Companion_getInstance().g1e_1, Companion_getInstance().j1e_1));
  };
  protoOf(SubBezier).u1x = function (t) {
    return _splitLeft$default(this, t);
  };
  protoOf(SubBezier).t1x = function (t) {
    return _splitRight$default(this, t);
  };
  protoOf(SubBezier).toString = function () {
    return 'SubBezier[' + get_niceStr_0(Ratio__toDouble_impl_o7epze(this.q1x_1)) + '..' + get_niceStr_0(Ratio__toDouble_impl_o7epze(this.r1x_1)) + '](' + this.p1x_1 + ')';
  };
  function CurveLUT_init_$Init$(curve, capacity, $this) {
    CurveLUT.call($this, curve, new PointArrayList(capacity), new DoubleArrayList(capacity), new DoubleArrayList(capacity));
    return $this;
  }
  function CurveLUT_init_$Create$(curve, capacity) {
    return CurveLUT_init_$Init$(curve, capacity, objectCreate(protoOf(CurveLUT)));
  }
  function ClosestResult(mdistSq, mpos) {
    this.q1z_1 = mdistSq;
    this.r1z_1 = mpos;
  }
  function Estimation(point, ratio, length) {
    point = point === VOID ? Vector2D_init_$Create$_1() : point;
    ratio = ratio === VOID ? Companion_getInstance().g1e_1 : ratio;
    length = length === VOID ? 0.0 : length;
    this.d1z_1 = point;
    this.e1z_1 = ratio;
    this.f1z_1 = length;
  }
  protoOf(Estimation).toString = function () {
    return 'Estimation(point=' + this.d1z_1.f1t() + ', ratio=' + get_niceStr(this.e1z_1) + ', length=' + get_niceStr_0(this.f1z_1) + ')';
  };
  protoOf(Estimation).hashCode = function () {
    var result = this.d1z_1.hashCode();
    result = imul(result, 31) + Ratio__hashCode_impl_quhc2v(this.e1z_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.f1z_1) | 0;
    return result;
  };
  protoOf(Estimation).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Estimation))
      return false;
    var tmp0_other_with_cast = other instanceof Estimation ? other : THROW_CCE();
    if (!this.d1z_1.equals(tmp0_other_with_cast.d1z_1))
      return false;
    if (!equals(this.e1z_1, tmp0_other_with_cast.e1z_1))
      return false;
    if (!equals(this.f1z_1, tmp0_other_with_cast.f1z_1))
      return false;
    return true;
  };
  function estimateAt($this, values, value, out) {
    var result = binarySearch_0(values, value);
    if (_BSearchResult___get_found__impl__om04iz(result))
      return $this.a20(out, _BSearchResult___get_index__impl__b5kraz(result), 0.0);
    var index = _BSearchResult___get_nearIndex__impl__s8nq43(result);
    if (value <= 0.0)
      return $this.a20(out, 0, 0.0);
    if (index >= (values.l() - 1 | 0))
      return $this.a20(out, $this.h1z_1.l() - 1 | 0, 0.0);
    var ratio0 = values.b1(index);
    var ratio1 = values.b1(index + 1 | 0);
    var ratio = convertRange(value, ratio0, ratio1, 0.0, 1.0);
    return $this.a20(out, index, ratio);
  }
  function CurveLUT$toString$lambda(this$0) {
    return function (it) {
      return '' + this$0.i1z_1.b1(it) + ',len=' + this$0.b20().b1(it) + ': ' + this$0.h1z_1.b1(it);
    };
  }
  function CurveLUT(curve, points, ts, _estimatedLengths) {
    this.g1z_1 = curve;
    this.h1z_1 = points;
    this.i1z_1 = ts;
    this.j1z_1 = _estimatedLengths;
  }
  protoOf(CurveLUT).b20 = function () {
    if (this.j1z_1.x()) {
      this.j1z_1.u10(0.0);
    }
    while (this.j1z_1.l() < this.l()) {
      var pos = this.j1z_1.l();
      var prev = last_0(this.j1z_1);
      this.j1z_1.u10(prev + Companion_getInstance_15().r1s(this.h1z_1.b1(pos - 1 | 0), this.h1z_1.b1(pos)));
    }
    return this.j1z_1;
  };
  protoOf(CurveLUT).o1z = function () {
    return this.h1z_1.l() - 1 | 0;
  };
  protoOf(CurveLUT).l = function () {
    return this.h1z_1.l();
  };
  protoOf(CurveLUT).l1 = function () {
    this.h1z_1.a1i();
    this.i1z_1.l1();
    this.j1z_1.l1();
  };
  protoOf(CurveLUT).m1z = function (t, p) {
    this.h1z_1.v1u(p);
    this.i1z_1.u10(Ratio__toDouble_impl_o7epze(t));
  };
  protoOf(CurveLUT).p1z = function (point) {
    var mdistSq = Infinity;
    var mpos = 0;
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var d = Companion_getInstance_15().s1s(this.h1z_1.b1(n), point);
        if (d < mdistSq) {
          mdistSq = d;
          mpos = n;
        }
      }
       while (inductionVariable < last);
    return new ClosestResult(mdistSq, mpos);
  };
  protoOf(CurveLUT).a20 = function (_this__u8e3s4, index, ratio) {
    var ratio0 = toRatio_0(this.i1z_1.b1(index));
    var length0 = this.b20().b1(index);
    var point0 = this.h1z_1.b1(index);
    if (ratio === 0.0) {
      _this__u8e3s4.e1z_1 = ratio0;
      _this__u8e3s4.f1z_1 = length0;
      _this__u8e3s4.d1z_1 = point0;
    } else {
      var ratio1 = toRatio_0(this.i1z_1.b1(index + 1 | 0));
      var length1 = this.b20().b1(index + 1 | 0);
      var point1 = this.h1z_1.b1(index + 1 | 0);
      _this__u8e3s4.e1z_1 = interpolate_0(toRatio_0(ratio), ratio0, ratio1);
      _this__u8e3s4.f1z_1 = interpolate_1(toRatio_0(ratio), length0, length1);
      _this__u8e3s4.d1z_1 = interpolate(toRatio_0(ratio), point0, point1);
    }
    return _this__u8e3s4;
  };
  protoOf(CurveLUT).c20 = function (length, out) {
    return estimateAt(this, this.b20(), length, out);
  };
  protoOf(CurveLUT).k1z = function (length, out, $super) {
    out = out === VOID ? new Estimation() : out;
    return $super === VOID ? this.c20(length, out) : $super.c20.call(this, length, out);
  };
  protoOf(CurveLUT).toString = function () {
    var tmp = until(0, this.l());
    return 'CurveLUT[' + this.g1z_1 + '](' + joinToString(tmp, ', ', VOID, VOID, VOID, VOID, CurveLUT$toString$lambda(this)) + ')';
  };
  protoOf(CurveLUT).hashCode = function () {
    var result = hashCode(this.g1z_1);
    result = imul(result, 31) + this.h1z_1.hashCode() | 0;
    result = imul(result, 31) + this.i1z_1.hashCode() | 0;
    result = imul(result, 31) + this.j1z_1.hashCode() | 0;
    return result;
  };
  protoOf(CurveLUT).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CurveLUT))
      return false;
    var tmp0_other_with_cast = other instanceof CurveLUT ? other : THROW_CCE();
    if (!equals(this.g1z_1, tmp0_other_with_cast.g1z_1))
      return false;
    if (!this.h1z_1.equals(tmp0_other_with_cast.h1z_1))
      return false;
    if (!this.i1z_1.equals(tmp0_other_with_cast.i1z_1))
      return false;
    if (!this.j1z_1.equals(tmp0_other_with_cast.j1z_1))
      return false;
    return true;
  };
  function toCurves(_this__u8e3s4, closed) {
    _init_properties__MathGeom_bezier_kt__br5r2i();
    return new Curves(_this__u8e3s4, closed);
  }
  function toNonCurveSimplePointList(_this__u8e3s4, out) {
    out = out === VOID ? new PointArrayList() : out;
    _init_properties__MathGeom_bezier_kt__br5r2i();
    var curves = _this__u8e3s4;
    var beziers = curves.u1w_1;
    var epsilon = 1.0E-5;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < beziers.l()) {
      // Inline function 'korlibs.math.geom.bezier.toNonCurveSimplePointList.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var bezier = beziers.b1(tmp0);
      // Inline function 'kotlin.collections.isNotEmpty' call
      // Inline function 'kotlin.collections.isEmpty' call
      if (!(bezier.s1z().length === 0))
        return null;
      var points = bezier.a1f_1;
      // Inline function 'korlibs.math.geom.fastForEach' call
      var inductionVariable = 0;
      var last = points.l();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.math.geom.bezier.toNonCurveSimplePointList.<anonymous>.<anonymous>' call
          var p = points.b1(n_0);
          if (out.x() ? true : !out.i13().e1t(p, epsilon)) {
            out.v1u(p);
          }
        }
         while (inductionVariable < last);
    }
    if (out.i13().e1t(out.h13(), epsilon)) {
      out.z1u(out.l() - 1 | 0);
    }
    return out;
  }
  function Arc() {
    this.d20_1 = 0.5522847498307933;
  }
  protoOf(Arc).e20 = function (out, center, r, start, end, counterclockwise) {
    var x = center.bf();
    var y = center.cf();
    var startAngle = _Angle___get_normalized__impl__9s30ys(start);
    var endAngle1 = _Angle___get_normalized__impl__9s30ys(end);
    var endAngle = Angle__compareTo_impl_a0hfds(endAngle1, startAngle) < 0 ? Angle__plus_impl_26v37k(endAngle1, Companion_getInstance_2().b1j_1) : endAngle1;
    // Inline function 'korlibs.math.geom.min' call
    var a = Companion_getInstance_2().b1j_1;
    // Inline function 'korlibs.math.geom.abs' call
    var angle = Angle__minus_impl_xeau7k(endAngle, startAngle);
    var b = _Angle___get_absoluteValue__impl__4qoakv(angle);
    // Inline function 'kotlin.math.min' call
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var a_0 = _Angle___get_radians__impl__n00yt5(a);
    // Inline function 'korlibs.math.geom.Angle.internal' call
    var b_0 = _Angle___get_radians__impl__n00yt5(b);
    var tmp$ret$3 = Math.min(a_0, b_0);
    var remainingAngle = _Angle___init__impl__g23q1m(tmp$ret$3);
    if (Angle__compareTo_impl_a0hfds(_Angle___get_absoluteValue__impl__4qoakv(remainingAngle), Companion_getInstance_2().w1i_1) < 0 ? !equals(start, end) : false)
      remainingAngle = Companion_getInstance_2().b1j_1;
    var sgn1 = Angle__compareTo_impl_a0hfds(startAngle, endAngle) < 0 ? 1 : -1;
    var sgn = counterclockwise ? -sgn1 | 0 : sgn1;
    if (counterclockwise) {
      remainingAngle = Angle__minus_impl_xeau7k(Companion_getInstance_2().b1j_1, remainingAngle);
      if (Angle__compareTo_impl_a0hfds(_Angle___get_absoluteValue__impl__4qoakv(remainingAngle), Companion_getInstance_2().w1i_1) < 0 ? !equals(start, end) : false)
        remainingAngle = Companion_getInstance_2().b1j_1;
    }
    var a1 = startAngle;
    var index = 0;
    while (Angle__compareTo_impl_a0hfds(remainingAngle, Companion_getInstance_2().w1i_1) > 0) {
      var tmp = a1;
      // Inline function 'korlibs.math.geom.min' call
      var a_1 = remainingAngle;
      var b_1 = Companion_getInstance_2().y1i_1;
      // Inline function 'kotlin.math.min' call
      // Inline function 'korlibs.math.geom.Angle.internal' call
      var a_2 = _Angle___get_radians__impl__n00yt5(a_1);
      // Inline function 'korlibs.math.geom.Angle.internal' call
      var b_2 = _Angle___get_radians__impl__n00yt5(b_1);
      var tmp$ret$7 = Math.min(a_2, b_2);
      var tmp$ret$8 = _Angle___init__impl__g23q1m(tmp$ret$7);
      var a2 = Angle__plus_impl_26v37k(tmp, Angle__times_impl_v3s9y2_0(tmp$ret$8, sgn));
      var a_3 = Angle__div_impl_ut2btd(Angle__minus_impl_xeau7k(a2, a1), 2.0);
      var x4 = r * _Angle___get_cosine__impl__vprvmu(a_3);
      var y4 = r * _Angle___get_sine__impl__15nmyi(a_3);
      var x1 = x4;
      var y1 = -y4;
      var f = 0.5522847498307933 * _Angle___get_tangent__impl__vushdy(a_3);
      var x2 = x1 + f * y4;
      var y2 = y1 + f * x4;
      var x3 = x2;
      var y3 = -y2;
      var ar = Angle__plus_impl_26v37k(a_3, a1);
      var cos_ar = _Angle___get_cosine__impl__vprvmu(ar);
      var sin_ar = _Angle___get_sine__impl__15nmyi(ar);
      if (index === 0) {
        out.f20(new Vector2D(x + r * _Angle___get_cosine__impl__vprvmu(a1), y + r * _Angle___get_sine__impl__15nmyi(a1)));
      }
      out.g20(new Vector2D(x + x2 * cos_ar - y2 * sin_ar, y + x2 * sin_ar + y2 * cos_ar), new Vector2D(x + x3 * cos_ar - y3 * sin_ar, y + x3 * sin_ar + y3 * cos_ar), new Vector2D(x + r * _Angle___get_cosine__impl__vprvmu(a2), y + r * _Angle___get_sine__impl__15nmyi(a2)));
      index = index + 1 | 0;
      var tmp_0 = remainingAngle;
      // Inline function 'korlibs.math.geom.abs' call
      var angle_0 = Angle__minus_impl_xeau7k(a2, a1);
      var tmp$ret$9 = _Angle___get_absoluteValue__impl__4qoakv(angle_0);
      remainingAngle = Angle__minus_impl_xeau7k(tmp_0, tmp$ret$9);
      a1 = a2;
    }
    if (equals(startAngle, endAngle) ? !(index === 0) : false) {
      out.x18();
    }
  };
  var Arc_instance;
  function Arc_getInstance() {
    return Arc_instance;
  }
  function toVectorPath(_this__u8e3s4, out) {
    out = out === VOID ? new VectorPath() : out;
    _init_properties__MathGeom_bezier_kt__br5r2i();
    var first = {_v: true};
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < _this__u8e3s4.l()) {
      // Inline function 'korlibs.math.geom.bezier.toVectorPath.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var curves = _this__u8e3s4.b1(tmp0);
      if (curves instanceof Curves) {
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var this_0 = curves.u1w_1;
        var n_0 = 0;
        while (n_0 < this_0.l()) {
          // Inline function 'korlibs.math.geom.bezier.toVectorPath.<anonymous>.<anonymous>' call
          var tmp0_0 = n_0;
          n_0 = tmp0_0 + 1 | 0;
          var it = this_0.b1(tmp0_0);
          toVectorPath$bezier(first, out, it);
        }
        if (curves.v1w_1) {
          out.x18();
        }
      } else {
        if (curves instanceof Bezier) {
          toVectorPath$bezier(first, out, curves);
        } else {
          throw new NotImplementedError();
        }
      }
    }
    return out;
  }
  function toVectorPath_0(_this__u8e3s4, out) {
    out = out === VOID ? new VectorPath() : out;
    _init_properties__MathGeom_bezier_kt__br5r2i();
    return toVectorPath(listOf(_this__u8e3s4), out);
  }
  function toDashes(_this__u8e3s4, pattern, offset) {
    offset = offset === VOID ? 0.0 : offset;
    _init_properties__MathGeom_bezier_kt__br5r2i();
    if (pattern == null)
      return listOf(_this__u8e3s4);
    // Inline function 'kotlin.check' call
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.all' call
      var inductionVariable = 0;
      var last = pattern.length;
      while (inductionVariable < last) {
        var element = pattern[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.geom.bezier.toDashes.<anonymous>' call
        if (!(element <= 0.0)) {
          tmp$ret$1 = false;
          break $l$block;
        }
      }
      tmp$ret$1 = true;
    }
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!tmp$ret$1) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException_init_$Create$(toString(message));
    }
    var length = _this__u8e3s4.a();
    var current = offset;
    var dashNow = true;
    var index = 0;
    // Inline function 'kotlin.collections.arrayListOf' call
    var out = ArrayList_init_$Create$();
    while (current < length) {
      var tmp0 = index;
      index = tmp0 + 1 | 0;
      var len = getCyclic_0(pattern, tmp0);
      if (dashNow) {
        // Inline function 'kotlin.collections.plusAssign' call
        var element_0 = _this__u8e3s4.n1x(current, current + len);
        out.p(element_0);
      }
      current = current + len;
      dashNow = !dashNow;
    }
    return out;
  }
  function toVectorPath$bezier(first, $out, bezier) {
    var points = bezier.a1f_1;
    if (first._v) {
      $out.f20(points.h13());
      first._v = false;
    }
    switch (bezier.b1x()) {
      case 1:
        $out.s20(points.b1(1));
        break;
      case 2:
        $out.t20(points.b1(1), points.b1(2));
        break;
      case 3:
        $out.g20(points.b1(1), points.b1(2), points.b1(3));
        break;
      default:
        // Inline function 'kotlin.TODO' call

        throw new NotImplementedError();
    }
  }
  function isConvex$delegate$lambda($this$extraPropertyThis) {
    _init_properties__MathGeom_bezier_kt__br5r2i();
    return $this$extraPropertyThis.x1w_1 ? true : Convex_instance.u20($this$extraPropertyThis);
  }
  function isConvex$delegate$lambda_0($this$null, it) {
    _init_properties__MathGeom_bezier_kt__br5r2i();
    return it;
  }
  function isConvex$factory() {
    return getPropertyCallableRef('isConvex', 1, KProperty1, function (receiver) {
      return get_isConvex(receiver);
    }, null);
  }
  function infos$factory() {
    return getPropertyCallableRef('infos', 1, KProperty1, function (receiver) {
      return receiver.i1x();
    }, null);
  }
  function length$factory() {
    return getPropertyCallableRef('length', 1, KProperty1, function (receiver) {
      return receiver.a();
    }, null);
  }
  function aligned$factory() {
    return getPropertyCallableRef('aligned', 1, KProperty1, function (receiver) {
      return _get_aligned__7fjidv(receiver);
    }, null);
  }
  function dpoints$factory() {
    return getPropertyCallableRef('dpoints', 1, KProperty1, function (receiver) {
      return receiver.u1y();
    }, null);
  }
  function extrema$factory() {
    return getPropertyCallableRef('extrema', 1, KProperty1, function (receiver) {
      return receiver.f1y();
    }, null);
  }
  function outerCircle$factory() {
    return getPropertyCallableRef('outerCircle', 1, KProperty1, function (receiver) {
      return receiver.b1z();
    }, null);
  }
  function boundingBox$factory() {
    return getPropertyCallableRef('boundingBox', 1, KProperty1, function (receiver) {
      return receiver.v1y();
    }, null);
  }
  function length$factory_0() {
    return getPropertyCallableRef('length', 1, KProperty1, function (receiver) {
      return receiver.a();
    }, null);
  }
  function lut$factory() {
    return getPropertyCallableRef('lut', 1, KProperty1, function (receiver) {
      return receiver.c1z();
    }, null);
  }
  function xt01$factory() {
    return getPropertyCallableRef('xt01', 1, KProperty1, function (receiver) {
      return receiver.n1y();
    }, null);
  }
  function yt01$factory() {
    return getPropertyCallableRef('yt01', 1, KProperty1, function (receiver) {
      return receiver.o1y();
    }, null);
  }
  var properties_initialized__MathGeom_bezier_kt_sxrpnw;
  function _init_properties__MathGeom_bezier_kt__br5r2i() {
    if (!properties_initialized__MathGeom_bezier_kt_sxrpnw) {
      properties_initialized__MathGeom_bezier_kt_sxrpnw = true;
      // Inline function 'korlibs.datastructure.extraPropertyThis' call
      var default_0 = isConvex$delegate$lambda;
      var transform = isConvex$delegate$lambda_0;
      // Inline function 'korlibs.datastructure.PropertyThis.withTransform' call
      var this_0 = new PropertyThis(null, default_0);
      this_0.i19_1 = transform;
      isConvex$delegate = this_0;
    }
  }
  function Companion_24() {
  }
  protoOf(Companion_24).v20 = function (width, height, algo) {
    return new BinPacker(new Size2D(width, height), algo);
  };
  protoOf(Companion_24).w20 = function (width, height, algo) {
    return Companion_instance_25.v20(width, height, algo);
  };
  protoOf(Companion_24).x20 = function (width, height, algo, $super) {
    algo = algo === VOID ? MaxRects_init_$Create$(width, height) : algo;
    return $super === VOID ? this.w20(width, height, algo) : $super.w20.call(this, width, height, algo);
  };
  var Companion_instance_25;
  function Companion_getInstance_24() {
    return Companion_instance_25;
  }
  function ImageDoNotFitException(width, height, packer) {
    extendThrowable(this, "Size '" + width + 'x' + height + "' doesn't fit in '" + packer.c10() + 'x' + packer.d10() + "'");
    captureStack(this, ImageDoNotFitException);
    this.b21_1 = width;
    this.c21_1 = height;
    this.d21_1 = packer;
  }
  function BinPacker(size, algo) {
    algo = algo === VOID ? new MaxRects(size) : algo;
    this.y20_1 = size;
    this.z20_1 = algo;
    this.a21_1 = FastArrayList_init_$Create$();
  }
  protoOf(BinPacker).c10 = function () {
    return this.y20_1.e1q_1;
  };
  protoOf(BinPacker).d10 = function () {
    return this.y20_1.f1q_1;
  };
  protoOf(BinPacker).u1u = function (width, height) {
    var tmp0_elvis_lhs = this.e21(width, height);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw new ImageDoNotFitException(width, height, this);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(BinPacker).e21 = function (width, height) {
    var tmp0_elvis_lhs = this.z20_1.f21(new Size2D(width, height));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var rect = tmp;
    // Inline function 'kotlin.collections.plusAssign' call
    this.a21_1.p(rect);
    return rect;
  };
  function MaxRects_init_$Init$(width, height, $this) {
    MaxRects.call($this, new Size2D(width, height));
    return $this;
  }
  function MaxRects_init_$Create$(width, height) {
    return MaxRects_init_$Init$(width, height, objectCreate(protoOf(MaxRects)));
  }
  function quickFindPositionForNewNodeBestAreaFit($this, width, height) {
    var score = 1.7976931348623157E308;
    var areaFit;
    var bestNode = Companion_getInstance_10().e1l();
    var tmp0_iterator = $this.g21_1.s();
    while (tmp0_iterator.t()) {
      var r = tmp0_iterator.u();
      if (r.q1j_1 >= width ? r.r1j_1 >= height : false) {
        areaFit = r.q1j_1 * r.r1j_1 - width * height;
        if (areaFit < score) {
          bestNode = new RectangleD(r.o1j_1, r.p1j_1, width, height);
          score = areaFit;
        }
      }
    }
    return bestNode;
  }
  function splitFreeNode($this, freeNode, usedNode) {
    var newNode;
    if (((usedNode.u1j() >= freeNode.v1j() ? true : usedNode.v1j() <= freeNode.o1j_1) ? true : usedNode.w1j() >= freeNode.x1j()) ? true : usedNode.x1j() <= freeNode.w1j()) {
      return false;
    }
    if (usedNode.o1j_1 < freeNode.v1j() ? usedNode.v1j() > freeNode.o1j_1 : false) {
      if (usedNode.p1j_1 > freeNode.p1j_1 ? usedNode.p1j_1 < freeNode.x1j() : false) {
        newNode = freeNode.e1r(VOID, VOID, VOID, usedNode.p1j_1 - freeNode.p1j_1);
        $this.g21_1.p(newNode);
      }
      if (usedNode.x1j() < freeNode.x1j()) {
        newNode = freeNode.e1r(VOID, usedNode.x1j(), VOID, freeNode.x1j() - usedNode.x1j());
        $this.g21_1.p(newNode);
      }
    }
    if (usedNode.p1j_1 < freeNode.x1j() ? usedNode.x1j() > freeNode.p1j_1 : false) {
      if (usedNode.o1j_1 > freeNode.o1j_1 ? usedNode.o1j_1 < freeNode.v1j() : false) {
        newNode = freeNode.e1r(VOID, VOID, usedNode.o1j_1 - freeNode.o1j_1);
        $this.g21_1.p(newNode);
      }
      if (usedNode.v1j() < freeNode.v1j()) {
        newNode = freeNode.e1r(usedNode.v1j(), VOID, freeNode.v1j() - usedNode.v1j());
        $this.g21_1.p(newNode);
      }
    }
    return true;
  }
  function pruneFreeList($this) {
    var len = $this.g21_1.l();
    var i = 0;
    while (i < len) {
      var j = i + 1 | 0;
      var tmpRect = $this.g21_1.b1(i);
      $l$loop: while (j < len) {
        var tmpRect2 = $this.g21_1.b1(j);
        if (Companion_getInstance_10().i1q(tmpRect, tmpRect2)) {
          $this.g21_1.e1(i);
          i = i - 1 | 0;
          len = len - 1 | 0;
          break $l$loop;
        }
        if (Companion_getInstance_10().i1q(tmpRect2, tmpRect)) {
          $this.g21_1.e1(j);
          len = len - 1 | 0;
          j = j - 1 | 0;
        }
        j = j + 1 | 0;
      }
      i = i + 1 | 0;
    }
  }
  function MaxRects(maxSize) {
    this.g21_1 = fastArrayListOf([Companion_getInstance_10().i1k(Companion_getInstance_15().g1j_1, maxSize)]);
  }
  protoOf(MaxRects).f21 = function (size) {
    return this.h21(size);
  };
  protoOf(MaxRects).h21 = function (size) {
    var width = size.bf();
    var height = size.cf();
    if (width <= 0.0 ? height <= 0.0 : false)
      return Companion_getInstance_10().c1q(0, 0, 0, 0);
    var newNode = quickFindPositionForNewNodeBestAreaFit(this, width, height);
    if (newNode.r1j_1 === 0.0)
      return null;
    var numRectanglesToProcess = this.g21_1.l();
    var i = 0;
    while (i < numRectanglesToProcess) {
      if (splitFreeNode(this, this.g21_1.b1(i), newNode)) {
        this.g21_1.e1(i);
        numRectanglesToProcess = numRectanglesToProcess - 1 | 0;
        i = i - 1 | 0;
      }
      i = i + 1 | 0;
    }
    pruneFreeList(this);
    return newNode;
  };
  var HitTestDirection_ANY_instance;
  var HitTestDirection_UP_instance;
  var HitTestDirection_RIGHT_instance;
  var HitTestDirection_DOWN_instance;
  var HitTestDirection_LEFT_instance;
  function Companion_25() {
  }
  var Companion_instance_26;
  function Companion_getInstance_25() {
    return Companion_instance_26;
  }
  var HitTestDirection_entriesInitialized;
  function HitTestDirection_initEntries() {
    if (HitTestDirection_entriesInitialized)
      return Unit_instance;
    HitTestDirection_entriesInitialized = true;
    HitTestDirection_ANY_instance = new HitTestDirection('ANY', 0);
    HitTestDirection_UP_instance = new HitTestDirection('UP', 1);
    HitTestDirection_RIGHT_instance = new HitTestDirection('RIGHT', 2);
    HitTestDirection_DOWN_instance = new HitTestDirection('DOWN', 3);
    HitTestDirection_LEFT_instance = new HitTestDirection('LEFT', 4);
  }
  function HitTestDirection(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function HitTestDirection_ANY_getInstance() {
    HitTestDirection_initEntries();
    return HitTestDirection_ANY_instance;
  }
  function Convex() {
  }
  protoOf(Convex).u20 = function (curves) {
    var tmp0_elvis_lhs = toNonCurveSimplePointList(curves);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var points = tmp;
    return this.i21(points);
  };
  protoOf(Convex).i21 = function (vertices) {
    var base = 0;
    var n = vertices.l();
    var TWO_PI = get_PI2();
    if (n <= 3)
      return true;
    if (vertices.j1u(base) === vertices.j1u(n - 1 | 0) ? vertices.k1u(base) === vertices.k1u(n - 1 | 0) : false) {
      n = n - 1 | 0;
    }
    var old_x = vertices.j1u(n - 2 | 0);
    var old_y = vertices.k1u(n - 2 | 0);
    var new_x = vertices.j1u(n - 1 | 0);
    var new_y = vertices.k1u(n - 1 | 0);
    // Inline function 'kotlin.math.atan2' call
    var y = new_y - old_y;
    var x = new_x - old_x;
    var new_direction = Math.atan2(y, x);
    var old_direction;
    var angle_sum = Companion_getInstance_2().x1i_1;
    var orientation = 0.0;
    var inductionVariable = 0;
    var last = n;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        old_x = new_x;
        old_y = new_y;
        old_direction = new_direction;
        var tmp2 = base;
        base = tmp2 + 1 | 0;
        var p = tmp2;
        new_x = vertices.j1u(p);
        new_y = vertices.k1u(p);
        // Inline function 'kotlin.math.atan2' call
        var y_0 = new_y - old_y;
        var x_0 = new_x - old_x;
        new_direction = Math.atan2(y_0, x_0);
        if (old_x === new_x ? old_y === new_y : false) {
          return false;
        }
        var angle = new_direction - old_direction;
        if (angle <= -get_PI())
          angle = angle + TWO_PI;
        else if (angle > get_PI())
          angle = angle - TWO_PI;
        if (i === 0) {
          if (angle === 0.0) {
            return false;
          }
          orientation = angle > 0.0 ? 1.0 : -1.0;
        } else if (orientation * angle < 0.0) {
          return false;
        }
        var tmp = angle_sum;
        // Inline function 'korlibs.math.geom.Companion.fromRadians' call
        Companion_getInstance_2();
        var radians = angle;
        var tmp$ret$2 = _Angle___init__impl__g23q1m(radians);
        angle_sum = Angle__plus_impl_26v37k(tmp, tmp$ret$2);
      }
       while (inductionVariable < last);
    return isAlmostEquals_2(Companion_getInstance().j1e_1, _Ratio___get_absoluteValue__impl__92ozif(_Angle___get_ratio__impl__ap3on4(angle_sum)));
  };
  var Convex_instance;
  function Convex_getInstance() {
    return Convex_instance;
  }
  function get_cachedPoints(_this__u8e3s4) {
    _init_properties__MathGeom_shape_kt__uqeev8();
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var this_0 = cachedPoints$delegate;
      var property = cachedPoints$factory();
      var tmp0_elvis_lhs = this_0.g19_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = this_0.h19_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = this_0.g19_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  var cachedPoints$delegate;
  function getPoints2(_this__u8e3s4, out) {
    out = out === VOID ? new PointArrayList() : out;
    _init_properties__MathGeom_shape_kt__uqeev8();
    // Inline function 'korlibs.math.geom.shape.emitPoints2' call
    var i = Vector2D_init_$Create$_1();
    var l = Vector2D_init_$Create$_1();
    // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = _this__u8e3s4.h20_1;
    var n_0 = 0;
    while (n_0 < this_0.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp0_subject = this_0.e10(tmp0);
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = _this__u8e3s4.i20_1.b1(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var it = new Vector2D(tmp, _this__u8e3s4.i20_1.b1(tmp2));
        i = it;
        // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
        out.v1u(it);
        l = it;
      } else {
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = _this__u8e3s4.i20_1.b1(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var it_0 = new Vector2D(tmp_0, _this__u8e3s4.i20_1.b1(tmp4));
          // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
          out.v1u(it_0);
          l = it_0;
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        } else {
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = _this__u8e3s4.i20_1.b1(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var c = new Vector2D(tmp_1, _this__u8e3s4.i20_1.b1(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = _this__u8e3s4.i20_1.b1(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var a = new Vector2D(tmp_2, _this__u8e3s4.i20_1.b1(tmp8));
            var sum = Companion_getInstance_15().r1s(l, c) + Companion_getInstance_15().r1s(c, a);
            // Inline function 'korlibs.math.geom.shape.approximateCurve' call
            // Inline function 'kotlin.math.max' call
            var a_0 = numberToInt(sum);
            var rcurveSteps = Math.max(a_0, 20);
            var dt = 1.0 / rcurveSteps;
            var lastPos = {_v: Vector2D_init_$Create$_1()};
            var prevPos = {_v: Vector2D_init_$Create$_1()};
            var emittedCount = {_v: 0};
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
            var ratio = Companion_getInstance().g1e_1;
            // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
            lastPos._v = Companion_getInstance_22().q1y(l, c, a, ratio);
            var nStart = false ? 0 : 1;
            var nEnd = true ? rcurveSteps : rcurveSteps - 1 | 0;
            var inductionVariable = nStart;
            if (inductionVariable <= nEnd)
              do {
                var n_1 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var ratio_0 = _Ratio___init__impl__9mwvn2_0(n_1 * dt);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                var it_1 = Companion_getInstance_22().q1y(l, c, a, ratio_0);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
                out.v1u(it_1);
                emittedCount._v = emittedCount._v + 1 | 0;
                lastPos._v = prevPos._v;
                prevPos._v = it_1;
              }
               while (!(n_1 === nEnd));
            l = a;
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          } else {
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = _this__u8e3s4.i20_1.b1(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var c0 = new Vector2D(tmp_3, _this__u8e3s4.i20_1.b1(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = _this__u8e3s4.i20_1.b1(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var c1 = new Vector2D(tmp_4, _this__u8e3s4.i20_1.b1(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = _this__u8e3s4.i20_1.b1(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var a_1 = new Vector2D(tmp_5, _this__u8e3s4.i20_1.b1(tmp14));
              var sum_0 = Companion_getInstance_15().r1s(l, c0) + Companion_getInstance_15().r1s(c0, c1) + Companion_getInstance_15().r1s(c1, a_1);
              // Inline function 'korlibs.math.geom.shape.approximateCurve' call
              // Inline function 'kotlin.math.max' call
              var a_2 = numberToInt(sum_0);
              var rcurveSteps_0 = Math.max(a_2, 20);
              var dt_0 = 1.0 / rcurveSteps_0;
              var lastPos_0 = {_v: Vector2D_init_$Create$_1()};
              var prevPos_0 = {_v: Vector2D_init_$Create$_1()};
              var emittedCount_0 = {_v: 0};
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
              var ratio_1 = Companion_getInstance().g1e_1;
              // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
              lastPos_0._v = Companion_getInstance_22().p1y(l, c0, c1, a_1, ratio_1);
              var nStart_0 = false ? 0 : 1;
              var nEnd_0 = true ? rcurveSteps_0 : rcurveSteps_0 - 1 | 0;
              var inductionVariable_0 = nStart_0;
              if (inductionVariable_0 <= nEnd_0)
                do {
                  var n_2 = inductionVariable_0;
                  inductionVariable_0 = inductionVariable_0 + 1 | 0;
                  var ratio_2 = _Ratio___init__impl__9mwvn2_0(n_2 * dt_0);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                  var it_2 = Companion_getInstance_22().p1y(l, c0, c1, a_1, ratio_2);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
                  out.v1u(it_2);
                  emittedCount_0._v = emittedCount_0._v + 1 | 0;
                  lastPos_0._v = prevPos_0._v;
                  prevPos_0._v = it_2;
                }
                 while (!(n_2 === nEnd_0));
              l = a_1;
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            } else {
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
                var p = i;
                out.v1u(p);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              }
            }
          }
        }
      }
    }
    // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
    return out;
  }
  function AbstractShape2D() {
  }
  function getPoints2List(_this__u8e3s4) {
    _init_properties__MathGeom_shape_kt__uqeev8();
    // Inline function 'kotlin.collections.arrayListOf' call
    var out = ArrayList_init_$Create$();
    var current = {_v: new PointArrayList()};
    // Inline function 'korlibs.math.geom.shape.emitPoints2' call
    var i = Vector2D_init_$Create$_1();
    var l = Vector2D_init_$Create$_1();
    // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = _this__u8e3s4.h20_1;
    var n_0 = 0;
    while (n_0 < this_0.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp0_subject = this_0.e10(tmp0);
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = _this__u8e3s4.i20_1.b1(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var it = new Vector2D(tmp, _this__u8e3s4.i20_1.b1(tmp2));
        i = it;
        // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
        if (true) {
          getPoints2List$flush(current, out);
        }
        current._v.v1u(it);
        l = it;
      } else {
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = _this__u8e3s4.i20_1.b1(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var it_0 = new Vector2D(tmp_0, _this__u8e3s4.i20_1.b1(tmp4));
          // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
          if (false) {
            getPoints2List$flush(current, out);
          }
          current._v.v1u(it_0);
          l = it_0;
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        } else {
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = _this__u8e3s4.i20_1.b1(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var c = new Vector2D(tmp_1, _this__u8e3s4.i20_1.b1(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = _this__u8e3s4.i20_1.b1(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var a = new Vector2D(tmp_2, _this__u8e3s4.i20_1.b1(tmp8));
            var sum = Companion_getInstance_15().r1s(l, c) + Companion_getInstance_15().r1s(c, a);
            // Inline function 'korlibs.math.geom.shape.approximateCurve' call
            // Inline function 'kotlin.math.max' call
            var a_0 = numberToInt(sum);
            var rcurveSteps = Math.max(a_0, 20);
            var dt = 1.0 / rcurveSteps;
            var lastPos = {_v: Vector2D_init_$Create$_1()};
            var prevPos = {_v: Vector2D_init_$Create$_1()};
            var emittedCount = {_v: 0};
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
            var ratio = Companion_getInstance().g1e_1;
            // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
            lastPos._v = Companion_getInstance_22().q1y(l, c, a, ratio);
            var nStart = false ? 0 : 1;
            var nEnd = true ? rcurveSteps : rcurveSteps - 1 | 0;
            var inductionVariable = nStart;
            if (inductionVariable <= nEnd)
              do {
                var n_1 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var ratio_0 = _Ratio___init__impl__9mwvn2_0(n_1 * dt);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                var it_1 = Companion_getInstance_22().q1y(l, c, a, ratio_0);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
                if (false) {
                  getPoints2List$flush(current, out);
                }
                current._v.v1u(it_1);
                emittedCount._v = emittedCount._v + 1 | 0;
                lastPos._v = prevPos._v;
                prevPos._v = it_1;
              }
               while (!(n_1 === nEnd));
            l = a;
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          } else {
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = _this__u8e3s4.i20_1.b1(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var c0 = new Vector2D(tmp_3, _this__u8e3s4.i20_1.b1(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = _this__u8e3s4.i20_1.b1(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var c1 = new Vector2D(tmp_4, _this__u8e3s4.i20_1.b1(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = _this__u8e3s4.i20_1.b1(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var a_1 = new Vector2D(tmp_5, _this__u8e3s4.i20_1.b1(tmp14));
              var sum_0 = Companion_getInstance_15().r1s(l, c0) + Companion_getInstance_15().r1s(c0, c1) + Companion_getInstance_15().r1s(c1, a_1);
              // Inline function 'korlibs.math.geom.shape.approximateCurve' call
              // Inline function 'kotlin.math.max' call
              var a_2 = numberToInt(sum_0);
              var rcurveSteps_0 = Math.max(a_2, 20);
              var dt_0 = 1.0 / rcurveSteps_0;
              var lastPos_0 = {_v: Vector2D_init_$Create$_1()};
              var prevPos_0 = {_v: Vector2D_init_$Create$_1()};
              var emittedCount_0 = {_v: 0};
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
              var ratio_1 = Companion_getInstance().g1e_1;
              // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
              lastPos_0._v = Companion_getInstance_22().p1y(l, c0, c1, a_1, ratio_1);
              var nStart_0 = false ? 0 : 1;
              var nEnd_0 = true ? rcurveSteps_0 : rcurveSteps_0 - 1 | 0;
              var inductionVariable_0 = nStart_0;
              if (inductionVariable_0 <= nEnd_0)
                do {
                  var n_2 = inductionVariable_0;
                  inductionVariable_0 = inductionVariable_0 + 1 | 0;
                  var ratio_2 = _Ratio___init__impl__9mwvn2_0(n_2 * dt_0);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                  var it_2 = Companion_getInstance_22().p1y(l, c0, c1, a_1, ratio_2);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
                  if (false) {
                    getPoints2List$flush(current, out);
                  }
                  current._v.v1u(it_2);
                  emittedCount_0._v = emittedCount_0._v + 1 | 0;
                  lastPos_0._v = prevPos_0._v;
                  prevPos_0._v = it_2;
                }
                 while (!(n_2 === nEnd_0));
              l = a_1;
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            } else {
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
                var p = i;
                if (false) {
                  getPoints2List$flush(current, out);
                }
                current._v.v1u(p);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              }
            }
          }
        }
      }
    }
    // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
    getPoints2List$flush(current, out);
    return out;
  }
  function getPoints2List$flush(current, out) {
    if (!current._v.v11())
      return Unit_instance;
    out.p(current._v);
    current._v = new PointArrayList();
  }
  function cachedPoints$delegate$lambda($this$$receiver) {
    _init_properties__MathGeom_shape_kt__uqeev8();
    return getPoints2($this$$receiver);
  }
  function cachedPoints$factory() {
    return getPropertyCallableRef('cachedPoints', 1, KProperty1, function (receiver) {
      return get_cachedPoints(receiver);
    }, null);
  }
  var properties_initialized__MathGeom_shape_kt_lonmli;
  function _init_properties__MathGeom_shape_kt__uqeev8() {
    if (!properties_initialized__MathGeom_shape_kt_lonmli) {
      properties_initialized__MathGeom_shape_kt_lonmli = true;
      cachedPoints$delegate = new PropertyThis(VOID, cachedPoints$delegate$lambda);
    }
  }
  var SliceRotation_R0_instance;
  var SliceRotation_R90_instance;
  var SliceRotation_R180_instance;
  var SliceRotation_R270_instance;
  function Companion_26() {
    Companion_instance_27 = this;
    this.j21_1 = values_0();
  }
  protoOf(Companion_26).b1 = function (index) {
    return this.j21_1[umod_0(index, 4)];
  };
  var Companion_instance_27;
  function Companion_getInstance_26() {
    SliceRotation_initEntries();
    if (Companion_instance_27 == null)
      new Companion_26();
    return Companion_instance_27;
  }
  function values_0() {
    return [SliceRotation_R0_getInstance(), SliceRotation_R90_getInstance(), SliceRotation_R180_getInstance(), SliceRotation_R270_getInstance()];
  }
  var SliceRotation_entriesInitialized;
  function SliceRotation_initEntries() {
    if (SliceRotation_entriesInitialized)
      return Unit_instance;
    SliceRotation_entriesInitialized = true;
    SliceRotation_R0_instance = new SliceRotation('R0', 0);
    SliceRotation_R90_instance = new SliceRotation('R90', 1);
    SliceRotation_R180_instance = new SliceRotation('R180', 2);
    SliceRotation_R270_instance = new SliceRotation('R270', 3);
    Companion_getInstance_26();
  }
  function SliceRotation(name, ordinal) {
    Enum.call(this, name, ordinal);
    this.m21_1 = imul(this.t9_1, 90);
  }
  function _SliceOrientation___init__impl__ewylib(raw) {
    return raw;
  }
  function _SliceOrientation___get_raw__impl__tj3u8w($this) {
    return $this;
  }
  function _SliceOrientation___get_rotation__impl__m65r0o($this) {
    var tmp = Companion_getInstance_26();
    // Inline function 'korlibs.memory.extract2' call
    var tmp$ret$0 = (_SliceOrientation___get_raw__impl__tj3u8w($this) >>> 0 | 0) & 3;
    return tmp.b1(tmp$ret$0);
  }
  function _SliceOrientation___get_flipX__impl__lgb7bx($this) {
    // Inline function 'korlibs.memory.extractBool' call
    // Inline function 'korlibs.memory.extract1' call
    return !(((_SliceOrientation___get_raw__impl__tj3u8w($this) >>> 2 | 0) & 1) === 0);
  }
  function _SliceOrientation___init__impl__ewylib_0(rotation, flipX) {
    rotation = rotation === VOID ? SliceRotation_R0_getInstance() : rotation;
    flipX = flipX === VOID ? false : flipX;
    return _SliceOrientation___init__impl__ewylib(insert(insert2(0, rotation.t9_1, 0), flipX, 2));
  }
  function _SliceOrientation___get_indices__impl__km5on3($this) {
    var tmp = Companion_getInstance_27().n21_1;
    // Inline function 'korlibs.memory.extract3' call
    return tmp[(_SliceOrientation___get_raw__impl__tj3u8w($this) >>> 0 | 0) & 7];
  }
  function _SliceOrientation___get_isRotatedDeg90CwOrCcw__impl__98v1vq($this) {
    return _SliceOrientation___get_rotation__impl__m65r0o($this).equals(SliceRotation_R90_getInstance()) ? true : _SliceOrientation___get_rotation__impl__m65r0o($this).equals(SliceRotation_R270_getInstance());
  }
  function SliceOrientation__toString_impl_tdph6r($this) {
    var tmp = Companion_getInstance_27().o21_1;
    // Inline function 'korlibs.memory.extract3' call
    return tmp[(_SliceOrientation___get_raw__impl__tj3u8w($this) >>> 0 | 0) & 7];
  }
  function Companion_27() {
    Companion_instance_28 = this;
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(8), null);
    while (tmp_0 < 8) {
      var tmp_2 = tmp_0;
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.intArrayOf' call
      var this_0 = new Int32Array([0, 1, 2, 3]);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.slice.Companion.INDICES.<anonymous>' call
      var orientation = _SliceOrientation___init__impl__ewylib(tmp_2);
      if (_SliceOrientation___get_flipX__impl__lgb7bx(orientation)) {
        swap(this_0, 0, 1);
        swap(this_0, 3, 2);
      }
      rotateRight(this_0, _SliceOrientation___get_rotation__impl__m65r0o(orientation).t9_1);
      tmp_1[tmp_2] = this_0;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.n21_1 = tmp_1;
    var tmp_3 = this;
    var tmp_4 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_5 = fillArrayVal(Array(8), null);
    while (tmp_4 < 8) {
      var tmp_6 = tmp_4;
      var orientation_0 = _SliceOrientation___init__impl__ewylib(tmp_6);
      // Inline function 'kotlin.text.buildString' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.apply' call
      var this_1 = StringBuilder_init_$Create$();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.slice.Companion.NAMES.<anonymous>' call
      this_1.i5(_SliceOrientation___get_flipX__impl__lgb7bx(orientation_0) ? 'MIRROR_HORIZONTAL_ROTATE_' : 'ROTATE_');
      this_1.t7(_SliceOrientation___get_rotation__impl__m65r0o(orientation_0).m21_1);
      tmp_5[tmp_6] = this_1.toString();
      tmp_4 = tmp_4 + 1 | 0;
    }
    tmp_3.o21_1 = tmp_5;
    var tmp_7 = this;
    var tmp0_rotation = SliceRotation_R0_getInstance();
    tmp_7.p21_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation, false);
    var tmp_8 = this;
    var tmp0_rotation_0 = SliceRotation_R90_getInstance();
    tmp_8.q21_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_0, false);
    var tmp_9 = this;
    var tmp0_rotation_1 = SliceRotation_R180_getInstance();
    tmp_9.r21_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_1, false);
    var tmp_10 = this;
    var tmp0_rotation_2 = SliceRotation_R270_getInstance();
    tmp_10.s21_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_2, false);
    this.t21_1 = _SliceOrientation___init__impl__ewylib_0(VOID, true);
    var tmp_11 = this;
    var tmp0_rotation_3 = SliceRotation_R90_getInstance();
    tmp_11.u21_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_3, true);
    var tmp_12 = this;
    var tmp0_rotation_4 = SliceRotation_R180_getInstance();
    tmp_12.v21_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_4, true);
    var tmp_13 = this;
    var tmp0_rotation_5 = SliceRotation_R270_getInstance();
    tmp_13.w21_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_5, true);
    this.x21_1 = listOf_0([new SliceOrientation(this.p21_1), new SliceOrientation(this.q21_1), new SliceOrientation(this.r21_1), new SliceOrientation(this.s21_1), new SliceOrientation(this.t21_1), new SliceOrientation(this.u21_1), new SliceOrientation(this.v21_1), new SliceOrientation(this.w21_1)]);
  }
  var Companion_instance_28;
  function Companion_getInstance_27() {
    if (Companion_instance_28 == null)
      new Companion_27();
    return Companion_instance_28;
  }
  function SliceOrientation__hashCode_impl_7mjkdw($this) {
    return $this;
  }
  function SliceOrientation__equals_impl_h4gywg($this, other) {
    if (!(other instanceof SliceOrientation))
      return false;
    if (!($this === (other instanceof SliceOrientation ? other.y21_1 : THROW_CCE())))
      return false;
    return true;
  }
  function SliceOrientation(raw) {
    Companion_getInstance_27();
    this.y21_1 = raw;
  }
  protoOf(SliceOrientation).toString = function () {
    return SliceOrientation__toString_impl_tdph6r(this.y21_1);
  };
  protoOf(SliceOrientation).hashCode = function () {
    return SliceOrientation__hashCode_impl_7mjkdw(this.y21_1);
  };
  protoOf(SliceOrientation).equals = function (other) {
    return SliceOrientation__equals_impl_h4gywg(this.y21_1, other);
  };
  function RectSlice(base, rect, orientation, padding, name) {
    orientation = orientation === VOID ? Companion_getInstance_27().p21_1 : orientation;
    padding = padding === VOID ? Companion_getInstance_5().t1k_1 : padding;
    name = name === VOID ? null : name;
    this.z21_1 = base;
    this.a22_1 = rect;
    this.b22_1 = orientation;
    this.c22_1 = padding;
    this.d22_1 = name;
    this.e22_1 = this.z21_1.l().k1r_1;
    this.f22_1 = this.z21_1.l().l1r_1;
    this.g22_1 = this.a22_1.p1r_1;
    this.h22_1 = this.a22_1.q1r_1;
    this.i22_1 = !_SliceOrientation___get_isRotatedDeg90CwOrCcw__impl__98v1vq(this.b22_1) ? this.a22_1.p1r_1 : this.a22_1.q1r_1;
    this.j22_1 = !_SliceOrientation___get_isRotatedDeg90CwOrCcw__impl__98v1vq(this.b22_1) ? this.a22_1.q1r_1 : this.a22_1.p1r_1;
    this.k22_1 = this.i22_1 + this.c22_1.z1k() | 0;
    this.l22_1 = this.j22_1 + this.c22_1.a1l() | 0;
    this.m22_1 = this.a22_1.u1j() / this.e22_1;
    this.n22_1 = this.a22_1.v1j() / this.e22_1;
    this.o22_1 = this.a22_1.w1j() / this.f22_1;
    this.p22_1 = this.a22_1.x1j() / this.f22_1;
    this.q22_1 = new RectCoords(this.m22_1, this.o22_1, this.n22_1, this.o22_1, this.n22_1, this.p22_1, this.m22_1, this.p22_1);
    this.r22_1 = transformed_1(this.q22_1, this.b22_1);
    this.s22_1 = this.r22_1.a23_1;
    this.t22_1 = this.r22_1.b23_1;
    this.u22_1 = this.r22_1.c23_1;
    this.v22_1 = this.r22_1.d23_1;
    this.w22_1 = this.r22_1.e23_1;
    this.x22_1 = this.r22_1.f23_1;
    this.y22_1 = this.r22_1.g23_1;
    this.z22_1 = this.r22_1.h23_1;
  }
  protoOf(RectSlice).i23 = function () {
    return this.z21_1;
  };
  protoOf(RectSlice).j23 = function () {
    return this.a22_1;
  };
  protoOf(RectSlice).k23 = function () {
    return this.c22_1;
  };
  protoOf(RectSlice).c10 = function () {
    return this.i22_1;
  };
  protoOf(RectSlice).d10 = function () {
    return this.j22_1;
  };
  protoOf(RectSlice).l23 = function () {
    return this.k22_1;
  };
  protoOf(RectSlice).m23 = function () {
    return this.l22_1;
  };
  protoOf(RectSlice).n23 = function (left, top, right, bottom, name, clamped, orientation) {
    var tmp = this.a22_1.v1r(left, top, right, bottom, clamped);
    return new RectSlice(this.z21_1, tmp, orientation, this.c22_1, name == null ? this.d22_1 : name);
  };
  protoOf(RectSlice).o23 = function (left, top, right, bottom, name, clamped, orientation, $super) {
    name = name === VOID ? null : name;
    clamped = clamped === VOID ? true : clamped;
    orientation = orientation === VOID ? this.b22_1 : orientation;
    return $super === VOID ? this.n23(left, top, right, bottom, name, clamped, orientation) : $super.n23.call(this, left, top, right, bottom, name, clamped, new SliceOrientation(orientation));
  };
  protoOf(RectSlice).p23 = function (x, y, width, height, name, clamped, orientation) {
    return this.n23(x, y, x + width | 0, y + height | 0, name, clamped, orientation);
  };
  protoOf(RectSlice).q23 = function (x, y, width, height, name, clamped, orientation, $super) {
    name = name === VOID ? null : name;
    clamped = clamped === VOID ? true : clamped;
    orientation = orientation === VOID ? this.b22_1 : orientation;
    return $super === VOID ? this.p23(x, y, width, height, name, clamped, orientation) : $super.p23.call(this, x, y, width, height, name, clamped, new SliceOrientation(orientation));
  };
  protoOf(RectSlice).r23 = function (rect, name, clamped, orientation) {
    return this.n23(rect.u1j(), rect.w1j(), rect.v1j(), rect.x1j(), name, clamped, orientation);
  };
  protoOf(RectSlice).s23 = function (rect, name, clamped, orientation, $super) {
    name = name === VOID ? null : name;
    clamped = clamped === VOID ? true : clamped;
    orientation = orientation === VOID ? this.b22_1 : orientation;
    return $super === VOID ? this.r23(rect, name, clamped, orientation) : $super.r23.call(this, rect, name, clamped, new SliceOrientation(orientation));
  };
  protoOf(RectSlice).t23 = function (x, y, width, height) {
    return this.u23(VOID, VOID, VOID, new MarginInt(y, (width - this.i22_1 | 0) - x | 0, (height - this.j22_1 | 0) - y | 0, x));
  };
  protoOf(RectSlice).v23 = function (frame) {
    return frame == null ? this.u23(VOID, VOID, VOID, Companion_getInstance_5().t1k_1) : this.t23(frame.n1r_1, frame.o1r_1, frame.p1r_1, frame.q1r_1);
  };
  protoOf(RectSlice).w23 = function () {
    return this.s22_1;
  };
  protoOf(RectSlice).x23 = function () {
    return this.t22_1;
  };
  protoOf(RectSlice).y23 = function () {
    return this.u22_1;
  };
  protoOf(RectSlice).z23 = function () {
    return this.v22_1;
  };
  protoOf(RectSlice).a24 = function () {
    return this.w22_1;
  };
  protoOf(RectSlice).b24 = function () {
    return this.x22_1;
  };
  protoOf(RectSlice).c24 = function () {
    return this.y22_1;
  };
  protoOf(RectSlice).d24 = function () {
    return this.z22_1;
  };
  protoOf(RectSlice).toString = function () {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.slice.RectSlice.toString.<anonymous>' call
    this_0.i5('RectSlice(');
    this_0.i5(this.d22_1);
    this_0.i5(':');
    this_0.h5(this.a22_1);
    if (!(this.b22_1 === Companion_getInstance_27().p21_1)) {
      this_0.i5(':');
      this_0.h5(new SliceOrientation(this.b22_1));
    }
    if (this.c22_1.y1k()) {
      this_0.i5(':');
      this_0.h5(this.c22_1);
    }
    this_0.i5(')');
    return this_0.toString();
  };
  protoOf(RectSlice).e24 = function (base, rect, orientation, padding, name) {
    return new RectSlice(base, rect, orientation, padding, name);
  };
  protoOf(RectSlice).u23 = function (base, rect, orientation, padding, name, $super) {
    base = base === VOID ? this.z21_1 : base;
    rect = rect === VOID ? this.a22_1 : rect;
    orientation = orientation === VOID ? this.b22_1 : orientation;
    padding = padding === VOID ? this.c22_1 : padding;
    name = name === VOID ? this.d22_1 : name;
    return $super === VOID ? this.e24(base, rect, orientation, padding, name) : $super.e24.call(this, base, rect, new SliceOrientation(orientation), padding, name);
  };
  protoOf(RectSlice).hashCode = function () {
    var result = hashCode(this.z21_1);
    result = imul(result, 31) + this.a22_1.hashCode() | 0;
    result = imul(result, 31) + SliceOrientation__hashCode_impl_7mjkdw(this.b22_1) | 0;
    result = imul(result, 31) + this.c22_1.hashCode() | 0;
    result = imul(result, 31) + (this.d22_1 == null ? 0 : getStringHashCode(this.d22_1)) | 0;
    return result;
  };
  protoOf(RectSlice).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RectSlice))
      return false;
    var tmp0_other_with_cast = other instanceof RectSlice ? other : THROW_CCE();
    if (!equals(this.z21_1, tmp0_other_with_cast.z21_1))
      return false;
    if (!this.a22_1.equals(tmp0_other_with_cast.a22_1))
      return false;
    if (!(this.b22_1 === tmp0_other_with_cast.b22_1))
      return false;
    if (!this.c22_1.equals(tmp0_other_with_cast.c22_1))
      return false;
    if (!(this.d22_1 == tmp0_other_with_cast.d22_1))
      return false;
    return true;
  };
  function Companion_28() {
  }
  protoOf(Companion_28).j24 = function (base, coords, name, flippedWidthHeight) {
    return new SliceCoordsImpl(base, coords, name, flippedWidthHeight);
  };
  protoOf(Companion_28).k24 = function (base, coords, name, flippedWidthHeight, $super) {
    name = name === VOID ? null : name;
    flippedWidthHeight = flippedWidthHeight === VOID ? false : flippedWidthHeight;
    return $super === VOID ? this.j24(base, coords, name, flippedWidthHeight) : $super.j24.call(this, base, coords, name, flippedWidthHeight);
  };
  var Companion_instance_29;
  function Companion_getInstance_28() {
    return Companion_instance_29;
  }
  function SliceCoordsWithBase() {
  }
  function SliceCoords() {
  }
  function SliceCoordsImpl(base, coords, name, flippedWidthHeight) {
    name = name === VOID ? null : name;
    flippedWidthHeight = flippedWidthHeight === VOID ? false : flippedWidthHeight;
    this.l24_1 = base;
    this.m24_1 = coords;
    this.n24_1 = name;
    this.o24_1 = flippedWidthHeight;
    this.p24_1 = Companion_getInstance_5().t1k_1;
    this.q24_1 = !this.o24_1 ? this.l24_1.l().k1r_1 : this.l24_1.l().l1r_1;
    this.r24_1 = !this.o24_1 ? this.l24_1.l().l1r_1 : this.l24_1.l().k1r_1;
    this.s24_1 = numberToInt(Companion_getInstance_15().p1s(this.m24_1.w23(), this.m24_1.x23(), this.m24_1.y23(), this.m24_1.z23()) * this.q24_1);
    this.t24_1 = numberToInt(Companion_getInstance_15().p1s(this.m24_1.w23(), this.m24_1.x23(), this.m24_1.c24(), this.m24_1.d24()) * this.r24_1);
    this.u24_1 = this.s24_1 + this.p24_1.z1k() | 0;
    this.v24_1 = this.t24_1 + this.p24_1.a1l() | 0;
  }
  protoOf(SliceCoordsImpl).i23 = function () {
    return this.l24_1;
  };
  protoOf(SliceCoordsImpl).k23 = function () {
    return this.p24_1;
  };
  protoOf(SliceCoordsImpl).c10 = function () {
    return this.s24_1;
  };
  protoOf(SliceCoordsImpl).d10 = function () {
    return this.t24_1;
  };
  protoOf(SliceCoordsImpl).l23 = function () {
    return this.u24_1;
  };
  protoOf(SliceCoordsImpl).m23 = function () {
    return this.v24_1;
  };
  protoOf(SliceCoordsImpl).w23 = function () {
    return this.m24_1.w23();
  };
  protoOf(SliceCoordsImpl).x23 = function () {
    return this.m24_1.x23();
  };
  protoOf(SliceCoordsImpl).y23 = function () {
    return this.m24_1.y23();
  };
  protoOf(SliceCoordsImpl).z23 = function () {
    return this.m24_1.z23();
  };
  protoOf(SliceCoordsImpl).a24 = function () {
    return this.m24_1.a24();
  };
  protoOf(SliceCoordsImpl).b24 = function () {
    return this.m24_1.b24();
  };
  protoOf(SliceCoordsImpl).c24 = function () {
    return this.m24_1.c24();
  };
  protoOf(SliceCoordsImpl).d24 = function () {
    return this.m24_1.d24();
  };
  protoOf(SliceCoordsImpl).toString = function () {
    return 'SliceCoordsImpl(base=' + this.l24_1 + ', coords=' + this.m24_1 + ', name=' + this.n24_1 + ', flippedWidthHeight=' + this.o24_1 + ')';
  };
  protoOf(SliceCoordsImpl).hashCode = function () {
    var result = hashCode(this.l24_1);
    result = imul(result, 31) + hashCode(this.m24_1) | 0;
    result = imul(result, 31) + (this.n24_1 == null ? 0 : getStringHashCode(this.n24_1)) | 0;
    result = imul(result, 31) + getBooleanHashCode(this.o24_1) | 0;
    return result;
  };
  protoOf(SliceCoordsImpl).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SliceCoordsImpl))
      return false;
    var tmp0_other_with_cast = other instanceof SliceCoordsImpl ? other : THROW_CCE();
    if (!equals(this.l24_1, tmp0_other_with_cast.l24_1))
      return false;
    if (!equals(this.m24_1, tmp0_other_with_cast.m24_1))
      return false;
    if (!(this.n24_1 == tmp0_other_with_cast.n24_1))
      return false;
    if (!(this.o24_1 === tmp0_other_with_cast.o24_1))
      return false;
    return true;
  };
  function SliceCoordsWithBaseAndRect() {
  }
  function RectCoords(tlX, tlY, trX, trY, brX, brY, blX, blY) {
    this.a23_1 = tlX;
    this.b23_1 = tlY;
    this.c23_1 = trX;
    this.d23_1 = trY;
    this.e23_1 = brX;
    this.f23_1 = brY;
    this.g23_1 = blX;
    this.h23_1 = blY;
  }
  protoOf(RectCoords).w23 = function () {
    return this.a23_1;
  };
  protoOf(RectCoords).x23 = function () {
    return this.b23_1;
  };
  protoOf(RectCoords).y23 = function () {
    return this.c23_1;
  };
  protoOf(RectCoords).z23 = function () {
    return this.d23_1;
  };
  protoOf(RectCoords).a24 = function () {
    return this.e23_1;
  };
  protoOf(RectCoords).b24 = function () {
    return this.f23_1;
  };
  protoOf(RectCoords).c24 = function () {
    return this.g23_1;
  };
  protoOf(RectCoords).d24 = function () {
    return this.h23_1;
  };
  protoOf(RectCoords).toString = function () {
    return 'RectCoords(tlX=' + this.a23_1 + ', tlY=' + this.b23_1 + ', trX=' + this.c23_1 + ', trY=' + this.d23_1 + ', brX=' + this.e23_1 + ', brY=' + this.f23_1 + ', blX=' + this.g23_1 + ', blY=' + this.h23_1 + ')';
  };
  protoOf(RectCoords).hashCode = function () {
    var result = getNumberHashCode(this.a23_1);
    result = imul(result, 31) + getNumberHashCode(this.b23_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.c23_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.d23_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.e23_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.f23_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.g23_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.h23_1) | 0;
    return result;
  };
  protoOf(RectCoords).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RectCoords))
      return false;
    var tmp0_other_with_cast = other instanceof RectCoords ? other : THROW_CCE();
    if (!equals(this.a23_1, tmp0_other_with_cast.a23_1))
      return false;
    if (!equals(this.b23_1, tmp0_other_with_cast.b23_1))
      return false;
    if (!equals(this.c23_1, tmp0_other_with_cast.c23_1))
      return false;
    if (!equals(this.d23_1, tmp0_other_with_cast.d23_1))
      return false;
    if (!equals(this.e23_1, tmp0_other_with_cast.e23_1))
      return false;
    if (!equals(this.f23_1, tmp0_other_with_cast.f23_1))
      return false;
    if (!equals(this.g23_1, tmp0_other_with_cast.g23_1))
      return false;
    if (!equals(this.h23_1, tmp0_other_with_cast.h23_1))
      return false;
    return true;
  };
  function transformed_1(_this__u8e3s4, orientation) {
    var i = _SliceOrientation___get_indices__impl__km5on3(orientation);
    return new RectCoords(_this__u8e3s4.h24(i[0]), _this__u8e3s4.i24(i[0]), _this__u8e3s4.h24(i[1]), _this__u8e3s4.i24(i[1]), _this__u8e3s4.h24(i[2]), _this__u8e3s4.i24(i[2]), _this__u8e3s4.h24(i[3]), _this__u8e3s4.i24(i[3]));
  }
  function SliceRotation_R0_getInstance() {
    SliceRotation_initEntries();
    return SliceRotation_R0_instance;
  }
  function SliceRotation_R90_getInstance() {
    SliceRotation_initEntries();
    return SliceRotation_R90_instance;
  }
  function SliceRotation_R180_getInstance() {
    SliceRotation_initEntries();
    return SliceRotation_R180_instance;
  }
  function SliceRotation_R270_getInstance() {
    SliceRotation_initEntries();
    return SliceRotation_R270_instance;
  }
  function PolygonScanline$AllBuckets$pool$lambda(it) {
    it.a1i();
    return Unit_instance;
  }
  function PolygonScanline$AllBuckets$pool$lambda_0(it) {
    return new Bucket();
  }
  function Bucket() {
    this.w24_1 = FastArrayList_init_$Create$();
  }
  protoOf(Bucket).a1i = function () {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.Bucket.clear.<anonymous>' call
    this.w24_1.l1();
    return this;
  };
  function Buckets(pool, ySize) {
    this.x24_1 = pool;
    this.y24_1 = ySize;
    this.z24_1 = FastIntMap();
  }
  protoOf(Buckets).l = function () {
    return get_size(this.z24_1);
  };
  protoOf(Buckets).a25 = function (y) {
    return y / this.y24_1 | 0;
  };
  protoOf(Buckets).b25 = function (index) {
    var tmp$ret$2;
    $l$block: {
      // Inline function 'korlibs.datastructure.getOrPut' call
      var this_0 = this.z24_1;
      // Inline function 'korlibs.datastructure.get' call
      // Inline function 'kotlin.js.asDynamic' call
      var res = this_0.get(index);
      if (!(res == null)) {
        tmp$ret$2 = res;
        break $l$block;
      }
      // Inline function 'korlibs.math.geom.vector.Buckets.getForIndex.<anonymous>' call
      var out = this.x24_1.v16();
      // Inline function 'korlibs.datastructure.set' call
      // Inline function 'kotlin.js.asDynamic' call
      this_0.set(index, out);
      tmp$ret$2 = out;
    }
    return tmp$ret$2;
  };
  protoOf(Buckets).c25 = function (y) {
    // Inline function 'korlibs.datastructure.get' call
    var this_0 = this.z24_1;
    var key = this.a25(y);
    // Inline function 'kotlin.js.asDynamic' call
    return this_0.get(key);
  };
  protoOf(Buckets).l1 = function () {
    // Inline function 'korlibs.datastructure.fastForEach' call
    var this_0 = this.z24_1;
    // Inline function 'korlibs.datastructure.fastKeyForEach' call
    // Inline function 'kotlin.js.asDynamic' call
    var mapIterator = this_0.keys();
    $l$loop: while (true) {
      var v = mapIterator.next();
      if (v.done)
        break $l$loop;
      // Inline function 'korlibs.datastructure.fastForEach.<anonymous>' call
      var it = v.value;
      // Inline function 'korlibs.math.geom.vector.Buckets.clear.<anonymous>' call
      // Inline function 'korlibs.datastructure.get' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$2 = this_0.get(it);
      var value = ensureNotNull(tmp$ret$2);
      this.x24_1.a17(value.a1i());
    }
    // Inline function 'korlibs.datastructure.clear' call
    // Inline function 'kotlin.js.asDynamic' call
    this.z24_1.clear();
  };
  protoOf(Buckets).d25 = function (edge, threshold) {
    var min = this.a25(edge.o25());
    var max = this.a25(edge.p25());
    if ((max - min | 0) < threshold) {
      var inductionVariable = min;
      if (inductionVariable <= max)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          this.b25(n).w24_1.p(edge);
        }
         while (!(n === max));
      return true;
    }
    return false;
  };
  protoOf(Buckets).q25 = function (edge, threshold, $super) {
    threshold = threshold === VOID ? IntCompanionObject_instance.MAX_VALUE : threshold;
    return $super === VOID ? this.d25(edge, threshold) : $super.d25.call(this, edge, threshold);
  };
  function AllBuckets() {
    var tmp = this;
    var tmp_0 = PolygonScanline$AllBuckets$pool$lambda;
    tmp.r25_1 = new Pool(tmp_0, VOID, PolygonScanline$AllBuckets$pool$lambda_0);
    this.s25_1 = new Buckets(this.r25_1, 80);
    this.t25_1 = new Buckets(this.r25_1, 320);
    this.u25_1 = new Buckets(this.r25_1, 1280);
  }
  protoOf(AllBuckets).v25 = function (edge) {
    if (this.s25_1.d25(edge, 4))
      return Unit_instance;
    if (this.t25_1.d25(edge, 4))
      return Unit_instance;
    this.u25_1.q25(edge);
  };
  protoOf(AllBuckets).l1 = function () {
    this.s25_1.l1();
    this.t25_1.l1();
    this.u25_1.l1();
  };
  function addPoint($this, x, y) {
    $this.f26_1.u1u(x, y);
  }
  function addEdge($this, ax, ay, bx, by) {
    if (ax === bx ? ay === by : false)
      return Unit_instance;
    var isHorizontal = ay === by;
    var iax = $this.o26(ax);
    var ibx = $this.o26(bx);
    var iay = $this.o26(ay);
    var iby = $this.o26(by);
    var edge = ay < by ? $this.z25_1.v16().p26(iax, iay, ibx, iby, 1) : $this.z25_1.v16().p26(ibx, iby, iax, iay, -1);
    $this.c26_1.p(edge);
    if (isHorizontal) {
      $this.b26_1.p(edge);
    } else {
      $this.a26_1.p(edge);
      $this.d26_1.v25(edge);
    }
    $this.y25_1 = BoundsBuilder__plus_impl_cz7f7x($this.y25_1, new Vector2D(ax, ay));
    $this.y25_1 = BoundsBuilder__plus_impl_cz7f7x($this.y25_1, new Vector2D(bx, by));
  }
  function XWithWind() {
    this.q26_1 = new IntArrayList(1024);
    this.r26_1 = new IntArrayList(1024);
  }
  protoOf(XWithWind).l = function () {
    return this.q26_1.l();
  };
  protoOf(XWithWind).s26 = function (x, wind) {
    this.q26_1.o11(x);
    this.r26_1.o11(wind);
  };
  protoOf(XWithWind).l1 = function () {
    this.q26_1.l1();
    this.r26_1.l1();
  };
  protoOf(XWithWind).toString = function () {
    return 'XWithWind(' + this.q26_1 + ', ' + this.r26_1 + ')';
  };
  function IntArrayListSort() {
    IntArrayListSort_instance = this;
    SortOps.call(this);
  }
  protoOf(IntArrayListSort).t26 = function (subject, l, r) {
    return compareTo(subject.q26_1.e10(l), subject.q26_1.e10(r));
  };
  protoOf(IntArrayListSort).l12 = function (subject, l, r) {
    return this.t26(subject instanceof XWithWind ? subject : THROW_CCE(), l, r);
  };
  protoOf(IntArrayListSort).u26 = function (subject, indexL, indexR) {
    subject.q26_1.f11(indexL, indexR);
    subject.r26_1.f11(indexL, indexR);
  };
  protoOf(IntArrayListSort).n12 = function (subject, indexL, indexR) {
    return this.u26(subject instanceof XWithWind ? subject : THROW_CCE(), indexL, indexR);
  };
  var IntArrayListSort_instance;
  function IntArrayListSort_getInstance() {
    if (IntArrayListSort_instance == null)
      new IntArrayListSort();
    return IntArrayListSort_instance;
  }
  function PolygonScanline$edgesPool$lambda(it) {
    return new MEdge();
  }
  function PolygonScanline() {
    RastScale.call(this);
    this.w25_1 = -1;
    this.x25_1 = Winding_NON_ZERO_getInstance();
    this.y25_1 = Companion_getInstance_3().d1k();
    var tmp = this;
    tmp.z25_1 = Pool_init_$Create$(VOID, PolygonScanline$edgesPool$lambda);
    this.a26_1 = FastArrayList_init_$Create$();
    this.b26_1 = FastArrayList_init_$Create$();
    this.c26_1 = FastArrayList_init_$Create$();
    this.d26_1 = new AllBuckets();
    this.e26_1 = true;
    this.f26_1 = new PointArrayList();
    this.g26_1 = 0.0;
    this.h26_1 = 0.0;
    this.i26_1 = 0.0;
    this.j26_1 = 0.0;
    this.k26_1 = false;
    this.l26_1 = new XWithWind();
    this.m26_1 = 0;
    this.n26_1 = new IntSegmentSet();
  }
  protoOf(PolygonScanline).h1x = function () {
    return _BoundsBuilder___get_bounds__impl__s1l1gx(this.y25_1);
  };
  protoOf(PolygonScanline).i17 = function () {
    this.e26_1 = true;
    this.y25_1 = Companion_getInstance_3().y1j_1;
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.a26_1.y13_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.math.geom.vector.PolygonScanline.reset.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'get' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var it = array[tmp0];
      this.z25_1.a17(it);
    }
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array_0 = this.b26_1.y13_1;
    var n_0 = 0;
    while (n_0 < array_0.length) {
      // Inline function 'korlibs.math.geom.vector.PolygonScanline.reset.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'get' call
      var tmp0_0 = n_0;
      n_0 = tmp0_0 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var it_0 = array_0[tmp0_0];
      this.z25_1.a17(it_0);
    }
    this.a26_1.l1();
    this.b26_1.l1();
    this.c26_1.l1();
    this.f26_1.a1i();
    this.d26_1.l1();
    this.g26_1 = 0.0;
    this.h26_1 = 0.0;
    this.i26_1 = 0.0;
    this.j26_1 = 0.0;
    this.k26_1 = false;
  };
  protoOf(PolygonScanline).v26 = function () {
    return this.a26_1.l();
  };
  protoOf(PolygonScanline).v11 = function () {
    return this.v26() > 0;
  };
  protoOf(PolygonScanline).w26 = function (x, y) {
    this.i26_1 = x;
    this.j26_1 = y;
    this.g26_1 = x;
    this.h26_1 = y;
    this.k26_1 = true;
  };
  protoOf(PolygonScanline).x26 = function (x, y) {
    if (this.k26_1) {
      addPoint(this, this.i26_1, this.j26_1);
    }
    addEdge(this, this.i26_1, this.j26_1, x, y);
    addPoint(this, x, y);
    this.i26_1 = x;
    this.j26_1 = y;
    this.k26_1 = false;
  };
  protoOf(PolygonScanline).f20 = function (p) {
    return this.w26(p.m1e_1, p.n1e_1);
  };
  protoOf(PolygonScanline).s20 = function (p) {
    return this.x26(p.m1e_1, p.n1e_1);
  };
  protoOf(PolygonScanline).y26 = function (path) {
    // Inline function 'korlibs.math.geom.shape.emitPoints2' call
    var i = Vector2D_init_$Create$_1();
    var l = Vector2D_init_$Create$_1();
    // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
    if (false) {
      this.x18();
    }
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = path.h20_1;
    var n_0 = 0;
    while (n_0 < this_0.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp0_subject = this_0.e10(tmp0);
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = path.i20_1.b1(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var it = new Vector2D(tmp, path.i20_1.b1(tmp2));
        i = it;
        // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
        this.z26(it, true);
        l = it;
      } else {
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = path.i20_1.b1(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var it_0 = new Vector2D(tmp_0, path.i20_1.b1(tmp4));
          // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
          this.z26(it_0, false);
          l = it_0;
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        } else {
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = path.i20_1.b1(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var c = new Vector2D(tmp_1, path.i20_1.b1(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = path.i20_1.b1(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var a = new Vector2D(tmp_2, path.i20_1.b1(tmp8));
            var sum = Companion_getInstance_15().r1s(l, c) + Companion_getInstance_15().r1s(c, a);
            // Inline function 'korlibs.math.geom.shape.approximateCurve' call
            // Inline function 'kotlin.math.max' call
            var a_0 = numberToInt(sum);
            var rcurveSteps = Math.max(a_0, 20);
            var dt = 1.0 / rcurveSteps;
            var lastPos = {_v: Vector2D_init_$Create$_1()};
            var prevPos = {_v: Vector2D_init_$Create$_1()};
            var emittedCount = {_v: 0};
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
            var ratio = Companion_getInstance().g1e_1;
            // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
            lastPos._v = Companion_getInstance_22().q1y(l, c, a, ratio);
            var nStart = false ? 0 : 1;
            var nEnd = true ? rcurveSteps : rcurveSteps - 1 | 0;
            var inductionVariable = nStart;
            if (inductionVariable <= nEnd)
              do {
                var n_1 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var ratio_0 = _Ratio___init__impl__9mwvn2_0(n_1 * dt);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                var it_1 = Companion_getInstance_22().q1y(l, c, a, ratio_0);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
                this.z26(it_1, false);
                emittedCount._v = emittedCount._v + 1 | 0;
                lastPos._v = prevPos._v;
                prevPos._v = it_1;
              }
               while (!(n_1 === nEnd));
            l = a;
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          } else {
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = path.i20_1.b1(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var c0 = new Vector2D(tmp_3, path.i20_1.b1(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = path.i20_1.b1(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var c1 = new Vector2D(tmp_4, path.i20_1.b1(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = path.i20_1.b1(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var a_1 = new Vector2D(tmp_5, path.i20_1.b1(tmp14));
              var sum_0 = Companion_getInstance_15().r1s(l, c0) + Companion_getInstance_15().r1s(c0, c1) + Companion_getInstance_15().r1s(c1, a_1);
              // Inline function 'korlibs.math.geom.shape.approximateCurve' call
              // Inline function 'kotlin.math.max' call
              var a_2 = numberToInt(sum_0);
              var rcurveSteps_0 = Math.max(a_2, 20);
              var dt_0 = 1.0 / rcurveSteps_0;
              var lastPos_0 = {_v: Vector2D_init_$Create$_1()};
              var prevPos_0 = {_v: Vector2D_init_$Create$_1()};
              var emittedCount_0 = {_v: 0};
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
              var ratio_1 = Companion_getInstance().g1e_1;
              // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
              lastPos_0._v = Companion_getInstance_22().p1y(l, c0, c1, a_1, ratio_1);
              var nStart_0 = false ? 0 : 1;
              var nEnd_0 = true ? rcurveSteps_0 : rcurveSteps_0 - 1 | 0;
              var inductionVariable_0 = nStart_0;
              if (inductionVariable_0 <= nEnd_0)
                do {
                  var n_2 = inductionVariable_0;
                  inductionVariable_0 = inductionVariable_0 + 1 | 0;
                  var ratio_2 = _Ratio___init__impl__9mwvn2_0(n_2 * dt_0);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                  var it_2 = Companion_getInstance_22().p1y(l, c0, c1, a_1, ratio_2);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
                  this.z26(it_2, false);
                  emittedCount_0._v = emittedCount_0._v + 1 | 0;
                  lastPos_0._v = prevPos_0._v;
                  prevPos_0._v = it_2;
                }
                 while (!(n_2 === nEnd_0));
              l = a_1;
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            } else {
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
                var p = i;
                this.z26(p, false);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
                if (true) {
                  this.x18();
                }
              }
            }
          }
        }
      }
    }
    // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
    if (false) {
      this.x18();
    }
  };
  protoOf(PolygonScanline).z26 = function (p, move) {
    var tmp;
    if (move) {
      this.f20(p);
      tmp = Unit_instance;
    } else {
      this.s20(p);
      tmp = Unit_instance;
    }
    return tmp;
  };
  protoOf(PolygonScanline).a27 = function (x, y, move) {
    var tmp;
    if (move) {
      this.w26(x, y);
      tmp = Unit_instance;
    } else {
      this.x26(x, y);
      tmp = Unit_instance;
    }
    return tmp;
  };
  protoOf(PolygonScanline).x18 = function () {
    this.x26(this.g26_1, this.h26_1);
  };
  protoOf(PolygonScanline).b27 = function (y, winding, out) {
    this.m26_1 = 0;
    this.l26_1.l1();
    out.a1i();
    var tmp = this;
    var tmp_0 = this.m26_1;
    // Inline function 'korlibs.math.geom.vector.PolygonScanline.forEachActiveEdgeAtY' call
    var edgesChecked = 0;
    // Inline function 'korlibs.math.geom.vector.AllBuckets.fastForEachY' call
    var this_0 = this.d26_1;
    // Inline function 'korlibs.math.geom.vector.Buckets.fastForEachY' call
    var this_1 = this_0.s25_1;
    if (this_1.l() > 0) {
      var tmp0_safe_receiver = this_1.c25(y);
      if (tmp0_safe_receiver == null)
        null;
      else {
        // Inline function 'korlibs.math.geom.vector.Bucket.fastForEach' call
        // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'kotlin.js.asDynamic' call
        var array = tmp0_safe_receiver.w24_1.y13_1;
        var n = 0;
        while (n < array.length) {
          // Inline function 'korlibs.math.geom.vector.AllBuckets.fastForEachY.<anonymous>' call
          // Inline function 'kotlin.js.unsafeCast' call
          // Inline function 'get' call
          var tmp0 = n;
          n = tmp0 + 1 | 0;
          // Inline function 'kotlin.js.asDynamic' call
          // Inline function 'kotlin.js.asDynamic' call
          // Inline function 'korlibs.math.geom.vector.PolygonScanline.forEachActiveEdgeAtY.<anonymous>' call
          var edge = array[tmp0];
          edgesChecked = edgesChecked + 1 | 0;
          if (edge.d27(y)) {
            // Inline function 'korlibs.math.geom.vector.PolygonScanline.scanline.<anonymous>' call
            if (!edge.l25_1) {
              this.l26_1.s26(edge.c27(y), edge.i25_1);
            }
          }
        }
      }
    }
    // Inline function 'korlibs.math.geom.vector.Buckets.fastForEachY' call
    var this_2 = this_0.t25_1;
    if (this_2.l() > 0) {
      var tmp0_safe_receiver_0 = this_2.c25(y);
      if (tmp0_safe_receiver_0 == null)
        null;
      else {
        // Inline function 'korlibs.math.geom.vector.Bucket.fastForEach' call
        // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'kotlin.js.asDynamic' call
        var array_0 = tmp0_safe_receiver_0.w24_1.y13_1;
        var n_0 = 0;
        while (n_0 < array_0.length) {
          // Inline function 'korlibs.math.geom.vector.AllBuckets.fastForEachY.<anonymous>' call
          // Inline function 'kotlin.js.unsafeCast' call
          // Inline function 'get' call
          var tmp0_0 = n_0;
          n_0 = tmp0_0 + 1 | 0;
          // Inline function 'kotlin.js.asDynamic' call
          // Inline function 'kotlin.js.asDynamic' call
          // Inline function 'korlibs.math.geom.vector.PolygonScanline.forEachActiveEdgeAtY.<anonymous>' call
          var edge_0 = array_0[tmp0_0];
          edgesChecked = edgesChecked + 1 | 0;
          if (edge_0.d27(y)) {
            // Inline function 'korlibs.math.geom.vector.PolygonScanline.scanline.<anonymous>' call
            if (!edge_0.l25_1) {
              this.l26_1.s26(edge_0.c27(y), edge_0.i25_1);
            }
          }
        }
      }
    }
    // Inline function 'korlibs.math.geom.vector.Buckets.fastForEachY' call
    var this_3 = this_0.u25_1;
    if (this_3.l() > 0) {
      var tmp0_safe_receiver_1 = this_3.c25(y);
      if (tmp0_safe_receiver_1 == null)
        null;
      else {
        // Inline function 'korlibs.math.geom.vector.Bucket.fastForEach' call
        // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'kotlin.js.asDynamic' call
        var array_1 = tmp0_safe_receiver_1.w24_1.y13_1;
        var n_1 = 0;
        while (n_1 < array_1.length) {
          // Inline function 'korlibs.math.geom.vector.AllBuckets.fastForEachY.<anonymous>' call
          // Inline function 'kotlin.js.unsafeCast' call
          // Inline function 'get' call
          var tmp0_1 = n_1;
          n_1 = tmp0_1 + 1 | 0;
          // Inline function 'kotlin.js.asDynamic' call
          // Inline function 'kotlin.js.asDynamic' call
          // Inline function 'korlibs.math.geom.vector.PolygonScanline.forEachActiveEdgeAtY.<anonymous>' call
          var edge_1 = array_1[tmp0_1];
          edgesChecked = edgesChecked + 1 | 0;
          if (edge_1.d27(y)) {
            // Inline function 'korlibs.math.geom.vector.PolygonScanline.scanline.<anonymous>' call
            if (!edge_1.l25_1) {
              this.l26_1.s26(edge_1.c27(y), edge_1.i25_1);
            }
          }
        }
      }
    }
    tmp.m26_1 = tmp_0 + edgesChecked | 0;
    genericSort(this.l26_1, 0, this.l26_1.l() - 1 | 0, IntArrayListSort_getInstance());
    var tempX = this.l26_1.q26_1;
    var tempW = this.l26_1.r26_1;
    if (this.l26_1.l() >= 2) {
      var tmp0_2 = winding.t9_1;
      if (tmp0_2 === 0) {
        var progression = step(until(0, tempX.l() - 1 | 0), 2);
        var inductionVariable = progression.pa_1;
        var last = progression.qa_1;
        var step_0 = progression.ra_1;
        if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
          do {
            var i = inductionVariable;
            inductionVariable = inductionVariable + step_0 | 0;
            var a = tempX.e10(i);
            var b = tempX.e10(i + 1 | 0);
            out.g1i(a, b);
          }
           while (!(i === last));
      } else if (tmp0_2 === 1) {
        var count = 0;
        var startX = 0;
        var endX = 0;
        var pending = false;
        var inductionVariable_0 = 0;
        var last_0 = tempX.l() - 1 | 0;
        if (inductionVariable_0 < last_0)
          do {
            var i_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var a_0 = tempX.e10(i_0);
            count = count + tempW.e10(i_0) | 0;
            var b_0 = tempX.e10(i_0 + 1 | 0);
            if (!(count === 0)) {
              if (pending ? !(a_0 === endX) : false) {
                out.g1i(startX, endX);
                startX = a_0;
                endX = b_0;
              } else {
                if (!pending) {
                  startX = a_0;
                }
                endX = b_0;
              }
              pending = true;
            }
          }
           while (inductionVariable_0 < last_0);
        if (pending) {
          out.g1i(startX, endX);
        }
      }
    }
    return out;
  };
  protoOf(PolygonScanline).e27 = function (x, y, winding) {
    return this.f27(this.o26(x), this.o26(y), winding);
  };
  protoOf(PolygonScanline).f27 = function (x, y, winding) {
    var ss = this.n26_1;
    this.b27(y, winding, ss.a1i());
    return ss.ah(x);
  };
  function Companion_29() {
    this.i27_1 = 20;
    this.j27_1 = 0;
    this.k27_1 = 80;
    this.l27_1 = 320;
    this.m27_1 = 1280;
  }
  var Companion_instance_30;
  function Companion_getInstance_29() {
    return Companion_instance_30;
  }
  function RastScale() {
  }
  protoOf(RastScale).g27 = function () {
    return 20;
  };
  protoOf(RastScale).h27 = function () {
    return 0;
  };
  protoOf(RastScale).o26 = function (_this__u8e3s4) {
    return numberToInt(_this__u8e3s4 * this.g27()) + this.h27() | 0;
  };
  function Companion_30() {
  }
  protoOf(Companion_30).n27 = function (a, b) {
    // Inline function 'korlibs.math.geom.vector.Companion._getIntersectXY' call
    var Ax = a.e25_1;
    var Ay = a.f25_1;
    var Bx = a.g25_1;
    var By = a.h25_1;
    var Cx = b.e25_1;
    var Cy = b.f25_1;
    var Dx = b.g25_1;
    var Dy = b.h25_1;
    var tmp0_safe_receiver = this.o27(Ax, Ay, Bx, By, Cx, Cy, Dx, Dy);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.vector.Companion._getIntersectXY.<anonymous>' call
      tmp = new Vector2I(numberToInt(floorCeil(tmp0_safe_receiver.m1e_1)), numberToInt(floorCeil(tmp0_safe_receiver.n1e_1)));
    }
    var tmp0_safe_receiver_0 = tmp;
    var tmp_0;
    if (tmp0_safe_receiver_0 == null) {
      tmp_0 = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.vector.Companion.getIntersectXY.<anonymous>' call
      tmp_0 = Vector2D_init_$Create$_0(tmp0_safe_receiver_0.i1r_1, tmp0_safe_receiver_0.j1r_1);
    }
    return tmp_0;
  };
  protoOf(Companion_30).p27 = function (a, b) {
    return Angle__minus_impl_xeau7k(b.q27(), a.q27());
  };
  protoOf(Companion_30).o27 = function (Ax, Ay, Bx, By, Cx, Cy, Dx, Dy) {
    return Companion_instance_39.o27(Ax, Ay, Bx, By, Cx, Cy, Dx, Dy);
  };
  var Companion_instance_31;
  function Companion_getInstance_30() {
    return Companion_instance_31;
  }
  function MEdge() {
    this.e25_1 = 0;
    this.f25_1 = 0;
    this.g25_1 = 0;
    this.h25_1 = 0;
    this.i25_1 = 0;
    this.j25_1 = 0;
    this.k25_1 = 0;
    this.l25_1 = false;
    this.m25_1 = false;
    this.n25_1 = 0;
  }
  protoOf(MEdge).j1u = function (n) {
    return n === 0 ? this.e25_1 : this.g25_1;
  };
  protoOf(MEdge).k1u = function (n) {
    return n === 0 ? this.f25_1 : this.h25_1;
  };
  protoOf(MEdge).r27 = function (other) {
    return this.p26(other.e25_1, other.f25_1, other.g25_1, other.h25_1, other.i25_1);
  };
  protoOf(MEdge).p26 = function (ax, ay, bx, by, wind) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.MEdge.setTo.<anonymous>' call
    this.e25_1 = ax;
    this.f25_1 = ay;
    this.g25_1 = bx;
    this.h25_1 = by;
    this.k25_1 = bx - ax | 0;
    this.j25_1 = by - ay | 0;
    this.l25_1 = ay === by;
    this.m25_1 = ax === bx;
    this.i25_1 = wind;
    this.n25_1 = this.m25_1 ? 0 : ay - (imul(ax, this.j25_1) / this.k25_1 | 0) | 0;
    return this;
  };
  protoOf(MEdge).o25 = function () {
    // Inline function 'kotlin.math.min' call
    var a = this.f25_1;
    var b = this.h25_1;
    return Math.min(a, b);
  };
  protoOf(MEdge).p25 = function () {
    // Inline function 'kotlin.math.max' call
    var a = this.f25_1;
    var b = this.h25_1;
    return Math.max(a, b);
  };
  protoOf(MEdge).d27 = function (y) {
    return y >= this.f25_1 ? y < this.h25_1 : false;
  };
  protoOf(MEdge).c27 = function (y) {
    return (this.m25_1 ? true : this.j25_1 === 0) ? this.e25_1 : imul(y - this.n25_1 | 0, this.k25_1) / this.j25_1 | 0;
  };
  protoOf(MEdge).q27 = function () {
    // Inline function 'korlibs.math.geom.Companion.between' call
    Companion_getInstance_2();
    var x0 = this.e25_1;
    var y0 = this.f25_1;
    var x1 = this.g25_1;
    var y1 = this.h25_1;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var up = Companion_getInstance_15().k1j_1;
    return Angle_between(x0, y0, x1, y1, up);
  };
  protoOf(MEdge).toString = function () {
    return 'Edge([' + this.e25_1 + ',' + this.f25_1 + ']-[' + this.g25_1 + ',' + this.h25_1 + '])';
  };
  var EdgePoint_A_instance;
  var EdgePoint_B_instance;
  var EdgePoint_entriesInitialized;
  function EdgePoint_initEntries() {
    if (EdgePoint_entriesInitialized)
      return Unit_instance;
    EdgePoint_entriesInitialized = true;
    EdgePoint_A_instance = new EdgePoint('A', 0, 0);
    EdgePoint_B_instance = new EdgePoint('B', 1, 1);
  }
  function _get_outFill__zcv64g($this) {
    var tmp = $this.t27_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('outFill');
    }
  }
  function EdgePoint(name, ordinal, n) {
    Enum.call(this, name, ordinal);
    this.l28_1 = n;
  }
  function EdgePoint_A_getInstance() {
    EdgePoint_initEntries();
    return EdgePoint_A_instance;
  }
  function EdgePoint_B_getInstance() {
    EdgePoint_initEntries();
    return EdgePoint_B_instance;
  }
  function StrokeToFill() {
    this.s27_1 = 1;
    this.u27_1 = LineCap_BUTT_getInstance();
    this.v27_1 = LineCap_BUTT_getInstance();
    this.w27_1 = LineJoin_BEVEL_getInstance();
    this.x27_1 = 4.0;
    this.y27_1 = new PointIntArrayList(1024);
    this.z27_1 = new IntArrayList(1024);
    var tmp = this;
    var tmp_0 = 0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(2), null);
    while (tmp_0 < 2) {
      tmp_1[tmp_0] = new PointIntArrayList(1024);
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.a28_1 = tmp_1;
    this.b28_1 = this.a28_1[0];
    this.c28_1 = this.a28_1[1];
    this.d28_1 = new MEdge();
    this.e28_1 = new MEdge();
    this.f28_1 = new MEdge();
    this.g28_1 = new MEdge();
    this.h28_1 = new MEdge();
    this.i28_1 = new MEdge();
  }
  protoOf(StrokeToFill).m28 = function (_this__u8e3s4, edge, width, angle) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.StrokeToFill.setEdgeDisplaced.<anonymous>' call
    var ldx = width * _Angle___get_cosine__impl__vprvmu(angle);
    var ldy = width * _Angle___get_sine__impl__15nmyi(angle);
    _this__u8e3s4.p26(numberToInt(edge.e25_1 + ldx), numberToInt(edge.f25_1 + ldy), numberToInt(edge.g25_1 + ldx), numberToInt(edge.h25_1 + ldy), edge.i25_1);
    return _this__u8e3s4;
  };
  protoOf(StrokeToFill).n28 = function (_this__u8e3s4, e) {
    return _this__u8e3s4.g1i(e.e25_1, e.f25_1);
  };
  protoOf(StrokeToFill).o28 = function (_this__u8e3s4, e) {
    return _this__u8e3s4.g1i(e.g25_1, e.h25_1);
  };
  protoOf(StrokeToFill).p28 = function (_this__u8e3s4, e) {
    _this__u8e3s4.g1i(numberToInt(e.m1e_1), numberToInt(e.n1e_1));
  };
  protoOf(StrokeToFill).q28 = function (out, mainPrev, mainCurr, prev, curr, join, miterLimit, scale, forcedMiter) {
    var rjoin = forcedMiter ? LineJoin_MITER_getInstance() : join;
    switch (rjoin.t9_1) {
      case 2:
        var intersection2 = Vector2D_init_$Create$_0(mainPrev.g25_1, mainPrev.h25_1);
        var intersection = Companion_instance_31.n27(prev, curr);
        if (!(intersection == null)) {
          var dist = Companion_getInstance_15().r1s(intersection, intersection2);
          if (forcedMiter ? true : dist <= miterLimit) {
            out.k1v(toInt_0(intersection));
          } else {
            this.o28(out, prev);
            this.n28(out, curr);
          }
        }

        break;
      case 0:
        this.o28(out, prev);
        this.n28(out, curr);
        break;
      case 1:
        var i = Companion_instance_31.n27(prev, curr);
        if (!(i == null)) {
          var count = clamp_1(numberToInt(Companion_getInstance_15().q1s(prev.g25_1, prev.h25_1, curr.e25_1, curr.f25_1) * scale), 4, 64);
          var inductionVariable = 0;
          if (inductionVariable <= count)
            do {
              var n = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              this.p28(out, Companion_getInstance_22().q1y(new Vector2D(prev.g25_1, prev.h25_1), new Vector2D(i.m1e_1, i.n1e_1), new Vector2D(curr.e25_1, curr.f25_1), _Ratio___init__impl__9mwvn2(n / count)));
            }
             while (!(n === count));
        } else {
          this.o28(out, prev);
          this.n28(out, curr);
        }

        break;
    }
  };
  protoOf(StrokeToFill).r28 = function (l, r, left, right, epoint, cap, scale) {
    var angle = epoint.equals(EdgePoint_A_getInstance()) ? Angle__unaryMinus_impl_7901fr(left.q27()) : Angle__unaryPlus_impl_eyi5pl(left.q27());
    var lx = left.j1u(epoint.l28_1);
    var ly = left.k1u(epoint.l28_1);
    var rx = right.j1u(epoint.l28_1);
    var ry = right.k1u(epoint.l28_1);
    switch (cap.t9_1) {
      case 0:
        l.g1i(lx, ly);
        r.g1i(rx, ry);
        break;
      case 2:
      case 1:
        var ax = numberToInt(_Angle___get_cosine__impl__vprvmu(angle) * this.s27_1 / 2);
        var ay = numberToInt(_Angle___get_sine__impl__15nmyi(angle) * this.s27_1 / 2);
        var lx2 = lx + ax | 0;
        var ly2 = ly + ay | 0;
        var rx2 = rx + ax | 0;
        var ry2 = ry + ay | 0;
        if (cap.equals(LineCap_SQUARE_getInstance())) {
          l.g1i(lx2, ly2);
          r.g1i(rx2, ry2);
        } else {
          var count = clamp_1(numberToInt(Companion_getInstance_36().q1s(lx, ly, rx, ry) * scale), 4, 64);
          l.g1i(lx, ly);
          var inductionVariable = 0;
          if (inductionVariable <= count)
            do {
              var n = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              var m = epoint.equals(EdgePoint_A_getInstance()) ? n : count - n | 0;
              var ratio = _Ratio___init__impl__9mwvn2(m / count);
              this.p28(r, Companion_getInstance_22().p1y(new Vector2D(lx, ly), new Vector2D(lx2, ly2), new Vector2D(rx2, ry2), new Vector2D(rx, ry), ratio));
            }
             while (!(n === count));
        }

        break;
    }
  };
  protoOf(StrokeToFill).z28 = function (scale, closed) {
    if (this.y27_1.x())
      return Unit_instance;
    var weightD2 = this.s27_1 / 2 | 0;
    this.b28_1.l1();
    this.c28_1.l1();
    var sp = this.y27_1;
    var nstrokePoints = sp.l();
    var inductionVariable = 0;
    if (inductionVariable < nstrokePoints)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var isFirst = n === 0;
        var isLast = n === (nstrokePoints - 1 | 0);
        var isMiddle = !isFirst ? !isLast ? true : closed : false;
        var n1 = isLast ? closed ? 1 : n : n + 1 | 0;
        this.d28_1.r27(this.g28_1);
        this.e28_1.r27(this.h28_1);
        this.f28_1.r27(this.i28_1);
        var doJoin = !(this.z27_1.e10(n) === 0);
        this.g28_1.p26(sp.j1u(n), sp.k1u(n), sp.j1u(n1), sp.k1u(n1), 1);
        this.m28(this.h28_1, this.g28_1, weightD2, Angle__minus_impl_xeau7k(this.g28_1.q27(), get_degrees(90)));
        this.m28(this.i28_1, this.g28_1, weightD2, Angle__plus_impl_26v37k(this.g28_1.q27(), get_degrees(90)));
        if (isFirst) {
          this.r28(this.b28_1, this.c28_1, this.h28_1, this.i28_1, EdgePoint_A_getInstance(), closed ? LineCap_BUTT_getInstance() : this.u27_1, scale);
        } else if (isMiddle) {
          var angle = Companion_instance_31.p27(this.d28_1, this.g28_1);
          var leftAngle = Angle__compareTo_impl_a0hfds(angle, get_degrees(0)) > 0;
          if (doJoin) {
            this.q28(this.b28_1, this.d28_1, this.g28_1, this.e28_1, this.h28_1, this.w27_1, this.x27_1, scale, leftAngle);
            this.q28(this.c28_1, this.d28_1, this.g28_1, this.f28_1, this.i28_1, this.w27_1, this.x27_1, scale, !leftAngle);
          } else {
            this.n28(this.b28_1, this.h28_1);
            this.n28(this.c28_1, this.i28_1);
          }
        } else if (isLast) {
          if (closed) {
            this.r28(this.b28_1, this.c28_1, this.h28_1, this.i28_1, EdgePoint_B_getInstance(), LineCap_BUTT_getInstance(), scale);
          } else {
            this.r28(this.b28_1, this.c28_1, this.e28_1, this.f28_1, EdgePoint_B_getInstance(), this.v27_1, scale);
          }
        }
      }
       while (inductionVariable < nstrokePoints);
    var inductionVariable_0 = 0;
    var last = this.b28_1.l();
    if (inductionVariable_0 < last)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var x = this.b28_1.j1u(n_0);
        var y = this.b28_1.k1u(n_0);
        if (n_0 === 0) {
          _get_outFill__zcv64g(this).f20(new Vector2D(x * scale, y * scale));
        } else {
          _get_outFill__zcv64g(this).s20(new Vector2D(x * scale, y * scale));
        }
      }
       while (inductionVariable_0 < last);
    var inductionVariable_1 = 0;
    var last_0 = this.c28_1.l();
    if (inductionVariable_1 < last_0)
      do {
        var n_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var m = (this.c28_1.l() - n_1 | 0) - 1 | 0;
        _get_outFill__zcv64g(this).s20(new Vector2D(this.c28_1.j1u(m) * scale, this.c28_1.k1u(m) * scale));
      }
       while (inductionVariable_1 < last_0);
    _get_outFill__zcv64g(this).x18();
    _get_outFill__zcv64g(this).j20_1 = Winding_NON_ZERO_getInstance();
    this.y27_1.l1();
    this.z27_1.l1();
  };
  protoOf(StrokeToFill).a29 = function (outFill, weight, startCap, endCap, joins, miterLimit) {
    this.t27_1 = outFill;
    this.s27_1 = weight;
    this.u27_1 = startCap;
    this.v27_1 = endCap;
    this.w27_1 = joins;
    this.x27_1 = miterLimit * weight;
  };
  protoOf(StrokeToFill).b29 = function (stroke, lineWidth, joins, startCap, endCap, miterLimit, outFill) {
    var scale = 20;
    var iscale = 1.0 / 20;
    this.a29(outFill, numberToInt(lineWidth * scale), startCap, endCap, joins, miterLimit);
    // Inline function 'korlibs.math.geom.shape.emitPoints2' call
    var i = Vector2D_init_$Create$_1();
    var l = Vector2D_init_$Create$_1();
    // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
    if (false) {
      this.z28(iscale, true);
    }
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = stroke.h20_1;
    var n_0 = 0;
    while (n_0 < this_0.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp0_subject = this_0.e10(tmp0);
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = stroke.i20_1.b1(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var it = new Vector2D(tmp, stroke.i20_1.b1(tmp2));
        i = it;
        // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
        if (true) {
          this.z28(iscale, false);
        }
        this.y27_1.g1i(numberToInt(it.m1e_1 * scale), numberToInt(it.n1e_1 * scale));
        this.z27_1.o11(0);
        l = it;
      } else {
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = stroke.i20_1.b1(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var it_0 = new Vector2D(tmp_0, stroke.i20_1.b1(tmp4));
          // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
          if (false) {
            this.z28(iscale, false);
          }
          this.y27_1.g1i(numberToInt(it_0.m1e_1 * scale), numberToInt(it_0.n1e_1 * scale));
          this.z27_1.o11(0);
          l = it_0;
          // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
          this.z27_1.t11(this.z27_1.l() - 1 | 0, 1);
        } else {
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = stroke.i20_1.b1(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var c = new Vector2D(tmp_1, stroke.i20_1.b1(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = stroke.i20_1.b1(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var a = new Vector2D(tmp_2, stroke.i20_1.b1(tmp8));
            var sum = Companion_getInstance_15().r1s(l, c) + Companion_getInstance_15().r1s(c, a);
            // Inline function 'korlibs.math.geom.shape.approximateCurve' call
            // Inline function 'kotlin.math.max' call
            var a_0 = numberToInt(sum);
            var rcurveSteps = Math.max(a_0, 20);
            var dt = 1.0 / rcurveSteps;
            var lastPos = {_v: Vector2D_init_$Create$_1()};
            var prevPos = {_v: Vector2D_init_$Create$_1()};
            var emittedCount = {_v: 0};
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
            var ratio = Companion_getInstance().g1e_1;
            // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
            lastPos._v = Companion_getInstance_22().q1y(l, c, a, ratio);
            var nStart = false ? 0 : 1;
            var nEnd = true ? rcurveSteps : rcurveSteps - 1 | 0;
            var inductionVariable = nStart;
            if (inductionVariable <= nEnd)
              do {
                var n_1 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var ratio_0 = _Ratio___init__impl__9mwvn2_0(n_1 * dt);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                var it_1 = Companion_getInstance_22().q1y(l, c, a, ratio_0);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                if (false) {
                  this.z28(iscale, false);
                }
                this.y27_1.g1i(numberToInt(it_1.m1e_1 * scale), numberToInt(it_1.n1e_1 * scale));
                this.z27_1.o11(0);
                emittedCount._v = emittedCount._v + 1 | 0;
                lastPos._v = prevPos._v;
                prevPos._v = it_1;
              }
               while (!(n_1 === nEnd));
            l = a;
            // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
            this.z27_1.t11(this.z27_1.l() - 1 | 0, 1);
          } else {
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = stroke.i20_1.b1(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var c0 = new Vector2D(tmp_3, stroke.i20_1.b1(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = stroke.i20_1.b1(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var c1 = new Vector2D(tmp_4, stroke.i20_1.b1(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = stroke.i20_1.b1(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var a_1 = new Vector2D(tmp_5, stroke.i20_1.b1(tmp14));
              var sum_0 = Companion_getInstance_15().r1s(l, c0) + Companion_getInstance_15().r1s(c0, c1) + Companion_getInstance_15().r1s(c1, a_1);
              // Inline function 'korlibs.math.geom.shape.approximateCurve' call
              // Inline function 'kotlin.math.max' call
              var a_2 = numberToInt(sum_0);
              var rcurveSteps_0 = Math.max(a_2, 20);
              var dt_0 = 1.0 / rcurveSteps_0;
              var lastPos_0 = {_v: Vector2D_init_$Create$_1()};
              var prevPos_0 = {_v: Vector2D_init_$Create$_1()};
              var emittedCount_0 = {_v: 0};
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
              var ratio_1 = Companion_getInstance().g1e_1;
              // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
              lastPos_0._v = Companion_getInstance_22().p1y(l, c0, c1, a_1, ratio_1);
              var nStart_0 = false ? 0 : 1;
              var nEnd_0 = true ? rcurveSteps_0 : rcurveSteps_0 - 1 | 0;
              var inductionVariable_0 = nStart_0;
              if (inductionVariable_0 <= nEnd_0)
                do {
                  var n_2 = inductionVariable_0;
                  inductionVariable_0 = inductionVariable_0 + 1 | 0;
                  var ratio_2 = _Ratio___init__impl__9mwvn2_0(n_2 * dt_0);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                  var it_2 = Companion_getInstance_22().p1y(l, c0, c1, a_1, ratio_2);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                  if (false) {
                    this.z28(iscale, false);
                  }
                  this.y27_1.g1i(numberToInt(it_2.m1e_1 * scale), numberToInt(it_2.n1e_1 * scale));
                  this.z27_1.o11(0);
                  emittedCount_0._v = emittedCount_0._v + 1 | 0;
                  lastPos_0._v = prevPos_0._v;
                  prevPos_0._v = it_2;
                }
                 while (!(n_2 === nEnd_0));
              l = a_1;
              // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
              this.z27_1.t11(this.z27_1.l() - 1 | 0, 1);
            } else {
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                var p = i;
                if (false) {
                  this.z28(iscale, false);
                }
                this.y27_1.g1i(numberToInt(p.m1e_1 * scale), numberToInt(p.n1e_1 * scale));
                this.z27_1.o11(0);
                // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                this.z27_1.t11(this.z27_1.l() - 1 | 0, 1);
                // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                if (true) {
                  this.z28(iscale, true);
                }
              }
            }
          }
        }
      }
    }
    // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
    if (false) {
      this.z28(iscale, true);
    }
    this.z28(iscale, false);
  };
  function strokeToFill(_this__u8e3s4, lineWidth, joins, startCap, endCap, miterLimit, lineDash, lineDashOffset, temp, outFill) {
    joins = joins === VOID ? LineJoin_MITER_getInstance() : joins;
    startCap = startCap === VOID ? LineCap_BUTT_getInstance() : startCap;
    endCap = endCap === VOID ? startCap : endCap;
    miterLimit = miterLimit === VOID ? 4.0 : miterLimit;
    lineDash = lineDash === VOID ? null : lineDash;
    lineDashOffset = lineDashOffset === VOID ? 0.0 : lineDashOffset;
    temp = temp === VOID ? new StrokeToFill() : temp;
    outFill = outFill === VOID ? new VectorPath(VOID, VOID, Winding_NON_ZERO_getInstance()) : outFill;
    var tmp;
    if (!(lineDash == null)) {
      // Inline function 'kotlin.collections.map' call
      // Inline function 'kotlin.collections.flatMap' call
      // Inline function 'kotlin.collections.flatMapTo' call
      var this_0 = toCurvesList(_this__u8e3s4);
      var destination = ArrayList_init_$Create$();
      var tmp0_iterator = this_0.s();
      while (tmp0_iterator.t()) {
        var element = tmp0_iterator.u();
        // Inline function 'korlibs.math.geom.vector.strokeToFill.<anonymous>' call
        var list = toDashes(element, lineDash.h11(), lineDashOffset);
        addAll(destination, list);
      }
      // Inline function 'kotlin.collections.mapTo' call
      var destination_0 = ArrayList_init_$Create$_0(collectionSizeOrDefault(destination, 10));
      var tmp0_iterator_0 = destination.s();
      while (tmp0_iterator_0.t()) {
        var item = tmp0_iterator_0.u();
        // Inline function 'korlibs.math.geom.vector.strokeToFill.<anonymous>' call
        var tmp$ret$3 = toVectorPath_0(item);
        destination_0.p(tmp$ret$3);
      }
      tmp = destination_0;
    } else {
      tmp = listOf(_this__u8e3s4);
    }
    var strokePaths = tmp;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < strokePaths.l()) {
      // Inline function 'korlibs.math.geom.vector.strokeToFill.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var strokePath = strokePaths.b1(tmp0);
      temp.b29(strokePath, lineWidth, joins, startCap, endCap, miterLimit, outFill);
    }
    return outFill;
  }
  function strokeToFill_0(_this__u8e3s4, info, temp, outFill) {
    temp = temp === VOID ? new StrokeToFill() : temp;
    outFill = outFill === VOID ? new VectorPath(VOID, VOID, Winding_NON_ZERO_getInstance()) : outFill;
    return strokeToFill(_this__u8e3s4, info.l1v_1, info.q1v_1, info.o1v_1, info.p1v_1, info.r1v_1, info.s1v_1, info.t1v_1, temp, outFill);
  }
  function t($this, p) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var this_0 = $this.f29_1;
      if (this_0.s1j()) {
        tmp$ret$0 = p;
        break $l$block;
      }
      tmp$ret$0 = new Vector2D(this_0.t1l(p.m1e_1, p.n1e_1), this_0.w1l(p.m1e_1, p.n1e_1));
    }
    return tmp$ret$0;
  }
  function VectorBuilder$transformed$1($parent, $im, $m) {
    this.d29_1 = $parent;
    this.e29_1 = $im;
    this.f29_1 = $m;
    this.c29_1 = $parent.g29();
  }
  protoOf(VectorBuilder$transformed$1).h29 = function () {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var this_0 = this.e29_1;
      var p = this.d29_1.h29();
      if (this_0.s1j()) {
        tmp$ret$0 = p;
        break $l$block;
      }
      tmp$ret$0 = new Vector2D(this_0.t1l(p.m1e_1, p.n1e_1), this_0.w1l(p.m1e_1, p.n1e_1));
    }
    return tmp$ret$0;
  };
  protoOf(VectorBuilder$transformed$1).g29 = function () {
    return this.c29_1;
  };
  protoOf(VectorBuilder$transformed$1).x18 = function () {
    return this.d29_1.x18();
  };
  protoOf(VectorBuilder$transformed$1).s20 = function (p) {
    return this.d29_1.s20(t(this, p));
  };
  protoOf(VectorBuilder$transformed$1).f20 = function (p) {
    return this.d29_1.s20(t(this, p));
  };
  protoOf(VectorBuilder$transformed$1).t20 = function (c, a) {
    return this.d29_1.t20(t(this, c), t(this, a));
  };
  protoOf(VectorBuilder$transformed$1).g20 = function (c1, c2, a) {
    return this.d29_1.g20(t(this, c1), t(this, c2), t(this, a));
  };
  function VectorBuilder() {
  }
  var _bvhCurvesCacheVersion$delegate;
  var _bvhCurvesCache$delegate;
  function set__curvesCacheVersion(_this__u8e3s4, _set____db54di) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    var this_0 = _curvesCacheVersion$delegate;
    var property = _curvesCacheVersion$factory();
    var tmp0_elvis_lhs = this_0.e19_1;
    var tmp = tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs;
    // Inline function 'korlibs.datastructure.fastCastTo' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    setExtra(_this__u8e3s4, tmp, _set____db54di);
    return Unit_instance;
  }
  function get__curvesCacheVersion(_this__u8e3s4) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    var tmp$ret$6;
    $l$block: {
      // Inline function 'korlibs.datastructure.Property.getValue' call
      var this_0 = _curvesCacheVersion$delegate;
      var property = _curvesCacheVersion$factory_0();
      // Inline function 'korlibs.datastructure.fastCastTo' call
      var tmp1_safe_receiver = _this__u8e3s4.d19();
      var tmp;
      if (tmp1_safe_receiver == null) {
        tmp = null;
      } else {
        var tmp0_elvis_lhs = this_0.e19_1;
        tmp = tmp1_safe_receiver.t12(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
      }
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var res = tmp;
      if (res == null) {
        var r = this_0.f19_1();
        var tmp_0;
        if (!(r == null)) {
          tmp_0 = true;
        } else {
          var tmp2_elvis_lhs = this_0.e19_1;
          tmp_0 = hasExtra(_this__u8e3s4, tmp2_elvis_lhs == null ? property.callableName : tmp2_elvis_lhs);
        }
        if (tmp_0) {
          // Inline function 'korlibs.datastructure.Property.setValue' call
          var tmp0_elvis_lhs_0 = this_0.e19_1;
          var tmp_1 = tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0;
          // Inline function 'korlibs.datastructure.fastCastTo' call
          // Inline function 'kotlin.js.unsafeCast' call
          // Inline function 'kotlin.js.asDynamic' call
          setExtra(_this__u8e3s4, tmp_1, r);
        }
        tmp$ret$6 = r;
        break $l$block;
      }
      tmp$ret$6 = res;
    }
    return tmp$ret$6;
  }
  var _curvesCacheVersion$delegate;
  function set__curvesCache(_this__u8e3s4, _set____db54di) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    var this_0 = _curvesCache$delegate;
    var property = _curvesCache$factory();
    var tmp0_elvis_lhs = this_0.e19_1;
    var tmp = tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs;
    // Inline function 'korlibs.datastructure.fastCastTo' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    setExtra(_this__u8e3s4, tmp, _set____db54di);
    return Unit_instance;
  }
  function get__curvesCache(_this__u8e3s4) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    var tmp$ret$6;
    $l$block: {
      // Inline function 'korlibs.datastructure.Property.getValue' call
      var this_0 = _curvesCache$delegate;
      var property = _curvesCache$factory_0();
      // Inline function 'korlibs.datastructure.fastCastTo' call
      var tmp1_safe_receiver = _this__u8e3s4.d19();
      var tmp;
      if (tmp1_safe_receiver == null) {
        tmp = null;
      } else {
        var tmp0_elvis_lhs = this_0.e19_1;
        tmp = tmp1_safe_receiver.t12(tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs);
      }
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var res = tmp;
      if (res == null) {
        var r = this_0.f19_1();
        var tmp_0;
        if (!(r == null)) {
          tmp_0 = true;
        } else {
          var tmp2_elvis_lhs = this_0.e19_1;
          tmp_0 = hasExtra(_this__u8e3s4, tmp2_elvis_lhs == null ? property.callableName : tmp2_elvis_lhs);
        }
        if (tmp_0) {
          // Inline function 'korlibs.datastructure.Property.setValue' call
          var tmp0_elvis_lhs_0 = this_0.e19_1;
          var tmp_1 = tmp0_elvis_lhs_0 == null ? property.callableName : tmp0_elvis_lhs_0;
          // Inline function 'korlibs.datastructure.fastCastTo' call
          // Inline function 'kotlin.js.unsafeCast' call
          // Inline function 'kotlin.js.asDynamic' call
          setExtra(_this__u8e3s4, tmp_1, r);
        }
        tmp$ret$6 = r;
        break $l$block;
      }
      tmp$ret$6 = res;
    }
    return tmp$ret$6;
  }
  var _curvesCache$delegate;
  function Companion_31() {
  }
  var Companion_instance_32;
  function Companion_getInstance_31() {
    return Companion_instance_32;
  }
  function Visitor() {
  }
  function ensureMoveTo($this, p) {
    if ($this.v11())
      return false;
    $this.f20(p);
    return true;
  }
  function Command() {
    this.s29_1 = 0;
    this.t29_1 = 1;
    this.u29_1 = 2;
    this.v29_1 = 3;
    this.w29_1 = 4;
  }
  var Command_instance;
  function Command_getInstance() {
    return Command_instance;
  }
  function VectorPath(commands, data, winding, optimize) {
    commands = commands === VOID ? new IntArrayList() : commands;
    data = data === VOID ? new DoubleArrayList() : data;
    winding = winding === VOID ? Companion_instance_33.x29() : winding;
    optimize = optimize === VOID ? true : optimize;
    AbstractShape2D.call(this);
    this.h20_1 = commands;
    this.i20_1 = data;
    this.j20_1 = winding;
    this.k20_1 = optimize;
    this.l20_1 = new Mixin();
    this.m20_1 = false;
    this.n20_1 = 0;
    this.o20_1 = Vector2D_init_$Create$_1();
    this.p20_1 = Vector2D_init_$Create$_1();
    this.q20_1 = null;
    this.r20_1 = null;
  }
  protoOf(VectorPath).c19 = function (_set____db54di) {
    this.l20_1.c19(_set____db54di);
  };
  protoOf(VectorPath).d19 = function () {
    return this.l20_1.d19();
  };
  protoOf(VectorPath).i11 = function () {
    return new VectorPath(IntArrayList_init_$Create$(this.h20_1), DoubleArrayList_init_$Create$(this.i20_1), this.j20_1);
  };
  protoOf(VectorPath).equals = function (other) {
    if (this === other)
      return true;
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof VectorPath) {
      tmp_1 = this.h20_1.equals(other.h20_1);
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.i20_1.equals(other.i20_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.j20_1.equals(other.j20_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(VectorPath).hashCode = function () {
    return (this.h20_1.hashCode() + imul(this.i20_1.hashCode(), 13) | 0) + imul(this.j20_1.t9_1, 111) | 0;
  };
  protoOf(VectorPath).y29 = function (visitor) {
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = this.h20_1;
    var n_0 = 0;
    while (n_0 < this_0.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      switch (this_0.e10(tmp0)) {
        case 0:
          var tmp1 = n;
          n = tmp1 + 1 | 0;
          var tmp = this.i20_1.b1(tmp1);
          var tmp2 = n;
          n = tmp2 + 1 | 0;
          visitor.f20(new Vector2D(tmp, this.i20_1.b1(tmp2)));
          break;
        case 1:
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = this.i20_1.b1(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          visitor.s20(new Vector2D(tmp_0, this.i20_1.b1(tmp4)));
          break;
        case 2:
          var tmp5 = n;
          n = tmp5 + 1 | 0;
          var tmp_1 = this.i20_1.b1(tmp5);
          var tmp6 = n;
          n = tmp6 + 1 | 0;
          var tmp_2 = new Vector2D(tmp_1, this.i20_1.b1(tmp6));
          var tmp7 = n;
          n = tmp7 + 1 | 0;
          var tmp_3 = this.i20_1.b1(tmp7);
          var tmp8 = n;
          n = tmp8 + 1 | 0;
          visitor.t20(tmp_2, new Vector2D(tmp_3, this.i20_1.b1(tmp8)));
          break;
        case 3:
          var tmp9 = n;
          n = tmp9 + 1 | 0;
          var tmp_4 = this.i20_1.b1(tmp9);
          var tmp10 = n;
          n = tmp10 + 1 | 0;
          var tmp_5 = new Vector2D(tmp_4, this.i20_1.b1(tmp10));
          var tmp11 = n;
          n = tmp11 + 1 | 0;
          var tmp_6 = this.i20_1.b1(tmp11);
          var tmp12 = n;
          n = tmp12 + 1 | 0;
          var tmp_7 = new Vector2D(tmp_6, this.i20_1.b1(tmp12));
          var tmp13 = n;
          n = tmp13 + 1 | 0;
          var tmp_8 = this.i20_1.b1(tmp13);
          var tmp14 = n;
          n = tmp14 + 1 | 0;
          visitor.g20(tmp_5, tmp_7, new Vector2D(tmp_8, this.i20_1.b1(tmp14)));
          break;
        case 4:
          visitor.x18();
          break;
      }
    }
  };
  protoOf(VectorPath).l1 = function () {
    this.h20_1.l1();
    this.i20_1.l1();
    this.o20_1 = Vector2D_init_$Create$_1();
    this.n20_1 = 0;
    this.z29().w25_1 = this.n20_1 - 1 | 0;
  };
  protoOf(VectorPath).h29 = function () {
    return this.o20_1;
  };
  protoOf(VectorPath).f20 = function (p) {
    if (this.h20_1.v11() ? this.h20_1.x11() === 0 : false) {
      if (this.o20_1.equals(p))
        return Unit_instance;
    }
    this.h20_1.o11(0);
    this.i20_1.v10(p.m1e_1, p.n1e_1);
    this.o20_1 = p;
    this.p20_1 = p;
    this.n20_1 = this.n20_1 + 1 | 0;
  };
  protoOf(VectorPath).s20 = function (p) {
    if (ensureMoveTo(this, p) ? this.k20_1 : false)
      return Unit_instance;
    if (p.equals(this.o20_1) ? this.k20_1 : false)
      return Unit_instance;
    this.h20_1.o11(1);
    this.i20_1.v10(p.m1e_1, p.n1e_1);
    this.o20_1 = p;
    this.n20_1 = this.n20_1 + 1 | 0;
  };
  protoOf(VectorPath).t20 = function (c, a) {
    ensureMoveTo(this, c);
    this.h20_1.o11(2);
    this.i20_1.w10(c.m1e_1, c.n1e_1, a.m1e_1, a.n1e_1);
    this.o20_1 = a;
    this.n20_1 = this.n20_1 + 1 | 0;
  };
  protoOf(VectorPath).g20 = function (c1, c2, a) {
    ensureMoveTo(this, c1);
    this.h20_1.o11(3);
    this.i20_1.x10(c1.m1e_1, c1.n1e_1, c2.m1e_1, c2.n1e_1, a.m1e_1, a.n1e_1);
    this.o20_1 = a;
    this.n20_1 = this.n20_1 + 1 | 0;
  };
  protoOf(VectorPath).x18 = function () {
    this.h20_1.o11(4);
    this.o20_1 = this.p20_1;
    this.n20_1 = this.n20_1 + 1 | 0;
  };
  protoOf(VectorPath).g29 = function () {
    return this.i20_1.l() / 2 | 0;
  };
  protoOf(VectorPath).h1x = function () {
    return _BoundsBuilder___get_bounds__impl__s1l1gx(plus(Companion_getInstance_3().d1k(), this));
  };
  protoOf(VectorPath).a2a = function (p) {
    return this.e27(p.m1e_1, p.n1e_1, this.j20_1);
  };
  protoOf(VectorPath).z29 = function () {
    if (this.r20_1 == null)
      this.r20_1 = new PolygonScanline();
    var scanline = ensureNotNull(this.r20_1);
    if (!(scanline.w25_1 === this.n20_1)) {
      scanline.i17();
      scanline.y26(this);
      scanline.w25_1 = this.n20_1;
    }
    return ensureNotNull(this.r20_1);
  };
  protoOf(VectorPath).e27 = function (x, y, winding) {
    return this.z29().e27(x, y, winding);
  };
  protoOf(VectorPath).b2a = function (path, transform) {
    this.h20_1.q11(path.h20_1);
    if (transform.q1l()) {
      this.i20_1.z10(path.i20_1);
      this.o20_1 = path.o20_1;
    } else {
      var progression = step(until(0, path.i20_1.l()), 2);
      var inductionVariable = progression.pa_1;
      var last = progression.qa_1;
      var step_0 = progression.ra_1;
      if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + step_0 | 0;
          var tmp$ret$0;
          $l$block: {
            // Inline function 'korlibs.math.geom.Matrix.transform' call
            var p = new Vector2D(path.i20_1.b1(n + 0 | 0), path.i20_1.b1(n + 1 | 0));
            if (transform.s1j()) {
              tmp$ret$0 = p;
              break $l$block;
            }
            tmp$ret$0 = new Vector2D(transform.t1l(p.m1e_1, p.n1e_1), transform.w1l(p.m1e_1, p.n1e_1));
          }
          var p_0 = tmp$ret$0;
          this.i20_1.v10(p_0.m1e_1, p_0.n1e_1);
        }
         while (!(n === last));
      var tmp = this;
      var tmp$ret$1;
      $l$block_0: {
        // Inline function 'korlibs.math.geom.Matrix.transform' call
        var p_1 = path.o20_1;
        if (transform.s1j()) {
          tmp$ret$1 = p_1;
          break $l$block_0;
        }
        tmp$ret$1 = new Vector2D(transform.t1l(p_1.m1e_1, p_1.n1e_1), transform.w1l(p_1.m1e_1, p_1.n1e_1));
      }
      tmp.o20_1 = tmp$ret$1;
    }
    this.n20_1 = this.n20_1 + 1 | 0;
  };
  protoOf(VectorPath).c2a = function (path, transform, $super) {
    transform = transform === VOID ? Companion_getInstance_6().b1l_1 : transform;
    var tmp;
    if ($super === VOID) {
      this.b2a(path, transform);
      tmp = Unit_instance;
    } else {
      tmp = $super.b2a.call(this, path, transform);
    }
    return tmp;
  };
  protoOf(VectorPath).d2a = function () {
    // Inline function 'kotlin.text.trimEnd' call
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>' call
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_1 = this.h20_1;
    var n_0 = 0;
    while (n_0 < this_1.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      switch (this_1.e10(tmp0)) {
        case 0:
          // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call

          var tmp1 = n;
          n = tmp1 + 1 | 0;
          var tmp = this.i20_1.b1(tmp1);
          var tmp2 = n;
          n = tmp2 + 1 | 0;
          var name_for_destructuring_parameter_0_fjsvno = new Vector2D(tmp, this.i20_1.b1(tmp2));
          var x = name_for_destructuring_parameter_0_fjsvno.bf();
          var y = name_for_destructuring_parameter_0_fjsvno.cf();
          this_0.i5('M' + get_niceStr_0(x) + ',' + get_niceStr_0(y) + ' ');
          break;
        case 1:
          // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call

          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = this.i20_1.b1(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var name_for_destructuring_parameter_0_fjsvno_0 = new Vector2D(tmp_0, this.i20_1.b1(tmp4));
          var x_0 = name_for_destructuring_parameter_0_fjsvno_0.bf();
          var y_0 = name_for_destructuring_parameter_0_fjsvno_0.cf();
          this_0.i5('L' + get_niceStr_0(x_0) + ',' + get_niceStr_0(y_0) + ' ');
          break;
        case 2:
          // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call

          var tmp5 = n;
          n = tmp5 + 1 | 0;
          var tmp_1 = this.i20_1.b1(tmp5);
          var tmp6 = n;
          n = tmp6 + 1 | 0;
          var name_for_destructuring_parameter_0_fjsvno_1 = new Vector2D(tmp_1, this.i20_1.b1(tmp6));
          var tmp7 = n;
          n = tmp7 + 1 | 0;
          var tmp_2 = this.i20_1.b1(tmp7);
          var tmp8 = n;
          n = tmp8 + 1 | 0;
          var name_for_destructuring_parameter_1_fjsvnn = new Vector2D(tmp_2, this.i20_1.b1(tmp8));
          var x1 = name_for_destructuring_parameter_0_fjsvno_1.bf();
          var y1 = name_for_destructuring_parameter_0_fjsvno_1.cf();
          var x2 = name_for_destructuring_parameter_1_fjsvnn.bf();
          var y2 = name_for_destructuring_parameter_1_fjsvnn.cf();
          this_0.i5('Q' + get_niceStr_0(x1) + ',' + get_niceStr_0(y1) + ',' + get_niceStr_0(x2) + ',' + get_niceStr_0(y2) + ' ');
          break;
        case 3:
          // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call

          var tmp9 = n;
          n = tmp9 + 1 | 0;
          var tmp_3 = this.i20_1.b1(tmp9);
          var tmp10 = n;
          n = tmp10 + 1 | 0;
          var name_for_destructuring_parameter_0_fjsvno_2 = new Vector2D(tmp_3, this.i20_1.b1(tmp10));
          var tmp11 = n;
          n = tmp11 + 1 | 0;
          var tmp_4 = this.i20_1.b1(tmp11);
          var tmp12 = n;
          n = tmp12 + 1 | 0;
          var name_for_destructuring_parameter_1_fjsvnn_0 = new Vector2D(tmp_4, this.i20_1.b1(tmp12));
          var tmp13 = n;
          n = tmp13 + 1 | 0;
          var tmp_5 = this.i20_1.b1(tmp13);
          var tmp14 = n;
          n = tmp14 + 1 | 0;
          var name_for_destructuring_parameter_2_fjsvnm = new Vector2D(tmp_5, this.i20_1.b1(tmp14));
          var x1_0 = name_for_destructuring_parameter_0_fjsvno_2.bf();
          var y1_0 = name_for_destructuring_parameter_0_fjsvno_2.cf();
          var x2_0 = name_for_destructuring_parameter_1_fjsvnn_0.bf();
          var y2_0 = name_for_destructuring_parameter_1_fjsvnn_0.cf();
          var x3 = name_for_destructuring_parameter_2_fjsvnm.bf();
          var y3 = name_for_destructuring_parameter_2_fjsvnm.cf();
          this_0.i5('C' + get_niceStr_0(x1_0) + ',' + get_niceStr_0(y1_0) + ',' + get_niceStr_0(x2_0) + ',' + get_niceStr_0(y2_0) + ',' + get_niceStr_0(x3) + ',' + get_niceStr_0(y3) + ' ');
          break;
        case 4:
          // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call

          this_0.i5('Z ');
          break;
      }
    }
    var this_2 = this_0.toString();
    return toString(trimEnd(isCharSequence(this_2) ? this_2 : THROW_CCE()));
  };
  protoOf(VectorPath).toString = function () {
    return 'VectorPath(' + this.d2a() + ')';
  };
  protoOf(VectorPath).e2a = function (sx, sy) {
    // Inline function 'korlibs.math.geom.vector.VectorPath.transformPoints' call
    var progression = step(until(0, this.i20_1.l()), 2);
    var inductionVariable = progression.pa_1;
    var last = progression.qa_1;
    var step_0 = progression.ra_1;
    if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        // Inline function 'korlibs.math.geom.vector.VectorPath.scale.<anonymous>' call
        var it = new Vector2D(this.i20_1.b1(n + 0 | 0), this.i20_1.b1(n + 1 | 0));
        var p = new Vector2D(it.m1e_1 * sx, it.n1e_1 * sy);
        this.i20_1.c11(n + 0 | 0, p.m1e_1);
        this.i20_1.c11(n + 1 | 0, p.n1e_1);
      }
       while (!(n === last));
    this.n20_1 = this.n20_1 + 1 | 0;
    return this;
  };
  function getCurvesList(_this__u8e3s4) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    if (!(get__curvesCacheVersion(_this__u8e3s4) === _this__u8e3s4.n20_1)) {
      set__curvesCacheVersion(_this__u8e3s4, _this__u8e3s4.n20_1);
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.collections.arrayListOf' call
      var this_0 = ArrayList_init_$Create$();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>' call
      var currentClosed = {_v: false};
      // Inline function 'kotlin.collections.arrayListOf' call
      var current = {_v: ArrayList_init_$Create$()};
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges' call
      var m = Vector2D_init_$Create$_1();
      var l = Vector2D_init_$Create$_1();
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
      var n = 0;
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var this_1 = _this__u8e3s4.h20_1;
      var n_0 = 0;
      while (n_0 < this_1.l()) {
        // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
        var tmp0 = n_0;
        n_0 = tmp0 + 1 | 0;
        switch (this_1.e10(tmp0)) {
          case 0:
            // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call

            var tmp1 = n;
            n = tmp1 + 1 | 0;
            var tmp = _this__u8e3s4.i20_1.b1(tmp1);
            var tmp2 = n;
            n = tmp2 + 1 | 0;
            var it = new Vector2D(tmp, _this__u8e3s4.i20_1.b1(tmp2));
            m = it;
            l = it;
            // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call

            getCurvesList$_anonymous_$flush_1e57i7(current, this_0, currentClosed, _this__u8e3s4);
            break;
          case 1:
            // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call

            var tmp3 = n;
            n = tmp3 + 1 | 0;
            var tmp_0 = _this__u8e3s4.i20_1.b1(tmp3);
            var tmp4 = n;
            n = tmp4 + 1 | 0;
            var it_0 = new Vector2D(tmp_0, _this__u8e3s4.i20_1.b1(tmp4));
            // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call

            var p1 = l;
            // Inline function 'kotlin.collections.plusAssign' call

            var this_2 = current._v;
            var element = Bezier_init_$Create$_0(p1, it_0);
            this_2.p(element);
            l = it_0;
            break;
          case 2:
            // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call

            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = _this__u8e3s4.i20_1.b1(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var p1_0 = new Vector2D(tmp_1, _this__u8e3s4.i20_1.b1(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = _this__u8e3s4.i20_1.b1(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var p2 = new Vector2D(tmp_2, _this__u8e3s4.i20_1.b1(tmp8));
            // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call

            var p1_1 = l;
            // Inline function 'kotlin.collections.plusAssign' call

            var this_3 = current._v;
            var element_0 = Bezier_init_$Create$_1(p1_1, p1_0, p2);
            this_3.p(element_0);
            l = p2;
            break;
          case 3:
            // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call

            var tmp9 = n;
            n = tmp9 + 1 | 0;
            var tmp_3 = _this__u8e3s4.i20_1.b1(tmp9);
            var tmp10 = n;
            n = tmp10 + 1 | 0;
            var p1_2 = new Vector2D(tmp_3, _this__u8e3s4.i20_1.b1(tmp10));
            var tmp11 = n;
            n = tmp11 + 1 | 0;
            var tmp_4 = _this__u8e3s4.i20_1.b1(tmp11);
            var tmp12 = n;
            n = tmp12 + 1 | 0;
            var p2_0 = new Vector2D(tmp_4, _this__u8e3s4.i20_1.b1(tmp12));
            var tmp13 = n;
            n = tmp13 + 1 | 0;
            var tmp_5 = _this__u8e3s4.i20_1.b1(tmp13);
            var tmp14 = n;
            n = tmp14 + 1 | 0;
            var p3 = new Vector2D(tmp_5, _this__u8e3s4.i20_1.b1(tmp14));
            // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call

            var p1_3 = l;
            // Inline function 'kotlin.collections.plusAssign' call

            var this_4 = current._v;
            var element_1 = Bezier_init_$Create$_2(p1_3, p1_2, p2_0, p3);
            this_4.p(element_1);
            l = p3;
            break;
          case 4:
            // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call

            var equal = true ? l.x1h(m) : l.equals(m);
            if (!equal) {
              // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call
              var p1_4 = l;
              var p2_1 = m;
              // Inline function 'kotlin.collections.plusAssign' call
              var this_5 = current._v;
              var element_2 = Bezier_init_$Create$_0(p1_4, p2_1);
              this_5.p(element_2);
            }

            // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call

            currentClosed._v = true;
            getCurvesList$_anonymous_$flush_1e57i7(current, this_0, currentClosed, _this__u8e3s4);
            break;
        }
      }
      getCurvesList$_anonymous_$flush_1e57i7(current, this_0, currentClosed, _this__u8e3s4);
      set__curvesCache(_this__u8e3s4, this_0);
    }
    return ensureNotNull(get__curvesCache(_this__u8e3s4));
  }
  function plus(_this__u8e3s4, path) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    return with_0(_this__u8e3s4, path);
  }
  function with_0(_this__u8e3s4, path, transform) {
    transform = transform === VOID ? Companion_getInstance_6().c1l_1 : transform;
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    var bb = {_v: new BoundsBuilder(_this__u8e3s4)};
    var curvesList = getCurvesList(path);
    if (curvesList.x() ? path.v11() : false) {
      path.y29(new with$1(bb));
    }
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < curvesList.l()) {
      // Inline function 'korlibs.math.geom.vector.with.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var this_0 = curvesList.b1(tmp0).u1w_1;
      var n_0 = 0;
      while (n_0 < this_0.l()) {
        // Inline function 'korlibs.math.geom.vector.with.<anonymous>.<anonymous>' call
        var tmp0_0 = n_0;
        n_0 = tmp0_0 + 1 | 0;
        var bezier = this_0.b1(tmp0_0);
        bb._v = new BoundsBuilder(BoundsBuilder__plus_impl_cz7f7x_1(bb._v.n1k_1, bezier.a1z(transform)));
      }
    }
    return bb._v.n1k_1;
  }
  function write(_this__u8e3s4, path) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var this_0 = path.h20_1;
    var n_0 = 0;
    while (n_0 < this_0.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      switch (this_0.e10(tmp0)) {
        case 0:
          // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call

          var tmp1 = n;
          n = tmp1 + 1 | 0;
          var tmp = path.i20_1.b1(tmp1);
          var tmp2 = n;
          n = tmp2 + 1 | 0;
          var it = new Vector2D(tmp, path.i20_1.b1(tmp2));
          _this__u8e3s4.f20(it);
          break;
        case 1:
          // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call

          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = path.i20_1.b1(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var it_0 = new Vector2D(tmp_0, path.i20_1.b1(tmp4));
          _this__u8e3s4.s20(it_0);
          break;
        case 2:
          // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call

          var tmp5 = n;
          n = tmp5 + 1 | 0;
          var tmp_1 = path.i20_1.b1(tmp5);
          var tmp6 = n;
          n = tmp6 + 1 | 0;
          var p1 = new Vector2D(tmp_1, path.i20_1.b1(tmp6));
          var tmp7 = n;
          n = tmp7 + 1 | 0;
          var tmp_2 = path.i20_1.b1(tmp7);
          var tmp8 = n;
          n = tmp8 + 1 | 0;
          var p2 = new Vector2D(tmp_2, path.i20_1.b1(tmp8));
          _this__u8e3s4.t20(p1, p2);
          break;
        case 3:
          // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call

          var tmp9 = n;
          n = tmp9 + 1 | 0;
          var tmp_3 = path.i20_1.b1(tmp9);
          var tmp10 = n;
          n = tmp10 + 1 | 0;
          var p1_0 = new Vector2D(tmp_3, path.i20_1.b1(tmp10));
          var tmp11 = n;
          n = tmp11 + 1 | 0;
          var tmp_4 = path.i20_1.b1(tmp11);
          var tmp12 = n;
          n = tmp12 + 1 | 0;
          var p2_0 = new Vector2D(tmp_4, path.i20_1.b1(tmp12));
          var tmp13 = n;
          n = tmp13 + 1 | 0;
          var tmp_5 = path.i20_1.b1(tmp13);
          var tmp14 = n;
          n = tmp14 + 1 | 0;
          var p3 = new Vector2D(tmp_5, path.i20_1.b1(tmp14));
          _this__u8e3s4.g20(p1_0, p2_0, p3);
          break;
        case 4:
          // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call

          _this__u8e3s4.x18();
          break;
      }
    }
  }
  function getCurves(_this__u8e3s4) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    var curvesList = getCurvesList(_this__u8e3s4);
    // Inline function 'kotlin.collections.flatMap' call
    // Inline function 'kotlin.collections.flatMapTo' call
    var destination = ArrayList_init_$Create$();
    var tmp0_iterator = curvesList.s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      // Inline function 'korlibs.math.geom.vector.getCurves.<anonymous>' call
      var list = element.u1w_1;
      addAll(destination, list);
    }
    var tmp = destination;
    var tmp0_safe_receiver = lastOrNull(curvesList);
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.v1w_1;
    return toCurves(tmp, tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs);
  }
  function toCurvesList(_this__u8e3s4) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    return getCurvesList(_this__u8e3s4);
  }
  function applyTransform(_this__u8e3s4, m) {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    var tmp;
    if (m.t1j()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.transformPoints' call
      var progression = step(until(0, _this__u8e3s4.i20_1.l()), 2);
      var inductionVariable = progression.pa_1;
      var last = progression.qa_1;
      var step_0 = progression.ra_1;
      if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + step_0 | 0;
          // Inline function 'korlibs.math.geom.vector.applyTransform.<anonymous>' call
          var it = new Vector2D(_this__u8e3s4.i20_1.b1(n + 0 | 0), _this__u8e3s4.i20_1.b1(n + 1 | 0));
          var tmp$ret$0;
          $l$block: {
            // Inline function 'korlibs.math.geom.Matrix.transform' call
            if (m.s1j()) {
              tmp$ret$0 = it;
              break $l$block;
            }
            tmp$ret$0 = new Vector2D(m.t1l(it.m1e_1, it.n1e_1), m.w1l(it.m1e_1, it.n1e_1));
          }
          var p = tmp$ret$0;
          _this__u8e3s4.i20_1.c11(n + 0 | 0, p.m1e_1);
          _this__u8e3s4.i20_1.c11(n + 1 | 0, p.n1e_1);
        }
         while (!(n === last));
      _this__u8e3s4.n20_1 = _this__u8e3s4.n20_1 + 1 | 0;
      tmp = _this__u8e3s4;
    } else {
      tmp = _this__u8e3s4;
    }
    return tmp;
  }
  function getCurvesList$_anonymous_$flush_1e57i7(current, $out, currentClosed, $this_getCurvesList) {
    if (current._v.x())
      return Unit_instance;
    // Inline function 'kotlin.also' call
    var this_0 = new Curves(current._v, currentClosed._v);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>$flush.<anonymous>' call
    this_0.x1w_1 = $this_getCurvesList.m20_1;
    $out.p(this_0);
    currentClosed._v = false;
    // Inline function 'kotlin.collections.arrayListOf' call
    current._v = ArrayList_init_$Create$();
  }
  function _bvhCurvesCacheVersion$delegate$lambda() {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    return -1;
  }
  function _bvhCurvesCache$delegate$lambda() {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    return null;
  }
  function _curvesCacheVersion$delegate$lambda() {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    return -1;
  }
  function _curvesCache$delegate$lambda() {
    _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe();
    return null;
  }
  function with$1($bb) {
    this.f2a_1 = $bb;
  }
  protoOf(with$1).f20 = function (p) {
    this.f2a_1._v = new BoundsBuilder(BoundsBuilder__plus_impl_cz7f7x(this.f2a_1._v.n1k_1, p));
  };
  function _curvesCacheVersion$factory() {
    return getPropertyCallableRef('_curvesCacheVersion', 1, KMutableProperty1, function (receiver) {
      return get__curvesCacheVersion(receiver);
    }, function (receiver, value) {
      return set__curvesCacheVersion(receiver, value);
    });
  }
  function _curvesCacheVersion$factory_0() {
    return getPropertyCallableRef('_curvesCacheVersion', 1, KMutableProperty1, function (receiver) {
      return get__curvesCacheVersion(receiver);
    }, function (receiver, value) {
      return set__curvesCacheVersion(receiver, value);
    });
  }
  function _curvesCache$factory() {
    return getPropertyCallableRef('_curvesCache', 1, KMutableProperty1, function (receiver) {
      return get__curvesCache(receiver);
    }, function (receiver, value) {
      return set__curvesCache(receiver, value);
    });
  }
  function _curvesCache$factory_0() {
    return getPropertyCallableRef('_curvesCache', 1, KMutableProperty1, function (receiver) {
      return get__curvesCache(receiver);
    }, function (receiver, value) {
      return set__curvesCache(receiver, value);
    });
  }
  var properties_initialized__MathGeom_vector_VectorPath_kt_i91mdk;
  function _init_properties__MathGeom_vector_VectorPath_kt__cc0pbe() {
    if (!properties_initialized__MathGeom_vector_VectorPath_kt_i91mdk) {
      properties_initialized__MathGeom_vector_VectorPath_kt_i91mdk = true;
      // Inline function 'korlibs.datastructure.extraProperty' call
      var default_0 = _bvhCurvesCacheVersion$delegate$lambda;
      _bvhCurvesCacheVersion$delegate = new Property(null, default_0);
      // Inline function 'korlibs.datastructure.extraProperty' call
      var default_1 = _bvhCurvesCache$delegate$lambda;
      _bvhCurvesCache$delegate = new Property(null, default_1);
      // Inline function 'korlibs.datastructure.extraProperty' call
      var default_2 = _curvesCacheVersion$delegate$lambda;
      _curvesCacheVersion$delegate = new Property(null, default_2);
      // Inline function 'korlibs.datastructure.extraProperty' call
      var default_3 = _curvesCache$delegate$lambda;
      _curvesCache$delegate = new Property(null, default_3);
    }
  }
  var Winding_EVEN_ODD_instance;
  var Winding_NON_ZERO_instance;
  function Companion_32() {
  }
  protoOf(Companion_32).x29 = function () {
    return Winding_NON_ZERO_getInstance();
  };
  var Companion_instance_33;
  function Companion_getInstance_32() {
    return Companion_instance_33;
  }
  var Winding_entriesInitialized;
  function Winding_initEntries() {
    if (Winding_entriesInitialized)
      return Unit_instance;
    Winding_entriesInitialized = true;
    Winding_EVEN_ODD_instance = new Winding('EVEN_ODD', 0, 'evenOdd');
    Winding_NON_ZERO_instance = new Winding('NON_ZERO', 1, 'nonZero');
  }
  function Winding(name, ordinal, str) {
    Enum.call(this, name, ordinal);
    this.i2a_1 = str;
  }
  var LineJoin_BEVEL_instance;
  var LineJoin_ROUND_instance;
  var LineJoin_MITER_instance;
  function Companion_33() {
  }
  var Companion_instance_34;
  function Companion_getInstance_33() {
    return Companion_instance_34;
  }
  var LineJoin_entriesInitialized;
  function LineJoin_initEntries() {
    if (LineJoin_entriesInitialized)
      return Unit_instance;
    LineJoin_entriesInitialized = true;
    LineJoin_BEVEL_instance = new LineJoin('BEVEL', 0);
    LineJoin_ROUND_instance = new LineJoin('ROUND', 1);
    LineJoin_MITER_instance = new LineJoin('MITER', 2);
  }
  function LineJoin(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  var LineCap_BUTT_instance;
  var LineCap_SQUARE_instance;
  var LineCap_ROUND_instance;
  function Companion_34() {
  }
  var Companion_instance_35;
  function Companion_getInstance_34() {
    return Companion_instance_35;
  }
  var LineCap_entriesInitialized;
  function LineCap_initEntries() {
    if (LineCap_entriesInitialized)
      return Unit_instance;
    LineCap_entriesInitialized = true;
    LineCap_BUTT_instance = new LineCap('BUTT', 0);
    LineCap_SQUARE_instance = new LineCap('SQUARE', 1);
    LineCap_ROUND_instance = new LineCap('ROUND', 2);
  }
  function LineCap(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  var LineScaleMode_NONE_instance;
  var LineScaleMode_HORIZONTAL_instance;
  var LineScaleMode_VERTICAL_instance;
  var LineScaleMode_NORMAL_instance;
  var LineScaleMode_entriesInitialized;
  function LineScaleMode_initEntries() {
    if (LineScaleMode_entriesInitialized)
      return Unit_instance;
    LineScaleMode_entriesInitialized = true;
    LineScaleMode_NONE_instance = new LineScaleMode('NONE', 0, false, false);
    LineScaleMode_HORIZONTAL_instance = new LineScaleMode('HORIZONTAL', 1, true, false);
    LineScaleMode_VERTICAL_instance = new LineScaleMode('VERTICAL', 2, false, true);
    LineScaleMode_NORMAL_instance = new LineScaleMode('NORMAL', 3, true, true);
  }
  function LineScaleMode(name, ordinal, hScale, vScale) {
    Enum.call(this, name, ordinal);
    this.l2a_1 = hScale;
    this.m2a_1 = vScale;
    this.n2a_1 = this.l2a_1 ? true : this.m2a_1;
    this.o2a_1 = this.l2a_1 ? this.m2a_1 : false;
  }
  function Companion_35() {
  }
  var Companion_instance_36;
  function Companion_getInstance_35() {
    return Companion_instance_36;
  }
  function StrokeInfo(thickness, pixelHinting, scaleMode, startCap, endCap, join, miterLimit, dash, dashOffset) {
    thickness = thickness === VOID ? 1.0 : thickness;
    pixelHinting = pixelHinting === VOID ? false : pixelHinting;
    scaleMode = scaleMode === VOID ? LineScaleMode_NORMAL_getInstance() : scaleMode;
    startCap = startCap === VOID ? LineCap_BUTT_getInstance() : startCap;
    endCap = endCap === VOID ? LineCap_BUTT_getInstance() : endCap;
    join = join === VOID ? LineJoin_MITER_getInstance() : join;
    miterLimit = miterLimit === VOID ? 20.0 : miterLimit;
    dash = dash === VOID ? null : dash;
    dashOffset = dashOffset === VOID ? 0.0 : dashOffset;
    this.l1v_1 = thickness;
    this.m1v_1 = pixelHinting;
    this.n1v_1 = scaleMode;
    this.o1v_1 = startCap;
    this.p1v_1 = endCap;
    this.q1v_1 = join;
    this.r1v_1 = miterLimit;
    this.s1v_1 = dash;
    this.t1v_1 = dashOffset;
  }
  protoOf(StrokeInfo).toString = function () {
    return 'StrokeInfo(thickness=' + this.l1v_1 + ', pixelHinting=' + this.m1v_1 + ', scaleMode=' + this.n1v_1 + ', startCap=' + this.o1v_1 + ', endCap=' + this.p1v_1 + ', join=' + this.q1v_1 + ', miterLimit=' + this.r1v_1 + ', dash=' + this.s1v_1 + ', dashOffset=' + this.t1v_1 + ')';
  };
  protoOf(StrokeInfo).hashCode = function () {
    var result = getNumberHashCode(this.l1v_1);
    result = imul(result, 31) + getBooleanHashCode(this.m1v_1) | 0;
    result = imul(result, 31) + this.n1v_1.hashCode() | 0;
    result = imul(result, 31) + this.o1v_1.hashCode() | 0;
    result = imul(result, 31) + this.p1v_1.hashCode() | 0;
    result = imul(result, 31) + this.q1v_1.hashCode() | 0;
    result = imul(result, 31) + getNumberHashCode(this.r1v_1) | 0;
    result = imul(result, 31) + (this.s1v_1 == null ? 0 : hashCode(this.s1v_1)) | 0;
    result = imul(result, 31) + getNumberHashCode(this.t1v_1) | 0;
    return result;
  };
  protoOf(StrokeInfo).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof StrokeInfo))
      return false;
    var tmp0_other_with_cast = other instanceof StrokeInfo ? other : THROW_CCE();
    if (!equals(this.l1v_1, tmp0_other_with_cast.l1v_1))
      return false;
    if (!(this.m1v_1 === tmp0_other_with_cast.m1v_1))
      return false;
    if (!this.n1v_1.equals(tmp0_other_with_cast.n1v_1))
      return false;
    if (!this.o1v_1.equals(tmp0_other_with_cast.o1v_1))
      return false;
    if (!this.p1v_1.equals(tmp0_other_with_cast.p1v_1))
      return false;
    if (!this.q1v_1.equals(tmp0_other_with_cast.q1v_1))
      return false;
    if (!equals(this.r1v_1, tmp0_other_with_cast.r1v_1))
      return false;
    if (!equals(this.s1v_1, tmp0_other_with_cast.s1v_1))
      return false;
    if (!equals(this.t1v_1, tmp0_other_with_cast.t1v_1))
      return false;
    return true;
  };
  function Winding_EVEN_ODD_getInstance() {
    Winding_initEntries();
    return Winding_EVEN_ODD_instance;
  }
  function Winding_NON_ZERO_getInstance() {
    Winding_initEntries();
    return Winding_NON_ZERO_instance;
  }
  function LineJoin_BEVEL_getInstance() {
    LineJoin_initEntries();
    return LineJoin_BEVEL_instance;
  }
  function LineJoin_MITER_getInstance() {
    LineJoin_initEntries();
    return LineJoin_MITER_instance;
  }
  function LineCap_BUTT_getInstance() {
    LineCap_initEntries();
    return LineCap_BUTT_instance;
  }
  function LineCap_SQUARE_getInstance() {
    LineCap_initEntries();
    return LineCap_SQUARE_instance;
  }
  function LineScaleMode_NONE_getInstance() {
    LineScaleMode_initEntries();
    return LineScaleMode_NONE_instance;
  }
  function LineScaleMode_NORMAL_getInstance() {
    LineScaleMode_initEntries();
    return LineScaleMode_NORMAL_instance;
  }
  function MPoint$Companion$POOL$lambda(it) {
    it.r2a(0.0, 0.0);
    return Unit_instance;
  }
  function MPoint$Companion$POOL$lambda_0(it) {
    return Companion_getInstance_36().e1l();
  }
  function Companion_36() {
    Companion_instance_37 = this;
    var tmp = this;
    var tmp_0 = MPoint$Companion$POOL$lambda;
    tmp.s28_1 = new ConcurrentPool(tmp_0, VOID, MPoint$Companion$POOL$lambda_0);
    this.t28_1 = new MPoint(0.0, 0.0);
    this.u28_1 = new MPoint(1.0, 1.0);
    this.v28_1 = new MPoint(0.0, -1.0);
    this.w28_1 = new MPoint(0.0, 1.0);
    this.x28_1 = new MPoint(-1.0, 0.0);
    this.y28_1 = new MPoint(1.0, 0.0);
  }
  protoOf(Companion_36).e1l = function () {
    return new MPoint(0.0, 0.0);
  };
  protoOf(Companion_36).s2a = function (lx, ly, rx, ry) {
    var ret = compareTo(ly, ry);
    return ret === 0 ? compareTo(lx, rx) : ret;
  };
  protoOf(Companion_36).y1q = function (x1, y1, x2, y2) {
    // Inline function 'kotlin.math.hypot' call
    var x = x1 - x2;
    var y = y1 - y2;
    return hypot(x, y);
  };
  protoOf(Companion_36).q1s = function (x1, y1, x2, y2) {
    return this.y1q(x1, y1, x2, y2);
  };
  protoOf(Companion_36).t2a = function (aX, aY, bX, bY) {
    return aX * bX + aY * bY;
  };
  var Companion_instance_37;
  function Companion_getInstance_36() {
    if (Companion_instance_37 == null)
      new Companion_36();
    return Companion_instance_37;
  }
  function MPoint(x, y) {
    Companion_getInstance_36();
    this.p2a_1 = x;
    this.q2a_1 = y;
  }
  protoOf(MPoint).u2a = function (other, epsilon) {
    return isAlmostEquals(this.p2a_1, other.p2a_1, epsilon) ? isAlmostEquals(this.q2a_1, other.q2a_1, epsilon) : false;
  };
  protoOf(MPoint).w1h = function (other, epsilon) {
    return this.u2a(other instanceof MPoint ? other : THROW_CCE(), epsilon);
  };
  protoOf(MPoint).r2a = function (x, y) {
    this.p2a_1 = x;
    this.q2a_1 = y;
    return this;
  };
  protoOf(MPoint).v2a = function (other) {
    return Companion_getInstance_36().s2a(this.p2a_1, this.q2a_1, other.p2a_1, other.q2a_1);
  };
  protoOf(MPoint).d = function (other) {
    return this.v2a(other instanceof MPoint ? other : THROW_CCE());
  };
  protoOf(MPoint).toString = function () {
    return '(' + get_niceStr_0(this.p2a_1) + ', ' + get_niceStr_0(this.q2a_1) + ')';
  };
  protoOf(MPoint).hashCode = function () {
    var result = getNumberHashCode(this.p2a_1);
    result = imul(result, 31) + getNumberHashCode(this.q2a_1) | 0;
    return result;
  };
  protoOf(MPoint).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MPoint))
      return false;
    var tmp0_other_with_cast = other instanceof MPoint ? other : THROW_CCE();
    if (!equals(this.p2a_1, tmp0_other_with_cast.p2a_1))
      return false;
    if (!equals(this.q2a_1, tmp0_other_with_cast.q2a_1))
      return false;
    return true;
  };
  function MMatrix$Companion$POOL$lambda(it) {
    it.c2b();
    return Unit_instance;
  }
  function MMatrix$Companion$POOL$lambda_0(it) {
    return new MMatrix();
  }
  function Companion_37() {
    Companion_instance_38 = this;
    var tmp = this;
    var tmp_0 = MMatrix$Companion$POOL$lambda;
    tmp.d2b_1 = new ConcurrentPool(tmp_0, VOID, MMatrix$Companion$POOL$lambda_0);
  }
  var Companion_instance_38;
  function Companion_getInstance_37() {
    if (Companion_instance_38 == null)
      new Companion_37();
    return Companion_instance_38;
  }
  function MMatrix(a, b, c, d, tx, ty) {
    Companion_getInstance_37();
    a = a === VOID ? 1.0 : a;
    b = b === VOID ? 0.0 : b;
    c = c === VOID ? 0.0 : c;
    d = d === VOID ? 1.0 : d;
    tx = tx === VOID ? 0.0 : tx;
    ty = ty === VOID ? 0.0 : ty;
    this.w2a_1 = a;
    this.x2a_1 = b;
    this.y2a_1 = c;
    this.z2a_1 = d;
    this.a2b_1 = tx;
    this.b2b_1 = ty;
  }
  protoOf(MMatrix).e2b = function () {
    return new Matrix(this.w2a_1, this.x2a_1, this.y2a_1, this.z2a_1, this.a2b_1, this.b2b_1);
  };
  protoOf(MMatrix).f2b = function (a, b, c, d, tx, ty) {
    this.w2a_1 = a;
    this.x2a_1 = b;
    this.y2a_1 = c;
    this.z2a_1 = d;
    this.a2b_1 = tx;
    this.b2b_1 = ty;
    return this;
  };
  protoOf(MMatrix).g2b = function (that) {
    return this.f2b(that.g1l_1, that.h1l_1, that.i1l_1, that.j1l_1, that.k1l_1, that.l1l_1);
  };
  protoOf(MMatrix).h2b = function (dx, dy) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.MMatrix.pretranslate.<anonymous>' call
    this.a2b_1 = this.a2b_1 + (this.w2a_1 * dx + this.y2a_1 * dy);
    this.b2b_1 = this.b2b_1 + (this.x2a_1 * dx + this.z2a_1 * dy);
    return this;
  };
  protoOf(MMatrix).c2b = function () {
    return this.f2b(1.0, 0.0, 0.0, 1.0, 0.0, 0.0);
  };
  protoOf(MMatrix).toString = function () {
    return 'Matrix(a=' + this.w2a_1 + ', b=' + this.x2a_1 + ', c=' + this.y2a_1 + ', d=' + this.z2a_1 + ', tx=' + this.a2b_1 + ', ty=' + this.b2b_1 + ')';
  };
  protoOf(MMatrix).hashCode = function () {
    var result = getNumberHashCode(this.w2a_1);
    result = imul(result, 31) + getNumberHashCode(this.x2a_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.y2a_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.z2a_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.a2b_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.b2b_1) | 0;
    return result;
  };
  protoOf(MMatrix).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MMatrix))
      return false;
    var tmp0_other_with_cast = other instanceof MMatrix ? other : THROW_CCE();
    if (!equals(this.w2a_1, tmp0_other_with_cast.w2a_1))
      return false;
    if (!equals(this.x2a_1, tmp0_other_with_cast.x2a_1))
      return false;
    if (!equals(this.y2a_1, tmp0_other_with_cast.y2a_1))
      return false;
    if (!equals(this.z2a_1, tmp0_other_with_cast.z2a_1))
      return false;
    if (!equals(this.a2b_1, tmp0_other_with_cast.a2b_1))
      return false;
    if (!equals(this.b2b_1, tmp0_other_with_cast.b2b_1))
      return false;
    return true;
  };
  function MLine_init_$Init$($this) {
    MLine.call($this, Vector2D_init_$Create$_1(), Vector2D_init_$Create$_1());
    return $this;
  }
  function MLine_init_$Create$() {
    return MLine_init_$Init$(objectCreate(protoOf(MLine)));
  }
  function Companion_38() {
  }
  protoOf(Companion_38).i2b = function (point, direction, scale, out) {
    return out.j2b(point.m1e_1, point.n1e_1, point.m1e_1 + direction.m1e_1 * scale, point.n1e_1 + direction.n1e_1 * scale);
  };
  protoOf(Companion_38).l1w = function (point, direction, scale, out, $super) {
    scale = scale === VOID ? 1.0 : scale;
    out = out === VOID ? MLine_init_$Create$() : out;
    return $super === VOID ? this.i2b(point, direction, scale, out) : $super.i2b.call(this, point, direction, scale, out);
  };
  protoOf(Companion_38).o27 = function (Ax, Ay, Bx, By, Cx, Cy, Dx, Dy) {
    var a1 = By - Ay;
    var b1 = Ax - Bx;
    var c1 = a1 * Ax + b1 * Ay;
    var a2 = Dy - Cy;
    var b2 = Cx - Dx;
    var c2 = a2 * Cx + b2 * Cy;
    var determinant = a1 * b2 - a2 * b1;
    if (isAlmostZero(determinant))
      return null;
    var x = (b2 * c1 - b1 * c2) / determinant;
    var y = (a1 * c2 - a2 * c1) / determinant;
    return new Vector2D(x, y);
  };
  var Companion_instance_39;
  function Companion_getInstance_38() {
    return Companion_instance_39;
  }
  function MLine(a, b) {
    this.m1w_1 = a;
    this.n1w_1 = b;
  }
  protoOf(MLine).i11 = function () {
    return new MLine(this.m1w_1, this.n1w_1);
  };
  protoOf(MLine).j2b = function (x0, y0, x1, y1) {
    this.m1w_1 = new Vector2D(x0, y0);
    this.n1w_1 = new Vector2D(x1, y1);
    return this;
  };
  protoOf(MLine).o1w = function (scale) {
    var tmp = this;
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var this_0 = this.m1w_1;
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var this_1 = this.k2b();
    var that = new Vector2D(this_1.m1e_1 * scale, this_1.n1e_1 * scale);
    tmp.m1w_1 = new Vector2D(this_0.m1e_1 - that.m1e_1, this_0.n1e_1 - that.n1e_1);
    var tmp_0 = this;
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var this_2 = this.n1w_1;
    // Inline function 'korlibs.math.geom.Vector2D.times' call
    var this_3 = this.k2b();
    var that_0 = new Vector2D(this_3.m1e_1 * scale, this_3.n1e_1 * scale);
    tmp_0.n1w_1 = new Vector2D(this_2.m1e_1 - that_0.m1e_1, this_2.n1e_1 - that_0.n1e_1);
    return this;
  };
  protoOf(MLine).l2b = function () {
    return this.m1w_1.m1e_1;
  };
  protoOf(MLine).m2b = function () {
    return this.m1w_1.n1e_1;
  };
  protoOf(MLine).n2b = function () {
    return this.n1w_1.m1e_1;
  };
  protoOf(MLine).o2b = function () {
    return this.n1w_1.n1e_1;
  };
  protoOf(MLine).k2b = function () {
    // Inline function 'korlibs.math.geom.Vector2D.minus' call
    var this_0 = this.n1w_1;
    var that = this.m1w_1;
    return new Vector2D(this_0.m1e_1 - that.m1e_1, this_0.n1e_1 - that.n1e_1);
  };
  protoOf(MLine).toString = function () {
    return 'Line(' + this.m1w_1 + ', ' + this.n1w_1 + ')';
  };
  protoOf(MLine).p2b = function (line) {
    return Companion_instance_39.o27(this.l2b(), this.m2b(), this.n2b(), this.o2b(), line.l2b(), line.m2b(), line.n2b(), line.o2b());
  };
  protoOf(MLine).hashCode = function () {
    var result = this.m1w_1.hashCode();
    result = imul(result, 31) + this.n1w_1.hashCode() | 0;
    return result;
  };
  protoOf(MLine).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MLine))
      return false;
    var tmp0_other_with_cast = other instanceof MLine ? other : THROW_CCE();
    if (!this.m1w_1.equals(tmp0_other_with_cast.m1w_1))
      return false;
    if (!this.n1w_1.equals(tmp0_other_with_cast.n1w_1))
      return false;
    return true;
  };
  function MRectangle$Companion$POOL$lambda(it) {
    it.a1i();
    return Unit_instance;
  }
  function MRectangle$Companion$POOL$lambda_0(it) {
    return Companion_getInstance_39().e1l();
  }
  function Companion_39() {
    Companion_instance_40 = this;
    var tmp = this;
    var tmp_0 = MRectangle$Companion$POOL$lambda;
    tmp.u2b_1 = new ConcurrentPool(tmp_0, VOID, MRectangle$Companion$POOL$lambda_0);
  }
  protoOf(Companion_39).e1l = function () {
    return new MRectangle(0.0, 0.0, 0.0, 0.0);
  };
  var Companion_instance_40;
  function Companion_getInstance_39() {
    if (Companion_instance_40 == null)
      new Companion_39();
    return Companion_instance_40;
  }
  function MRectangle(x, y, width, height) {
    Companion_getInstance_39();
    this.q2b_1 = x;
    this.r2b_1 = y;
    this.s2b_1 = width;
    this.t2b_1 = height;
  }
  protoOf(MRectangle).v2b = function (other, epsilon) {
    return ((isAlmostEquals(this.q2b_1, other.q2b_1, epsilon) ? isAlmostEquals(this.r2b_1, other.r2b_1, epsilon) : false) ? isAlmostEquals(this.s2b_1, other.s2b_1, epsilon) : false) ? isAlmostEquals(this.t2b_1, other.t2b_1, epsilon) : false;
  };
  protoOf(MRectangle).w1h = function (other, epsilon) {
    return this.v2b(other instanceof MRectangle ? other : THROW_CCE(), epsilon);
  };
  protoOf(MRectangle).u1j = function () {
    return this.q2b_1;
  };
  protoOf(MRectangle).w1j = function () {
    return this.r2b_1;
  };
  protoOf(MRectangle).v1j = function () {
    return this.q2b_1 + this.s2b_1;
  };
  protoOf(MRectangle).x1j = function () {
    return this.r2b_1 + this.t2b_1;
  };
  protoOf(MRectangle).j2b = function (x, y, width, height) {
    this.q2b_1 = x;
    this.r2b_1 = y;
    this.s2b_1 = width;
    this.t2b_1 = height;
    return this;
  };
  protoOf(MRectangle).w2b = function (left, top, right, bottom) {
    return this.j2b(left, top, right - left, bottom - top);
  };
  protoOf(MRectangle).x2b = function (left, top, right, bottom) {
    return this.w2b(this.u1j() - left, this.w1j() - top, this.v1j() + right, this.x1j() + bottom);
  };
  protoOf(MRectangle).y2b = function (left, top, right, bottom, $super) {
    top = top === VOID ? left : top;
    right = right === VOID ? left : right;
    bottom = bottom === VOID ? top : bottom;
    return $super === VOID ? this.x2b(left, top, right, bottom) : $super.x2b.call(this, left, top, right, bottom);
  };
  protoOf(MRectangle).a1i = function () {
    return this.j2b(0.0, 0.0, 0.0, 0.0);
  };
  protoOf(MRectangle).toString = function () {
    return 'Rectangle(x=' + get_niceStr_0(this.q2b_1) + ', y=' + get_niceStr_0(this.r2b_1) + ', width=' + get_niceStr_0(this.s2b_1) + ', height=' + get_niceStr_0(this.t2b_1) + ')';
  };
  protoOf(MRectangle).equals = function (other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (other instanceof MRectangle) {
      tmp_2 = isAlmostEquals(this.q2b_1, other.q2b_1);
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      tmp_1 = isAlmostEquals(this.r2b_1, other.r2b_1);
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = isAlmostEquals(this.s2b_1, other.s2b_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = isAlmostEquals(this.t2b_1, other.t2b_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(MRectangle).e2b = function () {
    return new RectangleD(this.q2b_1, this.r2b_1, this.s2b_1, this.t2b_1);
  };
  protoOf(MRectangle).hashCode = function () {
    var result = getNumberHashCode(this.q2b_1);
    result = imul(result, 31) + getNumberHashCode(this.r2b_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.s2b_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.t2b_1) | 0;
    return result;
  };
  function get_immutable(_this__u8e3s4) {
    return _this__u8e3s4;
  }
  function get_mutable(_this__u8e3s4) {
    return new MRectangle(_this__u8e3s4.o1j_1, _this__u8e3s4.p1j_1, _this__u8e3s4.q1j_1, _this__u8e3s4.r1j_1);
  }
  function toMatrix4(_this__u8e3s4) {
    if (_this__u8e3s4.s1j())
      return Companion_getInstance_8().f1o_1;
    return Companion_getInstance_8().b1p(_this__u8e3s4.g1l_1, _this__u8e3s4.i1l_1, 0.0, _this__u8e3s4.k1l_1, _this__u8e3s4.h1l_1, _this__u8e3s4.j1l_1, 0.0, _this__u8e3s4.l1l_1, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0);
  }
  function Companion_40() {
    Companion_instance_41 = this;
    this.h1o_1 = 0;
    this.i1o_1 = 1;
    this.j1o_1 = 2;
    this.k1o_1 = 3;
    this.l1o_1 = 4;
    this.m1o_1 = 5;
    this.n1o_1 = 6;
    this.o1o_1 = 7;
    this.p1o_1 = 8;
    this.q1o_1 = 9;
    this.r1o_1 = 10;
    this.s1o_1 = 11;
    this.t1o_1 = 12;
    this.u1o_1 = 13;
    this.v1o_1 = 14;
    this.w1o_1 = 15;
    var tmp = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp.x1o_1 = new Int32Array([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]);
    var tmp_0 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_0.y1o_1 = new Int32Array([0, 4, 8, 12, 1, 5, 9, 13, 2, 6, 10, 14, 3, 7, 11, 15]);
    var tmp_1 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_1.z1o_1 = new Int32Array([0, 1, 2, 4, 5, 6, 8, 9, 10]);
    var tmp_2 = this;
    // Inline function 'kotlin.intArrayOf' call
    tmp_2.a1p_1 = new Int32Array([0, 4, 8, 1, 5, 9, 2, 6, 10]);
  }
  var Companion_instance_41;
  function Companion_getInstance_40() {
    if (Companion_instance_41 == null)
      new Companion_40();
    return Companion_instance_41;
  }
  function lineIntersectionPoint(_this__u8e3s4, l1, l2) {
    return l1.p2b(l2);
  }
  function projectedPoint(_this__u8e3s4, point) {
    return projectedPoint_0(Companion_instance_39, _this__u8e3s4.m1w_1, _this__u8e3s4.n1w_1, point);
  }
  function projectedPoint_0(_this__u8e3s4, v1, v2, point) {
    return projectedPoint_1(_this__u8e3s4, v1.m1e_1, v1.n1e_1, v2.m1e_1, v2.n1e_1, point.m1e_1, point.n1e_1);
  }
  function projectedPoint_1(_this__u8e3s4, v1x, v1y, v2x, v2y, px, py) {
    var e1x = v2x - v1x;
    var e1y = v2y - v1y;
    var e2x = px - v1x;
    var e2y = py - v1y;
    var valDp = Companion_getInstance_36().t2a(e1x, e1y, e2x, e2y);
    // Inline function 'kotlin.math.hypot' call
    var lenLineE1 = hypot(e1x, e1y);
    // Inline function 'kotlin.math.hypot' call
    var lenLineE2 = hypot(e2x, e2y);
    if (lenLineE1 === 0.0 ? true : lenLineE2 === 0.0) {
      return new Vector2D(px, py);
    }
    var cos = valDp / (lenLineE1 * lenLineE2);
    var projLenOfLine = cos * lenLineE2;
    return new Vector2D(v1x + projLenOfLine * e1x / lenLineE1, v1y + projLenOfLine * e1y / lenLineE1);
  }
  function Companion_41() {
  }
  var Companion_instance_42;
  function Companion_getInstance_41() {
    return Companion_instance_42;
  }
  function Circle$lazyVectorPath$delegate$lambda(this$0) {
    return function () {
      // Inline function 'korlibs.math.geom.shape.buildVectorPath' call
      // Inline function 'kotlin.apply' call
      var this_0 = new VectorPath();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.Circle.lazyVectorPath$delegate.<anonymous>.<anonymous>' call
      this_0.o29(this$0.z2b_1, this$0.a2c_1);
      return this_0;
    };
  }
  function Circle(center, radius) {
    AbstractShape2D.call(this);
    this.z2b_1 = center;
    this.a2c_1 = radius;
    var tmp = this;
    tmp.b2c_1 = lazy_0(Circle$lazyVectorPath$delegate$lambda(this));
  }
  protoOf(Circle).c2c = function () {
    return this.a2c_1 * this.a2c_1;
  };
  protoOf(Circle).d2c = function (p) {
    return Companion_getInstance_15().s1s(p, this.z2b_1);
  };
  protoOf(Circle).e2c = function (p) {
    return this.d2c(p) - this.c2c();
  };
  protoOf(Circle).f2c = function (p) {
    return this.d2c(p) + this.c2c();
  };
  protoOf(Circle).toString = function () {
    return 'Circle(center=' + this.z2b_1 + ', radius=' + this.a2c_1 + ')';
  };
  protoOf(Circle).hashCode = function () {
    var result = this.z2b_1.hashCode();
    result = imul(result, 31) + getNumberHashCode(this.a2c_1) | 0;
    return result;
  };
  protoOf(Circle).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Circle))
      return false;
    var tmp0_other_with_cast = other instanceof Circle ? other : THROW_CCE();
    if (!this.z2b_1.equals(tmp0_other_with_cast.z2b_1))
      return false;
    if (!equals(this.a2c_1, tmp0_other_with_cast.a2c_1))
      return false;
    return true;
  };
  function get_niceStr_0(_this__u8e3s4) {
    return niceStr(_this__u8e3s4, -1, false);
  }
  function niceStr(_this__u8e3s4, decimalPlaces, zeroSuffix) {
    zeroSuffix = zeroSuffix === VOID ? false : zeroSuffix;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.number.niceStr.<anonymous>' call
    appendNice(this_0, roundDecimalPlaces(_this__u8e3s4, decimalPlaces), zeroSuffix ? decimalPlaces > 0 : false);
    return this_0.toString();
  }
  function appendNice(_this__u8e3s4, value, zeroSuffix) {
    zeroSuffix = zeroSuffix === VOID ? false : zeroSuffix;
    if (isAlmostEquals(round(value), value))
      if (value >= IntCompanionObject_instance.MIN_VALUE ? value <= IntCompanionObject_instance.MAX_VALUE : false) {
        _this__u8e3s4.t7(numberToInt(round(value)));
      } else {
        _this__u8e3s4.u7(numberToLong(round(value)));
      }
     else {
      _this__u8e3s4.w7(value);
      return Unit_instance;
    }
    if (zeroSuffix) {
      _this__u8e3s4.i5('.0');
    }
  }
  function get_niceStr_1(_this__u8e3s4) {
    return niceStr_0(_this__u8e3s4, -1, false);
  }
  function niceStr_0(_this__u8e3s4, decimalPlaces, zeroSuffix) {
    zeroSuffix = zeroSuffix === VOID ? false : zeroSuffix;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.number.niceStr.<anonymous>' call
    appendNice_0(this_0, roundDecimalPlaces_0(_this__u8e3s4, decimalPlaces), zeroSuffix ? decimalPlaces > 0 : false);
    return this_0.toString();
  }
  function appendNice_0(_this__u8e3s4, value, zeroSuffix) {
    zeroSuffix = zeroSuffix === VOID ? false : zeroSuffix;
    // Inline function 'kotlin.math.round' call
    var tmp$ret$0 = round(value);
    if (isAlmostEquals_0(tmp$ret$0, value))
      if (value >= IntCompanionObject_instance.MIN_VALUE ? value <= IntCompanionObject_instance.MAX_VALUE : false) {
        _this__u8e3s4.t7(numberToInt(value));
      } else {
        _this__u8e3s4.u7(numberToLong(value));
      }
     else {
      _this__u8e3s4.v7(value);
      return Unit_instance;
    }
    if (zeroSuffix) {
      _this__u8e3s4.i5('.0');
    }
  }
  function JSStackTrace$Companion$parse$lambda($numParts) {
    return function (it) {
      var tmp0_elvis_lhs = toIntOrNull(it.h9().b1(2));
      $numParts.k1(0, tmp0_elvis_lhs == null ? -1 : tmp0_elvis_lhs);
      return it.h9().b1(1);
    };
  }
  function Entry(method, file, line, column) {
    column = column === VOID ? -1 : column;
    this.g2c_1 = method;
    this.h2c_1 = file;
    this.i2c_1 = line;
    this.j2c_1 = column;
  }
  protoOf(Entry).toString = function () {
    return 'Entry(method=' + this.g2c_1 + ', file=' + this.h2c_1 + ', line=' + this.i2c_1 + ', column=' + this.j2c_1 + ')';
  };
  protoOf(Entry).hashCode = function () {
    var result = getStringHashCode(this.g2c_1);
    result = imul(result, 31) + getStringHashCode(this.h2c_1) | 0;
    result = imul(result, 31) + this.i2c_1 | 0;
    result = imul(result, 31) + this.j2c_1 | 0;
    return result;
  };
  protoOf(Entry).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Entry))
      return false;
    var tmp0_other_with_cast = other instanceof Entry ? other : THROW_CCE();
    if (!(this.g2c_1 === tmp0_other_with_cast.g2c_1))
      return false;
    if (!(this.h2c_1 === tmp0_other_with_cast.h2c_1))
      return false;
    if (!(this.i2c_1 === tmp0_other_with_cast.i2c_1))
      return false;
    if (!(this.j2c_1 === tmp0_other_with_cast.j2c_1))
      return false;
    return true;
  };
  function Companion_42() {
  }
  protoOf(Companion_42).k2c = function (stack, message) {
    // Inline function 'kotlin.collections.arrayListOf' call
    var entries = ArrayList_init_$Create$();
    // Inline function 'kotlin.collections.arrayListOf' call
    var messageLines = ArrayList_init_$Create$();
    var isChrome = false;
    var iterator = lines(stack).s();
    var index = 0;
    $l$loop: while (iterator.t()) {
      var index_0 = index;
      index = index + 1 | 0;
      var strLine = iterator.u();
      // Inline function 'kotlin.text.trimEnd' call
      var strLine_0 = toString(trimEnd(isCharSequence(strLine) ? strLine : THROW_CCE()));
      var tmp;
      // Inline function 'kotlin.text.isEmpty' call
      if (charSequenceLength(strLine_0) === 0) {
        tmp = !isChrome;
      } else {
        tmp = false;
      }
      if (tmp)
        continue $l$loop;
      if ((startsWith(strLine_0, 'Error: ') ? true : strLine_0 === 'Error') ? index_0 === 0 : false) {
        isChrome = true;
        var tmp_0 = messageLines;
        // Inline function 'kotlin.text.substring' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp$ret$5 = strLine_0.substring(7);
        tmp_0.p(tmp$ret$5);
      } else if (startsWith(strLine_0, '    at ')) {
        // Inline function 'kotlin.text.substring' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp$ret$7 = strLine_0.substring(7);
        var part = trimEnd_0(tmp$ret$7, charArrayOf([_Char___init__impl__6a9atx(41)]));
        var column = substringAfterLast(part, _Char___init__impl__6a9atx(58));
        var part0 = substringBeforeLast(part, _Char___init__impl__6a9atx(58));
        var line = substringAfterLast(part0, _Char___init__impl__6a9atx(58));
        var part1 = substringBeforeLast(part0, _Char___init__impl__6a9atx(58));
        var tmp_1;
        if (contains(part1, _Char___init__impl__6a9atx(40))) {
          // Inline function 'kotlin.collections.map' call
          var this_0 = split(part1, ['(']);
          // Inline function 'kotlin.collections.mapTo' call
          var destination = ArrayList_init_$Create$_0(collectionSizeOrDefault(this_0, 10));
          var tmp0_iterator = this_0.s();
          while (tmp0_iterator.t()) {
            var item = tmp0_iterator.u();
            // Inline function 'korlibs.js.Companion.parse.<anonymous>' call
            // Inline function 'kotlin.text.trim' call
            var tmp$ret$9 = toString(trim(isCharSequence(item) ? item : THROW_CCE()));
            destination.p(tmp$ret$9);
          }
          tmp_1 = destination;
        } else {
          // Inline function 'kotlin.text.trim' call
          var tmp$ret$12 = toString(trim(isCharSequence(part1) ? part1 : THROW_CCE()));
          tmp_1 = listOf_0(['', tmp$ret$12]);
        }
        var tmp2_container = tmp_1;
        // Inline function 'kotlin.collections.component1' call
        var method = tmp2_container.b1(0);
        // Inline function 'kotlin.collections.component2' call
        var file = tmp2_container.b1(1);
        // Inline function 'kotlin.collections.plusAssign' call
        var tmp3_elvis_lhs = toIntOrNull(line);
        var tmp_2 = tmp3_elvis_lhs == null ? -1 : tmp3_elvis_lhs;
        var tmp4_elvis_lhs = toIntOrNull(column);
        var element = new Entry(method, file, tmp_2, tmp4_elvis_lhs == null ? -1 : tmp4_elvis_lhs);
        entries.p(element);
      } else if (isChrome) {
        messageLines.p(strLine_0);
      } else {
        var cline = strLine_0;
        // Inline function 'kotlin.collections.arrayListOf' call
        var numParts = ArrayList_init_$Create$();
        var inductionVariable = 0;
        if (inductionVariable < 2)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var tmp_3 = Regex_init_$Create$('^(.*):(\\d+)$');
            var tmp_4 = cline;
            cline = tmp_3.l8(tmp_4, JSStackTrace$Companion$parse$lambda(numParts));
          }
           while (inductionVariable < 2);
        var file_0 = substringAfterLast(cline, _Char___init__impl__6a9atx(64));
        var method_0 = substringBeforeLast(cline, _Char___init__impl__6a9atx(64));
        var tmp6_elvis_lhs = firstOrNull(numParts);
        var line_0 = tmp6_elvis_lhs == null ? -1 : tmp6_elvis_lhs;
        var tmp_5;
        if (numParts.l() >= 2) {
          var tmp7_elvis_lhs = lastOrNull(numParts);
          tmp_5 = tmp7_elvis_lhs == null ? -1 : tmp7_elvis_lhs;
        } else {
          tmp_5 = -1;
        }
        var column_0 = tmp_5;
        // Inline function 'kotlin.collections.plusAssign' call
        var element_0 = new Entry(method_0, file_0, line_0, column_0);
        entries.p(element_0);
      }
    }
    if (!isChrome) {
      messageLines = arrayListOf([message == null ? '' : message]);
    }
    if (entries.x()) {
      entries.p(new Entry('<unknown>', '<unknown>', -1));
    }
    return new JSStackTrace(joinToString(messageLines, '\n'), entries);
  };
  protoOf(Companion_42).l2c = function (e) {
    return this.k2c(stackTraceToString(e), e.message);
  };
  protoOf(Companion_42).m2c = function () {
    return this.l2c(Exception_init_$Create$(''));
  };
  var Companion_instance_43;
  function Companion_getInstance_42() {
    return Companion_instance_43;
  }
  function JSStackTrace(message, entries) {
    this.n2c_1 = message;
    this.o2c_1 = entries;
  }
  protoOf(JSStackTrace).toString = function () {
    return 'JSStackTrace(message=' + this.n2c_1 + ', entries=' + this.o2c_1 + ')';
  };
  protoOf(JSStackTrace).hashCode = function () {
    var result = getStringHashCode(this.n2c_1);
    result = imul(result, 31) + hashCode(this.o2c_1) | 0;
    return result;
  };
  protoOf(JSStackTrace).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof JSStackTrace))
      return false;
    var tmp0_other_with_cast = other instanceof JSStackTrace ? other : THROW_CCE();
    if (!(this.n2c_1 === tmp0_other_with_cast.n2c_1))
      return false;
    if (!equals(this.o2c_1, tmp0_other_with_cast.o2c_1))
      return false;
    return true;
  };
  //region block: post-declaration
  protoOf(EasingCubic).s1e = invoke;
  protoOf(EasingCubic).t1e = invoke_0;
  protoOf(Easings).s1e = invoke;
  protoOf(Easings).t1e = invoke_0;
  protoOf(Angle).x1h = isAlmostEquals$default;
  protoOf(Matrix).x1h = isAlmostEquals$default;
  protoOf(MatrixTransform).x1h = isAlmostEquals$default;
  protoOf(RectangleD).x1h = isAlmostEquals$default;
  protoOf(Vector2D).x1h = isAlmostEquals$default;
  protoOf(PointArrayList).s1u = clone$default;
  protoOf(PointArrayList).i1u = get_dimensions;
  protoOf(PointArrayList).l1u = getComponentList;
  protoOf(PointArrayList).m1u = getComponentList$default;
  protoOf(PointArrayList).h13 = get_first;
  protoOf(PointArrayList).i13 = get_last;
  protoOf(PointArrayList).x = isEmpty;
  protoOf(PointArrayList).v11 = isNotEmpty;
  protoOf(PointArrayList).t1u = isAlmostEquals_1;
  protoOf(PointArrayList).x1h = isAlmostEquals$default;
  protoOf(DoubleVectorArrayList).t1u = isAlmostEquals_1;
  protoOf(DoubleVectorArrayList).x1h = isAlmostEquals$default;
  protoOf(ProjectedPoint).x1h = isAlmostEquals$default;
  protoOf(Bezier).x1h = isAlmostEquals$default;
  protoOf(RectSlice).u1j = get_left;
  protoOf(RectSlice).w1j = get_top;
  protoOf(RectSlice).v1j = get_right;
  protoOf(RectSlice).x1j = get_bottom;
  protoOf(RectSlice).u1r = get_area;
  protoOf(RectSlice).f24 = get_frameOffsetX;
  protoOf(RectSlice).g24 = get_frameOffsetY;
  protoOf(RectSlice).h24 = x;
  protoOf(RectSlice).i24 = y;
  protoOf(SliceCoordsImpl).f24 = get_frameOffsetX;
  protoOf(SliceCoordsImpl).g24 = get_frameOffsetY;
  protoOf(SliceCoordsImpl).h24 = x;
  protoOf(SliceCoordsImpl).i24 = y;
  protoOf(RectCoords).h24 = x;
  protoOf(RectCoords).i24 = y;
  protoOf(VectorBuilder$transformed$1).i29 = line;
  protoOf(VectorBuilder$transformed$1).j29 = rect;
  protoOf(VectorBuilder$transformed$1).k29 = rect_0;
  protoOf(VectorBuilder$transformed$1).l29 = rect_1;
  protoOf(VectorBuilder$transformed$1).m29 = arc;
  protoOf(VectorBuilder$transformed$1).n29 = arc$default;
  protoOf(VectorBuilder$transformed$1).o29 = circle;
  protoOf(VectorBuilder$transformed$1).p29 = rMoveTo;
  protoOf(VectorBuilder$transformed$1).q29 = rLineTo;
  protoOf(VectorBuilder$transformed$1).f1r = transformed;
  protoOf(VectorPath).i29 = line;
  protoOf(VectorPath).x = isEmpty_0;
  protoOf(VectorPath).v11 = isNotEmpty_0;
  protoOf(VectorPath).j29 = rect;
  protoOf(VectorPath).k29 = rect_0;
  protoOf(VectorPath).l29 = rect_1;
  protoOf(VectorPath).m29 = arc;
  protoOf(VectorPath).n29 = arc$default;
  protoOf(VectorPath).o29 = circle;
  protoOf(VectorPath).p29 = rMoveTo;
  protoOf(VectorPath).q29 = rLineTo;
  protoOf(VectorPath).f1r = transformed;
  protoOf(VectorPath).r29 = transformed_0;
  protoOf(with$1).x18 = close;
  protoOf(with$1).s20 = lineTo;
  protoOf(with$1).t20 = quadTo_0;
  protoOf(with$1).g20 = cubicTo;
  protoOf(MPoint).x1h = isAlmostEquals$default;
  protoOf(MRectangle).x1h = isAlmostEquals$default;
  //endregion
  //region block: init
  MINUS_ZERO_D = -0.0;
  MINUS_ZERO_F = -0.0;
  PI2 = 6.283185307179586;
  Companion_instance_10 = new Companion_9();
  Companion_instance_12 = new Companion_11();
  Companion_instance_21 = new Companion_20();
  Companion_instance_22 = new Companion_21();
  Arc_instance = new Arc();
  Companion_instance_25 = new Companion_24();
  Companion_instance_26 = new Companion_25();
  Convex_instance = new Convex();
  Companion_instance_29 = new Companion_28();
  Companion_instance_30 = new Companion_29();
  Companion_instance_31 = new Companion_30();
  Companion_instance_32 = new Companion_31();
  Command_instance = new Command();
  Companion_instance_33 = new Companion_32();
  Companion_instance_34 = new Companion_33();
  Companion_instance_35 = new Companion_34();
  Companion_instance_36 = new Companion_35();
  Companion_instance_39 = new Companion_38();
  Companion_instance_42 = new Companion_41();
  Companion_instance_43 = new Companion_42();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_getInstance;
  _.$_$.b = HitTestDirection_ANY_getInstance;
  _.$_$.c = LineCap_BUTT_getInstance;
  _.$_$.d = LineJoin_MITER_getInstance;
  _.$_$.e = LineScaleMode_NONE_getInstance;
  _.$_$.f = LineScaleMode_NORMAL_getInstance;
  _.$_$.g = Winding_EVEN_ODD_getInstance;
  _.$_$.h = Winding_NON_ZERO_getInstance;
  _.$_$.i = MatrixType_IDENTITY_getInstance;
  _.$_$.j = arc$default;
  _.$_$.k = MarginInt_init_$Create$;
  _.$_$.l = Matrix4_init_$Create$_0;
  _.$_$.m = MatrixTransform_init_$Create$;
  _.$_$.n = RectangleI_init_$Create$;
  _.$_$.o = Scale_init_$Create$_0;
  _.$_$.p = Scale_init_$Create$_2;
  _.$_$.q = Size2D_init_$Create$_1;
  _.$_$.r = Size2D_init_$Create$_0;
  _.$_$.s = SizeInt_init_$Create$;
  _.$_$.t = Vector2D_init_$Create$_1;
  _.$_$.u = Vector2D_init_$Create$_0;
  _.$_$.v = Vector2D_init_$Create$;
  _.$_$.w = Vector2F_init_$Create$_0;
  _.$_$.x = Vector2F_init_$Create$;
  _.$_$.y = _SliceOrientation___get_flipX__impl__lgb7bx;
  _.$_$.z = SliceOrientation__hashCode_impl_7mjkdw;
  _.$_$.a1 = _SliceOrientation___get_isRotatedDeg90CwOrCcw__impl__98v1vq;
  _.$_$.b1 = _SliceOrientation___get_rotation__impl__m65r0o;
  _.$_$.c1 = _Angle___init__impl__g23q1m;
  _.$_$.d1 = _Angle___get_absoluteValue__impl__4qoakv;
  _.$_$.e1 = _Angle___get_degrees__impl__qg56vw;
  _.$_$.f1 = Angle__div_impl_ut2btd_0;
  _.$_$.g1 = Angle__hashCode_impl_szcndt;
  _.$_$.h1 = Angle__plus_impl_26v37k;
  _.$_$.i1 = _Angle___get_radians__impl__n00yt5;
  _.$_$.j1 = _Angle___get_ratio__impl__ap3on4;
  _.$_$.k1 = Angle__times_impl_v3s9y2_0;
  _.$_$.l1 = Angle__times_impl_v3s9y2;
  _.$_$.m1 = Angle__unaryMinus_impl_7901fr;
  _.$_$.n1 = _BoundsBuilder___get_bounds__impl__s1l1gx;
  _.$_$.o1 = BoundsBuilder__boundsOrNull_impl_se9lyg;
  _.$_$.p1 = BoundsBuilder__hashCode_impl_649zbo;
  _.$_$.q1 = BoundsBuilder__plus_impl_cz7f7x;
  _.$_$.r1 = BoundsBuilder__plus_impl_cz7f7x_2;
  _.$_$.s1 = BoundsBuilder__plus_impl_cz7f7x_0;
  _.$_$.t1 = BoundsBuilder__plus_impl_cz7f7x_1;
  _.$_$.u1 = _BoundsBuilder___get_xmax__impl__gsz0km;
  _.$_$.v1 = _BoundsBuilder___get_xmin__impl__o2sib0;
  _.$_$.w1 = BoundsBuilder__xminOr_impl_k3l1m2;
  _.$_$.x1 = _BoundsBuilder___get_ymax__impl__oia4uz;
  _.$_$.y1 = _BoundsBuilder___get_ymin__impl__5n2e8j;
  _.$_$.z1 = _Ratio___init__impl__9mwvn2_1;
  _.$_$.a2 = _Ratio___init__impl__9mwvn2_0;
  _.$_$.b2 = Ratio__compareTo_impl_z2ienc;
  _.$_$.c2 = Ratio__times_impl_ucdh7y_0;
  _.$_$.d2 = Ratio__toDouble_impl_o7epze;
  _.$_$.e2 = Ratio__toFloat_impl_1ftup5;
  _.$_$.f2 = Companion_instance_43;
  _.$_$.g2 = Companion_getInstance_22;
  _.$_$.h2 = Companion_instance_25;
  _.$_$.i2 = Companion_instance_29;
  _.$_$.j2 = Companion_getInstance_27;
  _.$_$.k2 = Companion_instance_30;
  _.$_$.l2 = Command_instance;
  _.$_$.m2 = Companion_getInstance_1;
  _.$_$.n2 = Companion_getInstance_2;
  _.$_$.o2 = Companion_getInstance_3;
  _.$_$.p2 = Companion_getInstance_36;
  _.$_$.q2 = Companion_getInstance_5;
  _.$_$.r2 = Companion_getInstance_6;
  _.$_$.s2 = Companion_getInstance_8;
  _.$_$.t2 = Companion_getInstance_10;
  _.$_$.u2 = Companion_instance_12;
  _.$_$.v2 = Companion_getInstance_13;
  _.$_$.w2 = Companion_getInstance_14;
  _.$_$.x2 = Companion_getInstance_15;
  _.$_$.y2 = Companion_getInstance_18;
  _.$_$.z2 = Companion_getInstance_0;
  _.$_$.a3 = Companion_getInstance;
  _.$_$.b3 = SyncEventLoop;
  _.$_$.c3 = ProjectedPoint;
  _.$_$.d3 = get_isConvex;
  _.$_$.e3 = toStrokePointsList;
  _.$_$.f3 = ImageDoNotFitException;
  _.$_$.g3 = get_cachedPoints;
  _.$_$.h3 = getPoints2List;
  _.$_$.i3 = RectSlice;
  _.$_$.j3 = SliceOrientation;
  _.$_$.k3 = PolygonScanline;
  _.$_$.l3 = RastScale;
  _.$_$.m3 = StrokeInfo;
  _.$_$.n3 = StrokeToFill;
  _.$_$.o3 = arc;
  _.$_$.p3 = circle;
  _.$_$.q3 = isEmpty_0;
  _.$_$.r3 = line;
  _.$_$.s3 = rLineTo;
  _.$_$.t3 = rMoveTo;
  _.$_$.u3 = rect_0;
  _.$_$.v3 = rect_1;
  _.$_$.w3 = rect;
  _.$_$.x3 = transformed;
  _.$_$.y3 = VectorBuilder;
  _.$_$.z3 = VectorPath;
  _.$_$.a4 = applyTransform;
  _.$_$.b4 = getCurves;
  _.$_$.c4 = plus;
  _.$_$.d4 = strokeToFill_0;
  _.$_$.e4 = strokeToFill;
  _.$_$.f4 = toCurvesList;
  _.$_$.g4 = write;
  _.$_$.h4 = Anchor2D;
  _.$_$.i4 = Angle_between;
  _.$_$.j4 = Angle;
  _.$_$.k4 = BoundsBuilder;
  _.$_$.l4 = DoubleVectorArrayList;
  _.$_$.m4 = Line2D;
  _.$_$.n4 = MMatrix;
  _.$_$.o4 = Matrix;
  _.$_$.p4 = get_PI2;
  _.$_$.q4 = RectangleD;
  _.$_$.r4 = RectangleI;
  _.$_$.s4 = Scale;
  _.$_$.t4 = Size2D;
  _.$_$.u4 = SizeInt;
  _.$_$.v4 = Vector2D;
  _.$_$.w4 = Vector2F;
  _.$_$.x4 = Vector2I;
  _.$_$.y4 = Vector4F;
  _.$_$.z4 = get_degrees;
  _.$_$.a5 = get_immutable;
  _.$_$.b5 = get_mutable;
  _.$_$.c5 = get_radians;
  _.$_$.d5 = times;
  _.$_$.e5 = toFloat;
  _.$_$.f5 = toInt;
  _.$_$.g5 = toMatrix4;
  _.$_$.h5 = Ratio;
  _.$_$.i5 = interpolate;
  _.$_$.j5 = interpolate_0;
  _.$_$.k5 = interpolate_2;
  _.$_$.l5 = interpolate_1;
  _.$_$.m5 = toRatio_1;
  _.$_$.n5 = toRatio_0;
  _.$_$.o5 = get_0;
  _.$_$.p5 = IntSegmentSet;
  _.$_$.q5 = max_0;
  _.$_$.r5 = max;
  _.$_$.s5 = min;
  _.$_$.t5 = min_0;
  _.$_$.u5 = nextMultipleOf;
  _.$_$.v5 = get_niceStr_0;
  _.$_$.w5 = get_niceStr_1;
  _.$_$.x5 = niceStr;
  _.$_$.y5 = niceStr_0;
  //endregion
  return _;
}));
