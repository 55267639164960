(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js', './korge-root-korlibs-math-core.js', './korge-root-korlibs-platform.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'), require('./korge-root-korlibs-math-core.js'), require('./korge-root-korlibs-platform.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-memory'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'korge-root-korlibs-memory'.");
    }
    if (typeof this['korge-root-korlibs-math-core'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-memory'. Its dependency 'korge-root-korlibs-math-core' was not found. Please, check whether 'korge-root-korlibs-math-core' is loaded prior to 'korge-root-korlibs-memory'.");
    }
    if (typeof this['korge-root-korlibs-platform'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-memory'. Its dependency 'korge-root-korlibs-platform' was not found. Please, check whether 'korge-root-korlibs-platform' is loaded prior to 'korge-root-korlibs-memory'.");
    }
    root['korge-root-korlibs-memory'] = factory(typeof this['korge-root-korlibs-memory'] === 'undefined' ? {} : this['korge-root-korlibs-memory'], this['kotlin-kotlin-stdlib'], this['korge-root-korlibs-math-core'], this['korge-root-korlibs-platform']);
  }
}(this, function (_, kotlin_kotlin, kotlin_com_soywiz_korge_korlibs_math_core, kotlin_com_soywiz_korge_korlibs_platform) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var toByte = kotlin_kotlin.$_$.aa;
  var toString = kotlin_kotlin.$_$.da;
  var hashCode = kotlin_kotlin.$_$.e9;
  var THROW_CCE = kotlin_kotlin.$_$.md;
  var equals = kotlin_kotlin.$_$.v8;
  var protoOf = kotlin_kotlin.$_$.y9;
  var classMeta = kotlin_kotlin.$_$.t8;
  var setMetadataFor = kotlin_kotlin.$_$.z9;
  var arrayCopy = kotlin_kotlin.$_$.u3;
  var Unit_instance = kotlin_kotlin.$_$.g3;
  var toShort = kotlin_kotlin.$_$.ca;
  var VOID = kotlin_kotlin.$_$.d;
  var fill = kotlin_kotlin.$_$.z4;
  var toLong = kotlin_kotlin.$_$.ba;
  var Long = kotlin_kotlin.$_$.hd;
  var clamp01 = kotlin_com_soywiz_korge_korlibs_math_core.$_$.b;
  var numberToInt = kotlin_kotlin.$_$.u9;
  var IntCompanionObject_instance = kotlin_kotlin.$_$.x2;
  var objectMeta = kotlin_kotlin.$_$.x9;
  var FloatCompanionObject_instance = kotlin_kotlin.$_$.w2;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.h1;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.j1;
  var interfaceMeta = kotlin_kotlin.$_$.g9;
  var objectCreate = kotlin_kotlin.$_$.w9;
  var RuntimeException_init_$Create$ = kotlin_kotlin.$_$.r1;
  var copyOf = kotlin_kotlin.$_$.m4;
  var copyOfRange = kotlin_kotlin.$_$.l4;
  var charArray = kotlin_kotlin.$_$.q8;
  var numberToChar = kotlin_kotlin.$_$.s9;
  var numberToLong = kotlin_kotlin.$_$.v9;
  var roundToInt = kotlin_kotlin.$_$.ga;
  var getNumberHashCode = kotlin_kotlin.$_$.b9;
  var Companion_instance = kotlin_com_soywiz_korge_korlibs_platform.$_$.e;
  //endregion
  //region block: pre-declaration
  setMetadataFor(UByteArrayInt, 'UByteArrayInt', classMeta);
  setMetadataFor(Companion, 'Companion', objectMeta);
  function get_size() {
    return this.f1c().y1b() / this.g1c() | 0;
  }
  setMetadataFor(TypedBuffer, 'TypedBuffer', interfaceMeta);
  setMetadataFor(Uint16Buffer, 'Uint16Buffer', classMeta, VOID, [TypedBuffer]);
  setMetadataFor(ByteArrayBuilder, 'ByteArrayBuilder', classMeta, VOID, VOID, ByteArrayBuilder_init_$Create$);
  setMetadataFor(FixedSizeByteArrayBuilder, 'FixedSizeByteArrayBuilder', classMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(ByteUnits, 'ByteUnits', classMeta);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(Buffer, 'Buffer', classMeta);
  //endregion
  function _UByteArrayInt___init__impl__xif7bu(data) {
    return data;
  }
  function _UByteArrayInt___get_data__impl__l2b913($this) {
    return $this;
  }
  function _UByteArrayInt___init__impl__xif7bu_0(size) {
    return _UByteArrayInt___init__impl__xif7bu(new Int8Array(size));
  }
  function _UByteArrayInt___get_size__impl__bcjnte($this) {
    return _UByteArrayInt___get_data__impl__l2b913($this).length;
  }
  function UByteArrayInt__get_impl_px6q8a($this, index) {
    return _UByteArrayInt___get_data__impl__l2b913($this)[index] & 255;
  }
  function UByteArrayInt__set_impl_gn4532($this, index, value) {
    _UByteArrayInt___get_data__impl__l2b913($this)[index] = toByte(value);
  }
  function UByteArrayInt__toString_impl_f8qdsi($this) {
    return 'UByteArrayInt(data=' + toString($this) + ')';
  }
  function UByteArrayInt__hashCode_impl_y17rdr($this) {
    return hashCode($this);
  }
  function UByteArrayInt__equals_impl_xlvwxn($this, other) {
    if (!(other instanceof UByteArrayInt))
      return false;
    var tmp0_other_with_cast = other instanceof UByteArrayInt ? other.p1b_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function UByteArrayInt(data) {
    this.p1b_1 = data;
  }
  protoOf(UByteArrayInt).toString = function () {
    return UByteArrayInt__toString_impl_f8qdsi(this.p1b_1);
  };
  protoOf(UByteArrayInt).hashCode = function () {
    return UByteArrayInt__hashCode_impl_y17rdr(this.p1b_1);
  };
  protoOf(UByteArrayInt).equals = function (other) {
    return UByteArrayInt__equals_impl_xlvwxn(this.p1b_1, other);
  };
  function arraycopy(src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  }
  function arraycopy_0(src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  }
  function arraycopy_1(src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  }
  function asByteArray(_this__u8e3s4) {
    return _UByteArrayInt___get_data__impl__l2b913(_this__u8e3s4);
  }
  function _UShortArrayInt___init__impl__kdofb8(data) {
    return data;
  }
  function _UShortArrayInt___get_data__impl__l4stnv($this) {
    return $this;
  }
  function _UShortArrayInt___init__impl__kdofb8_0(size) {
    return _UShortArrayInt___init__impl__kdofb8(new Int16Array(size));
  }
  function UShortArrayInt__get_impl_ciz20k($this, index) {
    return _UShortArrayInt___get_data__impl__l4stnv($this)[index] & 65535;
  }
  function UShortArrayInt__set_impl_38wgvc($this, index, value) {
    _UShortArrayInt___get_data__impl__l4stnv($this)[index] = toShort(value);
  }
  function arraycopy_2(src, srcPos, dst, dstPos, size) {
    Companion_instance_2.q1b(src, srcPos, dst, dstPos, size);
  }
  function arrayfill(array, value, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? get_size_0(array) : end;
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        setUInt8(array, n, value);
      }
       while (inductionVariable < end);
  }
  function arrayequal(src, srcPos, dst, dstPos, size) {
    return Companion_instance_2.r1b(src, srcPos, dst, dstPos, size);
  }
  function arraycopy_3(src, srcPos, dst, dstPos, size) {
    Uint16Buffer__setArray_impl_x4uzt(dst, dstPos, src, srcPos, size);
  }
  function asUShortArrayInt(_this__u8e3s4) {
    return _UShortArrayInt___init__impl__kdofb8(_this__u8e3s4);
  }
  function arrayadd(array, value, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? (new Uint16Buffer(array)).l() : end;
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        Uint16Buffer__set_impl_g0kliu(array, n, Uint16Buffer__get_impl_6qi0dm(array, n) + value | 0);
      }
       while (inductionVariable < end);
  }
  function arraycopy_4(src, srcPos, dst, dstPos, size) {
    return arraycopy_2(_Int32Buffer___get_buffer__impl__dnrimm(src), imul(srcPos, 4), _Int32Buffer___get_buffer__impl__dnrimm(dst), imul(dstPos, 4), imul(size, 4));
  }
  function arrayfill_0(array, value, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? array.length : end;
    return fill(array, value, start, end);
  }
  function arraycopy_5(src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  }
  function arraycopy_6(src, srcPos, dst, dstPos, size) {
    Int8Buffer__setArray_impl_oimleb(dst, dstPos, src, srcPos, size);
  }
  function arraycopy_7(src, srcPos, dst, dstPos, size) {
    Float32Buffer__setArray_impl_eosj51(dst, dstPos, src, srcPos, size);
  }
  function arraycopy_8(src, srcPos, dst, dstPos, size) {
    Int32Buffer__setArray_impl_eeeiw8(dst, dstPos, src, srcPos, size);
  }
  function arraycopy_9(src, srcPos, dst, dstPos, size) {
    dst.t1b(dstPos, src, srcPos, size, false);
  }
  function extract(_this__u8e3s4, offset, count) {
    return (_this__u8e3s4 >>> offset | 0) & mask(count);
  }
  function insert(_this__u8e3s4, value, offset, count) {
    var mask_0 = mask(count);
    var clearValue = _this__u8e3s4 & ~(mask_0 << offset);
    return clearValue | (value & mask_0) << offset;
  }
  function extractSigned(_this__u8e3s4, offset, count) {
    return signExtend((_this__u8e3s4 >>> offset | 0) & mask(count), count);
  }
  function insert16(_this__u8e3s4, value, offset) {
    // Inline function 'korlibs.memory.insertMask' call
    return _this__u8e3s4 & ~(65535 << offset) | (value & 65535) << offset;
  }
  function signExtend(_this__u8e3s4, bits) {
    return _this__u8e3s4 << (32 - bits | 0) >> (32 - bits | 0);
  }
  function fromLowHigh(_this__u8e3s4, low, high) {
    return toLong(low).dc(new Long(-1, 0)).ec(toLong(high).ac(32));
  }
  function get_low(_this__u8e3s4) {
    return _this__u8e3s4.ua();
  }
  function get_high(_this__u8e3s4) {
    return _this__u8e3s4.cc(32).ua();
  }
  function insert8(_this__u8e3s4, value, offset) {
    // Inline function 'korlibs.memory.insertMask' call
    return _this__u8e3s4 & ~(255 << offset) | (value & 255) << offset;
  }
  function insert_0(_this__u8e3s4, value, offset) {
    var ivalue = value ? 1 : 0;
    return _this__u8e3s4 & ~(1 << offset) | ivalue << offset;
  }
  function setBits(_this__u8e3s4, bits, set) {
    return set ? setBits_0(_this__u8e3s4, bits) : unsetBits(_this__u8e3s4, bits);
  }
  function insert5(_this__u8e3s4, value, offset) {
    // Inline function 'korlibs.memory.insertMask' call
    return _this__u8e3s4 & ~(31 << offset) | (value & 31) << offset;
  }
  function insert2(_this__u8e3s4, value, offset) {
    // Inline function 'korlibs.memory.insertMask' call
    return _this__u8e3s4 & ~(3 << offset) | (value & 3) << offset;
  }
  function insert4(_this__u8e3s4, value, offset) {
    // Inline function 'korlibs.memory.insertMask' call
    return _this__u8e3s4 & ~(15 << offset) | (value & 15) << offset;
  }
  function insert3(_this__u8e3s4, value, offset) {
    // Inline function 'korlibs.memory.insertMask' call
    return _this__u8e3s4 & ~(7 << offset) | (value & 7) << offset;
  }
  function insert14(_this__u8e3s4, value, offset) {
    // Inline function 'korlibs.memory.insertMask' call
    return _this__u8e3s4 & ~(16383 << offset) | (value & 16383) << offset;
  }
  function extractScaledf01(_this__u8e3s4, offset, count) {
    return extract(_this__u8e3s4, offset, count) / mask(count);
  }
  function insertScaledf01(_this__u8e3s4, value, offset, count) {
    return insert(_this__u8e3s4, numberToInt(clamp01(value) * mask(offset)), offset, count);
  }
  function mask(_this__u8e3s4) {
    return (1 << _this__u8e3s4) - 1 | 0;
  }
  function setBits_0(_this__u8e3s4, bits) {
    return _this__u8e3s4 | bits;
  }
  function unsetBits(_this__u8e3s4, bits) {
    return _this__u8e3s4 & ~bits;
  }
  function _Float32Buffer___init__impl__403k2m(buffer) {
    return buffer;
  }
  function _Float32Buffer___get_buffer__impl__x5nu9x($this) {
    return $this;
  }
  function _Float32Buffer___init__impl__403k2m_0(data, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? data.length - offset | 0 : size;
    // Inline function 'kotlin.also' call
    var this_0 = Buffer_init_$Create$(imul(size, 4));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.Float32Buffer.<init>.<anonymous>' call
    setArray(this_0, 0, data, offset, size);
    return _Float32Buffer___init__impl__403k2m(this_0);
  }
  function Float32Buffer__get_impl_659lhu($this, index) {
    // Inline function 'korlibs.memory.getF32' call
    var this_0 = _Float32Buffer___get_buffer__impl__x5nu9x($this);
    var byteOffset = imul(index, 4);
    return true ? this_0.v1b(byteOffset) : this_0.u1b(byteOffset);
  }
  function Float32Buffer__set_impl_34szne($this, index, value) {
    // Inline function 'korlibs.memory.setF32' call
    var this_0 = _Float32Buffer___get_buffer__impl__x5nu9x($this);
    var byteOffset = imul(index, 4);
    var tmp;
    if (true) {
      this_0.x1b(byteOffset, value);
      tmp = Unit_instance;
    } else {
      this_0.w1b(byteOffset, value);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function Float32Buffer__setArray_impl_eosj51($this, index, inp, offset, size) {
    return setArray(_Float32Buffer___get_buffer__impl__x5nu9x($this), imul(index, 4), inp, offset, size);
  }
  function asFloat32(_this__u8e3s4) {
    return _Float32Buffer___init__impl__403k2m(_this__u8e3s4);
  }
  function copyOf_0(_this__u8e3s4, newSize) {
    var out = Buffer_init_$Create$(newSize);
    // Inline function 'kotlin.math.min' call
    var a = _this__u8e3s4.y1b();
    var tmp$ret$0 = Math.min(a, newSize);
    arraycopy_2(_this__u8e3s4, 0, out, 0, tmp$ret$0);
    return out;
  }
  function setInt32(_this__u8e3s4, index, value) {
    return _this__u8e3s4.z1b(imul(index, 4), value);
  }
  function getInt32(_this__u8e3s4, index) {
    return _this__u8e3s4.a1c(imul(index, 4));
  }
  function _Uint16Buffer___init__impl__4401ya(buffer) {
    return buffer;
  }
  function _Uint16Buffer___get_buffer__impl__rei8ax($this) {
    return $this;
  }
  function Companion() {
    this.b1c_1 = 2;
  }
  var Companion_instance_0;
  function Companion_getInstance() {
    return Companion_instance_0;
  }
  function _Uint16Buffer___get_elementSizeInBytes__impl__tcik7m($this) {
    return 2;
  }
  function Uint16Buffer__get_impl_6qi0dm($this, index) {
    // Inline function 'korlibs.memory.getU16' call
    var this_0 = _Uint16Buffer___get_buffer__impl__rei8ax($this);
    var byteOffset = imul(index, 2);
    return true ? getU16LE(this_0, byteOffset) : getU16BE(this_0, byteOffset);
  }
  function Uint16Buffer__set_impl_g0kliu($this, index, value) {
    // Inline function 'korlibs.memory.set16' call
    var this_0 = _Uint16Buffer___get_buffer__impl__rei8ax($this);
    var byteOffset = imul(index, 2);
    var value_0 = toShort(value);
    var tmp;
    if (true) {
      this_0.d1c(byteOffset, value_0);
      tmp = Unit_instance;
    } else {
      this_0.c1c(byteOffset, value_0);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function Uint16Buffer__setArray_impl_x4uzt($this, index, inp, offset, size) {
    return setArray_0(_Uint16Buffer___get_buffer__impl__rei8ax($this), imul(index, 2), _UShortArrayInt___get_data__impl__l4stnv(inp), offset, size);
  }
  function Uint16Buffer__toString_impl_rxffha($this) {
    return 'Uint16Buffer(buffer=' + $this + ')';
  }
  function Uint16Buffer__hashCode_impl_lcipoz($this) {
    return $this.hashCode();
  }
  function Uint16Buffer__equals_impl_u4cl2n($this, other) {
    if (!(other instanceof Uint16Buffer))
      return false;
    var tmp0_other_with_cast = other instanceof Uint16Buffer ? other.e1c_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function Uint16Buffer(buffer) {
    this.e1c_1 = buffer;
  }
  protoOf(Uint16Buffer).f1c = function () {
    return _Uint16Buffer___get_buffer__impl__rei8ax(this.e1c_1);
  };
  protoOf(Uint16Buffer).g1c = function () {
    return _Uint16Buffer___get_elementSizeInBytes__impl__tcik7m(this.e1c_1);
  };
  protoOf(Uint16Buffer).toString = function () {
    return Uint16Buffer__toString_impl_rxffha(this.e1c_1);
  };
  protoOf(Uint16Buffer).hashCode = function () {
    return Uint16Buffer__hashCode_impl_lcipoz(this.e1c_1);
  };
  protoOf(Uint16Buffer).equals = function (other) {
    return Uint16Buffer__equals_impl_u4cl2n(this.e1c_1, other);
  };
  function get_u16(_this__u8e3s4) {
    return asUInt16(_this__u8e3s4);
  }
  function setFloat32(_this__u8e3s4, index, value) {
    return _this__u8e3s4.x1b(imul(index, 4), value);
  }
  function _Int32Buffer___init__impl__o98lxn(buffer) {
    return buffer;
  }
  function _Int32Buffer___get_buffer__impl__dnrimm($this) {
    return $this;
  }
  function Int32Buffer__setArray_impl_eeeiw8($this, index, inp, offset, size) {
    return setArray_1(_Int32Buffer___get_buffer__impl__dnrimm($this), imul(index, 4), inp, offset, size);
  }
  function get_i32(_this__u8e3s4) {
    return asInt32(_this__u8e3s4);
  }
  function get_f32(_this__u8e3s4) {
    return asFloat32(_this__u8e3s4);
  }
  function allocDirect(_this__u8e3s4, size) {
    return Buffer_init_$Create$(size, true);
  }
  function allocNoDirect(_this__u8e3s4, size) {
    return Buffer_init_$Create$(size, false);
  }
  function get_size_0(_this__u8e3s4) {
    return _this__u8e3s4.y1b();
  }
  function _Int8Buffer___init__impl__e20lvc(buffer) {
    return buffer;
  }
  function _Int8Buffer___get_buffer__impl__q0gulb($this) {
    return $this;
  }
  function _Int8Buffer___init__impl__e20lvc_0(data, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? data.length - offset | 0 : size;
    // Inline function 'kotlin.also' call
    var this_0 = Buffer_init_$Create$(imul(size, 1));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.Int8Buffer.<init>.<anonymous>' call
    setArray_2(this_0, 0, data, offset, size);
    return _Int8Buffer___init__impl__e20lvc(this_0);
  }
  function Int8Buffer__setArray_impl_oimleb($this, index, inp, offset, size) {
    return setArray_2(_Int8Buffer___get_buffer__impl__q0gulb($this), imul(index, 1), inp, offset, size);
  }
  function sliceWithSize(_this__u8e3s4, start, size) {
    return sliceBuffer(_this__u8e3s4, start, start + size | 0);
  }
  function clone(_this__u8e3s4, direct) {
    direct = direct === VOID ? false : direct;
    var out = Buffer_init_$Create$(get_size_0(_this__u8e3s4), direct);
    arraycopy_2(_this__u8e3s4, 0, out, 0, get_size_0(_this__u8e3s4));
    return out;
  }
  function sliceBuffer(_this__u8e3s4, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? _this__u8e3s4.y1b() : end;
    if ((start > end ? true : !(0 <= start ? start <= _this__u8e3s4.y1b() : false)) ? true : !(0 <= end ? end <= _this__u8e3s4.y1b() : false)) {
      throw IllegalArgumentException_init_$Create$('invalid slice start:' + start + ', end:' + end + ' not in 0..' + _this__u8e3s4.y1b());
    }
    return _this__u8e3s4.h1c(start, end);
  }
  function get_i8(_this__u8e3s4) {
    return asInt8(_this__u8e3s4);
  }
  function setInt8(_this__u8e3s4, index, value) {
    return _this__u8e3s4.d1c(index, toShort(value));
  }
  function getInt8(_this__u8e3s4, index) {
    return _this__u8e3s4.i1c(index);
  }
  function setUInt8(_this__u8e3s4, index, value) {
    return _this__u8e3s4.j1c(index, toByte(value));
  }
  function checkNBufferSize(size) {
    if (size < 0)
      throw IllegalArgumentException_init_$Create$('invalid size ' + size);
    return size;
  }
  function hashCodeCommon(_this__u8e3s4, buffer) {
    var h = 1;
    var len = buffer.y1b();
    var inductionVariable = 0;
    var last = len / 4 | 0;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        h = imul(31, h) + buffer.a1c(imul(n, 4)) | 0;
      }
       while (inductionVariable < last);
    var offset = imul(len / 4 | 0, 4);
    var inductionVariable_0 = 0;
    var last_0 = len % 4 | 0;
    if (inductionVariable_0 < last_0)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        h = imul(31, h) + buffer.i1c(offset + n_0 | 0) | 0;
      }
       while (inductionVariable_0 < last_0);
    return h;
  }
  function equalsCommon(_this__u8e3s4, that, other) {
    var tmp;
    if (!(other instanceof Buffer)) {
      tmp = true;
    } else {
      tmp = !(get_size_0(that) === get_size_0(other));
    }
    if (tmp)
      return false;
    return Companion_instance_2.r1b(that, 0, other, 0, that.y1b());
  }
  function NBuffer_toString(buffer) {
    return 'Buffer(size=' + get_size_0(buffer) + ')';
  }
  function equalsCommon_0(_this__u8e3s4, src, srcPosBytes, dst, dstPosBytes, sizeInBytes, use64) {
    use64 = use64 === VOID ? true : use64;
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!((srcPosBytes + sizeInBytes | 0) <= src.y1b())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message = 'Check failed.';
      throw IllegalStateException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!((dstPosBytes + sizeInBytes | 0) <= dst.y1b())) {
      // Inline function 'kotlin.check.<anonymous>' call
      var message_0 = 'Check failed.';
      throw IllegalStateException_init_$Create$(toString(message_0));
    }
    var offset = 0;
    var remaining = sizeInBytes;
    if (use64) {
      var WORD = 8;
      var words = remaining / WORD | 0;
      remaining = remaining % WORD | 0;
      var inductionVariable = 0;
      if (inductionVariable < words)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var v0 = src.k1c((srcPosBytes + offset | 0) + imul(n, WORD) | 0);
          var v1 = dst.k1c((dstPosBytes + offset | 0) + imul(n, WORD) | 0);
          if (!v0.equals(v1)) {
            return false;
          }
        }
         while (inductionVariable < words);
      offset = offset + imul(words, WORD) | 0;
    }
    var WORD_0 = 4;
    var words_0 = remaining / WORD_0 | 0;
    remaining = remaining % WORD_0 | 0;
    var inductionVariable_0 = 0;
    if (inductionVariable_0 < words_0)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var v0_0 = src.a1c((srcPosBytes + offset | 0) + imul(n_0, WORD_0) | 0);
        var v1_0 = dst.a1c((dstPosBytes + offset | 0) + imul(n_0, WORD_0) | 0);
        if (!(v0_0 === v1_0)) {
          return false;
        }
      }
       while (inductionVariable_0 < words_0);
    offset = offset + imul(words_0, WORD_0) | 0;
    var inductionVariable_1 = 0;
    var last = remaining;
    if (inductionVariable_1 < last)
      do {
        var n_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var v0_1 = src.i1c((srcPosBytes + offset | 0) + n_1 | 0);
        var v1_1 = dst.i1c((dstPosBytes + offset | 0) + n_1 | 0);
        if (!(v0_1 === v1_1)) {
          return false;
        }
      }
       while (inductionVariable_1 < last);
    return true;
  }
  function TypedBuffer() {
  }
  function setArray(_this__u8e3s4, byteOffset, data, start, size, littleEndian) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    littleEndian = littleEndian === VOID ? true : littleEndian;
    var tmp;
    if (littleEndian) {
      setArrayLE(_this__u8e3s4, byteOffset, data, start, size);
      tmp = Unit_instance;
    } else {
      setArrayBE(_this__u8e3s4, byteOffset, data, start, size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function setArray_0(_this__u8e3s4, byteOffset, data, start, size, littleEndian) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    littleEndian = littleEndian === VOID ? true : littleEndian;
    var tmp;
    if (littleEndian) {
      setArrayLE_0(_this__u8e3s4, byteOffset, data, start, size);
      tmp = Unit_instance;
    } else {
      setArrayBE_0(_this__u8e3s4, byteOffset, data, start, size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function asUInt16(_this__u8e3s4) {
    return _Uint16Buffer___init__impl__4401ya(_this__u8e3s4);
  }
  function setArray_1(_this__u8e3s4, byteOffset, data, start, size, littleEndian) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    littleEndian = littleEndian === VOID ? true : littleEndian;
    var tmp;
    if (littleEndian) {
      setArrayLE_1(_this__u8e3s4, byteOffset, data, start, size);
      tmp = Unit_instance;
    } else {
      setArrayBE_1(_this__u8e3s4, byteOffset, data, start, size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function asInt32(_this__u8e3s4) {
    return _Int32Buffer___init__impl__o98lxn(_this__u8e3s4);
  }
  function setArray_2(_this__u8e3s4, byteOffset, data, start, size) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    arraycopy_9(data, start, _this__u8e3s4, byteOffset, size);
  }
  function asInt8(_this__u8e3s4) {
    return _Int8Buffer___init__impl__e20lvc(_this__u8e3s4);
  }
  function setArrayLE(_this__u8e3s4, byteOffset, data, start, size) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    var inductionVariable = 0;
    var tmp;
    if (inductionVariable < size) {
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.setArrayLE.<anonymous>' call
        var offset = byteOffset + imul(n, 4) | 0;
        var index = start + n | 0;
        _this__u8e3s4.x1b(offset, data[index]);
      }
       while (inductionVariable < size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function setArrayBE(_this__u8e3s4, byteOffset, data, start, size) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    var inductionVariable = 0;
    var tmp;
    if (inductionVariable < size) {
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.setArrayBE.<anonymous>' call
        var offset = byteOffset + imul(n, 4) | 0;
        var index = start + n | 0;
        _this__u8e3s4.w1b(offset, data[index]);
      }
       while (inductionVariable < size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function setArrayLE_0(_this__u8e3s4, byteOffset, data, start, size) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    var inductionVariable = 0;
    var tmp;
    if (inductionVariable < size) {
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.setArrayLE.<anonymous>' call
        var offset = byteOffset + imul(n, 2) | 0;
        var index = start + n | 0;
        _this__u8e3s4.d1c(offset, data[index]);
      }
       while (inductionVariable < size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function setArrayBE_0(_this__u8e3s4, byteOffset, data, start, size) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    var inductionVariable = 0;
    var tmp;
    if (inductionVariable < size) {
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.setArrayBE.<anonymous>' call
        var offset = byteOffset + imul(n, 2) | 0;
        var index = start + n | 0;
        _this__u8e3s4.c1c(offset, data[index]);
      }
       while (inductionVariable < size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function getU16LE(_this__u8e3s4, byteOffset) {
    // Inline function 'korlibs.math.unsigned' call
    return _this__u8e3s4.l1c(byteOffset) & 65535;
  }
  function getU16BE(_this__u8e3s4, byteOffset) {
    // Inline function 'korlibs.math.unsigned' call
    return _this__u8e3s4.m1c(byteOffset) & 65535;
  }
  function setArrayLE_1(_this__u8e3s4, byteOffset, data, start, size) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    var inductionVariable = 0;
    var tmp;
    if (inductionVariable < size) {
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.setArrayLE.<anonymous>' call
        var offset = byteOffset + imul(n, 4) | 0;
        var index = start + n | 0;
        _this__u8e3s4.z1b(offset, data[index]);
      }
       while (inductionVariable < size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function setArrayBE_1(_this__u8e3s4, byteOffset, data, start, size) {
    start = start === VOID ? 0 : start;
    size = size === VOID ? data.length - start | 0 : size;
    var inductionVariable = 0;
    var tmp;
    if (inductionVariable < size) {
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.setArrayBE.<anonymous>' call
        var offset = byteOffset + imul(n, 4) | 0;
        var index = start + n | 0;
        _this__u8e3s4.n1c(offset, data[index]);
      }
       while (inductionVariable < size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function ByteArrayBuilder_init_$Init$(initialCapacity, $this) {
    initialCapacity = initialCapacity === VOID ? 4096 : initialCapacity;
    ByteArrayBuilder.call($this, new Int8Array(initialCapacity), 0);
    return $this;
  }
  function ByteArrayBuilder_init_$Create$(initialCapacity) {
    return ByteArrayBuilder_init_$Init$(initialCapacity, objectCreate(protoOf(ByteArrayBuilder)));
  }
  function ensure($this, expected) {
    if ($this.o1c_1.length < expected) {
      if (!$this.p1c_1)
        throw RuntimeException_init_$Create$('ByteArrayBuffer configured to not grow!');
      var newSize = imul($this.o1c_1.length + 7 | 0, 5);
      var realNewSize = newSize < 0 ? IntCompanionObject_instance.MAX_VALUE / 2 | 0 : newSize;
      if (newSize < 0 ? expected > realNewSize : false) {
        // Inline function 'kotlin.error' call
        var message = "ByteArrayBuffer can't grow that much";
        throw IllegalStateException_init_$Create$(toString(message));
      }
      var tmp = $this;
      var tmp_0 = $this.o1c_1;
      // Inline function 'kotlin.math.max' call
      var tmp$ret$0 = Math.max(expected, realNewSize);
      tmp.o1c_1 = copyOf(tmp_0, tmp$ret$0);
    }
  }
  function ensureCount($this, count) {
    ensure($this, $this.q1c_1 + count | 0);
  }
  function ByteArrayBuilder(data, size, allowGrow) {
    size = size === VOID ? data.length : size;
    allowGrow = allowGrow === VOID ? true : allowGrow;
    this.o1c_1 = data;
    this.p1c_1 = allowGrow;
    this.q1c_1 = size;
  }
  protoOf(ByteArrayBuilder).t10 = function (value) {
    var oldPosition = this.q1c_1;
    var newPosition = value;
    ensure(this, newPosition);
    this.q1c_1 = newPosition;
    if (newPosition > oldPosition) {
      arrayfill_0(this.o1c_1, 0, oldPosition, newPosition);
    }
  };
  protoOf(ByteArrayBuilder).l = function () {
    return this.q1c_1;
  };
  protoOf(ByteArrayBuilder).r1c = function (array, offset, len) {
    ensureCount(this, len);
    arraycopy_1(array, offset, this.o1c_1, this.q1c_1, len);
    this.q1c_1 = this.q1c_1 + len | 0;
  };
  protoOf(ByteArrayBuilder).s1c = function (array, offset, len, $super) {
    offset = offset === VOID ? 0 : offset;
    len = len === VOID ? array.length - offset | 0 : len;
    var tmp;
    if ($super === VOID) {
      this.r1c(array, offset, len);
      tmp = Unit_instance;
    } else {
      tmp = $super.r1c.call(this, array, offset, len);
    }
    return tmp;
  };
  protoOf(ByteArrayBuilder).t1c = function (v) {
    ensure(this, this.q1c_1 + 1 | 0);
    var tmp = this.o1c_1;
    var tmp1 = this.q1c_1;
    this.q1c_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v;
  };
  protoOf(ByteArrayBuilder).u1c = function () {
    return copyOf(this.o1c_1, this.q1c_1);
  };
  function getU8(_this__u8e3s4, offset) {
    return _this__u8e3s4[offset] & 255;
  }
  function getS16LE(_this__u8e3s4, offset) {
    // Inline function 'korlibs.memory.get16LE' call
    var tmp$ret$0 = u8$accessor$1maot03(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset + 1 | 0) << 8;
    return signExtend(tmp$ret$0, 16);
  }
  function getU16LE_0(_this__u8e3s4, offset) {
    // Inline function 'korlibs.memory.get16LE' call
    return u8$accessor$1maot03(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset + 1 | 0) << 8;
  }
  function getU16BE_0(_this__u8e3s4, offset) {
    // Inline function 'korlibs.memory.get16BE' call
    return u8$accessor$1maot03(_this__u8e3s4, offset + 1 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset + 0 | 0) << 8;
  }
  function getU24LE(_this__u8e3s4, offset) {
    // Inline function 'korlibs.memory.get24LE' call
    return u8$accessor$1maot03(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset + 1 | 0) << 8 | u8$accessor$1maot03(_this__u8e3s4, offset + 2 | 0) << 16;
  }
  function getU24BE(_this__u8e3s4, offset) {
    // Inline function 'korlibs.memory.get24BE' call
    return u8$accessor$1maot03(_this__u8e3s4, offset + 2 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset + 1 | 0) << 8 | u8$accessor$1maot03(_this__u8e3s4, offset + 0 | 0) << 16;
  }
  function getS32LE(_this__u8e3s4, offset) {
    // Inline function 'korlibs.memory.get32LE' call
    return u8$accessor$1maot03(_this__u8e3s4, offset + 0 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset + 1 | 0) << 8 | u8$accessor$1maot03(_this__u8e3s4, offset + 2 | 0) << 16 | u8$accessor$1maot03(_this__u8e3s4, offset + 3 | 0) << 24;
  }
  function getS32BE(_this__u8e3s4, offset) {
    // Inline function 'korlibs.memory.get32BE' call
    return u8$accessor$1maot03(_this__u8e3s4, offset + 3 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset + 2 | 0) << 8 | u8$accessor$1maot03(_this__u8e3s4, offset + 1 | 0) << 16 | u8$accessor$1maot03(_this__u8e3s4, offset + 0 | 0) << 24;
  }
  function getS16(_this__u8e3s4, offset, littleEndian) {
    return littleEndian ? getS16LE(_this__u8e3s4, offset) : getS16BE(_this__u8e3s4, offset);
  }
  function getS8(_this__u8e3s4, offset) {
    return _this__u8e3s4[offset];
  }
  function getS16BE(_this__u8e3s4, offset) {
    // Inline function 'korlibs.memory.get16BE' call
    var tmp$ret$0 = u8$accessor$1maot03(_this__u8e3s4, offset + 1 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset + 0 | 0) << 8;
    return signExtend(tmp$ret$0, 16);
  }
  function getS64BE(_this__u8e3s4, offset) {
    // Inline function 'korlibs.memory.get64BE' call
    // Inline function 'korlibs.math.unsigned' call
    // Inline function 'korlibs.memory.get32BE' call
    var offset_0 = offset + 4 | 0;
    var this_0 = u8$accessor$1maot03(_this__u8e3s4, offset_0 + 3 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset_0 + 2 | 0) << 8 | u8$accessor$1maot03(_this__u8e3s4, offset_0 + 1 | 0) << 16 | u8$accessor$1maot03(_this__u8e3s4, offset_0 + 0 | 0) << 24;
    var tmp = toLong(this_0).dc(new Long(-1, 0)).ac(0);
    // Inline function 'korlibs.math.unsigned' call
    // Inline function 'korlibs.memory.get32BE' call
    var offset_1 = offset + 0 | 0;
    var this_1 = u8$accessor$1maot03(_this__u8e3s4, offset_1 + 3 | 0) << 0 | u8$accessor$1maot03(_this__u8e3s4, offset_1 + 2 | 0) << 8 | u8$accessor$1maot03(_this__u8e3s4, offset_1 + 1 | 0) << 16 | u8$accessor$1maot03(_this__u8e3s4, offset_1 + 0 | 0) << 24;
    var tmp$ret$3 = toLong(this_1).dc(new Long(-1, 0));
    return tmp.ec(tmp$ret$3.ac(32));
  }
  function getS8Array(_this__u8e3s4, offset, count) {
    return copyOfRange(_this__u8e3s4, offset, offset + count | 0);
  }
  function getS16ArrayBE(_this__u8e3s4, offset, count) {
    // Inline function 'korlibs.memory.getTypedArray' call
    var array = new Int16Array(count);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.getTypedArray.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.getS16ArrayBE.<anonymous>' call
        var pos = offset + imul(n, 2) | 0;
        array[n] = toShort(getS16BE(_this__u8e3s4, pos));
      }
       while (inductionVariable < count);
    return array;
  }
  function getU16ArrayBE(_this__u8e3s4, offset, count) {
    // Inline function 'korlibs.memory.getTypedArray' call
    var array = charArray(count);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.getTypedArray.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.getU16ArrayBE.<anonymous>' call
        var pos = offset + imul(n, 2) | 0;
        array[n] = numberToChar(getS16BE(_this__u8e3s4, pos));
      }
       while (inductionVariable < count);
    return array;
  }
  function getS32ArrayLE(_this__u8e3s4, offset, count) {
    // Inline function 'korlibs.memory.getTypedArray' call
    var array = new Int32Array(count);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.getTypedArray.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.getS32ArrayLE.<anonymous>' call
        var pos = offset + imul(n, 4) | 0;
        array[n] = getS32LE(_this__u8e3s4, pos);
      }
       while (inductionVariable < count);
    return array;
  }
  function getS32ArrayBE(_this__u8e3s4, offset, count) {
    // Inline function 'korlibs.memory.getTypedArray' call
    var array = new Int32Array(count);
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.getTypedArray.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.getS32ArrayBE.<anonymous>' call
        var pos = offset + imul(n, 4) | 0;
        array[n] = getS32BE(_this__u8e3s4, pos);
      }
       while (inductionVariable < count);
    return array;
  }
  function getU16(_this__u8e3s4, offset, littleEndian) {
    return littleEndian ? getU16LE_0(_this__u8e3s4, offset) : getU16BE_0(_this__u8e3s4, offset);
  }
  function getS32(_this__u8e3s4, offset, littleEndian) {
    return littleEndian ? getS32LE(_this__u8e3s4, offset) : getS32BE(_this__u8e3s4, offset);
  }
  function u8(_this__u8e3s4, offset) {
    return _this__u8e3s4[offset] & 255;
  }
  function u8$accessor$1maot03(_this__u8e3s4, offset) {
    return u8(_this__u8e3s4, offset);
  }
  function FixedSizeByteArrayBuilder_init_$Init$(size, $this) {
    FixedSizeByteArrayBuilder.call($this, new Int8Array(size));
    return $this;
  }
  function FixedSizeByteArrayBuilder_init_$Create$(size) {
    return FixedSizeByteArrayBuilder_init_$Init$(size, objectCreate(protoOf(FixedSizeByteArrayBuilder)));
  }
  function FixedSizeByteArrayBuilder(data) {
    this.v1c_1 = data;
    this.w1c_1 = 0;
  }
  protoOf(FixedSizeByteArrayBuilder).l1 = function () {
    this.w1c_1 = 0;
  };
  protoOf(FixedSizeByteArrayBuilder).r1c = function (array, offset, len) {
    arraycopy_1(array, offset, this.v1c_1, this.w1c_1, len);
    this.w1c_1 = this.w1c_1 + len | 0;
  };
  protoOf(FixedSizeByteArrayBuilder).t1c = function (v) {
    var tmp1 = this.w1c_1;
    this.w1c_1 = tmp1 + 1 | 0;
    this.v1c_1[tmp1] = v;
  };
  function _ByteUnits___init__impl__x795am(bytes) {
    return bytes;
  }
  function _ByteUnits___get_bytes__impl__leukoc($this) {
    return $this;
  }
  function _ByteUnits___get_bytesLong__impl__1lc1w0($this) {
    return numberToLong(_ByteUnits___get_bytes__impl__leukoc($this));
  }
  function _ByteUnits___get_kiloBytes__impl__jnym6r($this) {
    return _ByteUnits___get_bytes__impl__leukoc($this) / 1024.0;
  }
  function _ByteUnits___get_megaBytes__impl__qjjio2($this) {
    return _ByteUnits___get_bytes__impl__leukoc($this) / 1048576.0;
  }
  function _ByteUnits___get_gigaBytes__impl__i7s8s8($this) {
    return _ByteUnits___get_bytes__impl__leukoc($this) / 1.073741824E9;
  }
  function Companion_0() {
    Companion_instance_1 = this;
    this.x1c_1 = _ByteUnits___init__impl__x795am(0.0);
  }
  protoOf(Companion_0).y1c = function (bytes) {
    return _ByteUnits___init__impl__x795am(bytes);
  };
  protoOf(Companion_0).z1c = function (bytes) {
    return _ByteUnits___init__impl__x795am(bytes.k6());
  };
  var Companion_instance_1;
  function Companion_getInstance_0() {
    if (Companion_instance_1 == null)
      new Companion_0();
    return Companion_instance_1;
  }
  function roundToDigits(_this__u8e3s4, $this, digits) {
    // Inline function 'kotlin.math.pow' call
    var num = Math.pow(10.0, digits);
    return roundToInt(_this__u8e3s4 * num) / num;
  }
  function ByteUnits__toString_impl_ox2ku2($this) {
    return _ByteUnits___get_gigaBytes__impl__i7s8s8($this) >= 1.0 ? '' + roundToDigits(_ByteUnits___get_gigaBytes__impl__i7s8s8($this), $this, 1) + ' GB' : _ByteUnits___get_megaBytes__impl__qjjio2($this) >= 1.0 ? '' + roundToDigits(_ByteUnits___get_megaBytes__impl__qjjio2($this), $this, 1) + ' MB' : _ByteUnits___get_kiloBytes__impl__jnym6r($this) >= 1.0 ? '' + roundToDigits(_ByteUnits___get_kiloBytes__impl__jnym6r($this), $this, 1) + ' KB' : _ByteUnits___get_bytesLong__impl__1lc1w0($this).toString() + ' B';
  }
  function ByteUnits__hashCode_impl_35wo17($this) {
    return getNumberHashCode($this);
  }
  function ByteUnits__equals_impl_y9yptl($this, other) {
    if (!(other instanceof ByteUnits))
      return false;
    var tmp0_other_with_cast = other instanceof ByteUnits ? other.a1d_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function ByteUnits(bytes) {
    Companion_getInstance_0();
    this.a1d_1 = bytes;
  }
  protoOf(ByteUnits).toString = function () {
    return ByteUnits__toString_impl_ox2ku2(this.a1d_1);
  };
  protoOf(ByteUnits).hashCode = function () {
    return ByteUnits__hashCode_impl_35wo17(this.a1d_1);
  };
  protoOf(ByteUnits).equals = function (other) {
    return ByteUnits__equals_impl_y9yptl(this.a1d_1, other);
  };
  function Buffer_init_$Init$(size, direct, $this) {
    direct = direct === VOID ? false : direct;
    Buffer.call($this, new DataView(new ArrayBuffer(checkNBufferSize(size))));
    return $this;
  }
  function Buffer_init_$Create$(size, direct) {
    return Buffer_init_$Init$(size, direct, objectCreate(protoOf(Buffer)));
  }
  function Companion_1() {
  }
  protoOf(Companion_1).q1b = function (src, srcPosBytes, dst, dstPosBytes, sizeInBytes) {
    dst.b1d(dstPosBytes, sizeInBytes).set(src.b1d(srcPosBytes, sizeInBytes), 0);
  };
  protoOf(Companion_1).r1b = function (src, srcPosBytes, dst, dstPosBytes, sizeInBytes) {
    return equalsCommon_0(this, src, srcPosBytes, dst, dstPosBytes, sizeInBytes, false);
  };
  var Companion_instance_2;
  function Companion_getInstance_1() {
    return Companion_instance_2;
  }
  function Buffer(dataView) {
    this.s1b_1 = dataView;
  }
  protoOf(Buffer).c1d = function () {
    return this.s1b_1.byteOffset;
  };
  protoOf(Buffer).y1b = function () {
    return this.s1b_1.byteLength;
  };
  protoOf(Buffer).h1c = function (start, end) {
    return new Buffer(new DataView(get_buffer(this), this.c1d() + start | 0, end - start | 0));
  };
  protoOf(Buffer).b1d = function (offset, size) {
    return new Uint8Array(get_buffer(this), this.s1b_1.byteOffset + offset | 0, size);
  };
  protoOf(Buffer).t1b = function (bufferOffset, array, arrayOffset, len, toArray) {
    var bufferSlice = new Int8Array(this.s1b_1.buffer, this.s1b_1.byteOffset + bufferOffset | 0, len);
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var arraySlice = array.subarray(arrayOffset, arrayOffset + len | 0);
    if (toArray) {
      arraySlice.set(bufferSlice, 0);
    } else {
      bufferSlice.set(arraySlice, 0);
    }
  };
  protoOf(Buffer).i1c = function (byteOffset) {
    return this.s1b_1.getInt8(byteOffset);
  };
  protoOf(Buffer).l1c = function (byteOffset) {
    return this.s1b_1.getInt16(byteOffset, true);
  };
  protoOf(Buffer).a1c = function (byteOffset) {
    return this.s1b_1.getInt32(byteOffset, true);
  };
  protoOf(Buffer).k1c = function (byteOffset) {
    var v0 = toLong(this.a1c(byteOffset)).dc(new Long(-1, 0));
    var v1 = toLong(this.a1c(byteOffset + 4 | 0)).dc(new Long(-1, 0));
    return Companion_instance.nz() ? v1.ac(32).ec(v0) : v0.ac(32).ec(v1);
  };
  protoOf(Buffer).v1b = function (byteOffset) {
    return this.s1b_1.getFloat32(byteOffset, true);
  };
  protoOf(Buffer).m1c = function (byteOffset) {
    return this.s1b_1.getInt16(byteOffset, false);
  };
  protoOf(Buffer).u1b = function (byteOffset) {
    return this.s1b_1.getFloat32(byteOffset, false);
  };
  protoOf(Buffer).j1c = function (byteOffset, value) {
    return this.s1b_1.setInt8(byteOffset, value);
  };
  protoOf(Buffer).d1c = function (byteOffset, value) {
    return this.s1b_1.setInt16(byteOffset, value, true);
  };
  protoOf(Buffer).z1b = function (byteOffset, value) {
    return this.s1b_1.setInt32(byteOffset, value, true);
  };
  protoOf(Buffer).x1b = function (byteOffset, value) {
    return this.s1b_1.setFloat32(byteOffset, value, true);
  };
  protoOf(Buffer).c1c = function (byteOffset, value) {
    return this.s1b_1.setInt16(byteOffset, value, false);
  };
  protoOf(Buffer).n1c = function (byteOffset, value) {
    return this.s1b_1.setInt32(byteOffset, value, false);
  };
  protoOf(Buffer).w1b = function (byteOffset, value) {
    return this.s1b_1.setFloat32(byteOffset, value, false);
  };
  protoOf(Buffer).hashCode = function () {
    return hashCodeCommon(Companion_instance_2, this);
  };
  protoOf(Buffer).equals = function (other) {
    return equalsCommon(Companion_instance_2, this, other);
  };
  protoOf(Buffer).toString = function () {
    return NBuffer_toString(this);
  };
  function asInt32Array(_this__u8e3s4) {
    return new Int32Array(_this__u8e3s4);
  }
  function get_arrayUByte(_this__u8e3s4) {
    return new Uint8Array(get_buffer(_this__u8e3s4), _this__u8e3s4.c1d(), _this__u8e3s4.y1b());
  }
  function get_arrayInt(_this__u8e3s4) {
    return new Int32Array(get_buffer(_this__u8e3s4), _this__u8e3s4.c1d(), _this__u8e3s4.y1b() / 4 | 0);
  }
  function get_arrayFloat(_this__u8e3s4) {
    return new Float32Array(get_buffer(_this__u8e3s4), _this__u8e3s4.c1d(), _this__u8e3s4.y1b() / 4 | 0);
  }
  function get_buffer(_this__u8e3s4) {
    return _this__u8e3s4.s1b_1.buffer;
  }
  //region block: post-declaration
  protoOf(Uint16Buffer).l = get_size;
  //endregion
  //region block: init
  Companion_instance_0 = new Companion();
  Companion_instance_2 = new Companion_1();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Buffer_init_$Create$;
  _.$_$.b = ByteArrayBuilder_init_$Create$;
  _.$_$.c = FixedSizeByteArrayBuilder_init_$Create$;
  _.$_$.d = _Float32Buffer___init__impl__403k2m_0;
  _.$_$.e = _Float32Buffer___get_buffer__impl__x5nu9x;
  _.$_$.f = Float32Buffer__get_impl_659lhu;
  _.$_$.g = Float32Buffer__set_impl_34szne;
  _.$_$.h = _Int8Buffer___init__impl__e20lvc_0;
  _.$_$.i = _Int8Buffer___get_buffer__impl__q0gulb;
  _.$_$.j = _UByteArrayInt___init__impl__xif7bu;
  _.$_$.k = _UByteArrayInt___init__impl__xif7bu_0;
  _.$_$.l = UByteArrayInt__get_impl_px6q8a;
  _.$_$.m = UByteArrayInt__set_impl_gn4532;
  _.$_$.n = _UByteArrayInt___get_size__impl__bcjnte;
  _.$_$.o = _UShortArrayInt___init__impl__kdofb8_0;
  _.$_$.p = UShortArrayInt__get_impl_ciz20k;
  _.$_$.q = UShortArrayInt__set_impl_38wgvc;
  _.$_$.r = Uint16Buffer__set_impl_g0kliu;
  _.$_$.s = Companion_getInstance_0;
  _.$_$.t = Companion_instance_2;
  _.$_$.u = ByteUnits;
  _.$_$.v = ByteArrayBuilder;
  _.$_$.w = UByteArrayInt;
  _.$_$.x = allocDirect;
  _.$_$.y = allocNoDirect;
  _.$_$.z = get_arrayFloat;
  _.$_$.a1 = get_arrayInt;
  _.$_$.b1 = get_arrayUByte;
  _.$_$.c1 = arrayadd;
  _.$_$.d1 = arraycopy_1;
  _.$_$.e1 = arraycopy_0;
  _.$_$.f1 = arraycopy;
  _.$_$.g1 = arraycopy_6;
  _.$_$.h1 = arraycopy_4;
  _.$_$.i1 = arraycopy_8;
  _.$_$.j1 = arraycopy_7;
  _.$_$.k1 = arraycopy_2;
  _.$_$.l1 = arraycopy_3;
  _.$_$.m1 = arraycopy_5;
  _.$_$.n1 = arrayequal;
  _.$_$.o1 = arrayfill_0;
  _.$_$.p1 = arrayfill;
  _.$_$.q1 = asByteArray;
  _.$_$.r1 = asInt32Array;
  _.$_$.s1 = asUShortArrayInt;
  _.$_$.t1 = clone;
  _.$_$.u1 = copyOf_0;
  _.$_$.v1 = extractScaledf01;
  _.$_$.w1 = extractSigned;
  _.$_$.x1 = extract;
  _.$_$.y1 = get_f32;
  _.$_$.z1 = fromLowHigh;
  _.$_$.a2 = getInt32;
  _.$_$.b2 = getInt8;
  _.$_$.c2 = getS16ArrayBE;
  _.$_$.d2 = getS16BE;
  _.$_$.e2 = getS16;
  _.$_$.f2 = getS32ArrayBE;
  _.$_$.g2 = getS32ArrayLE;
  _.$_$.h2 = getS32BE;
  _.$_$.i2 = getS32LE;
  _.$_$.j2 = getS32;
  _.$_$.k2 = getS64BE;
  _.$_$.l2 = getS8Array;
  _.$_$.m2 = getS8;
  _.$_$.n2 = getU16ArrayBE;
  _.$_$.o2 = getU16BE_0;
  _.$_$.p2 = getU16LE_0;
  _.$_$.q2 = getU16;
  _.$_$.r2 = getU24BE;
  _.$_$.s2 = getU24LE;
  _.$_$.t2 = getU8;
  _.$_$.u2 = get_high;
  _.$_$.v2 = get_i32;
  _.$_$.w2 = get_i8;
  _.$_$.x2 = insert14;
  _.$_$.y2 = insert16;
  _.$_$.z2 = insert2;
  _.$_$.a3 = insert3;
  _.$_$.b3 = insert4;
  _.$_$.c3 = insert5;
  _.$_$.d3 = insert8;
  _.$_$.e3 = insertScaledf01;
  _.$_$.f3 = insert;
  _.$_$.g3 = insert_0;
  _.$_$.h3 = get_low;
  _.$_$.i3 = setBits;
  _.$_$.j3 = setFloat32;
  _.$_$.k3 = setInt32;
  _.$_$.l3 = setInt8;
  _.$_$.m3 = get_size_0;
  _.$_$.n3 = sliceBuffer;
  _.$_$.o3 = sliceWithSize;
  _.$_$.p3 = get_u16;
  //endregion
  return _;
}));
