(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-logger'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'korge-root-korlibs-logger'.");
    }
    root['korge-root-korlibs-logger'] = factory(typeof this['korge-root-korlibs-logger'] === 'undefined' ? {} : this['korge-root-korlibs-logger'], this['kotlin-kotlin-stdlib']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var Unit_instance = kotlin_kotlin.$_$.g3;
  var Enum = kotlin_kotlin.$_$.ed;
  var protoOf = kotlin_kotlin.$_$.y9;
  var classMeta = kotlin_kotlin.$_$.t8;
  var setMetadataFor = kotlin_kotlin.$_$.z9;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var VOID = kotlin_kotlin.$_$.d;
  var interfaceMeta = kotlin_kotlin.$_$.g9;
  var println = kotlin_kotlin.$_$.l8;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.z;
  var joinTo = kotlin_kotlin.$_$.n5;
  var replace = kotlin_kotlin.$_$.yb;
  var mapCapacity = kotlin_kotlin.$_$.y5;
  var coerceAtLeast = kotlin_kotlin.$_$.ma;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.q;
  var objectMeta = kotlin_kotlin.$_$.x9;
  var THROW_IAE = kotlin_kotlin.$_$.nd;
  var HashMap_init_$Create$ = kotlin_kotlin.$_$.n;
  var ensureNotNull = kotlin_kotlin.$_$.td;
  var to = kotlin_kotlin.$_$.fe;
  var LinkedHashMap_init_$Create$_0 = kotlin_kotlin.$_$.r;
  var charArrayOf = kotlin_kotlin.$_$.p8;
  var split = kotlin_kotlin.$_$.zb;
  var get_lastIndex = kotlin_kotlin.$_$.p5;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.m;
  var THROW_CCE = kotlin_kotlin.$_$.md;
  var isArray = kotlin_kotlin.$_$.h9;
  var fillArrayVal = kotlin_kotlin.$_$.x8;
  var emptyMap = kotlin_kotlin.$_$.v4;
  var trimStart = kotlin_kotlin.$_$.uc;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.l;
  var firstOrNull = kotlin_kotlin.$_$.b5;
  var toMap = kotlin_kotlin.$_$.c7;
  var KProperty0 = kotlin_kotlin.$_$.ab;
  var getPropertyCallableRef = kotlin_kotlin.$_$.c9;
  var lazy = kotlin_kotlin.$_$.xd;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Color, 'Color', classMeta, Enum);
  function appendAnsiScape(_this__u8e3s4, code, extra, char) {
    _this__u8e3s4.j5(_Char___init__impl__6a9atx(27));
    _this__u8e3s4.j5(_Char___init__impl__6a9atx(91));
    _this__u8e3s4.t7(code);
    if (!(extra == null)) {
      _this__u8e3s4.i5(extra);
    }
    _this__u8e3s4.j5(char);
    return _this__u8e3s4;
  }
  function appendAnsiScape$default(_this__u8e3s4, code, extra, char, $super) {
    extra = extra === VOID ? null : extra;
    char = char === VOID ? _Char___init__impl__6a9atx(109) : char;
    return $super === VOID ? this.s19(_this__u8e3s4, code, extra, char) : appendAnsiScape(_this__u8e3s4, code, extra, char);
  }
  function appendReset(_this__u8e3s4) {
    return this.t19(_this__u8e3s4, 0);
  }
  function appendFgColor(_this__u8e3s4, color, bright) {
    return this.t19(_this__u8e3s4, 30 + color.r19_1 | 0, bright ? ';1' : null);
  }
  function appendFgColor$default(_this__u8e3s4, color, bright, $super) {
    bright = bright === VOID ? false : bright;
    return $super === VOID ? this.v19(_this__u8e3s4, color, bright) : appendFgColor(_this__u8e3s4, color, bright);
  }
  setMetadataFor(AnsiEscape, 'AnsiEscape', interfaceMeta);
  setMetadataFor(Kind, 'Kind', classMeta, Enum);
  setMetadataFor(BaseConsole, 'BaseConsole', classMeta, VOID, [AnsiEscape], BaseConsole);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(Level, 'Level', classMeta, Enum);
  setMetadataFor(ConsoleLogOutput, 'ConsoleLogOutput', objectMeta);
  setMetadataFor(Logger, 'Logger', classMeta);
  setMetadataFor(Console, 'Console', objectMeta, BaseConsole);
  setMetadataFor(DefaultLogOutput, 'DefaultLogOutput', objectMeta);
  //endregion
  var Color_BLACK_instance;
  var Color_RED_instance;
  var Color_GREEN_instance;
  var Color_YELLOW_instance;
  var Color_BLUE_instance;
  var Color_PURPLE_instance;
  var Color_CYAN_instance;
  var Color_WHITE_instance;
  var Color_entriesInitialized;
  function Color_initEntries() {
    if (Color_entriesInitialized)
      return Unit_instance;
    Color_entriesInitialized = true;
    Color_BLACK_instance = new Color('BLACK', 0, 0);
    Color_RED_instance = new Color('RED', 1, 1);
    Color_GREEN_instance = new Color('GREEN', 2, 2);
    Color_YELLOW_instance = new Color('YELLOW', 3, 3);
    Color_BLUE_instance = new Color('BLUE', 4, 4);
    Color_PURPLE_instance = new Color('PURPLE', 5, 5);
    Color_CYAN_instance = new Color('CYAN', 6, 6);
    Color_WHITE_instance = new Color('WHITE', 7, 7);
  }
  function Color(name, ordinal, index) {
    Enum.call(this, name, ordinal);
    this.r19_1 = index;
  }
  function Color_RED_getInstance() {
    Color_initEntries();
    return Color_RED_instance;
  }
  function Color_GREEN_getInstance() {
    Color_initEntries();
    return Color_GREEN_instance;
  }
  function Color_YELLOW_getInstance() {
    Color_initEntries();
    return Color_YELLOW_instance;
  }
  function Color_BLUE_getInstance() {
    Color_initEntries();
    return Color_BLUE_instance;
  }
  function Color_CYAN_getInstance() {
    Color_initEntries();
    return Color_CYAN_instance;
  }
  function AnsiEscape() {
  }
  var baseConsoleHook;
  var Kind_ERROR_instance;
  var Kind_WARN_instance;
  var Kind_INFO_instance;
  var Kind_DEBUG_instance;
  var Kind_TRACE_instance;
  var Kind_LOG_instance;
  var Kind_entriesInitialized;
  function Kind_initEntries() {
    if (Kind_entriesInitialized)
      return Unit_instance;
    Kind_entriesInitialized = true;
    Kind_ERROR_instance = new Kind('ERROR', 0, 0, Color_RED_getInstance());
    Kind_WARN_instance = new Kind('WARN', 1, 1, Color_YELLOW_getInstance());
    Kind_INFO_instance = new Kind('INFO', 2, 2, Color_BLUE_getInstance());
    Kind_DEBUG_instance = new Kind('DEBUG', 3, 3, Color_CYAN_getInstance());
    Kind_TRACE_instance = new Kind('TRACE', 4, 4, Color_GREEN_getInstance());
    Kind_LOG_instance = new Kind('LOG', 5, 5, null);
  }
  function Kind(name, ordinal, level, color) {
    Enum.call(this, name, ordinal);
    this.z19_1 = level;
    this.a1a_1 = color;
  }
  function BaseConsole$logInternal$ref($boundThis) {
    var l = function (p0, p1) {
      $boundThis.b1a(p0, p1);
      return Unit_instance;
    };
    l.callableName = 'logInternal';
    return l;
  }
  function Kind_ERROR_getInstance() {
    Kind_initEntries();
    return Kind_ERROR_instance;
  }
  function Kind_WARN_getInstance() {
    Kind_initEntries();
    return Kind_WARN_instance;
  }
  function Kind_LOG_getInstance() {
    Kind_initEntries();
    return Kind_LOG_instance;
  }
  function BaseConsole() {
  }
  protoOf(BaseConsole).c1a = function (kind, msg) {
    var hook = baseConsoleHook;
    if (!(hook == null)) {
      hook(kind, msg, BaseConsole$logInternal$ref(this));
    } else {
      this.b1a(kind, msg.slice());
    }
  };
  protoOf(BaseConsole).b1a = function (kind, msg) {
    println(this.d1a(kind, msg.slice()));
  };
  protoOf(BaseConsole).d1a = function (kind, msg) {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.logger.BaseConsole.logToString.<anonymous>' call
    var color = kind.a1a_1;
    if (!(color == null)) {
      this.w19(this_0, color);
    }
    joinTo(msg, this_0, ', ');
    if (!(color == null)) {
      this.u19(this_0);
    }
    return this_0.toString();
  };
  protoOf(BaseConsole).e1a = function (msg) {
    return this.c1a(Kind_LOG_getInstance(), msg.slice());
  };
  protoOf(BaseConsole).f1a = function (msg) {
    return this.c1a(Kind_WARN_getInstance(), msg.slice());
  };
  protoOf(BaseConsole).g1a = function (msg) {
    return this.c1a(Kind_ERROR_getInstance(), msg.slice());
  };
  var _miniEnvironmentVariablesUC;
  function normalizeName($this, name) {
    // Inline function 'kotlin.text.uppercase' call
    // Inline function 'kotlin.js.asDynamic' call
    return replace(replace(name, _Char___init__impl__6a9atx(46), _Char___init__impl__6a9atx(95)), _Char___init__impl__6a9atx(47), _Char___init__impl__6a9atx(95)).toUpperCase();
  }
  var Level_NONE_instance;
  var Level_FATAL_instance;
  var Level_ERROR_instance;
  var Level_WARN_instance;
  var Level_INFO_instance;
  var Level_DEBUG_instance;
  var Level_TRACE_instance;
  function Companion() {
    Companion_instance = this;
    var tmp = this;
    // Inline function 'kotlin.collections.associateBy' call
    var this_0 = values();
    var capacity = coerceAtLeast(mapCapacity(this_0.length), 16);
    // Inline function 'kotlin.collections.associateByTo' call
    var destination = LinkedHashMap_init_$Create$(capacity);
    var inductionVariable = 0;
    var last = this_0.length;
    while (inductionVariable < last) {
      var element = this_0[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'korlibs.logger.Companion.BY_NAME.<anonymous>' call
      var tmp$ret$0 = element.s9_1;
      destination.a2(tmp$ret$0, element);
    }
    tmp.h1a_1 = destination;
  }
  protoOf(Companion).t12 = function (name) {
    // Inline function 'kotlin.text.uppercase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = name.toUpperCase();
    var tmp0_elvis_lhs = this.h1a_1.k2(tmp$ret$1);
    return tmp0_elvis_lhs == null ? Level_NONE_getInstance() : tmp0_elvis_lhs;
  };
  var Companion_instance;
  function Companion_getInstance() {
    Level_initEntries();
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function values() {
    return [Level_NONE_getInstance(), Level_FATAL_getInstance(), Level_ERROR_getInstance(), Level_WARN_getInstance(), Level_INFO_getInstance(), Level_DEBUG_getInstance(), Level_TRACE_getInstance()];
  }
  function valueOf(value) {
    switch (value) {
      case 'NONE':
        return Level_NONE_getInstance();
      case 'FATAL':
        return Level_FATAL_getInstance();
      case 'ERROR':
        return Level_ERROR_getInstance();
      case 'WARN':
        return Level_WARN_getInstance();
      case 'INFO':
        return Level_INFO_getInstance();
      case 'DEBUG':
        return Level_DEBUG_getInstance();
      case 'TRACE':
        return Level_TRACE_getInstance();
      default:
        Level_initEntries();
        THROW_IAE('No enum constant value.');
        break;
    }
  }
  var Level_entriesInitialized;
  function Level_initEntries() {
    if (Level_entriesInitialized)
      return Unit_instance;
    Level_entriesInitialized = true;
    Level_NONE_instance = new Level('NONE', 0, 0);
    Level_FATAL_instance = new Level('FATAL', 1, 1);
    Level_ERROR_instance = new Level('ERROR', 2, 2);
    Level_WARN_instance = new Level('WARN', 3, 3);
    Level_INFO_instance = new Level('INFO', 4, 4);
    Level_DEBUG_instance = new Level('DEBUG', 5, 5);
    Level_TRACE_instance = new Level('TRACE', 6, 6);
    Companion_getInstance();
  }
  function Companion_0() {
    Companion_instance_0 = this;
    this.i1a_1 = new Object();
    this.j1a_1 = HashMap_init_$Create$();
    this.k1a_1 = null;
    this.l1a_1 = DefaultLogOutput_instance;
  }
  protoOf(Companion_0).m1a = function (name) {
    // Inline function 'kotlinx.atomicfu.locks.synchronized' call
    this.i1a_1;
    var normalizedName = normalizeName(Companion_getInstance_0(), name);
    if (Companion_getInstance_0().j1a_1.k2(normalizedName) == null) {
      var logger = new Logger(name, normalizedName, true);
      var tmp0_safe_receiver = get_miniEnvironmentVariablesUC().k2('LOG_' + normalizedName);
      if (tmp0_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.logger.Companion.invoke.<anonymous>.<anonymous>' call
        logger.t1a(Companion_getInstance().t12(tmp0_safe_receiver));
      }
      if (Companion_getInstance_0().j1a_1.x()) {
        var tmp1_safe_receiver = get_miniEnvironmentVariablesUC().k2('LOG_LEVEL');
        if (tmp1_safe_receiver == null)
          null;
        else {
          // Inline function 'kotlin.also' call
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.logger.Companion.invoke.<anonymous>.<anonymous>' call
          Companion_getInstance_0().k1a_1 = Companion_getInstance().t12(tmp1_safe_receiver);
        }
      }
      // Inline function 'kotlin.collections.set' call
      Companion_getInstance_0().j1a_1.a2(normalizedName, logger);
    }
    return ensureNotNull(Companion_getInstance_0().j1a_1.k2(normalizedName));
  };
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function Level(name, ordinal, index) {
    Enum.call(this, name, ordinal);
    this.w1a_1 = index;
  }
  function ConsoleLogOutput() {
  }
  protoOf(ConsoleLogOutput).x1a = function (logger, level, msg) {
    switch (level.t9_1) {
      case 2:
        Console_getInstance().g1a([logger.n1a_1, msg]);
        break;
      case 3:
        Console_getInstance().f1a([logger.n1a_1, msg]);
        break;
      default:
        Console_getInstance().e1a([logger.n1a_1, msg]);
        break;
    }
  };
  var ConsoleLogOutput_instance;
  function ConsoleLogOutput_getInstance() {
    return ConsoleLogOutput_instance;
  }
  function Level_NONE_getInstance() {
    Level_initEntries();
    return Level_NONE_instance;
  }
  function Level_FATAL_getInstance() {
    Level_initEntries();
    return Level_FATAL_instance;
  }
  function Level_ERROR_getInstance() {
    Level_initEntries();
    return Level_ERROR_instance;
  }
  function Level_WARN_getInstance() {
    Level_initEntries();
    return Level_WARN_instance;
  }
  function Level_INFO_getInstance() {
    Level_initEntries();
    return Level_INFO_instance;
  }
  function Level_DEBUG_getInstance() {
    Level_initEntries();
    return Level_DEBUG_instance;
  }
  function Level_TRACE_getInstance() {
    Level_initEntries();
    return Level_TRACE_instance;
  }
  function Logger(name, normalizedName, dummy) {
    Companion_getInstance_0();
    this.n1a_1 = name;
    this.o1a_1 = normalizedName;
    this.p1a_1 = dummy;
    this.q1a_1 = null;
    this.r1a_1 = null;
    this.s1a_1 = null;
  }
  protoOf(Logger).t1a = function (value) {
    this.r1a_1 = value;
  };
  protoOf(Logger).y1a = function () {
    var tmp0_elvis_lhs = this.r1a_1;
    var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? Companion_getInstance_0().k1a_1 : tmp0_elvis_lhs;
    return tmp1_elvis_lhs == null ? Level_WARN_getInstance() : tmp1_elvis_lhs;
  };
  protoOf(Logger).z1a = function () {
    var tmp0_elvis_lhs = this.s1a_1;
    return tmp0_elvis_lhs == null ? Companion_getInstance_0().l1a_1 : tmp0_elvis_lhs;
  };
  protoOf(Logger).a1b = function (level) {
    return level.w1a_1 <= this.y1a().w1a_1;
  };
  protoOf(Logger).b1b = function (level, msg) {
    this.z1a().x1a(this, level, msg);
  };
  function get_miniEnvironmentVariablesUC() {
    if (_miniEnvironmentVariablesUC == null) {
      // Inline function 'kotlin.collections.mapKeys' call
      var this_0 = get_miniEnvironmentVariables();
      // Inline function 'kotlin.collections.mapKeysTo' call
      var destination = LinkedHashMap_init_$Create$(mapCapacity(this_0.l()));
      // Inline function 'kotlin.collections.associateByTo' call
      var tmp0_iterator = this_0.c2().s();
      while (tmp0_iterator.t()) {
        var element = tmp0_iterator.u();
        // Inline function 'korlibs.logger.<get-miniEnvironmentVariablesUC>.<anonymous>' call
        // Inline function 'kotlin.text.uppercase' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp = element.d2().toUpperCase();
        // Inline function 'kotlin.collections.mapKeysTo.<anonymous>' call
        var tmp$ret$3 = element.e2();
        destination.a2(tmp, tmp$ret$3);
      }
      _miniEnvironmentVariablesUC = destination;
    }
    return ensureNotNull(_miniEnvironmentVariablesUC);
  }
  function get_isDenoJs() {
    _init_properties_Logger_js_kt__qad3pn();
    // Inline function 'kotlin.getValue' call
    var this_0 = isDenoJs$delegate;
    isDenoJs$factory();
    return this_0.e2();
  }
  var isDenoJs$delegate;
  function get_isNodeJs() {
    _init_properties_Logger_js_kt__qad3pn();
    // Inline function 'kotlin.getValue' call
    var this_0 = isNodeJs$delegate;
    isNodeJs$factory();
    return this_0.e2();
  }
  var isNodeJs$delegate;
  function get_miniEnvironmentVariables() {
    _init_properties_Logger_js_kt__qad3pn();
    // Inline function 'kotlin.getValue' call
    var this_0 = miniEnvironmentVariables$delegate;
    miniEnvironmentVariables$factory();
    return this_0.e2();
  }
  var miniEnvironmentVariables$delegate;
  function jsObjectToMap(obj) {
    _init_properties_Logger_js_kt__qad3pn();
    // Inline function 'kotlin.collections.associate' call
    var this_0 = jsObjectKeysArray(obj);
    var capacity = coerceAtLeast(mapCapacity(this_0.length), 16);
    // Inline function 'kotlin.collections.associateTo' call
    var destination = LinkedHashMap_init_$Create$(capacity);
    var inductionVariable = 0;
    var last = this_0.length;
    while (inductionVariable < last) {
      var element = this_0[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.collections.plusAssign' call
      // Inline function 'korlibs.logger.jsObjectToMap.<anonymous>' call
      var pair = to(element, obj[element]);
      destination.a2(pair.ze_1, pair.af_1);
    }
    return destination;
  }
  function QueryString_decode(str) {
    _init_properties_Logger_js_kt__qad3pn();
    // Inline function 'kotlin.collections.linkedMapOf' call
    var out = LinkedHashMap_init_$Create$_0();
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = split(str, charArrayOf([_Char___init__impl__6a9atx(38)])).s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      // Inline function 'korlibs.logger.QueryString_decode.<anonymous>' call
      var parts = split(element, charArrayOf([_Char___init__impl__6a9atx(61)]), VOID, 2);
      var key = parts.b1(0);
      // Inline function 'kotlin.collections.getOrElse' call
      var tmp;
      if (1 >= 0 ? 1 <= get_lastIndex(parts) : false) {
        tmp = parts.b1(1);
      } else {
        // Inline function 'korlibs.logger.QueryString_decode.<anonymous>.<anonymous>' call
        tmp = key;
      }
      var value = tmp;
      // Inline function 'kotlin.collections.getOrPut' call
      var value_0 = out.k2(key);
      var tmp_0;
      if (value_0 == null) {
        // Inline function 'korlibs.logger.QueryString_decode.<anonymous>.<anonymous>' call
        // Inline function 'kotlin.collections.arrayListOf' call
        var answer = ArrayList_init_$Create$();
        out.a2(key, answer);
        tmp_0 = answer;
      } else {
        tmp_0 = value_0;
      }
      var list = tmp_0;
      // Inline function 'kotlin.collections.plusAssign' call
      list.p(value);
    }
    return out;
  }
  function jsObjectKeysArray(obj) {
    _init_properties_Logger_js_kt__qad3pn();
    var tmp = jsToArray(jsObjectKeys(obj));
    return isArray(tmp) ? tmp : THROW_CCE();
  }
  function jsToArray(obj) {
    _init_properties_Logger_js_kt__qad3pn();
    var tmp = 0;
    var tmp_0 = obj.length;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_1 = fillArrayVal(Array(tmp_0), null);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      tmp_1[tmp_2] = obj[tmp_2];
      tmp = tmp + 1 | 0;
    }
    return tmp_1;
  }
  function jsObjectKeys(obj) {
    _init_properties_Logger_js_kt__qad3pn();
    return Object.keys(obj);
  }
  function Console() {
    Console_instance = this;
    BaseConsole.call(this);
  }
  protoOf(Console).b1a = function (kind, msg) {
    switch (kind.t9_1) {
      case 0:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.error.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 1:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.warn.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 2:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.info.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 3:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 4:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
      case 5:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        break;
    }
  };
  var Console_instance;
  function Console_getInstance() {
    if (Console_instance == null)
      new Console();
    return Console_instance;
  }
  function DefaultLogOutput() {
  }
  protoOf(DefaultLogOutput).x1a = function (logger, level, msg) {
    return ConsoleLogOutput_instance.x1a(logger, level, msg);
  };
  var DefaultLogOutput_instance;
  function DefaultLogOutput_getInstance() {
    return DefaultLogOutput_instance;
  }
  function isDenoJs$delegate$lambda() {
    _init_properties_Logger_js_kt__qad3pn();
    // Inline function 'kotlin.js.unsafeCast' call
    return typeof Deno === 'object' && Deno.statSync;
  }
  function isNodeJs$delegate$lambda() {
    _init_properties_Logger_js_kt__qad3pn();
    // Inline function 'kotlin.js.unsafeCast' call
    return typeof process !== 'undefined' && process.release && process.release.name.search(/node|io.js/) !== -1;
  }
  function miniEnvironmentVariables$delegate$lambda() {
    _init_properties_Logger_js_kt__qad3pn();
    var tmp;
    if (get_isNodeJs()) {
      tmp = jsObjectToMap(process.env);
    } else if (get_isDenoJs()) {
      tmp = jsObjectToMap(Deno.env);
    } else if (typeof document !== 'undefined') {
      // Inline function 'kotlin.collections.map' call
      var tmp0_safe_receiver = document.location;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.search;
      var this_0 = QueryString_decode(trimStart(tmp1_elvis_lhs == null ? '' : tmp1_elvis_lhs, charArrayOf([_Char___init__impl__6a9atx(63)])));
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList_init_$Create$_0(this_0.l());
      // Inline function 'kotlin.collections.iterator' call
      var tmp0_iterator = this_0.c2().s();
      while (tmp0_iterator.t()) {
        var item = tmp0_iterator.u();
        // Inline function 'korlibs.logger.miniEnvironmentVariables$delegate.<anonymous>.<anonymous>' call
        var tmp_0 = item.d2();
        var tmp0_elvis_lhs = firstOrNull(item.e2());
        var tmp$ret$1 = to(tmp_0, tmp0_elvis_lhs == null ? item.d2() : tmp0_elvis_lhs);
        destination.p(tmp$ret$1);
      }
      tmp = toMap(destination);
    } else {
      // Inline function 'kotlin.collections.mapOf' call
      tmp = emptyMap();
    }
    return tmp;
  }
  function isDenoJs$factory() {
    return getPropertyCallableRef('isDenoJs', 0, KProperty0, function () {
      return get_isDenoJs();
    }, null);
  }
  function isNodeJs$factory() {
    return getPropertyCallableRef('isNodeJs', 0, KProperty0, function () {
      return get_isNodeJs();
    }, null);
  }
  function miniEnvironmentVariables$factory() {
    return getPropertyCallableRef('miniEnvironmentVariables', 0, KProperty0, function () {
      return get_miniEnvironmentVariables();
    }, null);
  }
  var properties_initialized_Logger_js_kt_6fk7m1;
  function _init_properties_Logger_js_kt__qad3pn() {
    if (!properties_initialized_Logger_js_kt_6fk7m1) {
      properties_initialized_Logger_js_kt_6fk7m1 = true;
      isDenoJs$delegate = lazy(isDenoJs$delegate$lambda);
      isNodeJs$delegate = lazy(isNodeJs$delegate$lambda);
      miniEnvironmentVariables$delegate = lazy(miniEnvironmentVariables$delegate$lambda);
    }
  }
  //region block: post-declaration
  protoOf(BaseConsole).s19 = appendAnsiScape;
  protoOf(BaseConsole).t19 = appendAnsiScape$default;
  protoOf(BaseConsole).u19 = appendReset;
  protoOf(BaseConsole).v19 = appendFgColor;
  protoOf(BaseConsole).w19 = appendFgColor$default;
  //endregion
  //region block: init
  baseConsoleHook = null;
  _miniEnvironmentVariablesUC = null;
  ConsoleLogOutput_instance = new ConsoleLogOutput();
  DefaultLogOutput_instance = new DefaultLogOutput();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Level_DEBUG_getInstance;
  _.$_$.b = Level_ERROR_getInstance;
  _.$_$.c = Level_INFO_getInstance;
  _.$_$.d = Level_TRACE_getInstance;
  _.$_$.e = Level_WARN_getInstance;
  _.$_$.f = Console_getInstance;
  _.$_$.g = Companion_getInstance_0;
  _.$_$.h = valueOf;
  //endregion
  return _;
}));
