(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js', './korge-root-korge.js', './korge-root-korge-foundation.js', './korge-root-korge-core.js', './korge-root-korlibs-time.js', './korge-root-korlibs-logger.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'), require('./korge-root-korge.js'), require('./korge-root-korge-foundation.js'), require('./korge-root-korge-core.js'), require('./korge-root-korlibs-time.js'), require('./korge-root-korlibs-logger.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'korge-hello-world'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'korge-hello-world'.");
    }
    if (typeof this['korge-root-korge'] === 'undefined') {
      throw new Error("Error loading module 'korge-hello-world'. Its dependency 'korge-root-korge' was not found. Please, check whether 'korge-root-korge' is loaded prior to 'korge-hello-world'.");
    }
    if (typeof this['korge-root-korge-foundation'] === 'undefined') {
      throw new Error("Error loading module 'korge-hello-world'. Its dependency 'korge-root-korge-foundation' was not found. Please, check whether 'korge-root-korge-foundation' is loaded prior to 'korge-hello-world'.");
    }
    if (typeof this['korge-root-korge-core'] === 'undefined') {
      throw new Error("Error loading module 'korge-hello-world'. Its dependency 'korge-root-korge-core' was not found. Please, check whether 'korge-root-korge-core' is loaded prior to 'korge-hello-world'.");
    }
    if (typeof this['korge-root-korlibs-time'] === 'undefined') {
      throw new Error("Error loading module 'korge-hello-world'. Its dependency 'korge-root-korlibs-time' was not found. Please, check whether 'korge-root-korlibs-time' is loaded prior to 'korge-hello-world'.");
    }
    if (typeof this['korge-root-korlibs-logger'] === 'undefined') {
      throw new Error("Error loading module 'korge-hello-world'. Its dependency 'korge-root-korlibs-logger' was not found. Please, check whether 'korge-root-korlibs-logger' is loaded prior to 'korge-hello-world'.");
    }
    root['korge-hello-world'] = factory(typeof this['korge-hello-world'] === 'undefined' ? {} : this['korge-hello-world'], this['kotlin-kotlin-stdlib'], this['korge-root-korge'], this['korge-root-korge-foundation'], this['korge-root-korge-core'], this['korge-root-korlibs-time'], this['korge-root-korlibs-logger']);
  }
}(this, function (_, kotlin_kotlin, kotlin_com_soywiz_korge_korge, kotlin_com_soywiz_korge_korge_foundation, kotlin_com_soywiz_korge_korge_core, kotlin_com_soywiz_korge_korlibs_time, kotlin_com_soywiz_korge_korlibs_logger) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var listOf = kotlin_kotlin.$_$.w5;
  var numberRangeToNumber = kotlin_kotlin.$_$.r9;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.y3;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var Unit_instance = kotlin_kotlin.$_$.g3;
  var plus = kotlin_kotlin.$_$.g6;
  var SpriteAnimation = kotlin_com_soywiz_korge_korge.$_$.n;
  var protoOf = kotlin_kotlin.$_$.y9;
  var hashCode = kotlin_kotlin.$_$.e9;
  var THROW_CCE = kotlin_kotlin.$_$.md;
  var equals = kotlin_kotlin.$_$.v8;
  var classMeta = kotlin_kotlin.$_$.t8;
  var setMetadataFor = kotlin_kotlin.$_$.z9;
  var enumEntries = kotlin_kotlin.$_$.j8;
  var Vector2I = kotlin_com_soywiz_korge_korge_foundation.$_$.x4;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var Enum = kotlin_kotlin.$_$.ed;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.yd;
  var Companion_getInstance = kotlin_com_soywiz_korge_korge_foundation.$_$.n2;
  var objectMeta = kotlin_kotlin.$_$.x9;
  var listOf_0 = kotlin_kotlin.$_$.x5;
  var VOID = kotlin_kotlin.$_$.d;
  var Companion_getInstance_0 = kotlin_kotlin.$_$.c3;
  var DurationUnit_SECONDS_getInstance = kotlin_kotlin.$_$.h;
  var toDuration = kotlin_kotlin.$_$.ad;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.m;
  var removeFirst = kotlin_kotlin.$_$.k6;
  var println = kotlin_kotlin.$_$.l8;
  var until = kotlin_kotlin.$_$.wa;
  var Default_getInstance = kotlin_kotlin.$_$.b3;
  var random = kotlin_kotlin.$_$.h6;
  var getOrNull = kotlin_kotlin.$_$.h5;
  var toMutableList = kotlin_kotlin.$_$.d7;
  var random_0 = kotlin_kotlin.$_$.sa;
  var Long = kotlin_kotlin.$_$.hd;
  var checkIndexOverflow = kotlin_kotlin.$_$.x3;
  var Size2D_init_$Create$ = kotlin_com_soywiz_korge_korge_foundation.$_$.q;
  var Colors_getInstance = kotlin_com_soywiz_korge_korge_core.$_$.c1;
  var Korge = kotlin_com_soywiz_korge_korge.$_$.b;
  var CoroutineImpl = kotlin_kotlin.$_$.g8;
  var MouseEvents = kotlin_com_soywiz_korge_korge.$_$.e;
  var toDuration_0 = kotlin_kotlin.$_$.yc;
  var getKClass = kotlin_kotlin.$_$.b;
  var get_COROUTINE_SUSPENDED = kotlin_kotlin.$_$.p7;
  var launchImmediately = kotlin_com_soywiz_korge_korge_core.$_$.l3;
  var Scene = kotlin_com_soywiz_korge_korge.$_$.i;
  var get_DefaultTtfFontAsBitmap = kotlin_com_soywiz_korge_korge_core.$_$.k2;
  var Companion_getInstance_1 = kotlin_com_soywiz_korge_korge_core.$_$.h1;
  var get_DefaultStringTextRenderer = kotlin_com_soywiz_korge_korge_core.$_$.x2;
  var Companion_instance = kotlin_com_soywiz_korge_korge.$_$.d;
  var numberToDouble = kotlin_kotlin.$_$.t9;
  var Text = kotlin_com_soywiz_korge_korge.$_$.q;
  var addTo = kotlin_com_soywiz_korge_korge.$_$.r;
  var centerOnStage = kotlin_com_soywiz_korge_korge.$_$.k;
  var get_mouse = kotlin_com_soywiz_korge_korge.$_$.f;
  var get_resourcesVfs = kotlin_com_soywiz_korge_korge_core.$_$.u3;
  var readAtlas = kotlin_com_soywiz_korge_korge_core.$_$.a;
  var FixedSizeContainer = kotlin_com_soywiz_korge_korge.$_$.l;
  var Vector2F_init_$Create$ = kotlin_com_soywiz_korge_korge_foundation.$_$.x;
  var get = kotlin_com_soywiz_korge_korge_foundation.$_$.o5;
  var Vector2F_init_$Create$_0 = kotlin_com_soywiz_korge_korge_foundation.$_$.w;
  var SolidRect = kotlin_com_soywiz_korge_korge.$_$.m;
  var Duration = kotlin_kotlin.$_$.xc;
  var Duration__minus_impl_q5cfm7 = kotlin_kotlin.$_$.c2;
  var coerceAtLeast = kotlin_kotlin.$_$.la;
  var Duration__div_impl_dknbf4 = kotlin_kotlin.$_$.y1;
  var get_AlphaTransition = kotlin_com_soywiz_korge_korge.$_$.g;
  var Companion_getInstance_2 = kotlin_com_soywiz_korge_korge_foundation.$_$.z2;
  var withEasing = kotlin_com_soywiz_korge_korge.$_$.j;
  var Companion_getInstance_3 = kotlin_com_soywiz_korge_korge_foundation.$_$.w2;
  var views = kotlin_com_soywiz_korge_korge.$_$.a;
  var findFirstAscendant = kotlin_com_soywiz_korge_korge.$_$.u;
  var Size2D = kotlin_com_soywiz_korge_korge_foundation.$_$.t4;
  var SceneContainer = kotlin_com_soywiz_korge_korge.$_$.h;
  var Companion_getInstance_4 = kotlin_com_soywiz_korge_korge_foundation.$_$.t2;
  var xy = kotlin_com_soywiz_korge_korge.$_$.y;
  var addUpdater = kotlin_com_soywiz_korge_korge.$_$.s;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ce;
  var Stage = kotlin_com_soywiz_korge_korge.$_$.p;
  var get_RegisteredImageFormats = kotlin_com_soywiz_korge_korge_core.$_$.s2;
  var PNG_getInstance = kotlin_com_soywiz_korge_korge_core.$_$.g1;
  var KProperty1 = kotlin_kotlin.$_$.bb;
  var getPropertyCallableRef = kotlin_kotlin.$_$.c9;
  var get_EmptyContinuation = kotlin_kotlin.$_$.s7;
  var Duration__plus_impl_yu9v8f = kotlin_kotlin.$_$.d2;
  var NoSuchElementException_init_$Create$ = kotlin_kotlin.$_$.o1;
  var Duration__compareTo_impl_pchp0f = kotlin_kotlin.$_$.w1;
  var ensureNotNull = kotlin_kotlin.$_$.td;
  var to = kotlin_kotlin.$_$.fe;
  var mapOf = kotlin_kotlin.$_$.z5;
  var Collection = kotlin_kotlin.$_$.k3;
  var isInterface = kotlin_kotlin.$_$.o9;
  var Duration__hashCode_impl_u4exz6 = kotlin_kotlin.$_$.z1;
  var Angle = kotlin_com_soywiz_korge_korge_foundation.$_$.j4;
  var Angle__hashCode_impl_szcndt = kotlin_com_soywiz_korge_korge_foundation.$_$.g1;
  var get_seconds = kotlin_com_soywiz_korge_korlibs_time.$_$.t;
  var times = kotlin_com_soywiz_korge_korge_foundation.$_$.d5;
  var Vector2F = kotlin_com_soywiz_korge_korge_foundation.$_$.w4;
  var Angle__times_impl_v3s9y2 = kotlin_com_soywiz_korge_korge_foundation.$_$.l1;
  var Angle__plus_impl_26v37k = kotlin_com_soywiz_korge_korge_foundation.$_$.h1;
  var Companion_getInstance_5 = kotlin_com_soywiz_korge_korge_foundation.$_$.m2;
  var Sprite = kotlin_com_soywiz_korge_korge.$_$.o;
  var position = kotlin_com_soywiz_korge_korge.$_$.v;
  var get_centered = kotlin_com_soywiz_korge_korge.$_$.t;
  var size = kotlin_com_soywiz_korge_korge.$_$.x;
  var Sprite_init_$Create$ = kotlin_com_soywiz_korge_korge.$_$.c;
  var rotation = kotlin_com_soywiz_korge_korge.$_$.w;
  var first = kotlin_kotlin.$_$.d5;
  var coerceAtMost = kotlin_kotlin.$_$.na;
  var numberToInt = kotlin_kotlin.$_$.u9;
  var Companion_getInstance_6 = kotlin_com_soywiz_korge_korlibs_logger.$_$.g;
  var getKClassFromExpression = kotlin_kotlin.$_$.a;
  var Companion_getInstance_7 = kotlin_com_soywiz_korge_korge_foundation.$_$.x2;
  var Vector2D = kotlin_com_soywiz_korge_korge_foundation.$_$.v4;
  var Level_DEBUG_getInstance = kotlin_com_soywiz_korge_korlibs_logger.$_$.a;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Assets, 'Assets', classMeta);
  setMetadataFor(Direction, 'Direction', classMeta, Enum);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Orientation, 'Orientation', classMeta, Enum);
  setMetadataFor(GameOver, 'GameOver', classMeta, VOID, VOID, GameOver);
  setMetadataFor(Scored, 'Scored', classMeta);
  setMetadataFor(Placed, 'Placed', classMeta);
  setMetadataFor(StagingArea, 'StagingArea', classMeta, VOID, VOID, StagingArea);
  setMetadataFor(PlayField, 'PlayField', classMeta);
  setMetadataFor(MainMenuScene$sceneMain$slambda, 'MainMenuScene$sceneMain$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor(MainMenuScene$sceneMain$lambda$slambda, 'MainMenuScene$sceneMain$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor(MainMenuScene, 'MainMenuScene', classMeta, Scene, VOID, MainMenuScene, VOID, VOID, [0]);
  setMetadataFor(AssetsLoader$get$slambda, 'AssetsLoader$get$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor($getCOROUTINE$0, '$getCOROUTINE$0', classMeta, CoroutineImpl);
  setMetadataFor(AssetsLoader, 'AssetsLoader', classMeta, VOID, VOID, AssetsLoader, VOID, VOID, [0]);
  setMetadataFor(MyScene$sceneMain$slambda, 'MyScene$sceneMain$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor(MyScene$sceneMain$lambda$slambda, 'MyScene$sceneMain$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor($sceneInitCOROUTINE$1, '$sceneInitCOROUTINE$1', classMeta, CoroutineImpl);
  setMetadataFor($sceneMainCOROUTINE$2, '$sceneMainCOROUTINE$2', classMeta, CoroutineImpl);
  setMetadataFor(MyScene, 'MyScene', classMeta, Scene, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor(main$slambda, 'main$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor(Tile, 'Tile', classMeta);
  setMetadataFor(CornerPipe, 'CornerPipe', classMeta, Tile);
  setMetadataFor(Inner, 'Inner', classMeta);
  setMetadataFor(CrossPipe, 'CrossPipe', classMeta, Tile);
  setMetadataFor(EmptyTile, 'EmptyTile', classMeta, Tile, VOID, EmptyTile);
  setMetadataFor(StartPipe, 'StartPipe', classMeta, Tile);
  setMetadataFor(StraightPipe, 'StraightPipe', classMeta, Tile);
  setMetadataFor(Overflow, 'Overflow', classMeta);
  setMetadataFor(Particle, 'Particle', classMeta);
  setMetadataFor(Particles, 'Particles', classMeta);
  setMetadataFor(PlayFieldView, 'PlayFieldView', classMeta);
  setMetadataFor(StagingAreaView, 'StagingAreaView', classMeta);
  //endregion
  function Assets(atlas) {
    this.xbl_1 = atlas;
    this.ybl_1 = this.xbl_1.t12('transparent');
    this.zbl_1 = this.xbl_1.t12('empty');
    this.abm_1 = this.xbl_1.t12('corner');
    this.bbm_1 = this.xbl_1.t12('start');
    this.cbm_1 = this.xbl_1.t12('cross');
    this.dbm_1 = this.xbl_1.t12('straightH');
    this.ebm_1 = this.xbl_1.t12('straightV');
    var tmp = this;
    var tmp_0 = listOf(this.ybl_1);
    // Inline function 'kotlin.collections.map' call
    var this_0 = numberRangeToNumber(1, 8);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList_init_$Create$(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.s();
    while (tmp0_iterator.t()) {
      var item = tmp0_iterator.u();
      // Inline function 'Assets.startFluid.<anonymous>' call
      var tmp$ret$0 = this.xbl_1.t12('start-fluid' + item);
      destination.p(tmp$ret$0);
    }
    tmp.fbm_1 = new SpriteAnimation(plus(tmp_0, destination));
    var tmp_1 = this;
    var tmp_2 = listOf(this.ybl_1);
    // Inline function 'kotlin.collections.map' call
    var this_1 = numberRangeToNumber(1, 8);
    // Inline function 'kotlin.collections.mapTo' call
    var destination_0 = ArrayList_init_$Create$(collectionSizeOrDefault(this_1, 10));
    var tmp0_iterator_0 = this_1.s();
    while (tmp0_iterator_0.t()) {
      var item_0 = tmp0_iterator_0.u();
      // Inline function 'Assets.cornerFluid.<anonymous>' call
      var tmp$ret$3 = this.xbl_1.t12('corner-fluid' + item_0);
      destination_0.p(tmp$ret$3);
    }
    tmp_1.gbm_1 = new SpriteAnimation(plus(tmp_2, destination_0));
    var tmp_3 = this;
    var tmp_4 = listOf(this.ybl_1);
    // Inline function 'kotlin.collections.map' call
    var this_2 = numberRangeToNumber(1, 8);
    // Inline function 'kotlin.collections.mapTo' call
    var destination_1 = ArrayList_init_$Create$(collectionSizeOrDefault(this_2, 10));
    var tmp0_iterator_1 = this_2.s();
    while (tmp0_iterator_1.t()) {
      var item_1 = tmp0_iterator_1.u();
      // Inline function 'Assets.cornerFluidFlipped.<anonymous>' call
      var tmp$ret$6 = this.xbl_1.t12('corner-fluid-flip' + item_1);
      destination_1.p(tmp$ret$6);
    }
    tmp_3.hbm_1 = new SpriteAnimation(plus(tmp_4, destination_1));
    var tmp_5 = this;
    var tmp_6 = listOf(this.ybl_1);
    // Inline function 'kotlin.collections.map' call
    var this_3 = numberRangeToNumber(1, 8);
    // Inline function 'kotlin.collections.mapTo' call
    var destination_2 = ArrayList_init_$Create$(collectionSizeOrDefault(this_3, 10));
    var tmp0_iterator_2 = this_3.s();
    while (tmp0_iterator_2.t()) {
      var item_2 = tmp0_iterator_2.u();
      // Inline function 'Assets.straightFluid.<anonymous>' call
      var tmp$ret$9 = this.xbl_1.t12('straight-fluid' + item_2);
      destination_2.p(tmp$ret$9);
    }
    tmp_5.ibm_1 = new SpriteAnimation(plus(tmp_6, destination_2));
  }
  protoOf(Assets).toString = function () {
    return 'Assets(atlas=' + this.xbl_1 + ')';
  };
  protoOf(Assets).hashCode = function () {
    return hashCode(this.xbl_1);
  };
  protoOf(Assets).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Assets))
      return false;
    var tmp0_other_with_cast = other instanceof Assets ? other : THROW_CCE();
    if (!equals(this.xbl_1, tmp0_other_with_cast.xbl_1))
      return false;
    return true;
  };
  var Direction_UP_instance;
  var Direction_DOWN_instance;
  var Direction_LEFT_instance;
  var Direction_RIGHT_instance;
  function values() {
    return [Direction_UP_getInstance(), Direction_DOWN_getInstance(), Direction_LEFT_getInstance(), Direction_RIGHT_getInstance()];
  }
  function get_entries() {
    if ($ENTRIES == null)
      $ENTRIES = enumEntries(values());
    return $ENTRIES;
  }
  var Direction_entriesInitialized;
  function Direction_initEntries() {
    if (Direction_entriesInitialized)
      return Unit_instance;
    Direction_entriesInitialized = true;
    Direction_UP_instance = new Direction('UP', 0, new Vector2I(0, -1), _Char___init__impl__6a9atx(8593));
    Direction_DOWN_instance = new Direction('DOWN', 1, new Vector2I(0, 1), _Char___init__impl__6a9atx(8595));
    Direction_LEFT_instance = new Direction('LEFT', 2, new Vector2I(-1, 0), _Char___init__impl__6a9atx(8592));
    Direction_RIGHT_instance = new Direction('RIGHT', 3, new Vector2I(1, 0), _Char___init__impl__6a9atx(8594));
  }
  var $ENTRIES;
  function Direction(name, ordinal, vec, char) {
    Enum.call(this, name, ordinal);
    this.lbm_1 = vec;
    this.mbm_1 = char;
  }
  protoOf(Direction).nbm = function () {
    var tmp;
    switch (this.t9_1) {
      case 0:
        tmp = Direction_DOWN_getInstance();
        break;
      case 1:
        tmp = Direction_UP_getInstance();
        break;
      case 2:
        tmp = Direction_RIGHT_getInstance();
        break;
      case 3:
        tmp = Direction_LEFT_getInstance();
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(Direction).obm = function () {
    var tmp;
    switch (this.t9_1) {
      case 0:
        tmp = Direction_RIGHT_getInstance();
        break;
      case 3:
        tmp = Direction_DOWN_getInstance();
        break;
      case 1:
        tmp = Direction_LEFT_getInstance();
        break;
      case 2:
        tmp = Direction_UP_getInstance();
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(Direction).pbm = function () {
    var tmp;
    switch (this.t9_1) {
      case 0:
        tmp = Companion_getInstance().x1i_1;
        break;
      case 3:
        tmp = Companion_getInstance().y1i_1;
        break;
      case 1:
        tmp = Companion_getInstance().z1i_1;
        break;
      case 2:
        tmp = Companion_getInstance().a1j_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  var Orientation_HORIZONTAL_instance;
  var Orientation_VERTICAL_instance;
  function Companion() {
  }
  var Companion_instance_0;
  function Companion_getInstance_8() {
    return Companion_instance_0;
  }
  var Orientation_entriesInitialized;
  function Orientation_initEntries() {
    if (Orientation_entriesInitialized)
      return Unit_instance;
    Orientation_entriesInitialized = true;
    Orientation_HORIZONTAL_instance = new Orientation('HORIZONTAL', 0, listOf_0([Direction_LEFT_getInstance(), Direction_RIGHT_getInstance()]));
    Orientation_VERTICAL_instance = new Orientation('VERTICAL', 1, listOf_0([Direction_UP_getInstance(), Direction_DOWN_getInstance()]));
  }
  function Orientation(name, ordinal, directions) {
    Enum.call(this, name, ordinal);
    this.sbm_1 = directions;
  }
  function Direction_UP_getInstance() {
    Direction_initEntries();
    return Direction_UP_instance;
  }
  function Direction_DOWN_getInstance() {
    Direction_initEntries();
    return Direction_DOWN_instance;
  }
  function Direction_LEFT_getInstance() {
    Direction_initEntries();
    return Direction_LEFT_instance;
  }
  function Direction_RIGHT_getInstance() {
    Direction_initEntries();
    return Direction_RIGHT_instance;
  }
  function Orientation_HORIZONTAL_getInstance() {
    Orientation_initEntries();
    return Orientation_HORIZONTAL_instance;
  }
  function Orientation_VERTICAL_getInstance() {
    Orientation_initEntries();
    return Orientation_VERTICAL_instance;
  }
  function GameOver() {
  }
  function Scored(x, y, score) {
    this.tbm_1 = x;
    this.ubm_1 = y;
    this.vbm_1 = score;
  }
  protoOf(Scored).toString = function () {
    return 'Scored(x=' + this.tbm_1 + ', y=' + this.ubm_1 + ', score=' + this.vbm_1.toString() + ')';
  };
  protoOf(Scored).hashCode = function () {
    var result = this.tbm_1;
    result = imul(result, 31) + this.ubm_1 | 0;
    result = imul(result, 31) + this.vbm_1.hashCode() | 0;
    return result;
  };
  protoOf(Scored).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Scored))
      return false;
    var tmp0_other_with_cast = other instanceof Scored ? other : THROW_CCE();
    if (!(this.tbm_1 === tmp0_other_with_cast.tbm_1))
      return false;
    if (!(this.ubm_1 === tmp0_other_with_cast.ubm_1))
      return false;
    if (!this.vbm_1.equals(tmp0_other_with_cast.vbm_1))
      return false;
    return true;
  };
  function Placed(x, y, tile) {
  }
  function StagingArea$generators$lambda() {
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    var tmp$ret$0 = toDuration(2, DurationUnit_SECONDS_getInstance());
    return new StraightPipe(tmp$ret$0, Orientation_VERTICAL_getInstance());
  }
  function StagingArea$generators$lambda_0() {
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    var tmp$ret$0 = toDuration(2, DurationUnit_SECONDS_getInstance());
    return new StraightPipe(tmp$ret$0, Orientation_HORIZONTAL_getInstance());
  }
  function StagingArea$generators$lambda_1() {
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    var tmp$ret$0 = toDuration(2, DurationUnit_SECONDS_getInstance());
    return new CornerPipe(tmp$ret$0, Direction_UP_getInstance());
  }
  function StagingArea$generators$lambda_2() {
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    var tmp$ret$0 = toDuration(2, DurationUnit_SECONDS_getInstance());
    return new CornerPipe(tmp$ret$0, Direction_DOWN_getInstance());
  }
  function StagingArea$generators$lambda_3() {
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    var tmp$ret$0 = toDuration(2, DurationUnit_SECONDS_getInstance());
    return new CornerPipe(tmp$ret$0, Direction_LEFT_getInstance());
  }
  function StagingArea$generators$lambda_4() {
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    var tmp$ret$0 = toDuration(2, DurationUnit_SECONDS_getInstance());
    return new CornerPipe(tmp$ret$0, Direction_RIGHT_getInstance());
  }
  function StagingArea$generators$lambda_5() {
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    var tmp$ret$0 = toDuration(2, DurationUnit_SECONDS_getInstance());
    return new CrossPipe(tmp$ret$0);
  }
  function StagingArea() {
    var tmp = this;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp.wbm_1 = ArrayList_init_$Create$_0();
    this.xbm_1 = 5;
    var tmp_0 = this;
    var tmp_1 = StagingArea$generators$lambda;
    var tmp_2 = StagingArea$generators$lambda_0;
    var tmp_3 = StagingArea$generators$lambda_1;
    var tmp_4 = StagingArea$generators$lambda_2;
    var tmp_5 = StagingArea$generators$lambda_3;
    var tmp_6 = StagingArea$generators$lambda_4;
    tmp_0.ybm_1 = listOf_0([tmp_1, tmp_2, tmp_3, tmp_4, tmp_5, tmp_6, StagingArea$generators$lambda_5]);
  }
  protoOf(StagingArea).zbm = function () {
    var ret = removeFirst(this.wbm_1);
    println('' + this.wbm_1 + ' ' + ret);
    this.abn();
    println('' + this.wbm_1);
    return ret;
  };
  protoOf(StagingArea).abn = function () {
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = until(this.wbm_1.l(), this.xbm_1).s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      // Inline function 'StagingArea.replenish.<anonymous>' call
      // Inline function 'kotlin.collections.random' call
      var this_0 = this.ybm_1;
      var tmp$ret$0 = random(this_0, Default_getInstance());
      this.wbm_1.p(tmp$ret$0());
    }
  };
  function getTileOrNull($this, vec) {
    var tmp0_safe_receiver = getOrNull($this.fbn_1, vec.i1r_1);
    return tmp0_safe_receiver == null ? null : getOrNull(tmp0_safe_receiver, vec.j1r_1);
  }
  function PlayField(xtiles, ytiles, stagingArea, eventCallback) {
    this.bbn_1 = xtiles;
    this.cbn_1 = ytiles;
    this.dbn_1 = stagingArea;
    this.ebn_1 = eventCallback;
    var tmp = this;
    // Inline function 'kotlin.collections.map' call
    var this_0 = numberRangeToNumber(1, this.bbn_1);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList_init_$Create$(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.s();
    while (tmp0_iterator.t()) {
      var item = tmp0_iterator.u();
      // Inline function 'PlayField.tiles.<anonymous>' call
      // Inline function 'kotlin.collections.map' call
      var this_1 = numberRangeToNumber(1, this.cbn_1);
      // Inline function 'kotlin.collections.mapTo' call
      var destination_0 = ArrayList_init_$Create$(collectionSizeOrDefault(this_1, 10));
      var tmp0_iterator_0 = this_1.s();
      while (tmp0_iterator_0.t()) {
        var item_0 = tmp0_iterator_0.u();
        // Inline function 'PlayField.tiles.<anonymous>.<anonymous>' call
        var tmp$ret$0 = new EmptyTile();
        destination_0.p(tmp$ret$0);
      }
      var tmp$ret$3 = toMutableList(destination_0);
      destination.p(tmp$ret$3);
    }
    tmp.fbn_1 = destination;
    var tmp_0 = this;
    // Inline function 'kotlin.ranges.random' call
    var this_2 = until(1, this.bbn_1 - 1 | 0);
    tmp_0.gbn_1 = random_0(this_2, Default_getInstance());
    var tmp_1 = this;
    // Inline function 'kotlin.ranges.random' call
    var this_3 = until(1, this.cbn_1 - 1 | 0);
    tmp_1.hbn_1 = random_0(this_3, Default_getInstance());
    this.ibn_1 = new Long(0, 0);
    var tmp_2 = this.fbn_1.b1(this.gbn_1);
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    var tmp_3 = toDuration(2, DurationUnit_SECONDS_getInstance());
    // Inline function 'kotlin.collections.random' call
    var this_4 = get_entries();
    var tmp$ret$9 = random(this_4, Default_getInstance());
    tmp_2.m(this.hbn_1, new StartPipe(tmp_3, tmp$ret$9));
  }
  protoOf(PlayField).jbn = function () {
    var tmp = this.fbn_1.b1(this.gbn_1).b1(this.hbn_1);
    (tmp instanceof StartPipe ? tmp : THROW_CCE()).jbn();
  };
  protoOf(PlayField).pbn = function (dt) {
    var result = null;
    // Inline function 'kotlin.collections.forEachIndexed' call
    var index = 0;
    var tmp0_iterator = this.fbn_1.s();
    while (tmp0_iterator.t()) {
      var item = tmp0_iterator.u();
      // Inline function 'PlayField.onUpdate.<anonymous>' call
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      var x = checkIndexOverflow(tmp1);
      // Inline function 'kotlin.collections.forEachIndexed' call
      var index_0 = 0;
      var tmp0_iterator_0 = item.s();
      while (tmp0_iterator_0.t()) {
        var item_0 = tmp0_iterator_0.u();
        // Inline function 'PlayField.onUpdate.<anonymous>.<anonymous>' call
        var tmp1_0 = index_0;
        index_0 = tmp1_0 + 1 | 0;
        var y = checkIndexOverflow(tmp1_0);
        var event = item_0.pbn(dt);
        if (event instanceof Overflow) {
          this.ibn_1 = this.ibn_1.wb(event.sbn_1);
          this.ebn_1(new Scored(x, y, event.sbn_1));
          var pos = event.rbn_1.lbm_1.h1u(new Vector2I(x, y));
          var newTile = getTileOrNull(this, pos);
          if (newTile == null) {
            result = new GameOver();
          } else {
            if (!newTile.tbn(event.rbn_1, event.qbn_1)) {
              result = new GameOver();
            }
          }
        }
      }
    }
    var tmp0_safe_receiver = result;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'PlayField.onUpdate.<anonymous>' call
      this.ebn_1(tmp0_safe_receiver);
      tmp = tmp0_safe_receiver;
    }
    return tmp;
  };
  protoOf(PlayField).ubn = function (x, y) {
    var currentTile = this.fbn_1.b1(x).b1(y);
    if (currentTile.vbn()) {
      var tile = this.dbn_1.zbm();
      this.fbn_1.b1(x).m(y, tile);
      this.ebn_1(new Placed(x, y, tile));
    }
  };
  function main($completion) {
    var tmp = Size2D_init_$Create$(512, 512);
    var tmp_0 = Colors_getInstance().v2y('#2b2b2b');
    return Korge(VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, tmp, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, tmp_0, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, main$slambda_0(null), $completion);
  }
  function MainMenuScene$sceneMain$slambda(this$0, resultContinuation) {
    this.ebo_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(MainMenuScene$sceneMain$slambda).kbo = function (it, $completion) {
    var tmp = this.lbo(it, $completion);
    tmp.kc_1 = Unit_instance;
    tmp.lc_1 = null;
    return tmp.tc();
  };
  protoOf(MainMenuScene$sceneMain$slambda).qd = function (p1, $completion) {
    return this.kbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(MainMenuScene$sceneMain$slambda).tc = function () {
    var suspendResult = this.kc_1;
    $sm: do
      try {
        var tmp = this.ic_1;
        switch (tmp) {
          case 0:
            this.jc_1 = 2;
            var tmp_0 = this;
            tmp_0.gbo_1 = this.ebo_1.c9b();
            var tmp_1 = this;
            tmp_1.hbo_1 = [];
            var tmp_2 = this;
            tmp_2.ibo_1 = toDuration_0(0, DurationUnit_SECONDS_getInstance());
            var tmp_3 = this;
            tmp_3.jbo_1 = this.gbo_1.l8l_1;
            this.ic_1 = 1;
            suspendResult = this.gbo_1.g9m(getKClass(MyScene), this.hbo_1.slice(), this.ibo_1, this.jbo_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_instance;
          case 2:
            throw this.lc_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.jc_1 === 2) {
          throw e;
        } else {
          this.ic_1 = this.jc_1;
          this.lc_1 = e;
        }
      }
     while (true);
  };
  protoOf(MainMenuScene$sceneMain$slambda).lbo = function (it, completion) {
    var i = new MainMenuScene$sceneMain$slambda(this.ebo_1, completion);
    i.fbo_1 = it;
    return i;
  };
  function MainMenuScene$sceneMain$slambda_0(this$0, resultContinuation) {
    var i = new MainMenuScene$sceneMain$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.kbo(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function MainMenuScene$sceneMain$lambda$slambda($handler, $it, resultContinuation) {
    this.ubo_1 = $handler;
    this.vbo_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(MainMenuScene$sceneMain$lambda$slambda).i4q = function ($completion) {
    var tmp = this.l4b($completion);
    tmp.kc_1 = Unit_instance;
    tmp.lc_1 = null;
    return tmp.tc();
  };
  protoOf(MainMenuScene$sceneMain$lambda$slambda).ed = function ($completion) {
    return this.i4q($completion);
  };
  protoOf(MainMenuScene$sceneMain$lambda$slambda).tc = function () {
    var suspendResult = this.kc_1;
    $sm: do
      try {
        var tmp = this.ic_1;
        switch (tmp) {
          case 0:
            this.jc_1 = 2;
            this.ic_1 = 1;
            suspendResult = this.ubo_1(this.vbo_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_instance;
          case 2:
            throw this.lc_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.jc_1 === 2) {
          throw e;
        } else {
          this.ic_1 = this.jc_1;
          this.lc_1 = e;
        }
      }
     while (true);
  };
  protoOf(MainMenuScene$sceneMain$lambda$slambda).l4b = function (completion) {
    return new MainMenuScene$sceneMain$lambda$slambda(this.ubo_1, this.vbo_1, completion);
  };
  function MainMenuScene$sceneMain$lambda$slambda_0($handler, $it, resultContinuation) {
    var i = new MainMenuScene$sceneMain$lambda$slambda($handler, $it, resultContinuation);
    var l = function ($completion) {
      return i.i4q($completion);
    };
    l.$arity = 0;
    return l;
  }
  function MainMenuScene$sceneMain$lambda($this, $handler) {
    return function (it) {
      var tmp = $this.xi();
      launchImmediately(tmp, MainMenuScene$sceneMain$lambda$slambda_0($handler, it, null));
      return Unit_instance;
    };
  }
  function MainMenuScene() {
    Scene.call(this);
  }
  protoOf(MainMenuScene).z9d = function (_this__u8e3s4, $completion) {
    return Unit_instance;
  };
  protoOf(MainMenuScene).a9e = function (_this__u8e3s4, $completion) {
    // Inline function 'korlibs.korge.view.text' call
    var text = 'Pipeteer';
    var color = Colors_getInstance().u2j_1;
    var font = get_DefaultTtfFontAsBitmap();
    var alignment = Companion_getInstance_1().k38_1;
    var renderer = get_DefaultStringTextRenderer();
    var autoScaling = Companion_instance.qb0_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = new Text(text, numberToDouble(64), color, font, alignment, renderer, autoScaling, null, null);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'MainMenuScene.sceneMain.<anonymous>' call
    centerOnStage(this_1);
    // Inline function 'korlibs.korge.input.mouse' call
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.input.MouseEvents.onClick' call
    var this_2 = get_mouse(_this__u8e3s4);
    var handler = MainMenuScene$sceneMain$slambda_0(this, null);
    // Inline function 'korlibs.korge.input.MouseEvents._mouseEvent' call
    var tmp = click$factory().get(this_2);
    tmp.r4n(MainMenuScene$sceneMain$lambda(this_2, handler));
    return Unit_instance;
  };
  function AssetsLoader$get$slambda(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(AssetsLoader$get$slambda).obp = function ($completion) {
    var tmp = this.l4b($completion);
    tmp.kc_1 = Unit_instance;
    tmp.lc_1 = null;
    return tmp.tc();
  };
  protoOf(AssetsLoader$get$slambda).ed = function ($completion) {
    return this.obp($completion);
  };
  protoOf(AssetsLoader$get$slambda).tc = function () {
    var suspendResult = this.kc_1;
    $sm: do
      try {
        var tmp = this.ic_1;
        switch (tmp) {
          case 0:
            this.jc_1 = 2;
            this.ic_1 = 1;
            suspendResult = readAtlas(get_resourcesVfs().t12('texture3.json'), VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var atlas = suspendResult;
            return new Assets(atlas);
          case 2:
            throw this.lc_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.jc_1 === 2) {
          throw e;
        } else {
          this.ic_1 = this.jc_1;
          this.lc_1 = e;
        }
      }
     while (true);
  };
  protoOf(AssetsLoader$get$slambda).l4b = function (completion) {
    return new AssetsLoader$get$slambda(completion);
  };
  function AssetsLoader$get$slambda_0(resultContinuation) {
    var i = new AssetsLoader$get$slambda(resultContinuation);
    var l = function ($completion) {
      return i.obp($completion);
    };
    l.$arity = 0;
    return l;
  }
  function $getCOROUTINE$0(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.xbp_1 = _this__u8e3s4;
  }
  protoOf($getCOROUTINE$0).tc = function () {
    var suspendResult = this.kc_1;
    $sm: do
      try {
        var tmp = this.ic_1;
        switch (tmp) {
          case 0:
            this.jc_1 = 3;
            this.ybp_1 = this.xbp_1.abq_1;
            if (this.ybp_1 == null) {
              this.ic_1 = 1;
              suspendResult = AssetsLoader$get$slambda_0(null)(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.zbp_1 = this.ybp_1;
              this.ic_1 = 2;
              continue $sm;
            }

          case 1:
            this.zbp_1 = suspendResult;
            this.ic_1 = 2;
            continue $sm;
          case 2:
            return this.zbp_1;
          case 3:
            throw this.lc_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.jc_1 === 3) {
          throw e;
        } else {
          this.ic_1 = this.jc_1;
          this.lc_1 = e;
        }
      }
     while (true);
  };
  function AssetsLoader() {
    this.abq_1 = null;
  }
  protoOf(AssetsLoader).bbq = function ($completion) {
    var tmp = new $getCOROUTINE$0(this, $completion);
    tmp.kc_1 = Unit_instance;
    tmp.lc_1 = null;
    return tmp.tc();
  };
  function MyScene$sceneMain$lambda(it) {
    return it instanceof FixedSizeContainer;
  }
  function MyScene$sceneMain$lambda_0(it) {
    return it instanceof FixedSizeContainer;
  }
  function MyScene$sceneMain$lambda_1(it) {
    return it instanceof FixedSizeContainer;
  }
  function MyScene$sceneMain$lambda_2($tileWidth, $tileHeight, $particles, $particleLayer) {
    return function (it) {
      var tmp;
      if (it instanceof Scored) {
        var position = Vector2F_init_$Create$(imul(it.tbm_1, $tileWidth) + $tileWidth * 0.5, imul(it.ubm_1, $tileHeight) + $tileHeight * 0.5);
        var tmp_0 = Vector2F_init_$Create$(get(Default_getInstance(), -40.0, 40.0), get(Default_getInstance(), -30.0, 10.0));
        var tmp_1 = Vector2F_init_$Create$_0(0, 98.0);
        var tmp_2 = Companion_getInstance().x1i_1;
        var tmp_3 = Companion_getInstance().x1i_1;
        // Inline function 'korlibs.korge.view.text' call
        var this_0 = $particleLayer;
        var text = it.vbm_1.toString();
        var textSize = Companion_instance.pb0_1;
        var color = Colors_getInstance().s2j_1;
        var font = get_DefaultTtfFontAsBitmap();
        var alignment = Companion_getInstance_1().k38_1;
        var renderer = get_DefaultStringTextRenderer();
        var autoScaling = Companion_instance.qb0_1;
        // Inline function 'korlibs.korge.view.addTo' call
        var this_1 = new Text(text, numberToDouble(textSize), color, font, alignment, renderer, autoScaling, null, null);
        // Inline function 'kotlin.apply' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.korge.view.text.<anonymous>' call
        var tmp_4 = addTo(this_1, this_0);
        // Inline function 'kotlin.time.Companion.seconds' call
        Companion_getInstance_0();
        var tmp$ret$4 = toDuration(2, DurationUnit_SECONDS_getInstance());
        $particles.gbq(new Particle(position, tmp_0, tmp_1, tmp_2, tmp_3, tmp_4, tmp$ret$4));
        var tmp0_iterator = numberRangeToNumber(1, 50).s();
        while (tmp0_iterator.t()) {
          var element = tmp0_iterator.u();
          // Inline function 'MyScene.sceneMain.<anonymous>.<anonymous>' call
          var tmp_5 = Vector2F_init_$Create$(get(Default_getInstance(), -40.0, 40.0), get(Default_getInstance(), -30.0, 10.0));
          var tmp_6 = Vector2F_init_$Create$_0(0, 98.0);
          var tmp_7 = Companion_getInstance().x1i_1;
          var tmp_8 = Companion_getInstance().x1i_1;
          // Inline function 'korlibs.korge.view.solidRect' call
          var this_2 = $particleLayer;
          var size = Size2D_init_$Create$(1, 1);
          var color_0 = Colors_getInstance().s2j_1;
          // Inline function 'korlibs.korge.view.addTo' call
          var this_3 = new SolidRect(size, color_0);
          // Inline function 'kotlin.apply' call
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
          var tmp_9 = addTo(this_3, this_2);
          // Inline function 'kotlin.time.Companion.seconds' call
          Companion_getInstance_0();
          var tmp$ret$9 = toDuration(2, DurationUnit_SECONDS_getInstance());
          $particles.gbq(new Particle(position, tmp_5, tmp_6, tmp_7, tmp_8, tmp_9, tmp$ret$9));
        }
        tmp = Unit_instance;
      } else {
        println('unhandled ' + it);
        tmp = Unit_instance;
      }
      return Unit_instance;
    };
  }
  function MyScene$sceneMain$lambda_3($field, $gameOver, $fieldView, $stagingView, $particles, $scoreView, $started, $startTimer, $startDuration, $startTimerView, $gameOverView, $topLayer) {
    return function ($this$addUpdater, dt) {
      var event = $field.pbn(dt.ai_1);
      var tmp;
      if (event instanceof GameOver) {
        $gameOver._v = true;
        tmp = Unit_instance;
      } else {
        if (event == null) {
          tmp = Unit_instance;
        }
      }
      $fieldView.bb8(dt.ai_1);
      $stagingView.bb8(dt.ai_1);
      $particles.bb8(dt.ai_1);
      $scoreView.mb5('SCORE: ' + $field.ibn_1.toString());
      var tmp_0;
      var tmp_1;
      if (!$started._v) {
        var tmp_2 = $startTimer._v;
        // Inline function 'kotlin.time.Companion.seconds' call
        Companion_getInstance_0();
        var tmp$ret$0 = toDuration(0, DurationUnit_SECONDS_getInstance());
        tmp_1 = tmp_2.equals(new Duration(tmp$ret$0));
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        $started._v = true;
        $field.jbn();
        tmp_0 = Unit_instance;
      } else {
        var tmp_3 = Duration__minus_impl_q5cfm7($startTimer._v.ai_1, dt.ai_1);
        // Inline function 'kotlin.time.Companion.seconds' call
        Companion_getInstance_0();
        var tmp$ret$1 = toDuration(0, DurationUnit_SECONDS_getInstance());
        $startTimer._v = coerceAtLeast(new Duration(tmp_3), new Duration(tmp$ret$1));
        var timerLeft = Duration__div_impl_dknbf4($startTimer._v.ai_1, $startDuration);
        $startTimerView.wau(200 * timerLeft);
        tmp_0 = Unit_instance;
      }
      var tmp_4;
      if ($gameOver._v ? $gameOverView._v == null : false) {
        // Inline function 'korlibs.korge.view.text' call
        var this_0 = $topLayer;
        var text = 'GAME OVER';
        var color = Colors_getInstance().t2j_1;
        var font = get_DefaultTtfFontAsBitmap();
        var alignment = Companion_getInstance_1().k38_1;
        var renderer = get_DefaultStringTextRenderer();
        var autoScaling = Companion_instance.qb0_1;
        // Inline function 'korlibs.korge.view.addTo' call
        var this_1 = new Text(text, numberToDouble(68), color, font, alignment, renderer, autoScaling, null, null);
        // Inline function 'kotlin.apply' call
        var this_2 = addTo(this_1, this_0);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'MyScene.sceneMain.<anonymous>.<anonymous>' call
        centerOnStage(this_2);
        $gameOverView._v = this_2;
        // Inline function 'korlibs.korge.view.text' call
        var this_3 = $topLayer;
        var text_0 = 'GAME OVER';
        var color_0 = Colors_getInstance().s2j_1;
        var font_0 = get_DefaultTtfFontAsBitmap();
        var alignment_0 = Companion_getInstance_1().k38_1;
        var renderer_0 = get_DefaultStringTextRenderer();
        var autoScaling_0 = Companion_instance.qb0_1;
        // Inline function 'korlibs.korge.view.addTo' call
        var this_4 = new Text(text_0, numberToDouble(66), color_0, font_0, alignment_0, renderer_0, autoScaling_0, null, null);
        // Inline function 'kotlin.apply' call
        var this_5 = addTo(this_4, this_3);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'MyScene.sceneMain.<anonymous>.<anonymous>' call
        centerOnStage(this_5);
        $gameOverView._v = this_5;
        // Inline function 'korlibs.korge.view.text' call
        var this_6 = $topLayer;
        var text_1 = 'GAME OVER';
        var color_1 = Colors_getInstance().u2j_1;
        var font_1 = get_DefaultTtfFontAsBitmap();
        var alignment_1 = Companion_getInstance_1().k38_1;
        var renderer_1 = get_DefaultStringTextRenderer();
        var autoScaling_1 = Companion_instance.qb0_1;
        // Inline function 'korlibs.korge.view.addTo' call
        var this_7 = new Text(text_1, numberToDouble(64), color_1, font_1, alignment_1, renderer_1, autoScaling_1, null, null);
        // Inline function 'kotlin.apply' call
        var this_8 = addTo(this_7, this_6);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'MyScene.sceneMain.<anonymous>.<anonymous>' call
        centerOnStage(this_8);
        $gameOverView._v = this_8;
        tmp_4 = Unit_instance;
      }
      return Unit_instance;
    };
  }
  function MyScene$sceneMain$slambda($gameOver, $fieldView, this$0, resultContinuation) {
    this.cbr_1 = $gameOver;
    this.dbr_1 = $fieldView;
    this.ebr_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(MyScene$sceneMain$slambda).kbo = function (it, $completion) {
    var tmp = this.lbo(it, $completion);
    tmp.kc_1 = Unit_instance;
    tmp.lc_1 = null;
    return tmp.tc();
  };
  protoOf(MyScene$sceneMain$slambda).qd = function (p1, $completion) {
    return this.kbo(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(MyScene$sceneMain$slambda).tc = function () {
    var suspendResult = this.kc_1;
    $sm: do
      try {
        var tmp = this.ic_1;
        switch (tmp) {
          case 0:
            this.jc_1 = 3;
            if (!this.cbr_1._v) {
              this.dbr_1.kbr(this.fbr_1);
              this.ic_1 = 2;
              continue $sm;
            } else {
              var tmp_0 = this;
              tmp_0.gbr_1 = this.ebr_1.c9b();
              var tmp_1 = this;
              tmp_1.hbr_1 = [];
              var tmp_2 = this;
              tmp_2.ibr_1 = toDuration_0(0, DurationUnit_SECONDS_getInstance());
              var tmp_3 = this;
              tmp_3.jbr_1 = this.gbr_1.l8l_1;
              this.ic_1 = 1;
              suspendResult = this.gbr_1.g9m(getKClass(MainMenuScene), this.hbr_1.slice(), this.ibr_1, this.jbr_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

          case 1:
            this.ic_1 = 2;
            continue $sm;
          case 2:
            return Unit_instance;
          case 3:
            throw this.lc_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.jc_1 === 3) {
          throw e;
        } else {
          this.ic_1 = this.jc_1;
          this.lc_1 = e;
        }
      }
     while (true);
  };
  protoOf(MyScene$sceneMain$slambda).lbo = function (it, completion) {
    var i = new MyScene$sceneMain$slambda(this.cbr_1, this.dbr_1, this.ebr_1, completion);
    i.fbr_1 = it;
    return i;
  };
  function MyScene$sceneMain$slambda_0($gameOver, $fieldView, this$0, resultContinuation) {
    var i = new MyScene$sceneMain$slambda($gameOver, $fieldView, this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.kbo(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function MyScene$sceneMain$lambda$slambda($handler, $it, resultContinuation) {
    this.tbr_1 = $handler;
    this.ubr_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(MyScene$sceneMain$lambda$slambda).i4q = function ($completion) {
    var tmp = this.l4b($completion);
    tmp.kc_1 = Unit_instance;
    tmp.lc_1 = null;
    return tmp.tc();
  };
  protoOf(MyScene$sceneMain$lambda$slambda).ed = function ($completion) {
    return this.i4q($completion);
  };
  protoOf(MyScene$sceneMain$lambda$slambda).tc = function () {
    var suspendResult = this.kc_1;
    $sm: do
      try {
        var tmp = this.ic_1;
        switch (tmp) {
          case 0:
            this.jc_1 = 2;
            this.ic_1 = 1;
            suspendResult = this.tbr_1(this.ubr_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_instance;
          case 2:
            throw this.lc_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.jc_1 === 2) {
          throw e;
        } else {
          this.ic_1 = this.jc_1;
          this.lc_1 = e;
        }
      }
     while (true);
  };
  protoOf(MyScene$sceneMain$lambda$slambda).l4b = function (completion) {
    return new MyScene$sceneMain$lambda$slambda(this.tbr_1, this.ubr_1, completion);
  };
  function MyScene$sceneMain$lambda$slambda_0($handler, $it, resultContinuation) {
    var i = new MyScene$sceneMain$lambda$slambda($handler, $it, resultContinuation);
    var l = function ($completion) {
      return i.i4q($completion);
    };
    l.$arity = 0;
    return l;
  }
  function MyScene$sceneMain$lambda_4($this, $handler) {
    return function (it) {
      var tmp = $this.xi();
      launchImmediately(tmp, MyScene$sceneMain$lambda$slambda_0($handler, it, null));
      return Unit_instance;
    };
  }
  function $sceneInitCOROUTINE$1(_this__u8e3s4, _this__u8e3s4_0, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.dbs_1 = _this__u8e3s4;
    this.ebs_1 = _this__u8e3s4_0;
  }
  protoOf($sceneInitCOROUTINE$1).tc = function () {
    var suspendResult = this.kc_1;
    $sm: do
      try {
        var tmp = this.ic_1;
        switch (tmp) {
          case 0:
            this.jc_1 = 2;
            this.ic_1 = 1;
            suspendResult = this.dbs_1.pbs_1.bbq(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            this.dbs_1.qbs_1 = ARGUMENT;
            return Unit_instance;
          case 2:
            throw this.lc_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.jc_1 === 2) {
          throw e;
        } else {
          this.ic_1 = this.jc_1;
          this.lc_1 = e;
        }
      }
     while (true);
  };
  function $sceneMainCOROUTINE$2(_this__u8e3s4, _this__u8e3s4_0, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.zbs_1 = _this__u8e3s4;
    this.abt_1 = _this__u8e3s4_0;
  }
  protoOf($sceneMainCOROUTINE$2).tc = function () {
    var suspendResult = this.kc_1;
    $sm: do
      try {
        var tmp = this.ic_1;
        switch (tmp) {
          case 0:
            this.jc_1 = 4;
            var tmp_0 = this;
            Companion_getInstance_0();
            tmp_0.bbt_1 = toDuration(4, DurationUnit_SECONDS_getInstance());
            this.cbt_1 = {_v: new Duration(this.bbt_1)};
            this.dbt_1 = {_v: false};
            this.ebt_1 = {_v: false};
            this.fbt_1 = 40;
            this.gbt_1 = 40;
            this.hbt_1 = 10;
            this.ibt_1 = 10;
            var tmp_1 = this;
            tmp_1.jbt_1 = this.abt_1;
            var tmp_2 = this;
            tmp_2.kbt_1 = withEasing(get_AlphaTransition(), Companion_getInstance_2().q1e());
            var tmp_3 = this;
            tmp_3.lbt_1 = 'sceneContainer';
            var tmp_4 = this;
            tmp_4.mbt_1 = Companion_getInstance_3().i1s_1;
            var tmp_5 = this;
            tmp_5.nbt_1 = this.jbt_1;
            this.ic_1 = 1;
            suspendResult = views(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.obt_1 = suspendResult;
            var tmp_6 = this;
            tmp_6.pbt_1 = this.kbt_1;
            var tmp_7 = this;
            tmp_7.qbt_1 = this.lbt_1;
            var tmp_8 = this;
            tmp_8.rbt_1 = this.mbt_1;
            this.sbt_1 = this.rbt_1;
            this.tbt_1 = this.sbt_1.bf();
            this.ubt_1 = this.sbt_1.cf();
            this.vbt_1 = this.tbt_1;
            this.wbt_1 = this.ubt_1;
            if (this.tbt_1 === 0.0 ? this.ubt_1 === 0.0 : false) {
              var tmp_9 = findFirstAscendant(this.nbt_1, MyScene$sceneMain$lambda);
              var base = (tmp_9 == null ? true : tmp_9 instanceof FixedSizeContainer) ? tmp_9 : null;
              var tmp_10 = this;
              var tmp2_elvis_lhs = base == null ? null : base.c10();
              tmp_10.vbt_1 = tmp2_elvis_lhs == null ? this.obt_1.t8f_1.c10() : tmp2_elvis_lhs;
              var tmp_11 = this;
              var tmp4_elvis_lhs = base == null ? null : base.d10();
              tmp_11.wbt_1 = tmp4_elvis_lhs == null ? this.obt_1.t8f_1.d10() : tmp4_elvis_lhs;
            }

            var tmp_12 = this;
            var this_0 = new SceneContainer(this.obt_1, this.pbt_1, this.qbt_1, new Size2D(this.vbt_1, this.wbt_1));
            var instance = this.nbt_1;
            tmp_12.xbt_1 = addTo(this_0, instance);
            var tmp_13 = this;
            tmp_13.ybt_1 = this.abt_1;
            var tmp_14 = this;
            tmp_14.zbt_1 = withEasing(get_AlphaTransition(), Companion_getInstance_2().q1e());
            var tmp_15 = this;
            tmp_15.abu_1 = 'sceneContainer';
            var tmp_16 = this;
            tmp_16.bbu_1 = Companion_getInstance_3().i1s_1;
            var tmp_17 = this;
            tmp_17.cbu_1 = this.ybt_1;
            this.ic_1 = 2;
            suspendResult = views(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.dbu_1 = suspendResult;
            var tmp_18 = this;
            tmp_18.ebu_1 = this.zbt_1;
            var tmp_19 = this;
            tmp_19.fbu_1 = this.abu_1;
            var tmp_20 = this;
            tmp_20.gbu_1 = this.bbu_1;
            this.hbu_1 = this.gbu_1;
            this.ibu_1 = this.hbu_1.bf();
            this.jbu_1 = this.hbu_1.cf();
            this.kbu_1 = this.ibu_1;
            this.lbu_1 = this.jbu_1;
            if (this.ibu_1 === 0.0 ? this.jbu_1 === 0.0 : false) {
              var tmp_21 = findFirstAscendant(this.cbu_1, MyScene$sceneMain$lambda_0);
              var base_0 = (tmp_21 == null ? true : tmp_21 instanceof FixedSizeContainer) ? tmp_21 : null;
              var tmp_22 = this;
              var tmp2_elvis_lhs_0 = base_0 == null ? null : base_0.c10();
              tmp_22.kbu_1 = tmp2_elvis_lhs_0 == null ? this.dbu_1.t8f_1.c10() : tmp2_elvis_lhs_0;
              var tmp_23 = this;
              var tmp4_elvis_lhs_0 = base_0 == null ? null : base_0.d10();
              tmp_23.lbu_1 = tmp4_elvis_lhs_0 == null ? this.dbu_1.t8f_1.d10() : tmp4_elvis_lhs_0;
            }

            var tmp_24 = this;
            var this_1 = new SceneContainer(this.dbu_1, this.ebu_1, this.fbu_1, new Size2D(this.kbu_1, this.lbu_1));
            var instance_0 = this.cbu_1;
            tmp_24.mbu_1 = addTo(this_1, instance_0);
            var tmp_25 = this;
            tmp_25.nbu_1 = this.abt_1;
            var tmp_26 = this;
            tmp_26.obu_1 = withEasing(get_AlphaTransition(), Companion_getInstance_2().q1e());
            var tmp_27 = this;
            tmp_27.pbu_1 = 'sceneContainer';
            var tmp_28 = this;
            tmp_28.qbu_1 = Companion_getInstance_3().i1s_1;
            var tmp_29 = this;
            tmp_29.rbu_1 = this.nbu_1;
            this.ic_1 = 3;
            suspendResult = views(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            var views_0 = suspendResult;
            var defaultTransition = this.obu_1;
            var name = this.pbu_1;
            var tmp0_container = this.qbu_1;
            var width = tmp0_container.bf();
            var height = tmp0_container.cf();
            var rwidth = width;
            var rheight = height;
            if (width === 0.0 ? height === 0.0 : false) {
              var tmp_30 = findFirstAscendant(this.rbu_1, MyScene$sceneMain$lambda_1);
              var base_1 = (tmp_30 == null ? true : tmp_30 instanceof FixedSizeContainer) ? tmp_30 : null;
              var tmp2_elvis_lhs_1 = base_1 == null ? null : base_1.c10();
              rwidth = tmp2_elvis_lhs_1 == null ? views_0.t8f_1.c10() : tmp2_elvis_lhs_1;
              var tmp4_elvis_lhs_1 = base_1 == null ? null : base_1.d10();
              rheight = tmp4_elvis_lhs_1 == null ? views_0.t8f_1.d10() : tmp4_elvis_lhs_1;
            }

            var this_2 = new SceneContainer(views_0, defaultTransition, name, new Size2D(rwidth, rheight));
            var instance_1 = this.rbu_1;
            var topLayer = addTo(this_2, instance_1);
            var this_3 = new StagingArea();
            this_3.abn();
            var staging = this_3;
            var viewRectangle = Companion_getInstance_4().c1q(0, 0, imul(this.fbt_1, this.hbt_1), imul(this.gbt_1, this.ibt_1));
            var particles = new Particles(this.zbs_1.sbu(), this.mbu_1, viewRectangle);
            var field = new PlayField(this.hbt_1, this.ibt_1, staging, MyScene$sceneMain$lambda_2(this.fbt_1, this.gbt_1, particles, this.mbu_1));
            var stagingView = new StagingAreaView(staging, this.zbs_1.sbu(), this.xbt_1, Companion_getInstance_4().c1q(450, 0, this.fbt_1, imul(5, this.gbt_1)));
            var fieldView = new PlayFieldView(field, this.zbs_1.sbu(), this.xbt_1, viewRectangle);
            var this_4 = this.abt_1;
            var text = 'SCORE: 0';
            var color = Colors_getInstance().s2j_1;
            var font = get_DefaultTtfFontAsBitmap();
            var alignment = Companion_getInstance_1().k38_1;
            var renderer = get_DefaultStringTextRenderer();
            var autoScaling = Companion_instance.qb0_1;
            var this_5 = new Text(text, numberToDouble(24), color, font, alignment, renderer, autoScaling, null, null);
            var this_6 = addTo(this_5, this_4);
            xy(this_6, numberToDouble(10), numberToDouble(410));
            var scoreView = this_6;
            var gameOverView = {_v: null};
            var color_0 = Colors_getInstance().v2j_1;
            var this_7 = new SolidRect(Size2D_init_$Create$(30, 200), color_0);
            var this_8 = addTo(this_7, topLayer);
            xy(this_8, numberToDouble(410), numberToDouble(0));
            var startTimerView = this_8;
            addUpdater(this.abt_1, MyScene$sceneMain$lambda_3(field, this.ebt_1, fieldView, stagingView, particles, scoreView, this.dbt_1, this.cbt_1, this.bbt_1, startTimerView, gameOverView, topLayer));
            var this_9 = this.abt_1;
            var this_10 = get_mouse(this_9);
            var handler = MyScene$sceneMain$slambda_0(this.ebt_1, fieldView, this.zbs_1, null);
            var tmp_31 = click$factory_0().get(this_10);
            tmp_31.r4n(MyScene$sceneMain$lambda_4(this_10, handler));
            return Unit_instance;
          case 4:
            throw this.lc_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.jc_1 === 4) {
          throw e;
        } else {
          this.ic_1 = this.jc_1;
          this.lc_1 = e;
        }
      }
     while (true);
  };
  function MyScene(assetsLoader) {
    Scene.call(this);
    this.pbs_1 = assetsLoader;
  }
  protoOf(MyScene).sbu = function () {
    var tmp = this.qbs_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('assets');
    }
  };
  protoOf(MyScene).z9d = function (_this__u8e3s4, $completion) {
    var tmp = new $sceneInitCOROUTINE$1(this, _this__u8e3s4, $completion);
    tmp.kc_1 = Unit_instance;
    tmp.lc_1 = null;
    return tmp.tc();
  };
  protoOf(MyScene).a9e = function (_this__u8e3s4, $completion) {
    var tmp = new $sceneMainCOROUTINE$2(this, _this__u8e3s4, $completion);
    tmp.kc_1 = Unit_instance;
    tmp.lc_1 = null;
    return tmp.tc();
  };
  function main$slambda$lambda(it) {
    return it instanceof FixedSizeContainer;
  }
  function main$slambda$lambda_0($this$mapPrototype) {
    // Inline function 'korlibs.inject.Injector.get' call
    var tmp$ret$0 = $this$mapPrototype.nx(getKClass(AssetsLoader));
    return new MyScene(tmp$ret$0);
  }
  function main$slambda$lambda_1($this$mapPrototype) {
    return new MainMenuScene();
  }
  function main$slambda$lambda_2($this$mapSingleton) {
    return new AssetsLoader();
  }
  function main$slambda(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda).i8a = function ($this$Korge, $completion) {
    var tmp = this.j8a($this$Korge, $completion);
    tmp.kc_1 = Unit_instance;
    tmp.lc_1 = null;
    return tmp.tc();
  };
  protoOf(main$slambda).qd = function (p1, $completion) {
    return this.i8a(p1 instanceof Stage ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda).tc = function () {
    var suspendResult = this.kc_1;
    $sm: do
      try {
        var tmp = this.ic_1;
        switch (tmp) {
          case 0:
            this.jc_1 = 3;
            var tmp_0 = this;
            tmp_0.cbv_1 = this.bbv_1;
            var tmp_1 = this;
            tmp_1.dbv_1 = withEasing(get_AlphaTransition(), Companion_getInstance_2().q1e());
            var tmp_2 = this;
            tmp_2.ebv_1 = 'sceneContainer';
            var tmp_3 = this;
            tmp_3.fbv_1 = Companion_getInstance_3().i1s_1;
            var tmp_4 = this;
            tmp_4.gbv_1 = this.cbv_1;
            this.ic_1 = 1;
            suspendResult = views(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.hbv_1 = suspendResult;
            var tmp_5 = this;
            tmp_5.ibv_1 = this.dbv_1;
            var tmp_6 = this;
            tmp_6.jbv_1 = this.ebv_1;
            var tmp_7 = this;
            tmp_7.kbv_1 = this.fbv_1;
            this.lbv_1 = this.kbv_1;
            this.mbv_1 = this.lbv_1.bf();
            this.nbv_1 = this.lbv_1.cf();
            this.obv_1 = this.mbv_1;
            this.pbv_1 = this.nbv_1;
            if (this.mbv_1 === 0.0 ? this.nbv_1 === 0.0 : false) {
              var tmp_8 = findFirstAscendant(this.gbv_1, main$slambda$lambda);
              var base = (tmp_8 == null ? true : tmp_8 instanceof FixedSizeContainer) ? tmp_8 : null;
              var tmp_9 = this;
              var tmp2_elvis_lhs = base == null ? null : base.c10();
              tmp_9.obv_1 = tmp2_elvis_lhs == null ? this.hbv_1.t8f_1.c10() : tmp2_elvis_lhs;
              var tmp_10 = this;
              var tmp4_elvis_lhs = base == null ? null : base.d10();
              tmp_10.pbv_1 = tmp4_elvis_lhs == null ? this.hbv_1.t8f_1.d10() : tmp4_elvis_lhs;
            }

            var tmp_11 = this;
            var this_0 = new SceneContainer(this.hbv_1, this.ibv_1, this.jbv_1, new Size2D(this.obv_1, this.pbv_1));
            var instance = this.gbv_1;
            tmp_11.qbv_1 = addTo(this_0, instance);
            var this_1 = this.bbv_1.b9b();
            var gen = main$slambda$lambda_0;
            this_1.ix(getKClass(MyScene), gen);
            var this_2 = this.bbv_1.b9b();
            var gen_0 = main$slambda$lambda_1;
            this_2.ix(getKClass(MainMenuScene), gen_0);
            var this_3 = this.bbv_1.b9b();
            var gen_1 = main$slambda$lambda_2;
            this_3.hx(getKClass(AssetsLoader), gen_1);
            get_RegisteredImageFormats().p44([PNG_getInstance()]);
            var tmp_12 = this;
            tmp_12.rbv_1 = this.qbv_1;
            var tmp_13 = this;
            tmp_13.sbv_1 = [];
            var tmp_14 = this;
            tmp_14.tbv_1 = toDuration_0(0, DurationUnit_SECONDS_getInstance());
            var tmp_15 = this;
            tmp_15.ubv_1 = this.rbv_1.l8l_1;
            this.ic_1 = 2;
            suspendResult = this.rbv_1.g9m(getKClass(MainMenuScene), this.sbv_1.slice(), this.tbv_1, this.ubv_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return Unit_instance;
          case 3:
            throw this.lc_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.jc_1 === 3) {
          throw e;
        } else {
          this.ic_1 = this.jc_1;
          this.lc_1 = e;
        }
      }
     while (true);
  };
  protoOf(main$slambda).j8a = function ($this$Korge, completion) {
    var i = new main$slambda(completion);
    i.bbv_1 = $this$Korge;
    return i;
  };
  function main$slambda_0(resultContinuation) {
    var i = new main$slambda(resultContinuation);
    var l = function ($this$Korge, $completion) {
      return i.i8a($this$Korge, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function click$factory() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.k90_1;
    }, null);
  }
  function click$factory_0() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.k90_1;
    }, null);
  }
  function validDirections($this) {
    return listOf_0([$this.wbv_1, $this.wbv_1.obm()]);
  }
  function CornerPipe(length, direction) {
    Tile.call(this);
    this.vbv_1 = length;
    this.wbv_1 = direction;
    var tmp = this;
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    tmp.xbv_1 = toDuration(0, DurationUnit_SECONDS_getInstance());
    this.ybv_1 = null;
    this.zbv_1 = false;
  }
  protoOf(CornerPipe).pbn = function (dt) {
    if (!(this.ybv_1 == null)) {
      if (!this.zbv_1) {
        this.xbv_1 = Duration__plus_impl_yu9v8f(this.xbv_1, dt);
      }
      if ((!this.zbv_1 ? !(this.ybv_1 == null) : false) ? Duration__compareTo_impl_pchp0f(this.xbv_1, this.vbv_1) > 0 : false) {
        this.zbv_1 = true;
        var tmp$ret$1;
        $l$block: {
          // Inline function 'kotlin.collections.first' call
          var tmp0_iterator = validDirections(this).s();
          while (tmp0_iterator.t()) {
            var element = tmp0_iterator.u();
            // Inline function 'tile.CornerPipe.onUpdate.<anonymous>' call
            if (!element.equals(this.ybv_1)) {
              tmp$ret$1 = element;
              break $l$block;
            }
          }
          throw NoSuchElementException_init_$Create$('Collection contains no element matching the predicate.');
        }
        var outputDirection = tmp$ret$1;
        return new Overflow(Duration__minus_impl_q5cfm7(this.xbv_1, this.vbv_1), outputDirection, new Long(1000, 0));
      }
    }
    return null;
  };
  protoOf(CornerPipe).tbn = function (direction, dt) {
    var tmp;
    if (!(this.ybv_1 == null)) {
      tmp = false;
    } else if (validDirections(this).v(direction.nbm())) {
      this.ybv_1 = direction.nbm();
      tmp = true;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(CornerPipe).vbn = function () {
    return this.ybv_1 == null;
  };
  protoOf(CornerPipe).toString = function () {
    return 'CornerPipe(direction=' + this.wbv_1 + ', elapsed=' + new Duration(this.xbv_1) + ', liquidDirection=' + this.ybv_1 + ')';
  };
  function Inner(length, orientation) {
    this.abw_1 = length;
    this.bbw_1 = orientation;
    var tmp = this;
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    tmp.cbw_1 = toDuration(0, DurationUnit_SECONDS_getInstance());
    this.dbw_1 = null;
    this.ebw_1 = false;
  }
  protoOf(Inner).pbn = function (dt) {
    if (!(this.dbw_1 == null)) {
      if (!this.ebw_1) {
        this.cbw_1 = Duration__plus_impl_yu9v8f(this.cbw_1, dt);
      }
      if ((!this.ebw_1 ? !(this.dbw_1 == null) : false) ? Duration__compareTo_impl_pchp0f(this.cbw_1, this.abw_1) > 0 : false) {
        this.ebw_1 = true;
        return new Overflow(Duration__minus_impl_q5cfm7(this.cbw_1, this.abw_1), ensureNotNull(this.dbw_1), new Long(1000, 0));
      }
    }
    return null;
  };
  protoOf(Inner).tbn = function (direction, dt) {
    var tmp;
    if (!(this.dbw_1 == null)) {
      tmp = false;
    } else if (this.bbw_1.sbm_1.v(direction)) {
      this.dbw_1 = direction;
      tmp = true;
    } else {
      tmp = false;
    }
    return tmp;
  };
  function CrossPipe(length) {
    Tile.call(this);
    this.fbw_1 = mapOf([to(Orientation_VERTICAL_getInstance(), new Inner(length, Orientation_VERTICAL_getInstance())), to(Orientation_HORIZONTAL_getInstance(), new Inner(length, Orientation_HORIZONTAL_getInstance()))]);
  }
  protoOf(CrossPipe).pbn = function (dt) {
    var ret = null;
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = this.fbw_1.c2().s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      // Inline function 'tile.CrossPipe.onUpdate.<anonymous>' call
      var tmp0_elvis_lhs = element.e2().pbn(dt);
      ret = tmp0_elvis_lhs == null ? ret : tmp0_elvis_lhs;
    }
    var tmp;
    var tmp_0;
    if (ret instanceof Overflow) {
      var tmp$ret$1;
      $l$block_0: {
        // Inline function 'kotlin.collections.all' call
        var this_0 = this.fbw_1;
        if (this_0.x()) {
          tmp$ret$1 = true;
          break $l$block_0;
        }
        // Inline function 'kotlin.collections.iterator' call
        var tmp0_iterator_0 = this_0.c2().s();
        while (tmp0_iterator_0.t()) {
          var element_0 = tmp0_iterator_0.u();
          // Inline function 'tile.CrossPipe.onUpdate.<anonymous>' call
          if (!element_0.e2().ebw_1) {
            tmp$ret$1 = false;
            break $l$block_0;
          }
        }
        tmp$ret$1 = true;
      }
      tmp_0 = tmp$ret$1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      var tmp_1 = (ret instanceof Overflow ? ret : THROW_CCE()).qbn_1;
      tmp = new Overflow(tmp_1, (ret instanceof Overflow ? ret : THROW_CCE()).rbn_1, new Long(5000, 0));
    } else {
      tmp = ret;
    }
    return tmp;
  };
  protoOf(CrossPipe).tbn = function (direction, dt) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = this.fbw_1;
      if (this_0.x()) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      // Inline function 'kotlin.collections.iterator' call
      var tmp0_iterator = this_0.c2().s();
      while (tmp0_iterator.t()) {
        var element = tmp0_iterator.u();
        // Inline function 'tile.CrossPipe.takeLiquid.<anonymous>' call
        if (element.e2().tbn(direction, dt)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(CrossPipe).vbn = function () {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.none' call
      var this_0 = this.fbw_1.z1();
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.x();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.s();
      while (tmp0_iterator.t()) {
        var element = tmp0_iterator.u();
        // Inline function 'tile.CrossPipe.isEditable.<anonymous>' call
        if (!(element.dbw_1 == null)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  function EmptyTile() {
    Tile.call(this);
  }
  protoOf(EmptyTile).pbn = function (dt) {
    return null;
  };
  protoOf(EmptyTile).tbn = function (direction, dt) {
    return false;
  };
  protoOf(EmptyTile).vbn = function () {
    return true;
  };
  function StartPipe(length, direction) {
    Tile.call(this);
    this.kbn_1 = length;
    this.lbn_1 = direction;
    var tmp = this;
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    tmp.mbn_1 = toDuration(0, DurationUnit_SECONDS_getInstance());
    this.nbn_1 = null;
    this.obn_1 = false;
  }
  protoOf(StartPipe).pbn = function (dt) {
    if (!(this.nbn_1 == null)) {
      if (!this.obn_1) {
        println(this);
        this.mbn_1 = Duration__plus_impl_yu9v8f(this.mbn_1, dt);
      }
      if ((!this.obn_1 ? !(this.nbn_1 == null) : false) ? Duration__compareTo_impl_pchp0f(this.mbn_1, this.kbn_1) > 0 : false) {
        this.obn_1 = true;
        return new Overflow(Duration__minus_impl_q5cfm7(this.mbn_1, this.kbn_1), this.lbn_1, new Long(0, 0));
      }
    }
    return null;
  };
  protoOf(StartPipe).tbn = function (direction, dt) {
    return false;
  };
  protoOf(StartPipe).vbn = function () {
    return false;
  };
  protoOf(StartPipe).toString = function () {
    return 'StartPipe(direction=' + this.lbn_1 + ', elapsed=' + new Duration(this.mbn_1) + ', liquidDirection=' + this.nbn_1 + ')';
  };
  protoOf(StartPipe).jbn = function () {
    this.nbn_1 = this.lbn_1;
  };
  function StraightPipe(length, orientation) {
    Tile.call(this);
    this.gbw_1 = length;
    this.hbw_1 = orientation;
    var tmp = this;
    // Inline function 'kotlin.time.Companion.seconds' call
    Companion_getInstance_0();
    tmp.ibw_1 = toDuration(0, DurationUnit_SECONDS_getInstance());
    this.jbw_1 = null;
    this.kbw_1 = false;
  }
  protoOf(StraightPipe).pbn = function (dt) {
    if (!(this.jbw_1 == null)) {
      if (!this.kbw_1) {
        this.ibw_1 = Duration__plus_impl_yu9v8f(this.ibw_1, dt);
      }
      if ((!this.kbw_1 ? !(this.jbw_1 == null) : false) ? Duration__compareTo_impl_pchp0f(this.ibw_1, this.gbw_1) > 0 : false) {
        this.kbw_1 = true;
        return new Overflow(Duration__minus_impl_q5cfm7(this.ibw_1, this.gbw_1), ensureNotNull(this.jbw_1), new Long(1000, 0));
      }
    }
    return null;
  };
  protoOf(StraightPipe).tbn = function (direction, dt) {
    var tmp;
    if (!(this.jbw_1 == null)) {
      tmp = false;
    } else if (this.hbw_1.sbm_1.v(direction)) {
      this.jbw_1 = direction;
      tmp = true;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(StraightPipe).vbn = function () {
    return this.jbw_1 == null;
  };
  function Tile() {
  }
  function Overflow(dt, direction, score) {
    this.qbn_1 = dt;
    this.rbn_1 = direction;
    this.sbn_1 = score;
    println(this);
  }
  protoOf(Overflow).toString = function () {
    return 'Overflow(dt=' + new Duration(this.qbn_1) + ', direction=' + this.rbn_1 + ', score=' + this.sbn_1.toString() + ')';
  };
  protoOf(Overflow).hashCode = function () {
    var result = Duration__hashCode_impl_u4exz6(this.qbn_1);
    result = imul(result, 31) + this.rbn_1.hashCode() | 0;
    result = imul(result, 31) + this.sbn_1.hashCode() | 0;
    return result;
  };
  protoOf(Overflow).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Overflow))
      return false;
    var tmp0_other_with_cast = other instanceof Overflow ? other : THROW_CCE();
    if (!equals(this.qbn_1, tmp0_other_with_cast.qbn_1))
      return false;
    if (!this.rbn_1.equals(tmp0_other_with_cast.rbn_1))
      return false;
    if (!this.sbn_1.equals(tmp0_other_with_cast.sbn_1))
      return false;
    return true;
  };
  function Particle(position, velocity, acceleration, angle, angularVelocity, view, lifetime) {
    this.lbw_1 = position;
    this.mbw_1 = velocity;
    this.nbw_1 = acceleration;
    this.obw_1 = angle;
    this.pbw_1 = angularVelocity;
    this.qbw_1 = view;
    this.rbw_1 = lifetime;
  }
  protoOf(Particle).toString = function () {
    return 'Particle(position=' + this.lbw_1 + ', velocity=' + this.mbw_1 + ', acceleration=' + this.nbw_1 + ', angle=' + new Angle(this.obw_1) + ', angularVelocity=' + new Angle(this.pbw_1) + ', view=' + this.qbw_1 + ', lifetime=' + new Duration(this.rbw_1) + ')';
  };
  protoOf(Particle).hashCode = function () {
    var result = this.lbw_1.hashCode();
    result = imul(result, 31) + this.mbw_1.hashCode() | 0;
    result = imul(result, 31) + this.nbw_1.hashCode() | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.obw_1) | 0;
    result = imul(result, 31) + Angle__hashCode_impl_szcndt(this.pbw_1) | 0;
    result = imul(result, 31) + hashCode(this.qbw_1) | 0;
    result = imul(result, 31) + Duration__hashCode_impl_u4exz6(this.rbw_1) | 0;
    return result;
  };
  protoOf(Particle).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Particle))
      return false;
    var tmp0_other_with_cast = other instanceof Particle ? other : THROW_CCE();
    if (!this.lbw_1.equals(tmp0_other_with_cast.lbw_1))
      return false;
    if (!this.mbw_1.equals(tmp0_other_with_cast.mbw_1))
      return false;
    if (!this.nbw_1.equals(tmp0_other_with_cast.nbw_1))
      return false;
    if (!equals(this.obw_1, tmp0_other_with_cast.obw_1))
      return false;
    if (!equals(this.pbw_1, tmp0_other_with_cast.pbw_1))
      return false;
    if (!equals(this.qbw_1, tmp0_other_with_cast.qbw_1))
      return false;
    if (!equals(this.rbw_1, tmp0_other_with_cast.rbw_1))
      return false;
    return true;
  };
  function Particles(assets, sContainer, viewRectangle) {
    this.cbq_1 = assets;
    this.dbq_1 = sContainer;
    this.ebq_1 = viewRectangle;
    var tmp = this;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp.fbq_1 = ArrayList_init_$Create$_0();
  }
  protoOf(Particles).gbq = function (particle) {
    this.fbq_1.p(particle);
  };
  protoOf(Particles).bb8 = function (dt) {
    // Inline function 'kotlin.collections.mutableListOf' call
    var toRemove = ArrayList_init_$Create$_0();
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = this.fbq_1.s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      // Inline function 'view.Particles.update.<anonymous>' call
      var tmp = element;
      // Inline function 'korlibs.math.geom.Vector2F.plus' call
      var this_0 = element.lbw_1;
      var that = times(get_seconds(dt), element.mbw_1);
      tmp.lbw_1 = new Vector2F(this_0.p1q_1 + that.p1q_1, this_0.q1q_1 + that.q1q_1);
      var tmp_0 = element;
      // Inline function 'korlibs.math.geom.Vector2F.plus' call
      var this_1 = element.mbw_1;
      var that_0 = times(get_seconds(dt), element.nbw_1);
      tmp_0.mbw_1 = new Vector2F(this_1.p1q_1 + that_0.p1q_1, this_1.q1q_1 + that_0.q1q_1);
      element.obw_1 = Angle__plus_impl_26v37k(element.obw_1, Angle__times_impl_v3s9y2(element.pbw_1, get_seconds(dt)));
      element.rbw_1 = Duration__minus_impl_q5cfm7(element.rbw_1, dt);
      element.qbw_1.f9t(element.lbw_1.p1q_1 - element.qbw_1.c10() * 0.5);
      element.qbw_1.g9t(element.lbw_1.q1q_1 - element.qbw_1.d10() * 0.5);
      element.qbw_1.r9t(element.obw_1);
      var tmp_1 = element.rbw_1;
      // Inline function 'korlibs.time.seconds' call
      // Inline function 'korlibs.time.seconds' call
      var tmp$ret$4 = toDuration_0(0, DurationUnit_SECONDS_getInstance());
      if (Duration__compareTo_impl_pchp0f(tmp_1, tmp$ret$4) <= 0) {
        toRemove.p(element);
      }
      if (!this.ebq_1.o1q(element.lbw_1)) {
        toRemove.p(element);
      }
    }
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator_0 = toRemove.s();
    while (tmp0_iterator_0.t()) {
      var element_0 = tmp0_iterator_0.u();
      // Inline function 'view.Particles.update.<anonymous>' call
      element_0.qbw_1.x9u();
    }
    this.fbq_1.n1(toRemove);
  };
  function crossPipe($this, tile, tileRect) {
    // Inline function 'korlibs.korge.view.sprite' call
    var this_0 = $this.jbq_1;
    var texture = $this.ibq_1.cbm_1;
    var anchor = Companion_getInstance_5().j1i_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = new Sprite(texture, anchor);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, this_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'view.PlayFieldView.crossPipe.<anonymous>' call
    position(this_2, tileRect.u1q(), tileRect.v1q());
    get_centered(this_2);
    size(this_2, $this.nbq_1);
    $this.mbq_1.p(this_2);
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = tile.fbw_1.c2().s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      // Inline function 'view.PlayFieldView.crossPipe.<anonymous>' call
      var innerTile = element.e2();
      // Inline function 'korlibs.korge.view.sprite' call
      var this_3 = $this.jbq_1;
      var initialAnimation = $this.ibq_1.ibm_1;
      var anchor_0 = Companion_getInstance_5().j1i_1;
      // Inline function 'korlibs.korge.view.addTo' call
      var this_4 = Sprite_init_$Create$(initialAnimation, anchor_0);
      // Inline function 'kotlin.apply' call
      var this_5 = addTo(this_4, this_3);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'view.PlayFieldView.crossPipe.<anonymous>.<anonymous>' call
      position(this_5, tileRect.u1q(), tileRect.v1q());
      get_centered(this_5);
      size(this_5, $this.nbq_1);
      var tmp0_safe_receiver = innerTile.dbw_1;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.pbm();
      var tmp;
      var tmp_0 = tmp1_elvis_lhs;
      if ((tmp_0 == null ? null : new Angle(tmp_0)) == null) {
        tmp = Companion_getInstance().x1i_1;
      } else {
        tmp = tmp1_elvis_lhs;
      }
      rotation(this_5, tmp);
      updateLiquidFrame(this_5, $this, !(innerTile.dbw_1 == null), innerTile.cbw_1, innerTile.abw_1);
      var liquidView = this_5;
      $this.mbq_1.p(liquidView);
    }
  }
  function straightPipe($this, tile, tileRect) {
    // Inline function 'korlibs.korge.view.sprite' call
    var this_0 = $this.jbq_1;
    var texture = $this.ibq_1.ebm_1;
    var anchor = Companion_getInstance_5().j1i_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = new Sprite(texture, anchor);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, this_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'view.PlayFieldView.straightPipe.<anonymous>' call
    position(this_2, tileRect.u1q(), tileRect.v1q());
    get_centered(this_2);
    size(this_2, $this.nbq_1);
    rotation(this_2, first(tile.hbw_1.sbm_1).pbm());
    $this.mbq_1.p(this_2);
    // Inline function 'korlibs.korge.view.sprite' call
    var this_3 = $this.jbq_1;
    var initialAnimation = $this.ibq_1.ibm_1;
    var anchor_0 = Companion_getInstance_5().j1i_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_4 = Sprite_init_$Create$(initialAnimation, anchor_0);
    // Inline function 'kotlin.apply' call
    var this_5 = addTo(this_4, this_3);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'view.PlayFieldView.straightPipe.<anonymous>' call
    position(this_5, tileRect.u1q(), tileRect.v1q());
    get_centered(this_5);
    size(this_5, $this.nbq_1);
    var tmp0_safe_receiver = tile.jbw_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.pbm();
    var tmp;
    var tmp_0 = tmp1_elvis_lhs;
    if ((tmp_0 == null ? null : new Angle(tmp_0)) == null) {
      tmp = Companion_getInstance().x1i_1;
    } else {
      tmp = tmp1_elvis_lhs;
    }
    rotation(this_5, tmp);
    updateLiquidFrame(this_5, $this, !(tile.jbw_1 == null), tile.ibw_1, tile.gbw_1);
    var liquidView = this_5;
    $this.mbq_1.p(liquidView);
  }
  function startPipe($this, tile, tileRect) {
    // Inline function 'korlibs.korge.view.sprite' call
    var this_0 = $this.jbq_1;
    var texture = $this.ibq_1.bbm_1;
    var anchor = Companion_getInstance_5().j1i_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = new Sprite(texture, anchor);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, this_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'view.PlayFieldView.startPipe.<anonymous>' call
    position(this_2, tileRect.u1q(), tileRect.v1q());
    get_centered(this_2);
    size(this_2, tileRect.l());
    rotation(this_2, tile.lbn_1.pbm());
    $this.mbq_1.p(this_2);
    // Inline function 'korlibs.korge.view.sprite' call
    var this_3 = $this.jbq_1;
    var initialAnimation = $this.ibq_1.fbm_1;
    var anchor_0 = Companion_getInstance_5().j1i_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_4 = Sprite_init_$Create$(initialAnimation, anchor_0);
    // Inline function 'kotlin.apply' call
    var this_5 = addTo(this_4, this_3);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'view.PlayFieldView.startPipe.<anonymous>' call
    position(this_5, tileRect.u1q(), tileRect.v1q());
    get_centered(this_5);
    size(this_5, tileRect.l());
    rotation(this_5, tile.lbn_1.pbm());
    updateLiquidFrame(this_5, $this, !(tile.nbn_1 == null), tile.mbn_1, tile.kbn_1);
    var liquidView = this_5;
    $this.mbq_1.p(liquidView);
  }
  function updateLiquidFrame(_this__u8e3s4, $this, liquid, elapsed, length) {
    if (liquid) {
      var elapsedRatio = coerceAtMost(Duration__div_impl_dknbf4(elapsed, length), 0.9999);
      var frame = numberToInt((_this__u8e3s4.ib0() - 1 | 0) * elapsedRatio);
      _this__u8e3s4.eb0(1 + frame | 0);
    } else {
      _this__u8e3s4.eb0(0);
    }
  }
  function cornerPipe($this, tile, tileRect) {
    // Inline function 'korlibs.korge.view.sprite' call
    var this_0 = $this.jbq_1;
    var texture = $this.ibq_1.abm_1;
    var anchor = Companion_getInstance_5().j1i_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_1 = new Sprite(texture, anchor);
    // Inline function 'kotlin.apply' call
    var this_2 = addTo(this_1, this_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'view.PlayFieldView.cornerPipe.<anonymous>' call
    position(this_2, tileRect.u1q(), tileRect.v1q());
    get_centered(this_2);
    size(this_2, $this.nbq_1);
    rotation(this_2, tile.wbv_1.pbm());
    $this.mbq_1.p(this_2);
    var tmp;
    if (tile.wbv_1.equals(tile.ybv_1)) {
      // Inline function 'korlibs.korge.view.sprite' call
      var this_3 = $this.jbq_1;
      var initialAnimation = $this.ibq_1.hbm_1;
      var anchor_0 = Companion_getInstance_5().j1i_1;
      // Inline function 'korlibs.korge.view.addTo' call
      var this_4 = Sprite_init_$Create$(initialAnimation, anchor_0);
      // Inline function 'kotlin.apply' call
      var this_5 = addTo(this_4, this_3);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'view.PlayFieldView.cornerPipe.<anonymous>' call
      position(this_5, tileRect.u1q(), tileRect.v1q());
      get_centered(this_5);
      size(this_5, $this.nbq_1);
      rotation(this_5, tile.wbv_1.pbm());
      updateLiquidFrame(this_5, $this, !(tile.ybv_1 == null), tile.xbv_1, tile.vbv_1);
      tmp = this_5;
    } else {
      // Inline function 'korlibs.korge.view.sprite' call
      var this_6 = $this.jbq_1;
      var initialAnimation_0 = $this.ibq_1.gbm_1;
      var anchor_1 = Companion_getInstance_5().j1i_1;
      // Inline function 'korlibs.korge.view.addTo' call
      var this_7 = Sprite_init_$Create$(initialAnimation_0, anchor_1);
      // Inline function 'kotlin.apply' call
      var this_8 = addTo(this_7, this_6);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'view.PlayFieldView.cornerPipe.<anonymous>' call
      position(this_8, tileRect.u1q(), tileRect.v1q());
      get_centered(this_8);
      size(this_8, $this.nbq_1);
      rotation(this_8, tile.wbv_1.pbm());
      updateLiquidFrame(this_8, $this, !(tile.ybv_1 == null), tile.xbv_1, tile.vbv_1);
      tmp = this_8;
    }
    var liquidView = tmp;
    $this.mbq_1.p(liquidView);
  }
  function PlayFieldView(playField, assets, sContainer, viewRectangle) {
    this.hbq_1 = playField;
    this.ibq_1 = assets;
    this.jbq_1 = sContainer;
    this.kbq_1 = viewRectangle;
    this.lbq_1 = Companion_getInstance_6().m1a(ensureNotNull(getKClassFromExpression(this).l6()));
    var tmp = this;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp.mbq_1 = ArrayList_init_$Create$_0();
    this.nbq_1 = new Size2D(this.kbq_1.q1j_1 / this.hbq_1.bbn_1, this.kbq_1.r1j_1 / this.hbq_1.cbn_1);
  }
  protoOf(PlayFieldView).bb8 = function (dt) {
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = this.mbq_1.s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      // Inline function 'view.PlayFieldView.update.<anonymous>' call
      element.x9u();
    }
    this.mbq_1.l1();
    // Inline function 'kotlin.collections.forEachIndexed' call
    var index = 0;
    var tmp0_iterator_0 = this.hbq_1.fbn_1.s();
    while (tmp0_iterator_0.t()) {
      var item = tmp0_iterator_0.u();
      // Inline function 'view.PlayFieldView.update.<anonymous>' call
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      var x = checkIndexOverflow(tmp1);
      // Inline function 'kotlin.collections.forEachIndexed' call
      var index_0 = 0;
      var tmp0_iterator_1 = item.s();
      while (tmp0_iterator_1.t()) {
        var item_0 = tmp0_iterator_1.u();
        // Inline function 'view.PlayFieldView.update.<anonymous>.<anonymous>' call
        var tmp1_0 = index_0;
        index_0 = tmp1_0 + 1 | 0;
        var y = checkIndexOverflow(tmp1_0);
        var tilePos = Vector2F_init_$Create$(this.kbq_1.o1j_1 + x * this.nbq_1.e1q_1, this.kbq_1.p1j_1 + y * this.nbq_1.f1q_1);
        // Inline function 'korlibs.math.geom.Companion.invoke' call
        Companion_getInstance_4();
        var x_0 = tilePos.p1q_1;
        var y_0 = tilePos.q1q_1;
        var width = this.nbq_1.e1q_1;
        var height = this.nbq_1.f1q_1;
        var tmp = Companion_getInstance_4();
        // Inline function 'korlibs.math.geom.Companion.invoke' call
        Companion_getInstance_7();
        var tmp_0 = new Vector2D(numberToDouble(x_0), numberToDouble(y_0));
        // Inline function 'korlibs.math.geom.Companion.invoke' call
        Companion_getInstance_3();
        var tmp$ret$1 = new Size2D(numberToDouble(width), numberToDouble(height));
        var tileRect = tmp.i1k(tmp_0, tmp$ret$1);
        // Inline function 'korlibs.korge.view.sprite' call
        var this_0 = this.jbq_1;
        var texture = this.ibq_1.zbl_1;
        var anchor = Companion_getInstance_5().j1i_1;
        // Inline function 'korlibs.korge.view.addTo' call
        var this_1 = new Sprite(texture, anchor);
        // Inline function 'kotlin.apply' call
        var this_2 = addTo(this_1, this_0);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'view.PlayFieldView.update.<anonymous>.<anonymous>.<anonymous>' call
        // Inline function 'korlibs.korge.view.position' call
        var x_1 = tilePos.p1q_1;
        var y_1 = tilePos.q1q_1;
        xy(this_2, numberToDouble(x_1), numberToDouble(y_1));
        size(this_2, this.nbq_1);
        this.mbq_1.p(this_2);
        if (!(item_0 instanceof EmptyTile)) {
          if (item_0 instanceof CornerPipe) {
            cornerPipe(this, item_0, tileRect);
          } else {
            if (item_0 instanceof CrossPipe) {
              crossPipe(this, item_0, tileRect);
            } else {
              if (item_0 instanceof StartPipe) {
                startPipe(this, item_0, tileRect);
              } else {
                if (item_0 instanceof StraightPipe) {
                  straightPipe(this, item_0, tileRect);
                }
              }
            }
          }
        }
      }
    }
  };
  protoOf(PlayFieldView).kbr = function (it) {
    var clickPos = it.d92();
    if (this.kbq_1.m1q(clickPos)) {
      var indexX = (clickPos.m1e_1 - this.kbq_1.o1j_1) / this.nbq_1.e1q_1;
      var indexY = (clickPos.n1e_1 - this.kbq_1.p1j_1) / this.nbq_1.f1q_1;
      this.hbq_1.ubn(numberToInt(indexX), numberToInt(indexY));
    } else {
      // Inline function 'korlibs.logger.Logger.debug' call
      var this_0 = this.lbq_1;
      var level = Level_DEBUG_getInstance();
      var tmp;
      if (this_0.a1b(level)) {
        // Inline function 'view.PlayFieldView.onClick.<anonymous>' call
        var tmp$ret$0 = 'ignoring click outside view.PlayFieldView at ' + clickPos;
        this_0.b1b(level, tmp$ret$0);
        tmp = Unit_instance;
      }
    }
  };
  function baseCornerPipe(_this__u8e3s4, assets, tileRect, tile) {
    // Inline function 'korlibs.korge.view.sprite' call
    var texture = assets.abm_1;
    var anchor = Companion_getInstance_5().j1i_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = new Sprite(texture, anchor);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'view.baseCornerPipe.<anonymous>' call
    position(this_1, tileRect.u1q(), tileRect.v1q());
    get_centered(this_1);
    size(this_1, tileRect.l());
    rotation(this_1, tile.wbv_1.pbm());
    return this_1;
  }
  function baseStraigthPipe(_this__u8e3s4, assets, tileRect, tile) {
    // Inline function 'korlibs.korge.view.sprite' call
    var texture = assets.ebm_1;
    var anchor = Companion_getInstance_5().j1i_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = new Sprite(texture, anchor);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'view.baseStraigthPipe.<anonymous>' call
    position(this_1, tileRect.u1q(), tileRect.v1q());
    get_centered(this_1);
    size(this_1, tileRect.l());
    rotation(this_1, first(tile.hbw_1.sbm_1).pbm());
    return this_1;
  }
  function baseCrossPipe(_this__u8e3s4, assets, tileRect, tile) {
    // Inline function 'korlibs.korge.view.sprite' call
    var texture = assets.cbm_1;
    var anchor = Companion_getInstance_5().j1i_1;
    // Inline function 'korlibs.korge.view.addTo' call
    var this_0 = new Sprite(texture, anchor);
    // Inline function 'kotlin.apply' call
    var this_1 = addTo(this_0, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'view.baseCrossPipe.<anonymous>' call
    position(this_1, tileRect.u1q(), tileRect.v1q());
    get_centered(this_1);
    size(this_1, tileRect.l());
    return this_1;
  }
  function StagingAreaView(stagingArea, assets, sContainer, viewRectangle) {
    this.obq_1 = stagingArea;
    this.pbq_1 = assets;
    this.qbq_1 = sContainer;
    this.rbq_1 = viewRectangle;
    var tmp = this;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp.sbq_1 = ArrayList_init_$Create$_0();
    this.tbq_1 = new Size2D(this.rbq_1.q1j_1, this.rbq_1.r1j_1 / this.obq_1.xbm_1);
  }
  protoOf(StagingAreaView).bb8 = function (dt) {
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = this.sbq_1.s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      // Inline function 'view.StagingAreaView.update.<anonymous>' call
      element.x9u();
    }
    this.sbq_1.l1();
    // Inline function 'kotlin.collections.forEachIndexed' call
    var index = 0;
    var tmp0_iterator_0 = this.obq_1.wbm_1.s();
    while (tmp0_iterator_0.t()) {
      var item = tmp0_iterator_0.u();
      // Inline function 'view.StagingAreaView.update.<anonymous>' call
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      var y = checkIndexOverflow(tmp1);
      var tilePos = Vector2F_init_$Create$(this.rbq_1.o1j_1, this.rbq_1.p1j_1 + y * this.tbq_1.f1q_1);
      // Inline function 'korlibs.math.geom.Companion.invoke' call
      Companion_getInstance_4();
      var x = tilePos.p1q_1;
      var y_0 = tilePos.q1q_1;
      var width = this.tbq_1.e1q_1;
      var height = this.tbq_1.f1q_1;
      var tmp = Companion_getInstance_4();
      // Inline function 'korlibs.math.geom.Companion.invoke' call
      Companion_getInstance_7();
      var tmp_0 = new Vector2D(numberToDouble(x), numberToDouble(y_0));
      // Inline function 'korlibs.math.geom.Companion.invoke' call
      Companion_getInstance_3();
      var tmp$ret$1 = new Size2D(numberToDouble(width), numberToDouble(height));
      var tileRect = tmp.i1k(tmp_0, tmp$ret$1);
      // Inline function 'korlibs.korge.view.sprite' call
      var this_0 = this.qbq_1;
      var texture = this.pbq_1.zbl_1;
      var anchor = Companion_getInstance_5().j1i_1;
      // Inline function 'korlibs.korge.view.addTo' call
      var this_1 = new Sprite(texture, anchor);
      // Inline function 'kotlin.apply' call
      var this_2 = addTo(this_1, this_0);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'view.StagingAreaView.update.<anonymous>.<anonymous>' call
      // Inline function 'korlibs.korge.view.position' call
      var x_0 = tilePos.p1q_1;
      var y_1 = tilePos.q1q_1;
      xy(this_2, numberToDouble(x_0), numberToDouble(y_1));
      size(this_2, this.tbq_1);
      this.sbq_1.p(this_2);
      var tmp_1;
      if (item instanceof EmptyTile) {
        tmp_1 = null;
      } else {
        if (item instanceof CornerPipe) {
          tmp_1 = baseCornerPipe(this.qbq_1, this.pbq_1, tileRect, item);
        } else {
          if (item instanceof CrossPipe) {
            tmp_1 = baseCrossPipe(this.qbq_1, this.pbq_1, tileRect, item);
          } else {
            if (item instanceof StartPipe) {
              tmp_1 = null;
            } else {
              if (item instanceof StraightPipe) {
                tmp_1 = baseStraigthPipe(this.qbq_1, this.pbq_1, tileRect, item);
              } else {
                noWhenBranchMatchedException();
              }
            }
          }
        }
      }
      var tmp1_safe_receiver = tmp_1;
      if (tmp1_safe_receiver == null)
        null;
      else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        this.sbq_1.p(tmp1_safe_receiver);
      }
    }
  };
  //region block: init
  Companion_instance_0 = new Companion();
  //endregion
  //region block: exports
  kotlin_com_soywiz_korge_korge_core.$jsExportAll$(_);
  //endregion
  main(get_EmptyContinuation());
  return _;
}));
