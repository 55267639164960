(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js', './korge-root-korlibs-concurrent.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'), require('./korge-root-korlibs-concurrent.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-datastructure'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'korge-root-korlibs-datastructure'.");
    }
    if (typeof this['korge-root-korlibs-concurrent'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-datastructure'. Its dependency 'korge-root-korlibs-concurrent' was not found. Please, check whether 'korge-root-korlibs-concurrent' is loaded prior to 'korge-root-korlibs-datastructure'.");
    }
    root['korge-root-korlibs-datastructure'] = factory(typeof this['korge-root-korlibs-datastructure'] === 'undefined' ? {} : this['korge-root-korlibs-datastructure'], this['kotlin-kotlin-stdlib'], this['korge-root-korlibs-concurrent']);
  }
}(this, function (_, kotlin_kotlin, kotlin_com_soywiz_korge_korlibs_concurrent) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var log2 = Math.log2;
  var protoOf = kotlin_kotlin.$_$.y9;
  var objectMeta = kotlin_kotlin.$_$.x9;
  var setMetadataFor = kotlin_kotlin.$_$.z9;
  var Unit_instance = kotlin_kotlin.$_$.g3;
  var contentEquals = kotlin_kotlin.$_$.e4;
  var contentHashCode = kotlin_kotlin.$_$.f4;
  var intArrayIterator = kotlin_kotlin.$_$.f9;
  var classMeta = kotlin_kotlin.$_$.t8;
  var VOID = kotlin_kotlin.$_$.d;
  var contentEquals_0 = kotlin_kotlin.$_$.c4;
  var contentHashCode_0 = kotlin_kotlin.$_$.i4;
  var floatArrayIterator = kotlin_kotlin.$_$.y8;
  var toString = kotlin_kotlin.$_$.da;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.j1;
  var reverse = kotlin_kotlin.$_$.m6;
  var objectCreate = kotlin_kotlin.$_$.w9;
  var copyOf = kotlin_kotlin.$_$.n4;
  var THROW_CCE = kotlin_kotlin.$_$.md;
  var isDoubleArray = kotlin_kotlin.$_$.l9;
  var IndexOutOfBoundsException_init_$Create$ = kotlin_kotlin.$_$.l1;
  var take = kotlin_kotlin.$_$.u6;
  var equals = kotlin_kotlin.$_$.v8;
  var List = kotlin_kotlin.$_$.m3;
  var isInterface = kotlin_kotlin.$_$.o9;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.y;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var Collection = kotlin_kotlin.$_$.k3;
  var isIntArray = kotlin_kotlin.$_$.n9;
  var copyOf_0 = kotlin_kotlin.$_$.q4;
  var take_0 = kotlin_kotlin.$_$.t6;
  var copyOf_1 = kotlin_kotlin.$_$.o4;
  var isFloatArray = kotlin_kotlin.$_$.m9;
  var take_1 = kotlin_kotlin.$_$.s6;
  var compareTo = kotlin_kotlin.$_$.u8;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.r;
  var MutableMap = kotlin_kotlin.$_$.q3;
  var hashCode = kotlin_kotlin.$_$.e9;
  var fillArrayVal = kotlin_kotlin.$_$.x8;
  var isArray = kotlin_kotlin.$_$.h9;
  var toList = kotlin_kotlin.$_$.z6;
  var until = kotlin_kotlin.$_$.wa;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.y3;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var to = kotlin_kotlin.$_$.fe;
  var Map_0 = kotlin_kotlin.$_$.n3;
  var StringBuilder_init_$Create$_0 = kotlin_kotlin.$_$.z;
  var MutableIterable = kotlin_kotlin.$_$.o3;
  var addAll = kotlin_kotlin.$_$.s3;
  var MutableList = kotlin_kotlin.$_$.p3;
  var interfaceMeta = kotlin_kotlin.$_$.g9;
  var AbstractMutableSet = kotlin_kotlin.$_$.i3;
  var LinkedHashSet_init_$Create$ = kotlin_kotlin.$_$.s;
  var IndexOutOfBoundsException_init_$Create$_0 = kotlin_kotlin.$_$.m1;
  var NoSuchElementException_init_$Create$ = kotlin_kotlin.$_$.n1;
  var joinToString = kotlin_kotlin.$_$.l5;
  var Iterable = kotlin_kotlin.$_$.l3;
  var copyOf_2 = kotlin_kotlin.$_$.p4;
  var IllegalStateException_init_$Create$_0 = kotlin_kotlin.$_$.i1;
  var numberToInt = kotlin_kotlin.$_$.u9;
  var NonRecursiveLock = kotlin_com_soywiz_korge_korlibs_concurrent.$_$.b;
  var Comparator = kotlin_kotlin.$_$.dd;
  var reversed = kotlin_kotlin.$_$.n7;
  var NotImplementedError = kotlin_kotlin.$_$.jd;
  var toSet = kotlin_kotlin.$_$.h7;
  var contentEquals_1 = kotlin_kotlin.$_$.b4;
  var contentHashCode_1 = kotlin_kotlin.$_$.g4;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ce;
  var toRawBits = kotlin_kotlin.$_$.de;
  var Lock = kotlin_com_soywiz_korge_korlibs_concurrent.$_$.a;
  var toMutableList = kotlin_kotlin.$_$.d7;
  var mapCapacity = kotlin_kotlin.$_$.y5;
  var coerceAtLeast = kotlin_kotlin.$_$.ma;
  var LinkedHashMap_init_$Create$_0 = kotlin_kotlin.$_$.q;
  var toMutableMap = kotlin_kotlin.$_$.f7;
  var toMutableSet = kotlin_kotlin.$_$.g7;
  var Pair = kotlin_kotlin.$_$.kd;
  var toMap = kotlin_kotlin.$_$.c7;
  var step = kotlin_kotlin.$_$.ua;
  var arrayCopy = kotlin_kotlin.$_$.u3;
  var AbstractMutableList = kotlin_kotlin.$_$.h3;
  var copyToArray = kotlin_kotlin.$_$.r4;
  var get_lastIndex = kotlin_kotlin.$_$.p5;
  var joinToString_0 = kotlin_kotlin.$_$.m5;
  var RandomAccess = kotlin_kotlin.$_$.r3;
  var toList_0 = kotlin_kotlin.$_$.a7;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  function getAt(x, y) {
    return this.e10(index(this, x, y));
  }
  function asString() {
    var tmp = until(0, this.d10());
    return joinToString(tmp, '\n', VOID, VOID, VOID, VOID, IArray2$asString$lambda(this));
  }
  setMetadataFor(IArray2, 'IArray2', interfaceMeta, VOID, [Iterable]);
  setMetadataFor(IntArray2, 'IntArray2', classMeta, VOID, [IArray2]);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(FloatArray2, 'FloatArray2', classMeta, VOID, [IArray2]);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(DoubleArrayList, 'DoubleArrayList', classMeta, VOID, [Collection], DoubleArrayList);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(IntArrayList, 'IntArrayList', classMeta, VOID, [Collection], IntArrayList);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(FloatArrayList, 'FloatArrayList', classMeta, VOID, [Collection], FloatArrayList);
  setMetadataFor(SortOps, 'SortOps', classMeta);
  setMetadataFor(DoubleArrayListSortOps, 'DoubleArrayListSortOps', objectMeta, SortOps);
  setMetadataFor(CaseInsensitiveStringMap, 'CaseInsensitiveStringMap', classMeta, VOID, [MutableMap], CaseInsensitiveStringMap_init_$Create$);
  setMetadataFor(TGenDeque$iterator$1, VOID, classMeta);
  setMetadataFor(TGenDeque, 'TGenDeque', classMeta, VOID, [Collection, MutableIterable], TGenDeque_init_$Create$);
  setMetadataFor(IntDeque$iterator$1, VOID, classMeta);
  setMetadataFor(IntDeque, 'IntDeque', classMeta, VOID, [Collection, MutableIterable], IntDeque_init_$Create$);
  function setAddAll(index, elements, offset, size) {
    // Inline function 'kotlin.math.min' call
    var b = this.l() - index | 0;
    var setCount = Math.min(size, b);
    this.c14(index, elements, offset, setCount);
    this.d14(elements, offset + setCount | 0, size - setCount | 0);
  }
  function setAddAll$default(index, elements, offset, size, $super) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? elements.l() - offset | 0 : size;
    var tmp;
    if ($super === VOID) {
      this.b14(index, elements, offset, size);
      tmp = Unit_instance;
    } else {
      setAddAll(index, elements, offset, size);
      tmp = Unit_instance;
    }
    return tmp;
  }
  function setAll(index, elements, offset, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.m(index + n | 0, elements.b1(offset + n | 0));
      }
       while (inductionVariable < size);
  }
  function addAll_0(elements, offset, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.p(elements.b1(offset + n | 0));
      }
       while (inductionVariable < size);
  }
  function removeToSize(size) {
    this.m1(size, this.l());
  }
  setMetadataFor(MutableListEx, 'MutableListEx', interfaceMeta, VOID, [MutableList]);
  setMetadataFor(FastSmallSet, 'FastSmallSet', classMeta, AbstractMutableSet, VOID, FastSmallSet);
  setMetadataFor(GenericListIterator, 'GenericListIterator', classMeta);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  setMetadataFor(Entry, 'Entry', classMeta);
  setMetadataFor(Iterator, 'Iterator', classMeta);
  setMetadataFor(_no_name_provided__qut3iv, VOID, classMeta, VOID, [Iterable]);
  setMetadataFor(_no_name_provided__qut3iv_0, VOID, classMeta, VOID, [Iterable]);
  setMetadataFor(IntMap, 'IntMap', classMeta, VOID, VOID, IntMap_init_$Create$);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor(IntIntMap, 'IntIntMap', classMeta, VOID, VOID, IntIntMap_init_$Create$);
  setMetadataFor(Iterator$1, VOID, classMeta);
  setMetadataFor(ListReader, 'ListReader', classMeta);
  setMetadataFor(Pool, 'Pool', classMeta);
  setMetadataFor(ConcurrentPool, 'ConcurrentPool', classMeta, Pool);
  setMetadataFor(Companion_7, 'Companion', objectMeta);
  setMetadataFor(ReturnablePool, 'ReturnablePool', classMeta);
  setMetadataFor(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', classMeta, VOID, [Comparator]);
  setMetadataFor(Companion_8, 'Companion', objectMeta);
  setMetadataFor(TGenPriorityQueue$iterator$1, VOID, classMeta);
  setMetadataFor(TGenPriorityQueue, 'TGenPriorityQueue', classMeta, VOID, [Collection, MutableIterable]);
  setMetadataFor(TGenQueue, 'TGenQueue', classMeta, VOID, [Collection], TGenQueue);
  setMetadataFor(Ref$2, VOID, classMeta);
  setMetadataFor(Ref$3, VOID, classMeta);
  setMetadataFor(ByteRingBuffer, 'ByteRingBuffer', classMeta);
  setMetadataFor(RingBuffer, 'RingBuffer', classMeta, ByteRingBuffer);
  setMetadataFor(Companion_9, 'Companion', objectMeta);
  setMetadataFor(TGenStack, 'TGenStack', classMeta, VOID, [Collection]);
  setMetadataFor(Closeable, 'Closeable', interfaceMeta);
  setMetadataFor(SyncPauseable, 'SyncPauseable', classMeta, VOID, VOID, SyncPauseable);
  setMetadataFor(Mixin, 'Mixin', classMeta, VOID, VOID, Mixin);
  setMetadataFor(Property, 'Property', classMeta);
  setMetadataFor(PropertyThis, 'PropertyThis', classMeta);
  setMetadataFor(ExtraObject, 'ExtraObject', classMeta, VOID, [MutableMap], ExtraObject);
  setMetadataFor(Math_0, 'Math', objectMeta);
  setMetadataFor(Memory, 'Memory', objectMeta);
  setMetadataFor(FastArrayList, 'FastArrayList', classMeta, AbstractMutableList, [AbstractMutableList, MutableListEx, RandomAccess], FastArrayList_init_$Create$);
  //endregion
  function Companion() {
  }
  var Companion_instance;
  function Companion_getInstance() {
    return Companion_instance;
  }
  function IntArray2(width, height, data) {
    this.yz_1 = width;
    this.zz_1 = height;
    this.a10_1 = data;
    Companion_instance_4.b10(this.yz_1, this.zz_1, this.a10_1.length);
  }
  protoOf(IntArray2).c10 = function () {
    return this.yz_1;
  };
  protoOf(IntArray2).d10 = function () {
    return this.zz_1;
  };
  protoOf(IntArray2).e10 = function (idx) {
    return this.a10_1[idx];
  };
  protoOf(IntArray2).equals = function (other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof IntArray2) {
      tmp_1 = this.yz_1 === other.yz_1;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.zz_1 === other.zz_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals(this.a10_1, other.a10_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(IntArray2).f10 = function (x, y) {
    return this.a10_1[index(this, x, y)];
  };
  protoOf(IntArray2).g10 = function (x, y, value) {
    this.a10_1[index(this, x, y)] = value;
  };
  protoOf(IntArray2).hashCode = function () {
    return (this.yz_1 + this.zz_1 | 0) + contentHashCode(this.a10_1) | 0;
  };
  protoOf(IntArray2).s = function () {
    return intArrayIterator(this.a10_1);
  };
  protoOf(IntArray2).toString = function () {
    return this.h10();
  };
  function Companion_0() {
  }
  var Companion_instance_0;
  function Companion_getInstance_0() {
    return Companion_instance_0;
  }
  function FloatArray2(width, height, data) {
    this.j10_1 = width;
    this.k10_1 = height;
    this.l10_1 = data;
    Companion_instance_4.b10(this.j10_1, this.k10_1, this.l10_1.length);
  }
  protoOf(FloatArray2).c10 = function () {
    return this.j10_1;
  };
  protoOf(FloatArray2).d10 = function () {
    return this.k10_1;
  };
  protoOf(FloatArray2).e10 = function (idx) {
    return this.l10_1[idx];
  };
  protoOf(FloatArray2).equals = function (other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof FloatArray2) {
      tmp_1 = this.j10_1 === other.j10_1;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.k10_1 === other.k10_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals_0(this.l10_1, other.l10_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(FloatArray2).f10 = function (x, y) {
    return this.l10_1[index(this, x, y)];
  };
  protoOf(FloatArray2).m10 = function (x, y, value) {
    this.l10_1[index(this, x, y)] = value;
  };
  protoOf(FloatArray2).hashCode = function () {
    return (this.j10_1 + this.k10_1 | 0) + contentHashCode_0(this.l10_1) | 0;
  };
  protoOf(FloatArray2).s = function () {
    return floatArrayIterator(this.l10_1);
  };
  protoOf(FloatArray2).toString = function () {
    return this.h10();
  };
  function swap(_this__u8e3s4, lIndex, rIndex) {
    var temp = _this__u8e3s4[lIndex];
    _this__u8e3s4[lIndex] = _this__u8e3s4[rIndex];
    _this__u8e3s4[rIndex] = temp;
  }
  function rotateRight(_this__u8e3s4, offset) {
    offset = offset === VOID ? 1 : offset;
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure._rotateRight' call
      var size = _this__u8e3s4.length;
      var offset_0 = Math_instance.o10(offset, size);
      if (offset_0 === 0) {
        tmp$ret$0 = Unit_instance;
        break $l$block;
      }
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      if (!(1 <= offset_0 ? offset_0 < size : false)) {
        // Inline function 'kotlin.check.<anonymous>' call
        var message = 'Check failed.';
        throw IllegalStateException_init_$Create$(toString(message));
      }
      // Inline function 'korlibs.datastructure.rotateRight.<anonymous>' call
      reverse(_this__u8e3s4, 0, size);
      // Inline function 'korlibs.datastructure.rotateRight.<anonymous>' call
      reverse(_this__u8e3s4, 0, offset_0);
      // Inline function 'korlibs.datastructure.rotateRight.<anonymous>' call
      reverse(_this__u8e3s4, offset_0, size);
    }
    return tmp$ret$0;
  }
  function Companion_1() {
  }
  var Companion_instance_1;
  function Companion_getInstance_1() {
    return Companion_instance_1;
  }
  function DoubleArrayList_init_$Init$(other, $this) {
    DoubleArrayList.call($this);
    $this.r10(other);
    return $this;
  }
  function DoubleArrayList_init_$Create$(other) {
    return DoubleArrayList_init_$Init$(other, objectCreate(protoOf(DoubleArrayList)));
  }
  function DoubleArrayList_init_$Init$_0(other, $this) {
    DoubleArrayList.call($this);
    $this.s10(other);
    return $this;
  }
  function DoubleArrayList_init_$Create$_0(other) {
    return DoubleArrayList_init_$Init$_0(other, objectCreate(protoOf(DoubleArrayList)));
  }
  function ensure($this, count) {
    if (($this.q10_1 + count | 0) > $this.p10_1.length) {
      var tmp = $this;
      var tmp_0 = $this.p10_1;
      // Inline function 'kotlin.math.max' call
      var a = $this.q10_1 + count | 0;
      var b = imul($this.p10_1.length, 3);
      var tmp$ret$0 = Math.max(a, b);
      var tmp_1 = copyOf(tmp_0, tmp$ret$0);
      tmp.p10_1 = isDoubleArray(tmp_1) ? tmp_1 : THROW_CCE();
    }
  }
  function DoubleArrayList(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    var tmp = this;
    var tmp_0 = new Float64Array(capacity);
    tmp.p10_1 = isDoubleArray(tmp_0) ? tmp_0 : THROW_CCE();
    this.q10_1 = 0;
  }
  protoOf(DoubleArrayList).t10 = function (value) {
    ensure(this, value);
    this.q10_1 = value;
  };
  protoOf(DoubleArrayList).l = function () {
    return this.q10_1;
  };
  protoOf(DoubleArrayList).l1 = function () {
    this.q10_1 = 0;
  };
  protoOf(DoubleArrayList).u10 = function (v0) {
    ensure(this, 1);
    var tmp = this.p10_1;
    var tmp1 = this.q10_1;
    this.q10_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
  };
  protoOf(DoubleArrayList).v10 = function (v0, v1) {
    ensure(this, 2);
    var tmp = this.p10_1;
    var tmp1 = this.q10_1;
    this.q10_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.p10_1;
    var tmp3 = this.q10_1;
    this.q10_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
  };
  protoOf(DoubleArrayList).w10 = function (v0, v1, v2, v3) {
    ensure(this, 4);
    var tmp = this.p10_1;
    var tmp1 = this.q10_1;
    this.q10_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.p10_1;
    var tmp3 = this.q10_1;
    this.q10_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
    var tmp_1 = this.p10_1;
    var tmp5 = this.q10_1;
    this.q10_1 = tmp5 + 1 | 0;
    tmp_1[tmp5] = v2;
    var tmp_2 = this.p10_1;
    var tmp7 = this.q10_1;
    this.q10_1 = tmp7 + 1 | 0;
    tmp_2[tmp7] = v3;
  };
  protoOf(DoubleArrayList).x10 = function (v0, v1, v2, v3, v4, v5) {
    ensure(this, 6);
    var tmp = this.p10_1;
    var tmp1 = this.q10_1;
    this.q10_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.p10_1;
    var tmp3 = this.q10_1;
    this.q10_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
    var tmp_1 = this.p10_1;
    var tmp5 = this.q10_1;
    this.q10_1 = tmp5 + 1 | 0;
    tmp_1[tmp5] = v2;
    var tmp_2 = this.p10_1;
    var tmp7 = this.q10_1;
    this.q10_1 = tmp7 + 1 | 0;
    tmp_2[tmp7] = v3;
    var tmp_3 = this.p10_1;
    var tmp9 = this.q10_1;
    this.q10_1 = tmp9 + 1 | 0;
    tmp_3[tmp9] = v4;
    var tmp_4 = this.p10_1;
    var tmp11 = this.q10_1;
    this.q10_1 = tmp11 + 1 | 0;
    tmp_4[tmp11] = v5;
  };
  protoOf(DoubleArrayList).y10 = function (value) {
    return this.u10(value);
  };
  protoOf(DoubleArrayList).z10 = function (value) {
    return this.r10(value);
  };
  protoOf(DoubleArrayList).a11 = function (values, offset, length) {
    ensure(this, length);
    var tmp = Memory_instance;
    tmp.b11(isDoubleArray(values) ? values : THROW_CCE(), offset, this.p10_1, this.l(), length);
    this.t10(this.l() + length | 0);
  };
  protoOf(DoubleArrayList).s10 = function (values, offset, length, $super) {
    offset = offset === VOID ? 0 : offset;
    length = length === VOID ? values.length : length;
    var tmp;
    if ($super === VOID) {
      this.a11(values, offset, length);
      tmp = Unit_instance;
    } else {
      tmp = $super.a11.call(this, values, offset, length);
    }
    return tmp;
  };
  protoOf(DoubleArrayList).r10 = function (values) {
    return this.a11(values.p10_1, 0, values.l());
  };
  protoOf(DoubleArrayList).b1 = function (index) {
    return this.p10_1[index];
  };
  protoOf(DoubleArrayList).e10 = function (index) {
    return this.p10_1[index];
  };
  protoOf(DoubleArrayList).c11 = function (index, value) {
    if (index >= this.q10_1) {
      ensure(this, index + 1 | 0);
      this.q10_1 = index + 1 | 0;
    }
    this.p10_1[index] = value;
  };
  protoOf(DoubleArrayList).s = function () {
    return this.p1(0);
  };
  protoOf(DoubleArrayList).d11 = function (element) {
    var inductionVariable = 0;
    var last = this.q10_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.p10_1[n] === element)
          return true;
      }
       while (inductionVariable < last);
    return false;
  };
  protoOf(DoubleArrayList).v = function (element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.d11((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(DoubleArrayList).e11 = function (elements) {
    var tmp0_iterator = elements.s();
    while (tmp0_iterator.t()) {
      var e = tmp0_iterator.u();
      if (!this.d11(e))
        return false;
    }
    return true;
  };
  protoOf(DoubleArrayList).w = function (elements) {
    return this.e11(elements);
  };
  protoOf(DoubleArrayList).x = function () {
    return this.l() === 0;
  };
  protoOf(DoubleArrayList).f11 = function (indexA, indexB) {
    var l = this.e10(indexA);
    var r = this.e10(indexB);
    this.c11(indexA, r);
    this.c11(indexB, l);
  };
  protoOf(DoubleArrayList).e1 = function (index) {
    return this.g11(index, 1);
  };
  protoOf(DoubleArrayList).g11 = function (index, count) {
    if ((index < 0 ? true : index >= this.q10_1) ? true : (index + count | 0) > this.q10_1)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.p10_1[index];
    if (count > 0) {
      if (index < (this.q10_1 - count | 0)) {
        Memory_instance.b11(this.p10_1, index + count | 0, this.p10_1, index, (this.q10_1 - index | 0) - count | 0);
      }
      this.q10_1 = this.q10_1 - count | 0;
    }
    return out;
  };
  protoOf(DoubleArrayList).h11 = function () {
    return copyOf(this.p10_1, this.q10_1);
  };
  protoOf(DoubleArrayList).p1 = function (index) {
    return take(this.p10_1, this.q10_1).p1(index);
  };
  protoOf(DoubleArrayList).i11 = function () {
    return DoubleArrayList_init_$Create$(this);
  };
  protoOf(DoubleArrayList).hashCode = function () {
    return contentHashCode_2(this.p10_1, 0, this.l());
  };
  protoOf(DoubleArrayList).equals = function (other) {
    if (other instanceof DoubleArrayList)
      return this.l() === other.l() ? contentEquals_2(this.p10_1, other.p10_1, 0, this.l()) : false;
    if (!(other == null) ? isInterface(other, List) : false)
      return equals(other, this);
    return false;
  };
  protoOf(DoubleArrayList).toString = function () {
    // Inline function 'kotlin.also' call
    var this_0 = StringBuilder_init_$Create$(2 + imul(5, this.l()) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.DoubleArrayList.toString.<anonymous>' call
    this_0.j5(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.i5(', ');
        }
        this_0.w7(this.e10(n));
      }
       while (inductionVariable < last);
    this_0.j5(_Char___init__impl__6a9atx(93));
    return this_0.toString();
  };
  function Companion_2() {
  }
  var Companion_instance_2;
  function Companion_getInstance_2() {
    return Companion_instance_2;
  }
  function IntArrayList_init_$Init$(other, $this) {
    IntArrayList.call($this);
    $this.l11(other);
    return $this;
  }
  function IntArrayList_init_$Create$(other) {
    return IntArrayList_init_$Init$(other, objectCreate(protoOf(IntArrayList)));
  }
  function IntArrayList_init_$Init$_0(other, $this) {
    IntArrayList.call($this);
    $this.m11(other);
    return $this;
  }
  function IntArrayList_init_$Create$_0(other) {
    return IntArrayList_init_$Init$_0(other, objectCreate(protoOf(IntArrayList)));
  }
  function IntArrayList(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    var tmp = this;
    var tmp_0 = new Int32Array(capacity);
    tmp.j11_1 = isIntArray(tmp_0) ? tmp_0 : THROW_CCE();
    this.k11_1 = 0;
  }
  protoOf(IntArrayList).t10 = function (value) {
    this.n11(value);
    this.k11_1 = value;
  };
  protoOf(IntArrayList).l = function () {
    return this.k11_1;
  };
  protoOf(IntArrayList).n11 = function (count) {
    if ((this.k11_1 + count | 0) > this.j11_1.length) {
      var tmp = this;
      var tmp_0 = this.j11_1;
      // Inline function 'kotlin.math.max' call
      var a = this.k11_1 + count | 0;
      var b = imul(this.j11_1.length, 3);
      var tmp$ret$0 = Math.max(a, b);
      var tmp_1 = copyOf_0(tmp_0, tmp$ret$0);
      tmp.j11_1 = isIntArray(tmp_1) ? tmp_1 : THROW_CCE();
    }
  };
  protoOf(IntArrayList).l1 = function () {
    this.k11_1 = 0;
  };
  protoOf(IntArrayList).o11 = function (v0) {
    this.n11(1);
    var tmp = this.j11_1;
    var tmp1 = this.k11_1;
    this.k11_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
  };
  protoOf(IntArrayList).p11 = function (value) {
    return this.o11(value);
  };
  protoOf(IntArrayList).q11 = function (value) {
    return this.l11(value);
  };
  protoOf(IntArrayList).r11 = function (values, offset, length) {
    this.n11(length);
    var tmp = Memory_instance;
    tmp.s11(isIntArray(values) ? values : THROW_CCE(), offset, this.j11_1, this.l(), length);
    this.t10(this.l() + length | 0);
  };
  protoOf(IntArrayList).m11 = function (values, offset, length, $super) {
    offset = offset === VOID ? 0 : offset;
    length = length === VOID ? values.length : length;
    var tmp;
    if ($super === VOID) {
      this.r11(values, offset, length);
      tmp = Unit_instance;
    } else {
      tmp = $super.r11.call(this, values, offset, length);
    }
    return tmp;
  };
  protoOf(IntArrayList).l11 = function (values) {
    return this.r11(values.j11_1, 0, values.l());
  };
  protoOf(IntArrayList).b1 = function (index) {
    return this.j11_1[index];
  };
  protoOf(IntArrayList).e10 = function (index) {
    return this.j11_1[index];
  };
  protoOf(IntArrayList).t11 = function (index, value) {
    if (index >= this.k11_1) {
      this.n11(index + 1 | 0);
      this.k11_1 = index + 1 | 0;
    }
    this.j11_1[index] = value;
  };
  protoOf(IntArrayList).s = function () {
    return this.p1(0);
  };
  protoOf(IntArrayList).ah = function (element) {
    var inductionVariable = 0;
    var last = this.k11_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.j11_1[n] === element)
          return true;
      }
       while (inductionVariable < last);
    return false;
  };
  protoOf(IntArrayList).v = function (element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.ah((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(IntArrayList).u11 = function (elements) {
    var tmp0_iterator = elements.s();
    while (tmp0_iterator.t()) {
      var e = tmp0_iterator.u();
      if (!this.ah(e))
        return false;
    }
    return true;
  };
  protoOf(IntArrayList).w = function (elements) {
    return this.u11(elements);
  };
  protoOf(IntArrayList).x = function () {
    return this.l() === 0;
  };
  protoOf(IntArrayList).v11 = function () {
    return !(this.l() === 0);
  };
  protoOf(IntArrayList).w11 = function () {
    var tmp;
    if (this.x()) {
      throw IndexOutOfBoundsException_init_$Create$();
    } else {
      tmp = this.j11_1[0];
    }
    return tmp;
  };
  protoOf(IntArrayList).x11 = function () {
    var tmp;
    if (this.x()) {
      throw IndexOutOfBoundsException_init_$Create$();
    } else {
      tmp = this.j11_1[this.k11_1 - 1 | 0];
    }
    return tmp;
  };
  protoOf(IntArrayList).y11 = function (index, value) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntArrayList.insertAt.<anonymous>' call
    this.n11(1);
    if (this.v11()) {
      Memory_instance.s11(this.j11_1, index, this.j11_1, index + 1 | 0, this.k11_1 - index | 0);
    }
    this.j11_1[index] = value;
    this.k11_1 = this.k11_1 + 1 | 0;
    return this;
  };
  protoOf(IntArrayList).f11 = function (indexA, indexB) {
    var l = this.e10(indexA);
    var r = this.e10(indexB);
    this.t11(indexA, r);
    this.t11(indexB, l);
  };
  protoOf(IntArrayList).g11 = function (index, count) {
    if ((index < 0 ? true : index >= this.k11_1) ? true : (index + count | 0) > this.k11_1)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.j11_1[index];
    if (count > 0) {
      if (index < (this.k11_1 - count | 0)) {
        Memory_instance.s11(this.j11_1, index + count | 0, this.j11_1, index, (this.k11_1 - index | 0) - count | 0);
      }
      this.k11_1 = this.k11_1 - count | 0;
    }
    return out;
  };
  protoOf(IntArrayList).z11 = function () {
    return copyOf_0(this.j11_1, this.k11_1);
  };
  protoOf(IntArrayList).p1 = function (index) {
    return take_0(this.j11_1, this.k11_1).p1(index);
  };
  protoOf(IntArrayList).hashCode = function () {
    return contentHashCode_3(this.j11_1, 0, this.l());
  };
  protoOf(IntArrayList).equals = function (other) {
    if (other instanceof IntArrayList)
      return this.l() === other.l() ? contentEquals_3(this.j11_1, other.j11_1, 0, this.l()) : false;
    if (!(other == null) ? isInterface(other, List) : false)
      return equals(other, this);
    return false;
  };
  protoOf(IntArrayList).toString = function () {
    // Inline function 'kotlin.also' call
    var this_0 = StringBuilder_init_$Create$(2 + imul(5, this.l()) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntArrayList.toString.<anonymous>' call
    this_0.j5(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.i5(', ');
        }
        this_0.t7(this.e10(n));
      }
       while (inductionVariable < last);
    this_0.j5(_Char___init__impl__6a9atx(93));
    return this_0.toString();
  };
  function intArrayListOf(values) {
    return IntArrayList_init_$Create$_0(values.slice());
  }
  function toIntArrayList(_this__u8e3s4) {
    return IntArrayList_init_$Create$_0(_this__u8e3s4.slice());
  }
  function Companion_3() {
  }
  var Companion_instance_3;
  function Companion_getInstance_3() {
    return Companion_instance_3;
  }
  function FloatArrayList_init_$Init$(other, $this) {
    FloatArrayList.call($this);
    $this.c12(other);
    return $this;
  }
  function FloatArrayList_init_$Create$(other) {
    return FloatArrayList_init_$Init$(other, objectCreate(protoOf(FloatArrayList)));
  }
  function ensure_0($this, count) {
    if (($this.b12_1 + count | 0) > $this.a12_1.length) {
      var tmp = $this;
      var tmp_0 = $this.a12_1;
      // Inline function 'kotlin.math.max' call
      var a = $this.b12_1 + count | 0;
      var b = imul($this.a12_1.length, 3);
      var tmp$ret$0 = Math.max(a, b);
      var tmp_1 = copyOf_1(tmp_0, tmp$ret$0);
      tmp.a12_1 = isFloatArray(tmp_1) ? tmp_1 : THROW_CCE();
    }
  }
  function FloatArrayList(capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    var tmp = this;
    var tmp_0 = new Float32Array(capacity);
    tmp.a12_1 = isFloatArray(tmp_0) ? tmp_0 : THROW_CCE();
    this.b12_1 = 0;
  }
  protoOf(FloatArrayList).t10 = function (value) {
    ensure_0(this, value);
    this.b12_1 = value;
  };
  protoOf(FloatArrayList).l = function () {
    return this.b12_1;
  };
  protoOf(FloatArrayList).l1 = function () {
    this.b12_1 = 0;
  };
  protoOf(FloatArrayList).d12 = function (v0, v1, v2, v3) {
    ensure_0(this, 4);
    var tmp = this.a12_1;
    var tmp1 = this.b12_1;
    this.b12_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.a12_1;
    var tmp3 = this.b12_1;
    this.b12_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
    var tmp_1 = this.a12_1;
    var tmp5 = this.b12_1;
    this.b12_1 = tmp5 + 1 | 0;
    tmp_1[tmp5] = v2;
    var tmp_2 = this.a12_1;
    var tmp7 = this.b12_1;
    this.b12_1 = tmp7 + 1 | 0;
    tmp_2[tmp7] = v3;
  };
  protoOf(FloatArrayList).e12 = function (values, offset, length) {
    ensure_0(this, length);
    var tmp = Memory_instance;
    tmp.f12(isFloatArray(values) ? values : THROW_CCE(), offset, this.a12_1, this.l(), length);
    this.t10(this.l() + length | 0);
  };
  protoOf(FloatArrayList).c12 = function (values, offset, length, $super) {
    offset = offset === VOID ? 0 : offset;
    length = length === VOID ? values.length : length;
    var tmp;
    if ($super === VOID) {
      this.e12(values, offset, length);
      tmp = Unit_instance;
    } else {
      tmp = $super.e12.call(this, values, offset, length);
    }
    return tmp;
  };
  protoOf(FloatArrayList).e10 = function (index) {
    return this.a12_1[index];
  };
  protoOf(FloatArrayList).s = function () {
    return this.p1(0);
  };
  protoOf(FloatArrayList).g12 = function (element) {
    var inductionVariable = 0;
    var last = this.b12_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.a12_1[n] === element)
          return true;
      }
       while (inductionVariable < last);
    return false;
  };
  protoOf(FloatArrayList).v = function (element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.g12((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(FloatArrayList).h12 = function (elements) {
    var tmp0_iterator = elements.s();
    while (tmp0_iterator.t()) {
      var e = tmp0_iterator.u();
      if (!this.g12(e))
        return false;
    }
    return true;
  };
  protoOf(FloatArrayList).w = function (elements) {
    return this.h12(elements);
  };
  protoOf(FloatArrayList).x = function () {
    return this.l() === 0;
  };
  protoOf(FloatArrayList).i12 = function () {
    return copyOf_1(this.a12_1, this.b12_1);
  };
  protoOf(FloatArrayList).p1 = function (index) {
    return take_1(this.a12_1, this.b12_1).p1(index);
  };
  protoOf(FloatArrayList).hashCode = function () {
    return contentHashCode_4(this.a12_1, 0, this.l());
  };
  protoOf(FloatArrayList).equals = function (other) {
    if (other instanceof FloatArrayList)
      return this.l() === other.l() ? contentEquals_4(this.a12_1, other.a12_1, 0, this.l()) : false;
    if (!(other == null) ? isInterface(other, List) : false)
      return equals(other, this);
    return false;
  };
  protoOf(FloatArrayList).toString = function () {
    // Inline function 'kotlin.also' call
    var this_0 = StringBuilder_init_$Create$(2 + imul(5, this.l()) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.FloatArrayList.toString.<anonymous>' call
    this_0.j5(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          this_0.i5(', ');
        }
        this_0.v7(this.e10(n));
      }
       while (inductionVariable < last);
    this_0.j5(_Char___init__impl__6a9atx(93));
    return this_0.toString();
  };
  function floatArrayListOf(values) {
    return FloatArrayList_init_$Create$(values.slice());
  }
  function doubleArrayListOf(values) {
    return DoubleArrayList_init_$Create$_0(values.slice());
  }
  function sort(_this__u8e3s4, start, end, reversed) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? _this__u8e3s4.l() : end;
    reversed = reversed === VOID ? false : reversed;
    return genericSort_0(_this__u8e3s4, start, end - 1 | 0, DoubleArrayListSortOps_getInstance(), reversed);
  }
  function reverse_0(_this__u8e3s4, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? _this__u8e3s4.l() : end;
    return DoubleArrayListSortOps_getInstance().j12(_this__u8e3s4, start, end - 1 | 0);
  }
  function DoubleArrayListSortOps() {
    DoubleArrayListSortOps_instance = this;
    SortOps.call(this);
  }
  protoOf(DoubleArrayListSortOps).k12 = function (subject, l, r) {
    return compareTo(subject.e10(l), subject.e10(r));
  };
  protoOf(DoubleArrayListSortOps).l12 = function (subject, l, r) {
    return this.k12(subject instanceof DoubleArrayList ? subject : THROW_CCE(), l, r);
  };
  protoOf(DoubleArrayListSortOps).m12 = function (subject, indexL, indexR) {
    var l = subject.e10(indexL);
    var r = subject.e10(indexR);
    subject.c11(indexR, l);
    subject.c11(indexL, r);
  };
  protoOf(DoubleArrayListSortOps).n12 = function (subject, indexL, indexR) {
    return this.m12(subject instanceof DoubleArrayList ? subject : THROW_CCE(), indexL, indexR);
  };
  var DoubleArrayListSortOps_instance;
  function DoubleArrayListSortOps_getInstance() {
    if (DoubleArrayListSortOps_instance == null)
      new DoubleArrayListSortOps();
    return DoubleArrayListSortOps_instance;
  }
  function CaseInsensitiveStringMap_init_$Init$($this) {
    CaseInsensitiveStringMap.call($this, LinkedHashMap_init_$Create$(), LinkedHashMap_init_$Create$(), LinkedHashMap_init_$Create$());
    return $this;
  }
  function CaseInsensitiveStringMap_init_$Create$() {
    return CaseInsensitiveStringMap_init_$Init$(objectCreate(protoOf(CaseInsensitiveStringMap)));
  }
  function CaseInsensitiveStringMap(mapOrig, lcToOrig, mapLC) {
    this.p12_1 = mapOrig;
    this.q12_1 = lcToOrig;
    this.r12_1 = mapLC;
  }
  protoOf(CaseInsensitiveStringMap).c2 = function () {
    return this.p12_1.c2();
  };
  protoOf(CaseInsensitiveStringMap).y1 = function () {
    return this.p12_1.y1();
  };
  protoOf(CaseInsensitiveStringMap).l = function () {
    return this.p12_1.l();
  };
  protoOf(CaseInsensitiveStringMap).z1 = function () {
    return this.p12_1.z1();
  };
  protoOf(CaseInsensitiveStringMap).x = function () {
    return this.p12_1.x();
  };
  protoOf(CaseInsensitiveStringMap).s12 = function (key) {
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = key.toLowerCase();
    return this.r12_1.h2(tmp$ret$1);
  };
  protoOf(CaseInsensitiveStringMap).h2 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return false;
    return this.s12((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(CaseInsensitiveStringMap).t12 = function (key) {
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$1 = key.toLowerCase();
    return this.r12_1.k2(tmp$ret$1);
  };
  protoOf(CaseInsensitiveStringMap).k2 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.t12((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(CaseInsensitiveStringMap).u12 = function (key, value) {
    this.v12(key);
    // Inline function 'kotlin.collections.set' call
    this.p12_1.a2(key, value);
    // Inline function 'kotlin.collections.set' call
    var this_0 = this.q12_1;
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var key_0 = key.toLowerCase();
    this_0.a2(key_0, key);
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$3 = key.toLowerCase();
    return this.r12_1.a2(tmp$ret$3, value);
  };
  protoOf(CaseInsensitiveStringMap).a2 = function (key, value) {
    var tmp = (!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE();
    return this.u12(tmp, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  };
  protoOf(CaseInsensitiveStringMap).w12 = function (from) {
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = from.c2().s();
    while (tmp0_iterator.t()) {
      var v = tmp0_iterator.u();
      this.u12(v.d2(), v.e2());
    }
  };
  protoOf(CaseInsensitiveStringMap).v12 = function (key) {
    // Inline function 'kotlin.text.toLowerCase' call
    // Inline function 'kotlin.js.asDynamic' call
    var lkey = key.toLowerCase();
    var okey = this.q12_1.k2(lkey);
    // Inline function 'kotlin.collections.remove' call
    var this_0 = this.p12_1;
    (isInterface(this_0, MutableMap) ? this_0 : THROW_CCE()).b2(okey);
    var res = this.r12_1.b2(lkey);
    this.q12_1.b2(lkey);
    return res;
  };
  protoOf(CaseInsensitiveStringMap).b2 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.v12((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(CaseInsensitiveStringMap).equals = function (other) {
    var tmp;
    if (other instanceof CaseInsensitiveStringMap) {
      tmp = equals(this.r12_1, other.r12_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(CaseInsensitiveStringMap).hashCode = function () {
    return hashCode(this.r12_1);
  };
  function toCaseInsensitiveMap(_this__u8e3s4) {
    // Inline function 'kotlin.also' call
    var this_0 = CaseInsensitiveStringMap_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.toCaseInsensitiveMap.<anonymous>' call
    this_0.w12(_this__u8e3s4);
    return this_0;
  }
  function _get__data__kyoi3c($this) {
    // Inline function 'korlibs.datastructure.fastCastTo' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return $this.z12_1;
  }
  function _get_capacity__a9k9f3($this) {
    return $this.z12_1.length;
  }
  function TGenDeque_init_$Init$($this) {
    TGenDeque.call($this, 16);
    return $this;
  }
  function TGenDeque_init_$Create$() {
    return TGenDeque_init_$Init$(objectCreate(protoOf(TGenDeque)));
  }
  function resizeIfRequiredFor($this, count) {
    if (($this.l() + count | 0) > _get_capacity__a9k9f3($this)) {
      var i = $this.z12_1;
      var istart = $this.x12_1;
      // Inline function 'kotlin.arrayOfNulls' call
      // Inline function 'kotlin.comparisons.maxOf' call
      var a = $this.z12_1.length + 7 | 0;
      // Inline function 'kotlin.comparisons.maxOf' call
      var a_0 = $this.l() + count | 0;
      var b = imul($this.z12_1.length, 2);
      var b_0 = Math.max(a_0, b);
      var size = Math.max(a, b_0);
      var _o = fillArrayVal(Array(size), null);
      var o = isArray(_o) ? _o : THROW_CCE();
      copyCyclic($this, i, istart, o, $this.y12_1);
      $this.z12_1 = o;
      $this.x12_1 = 0;
    }
  }
  function copyCyclic($this, i, istart, o, count) {
    // Inline function 'kotlin.math.min' call
    var a = i.length - istart | 0;
    var size1 = Math.min(a, count);
    var size2 = count - size1 | 0;
    Memory_instance.a13(i, istart, o, 0, size1);
    if (size2 > 0) {
      Memory_instance.a13(i, 0, o, size1, size2);
    }
  }
  function _addLast($this, item) {
    $this.z12_1[($this.x12_1 + $this.y12_1 | 0) % _get_capacity__a9k9f3($this) | 0] = item;
    $this.y12_1 = $this.y12_1 + 1 | 0;
  }
  function nullify($this, index) {
    _get__data__kyoi3c($this)[index] = null;
  }
  function internalIndex($this, index) {
    return Math_instance.o10($this.x12_1 + index | 0, _get_capacity__a9k9f3($this));
  }
  function TGenDeque$iterator$1($that, this$0) {
    this.c13_1 = $that;
    this.d13_1 = this$0;
    this.b13_1 = 0;
  }
  protoOf(TGenDeque$iterator$1).u = function () {
    var tmp1 = this.b13_1;
    this.b13_1 = tmp1 + 1 | 0;
    return this.c13_1.b1(tmp1);
  };
  protoOf(TGenDeque$iterator$1).t = function () {
    return this.b13_1 < this.d13_1.l();
  };
  protoOf(TGenDeque$iterator$1).c1 = function () {
    this.b13_1 = this.b13_1 - 1 | 0;
    this.d13_1.e1(this.b13_1);
  };
  function TGenDeque(initialCapacity) {
    this.x12_1 = 0;
    this.y12_1 = 0;
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_0 = fillArrayVal(Array(initialCapacity), null);
    tmp.z12_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
  }
  protoOf(TGenDeque).l = function () {
    return this.y12_1;
  };
  protoOf(TGenDeque).x = function () {
    return this.l() === 0;
  };
  protoOf(TGenDeque).e13 = function (item) {
    resizeIfRequiredFor(this, 1);
    _addLast(this, item);
  };
  protoOf(TGenDeque).f13 = function (list) {
    // Inline function 'korlibs.datastructure.TGenDeque._addAll' call
    var count = list.l();
    resizeIfRequiredFor(this, count);
    var base = this.x12_1 + this.y12_1 | 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.z12_1;
        var tmp_0 = (base + n | 0) % _get_capacity__a9k9f3(this) | 0;
        // Inline function 'korlibs.datastructure.TGenDeque.addAll.<anonymous>' call
        tmp[tmp_0] = list.b1(n);
      }
       while (inductionVariable < count);
    this.y12_1 = this.y12_1 + count | 0;
    return true;
  };
  protoOf(TGenDeque).g13 = function (elements) {
    return this.f13(toList(elements));
  };
  protoOf(TGenDeque).q = function (elements) {
    return this.g13(elements);
  };
  protoOf(TGenDeque).je = function () {
    if (this.y12_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.h13();
    nullify(this, this.x12_1);
    this.x12_1 = (this.x12_1 + 1 | 0) % _get_capacity__a9k9f3(this) | 0;
    this.y12_1 = this.y12_1 - 1 | 0;
    return out;
  };
  protoOf(TGenDeque).le = function () {
    if (this.y12_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.i13();
    nullify(this, internalIndex(this, this.l() - 1 | 0));
    this.y12_1 = this.y12_1 - 1 | 0;
    return out;
  };
  protoOf(TGenDeque).e1 = function (index) {
    if (index < 0 ? true : index >= this.l())
      throw IndexOutOfBoundsException_init_$Create$();
    if (index === 0)
      return this.je();
    if (index === (this.l() - 1 | 0))
      return this.le();
    var old = this.b1(index);
    if (index < (this.l() / 2 | 0)) {
      var inductionVariable = index;
      if (1 <= inductionVariable)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          this.j13(n, this.b1(n - 1 | 0));
        }
         while (1 <= inductionVariable);
      this.x12_1 = Math_instance.o10(this.x12_1 + 1 | 0, _get_capacity__a9k9f3(this));
    } else {
      var inductionVariable_0 = index;
      var last = this.l() - 1 | 0;
      if (inductionVariable_0 < last)
        do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          this.j13(n_0, this.b1(n_0 + 1 | 0));
        }
         while (inductionVariable_0 < last);
    }
    this.y12_1 = this.y12_1 - 1 | 0;
    return old;
  };
  protoOf(TGenDeque).k13 = function (element) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.TGenDeque.add.<anonymous>' call
    this.e13(element);
    return true;
  };
  protoOf(TGenDeque).p = function (element) {
    return this.k13((element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  protoOf(TGenDeque).h13 = function () {
    return this.z12_1[this.x12_1];
  };
  protoOf(TGenDeque).i13 = function () {
    return this.z12_1[internalIndex(this, this.l() - 1 | 0)];
  };
  protoOf(TGenDeque).j13 = function (index, value) {
    this.z12_1[internalIndex(this, index)] = value;
  };
  protoOf(TGenDeque).b1 = function (index) {
    return this.z12_1[internalIndex(this, index)];
  };
  protoOf(TGenDeque).l13 = function (element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = until(0, this.l());
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.x();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.s();
      while (tmp0_iterator.t()) {
        var element_0 = tmp0_iterator.u();
        // Inline function 'korlibs.datastructure.TGenDeque.contains.<anonymous>' call
        if (equals(this.b1(element_0), element)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(TGenDeque).v = function (element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.l13((element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  protoOf(TGenDeque).m13 = function (elements) {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList_init_$Create$(collectionSizeOrDefault(elements, 10));
    var tmp0_iterator = elements.s();
    while (tmp0_iterator.t()) {
      var item = tmp0_iterator.u();
      // Inline function 'korlibs.datastructure.TGenDeque.containsAll.<anonymous>' call
      var tmp$ret$0 = to(item, 0);
      destination.p(tmp$ret$0);
    }
    var emap = toLinkedMap(destination);
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var it = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var e = this.b1(it);
        // Inline function 'kotlin.collections.contains' call
        // Inline function 'kotlin.collections.containsKey' call
        if ((isInterface(emap, Map_0) ? emap : THROW_CCE()).h2(e)) {
          // Inline function 'kotlin.collections.set' call
          emap.a2(e, 1);
        }
      }
       while (inductionVariable < last);
    var tmp$ret$5;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var this_0 = emap.z1();
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.x();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$5 = true;
        break $l$block_0;
      }
      var tmp0_iterator_0 = this_0.s();
      while (tmp0_iterator_0.t()) {
        var element = tmp0_iterator_0.u();
        // Inline function 'korlibs.datastructure.TGenDeque.containsAll.<anonymous>' call
        if (!(element === 1)) {
          tmp$ret$5 = false;
          break $l$block_0;
        }
      }
      tmp$ret$5 = true;
    }
    return tmp$ret$5;
  };
  protoOf(TGenDeque).w = function (elements) {
    return this.m13(elements);
  };
  protoOf(TGenDeque).s = function () {
    var that = this;
    return new TGenDeque$iterator$1(that, this);
  };
  protoOf(TGenDeque).hashCode = function () {
    // Inline function 'korlibs.datastructure.internal.contentHashCode' call
    var size = this.l();
    var result = 1;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = imul(31, result);
        // Inline function 'kotlin.hashCode' call
        // Inline function 'korlibs.datastructure.TGenDeque.hashCode.<anonymous>' call
        var tmp0_safe_receiver = this.b1(n);
        var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
        result = tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
      }
       while (inductionVariable < size);
    return result;
  };
  protoOf(TGenDeque).equals = function (other) {
    if (!(other instanceof TGenDeque))
      return false;
    if (!(other.l() === this.l()))
      return false;
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!equals(this.b1(n), other.b1(n)))
          return false;
      }
       while (inductionVariable < last);
    return true;
  };
  protoOf(TGenDeque).toString = function () {
    var sb = StringBuilder_init_$Create$_0();
    sb.j5(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        sb.h5(this.b1(n));
        if (!(n === (this.l() - 1 | 0))) {
          sb.i5(', ');
        }
      }
       while (inductionVariable < last);
    sb.j5(_Char___init__impl__6a9atx(93));
    return sb.toString();
  };
  function _get__data__kyoi3c_0($this) {
    // Inline function 'korlibs.datastructure.fastCastTo' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return $this.p13_1;
  }
  function _get_capacity__a9k9f3_0($this) {
    return $this.p13_1.length;
  }
  function IntDeque_init_$Init$($this) {
    IntDeque.call($this, 16);
    return $this;
  }
  function IntDeque_init_$Create$() {
    return IntDeque_init_$Init$(objectCreate(protoOf(IntDeque)));
  }
  function resizeIfRequiredFor_0($this, count) {
    if (($this.l() + count | 0) > _get_capacity__a9k9f3_0($this)) {
      var i = $this.p13_1;
      var istart = $this.n13_1;
      // Inline function 'kotlin.comparisons.maxOf' call
      var a = $this.p13_1.length + 7 | 0;
      // Inline function 'kotlin.comparisons.maxOf' call
      var a_0 = $this.l() + count | 0;
      var b = imul($this.p13_1.length, 2);
      var b_0 = Math.max(a_0, b);
      var tmp$ret$1 = Math.max(a, b_0);
      var _o = new Int32Array(tmp$ret$1);
      var o = isIntArray(_o) ? _o : THROW_CCE();
      copyCyclic_0($this, i, istart, o, $this.o13_1);
      $this.p13_1 = o;
      $this.n13_1 = 0;
    }
  }
  function copyCyclic_0($this, i, istart, o, count) {
    // Inline function 'kotlin.math.min' call
    var a = i.length - istart | 0;
    var size1 = Math.min(a, count);
    var size2 = count - size1 | 0;
    Memory_instance.s11(i, istart, o, 0, size1);
    if (size2 > 0) {
      Memory_instance.s11(i, 0, o, size1, size2);
    }
  }
  function _addLast_0($this, item) {
    $this.p13_1[($this.n13_1 + $this.o13_1 | 0) % _get_capacity__a9k9f3_0($this) | 0] = item;
    $this.o13_1 = $this.o13_1 + 1 | 0;
  }
  function nullify_0($this, index) {
    _get__data__kyoi3c_0($this)[index] = 0;
  }
  function internalIndex_0($this, index) {
    return Math_instance.o10($this.n13_1 + index | 0, _get_capacity__a9k9f3_0($this));
  }
  function IntDeque$iterator$1($that, this$0) {
    this.r13_1 = $that;
    this.s13_1 = this$0;
    this.q13_1 = 0;
  }
  protoOf(IntDeque$iterator$1).u = function () {
    var tmp1 = this.q13_1;
    this.q13_1 = tmp1 + 1 | 0;
    return this.r13_1.b1(tmp1);
  };
  protoOf(IntDeque$iterator$1).t = function () {
    return this.q13_1 < this.s13_1.l();
  };
  protoOf(IntDeque$iterator$1).c1 = function () {
    this.q13_1 = this.q13_1 - 1 | 0;
    this.s13_1.e1(this.q13_1);
  };
  function IntDeque(initialCapacity) {
    this.n13_1 = 0;
    this.o13_1 = 0;
    var tmp = this;
    var tmp_0 = new Int32Array(initialCapacity);
    tmp.p13_1 = isIntArray(tmp_0) ? tmp_0 : THROW_CCE();
  }
  protoOf(IntDeque).l = function () {
    return this.o13_1;
  };
  protoOf(IntDeque).x = function () {
    return this.l() === 0;
  };
  protoOf(IntDeque).t13 = function (item) {
    resizeIfRequiredFor_0(this, 1);
    _addLast_0(this, item);
  };
  protoOf(IntDeque).u13 = function (list) {
    // Inline function 'korlibs.datastructure.IntDeque._addAll' call
    var count = list.l();
    resizeIfRequiredFor_0(this, count);
    var base = this.n13_1 + this.o13_1 | 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.p13_1;
        var tmp_0 = (base + n | 0) % _get_capacity__a9k9f3_0(this) | 0;
        // Inline function 'korlibs.datastructure.IntDeque.addAll.<anonymous>' call
        tmp[tmp_0] = list.b1(n);
      }
       while (inductionVariable < count);
    this.o13_1 = this.o13_1 + count | 0;
    return true;
  };
  protoOf(IntDeque).v13 = function (elements) {
    return this.u13(toList(elements));
  };
  protoOf(IntDeque).q = function (elements) {
    return this.v13(elements);
  };
  protoOf(IntDeque).je = function () {
    if (this.o13_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.h13();
    nullify_0(this, this.n13_1);
    this.n13_1 = (this.n13_1 + 1 | 0) % _get_capacity__a9k9f3_0(this) | 0;
    this.o13_1 = this.o13_1 - 1 | 0;
    return out;
  };
  protoOf(IntDeque).le = function () {
    if (this.o13_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.i13();
    nullify_0(this, internalIndex_0(this, this.l() - 1 | 0));
    this.o13_1 = this.o13_1 - 1 | 0;
    return out;
  };
  protoOf(IntDeque).e1 = function (index) {
    if (index < 0 ? true : index >= this.l())
      throw IndexOutOfBoundsException_init_$Create$();
    if (index === 0)
      return this.je();
    if (index === (this.l() - 1 | 0))
      return this.le();
    var old = this.b1(index);
    if (index < (this.l() / 2 | 0)) {
      var inductionVariable = index;
      if (1 <= inductionVariable)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          this.t11(n, this.b1(n - 1 | 0));
        }
         while (1 <= inductionVariable);
      this.n13_1 = Math_instance.o10(this.n13_1 + 1 | 0, _get_capacity__a9k9f3_0(this));
    } else {
      var inductionVariable_0 = index;
      var last = this.l() - 1 | 0;
      if (inductionVariable_0 < last)
        do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          this.t11(n_0, this.b1(n_0 + 1 | 0));
        }
         while (inductionVariable_0 < last);
    }
    this.o13_1 = this.o13_1 - 1 | 0;
    return old;
  };
  protoOf(IntDeque).w13 = function (element) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntDeque.add.<anonymous>' call
    this.t13(element);
    return true;
  };
  protoOf(IntDeque).p = function (element) {
    return this.w13((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(IntDeque).h13 = function () {
    return this.p13_1[this.n13_1];
  };
  protoOf(IntDeque).i13 = function () {
    return this.p13_1[internalIndex_0(this, this.l() - 1 | 0)];
  };
  protoOf(IntDeque).t11 = function (index, value) {
    this.p13_1[internalIndex_0(this, index)] = value;
  };
  protoOf(IntDeque).b1 = function (index) {
    return this.p13_1[internalIndex_0(this, index)];
  };
  protoOf(IntDeque).ah = function (element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = until(0, this.l());
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.x();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.s();
      while (tmp0_iterator.t()) {
        var element_0 = tmp0_iterator.u();
        // Inline function 'korlibs.datastructure.IntDeque.contains.<anonymous>' call
        if (this.b1(element_0) === element) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(IntDeque).v = function (element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.ah((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(IntDeque).u11 = function (elements) {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList_init_$Create$(collectionSizeOrDefault(elements, 10));
    var tmp0_iterator = elements.s();
    while (tmp0_iterator.t()) {
      var item = tmp0_iterator.u();
      // Inline function 'korlibs.datastructure.IntDeque.containsAll.<anonymous>' call
      var tmp$ret$0 = to(item, 0);
      destination.p(tmp$ret$0);
    }
    var emap = toLinkedMap(destination);
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var it = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var e = this.b1(it);
        // Inline function 'kotlin.collections.contains' call
        // Inline function 'kotlin.collections.containsKey' call
        if ((isInterface(emap, Map_0) ? emap : THROW_CCE()).h2(e)) {
          // Inline function 'kotlin.collections.set' call
          emap.a2(e, 1);
        }
      }
       while (inductionVariable < last);
    var tmp$ret$5;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var this_0 = emap.z1();
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.x();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$5 = true;
        break $l$block_0;
      }
      var tmp0_iterator_0 = this_0.s();
      while (tmp0_iterator_0.t()) {
        var element = tmp0_iterator_0.u();
        // Inline function 'korlibs.datastructure.IntDeque.containsAll.<anonymous>' call
        if (!(element === 1)) {
          tmp$ret$5 = false;
          break $l$block_0;
        }
      }
      tmp$ret$5 = true;
    }
    return tmp$ret$5;
  };
  protoOf(IntDeque).w = function (elements) {
    return this.u11(elements);
  };
  protoOf(IntDeque).s = function () {
    var that = this;
    return new IntDeque$iterator$1(that, this);
  };
  protoOf(IntDeque).hashCode = function () {
    // Inline function 'korlibs.datastructure.internal.contentHashCode' call
    var size = this.l();
    var result = 1;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = imul(31, result);
        // Inline function 'kotlin.hashCode' call
        // Inline function 'korlibs.datastructure.IntDeque.hashCode.<anonymous>' call
        var tmp0_safe_receiver = this.b1(n);
        var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
        result = tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
      }
       while (inductionVariable < size);
    return result;
  };
  protoOf(IntDeque).equals = function (other) {
    if (!(other instanceof IntDeque))
      return false;
    if (!(other.l() === this.l()))
      return false;
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(this.b1(n) === other.b1(n)))
          return false;
      }
       while (inductionVariable < last);
    return true;
  };
  protoOf(IntDeque).toString = function () {
    var sb = StringBuilder_init_$Create$_0();
    sb.j5(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        sb.t7(this.b1(n));
        if (!(n === (this.l() - 1 | 0))) {
          sb.i5(', ');
        }
      }
       while (inductionVariable < last);
    sb.j5(_Char___init__impl__6a9atx(93));
    return sb.toString();
  };
  function fastArrayListOf(values) {
    // Inline function 'kotlin.also' call
    var this_0 = FastArrayList_init_$Create$_0(values.length);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.fastArrayListOf.<anonymous>' call
    addAll(this_0, values);
    return this_0;
  }
  function toFastList(_this__u8e3s4) {
    // Inline function 'kotlin.also' call
    var this_0 = FastArrayList_init_$Create$_0(_this__u8e3s4.length);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.toFastList.<anonymous>' call
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < _this__u8e3s4.length) {
      // Inline function 'korlibs.datastructure.toFastList.<anonymous>.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var it = _this__u8e3s4[tmp0];
      this_0.p(it);
    }
    return this_0;
  }
  function toFastList_0(_this__u8e3s4, out) {
    out = out === VOID ? FastArrayList_init_$Create$() : out;
    out.z13(0, _this__u8e3s4);
    out.a14(_this__u8e3s4.l());
    return out;
  }
  function MutableListEx() {
  }
  function getAndRemove(_this__u8e3s4, key) {
    // Inline function 'kotlin.also' call
    var this_0 = get(_this__u8e3s4, key);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.getAndRemove.<anonymous>' call
    remove(_this__u8e3s4, key);
    return this_0;
  }
  function get_keys(_this__u8e3s4) {
    return keys(_this__u8e3s4);
  }
  function get_keys_0(_this__u8e3s4) {
    return keys_0(_this__u8e3s4);
  }
  function get_values(_this__u8e3s4) {
    return values(_this__u8e3s4);
  }
  function values(_this__u8e3s4) {
    // Inline function 'kotlin.collections.map' call
    var this_0 = keys_0(_this__u8e3s4);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList_init_$Create$(collectionSizeOrDefault(this_0, 10));
    var tmp0_iterator = this_0.s();
    while (tmp0_iterator.t()) {
      var item = tmp0_iterator.u();
      // Inline function 'korlibs.datastructure.values.<anonymous>' call
      // Inline function 'korlibs.datastructure.get' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$2 = _this__u8e3s4.get(item);
      destination.p(tmp$ret$2);
    }
    var tmp = destination;
    return isInterface(tmp, List) ? tmp : THROW_CCE();
  }
  function FastSmallSet() {
    AbstractMutableSet.call(this);
    this.e14_1 = LinkedHashSet_init_$Create$();
    this.f14_1 = null;
    this.g14_1 = null;
    this.h14_1 = null;
  }
  protoOf(FastSmallSet).l = function () {
    return this.e14_1.l();
  };
  protoOf(FastSmallSet).s = function () {
    return this.e14_1.s();
  };
  protoOf(FastSmallSet).i14 = function (element) {
    if (this.se(element))
      return false;
    this.e14_1.p(element);
    return true;
  };
  protoOf(FastSmallSet).p = function (element) {
    return this.i14((element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  protoOf(FastSmallSet).j14 = function (element) {
    this.f14_1 = null;
    this.g14_1 = null;
    this.h14_1 = null;
    this.e14_1.w3(element);
    return true;
  };
  protoOf(FastSmallSet).se = function (element) {
    if ((element === this.f14_1 ? true : element === this.g14_1) ? true : element === this.f14_1)
      return true;
    var result = this.e14_1.v(element);
    if (result) {
      this.g14_1 = this.f14_1;
      this.h14_1 = this.g14_1;
      this.f14_1 = element;
    }
    return result;
  };
  protoOf(FastSmallSet).v = function (element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.se((element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  protoOf(FastSmallSet).l1 = function () {
    this.e14_1.l1();
    this.f14_1 = null;
    this.g14_1 = null;
    this.h14_1 = null;
  };
  function GenericListIterator(list, iindex) {
    iindex = iindex === VOID ? 0 : iindex;
    this.k14_1 = list;
    this.l14_1 = iindex;
    var containsUpper = this.k14_1.l();
    var containsArg = this.l14_1;
    if (!(0 <= containsArg ? containsArg < containsUpper : false))
      throw IndexOutOfBoundsException_init_$Create$_0('' + this.l14_1);
    this.m14_1 = this.l14_1;
  }
  protoOf(GenericListIterator).t = function () {
    return this.m14_1 < this.k14_1.l();
  };
  protoOf(GenericListIterator).u = function () {
    if (!this.t())
      throw NoSuchElementException_init_$Create$();
    var tmp1 = this.m14_1;
    this.m14_1 = tmp1 + 1 | 0;
    return this.k14_1.b1(tmp1);
  };
  protoOf(GenericListIterator).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof GenericListIterator) {
      tmp_0 = equals(this.k14_1, other.k14_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.m14_1 === other.m14_1;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(GenericListIterator).hashCode = function () {
    return hashCode(this.k14_1) + this.m14_1 | 0;
  };
  function Companion_4() {
  }
  protoOf(Companion_4).b10 = function (width, height, arraySize) {
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(arraySize >= imul(width, height))) {
      // Inline function 'korlibs.datastructure.Companion.checkArraySize.<anonymous>' call
      var message = 'backing array of size=' + arraySize + ', has less elements than ' + width + ' * ' + height;
      throw IllegalStateException_init_$Create$(toString(message));
    }
  };
  var Companion_instance_4;
  function Companion_getInstance_4() {
    return Companion_instance_4;
  }
  function IArray2$asString$lambda(this$0) {
    return function (y) {
      // Inline function 'kotlin.collections.map' call
      var this_0 = until(0, this$0.c10());
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList_init_$Create$(collectionSizeOrDefault(this_0, 10));
      var tmp0_iterator = this_0.s();
      while (tmp0_iterator.t()) {
        var item = tmp0_iterator.u();
        // Inline function 'korlibs.datastructure.IArray2.asString.<anonymous>.<anonymous>' call
        var tmp$ret$0 = this$0.i10(item, y);
        destination.p(tmp$ret$0);
      }
      return joinToString(destination, ', ');
    };
  }
  function IArray2() {
  }
  function index(_this__u8e3s4, x, y) {
    if (!(0 <= x ? x < _this__u8e3s4.c10() : false) ? true : !(0 <= y ? y < _this__u8e3s4.d10() : false))
      throw IndexOutOfBoundsException_init_$Create$();
    return imul(y, _this__u8e3s4.c10()) + x | 0;
  }
  function currentKey($this) {
    switch ($this.o14_1) {
      case 2147483647:
      case 2147483646:
        return 0;
      default:
        return $this.n14_1.x14_1[$this.o14_1];
    }
  }
  function currentValue($this) {
    switch ($this.o14_1) {
      case 2147483647:
        return $this.n14_1.u14_1;
      case 2147483646:
        return null;
      default:
        return $this.n14_1.y14_1[$this.o14_1];
    }
  }
  function nextNonEmptyIndex($this, keys, offset) {
    var inductionVariable = offset;
    var last = keys.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(keys[n] === 0))
          return n;
      }
       while (inductionVariable < last);
    return 2147483646;
  }
  function next($this) {
    if (!($this.o14_1 === 2147483646))
      $this.o14_1 = nextNonEmptyIndex($this, $this.n14_1.x14_1, $this.o14_1 === 2147483647 ? 0 : $this.o14_1 + 1 | 0);
  }
  function IntMap$_get_keys_$o$iterator$lambda_bn8atq($it) {
    return function () {
      return $it.t();
    };
  }
  function IntMap$_get_keys_$o$iterator$lambda_bn8atq_0($it) {
    return function () {
      return $it.b15();
    };
  }
  function IntMap$_get_values_$o$iterator$lambda_ggo058($it) {
    return function () {
      return $it.t();
    };
  }
  function IntMap$_get_values_$o$iterator$lambda_ggo058_0($it) {
    return function () {
      return $it.c15();
    };
  }
  function IntMap_init_$Init$(initialCapacity, loadFactor, $this) {
    initialCapacity = initialCapacity === VOID ? 16 : initialCapacity;
    loadFactor = loadFactor === VOID ? 0.75 : loadFactor;
    // Inline function 'kotlin.math.max' call
    var b = Math_instance.d15(initialCapacity);
    var tmp$ret$0 = Math.max(4, b);
    IntMap.call($this, tmp$ret$0, loadFactor, true);
    return $this;
  }
  function IntMap_init_$Create$(initialCapacity, loadFactor) {
    return IntMap_init_$Init$(initialCapacity, loadFactor, objectCreate(protoOf(IntMap)));
  }
  function Companion_5() {
    this.e15_1 = 2147483646;
    this.f15_1 = 2147483647;
    this.g15_1 = 0;
  }
  var Companion_instance_5;
  function Companion_getInstance_5() {
    return Companion_instance_5;
  }
  function _get_stashStart__3psegu($this) {
    return $this.x14_1.length - $this.w14_1 | 0;
  }
  function grow($this) {
    var inc = $this.q14_1 < 20 ? 3 : 1;
    var newnbits = $this.q14_1 + inc | 0;
    var new_0 = new IntMap(newnbits, $this.r14_1, true);
    var inductionVariable = 0;
    var last = $this.x14_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var k = $this.x14_1[n];
        if (!(k === 0)) {
          new_0.h15(k, $this.y14_1[n]);
        }
      }
       while (inductionVariable <= last);
    $this.q14_1 = new_0.q14_1;
    $this.s14_1 = new_0.s14_1;
    $this.v14_1 = new_0.v14_1;
    $this.w14_1 = new_0.w14_1;
    $this.x14_1 = new_0.x14_1;
    $this.y14_1 = new_0.y14_1;
    $this.z14_1 = new_0.z14_1;
  }
  function growStash($this) {
    $this.w14_1 = imul($this.w14_1, 2);
    $this.x14_1 = copyOf_0($this.x14_1, $this.i15());
    $this.y14_1 = copyOf_2($this.y14_1, $this.i15());
  }
  function _getKeyIndex($this, key) {
    if (key === 0)
      return $this.t14_1 ? 2147483647 : -1;
    var index1 = hash1($this, key);
    if ($this.x14_1[index1] === key)
      return index1;
    var index2 = hash2($this, key);
    if ($this.x14_1[index2] === key)
      return index2;
    var index3 = hash3($this, key);
    if ($this.x14_1[index3] === key)
      return index3;
    var inductionVariable = _get_stashStart__3psegu($this);
    var last = $this.x14_1.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if ($this.x14_1[n] === key)
          return n;
      }
       while (inductionVariable < last);
    return -1;
  }
  function setEmptySlot($this, index, key, value) {
    if (!($this.x14_1[index] === 0))
      throw IllegalStateException_init_$Create$_0();
    $this.x14_1[index] = key;
    $this.y14_1[index] = value;
    $this.a15_1 = $this.a15_1 + 1 | 0;
    return null;
  }
  function hash1($this, key) {
    return _hash1(key, $this.v14_1);
  }
  function hash2($this, key) {
    return _hash2(key, $this.v14_1);
  }
  function hash3($this, key) {
    return _hash3(key, $this.v14_1);
  }
  function Entry(key, value) {
    this.j15_1 = key;
    this.k15_1 = value;
  }
  protoOf(Entry).toString = function () {
    return 'Entry(key=' + this.j15_1 + ', value=' + this.k15_1 + ')';
  };
  protoOf(Entry).hashCode = function () {
    var result = this.j15_1;
    result = imul(result, 31) + (this.k15_1 == null ? 0 : hashCode(this.k15_1)) | 0;
    return result;
  };
  protoOf(Entry).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Entry))
      return false;
    var tmp0_other_with_cast = other instanceof Entry ? other : THROW_CCE();
    if (!(this.j15_1 === tmp0_other_with_cast.j15_1))
      return false;
    if (!equals(this.k15_1, tmp0_other_with_cast.k15_1))
      return false;
    return true;
  };
  function Iterator(map) {
    this.n14_1 = map;
    this.o14_1 = this.n14_1.t14_1 ? 2147483647 : nextNonEmptyIndex(this, this.n14_1.x14_1, 0);
    this.p14_1 = new Entry(0, null);
  }
  protoOf(Iterator).t = function () {
    return !(this.o14_1 === 2147483646);
  };
  protoOf(Iterator).b15 = function () {
    // Inline function 'kotlin.apply' call
    var this_0 = currentKey(this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.Iterator.nextKey.<anonymous>' call
    next(this);
    return this_0;
  };
  protoOf(Iterator).c15 = function () {
    // Inline function 'kotlin.apply' call
    var this_0 = currentValue(this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.Iterator.nextValue.<anonymous>' call
    next(this);
    return this_0;
  };
  function _no_name_provided__qut3iv(this$0) {
    this.l15_1 = this$0;
  }
  protoOf(_no_name_provided__qut3iv).s = function () {
    // Inline function 'korlibs.datastructure.IntMap.<get-keys>.<anonymous>' call
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntMap.<get-keys>.<anonymous>.<anonymous>' call
    var it = new Iterator(this.l15_1);
    var tmp = IntMap$_get_keys_$o$iterator$lambda_bn8atq(it);
    return Iterator_0(tmp, IntMap$_get_keys_$o$iterator$lambda_bn8atq_0(it));
  };
  function _no_name_provided__qut3iv_0(this$0) {
    this.m15_1 = this$0;
  }
  protoOf(_no_name_provided__qut3iv_0).s = function () {
    // Inline function 'korlibs.datastructure.IntMap.<get-values>.<anonymous>' call
    // Inline function 'kotlin.let' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntMap.<get-values>.<anonymous>.<anonymous>' call
    var it = new Iterator(this.m15_1);
    var tmp = IntMap$_get_values_$o$iterator$lambda_ggo058(it);
    return Iterator_0(tmp, IntMap$_get_values_$o$iterator$lambda_ggo058_0(it));
  };
  function IntMap(nbits, loadFactor, dummy) {
    dummy = dummy === VOID ? false : dummy;
    this.q14_1 = nbits;
    this.r14_1 = loadFactor;
    this.s14_1 = 1 << this.q14_1;
    this.t14_1 = false;
    this.u14_1 = null;
    this.v14_1 = this.s14_1 - 1 | 0;
    this.w14_1 = 1 + imul(this.q14_1, this.q14_1) | 0;
    this.x14_1 = new Int32Array(this.i15());
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    var size = this.i15();
    var tmp_0 = fillArrayVal(Array(size), null);
    tmp.y14_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
    this.z14_1 = numberToInt(this.s14_1 * this.r14_1);
    this.a15_1 = 0;
  }
  protoOf(IntMap).i15 = function () {
    return this.s14_1 + this.w14_1 | 0;
  };
  protoOf(IntMap).b1 = function (key) {
    var index = _getKeyIndex(this, key);
    if (index < 0)
      return null;
    if (index === 2147483647)
      return this.u14_1;
    return this.y14_1[index];
  };
  protoOf(IntMap).h15 = function (key, value) {
    retry: while (true) {
      var index = _getKeyIndex(this, key);
      if (index < 0) {
        if (key === 0) {
          this.t14_1 = true;
          this.u14_1 = value;
          this.a15_1 = this.a15_1 + 1 | 0;
          return null;
        }
        if (this.a15_1 >= this.z14_1) {
          grow(this);
        }
        var index1 = hash1(this, key);
        if (this.x14_1[index1] === 0)
          return setEmptySlot(this, index1, key, value);
        var index2 = hash2(this, key);
        if (this.x14_1[index2] === 0)
          return setEmptySlot(this, index2, key, value);
        var index3 = hash3(this, key);
        if (this.x14_1[index3] === 0)
          return setEmptySlot(this, index3, key, value);
        var inductionVariable = _get_stashStart__3psegu(this);
        var last = this.x14_1.length;
        if (inductionVariable < last)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            if (this.x14_1[n] === 0)
              return setEmptySlot(this, n, key, value);
          }
           while (inductionVariable < last);
        if (this.w14_1 > 512) {
          grow(this);
        } else {
          growStash(this);
        }
        continue retry;
      } else if (index === 2147483647) {
        // Inline function 'kotlin.apply' call
        var this_0 = this.u14_1;
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.datastructure.IntMap.set.<anonymous>' call
        this.u14_1 = value;
        return this_0;
      } else {
        // Inline function 'kotlin.apply' call
        var this_1 = this.y14_1[index];
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.datastructure.IntMap.set.<anonymous>' call
        this.y14_1[index] = value;
        return this_1;
      }
    }
  };
  protoOf(IntMap).y1 = function () {
    // Inline function 'kotlin.collections.Iterable' call
    return new _no_name_provided__qut3iv(this);
  };
  protoOf(IntMap).z1 = function () {
    // Inline function 'kotlin.collections.Iterable' call
    return new _no_name_provided__qut3iv_0(this);
  };
  protoOf(IntMap).n15 = function (keys, offset) {
    var inductionVariable = offset;
    var last = keys.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(keys[n] === 0))
          return n;
      }
       while (inductionVariable < last);
    return 2147483646;
  };
  protoOf(IntMap).equals = function (other) {
    if (!(other instanceof IntMap))
      return false;
    // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable' call
    // Inline function 'korlibs.datastructure.IntMap.fastKeyForEach' call
    var index = this.t14_1 ? 2147483647 : this.n15(this.x14_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.x14_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntMap.equals.<anonymous>' call
      var key = it;
      var value = this.b1(it);
      if (!equals(other.b1(key), value))
        return false;
      index = this.n15(this.x14_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return true;
  };
  protoOf(IntMap).hashCode = function () {
    var out = 0;
    // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable' call
    // Inline function 'korlibs.datastructure.IntMap.fastKeyForEach' call
    var index = this.t14_1 ? 2147483647 : this.n15(this.x14_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.x14_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntMap.hashCode.<anonymous>' call
      var tmp = out;
      var tmp_0 = it;
      // Inline function 'kotlin.hashCode' call
      var tmp0_safe_receiver = this.b1(it);
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
      out = tmp + (tmp_0 + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0) | 0;
      index = this.n15(this.x14_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return out;
  };
  function IntIntMap_init_$Init$(initialCapacity, loadFactor, $this) {
    initialCapacity = initialCapacity === VOID ? 16 : initialCapacity;
    loadFactor = loadFactor === VOID ? 0.75 : loadFactor;
    // Inline function 'kotlin.math.max' call
    var b = Math_instance.d15(initialCapacity);
    var tmp$ret$0 = Math.max(4, b);
    IntIntMap.call($this, tmp$ret$0, loadFactor, true);
    return $this;
  }
  function IntIntMap_init_$Create$(initialCapacity, loadFactor) {
    return IntIntMap_init_$Init$(initialCapacity, loadFactor, objectCreate(protoOf(IntIntMap)));
  }
  function Companion_6() {
    this.o15_1 = 2147483646;
    this.p15_1 = 2147483647;
    this.q15_1 = 0;
  }
  var Companion_instance_6;
  function Companion_getInstance_6() {
    return Companion_instance_6;
  }
  function _get_stashStart__3psegu_0($this) {
    return $this.y15_1.length - $this.x15_1 | 0;
  }
  function grow_0($this) {
    var inc = $this.r15_1 < 20 ? 3 : 1;
    var newnbits = $this.r15_1 + inc | 0;
    var new_0 = new IntIntMap(newnbits, $this.s15_1, true);
    var inductionVariable = 0;
    var last = $this.y15_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var k = $this.y15_1[n];
        if (!(k === 0)) {
          new_0.c16(k, $this.z15_1[n]);
        }
      }
       while (inductionVariable <= last);
    $this.r15_1 = new_0.r15_1;
    $this.t15_1 = new_0.t15_1;
    $this.w15_1 = new_0.w15_1;
    $this.x15_1 = new_0.x15_1;
    $this.y15_1 = new_0.y15_1;
    $this.z15_1 = new_0.z15_1;
    $this.a16_1 = new_0.a16_1;
  }
  function growStash_0($this) {
    $this.x15_1 = imul($this.x15_1, 2);
    $this.y15_1 = copyOf_0($this.y15_1, $this.i15());
    $this.z15_1 = copyOf_0($this.z15_1, $this.i15());
  }
  function _getKeyIndex_0($this, key) {
    if (key === 0)
      return $this.u15_1 ? 2147483647 : -1;
    var index1 = hash1_0($this, key);
    if ($this.y15_1[index1] === key)
      return index1;
    var index2 = hash2_0($this, key);
    if ($this.y15_1[index2] === key)
      return index2;
    var index3 = hash3_0($this, key);
    if ($this.y15_1[index3] === key)
      return index3;
    var inductionVariable = _get_stashStart__3psegu_0($this);
    var last = $this.y15_1.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if ($this.y15_1[n] === key)
          return n;
      }
       while (inductionVariable < last);
    return -1;
  }
  function setEmptySlot_0($this, index, key, value) {
    if (!($this.y15_1[index] === 0))
      throw IllegalStateException_init_$Create$_0();
    $this.y15_1[index] = key;
    $this.z15_1[index] = value;
    $this.b16_1 = $this.b16_1 + 1 | 0;
    return 0;
  }
  function hash1_0($this, key) {
    return _hash1(key, $this.w15_1);
  }
  function hash2_0($this, key) {
    return _hash2(key, $this.w15_1);
  }
  function hash3_0($this, key) {
    return _hash3(key, $this.w15_1);
  }
  function IntIntMap(nbits, loadFactor, dummy) {
    this.r15_1 = nbits;
    this.s15_1 = loadFactor;
    this.t15_1 = 1 << this.r15_1;
    this.u15_1 = false;
    this.v15_1 = 0;
    this.w15_1 = this.t15_1 - 1 | 0;
    this.x15_1 = 1 + imul(this.r15_1, this.r15_1) | 0;
    this.y15_1 = new Int32Array(this.i15());
    this.z15_1 = new Int32Array(this.i15());
    this.a16_1 = numberToInt(this.t15_1 * this.s15_1);
    this.b16_1 = 0;
  }
  protoOf(IntIntMap).toString = function () {
    return toString(this.d16());
  };
  protoOf(IntIntMap).i15 = function () {
    return this.t15_1 + this.x15_1 | 0;
  };
  protoOf(IntIntMap).e16 = function (out) {
    // Inline function 'korlibs.datastructure.IntIntMap.fastForEach' call
    // Inline function 'korlibs.datastructure.IntIntMap.fastKeyForEach' call
    var index = this.u15_1 ? 2147483647 : this.n15(this.y15_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntIntMap.fastForEach.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.y15_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntIntMap.toMap.<anonymous>' call
      var key = it;
      // Inline function 'kotlin.collections.set' call
      var value = this.b1(it);
      out.a2(key, value);
      index = this.n15(this.y15_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return out;
  };
  protoOf(IntIntMap).d16 = function (out, $super) {
    out = out === VOID ? linkedHashMapOf([]) : out;
    return $super === VOID ? this.e16(out) : $super.e16.call(this, out);
  };
  protoOf(IntIntMap).ah = function (key) {
    return _getKeyIndex_0(this, key) >= 0;
  };
  protoOf(IntIntMap).l1 = function () {
    this.u15_1 = false;
    this.v15_1 = 0;
    fill(this.y15_1, 0);
    fill(this.z15_1, 0);
    this.b16_1 = 0;
  };
  protoOf(IntIntMap).b1 = function (key) {
    var index = _getKeyIndex_0(this, key);
    if (index < 0)
      return 0;
    if (index === 2147483647)
      return this.v15_1;
    return this.z15_1[index];
  };
  protoOf(IntIntMap).c16 = function (key, value) {
    retry: while (true) {
      var index = _getKeyIndex_0(this, key);
      if (index < 0) {
        if (key === 0) {
          this.u15_1 = true;
          this.v15_1 = value;
          this.b16_1 = this.b16_1 + 1 | 0;
          return 0;
        }
        if (this.b16_1 >= this.a16_1) {
          grow_0(this);
        }
        var index1 = hash1_0(this, key);
        if (this.y15_1[index1] === 0)
          return setEmptySlot_0(this, index1, key, value);
        var index2 = hash2_0(this, key);
        if (this.y15_1[index2] === 0)
          return setEmptySlot_0(this, index2, key, value);
        var index3 = hash3_0(this, key);
        if (this.y15_1[index3] === 0)
          return setEmptySlot_0(this, index3, key, value);
        var inductionVariable = _get_stashStart__3psegu_0(this);
        var last = this.y15_1.length;
        if (inductionVariable < last)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            if (this.y15_1[n] === 0)
              return setEmptySlot_0(this, n, key, value);
          }
           while (inductionVariable < last);
        if (this.x15_1 > 512) {
          grow_0(this);
        } else {
          growStash_0(this);
        }
        continue retry;
      } else if (index === 2147483647) {
        // Inline function 'kotlin.apply' call
        var this_0 = this.v15_1;
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.datastructure.IntIntMap.set.<anonymous>' call
        this.v15_1 = value;
        return this_0;
      } else {
        // Inline function 'kotlin.apply' call
        var this_1 = this.z15_1[index];
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.datastructure.IntIntMap.set.<anonymous>' call
        this.z15_1[index] = value;
        return this_1;
      }
    }
  };
  protoOf(IntIntMap).n15 = function (keys, offset) {
    var inductionVariable = offset;
    var last = keys.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(keys[n] === 0))
          return n;
      }
       while (inductionVariable < last);
    return 2147483646;
  };
  protoOf(IntIntMap).equals = function (other) {
    if (!(other instanceof IntIntMap))
      return false;
    // Inline function 'korlibs.datastructure.IntIntMap.fastForEach' call
    // Inline function 'korlibs.datastructure.IntIntMap.fastKeyForEach' call
    var index = this.u15_1 ? 2147483647 : this.n15(this.y15_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntIntMap.fastForEach.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.y15_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntIntMap.equals.<anonymous>' call
      var key = it;
      var value = this.b1(it);
      if (!(other.b1(key) === value))
        return false;
      index = this.n15(this.y15_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return true;
  };
  protoOf(IntIntMap).hashCode = function () {
    var out = 0;
    // Inline function 'korlibs.datastructure.IntIntMap.fastForEach' call
    // Inline function 'korlibs.datastructure.IntIntMap.fastKeyForEach' call
    var index = this.u15_1 ? 2147483647 : this.n15(this.y15_1, 0);
    while (!(index === 2147483646)) {
      // Inline function 'korlibs.datastructure.IntIntMap.fastForEach.<anonymous>' call
      var it;
      switch (index) {
        case 2147483647:
        case 2147483646:
          it = 0;
          break;
        default:
          it = this.y15_1[index];
          break;
      }
      // Inline function 'korlibs.datastructure.IntIntMap.hashCode.<anonymous>' call
      out = out + (it + this.b1(it) | 0) | 0;
      index = this.n15(this.y15_1, index === 2147483647 ? 0 : index + 1 | 0);
    }
    return out;
  };
  function toIntMap(_this__u8e3s4) {
    var out = IntMap_init_$Create$(numberToInt(_this__u8e3s4.l() * 1.25));
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = _this__u8e3s4.c2().s();
    while (tmp0_iterator.t()) {
      var tmp1_loop_parameter = tmp0_iterator.u();
      // Inline function 'kotlin.collections.component1' call
      var k = tmp1_loop_parameter.d2();
      // Inline function 'kotlin.collections.component2' call
      var v = tmp1_loop_parameter.e2();
      out.h15(k, v);
    }
    return out;
  }
  function _hash1(key, mask) {
    return _mask(key, mask);
  }
  function _hash2(key, mask) {
    return _mask(imul(key, 1294968109), mask);
  }
  function _hash3(key, mask) {
    return _mask(imul(key, 294969449), mask);
  }
  function _mask(value, mask) {
    return (((value + ((value >>> 8 | 0) & 255) | 0) + ((value >>> 16 | 0) & 255) | 0) + (value >> 24 & 255) | 0) & mask;
  }
  function associateByInt(_this__u8e3s4, block) {
    var n = 0;
    var out = IntMap_init_$Create$();
    var tmp0_iterator = _this__u8e3s4.s();
    while (tmp0_iterator.t()) {
      var it = tmp0_iterator.u();
      var tmp1 = n;
      n = tmp1 + 1 | 0;
      out.h15(block(tmp1, it), it);
    }
    return out;
  }
  function Iterator_0(hasNext, next) {
    return new Iterator$1(hasNext, next);
  }
  function Iterator$1($hasNext, $next) {
    this.f16_1 = $hasNext;
    this.g16_1 = $next;
  }
  protoOf(Iterator$1).t = function () {
    return this.f16_1();
  };
  protoOf(Iterator$1).u = function () {
    return this.g16_1();
  };
  function ListReader(list) {
    this.h16_1 = list;
    this.i16_1 = 0;
  }
  protoOf(ListReader).j16 = function () {
    return this.i16_1 >= this.h16_1.l();
  };
  protoOf(ListReader).k16 = function () {
    return this.i16_1 < this.h16_1.l();
  };
  protoOf(ListReader).l16 = function () {
    return this.h16_1.b1(this.i16_1);
  };
  protoOf(ListReader).m16 = function (count) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.ListReader.skip.<anonymous>' call
    this.i16_1 = this.i16_1 + count | 0;
    return this;
  };
  protoOf(ListReader).n16 = function () {
    // Inline function 'kotlin.apply' call
    var this_0 = this.l16();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.ListReader.read.<anonymous>' call
    this.m16(1);
    return this_0;
  };
  protoOf(ListReader).toString = function () {
    return 'ListReader(' + this.h16_1 + ')';
  };
  protoOf(ListReader).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof ListReader) {
      tmp_0 = equals(this.h16_1, other.h16_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.i16_1 === other.i16_1;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(ListReader).hashCode = function () {
    return hashCode(this.h16_1);
  };
  function ConcurrentPool$_init_$lambda_gqhtk8(it) {
    return Unit_instance;
  }
  function ConcurrentPool(reset, preallocate, gen) {
    var tmp;
    if (reset === VOID) {
      tmp = ConcurrentPool$_init_$lambda_gqhtk8;
    } else {
      tmp = reset;
    }
    reset = tmp;
    preallocate = preallocate === VOID ? 0 : preallocate;
    Pool.call(this, reset, preallocate, gen);
    this.s16_1 = reset;
    this.t16_1 = gen;
    this.u16_1 = new NonRecursiveLock();
  }
  protoOf(ConcurrentPool).v16 = function () {
    // Inline function 'korlibs.concurrent.lock.NonRecursiveLock.invoke' call
    this.u16_1;
    // Inline function 'korlibs.datastructure.ConcurrentPool.alloc.<anonymous>' call
    return protoOf(Pool).v16.call(this);
  };
  protoOf(ConcurrentPool).l1 = function () {
    // Inline function 'korlibs.concurrent.lock.NonRecursiveLock.invoke' call
    this.u16_1;
    protoOf(Pool).l1.call(this);
  };
  protoOf(ConcurrentPool).a17 = function (element) {
    // Inline function 'korlibs.concurrent.lock.NonRecursiveLock.invoke' call
    this.u16_1;
    protoOf(Pool).a17.call(this, element);
  };
  function Companion_7() {
  }
  var Companion_instance_7;
  function Companion_getInstance_7() {
    return Companion_instance_7;
  }
  function Pool_init_$Init$(preallocate, gen, $this) {
    preallocate = preallocate === VOID ? 0 : preallocate;
    Pool.call($this, Pool$_init_$lambda_q3cbhr_0, preallocate, gen);
    return $this;
  }
  function Pool_init_$Create$(preallocate, gen) {
    return Pool_init_$Init$(preallocate, gen, objectCreate(protoOf(Pool)));
  }
  function Pool$_init_$lambda_q3cbhr(it) {
    return Unit_instance;
  }
  function Pool$_init_$lambda_q3cbhr_0(it) {
    return Unit_instance;
  }
  function Pool(reset, preallocate, gen) {
    var tmp;
    if (reset === VOID) {
      tmp = Pool$_init_$lambda_q3cbhr;
    } else {
      tmp = reset;
    }
    reset = tmp;
    preallocate = preallocate === VOID ? 0 : preallocate;
    this.w16_1 = reset;
    this.x16_1 = gen;
    this.y16_1 = _TGenStack___init__impl__yynjgt();
    this.z16_1 = 0;
    var inductionVariable = 0;
    if (inductionVariable < preallocate)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp2 = this.z16_1;
        this.z16_1 = tmp2 + 1 | 0;
        TGenStack__push_impl_9yh33t(this.y16_1, this.x16_1(tmp2));
      }
       while (inductionVariable < preallocate);
  }
  protoOf(Pool).b17 = function () {
    return _TGenStack___get_size__impl__cvg66t(this.y16_1);
  };
  protoOf(Pool).v16 = function () {
    var tmp;
    // Inline function 'kotlin.collections.isNotEmpty' call
    if (!(new TGenStack(this.y16_1)).x()) {
      tmp = TGenStack__pop_impl_qisxa0(this.y16_1);
    } else {
      var tmp1 = this.z16_1;
      this.z16_1 = tmp1 + 1 | 0;
      tmp = this.x16_1(tmp1);
    }
    return tmp;
  };
  protoOf(Pool).l1 = function () {
    TGenStack__clear_impl_pali4s(this.y16_1);
    this.z16_1 = 0;
  };
  protoOf(Pool).a17 = function (element) {
    this.w16_1(element);
    TGenStack__push_impl_9yh33t(this.y16_1, element);
  };
  protoOf(Pool).c17 = function (elements) {
    var tmp0_iterator = elements.s();
    while (tmp0_iterator.t()) {
      var element = tmp0_iterator.u();
      this.a17(element);
    }
  };
  protoOf(Pool).hashCode = function () {
    return TGenStack__hashCode_impl_l04zom(this.y16_1);
  };
  protoOf(Pool).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof Pool) {
      tmp_0 = equals(this.y16_1, other.y16_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.b17() === other.b17();
    } else {
      tmp = false;
    }
    return tmp;
  };
  function ReturnablePool$_init_$lambda_rsm60r(it) {
    return Unit_instance;
  }
  function ReturnablePool$list$lambda(this$0) {
    return function (it) {
      this$0.d17_1(it);
      return Unit_instance;
    };
  }
  function ReturnablePool$list$lambda_0(this$0) {
    return function (it) {
      return this$0.e17_1(it);
    };
  }
  function ReturnablePool(_reset, gen) {
    var tmp;
    if (_reset === VOID) {
      tmp = ReturnablePool$_init_$lambda_rsm60r;
    } else {
      tmp = _reset;
    }
    _reset = tmp;
    this.d17_1 = _reset;
    this.e17_1 = gen;
    this.f17_1 = fastArrayListOf([]);
    var tmp_0 = this;
    var tmp_1 = ReturnablePool$list$lambda(this);
    tmp_0.g17_1 = new Pool(tmp_1, VOID, ReturnablePool$list$lambda_0(this));
    this.h17_1 = this.g17_1.v16();
  }
  protoOf(ReturnablePool).u = function () {
    // Inline function 'kotlin.collections.plusAssign' call
    var this_0 = this.f17_1;
    var element = this.h17_1;
    this_0.p(element);
    this.h17_1 = this.g17_1.v16();
    return this.h17_1;
  };
  protoOf(ReturnablePool).i17 = function () {
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.f17_1.y13_1;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.datastructure.ReturnablePool.reset.<anonymous>' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'get' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      var it = array[tmp0];
      this.g17_1.a17(it);
    }
    this.f17_1.l1();
  };
  function sam$kotlin_Comparator$0(function_0) {
    this.j17_1 = function_0;
  }
  protoOf(sam$kotlin_Comparator$0).j9 = function (a, b) {
    return this.j17_1(a, b);
  };
  protoOf(sam$kotlin_Comparator$0).compare = function (a, b) {
    return this.j9(a, b);
  };
  function Companion_8() {
  }
  protoOf(Companion_8).k17 = function (comparator, reversed_0) {
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp = fillArrayVal(Array(16), null);
    return new TGenPriorityQueue(isArray(tmp) ? tmp : THROW_CCE(), reversed_0 ? reversed(comparator) : comparator);
  };
  protoOf(Companion_8).l17 = function (reversed, comparator) {
    return Companion_instance_8.k17(new sam$kotlin_Comparator$0(comparator), reversed);
  };
  protoOf(Companion_8).m17 = function (reversed, comparator, $super) {
    reversed = reversed === VOID ? false : reversed;
    return $super === VOID ? this.l17(reversed, comparator) : $super.l17.call(this, reversed, comparator);
  };
  var Companion_instance_8;
  function Companion_getInstance_8() {
    return Companion_instance_8;
  }
  function _set_value__lx0xdg(_this__u8e3s4, $this, value) {
    $this.n17_1[_PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)] = value;
  }
  function _get_value__a43j40(_this__u8e3s4, $this) {
    return $this.n17_1[_PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)];
  }
  function _get_isRoot__1006zj(_this__u8e3s4, $this) {
    return _PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4) === 0;
  }
  function _get_parent__oo9xup(_this__u8e3s4, $this) {
    return _PriorityQueueNode___init__impl__34dlfu((_PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4) - 1 | 0) / 2 | 0);
  }
  function _get_left__d9qyp0(_this__u8e3s4, $this) {
    return _PriorityQueueNode___init__impl__34dlfu(imul(2, _PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)) + 1 | 0);
  }
  function _get_right__bvz45n(_this__u8e3s4, $this) {
    return _PriorityQueueNode___init__impl__34dlfu(imul(2, _PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)) + 2 | 0);
  }
  function gt($this, a, b) {
    return $this.o17_1.compare(a, b) > 0;
  }
  function lt($this, a, b) {
    return $this.o17_1.compare(a, b) < 0;
  }
  function _get_capacity__a9k9f3_1($this) {
    return $this.n17_1.length;
  }
  function ensure_1($this, index) {
    if (index >= _get_capacity__a9k9f3_1($this)) {
      var tmp = $this;
      var tmp_0 = copyOf_2($this.n17_1, 2 + imul(_get_capacity__a9k9f3_1($this), 2) | 0);
      tmp.n17_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
    }
  }
  function minHeapify($this, index) {
    var i = _PriorityQueueNode___init__impl__34dlfu(index);
    $l$loop: while (true) {
      var left = _get_left__d9qyp0(i, $this);
      var right = _get_right__bvz45n(i, $this);
      var smallest = i;
      if (_PriorityQueueNode___get_index__impl__b6r7i5(left) < $this.p17_1 ? lt($this, _get_value__a43j40(left, $this), _get_value__a43j40(i, $this)) : false)
        smallest = left;
      if (_PriorityQueueNode___get_index__impl__b6r7i5(right) < $this.p17_1 ? lt($this, _get_value__a43j40(right, $this), _get_value__a43j40(smallest, $this)) : false)
        smallest = right;
      if (!(smallest === i)) {
        swap_0($this, i, smallest);
        i = smallest;
      } else {
        break $l$loop;
      }
    }
  }
  function swap_0($this, l, r) {
    var temp = _get_value__a43j40(r, $this);
    _set_value__lx0xdg(r, $this, _get_value__a43j40(l, $this));
    _set_value__lx0xdg(l, $this, temp);
  }
  function TGenPriorityQueue$iterator$1($index, this$0) {
    this.q17_1 = $index;
    this.r17_1 = this$0;
  }
  protoOf(TGenPriorityQueue$iterator$1).t = function () {
    return this.q17_1._v < this.r17_1.p17_1;
  };
  protoOf(TGenPriorityQueue$iterator$1).u = function () {
    var tmp0 = this.q17_1._v;
    this.q17_1._v = tmp0 + 1 | 0;
    return _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(tmp0), this.r17_1);
  };
  protoOf(TGenPriorityQueue$iterator$1).jq = function () {
    throw new NotImplementedError();
  };
  protoOf(TGenPriorityQueue$iterator$1).c1 = function () {
    return this.jq();
  };
  function TGenPriorityQueue(data, comparator) {
    this.n17_1 = data;
    this.o17_1 = comparator;
    this.p17_1 = 0;
  }
  protoOf(TGenPriorityQueue).l = function () {
    return this.p17_1;
  };
  protoOf(TGenPriorityQueue).s17 = function () {
    if (this.p17_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    return this.n17_1[0];
  };
  protoOf(TGenPriorityQueue).k13 = function (element) {
    this.p17_1 = this.p17_1 + 1 | 0;
    ensure_1(this, this.p17_1);
    var i = _PriorityQueueNode___init__impl__34dlfu(this.p17_1 - 1 | 0);
    _set_value__lx0xdg(i, this, element);
    while (!_get_isRoot__1006zj(i, this) ? gt(this, _get_value__a43j40(_get_parent__oo9xup(i, this), this), _get_value__a43j40(i, this)) : false) {
      swap_0(this, i, _get_parent__oo9xup(i, this));
      i = _get_parent__oo9xup(i, this);
    }
    return true;
  };
  protoOf(TGenPriorityQueue).p = function (element) {
    return this.k13((element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  protoOf(TGenPriorityQueue).t17 = function () {
    if (this.p17_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    if (this.p17_1 === 1) {
      this.p17_1 = this.p17_1 - 1 | 0;
      return _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(0), this);
    }
    var root = _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(0), this);
    _set_value__lx0xdg(_PriorityQueueNode___init__impl__34dlfu(0), this, _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(this.p17_1 - 1 | 0), this));
    this.p17_1 = this.p17_1 - 1 | 0;
    minHeapify(this, 0);
    return root;
  };
  protoOf(TGenPriorityQueue).u17 = function (element) {
    var inductionVariable = 0;
    var last = this.p17_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals(this.n17_1[n], element))
          return n;
      }
       while (inductionVariable < last);
    return -1;
  };
  protoOf(TGenPriorityQueue).v17 = function (element) {
    var index = this.u17(element);
    if (index >= 0) {
      this.w17(index);
    }
    return index >= 0;
  };
  protoOf(TGenPriorityQueue).w17 = function (index) {
    var i = _PriorityQueueNode___init__impl__34dlfu(index);
    while (!(_PriorityQueueNode___get_index__impl__b6r7i5(i) === 0)) {
      swap_0(this, i, _get_parent__oo9xup(i, this));
      i = _get_parent__oo9xup(i, this);
    }
    this.t17();
  };
  protoOf(TGenPriorityQueue).l13 = function (element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var this_0 = until(0, this.p17_1);
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.x();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.s();
      while (tmp0_iterator.t()) {
        var element_0 = tmp0_iterator.u();
        // Inline function 'korlibs.datastructure.TGenPriorityQueue.contains.<anonymous>' call
        if (equals(_get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(element_0), this), element)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(TGenPriorityQueue).v = function (element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.l13((element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  protoOf(TGenPriorityQueue).m13 = function (elements) {
    var thisSet = toSet(this);
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.x();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = elements.s();
      while (tmp0_iterator.t()) {
        var element = tmp0_iterator.u();
        // Inline function 'korlibs.datastructure.TGenPriorityQueue.containsAll.<anonymous>' call
        if (!thisSet.v(element)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  protoOf(TGenPriorityQueue).w = function (elements) {
    return this.m13(elements);
  };
  protoOf(TGenPriorityQueue).x = function () {
    return this.p17_1 === 0;
  };
  protoOf(TGenPriorityQueue).g13 = function (elements) {
    var tmp0_iterator = elements.s();
    while (tmp0_iterator.t()) {
      var e = tmp0_iterator.u();
      this.k13(e);
    }
    // Inline function 'kotlin.collections.isNotEmpty' call
    return !elements.x();
  };
  protoOf(TGenPriorityQueue).q = function (elements) {
    return this.g13(elements);
  };
  protoOf(TGenPriorityQueue).s = function () {
    var index = {_v: 0};
    return new TGenPriorityQueue$iterator$1(index, this);
  };
  protoOf(TGenPriorityQueue).toString = function () {
    return toString(toList(this));
  };
  protoOf(TGenPriorityQueue).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof TGenPriorityQueue) {
      tmp_0 = contentEquals_1(this.n17_1, other.n17_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = equals(this.o17_1, other.o17_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(TGenPriorityQueue).hashCode = function () {
    return contentHashCode_1(this.n17_1);
  };
  function _PriorityQueueNode___init__impl__34dlfu(index) {
    return index;
  }
  function _PriorityQueueNode___get_index__impl__b6r7i5($this) {
    return $this;
  }
  function TGenQueue() {
    this.x17_1 = TGenDeque_init_$Create$();
  }
  protoOf(TGenQueue).l = function () {
    return this.x17_1.l();
  };
  protoOf(TGenQueue).x = function () {
    return this.l() === 0;
  };
  protoOf(TGenQueue).y17 = function (v) {
    this.x17_1.e13(v);
  };
  protoOf(TGenQueue).z17 = function () {
    return this.x17_1.je();
  };
  protoOf(TGenQueue).l13 = function (element) {
    return this.x17_1.l13(element);
  };
  protoOf(TGenQueue).v = function (element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return this.l13((element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  protoOf(TGenQueue).m13 = function (elements) {
    return this.x17_1.m13(elements);
  };
  protoOf(TGenQueue).w = function (elements) {
    return this.m13(elements);
  };
  protoOf(TGenQueue).s = function () {
    return this.x17_1.s();
  };
  protoOf(TGenQueue).hashCode = function () {
    return this.x17_1.hashCode();
  };
  protoOf(TGenQueue).equals = function (other) {
    var tmp;
    if (other instanceof TGenQueue) {
      tmp = this.x17_1.equals(other.x17_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  function Ref(value) {
    // Inline function 'kotlin.also' call
    var this_0 = Ref_0();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.Ref.<anonymous>' call
    this_0.a18(value);
    return this_0;
  }
  function toRef(_this__u8e3s4) {
    return Ref_1(_this__u8e3s4);
  }
  function Ref_0() {
    return new Ref$2();
  }
  function Ref_1(prop) {
    return new Ref$3(prop);
  }
  function Ref$2() {
  }
  protoOf(Ref$2).a18 = function (_set____db54di) {
    this.b18_1 = _set____db54di;
  };
  protoOf(Ref$2).e2 = function () {
    var tmp = this.b18_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('value');
    }
  };
  function Ref$3($prop) {
    this.c18_1 = $prop;
  }
  protoOf(Ref$3).a18 = function (value) {
    this.c18_1.set(value);
  };
  protoOf(Ref$3).e2 = function () {
    return this.c18_1.get();
  };
  function RingBuffer(bits) {
    ByteRingBuffer.call(this, bits);
  }
  function ByteRingBuffer(bits) {
    this.d18_1 = bits;
    this.e18_1 = 1 << this.d18_1;
    this.f18_1 = this.e18_1 - 1 | 0;
    this.g18_1 = new Int8Array(this.e18_1);
    this.h18_1 = 0;
    this.i18_1 = 0;
    this.j18_1 = this.e18_1;
    this.k18_1 = 0;
  }
  protoOf(ByteRingBuffer).l18 = function () {
    return this.g18_1;
  };
  protoOf(ByteRingBuffer).m18 = function () {
    return this.i18_1 & this.f18_1;
  };
  protoOf(ByteRingBuffer).n18 = function (count) {
    if (count < 0 ? true : count > this.j18_1) {
      // Inline function 'kotlin.error' call
      var message = 'Try to write more than available';
      throw IllegalStateException_init_$Create$(toString(message));
    }
    this.i18_1 = this.i18_1 + count | 0;
    this.k18_1 = this.k18_1 + count | 0;
    this.j18_1 = this.j18_1 - count | 0;
  };
  protoOf(ByteRingBuffer).o18 = function () {
    // Inline function 'kotlin.math.min' call
    var a = this.k18_1;
    var b = this.e18_1 - (this.h18_1 & this.f18_1) | 0;
    return Math.min(a, b);
  };
  protoOf(ByteRingBuffer).p18 = function () {
    // Inline function 'kotlin.math.min' call
    var a = this.j18_1;
    var b = this.e18_1 - (this.i18_1 & this.f18_1) | 0;
    return Math.min(a, b);
  };
  protoOf(ByteRingBuffer).q18 = function (data, offset, size) {
    return this.m16(this.r18(data, offset, size));
  };
  protoOf(ByteRingBuffer).m16 = function (size) {
    // Inline function 'kotlin.math.min' call
    var a = this.k18_1;
    var toRead = Math.min(a, size);
    this.h18_1 = (this.h18_1 + toRead | 0) & this.f18_1;
    this.j18_1 = this.j18_1 + toRead | 0;
    this.k18_1 = this.k18_1 - toRead | 0;
    return toRead;
  };
  protoOf(ByteRingBuffer).r18 = function (data, offset, size) {
    // Inline function 'kotlin.math.min' call
    var a = this.k18_1;
    var toRead = Math.min(a, size);
    var readCount = 0;
    var buffer = this.g18_1;
    var mask = this.f18_1;
    var coffset = offset;
    var lReadPos = this.h18_1;
    $l$loop: while (true) {
      // Inline function 'kotlin.math.min' call
      var a_0 = toRead;
      var b = this.o18();
      var toReadChunk = Math.min(a_0, b);
      if (toReadChunk <= 0)
        break $l$loop;
      Memory_instance.v18(buffer, lReadPos & mask, data, coffset, toReadChunk);
      toRead = toRead - toReadChunk | 0;
      coffset = coffset + toReadChunk | 0;
      lReadPos = lReadPos + toReadChunk | 0;
      readCount = readCount + toReadChunk | 0;
    }
    return readCount;
  };
  protoOf(ByteRingBuffer).t18 = function () {
    if (this.k18_1 <= 0)
      return -1;
    var out = this.g18_1[this.h18_1] & 255;
    this.h18_1 = (this.h18_1 + 1 | 0) & this.f18_1;
    this.k18_1 = this.k18_1 - 1 | 0;
    this.j18_1 = this.j18_1 + 1 | 0;
    return out;
  };
  protoOf(ByteRingBuffer).s18 = function (offset) {
    return this.g18_1[(this.h18_1 + offset | 0) & this.f18_1];
  };
  protoOf(ByteRingBuffer).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof ByteRingBuffer) {
      tmp_0 = this.k18_1 === other.k18_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'korlibs.datastructure.internal.equaler' call
        var count = this.k18_1;
        var inductionVariable = 0;
        if (inductionVariable < count)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'korlibs.datastructure.ByteRingBuffer.equals.<anonymous>' call
            if (!(this.s18(n) === other.s18(n))) {
              tmp$ret$1 = false;
              break $l$block;
            }
          }
           while (inductionVariable < count);
        tmp$ret$1 = true;
      }
      tmp = tmp$ret$1;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(ByteRingBuffer).hashCode = function () {
    return this.u18();
  };
  protoOf(ByteRingBuffer).u18 = function () {
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var count = this.k18_1;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        // Inline function 'korlibs.datastructure.ByteRingBuffer.contentHashCode.<anonymous>' call
        out = tmp + this.s18(n) | 0;
      }
       while (inductionVariable < count);
    return out;
  };
  function _TGenStack___init__impl__yynjgt(items) {
    items = items === VOID ? FastArrayList_init_$Create$() : items;
    return items;
  }
  function _get_items__fzd5gv($this) {
    return $this;
  }
  function _TGenStack___get_size__impl__cvg66t($this) {
    return _get_items__fzd5gv($this).l();
  }
  function TGenStack__isEmpty_impl_bsbpyi($this) {
    return _TGenStack___get_size__impl__cvg66t($this) === 0;
  }
  function Companion_9() {
  }
  var Companion_instance_9;
  function Companion_getInstance_9() {
    return Companion_instance_9;
  }
  function TGenStack__push_impl_9yh33t($this, v) {
    _get_items__fzd5gv($this).p(v);
  }
  function TGenStack__pop_impl_qisxa0($this) {
    return _get_items__fzd5gv($this).e1(_get_items__fzd5gv($this).l() - 1 | 0);
  }
  function TGenStack__clear_impl_pali4s($this) {
    _get_items__fzd5gv($this).l1();
  }
  function TGenStack__contains_impl_l8kli6($this, element) {
    return _get_items__fzd5gv($this).v(element);
  }
  function TGenStack__contains_impl_l8kli6_0($this, element) {
    if (!(element == null ? true : !(element == null)))
      return false;
    return TGenStack__contains_impl_l8kli6($this.w18_1, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  }
  function TGenStack__containsAll_impl_ic9i7r($this, elements) {
    return _get_items__fzd5gv($this).w(elements);
  }
  function TGenStack__containsAll_impl_ic9i7r_0($this, elements) {
    return TGenStack__containsAll_impl_ic9i7r($this.w18_1, elements);
  }
  function TGenStack__iterator_impl_2pdia5($this) {
    return _get_items__fzd5gv($this).s();
  }
  function TGenStack__toString_impl_s9t5hn($this) {
    return 'TGenStack(items=' + $this + ')';
  }
  function TGenStack__hashCode_impl_l04zom($this) {
    return $this.hashCode();
  }
  function TGenStack__equals_impl_w9i35u($this, other) {
    if (!(other instanceof TGenStack))
      return false;
    var tmp0_other_with_cast = other instanceof TGenStack ? other.w18_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function TGenStack(items) {
    this.w18_1 = items;
  }
  protoOf(TGenStack).l = function () {
    return _TGenStack___get_size__impl__cvg66t(this.w18_1);
  };
  protoOf(TGenStack).x = function () {
    return TGenStack__isEmpty_impl_bsbpyi(this.w18_1);
  };
  protoOf(TGenStack).l13 = function (element) {
    return TGenStack__contains_impl_l8kli6(this.w18_1, element);
  };
  protoOf(TGenStack).v = function (element) {
    return TGenStack__contains_impl_l8kli6_0(this, element);
  };
  protoOf(TGenStack).m13 = function (elements) {
    return TGenStack__containsAll_impl_ic9i7r(this.w18_1, elements);
  };
  protoOf(TGenStack).w = function (elements) {
    return TGenStack__containsAll_impl_ic9i7r_0(this, elements);
  };
  protoOf(TGenStack).s = function () {
    return TGenStack__iterator_impl_2pdia5(this.w18_1);
  };
  protoOf(TGenStack).toString = function () {
    return TGenStack__toString_impl_s9t5hn(this.w18_1);
  };
  protoOf(TGenStack).hashCode = function () {
    return TGenStack__hashCode_impl_l04zom(this.w18_1);
  };
  protoOf(TGenStack).equals = function (other) {
    return TGenStack__equals_impl_w9i35u(this.w18_1, other);
  };
  function Closeable() {
  }
  function contentHashCode_2(_this__u8e3s4, src, dst) {
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var count = dst - src | 0;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        // Inline function 'korlibs.datastructure.internal.contentHashCode.<anonymous>' call
        out = tmp + numberToInt(_this__u8e3s4[src + n | 0]) | 0;
      }
       while (inductionVariable < count);
    return out;
  }
  function contentEquals_2(_this__u8e3s4, that, src, dst) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.internal.equaler' call
      var count = dst - src | 0;
      var inductionVariable = 0;
      if (inductionVariable < count)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.datastructure.internal.contentEquals.<anonymous>' call
          if (!(_this__u8e3s4[src + n | 0] === that[src + n | 0])) {
            tmp$ret$1 = false;
            break $l$block;
          }
        }
         while (inductionVariable < count);
      tmp$ret$1 = true;
    }
    return tmp$ret$1;
  }
  function contentHashCode_3(_this__u8e3s4, src, dst) {
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var count = dst - src | 0;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        // Inline function 'korlibs.datastructure.internal.contentHashCode.<anonymous>' call
        out = tmp + _this__u8e3s4[src + n | 0] | 0;
      }
       while (inductionVariable < count);
    return out;
  }
  function contentEquals_3(_this__u8e3s4, that, src, dst) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.internal.equaler' call
      var count = dst - src | 0;
      var inductionVariable = 0;
      if (inductionVariable < count)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.datastructure.internal.contentEquals.<anonymous>' call
          if (!(_this__u8e3s4[src + n | 0] === that[src + n | 0])) {
            tmp$ret$1 = false;
            break $l$block;
          }
        }
         while (inductionVariable < count);
      tmp$ret$1 = true;
    }
    return tmp$ret$1;
  }
  function contentHashCode_4(_this__u8e3s4, src, dst) {
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var count = dst - src | 0;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        // Inline function 'korlibs.datastructure.internal.contentHashCode.<anonymous>' call
        out = tmp + toRawBits(_this__u8e3s4[src + n | 0]) | 0;
      }
       while (inductionVariable < count);
    return out;
  }
  function contentEquals_4(_this__u8e3s4, that, src, dst) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.internal.equaler' call
      var count = dst - src | 0;
      var inductionVariable = 0;
      if (inductionVariable < count)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.datastructure.internal.contentEquals.<anonymous>' call
          if (!(_this__u8e3s4[src + n | 0] === that[src + n | 0])) {
            tmp$ret$1 = false;
            break $l$block;
          }
        }
         while (inductionVariable < count);
      tmp$ret$1 = true;
    }
    return tmp$ret$1;
  }
  function fill(_this__u8e3s4, value) {
    var inductionVariable = 0;
    var last = _this__u8e3s4.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        _this__u8e3s4[n] = value;
      }
       while (inductionVariable <= last);
  }
  function SyncPauseable() {
    this.y18_1 = new Lock();
    this.z18_1 = false;
  }
  protoOf(SyncPauseable).a19 = function (value) {
    if (!(this.z18_1 === value)) {
      this.z18_1 = value;
      $l$block: {
        // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
        var this_0 = this.y18_1;
        this_0.yx_1 = true;
        try {
          this.y18_1.xx();
          break $l$block;
        }finally {
          this_0.yx_1 = false;
        }
      }
    }
  };
  function Extra$PropertyThis$transform$lambda($this$null, it) {
    return it;
  }
  function Mixin(extra) {
    extra = extra === VOID ? null : extra;
    this.b19_1 = extra;
  }
  protoOf(Mixin).c19 = function (_set____db54di) {
    this.b19_1 = _set____db54di;
  };
  protoOf(Mixin).d19 = function () {
    return this.b19_1;
  };
  function Property(name, defaultGen) {
    name = name === VOID ? null : name;
    this.e19_1 = name;
    this.f19_1 = defaultGen;
  }
  function PropertyThis(name, defaultGen) {
    name = name === VOID ? null : name;
    this.g19_1 = name;
    this.h19_1 = defaultGen;
    var tmp = this;
    tmp.i19_1 = Extra$PropertyThis$transform$lambda;
  }
  function ExtraObject() {
    this.j19_1 = new Lock();
    this.k19_1 = FastStringMap();
    var tmp = this;
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'korlibs.datastructure.ExtraObject.values.<anonymous>' call
        tmp$ret$1 = toMutableList(get_values(this.k19_1));
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    tmp.l19_1 = tmp$ret$1;
  }
  protoOf(ExtraObject).l = function () {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'korlibs.datastructure.ExtraObject.<get-size>.<anonymous>' call
        tmp$ret$1 = get_size_1(this.k19_1);
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return tmp$ret$1;
  };
  protoOf(ExtraObject).c2 = function () {
    var tmp$ret$6;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'korlibs.datastructure.ExtraObject.<get-entries>.<anonymous>' call
        // Inline function 'kotlin.collections.associateWith' call
        var this_1 = get_keys_0(this.k19_1);
        var result = LinkedHashMap_init_$Create$_0(coerceAtLeast(mapCapacity(collectionSizeOrDefault(this_1, 10)), 16));
        // Inline function 'kotlin.collections.associateWithTo' call
        var tmp0_iterator = this_1.s();
        while (tmp0_iterator.t()) {
          var element = tmp0_iterator.u();
          // Inline function 'korlibs.datastructure.ExtraObject.<get-entries>.<anonymous>.<anonymous>' call
          // Inline function 'korlibs.datastructure.get' call
          // Inline function 'kotlin.js.asDynamic' call
          var tmp$ret$2 = this.k19_1.get(element);
          result.a2(element, tmp$ret$2);
        }
        tmp$ret$6 = toMutableSet(toMutableMap(result).c2());
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return tmp$ret$6;
  };
  protoOf(ExtraObject).y1 = function () {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'korlibs.datastructure.ExtraObject.<get-keys>.<anonymous>' call
        tmp$ret$1 = toMutableSet(get_keys_0(this.k19_1));
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return tmp$ret$1;
  };
  protoOf(ExtraObject).z1 = function () {
    return this.l19_1;
  };
  protoOf(ExtraObject).t12 = function (key) {
    var tmp$ret$3;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'korlibs.datastructure.ExtraObject.get.<anonymous>' call
        // Inline function 'korlibs.datastructure.get' call
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$3 = this.k19_1.get(key);
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return tmp$ret$3;
  };
  protoOf(ExtraObject).k2 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.t12((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(ExtraObject).m19 = function (key, value) {
    var tmp$ret$3;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'korlibs.datastructure.set' call
        // Inline function 'kotlin.run' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$3 = this.k19_1.set(key, value);
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return tmp$ret$3;
  };
  protoOf(ExtraObject).n19 = function (key) {
    var tmp$ret$3;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'korlibs.datastructure.ExtraObject.contains.<anonymous>' call
        // Inline function 'korlibs.datastructure.contains' call
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$3 = this.k19_1.has(key);
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return tmp$ret$3;
  };
  protoOf(ExtraObject).x = function () {
    return this.l() === 0;
  };
  protoOf(ExtraObject).l1 = function () {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$1 = this.k19_1.clear();
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return tmp$ret$1;
  };
  protoOf(ExtraObject).v12 = function (key) {
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'kotlin.js.asDynamic' call
        this.k19_1.delete(key);
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return Unit_instance;
  };
  protoOf(ExtraObject).b2 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.v12((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(ExtraObject).o19 = function (key, value) {
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'korlibs.datastructure.set' call
        // Inline function 'kotlin.run' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlin.js.asDynamic' call
        this.k19_1.set(key, value);
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return Unit_instance;
  };
  protoOf(ExtraObject).a2 = function (key, value) {
    var tmp = (!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE();
    return this.o19(tmp, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  };
  protoOf(ExtraObject).s12 = function (key) {
    var tmp$ret$3;
    $l$block: {
      // Inline function 'korlibs.concurrent.lock.Lock.invoke' call
      var this_0 = this.j19_1;
      this_0.yx_1 = true;
      try {
        // Inline function 'korlibs.datastructure.ExtraObject.containsKey.<anonymous>' call
        // Inline function 'korlibs.datastructure.contains' call
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$3 = this.k19_1.has(key);
        break $l$block;
      }finally {
        this_0.yx_1 = false;
      }
    }
    return tmp$ret$3;
  };
  protoOf(ExtraObject).h2 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return false;
    return this.s12((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  function getExtra(_this__u8e3s4, name) {
    var tmp0_safe_receiver = _this__u8e3s4.d19();
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.t12(name);
  }
  function setExtra(_this__u8e3s4, name, value) {
    if (_this__u8e3s4.d19() == null) {
      if (value == null)
        return Unit_instance;
      _this__u8e3s4.c19(ExtraTypeCreate());
    }
    var tmp0_safe_receiver = _this__u8e3s4.d19();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.m19(name, value);
    }
  }
  function hasExtra(_this__u8e3s4, name) {
    var tmp0_safe_receiver = _this__u8e3s4.d19();
    return (tmp0_safe_receiver == null ? null : tmp0_safe_receiver.n19(name)) === true;
  }
  function getExtraTyped(_this__u8e3s4, name) {
    // Inline function 'korlibs.datastructure.fastCastTo' call
    var tmp0_safe_receiver = _this__u8e3s4.d19();
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.t12(name);
  }
  function ExtraTypeCreate() {
    return new ExtraObject();
  }
  function _BSearchResult___init__impl__em6zam(raw) {
    return raw;
  }
  function _BSearchResult___get_raw__impl__uyuhf3($this) {
    return $this;
  }
  function _BSearchResult___get_found__impl__om04iz($this) {
    return _BSearchResult___get_raw__impl__uyuhf3($this) >= 0;
  }
  function _BSearchResult___get_index__impl__b5kraz($this) {
    return _BSearchResult___get_found__impl__om04iz($this) ? _BSearchResult___get_raw__impl__uyuhf3($this) : -1;
  }
  function _BSearchResult___get_nearIndex__impl__s8nq43($this) {
    return _BSearchResult___get_found__impl__om04iz($this) ? _BSearchResult___get_raw__impl__uyuhf3($this) : (-_BSearchResult___get_raw__impl__uyuhf3($this) | 0) - 1 | 0;
  }
  function binarySearch(_this__u8e3s4, v, fromIndex, toIndex) {
    fromIndex = fromIndex === VOID ? 0 : fromIndex;
    toIndex = toIndex === VOID ? _this__u8e3s4.l() : toIndex;
    // Inline function 'korlibs.datastructure.genericBinarySearchResult' call
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.genericBinarySearch' call
      var low = fromIndex;
      var high = toIndex - 1 | 0;
      while (low <= high) {
        var mid = (low + high | 0) / 2 | 0;
        // Inline function 'korlibs.datastructure.binarySearch.<anonymous>' call
        var mval = compareTo(_this__u8e3s4.e10(mid), v);
        if (mval < 0)
          low = mid + 1 | 0;
        else if (mval > 0)
          high = mid - 1 | 0;
        else {
          tmp$ret$1 = mid;
          break $l$block;
        }
      }
      // Inline function 'korlibs.datastructure.genericBinarySearchResult.<anonymous>' call
      tmp$ret$1 = (-low | 0) - 1 | 0;
    }
    return _BSearchResult___init__impl__em6zam(tmp$ret$1);
  }
  function getCyclic(_this__u8e3s4, index) {
    return _this__u8e3s4.b1(Math_instance.o10(index, _this__u8e3s4.l()));
  }
  function linkedHashMapOf(pairs) {
    // Inline function 'kotlin.also' call
    var this_0 = LinkedHashMap_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.linkedHashMapOf.<anonymous>' call
    var inductionVariable = 0;
    var last = pairs.length;
    while (inductionVariable < last) {
      var tmp1_loop_parameter = pairs[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var key = tmp1_loop_parameter.bf();
      var value = tmp1_loop_parameter.cf();
      // Inline function 'kotlin.collections.set' call
      this_0.a2(key, value);
    }
    return this_0;
  }
  function flip(_this__u8e3s4) {
    // Inline function 'kotlin.collections.map' call
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList_init_$Create$(_this__u8e3s4.l());
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = _this__u8e3s4.c2().s();
    while (tmp0_iterator.t()) {
      var item = tmp0_iterator.u();
      // Inline function 'korlibs.datastructure.flip.<anonymous>' call
      var tmp$ret$1 = new Pair(item.e2(), item.d2());
      destination.p(tmp$ret$1);
    }
    return toMap(destination);
  }
  function toLinkedMap(_this__u8e3s4) {
    // Inline function 'kotlin.also' call
    var this_0 = LinkedHashMap_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.toLinkedMap.<anonymous>' call
    var tmp0_iterator = _this__u8e3s4.s();
    while (tmp0_iterator.t()) {
      var tmp1_loop_parameter = tmp0_iterator.u();
      var key = tmp1_loop_parameter.bf();
      var value = tmp1_loop_parameter.cf();
      // Inline function 'kotlin.collections.set' call
      this_0.a2(key, value);
    }
    return this_0;
  }
  function getCyclic_0(_this__u8e3s4, index) {
    return _this__u8e3s4[Math_instance.o10(index, _this__u8e3s4.length)];
  }
  function genericSort(subject, left, right, ops) {
    return genericSort_0(subject, left, right, ops, false);
  }
  function SortOps() {
  }
  protoOf(SortOps).o12 = function (subject, indexL, indexR) {
    var inductionVariable = indexR;
    var last = indexL + 1 | 0;
    if (last <= inductionVariable)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        this.n12(subject, n - 1 | 0, n);
      }
       while (!(n === last));
  };
  protoOf(SortOps).j12 = function (subject, indexL, indexR) {
    var count = (indexR - indexL | 0) + 1 | 0;
    var inductionVariable = 0;
    var last = count / 2 | 0;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.n12(subject, indexL + n | 0, indexR - n | 0);
      }
       while (inductionVariable < last);
  };
  function genericSort_0(subject, left, right, ops, reversed) {
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.genericSort.<anonymous>' call
    timSort(subject, left, right, ops, reversed);
    return subject;
  }
  function timSort(arr, l, r, ops, reversed, RUN) {
    RUN = RUN === VOID ? 32 : RUN;
    var n = (r - l | 0) + 1 | 0;
    var progression = step(until(0, n), RUN);
    var inductionVariable = progression.pa_1;
    var last = progression.qa_1;
    var step_0 = progression.ra_1;
    if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        var tmp = l + i | 0;
        // Inline function 'kotlin.math.min' call
        var a = (i + RUN | 0) - 1 | 0;
        var b = n - 1 | 0;
        var tmp$ret$0 = Math.min(a, b);
        insertionSort(arr, tmp, l + tmp$ret$0 | 0, ops, reversed);
      }
       while (!(i === last));
    var size = RUN;
    while (size < n) {
      var progression_0 = step(until(0, n), imul(2, size));
      var inductionVariable_0 = progression_0.pa_1;
      var last_0 = progression_0.qa_1;
      var step_1 = progression_0.ra_1;
      if ((step_1 > 0 ? inductionVariable_0 <= last_0 : false) ? true : step_1 < 0 ? last_0 <= inductionVariable_0 : false)
        do {
          var left = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + step_1 | 0;
          // Inline function 'kotlin.math.min' call
          var a_0 = size;
          var b_0 = (n - left | 0) - 1 | 0;
          var rize = Math.min(a_0, b_0);
          var mid = (left + rize | 0) - 1 | 0;
          // Inline function 'kotlin.math.min' call
          var a_1 = (left + imul(2, rize) | 0) - 1 | 0;
          var b_1 = n - 1 | 0;
          var right = Math.min(a_1, b_1);
          merge(arr, l + left | 0, l + mid | 0, l + right | 0, ops, reversed);
        }
         while (!(left === last_0));
      size = imul(size, 2);
    }
  }
  function insertionSort(arr, left, right, ops, reversed) {
    var inductionVariable = left + 1 | 0;
    if (inductionVariable <= right)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var m = n - 1 | 0;
        $l$loop: while (m >= left && negateIf(ops.l12(arr, m, n), reversed) > 0) {
          m = m - 1 | 0;
        }
        m = m + 1 | 0;
        if (!(m === n)) {
          ops.o12(arr, m, n);
        }
      }
       while (!(n === right));
  }
  function merge(arr, start, mid, end, ops, reversed) {
    var s = start;
    var m = mid;
    var s2 = m + 1 | 0;
    if (negateIf(ops.l12(arr, m, s2), reversed) <= 0)
      return Unit_instance;
    while (s <= m ? s2 <= end : false) {
      if (negateIf(ops.l12(arr, s, s2), reversed) <= 0) {
        s = s + 1 | 0;
      } else {
        ops.o12(arr, s, s2);
        s = s + 1 | 0;
        m = m + 1 | 0;
        s2 = s2 + 1 | 0;
      }
    }
  }
  function negateIf(_this__u8e3s4, doNegate) {
    return doNegate ? -_this__u8e3s4 | 0 : _this__u8e3s4;
  }
  function Math_0() {
    this.n10_1 = -0.0;
  }
  protoOf(Math_0).o10 = function (_this__u8e3s4, other) {
    var rm = _this__u8e3s4 % other | 0;
    var remainder = rm === 0 ? 0 : rm;
    return remainder < 0 ? remainder + other | 0 : remainder;
  };
  protoOf(Math_0).d15 = function (v) {
    // Inline function 'kotlin.math.ceil' call
    // Inline function 'kotlin.math.log2' call
    var x = log2(v);
    var tmp$ret$1 = Math.ceil(x);
    return numberToInt(tmp$ret$1);
  };
  var Math_instance;
  function Math_getInstance() {
    return Math_instance;
  }
  function Memory() {
  }
  protoOf(Memory).a13 = function (src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var destination = isArray(dst) ? dst : THROW_CCE();
    var endIndex = srcPos + size | 0;
    arrayCopy(src, destination, dstPos, srcPos, endIndex);
  };
  protoOf(Memory).v18 = function (src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  };
  protoOf(Memory).s11 = function (src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  };
  protoOf(Memory).f12 = function (src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  };
  protoOf(Memory).b11 = function (src, srcPos, dst, dstPos, size) {
    // Inline function 'kotlin.collections.copyInto' call
    var endIndex = srcPos + size | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp = src;
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    arrayCopy(tmp, dst, dstPos, srcPos, endIndex);
  };
  var Memory_instance;
  function Memory_getInstance() {
    return Memory_instance;
  }
  function FastArrayList_init_$Init$($this) {
    // Inline function 'kotlin.emptyArray' call
    var tmp$ret$0 = [];
    FastArrayList.call($this, tmp$ret$0);
    return $this;
  }
  function FastArrayList_init_$Create$() {
    return FastArrayList_init_$Init$(objectCreate(protoOf(FastArrayList)));
  }
  function FastArrayList_init_$Init$_0(initialCapacity, $this) {
    // Inline function 'kotlin.emptyArray' call
    var tmp$ret$0 = [];
    FastArrayList.call($this, tmp$ret$0);
    return $this;
  }
  function FastArrayList_init_$Create$_0(initialCapacity) {
    return FastArrayList_init_$Init$_0(initialCapacity, objectCreate(protoOf(FastArrayList)));
  }
  function _addAll($this, elements) {
    // Inline function 'kotlin.collections.isEmpty' call
    if (elements.length === 0)
      return false;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$3 = $this.y13_1;
    (function () {
      var $externalVarargReceiverTmp = tmp$ret$3;
      return $externalVarargReceiverTmp.push.apply($externalVarargReceiverTmp, [].slice.call(elements.slice()));
    }.call(this));
    $this.d1_1 = $this.d1_1 + 1 | 0;
    return true;
  }
  function rangeCheck($this, index) {
    if (index < 0 ? true : index >= $this.l())
      throw IndexOutOfBoundsException_init_$Create$_0('index: ' + index + ', size: ' + $this.l());
    return index;
  }
  function insertionRangeCheck($this, index) {
    if (index < 0 ? true : index > $this.l())
      throw IndexOutOfBoundsException_init_$Create$_0('index: ' + index + ', size: ' + $this.l());
    return index;
  }
  function FastArrayList(__array) {
    AbstractMutableList.call(this);
    this.y13_1 = __array;
  }
  protoOf(FastArrayList).l = function () {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.y13_1.length;
  };
  protoOf(FastArrayList).b1 = function (index) {
    // Inline function 'get' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.y13_1[rangeCheck(this, index)];
  };
  protoOf(FastArrayList).m = function (index, element) {
    rangeCheck(this, index);
    // Inline function 'kotlin.apply' call
    // Inline function 'get' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    var this_0 = this.y13_1[index];
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.FastArrayList.set.<anonymous>' call
    // Inline function 'set' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    this.y13_1[index] = element;
    return this_0;
  };
  protoOf(FastArrayList).p = function (element) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    this.y13_1.push(element);
    this.d1_1 = this.d1_1 + 1 | 0;
    return true;
  };
  protoOf(FastArrayList).k1 = function (index, element) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    this.y13_1.splice(insertionRangeCheck(this, index), 0, element);
    this.d1_1 = this.d1_1 + 1 | 0;
  };
  protoOf(FastArrayList).q = function (elements) {
    // Inline function 'kotlin.collections.toTypedArray' call
    var tmp$ret$0 = copyToArray(elements);
    return _addAll(this, tmp$ret$0);
  };
  protoOf(FastArrayList).e1 = function (index) {
    rangeCheck(this, index);
    this.d1_1 = this.d1_1 + 1 | 0;
    var tmp;
    if (index === 0) {
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = this.y13_1.shift();
    } else if (index === get_lastIndex(this)) {
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = this.y13_1.pop();
    } else {
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = this.y13_1.splice(index, 1)[0];
    }
    return tmp;
  };
  protoOf(FastArrayList).w3 = function (element) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var array = this.y13_1;
    var inductionVariable = 0;
    var last = array.length;
    if (inductionVariable < last)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'get' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp$ret$4 = array[index];
        if (equals(tmp$ret$4, element)) {
          array.splice(index, 1);
          this.d1_1 = this.d1_1 + 1 | 0;
          return true;
        }
      }
       while (inductionVariable < last);
    return false;
  };
  protoOf(FastArrayList).m1 = function (fromIndex, toIndex) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    this.y13_1.splice(fromIndex, toIndex - fromIndex | 0);
    this.d1_1 = this.d1_1 + 1 | 0;
  };
  protoOf(FastArrayList).l1 = function () {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    this.y13_1.length = 0;
    this.d1_1 = this.d1_1 + 1 | 0;
  };
  protoOf(FastArrayList).o1 = function (element) {
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.y13_1.indexOf(element);
  };
  protoOf(FastArrayList).toString = function () {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$4 = this.y13_1;
    return '[' + joinToString_0(tmp$ret$4, ', ') + ']';
  };
  protoOf(FastArrayList).t2 = function () {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.y13_1.concat();
  };
  protoOf(FastArrayList).toArray = function () {
    return this.t2();
  };
  function FastIntMap() {
    // Inline function 'kotlin.js.asDynamic' call
    return new Map();
  }
  function get_size(_this__u8e3s4) {
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4.size;
  }
  function FastStringMap() {
    // Inline function 'kotlin.js.asDynamic' call
    return new Map();
  }
  function FastIdentityMap() {
    // Inline function 'kotlin.js.asDynamic' call
    return new Map();
  }
  function clear(_this__u8e3s4) {
    // Inline function 'kotlin.js.asDynamic' call
    _this__u8e3s4.clear();
  }
  function get_size_0(_this__u8e3s4) {
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4.size;
  }
  function contains(_this__u8e3s4, key) {
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4.has(key);
  }
  function get(_this__u8e3s4, key) {
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4.get(key);
  }
  function set(_this__u8e3s4, key, value) {
    // Inline function 'kotlin.js.asDynamic' call
    _this__u8e3s4.set(key, value);
  }
  function get_size_1(_this__u8e3s4) {
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4.size;
  }
  function remove(_this__u8e3s4, key) {
    // Inline function 'kotlin.js.asDynamic' call
    _this__u8e3s4.delete(key);
  }
  function keys(_this__u8e3s4) {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$2 = Array_from(_this__u8e3s4.keys());
    return toList_0(tmp$ret$2);
  }
  function keys_0(_this__u8e3s4) {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    // Inline function 'kotlin.js.asDynamic' call
    var tmp$ret$2 = Array_from(_this__u8e3s4.keys());
    return toList_0(tmp$ret$2);
  }
  function Array_from(value) {
    return Array.from(value);
  }
  var CONCURRENCY_COUNT;
  //region block: post-declaration
  protoOf(IntArray2).i10 = getAt;
  protoOf(IntArray2).h10 = asString;
  protoOf(FloatArray2).i10 = getAt;
  protoOf(FloatArray2).h10 = asString;
  protoOf(FastArrayList).d14 = addAll_0;
  protoOf(FastArrayList).b14 = setAddAll;
  protoOf(FastArrayList).z13 = setAddAll$default;
  protoOf(FastArrayList).c14 = setAll;
  protoOf(FastArrayList).a14 = removeToSize;
  //endregion
  //region block: init
  Companion_instance = new Companion();
  Companion_instance_0 = new Companion_0();
  Companion_instance_1 = new Companion_1();
  Companion_instance_2 = new Companion_2();
  Companion_instance_3 = new Companion_3();
  Companion_instance_4 = new Companion_4();
  Companion_instance_5 = new Companion_5();
  Companion_instance_6 = new Companion_6();
  Companion_instance_7 = new Companion_7();
  Companion_instance_8 = new Companion_8();
  Companion_instance_9 = new Companion_9();
  Math_instance = new Math_0();
  Memory_instance = new Memory();
  CONCURRENCY_COUNT = 1;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = DoubleArrayList_init_$Create$;
  _.$_$.b = FastArrayList_init_$Create$_0;
  _.$_$.c = FastArrayList_init_$Create$;
  _.$_$.d = IntArrayList_init_$Create$;
  _.$_$.e = IntIntMap_init_$Create$;
  _.$_$.f = IntMap_init_$Create$;
  _.$_$.g = Pool_init_$Create$;
  _.$_$.h = _BSearchResult___init__impl__em6zam;
  _.$_$.i = _BSearchResult___get_found__impl__om04iz;
  _.$_$.j = _BSearchResult___get_index__impl__b5kraz;
  _.$_$.k = _BSearchResult___get_nearIndex__impl__s8nq43;
  _.$_$.l = _TGenStack___init__impl__yynjgt;
  _.$_$.m = TGenStack__pop_impl_qisxa0;
  _.$_$.n = TGenStack__push_impl_9yh33t;
  _.$_$.o = Companion_instance_0;
  _.$_$.p = Companion_instance;
  _.$_$.q = Companion_instance_8;
  _.$_$.r = Closeable;
  _.$_$.s = SyncPauseable;
  _.$_$.t = ConcurrentPool;
  _.$_$.u = DoubleArrayList;
  _.$_$.v = Mixin;
  _.$_$.w = PropertyThis;
  _.$_$.x = Property;
  _.$_$.y = FastIdentityMap;
  _.$_$.z = FastIntMap;
  _.$_$.a1 = FastSmallSet;
  _.$_$.b1 = FastStringMap;
  _.$_$.c1 = FloatArray2;
  _.$_$.d1 = GenericListIterator;
  _.$_$.e1 = IntArray2;
  _.$_$.f1 = IntArrayList;
  _.$_$.g1 = IntDeque;
  _.$_$.h1 = ListReader;
  _.$_$.i1 = Pool;
  _.$_$.j1 = Ref_0;
  _.$_$.k1 = Ref;
  _.$_$.l1 = ReturnablePool;
  _.$_$.m1 = RingBuffer;
  _.$_$.n1 = SortOps;
  _.$_$.o1 = TGenQueue;
  _.$_$.p1 = associateByInt;
  _.$_$.q1 = binarySearch;
  _.$_$.r1 = clear;
  _.$_$.s1 = contains;
  _.$_$.t1 = doubleArrayListOf;
  _.$_$.u1 = fastArrayListOf;
  _.$_$.v1 = flip;
  _.$_$.w1 = floatArrayListOf;
  _.$_$.x1 = genericSort;
  _.$_$.y1 = getAndRemove;
  _.$_$.z1 = getCyclic_0;
  _.$_$.a2 = getCyclic;
  _.$_$.b2 = getExtraTyped;
  _.$_$.c2 = getExtra;
  _.$_$.d2 = get;
  _.$_$.e2 = hasExtra;
  _.$_$.f2 = intArrayListOf;
  _.$_$.g2 = get_keys;
  _.$_$.h2 = linkedHashMapOf;
  _.$_$.i2 = reverse_0;
  _.$_$.j2 = rotateRight;
  _.$_$.k2 = setExtra;
  _.$_$.l2 = set;
  _.$_$.m2 = get_size_0;
  _.$_$.n2 = get_size;
  _.$_$.o2 = sort;
  _.$_$.p2 = swap;
  _.$_$.q2 = toCaseInsensitiveMap;
  _.$_$.r2 = toFastList_0;
  _.$_$.s2 = toFastList;
  _.$_$.t2 = toIntArrayList;
  _.$_$.u2 = toIntMap;
  _.$_$.v2 = toRef;
  //endregion
  return _;
}));
