(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-math-core'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'korge-root-korlibs-math-core'.");
    }
    root['korge-root-korlibs-math-core'] = factory(typeof this['korge-root-korlibs-math-core'] === 'undefined' ? {} : this['korge-root-korlibs-math-core'], this['kotlin-kotlin-stdlib']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var clz32 = Math.clz32;
  var toLong = kotlin_kotlin.$_$.ba;
  var Long = kotlin_kotlin.$_$.hd;
  var IntCompanionObject_instance = kotlin_kotlin.$_$.x2;
  var VOID = kotlin_kotlin.$_$.d;
  var ShortCompanionObject_instance = kotlin_kotlin.$_$.y2;
  var toShort = kotlin_kotlin.$_$.ca;
  var numberToInt = kotlin_kotlin.$_$.u9;
  var isInfinite = kotlin_kotlin.$_$.ud;
  var isNaN_0 = kotlin_kotlin.$_$.vd;
  var Unit_instance = kotlin_kotlin.$_$.g3;
  var round = kotlin_kotlin.$_$.ha;
  //endregion
  //region block: pre-declaration
  //endregion
  function nextAlignedTo(_this__u8e3s4, align) {
    return isAlignedTo(_this__u8e3s4, align) ? _this__u8e3s4 : imul((_this__u8e3s4 / align | 0) + 1 | 0, align);
  }
  function nextAlignedTo_0(_this__u8e3s4, align) {
    var tmp;
    if (isAlignedTo_0(_this__u8e3s4, align)) {
      tmp = _this__u8e3s4;
    } else {
      // Inline function 'kotlin.Long.plus' call
      tmp = _this__u8e3s4.v9(align).wb(toLong(1)).w9(align);
    }
    return tmp;
  }
  function isAlignedTo(_this__u8e3s4, alignment) {
    return alignment === 0 ? true : (_this__u8e3s4 % alignment | 0) === 0;
  }
  function isAlignedTo_0(_this__u8e3s4, alignment) {
    return alignment.equals(new Long(0, 0)) ? true : _this__u8e3s4.xb(alignment).equals(new Long(0, 0));
  }
  function clamp(_this__u8e3s4, min, max) {
    return _this__u8e3s4 < min ? min : _this__u8e3s4 > max ? max : _this__u8e3s4;
  }
  function clamp01(_this__u8e3s4) {
    return clamp(_this__u8e3s4, 0.0, 1.0);
  }
  function clamp_0(_this__u8e3s4, min, max) {
    return _this__u8e3s4 < min ? min : _this__u8e3s4 > max ? max : _this__u8e3s4;
  }
  function clampUByte(_this__u8e3s4) {
    var n = _this__u8e3s4 & (-(_this__u8e3s4 >= 0 ? 1 : 0) | 0);
    return (n | (255 - n | 0) >> 31) & 255;
  }
  function clamp01_0(_this__u8e3s4) {
    return clamp_2(_this__u8e3s4, 0.0, 1.0);
  }
  function toIntClamp(_this__u8e3s4, min, max) {
    min = min === VOID ? IntCompanionObject_instance.MIN_VALUE : min;
    max = max === VOID ? IntCompanionObject_instance.MAX_VALUE : max;
    if (_this__u8e3s4.j6(toLong(min)) < 0)
      return min;
    if (_this__u8e3s4.j6(toLong(max)) > 0)
      return max;
    return _this__u8e3s4.ua();
  }
  function clamp_1(_this__u8e3s4, min, max) {
    return _this__u8e3s4.j6(min) < 0 ? min : _this__u8e3s4.j6(max) > 0 ? max : _this__u8e3s4;
  }
  function clamp_2(_this__u8e3s4, min, max) {
    return _this__u8e3s4 < min ? min : _this__u8e3s4 > max ? max : _this__u8e3s4;
  }
  function toShortClamped(_this__u8e3s4) {
    return toShort(clamp_0(_this__u8e3s4, ShortCompanionObject_instance.MIN_VALUE, ShortCompanionObject_instance.MAX_VALUE));
  }
  function convertRange(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
    return dstMin + (dstMax - dstMin) * ((_this__u8e3s4 - srcMin) / (srcMax - srcMin));
  }
  function convertRange_0(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
    return dstMin + (dstMax - dstMin) * ((_this__u8e3s4 - srcMin) / (srcMax - srcMin));
  }
  function convertRange_1(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
    return numberToInt(dstMin + (dstMax - dstMin | 0) * ((_this__u8e3s4 - srcMin | 0) / (srcMax - srcMin | 0)));
  }
  function divCeil(_this__u8e3s4, that) {
    return !((_this__u8e3s4 % that | 0) === 0) ? (_this__u8e3s4 / that | 0) + 1 | 0 : _this__u8e3s4 / that | 0;
  }
  function ilog2(v) {
    var tmp;
    if (v === 0) {
      tmp = -1;
    } else {
      // Inline function 'korlibs.math.countLeadingZeros' call
      // Inline function 'kotlin.countLeadingZeroBits' call
      tmp = 31 - clz32(v) | 0;
    }
    return tmp;
  }
  function isAlmostEquals(_this__u8e3s4, other, epsilon) {
    epsilon = epsilon === VOID ? 1.0E-6 : epsilon;
    // Inline function 'kotlin.math.absoluteValue' call
    var this_0 = _this__u8e3s4 - other;
    return Math.abs(this_0) < epsilon;
  }
  function isAlmostZero(_this__u8e3s4) {
    // Inline function 'kotlin.math.abs' call
    return Math.abs(_this__u8e3s4) <= 1.0E-19;
  }
  function isAlmostEquals_0(_this__u8e3s4, other, epsilon) {
    epsilon = epsilon === VOID ? 1.0E-5 : epsilon;
    // Inline function 'kotlin.math.absoluteValue' call
    var this_0 = _this__u8e3s4 - other;
    return Math.abs(this_0) < epsilon;
  }
  function isNanOrInfinite(_this__u8e3s4) {
    return isNaN_0(_this__u8e3s4) ? true : isInfinite(_this__u8e3s4);
  }
  function get_nextPowerOfTwo(_this__u8e3s4) {
    var v = _this__u8e3s4;
    v = v - 1 | 0;
    v = v | v >> 1;
    v = v | v >> 2;
    v = v | v >> 4;
    v = v | v >> 8;
    v = v | v >> 16;
    v = v + 1 | 0;
    return v;
  }
  function get_isPowerOfTwo(_this__u8e3s4) {
    return get_nextPowerOfTwo(_this__u8e3s4) === _this__u8e3s4;
  }
  function roundDecimalPlaces(_this__u8e3s4, places) {
    if (places < 0)
      return _this__u8e3s4;
    // Inline function 'kotlin.math.pow' call
    var placesFactor = Math.pow(10.0, places);
    return round(_this__u8e3s4 * placesFactor) / placesFactor;
  }
  function roundDecimalPlaces_0(_this__u8e3s4, places) {
    if (places < 0)
      return _this__u8e3s4;
    // Inline function 'kotlin.math.pow' call
    var placesFactor = Math.pow(10.0, places);
    // Inline function 'kotlin.math.round' call
    var x = _this__u8e3s4 * placesFactor;
    return round(x) / placesFactor;
  }
  function toIntRound(_this__u8e3s4) {
    return numberToInt(round(_this__u8e3s4));
  }
  function toIntCeil(_this__u8e3s4) {
    // Inline function 'kotlin.math.ceil' call
    var tmp$ret$0 = Math.ceil(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  function toIntFloor(_this__u8e3s4) {
    // Inline function 'kotlin.math.floor' call
    var tmp$ret$0 = Math.floor(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  function toIntFloor_0(_this__u8e3s4) {
    // Inline function 'kotlin.math.floor' call
    var tmp$ret$0 = Math.floor(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  function toIntCeil_0(_this__u8e3s4) {
    // Inline function 'kotlin.math.ceil' call
    var tmp$ret$0 = Math.ceil(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  function toIntRound_0(_this__u8e3s4) {
    // Inline function 'kotlin.math.round' call
    var tmp$ret$0 = round(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  var MINUS_ZERO_F;
  function umod(_this__u8e3s4, other) {
    var rm = _this__u8e3s4 % other;
    var remainder = rm === -0.0 ? 0.0 : rm;
    return remainder < 0.0 ? remainder + other : remainder;
  }
  function umod_0(_this__u8e3s4, other) {
    var rm = _this__u8e3s4 % other | 0;
    var remainder = rm === 0 ? 0 : rm;
    return remainder < 0 ? remainder + other | 0 : remainder;
  }
  function umod_1(_this__u8e3s4, other) {
    var rm = _this__u8e3s4 % other;
    var remainder = rm === MINUS_ZERO_F ? 0.0 : rm;
    return remainder < 0.0 ? remainder + other : remainder;
  }
  //region block: init
  MINUS_ZERO_F = -0.0;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = clamp01;
  _.$_$.b = clamp01_0;
  _.$_$.c = clampUByte;
  _.$_$.d = clamp_2;
  _.$_$.e = clamp_0;
  _.$_$.f = clamp;
  _.$_$.g = clamp_1;
  _.$_$.h = convertRange;
  _.$_$.i = convertRange_0;
  _.$_$.j = convertRange_1;
  _.$_$.k = divCeil;
  _.$_$.l = ilog2;
  _.$_$.m = isAlmostEquals_0;
  _.$_$.n = isAlmostEquals;
  _.$_$.o = isAlmostZero;
  _.$_$.p = isNanOrInfinite;
  _.$_$.q = get_isPowerOfTwo;
  _.$_$.r = nextAlignedTo_0;
  _.$_$.s = nextAlignedTo;
  _.$_$.t = get_nextPowerOfTwo;
  _.$_$.u = roundDecimalPlaces;
  _.$_$.v = roundDecimalPlaces_0;
  _.$_$.w = toIntCeil;
  _.$_$.x = toIntCeil_0;
  _.$_$.y = toIntClamp;
  _.$_$.z = toIntFloor;
  _.$_$.a1 = toIntFloor_0;
  _.$_$.b1 = toIntRound;
  _.$_$.c1 = toIntRound_0;
  _.$_$.d1 = toShortClamped;
  _.$_$.e1 = umod_0;
  _.$_$.f1 = umod_1;
  _.$_$.g1 = umod;
  //endregion
  return _;
}));
