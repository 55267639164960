(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-platform'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'korge-root-korlibs-platform'.");
    }
    root['korge-root-korlibs-platform'] = factory(typeof this['korge-root-korlibs-platform'] === 'undefined' ? {} : this['korge-root-korlibs-platform'], this['kotlin-kotlin-stdlib']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var protoOf = kotlin_kotlin.$_$.y9;
  var objectMeta = kotlin_kotlin.$_$.x9;
  var setMetadataFor = kotlin_kotlin.$_$.z9;
  var Unit_instance = kotlin_kotlin.$_$.g3;
  var VOID = kotlin_kotlin.$_$.d;
  var Enum = kotlin_kotlin.$_$.ed;
  var classMeta = kotlin_kotlin.$_$.t8;
  var interfaceMeta = kotlin_kotlin.$_$.g9;
  var toString = kotlin_kotlin.$_$.da;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.j1;
  var printStackTrace = kotlin_kotlin.$_$.zd;
  var KProperty0 = kotlin_kotlin.$_$.ab;
  var getPropertyCallableRef = kotlin_kotlin.$_$.c9;
  var lazy = kotlin_kotlin.$_$.xd;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Arch, 'Arch', classMeta, Enum);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(Endian, 'Endian', classMeta, Enum);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(Os, 'Os', classMeta, Enum);
  function get_isWindows() {
    return this.pz().kz();
  }
  function get_isLinux() {
    return this.pz().lz();
  }
  function get_isMac() {
    return this.pz().mz();
  }
  function get_isNative() {
    return this.qz().sz();
  }
  function get_isJsNodeJs() {
    return this.rz() === 'js-node' ? true : this.rz() === 'wasm-node';
  }
  function get_isJsBrowser() {
    return this.rz() === 'js-web' ? true : this.rz() === 'wasm-web';
  }
  setMetadataFor(Platform, 'Platform', interfaceMeta);
  setMetadataFor(Companion_2, 'Companion', objectMeta, VOID, [Platform]);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(Runtime, 'Runtime', classMeta, Enum);
  //endregion
  var Arch_UNKNOWN_instance;
  var Arch_X86_instance;
  var Arch_X64_instance;
  var Arch_ARM32_instance;
  var Arch_ARM64_instance;
  var Arch_MIPS32_instance;
  var Arch_MIPSEL32_instance;
  var Arch_MIPS64_instance;
  var Arch_MIPSEL64_instance;
  var Arch_WASM32_instance;
  var Arch_POWERPC64_instance;
  function Companion() {
  }
  protoOf(Companion).ty = function () {
    return get_currentArch();
  };
  var Companion_instance;
  function Companion_getInstance() {
    return Companion_instance;
  }
  var Arch_entriesInitialized;
  function Arch_initEntries() {
    if (Arch_entriesInitialized)
      return Unit_instance;
    Arch_entriesInitialized = true;
    Arch_UNKNOWN_instance = new Arch('UNKNOWN', 0, -1);
    Arch_X86_instance = new Arch('X86', 1, 32, VOID, true);
    Arch_X64_instance = new Arch('X64', 2, 64, VOID, true);
    Arch_ARM32_instance = new Arch('ARM32', 3, 32, true);
    Arch_ARM64_instance = new Arch('ARM64', 4, 64, true);
    Arch_MIPS32_instance = new Arch('MIPS32', 5, 32, VOID, VOID, true);
    Arch_MIPSEL32_instance = new Arch('MIPSEL32', 6, 32, VOID, VOID, true);
    Arch_MIPS64_instance = new Arch('MIPS64', 7, 64, VOID, VOID, true);
    Arch_MIPSEL64_instance = new Arch('MIPSEL64', 8, 64, VOID, VOID, true);
    Arch_WASM32_instance = new Arch('WASM32', 9, 32, VOID, VOID, VOID, true);
    Arch_POWERPC64_instance = new Arch('POWERPC64', 10, 64, VOID, VOID, VOID, VOID, true);
  }
  function Arch(name, ordinal, bits, isArm, isX86OrX64, isMips, isWasm, isPowerPC) {
    isArm = isArm === VOID ? false : isArm;
    isX86OrX64 = isX86OrX64 === VOID ? false : isX86OrX64;
    isMips = isMips === VOID ? false : isMips;
    isWasm = isWasm === VOID ? false : isWasm;
    isPowerPC = isPowerPC === VOID ? false : isPowerPC;
    Enum.call(this, name, ordinal);
    this.wy_1 = bits;
    this.xy_1 = isArm;
    this.yy_1 = isX86OrX64;
    this.zy_1 = isMips;
    this.az_1 = isWasm;
    this.bz_1 = isPowerPC;
  }
  protoOf(Arch).cz = function () {
    return this.wy_1 === 32;
  };
  function Arch_UNKNOWN_getInstance() {
    Arch_initEntries();
    return Arch_UNKNOWN_instance;
  }
  var Endian_LITTLE_ENDIAN_instance;
  var Endian_BIG_ENDIAN_instance;
  function Companion_0() {
    Companion_instance_0 = this;
    this.dz_1 = get_currentIsLittleEndian() ? Endian_LITTLE_ENDIAN_getInstance() : Endian_BIG_ENDIAN_getInstance();
  }
  var Companion_instance_0;
  function Companion_getInstance_0() {
    Endian_initEntries();
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  var Endian_entriesInitialized;
  function Endian_initEntries() {
    if (Endian_entriesInitialized)
      return Unit_instance;
    Endian_entriesInitialized = true;
    Endian_LITTLE_ENDIAN_instance = new Endian('LITTLE_ENDIAN', 0);
    Endian_BIG_ENDIAN_instance = new Endian('BIG_ENDIAN', 1);
    Companion_getInstance_0();
  }
  function Endian(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(Endian).gz = function () {
    return this.equals(Endian_LITTLE_ENDIAN_getInstance());
  };
  function Endian_LITTLE_ENDIAN_getInstance() {
    Endian_initEntries();
    return Endian_LITTLE_ENDIAN_instance;
  }
  function Endian_BIG_ENDIAN_getInstance() {
    Endian_initEntries();
    return Endian_BIG_ENDIAN_instance;
  }
  var Os_UNKNOWN_instance;
  var Os_MACOSX_instance;
  var Os_IOS_instance;
  var Os_LINUX_instance;
  var Os_WINDOWS_instance;
  var Os_ANDROID_instance;
  var Os_WASM_instance;
  var Os_TVOS_instance;
  function Companion_1() {
    Companion_instance_1 = this;
    this.hz_1 = values();
  }
  protoOf(Companion_1).ty = function () {
    return get_currentOs();
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    Os_initEntries();
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function values() {
    return [Os_UNKNOWN_getInstance(), Os_MACOSX_getInstance(), Os_IOS_getInstance(), Os_LINUX_getInstance(), Os_WINDOWS_getInstance(), Os_ANDROID_getInstance(), Os_WASM_getInstance(), Os_TVOS_getInstance()];
  }
  var Os_entriesInitialized;
  function Os_initEntries() {
    if (Os_entriesInitialized)
      return Unit_instance;
    Os_entriesInitialized = true;
    Os_UNKNOWN_instance = new Os('UNKNOWN', 0);
    Os_MACOSX_instance = new Os('MACOSX', 1);
    Os_IOS_instance = new Os('IOS', 2);
    Os_LINUX_instance = new Os('LINUX', 3);
    Os_WINDOWS_instance = new Os('WINDOWS', 4);
    Os_ANDROID_instance = new Os('ANDROID', 5);
    Os_WASM_instance = new Os('WASM', 6);
    Os_TVOS_instance = new Os('TVOS', 7);
    Companion_getInstance_1();
  }
  function Os(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(Os).kz = function () {
    return this.equals(Os_WINDOWS_getInstance());
  };
  protoOf(Os).lz = function () {
    return this.equals(Os_LINUX_getInstance());
  };
  protoOf(Os).mz = function () {
    return this.equals(Os_MACOSX_getInstance());
  };
  function Os_UNKNOWN_getInstance() {
    Os_initEntries();
    return Os_UNKNOWN_instance;
  }
  function Os_MACOSX_getInstance() {
    Os_initEntries();
    return Os_MACOSX_instance;
  }
  function Os_IOS_getInstance() {
    Os_initEntries();
    return Os_IOS_instance;
  }
  function Os_LINUX_getInstance() {
    Os_initEntries();
    return Os_LINUX_instance;
  }
  function Os_WINDOWS_getInstance() {
    Os_initEntries();
    return Os_WINDOWS_instance;
  }
  function Os_ANDROID_getInstance() {
    Os_initEntries();
    return Os_ANDROID_instance;
  }
  function Os_WASM_getInstance() {
    Os_initEntries();
    return Os_WASM_instance;
  }
  function Os_TVOS_getInstance() {
    Os_initEntries();
    return Os_TVOS_instance;
  }
  function Companion_2() {
  }
  protoOf(Companion_2).nz = function () {
    return get_currentIsLittleEndian();
  };
  protoOf(Companion_2).oz = function () {
    return Companion_instance.ty();
  };
  protoOf(Companion_2).pz = function () {
    return Companion_getInstance_1().ty();
  };
  protoOf(Companion_2).qz = function () {
    return Companion_instance_3.ty();
  };
  protoOf(Companion_2).rz = function () {
    return get_currentRawPlatformName();
  };
  var Companion_instance_2;
  function Companion_getInstance_2() {
    return Companion_instance_2;
  }
  function Platform() {
  }
  var Runtime_JS_instance;
  var Runtime_JVM_instance;
  var Runtime_ANDROID_instance;
  var Runtime_NATIVE_instance;
  var Runtime_WASM_instance;
  function Companion_3() {
  }
  protoOf(Companion_3).ty = function () {
    return get_currentRuntime();
  };
  var Companion_instance_3;
  function Companion_getInstance_3() {
    return Companion_instance_3;
  }
  var Runtime_entriesInitialized;
  function Runtime_initEntries() {
    if (Runtime_entriesInitialized)
      return Unit_instance;
    Runtime_entriesInitialized = true;
    Runtime_JS_instance = new Runtime('JS', 0);
    Runtime_JVM_instance = new Runtime('JVM', 1);
    Runtime_ANDROID_instance = new Runtime('ANDROID', 2);
    Runtime_NATIVE_instance = new Runtime('NATIVE', 3);
    Runtime_WASM_instance = new Runtime('WASM', 4);
  }
  function Runtime(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(Runtime).xz = function () {
    return this.equals(Runtime_JVM_getInstance());
  };
  protoOf(Runtime).sz = function () {
    return this.equals(Runtime_NATIVE_getInstance());
  };
  function Runtime_JS_getInstance() {
    Runtime_initEntries();
    return Runtime_JS_instance;
  }
  function Runtime_JVM_getInstance() {
    Runtime_initEntries();
    return Runtime_JVM_instance;
  }
  function Runtime_NATIVE_getInstance() {
    Runtime_initEntries();
    return Runtime_NATIVE_instance;
  }
  function get_isDenoJs() {
    _init_properties_Current_js_kt__w11d1e();
    // Inline function 'kotlin.getValue' call
    var this_0 = isDenoJs$delegate;
    isDenoJs$factory();
    return this_0.e2();
  }
  var isDenoJs$delegate;
  function get_isWeb() {
    _init_properties_Current_js_kt__w11d1e();
    // Inline function 'kotlin.getValue' call
    var this_0 = isWeb$delegate;
    isWeb$factory();
    return this_0.e2();
  }
  var isWeb$delegate;
  function get_isWorker() {
    _init_properties_Current_js_kt__w11d1e();
    // Inline function 'kotlin.getValue' call
    var this_0 = isWorker$delegate;
    isWorker$factory();
    return this_0.e2();
  }
  var isWorker$delegate;
  function get_isNodeJs() {
    _init_properties_Current_js_kt__w11d1e();
    // Inline function 'kotlin.getValue' call
    var this_0 = isNodeJs$delegate;
    isNodeJs$factory();
    return this_0.e2();
  }
  var isNodeJs$delegate;
  function get_currentArch() {
    _init_properties_Current_js_kt__w11d1e();
    return currentArch;
  }
  var currentArch;
  function get_currentRuntime() {
    _init_properties_Current_js_kt__w11d1e();
    return currentRuntime;
  }
  var currentRuntime;
  var currentIsDebug;
  function get_currentIsLittleEndian() {
    _init_properties_Current_js_kt__w11d1e();
    return currentIsLittleEndian;
  }
  var currentIsLittleEndian;
  var multithreadedSharedHeap;
  function get_currentRawPlatformName() {
    _init_properties_Current_js_kt__w11d1e();
    return currentRawPlatformName;
  }
  var currentRawPlatformName;
  var currentRawOsName;
  function get_isShell() {
    _init_properties_Current_js_kt__w11d1e();
    return (!get_isWeb() ? !get_isNodeJs() : false) ? !get_isWorker() : false;
  }
  function get_process() {
    _init_properties_Current_js_kt__w11d1e();
    if (typeof process === 'undefined') {
      try {
        // Inline function 'kotlin.error' call
        var message = "Not in NodeJS. Can't access process";
        throw IllegalStateException_init_$Create$(toString(message));
      } catch ($p) {
        if ($p instanceof Error) {
          var e = $p;
          printStackTrace(e);
          throw e;
        } else {
          throw $p;
        }
      }
    }
    return process;
  }
  function get_jsGlobalThis() {
    _init_properties_Current_js_kt__w11d1e();
    return globalThis;
  }
  function get_currentOs() {
    _init_properties_Current_js_kt__w11d1e();
    var tmp;
    if (get_isDenoJs()) {
      var tmp0_subject = Deno.build.os;
      tmp = tmp0_subject == 'darwin' ? Os_MACOSX_getInstance() : tmp0_subject == 'linux' ? Os_LINUX_getInstance() : tmp0_subject == 'windows' ? Os_WINDOWS_getInstance() : Os_UNKNOWN_getInstance();
    } else {
      tmp = Os_UNKNOWN_getInstance();
    }
    return tmp;
  }
  function isDenoJs$delegate$lambda() {
    _init_properties_Current_js_kt__w11d1e();
    // Inline function 'kotlin.js.unsafeCast' call
    return typeof Deno === 'object' && Deno.statSync;
  }
  function isWeb$delegate$lambda() {
    _init_properties_Current_js_kt__w11d1e();
    // Inline function 'kotlin.js.unsafeCast' call
    return typeof window === 'object';
  }
  function isWorker$delegate$lambda() {
    _init_properties_Current_js_kt__w11d1e();
    // Inline function 'kotlin.js.unsafeCast' call
    return typeof importScripts === 'function';
  }
  function isNodeJs$delegate$lambda() {
    _init_properties_Current_js_kt__w11d1e();
    // Inline function 'kotlin.js.unsafeCast' call
    return typeof process !== 'undefined' && process.release && process.release.name.search(/node|io.js/) !== -1;
  }
  function isDenoJs$factory() {
    return getPropertyCallableRef('isDenoJs', 0, KProperty0, function () {
      return get_isDenoJs();
    }, null);
  }
  function isWeb$factory() {
    return getPropertyCallableRef('isWeb', 0, KProperty0, function () {
      return get_isWeb();
    }, null);
  }
  function isWorker$factory() {
    return getPropertyCallableRef('isWorker', 0, KProperty0, function () {
      return get_isWorker();
    }, null);
  }
  function isNodeJs$factory() {
    return getPropertyCallableRef('isNodeJs', 0, KProperty0, function () {
      return get_isNodeJs();
    }, null);
  }
  var properties_initialized_Current_js_kt_4h6d9c;
  function _init_properties_Current_js_kt__w11d1e() {
    if (!properties_initialized_Current_js_kt_4h6d9c) {
      properties_initialized_Current_js_kt_4h6d9c = true;
      isDenoJs$delegate = lazy(isDenoJs$delegate$lambda);
      isWeb$delegate = lazy(isWeb$delegate$lambda);
      isWorker$delegate = lazy(isWorker$delegate$lambda);
      isNodeJs$delegate = lazy(isNodeJs$delegate$lambda);
      currentArch = Arch_UNKNOWN_getInstance();
      currentRuntime = Runtime_JS_getInstance();
      currentIsDebug = false;
      // Inline function 'org.khronos.webgl.get' call
      // Inline function 'kotlin.arrayOf' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$2 = [287454020];
      // Inline function 'kotlin.js.asDynamic' call
      currentIsLittleEndian = (new Uint8Array((new Uint32Array(tmp$ret$2)).buffer))[0] === 68;
      multithreadedSharedHeap = false;
      currentRawPlatformName = get_isDenoJs() ? 'js-deno' : get_isWeb() ? 'js-web' : get_isNodeJs() ? 'js-node' : get_isWorker() ? 'js-worker' : get_isShell() ? 'js-shell' : 'js';
      var tmp;
      if (get_isDenoJs()) {
        tmp = 'deno';
      } else if (get_isWeb() ? true : get_isWorker()) {
        // Inline function 'kotlin.js.unsafeCast' call
        tmp = navigator.userAgent;
      } else {
        // Inline function 'kotlin.js.unsafeCast' call
        tmp = get_process().platform;
      }
      currentRawOsName = tmp;
    }
  }
  //region block: post-declaration
  protoOf(Companion_2).kz = get_isWindows;
  protoOf(Companion_2).lz = get_isLinux;
  protoOf(Companion_2).mz = get_isMac;
  protoOf(Companion_2).sz = get_isNative;
  protoOf(Companion_2).tz = get_isJsNodeJs;
  protoOf(Companion_2).uz = get_isJsBrowser;
  //endregion
  //region block: init
  Companion_instance = new Companion();
  Companion_instance_2 = new Companion_2();
  Companion_instance_3 = new Companion_3();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Endian_BIG_ENDIAN_getInstance;
  _.$_$.b = Endian_LITTLE_ENDIAN_getInstance;
  _.$_$.c = Os_UNKNOWN_getInstance;
  _.$_$.d = Companion_getInstance_1;
  _.$_$.e = Companion_instance_2;
  _.$_$.f = get_jsGlobalThis;
  //endregion
  return _;
}));
