(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korlibs-inject'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'korge-root-korlibs-inject'.");
    }
    root['korge-root-korlibs-inject'] = factory(typeof this['korge-root-korlibs-inject'] === 'undefined' ? {} : this['korge-root-korlibs-inject'], this['kotlin-kotlin-stdlib']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var protoOf = kotlin_kotlin.$_$.y9;
  var objectMeta = kotlin_kotlin.$_$.x9;
  var setMetadataFor = kotlin_kotlin.$_$.z9;
  var THROW_CCE = kotlin_kotlin.$_$.md;
  var classMeta = kotlin_kotlin.$_$.t8;
  var RuntimeException = kotlin_kotlin.$_$.ld;
  var VOID = kotlin_kotlin.$_$.d;
  var RuntimeException_init_$Init$ = kotlin_kotlin.$_$.q1;
  var captureStack = kotlin_kotlin.$_$.o8;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.r;
  var getKClass = kotlin_kotlin.$_$.b;
  var Unit_instance = kotlin_kotlin.$_$.g3;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.m;
  var isInterface = kotlin_kotlin.$_$.o9;
  var interfaceMeta = kotlin_kotlin.$_$.g9;
  var ensureNotNull = kotlin_kotlin.$_$.td;
  var get = kotlin_kotlin.$_$.b8;
  var fold = kotlin_kotlin.$_$.a8;
  var minusKey = kotlin_kotlin.$_$.c8;
  var plus = kotlin_kotlin.$_$.e8;
  var Element = kotlin_kotlin.$_$.d8;
  var toString = kotlin_kotlin.$_$.da;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.j1;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(RequestContext, 'RequestContext', classMeta);
  setMetadataFor(NotMappedException, 'NotMappedException', classMeta, RuntimeException);
  setMetadataFor(Injector, 'Injector', classMeta, VOID, VOID, Injector);
  setMetadataFor(InjectorDependency, 'InjectorDependency', interfaceMeta);
  setMetadataFor(ObjectProvider, 'ObjectProvider', interfaceMeta);
  setMetadataFor(InstanceObjectProvider, 'InstanceObjectProvider', classMeta, VOID, [ObjectProvider]);
  setMetadataFor(SingletonObjectProvider, 'SingletonObjectProvider', classMeta, VOID, [ObjectProvider]);
  setMetadataFor(PrototypeObjectProvider, 'PrototypeObjectProvider', classMeta, VOID, [ObjectProvider]);
  setMetadataFor(InjectorDestructor, 'InjectorDestructor', interfaceMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(InjectorContext, 'InjectorContext', classMeta, VOID, [Element]);
  //endregion
  function Companion() {
  }
  var Companion_instance;
  function Companion_getInstance() {
    return Companion_instance;
  }
  function RequestContext(initialClazz) {
    this.tw_1 = initialClazz;
  }
  protoOf(RequestContext).toString = function () {
    return 'RequestContext(initialClazz=' + this.tw_1 + ')';
  };
  protoOf(RequestContext).hashCode = function () {
    return this.tw_1.hashCode();
  };
  protoOf(RequestContext).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RequestContext))
      return false;
    var tmp0_other_with_cast = other instanceof RequestContext ? other : THROW_CCE();
    if (!this.tw_1.equals(tmp0_other_with_cast.tw_1))
      return false;
    return true;
  };
  function NotMappedException(clazz, requestedByClass, ctx, msg) {
    msg = msg === VOID ? 'Not mapped ' + clazz + ' requested by ' + requestedByClass + ' in ' + ctx : msg;
    RuntimeException_init_$Init$(msg, this);
    captureStack(this, NotMappedException);
    this.uw_1 = clazz;
    this.vw_1 = requestedByClass;
    this.ww_1 = ctx;
    this.xw_1 = msg;
  }
  function Injector(parent, level) {
    parent = parent === VOID ? null : parent;
    level = level === VOID ? 0 : level;
    this.yw_1 = parent;
    this.zw_1 = level;
    this.ax_1 = null;
    this.bx_1 = LinkedHashMap_init_$Create$();
    var tmp = this;
    var tmp0_safe_receiver = this.yw_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.cx_1;
    tmp.cx_1 = tmp1_elvis_lhs == null ? this : tmp1_elvis_lhs;
    this.ex(getKClass(Injector), this);
    var tmp_0 = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp_0.dx_1 = ArrayList_init_$Create$();
  }
  protoOf(Injector).fx = function () {
    var tmp1_elvis_lhs = this.ax_1;
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp0_safe_receiver = this.yw_1;
      tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.ax_1;
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  };
  protoOf(Injector).gx = function () {
    return new Injector(this, this.zw_1 + 1 | 0);
  };
  protoOf(Injector).ex = function (clazz, instance) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.inject.Injector.mapInstance.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var this_0 = this.bx_1;
    var value = new InstanceObjectProvider(instance);
    this_0.a2(clazz, value);
    return this;
  };
  protoOf(Injector).hx = function (clazz, gen) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.inject.Injector.mapSingleton.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var this_0 = this.bx_1;
    var value = new SingletonObjectProvider(gen);
    this_0.a2(clazz, value);
    return this;
  };
  protoOf(Injector).ix = function (clazz, gen) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.inject.Injector.mapPrototype.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var this_0 = this.bx_1;
    var value = new PrototypeObjectProvider(gen);
    this_0.a2(clazz, value);
    return this;
  };
  protoOf(Injector).jx = function (clazz, ctx) {
    var tmp1_elvis_lhs = this.bx_1.k2(clazz);
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp0_safe_receiver = this.yw_1;
      tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.jx(clazz, ctx);
    } else {
      tmp = tmp1_elvis_lhs;
    }
    var tmp4_elvis_lhs = tmp;
    var tmp_0;
    if (tmp4_elvis_lhs == null) {
      var tmp2_safe_receiver = this.fx();
      var tmp3_safe_receiver = tmp2_safe_receiver == null ? null : tmp2_safe_receiver(clazz, ctx);
      var tmp_1;
      if (tmp3_safe_receiver == null) {
        tmp_1 = null;
      } else {
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.inject.Injector.getProviderOrNull.<anonymous>' call
        // Inline function 'kotlin.collections.set' call
        this.bx_1.a2(clazz, tmp3_safe_receiver);
        tmp_1 = tmp3_safe_receiver;
      }
      tmp_0 = tmp_1;
    } else {
      tmp_0 = tmp4_elvis_lhs;
    }
    var tmp_2 = tmp_0;
    return (tmp_2 == null ? true : isInterface(tmp_2, ObjectProvider)) ? tmp_2 : null;
  };
  protoOf(Injector).kx = function (clazz, ctx) {
    var tmp0_elvis_lhs = this.jx(clazz, ctx);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw new NotMappedException(clazz, ctx.tw_1, ctx, "Class '" + clazz + "' doesn't have constructors " + ctx);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(Injector).lx = function (clazz, ctx) {
    return this.kx(clazz, ctx).mx(this);
  };
  protoOf(Injector).nx = function (clazz, ctx, $super) {
    ctx = ctx === VOID ? new RequestContext(clazz) : ctx;
    return $super === VOID ? this.lx(clazz, ctx) : $super.lx.call(this, clazz, ctx);
  };
  protoOf(Injector).toString = function () {
    return 'Injector(level=' + this.zw_1 + ')';
  };
  protoOf(Injector).ox = function (instance) {
    if (!(instance == null) ? isInterface(instance, InjectorDependency) : false) {
      instance.px(this);
    }
    if (!(instance == null) ? isInterface(instance, InjectorDestructor) : false) {
      this.dx_1.p(instance);
    }
    return instance;
  };
  protoOf(Injector).qx = function () {
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = this.bx_1.c2().s();
    while (tmp0_iterator.t()) {
      var pair = tmp0_iterator.u();
      pair.e2().qx();
    }
    var tmp1_iterator = this.dx_1.s();
    while (tmp1_iterator.t()) {
      var deinit = tmp1_iterator.u();
      deinit.qx();
    }
    this.dx_1.l1();
  };
  function InjectorDependency() {
  }
  function ObjectProvider() {
  }
  function InstanceObjectProvider(instance) {
    this.rx_1 = instance;
  }
  protoOf(InstanceObjectProvider).mx = function (injector) {
    return this.rx_1;
  };
  protoOf(InstanceObjectProvider).qx = function () {
    var tmp = this.rx_1;
    var tmp0_safe_receiver = (tmp == null ? true : isInterface(tmp, InjectorDestructor)) ? tmp : null;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.qx();
    }
  };
  protoOf(InstanceObjectProvider).toString = function () {
    return 'InstanceObjectProvider(' + this.rx_1 + ')';
  };
  function SingletonObjectProvider(generator) {
    this.sx_1 = generator;
    this.tx_1 = null;
  }
  protoOf(SingletonObjectProvider).mx = function (injector) {
    if (this.tx_1 == null)
      this.tx_1 = injector.ox(this.sx_1(injector));
    return ensureNotNull(this.tx_1);
  };
  protoOf(SingletonObjectProvider).qx = function () {
    var tmp = this.tx_1;
    var tmp0_safe_receiver = (tmp == null ? true : isInterface(tmp, InjectorDestructor)) ? tmp : null;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.qx();
    }
  };
  protoOf(SingletonObjectProvider).toString = function () {
    return 'SingletonObjectProvider(' + this.tx_1 + ')';
  };
  function PrototypeObjectProvider(generator) {
    this.ux_1 = generator;
  }
  protoOf(PrototypeObjectProvider).mx = function (injector) {
    return injector.ox(this.ux_1(injector));
  };
  protoOf(PrototypeObjectProvider).qx = function () {
    return Unit_instance;
  };
  protoOf(PrototypeObjectProvider).toString = function () {
    return 'PrototypeObjectProvider()';
  };
  function InjectorDestructor() {
  }
  function Companion_0() {
  }
  var Companion_instance_0;
  function Companion_getInstance_0() {
    return Companion_instance_0;
  }
  function InjectorContext(injector) {
    this.vx_1 = injector;
  }
  protoOf(InjectorContext).d2 = function () {
    return Companion_instance_0;
  };
  function injector($completion) {
    // Inline function 'kotlin.js.getCoroutineContext' call
    var tmp0_safe_receiver = $completion.e6().pc(Companion_instance_0);
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.vx_1;
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var message = 'Injector not in the context, please call withInjector function';
      throw IllegalStateException_init_$Create$(toString(message));
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  }
  //region block: post-declaration
  protoOf(InjectorContext).pc = get;
  protoOf(InjectorContext).ag = fold;
  protoOf(InjectorContext).zf = minusKey;
  protoOf(InjectorContext).bg = plus;
  //endregion
  //region block: init
  Companion_instance = new Companion();
  Companion_instance_0 = new Companion_0();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = injector;
  _.$_$.b = InjectorContext;
  _.$_$.c = InjectorDependency;
  _.$_$.d = Injector;
  //endregion
  return _;
}));
